import { isNull, isUndefined } from 'lodash';
import { SELECTION_A, SELECTION_B, VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { Cell, Row } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import {
  APPROACH_SELECTIONS_ALIASES,
  EBITDA_APPROACH_SELECTION,
  EBITDA_GPC_MULTIPLE,
  GPC_LTM_EBITDA_COLUMN_LEGEND,
  GPC_LTM_REVENUE_COLUMN_LEGEND,
  GPC_MULTIPLE,
  GPT_EBITDA_COLUMN_LEGEND,
  GPT_REVENUE_COLUMN_LEGEND,
  MULTIPLE_ALIASES,
  PUBLIC_COMPANIES,
  PV_OF_TERMINAL_VALUE,
  REM_TVT_SECOND_COLUMN,
  REVENUE_APPROACH_SELECTION,
  SELECT_MULTIPLE_ALIASES,
  TERMINAL_VALUE,
} from 'pages/Valuations/util/constants';
import { mapVariableLabelsToSelectionIndex } from 'pages/Valuations/util/percentileOptionsTools';
import {
  PV_OF_TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE_CUSTOM_KEY,
} from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/constants';
import { getSelectionCellOptions, publicCompsOrTransactionsExists } from 'pages/ValuationsAllocation/util';
import { getObjectValue, getValueByPropOrFallback, parseValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import swapMap from 'utilities/swapMap';
import getCurrentSelectedApproach from './getCurrentSelectedApproach';
import getFormulaExpression from './getFormulaExpression';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';
import {
  CustomParserProps,
  GetBenchmarkColumnLegendProps,
  GetBenchmarkExpressionProps,
  GetCellValueProps,
  GetExpressionForMultipleProps,
  GetExpressionToUseProps,
  GetValueProps,
  HandleRowProps,
  ParserProps,
} from './types';

const getValue = (props: GetValueProps) => {
  const { column, row } = props;
  if (row.alias === EBITDA_APPROACH_SELECTION) {
    const { ebitda_gpc_approach_selection, ebitda_gpt_approach_selection } = column;
    return ebitda_gpc_approach_selection ?? ebitda_gpt_approach_selection;
  }
  if (row.alias === REVENUE_APPROACH_SELECTION) {
    const { revenue_gpc_approach_selection, revenue_gpt_approach_selection } = column;
    return revenue_gpc_approach_selection ?? revenue_gpt_approach_selection;
  }
  return !isUndefined(column[row.alias]) ? column[row.alias] : null;
};

export function getBenchmarkColumnLegend(props: GetBenchmarkColumnLegendProps) {
  const { benchmarkType, rowAlias, columnLegend } = props;
  let benchmarkColumnLegend
    = benchmarkType === PUBLIC_COMPANIES ? GPC_LTM_REVENUE_COLUMN_LEGEND : GPT_REVENUE_COLUMN_LEGEND;
  // this happens when we have the Revenue && EBITDA TVT
  if (rowAlias === GPC_MULTIPLE && columnLegend === REM_TVT_SECOND_COLUMN)
    return benchmarkType === PUBLIC_COMPANIES ? GPC_LTM_EBITDA_COLUMN_LEGEND : GPT_EBITDA_COLUMN_LEGEND;
  if (rowAlias === EBITDA_GPC_MULTIPLE) {
    benchmarkColumnLegend
      = benchmarkType === PUBLIC_COMPANIES ? GPC_LTM_EBITDA_COLUMN_LEGEND : GPT_EBITDA_COLUMN_LEGEND;
  }
  return benchmarkColumnLegend;
}

function getBenchmarkExpression(props: GetBenchmarkExpressionProps) {
  const { row, columnLegend, multipleSelection } = props;
  const { alias, expr, benchmarkType } = row;
  if (MULTIPLE_ALIASES.includes(alias) && multipleSelection !== VALUATIONS_OTHER_LABEL) {
    const blueprint = expr;
    // column legends depend on benchmark type
    const benchmarkColumnLegend = getBenchmarkColumnLegend({ benchmarkType, rowAlias: alias, columnLegend });
    if (blueprint) {
      const benchmarkExpression = blueprint.replace(/#/g, `benchmark.${benchmarkColumnLegend}`);
      return benchmarkExpression.replace(/@/g, columnLegend);
    }
  }
  return '';
}

function getExpr(row: Row, columnLegend: string) {
  return row.expr ? row.expr.replace(/@/g, columnLegend) : '';
}

function getCellValue(props: GetCellValueProps) {
  const { pickedSelectionValue, parsedValue } = props;
  if (!isNull(pickedSelectionValue)) {
    return pickedSelectionValue;
  }
  return parsedValue;
}

function getMultipleSelectionAliasForMultiple(multipleAlias: string) {
  if (!MULTIPLE_ALIASES.includes(multipleAlias)) return null;
  const invertedMap = swapMap(obtainMapForSelectMultipleAlias());
  return invertedMap.get(multipleAlias);
}

function updateReadOnlyState(cellObject: Cell, makeEditable: boolean): Cell {
  if (!makeEditable) return cellObject;
  return { ...cellObject, readOnly: !makeEditable };
}

const getExpressionToUse = ({
  rowConfigExpression,
  benchmarkBasedExpression,
  multipleSelectedIsOther,
}: GetExpressionToUseProps) => {
  if (multipleSelectedIsOther) return '';
  return benchmarkBasedExpression || rowConfigExpression;
};

const getExpressionForMultiple = ({
  approach,
  alias,
  columnLegend,
  selectedMultipleValue,
}: GetExpressionForMultipleProps) =>
  getFormulaExpression({
    approach,
    alias,
    columnLegend,
    selectedMultipleValue,
  });

const getParser
  = (customProps?: CustomParserProps) =>
    ({ columns, rowConfig, tableData }: ParserProps) => {
      const { cellUpdateMixin, cfTVTRMSheetName, dcfTVTEMSheetName } = getObjectValue(customProps);
      const { approaches } = tableData;
      const alphabet = alphabetGenerator([], columns.length);
      let cells = {};

      const handleRow = (props: HandleRowProps) => (row: Row, rowIndex: number) => {
        const { column, columnLegend } = props;
        const rowNumber = rowIndex + 1;
        const key = columnLegend + rowNumber;
        const value = getValue({ column, row });
        const type = getValueByPropOrFallback(row, 'gridType', null);

        let customKey;

        if (row.alias === PV_OF_TERMINAL_VALUE) {
          customKey = PV_OF_TERMINAL_VALUE_CUSTOM_KEY;
        }
        if (row.alias === TERMINAL_VALUE) {
          customKey = TERMINAL_VALUE_CUSTOM_KEY;
        }

        /* check if selection for the multiple was "Other".
    We don't want a complex expression if that's the case */
        const multipleSelectionAlias = getMultipleSelectionAliasForMultiple(row.alias);
        const transactionCompsOrPublicCompsExists = publicCompsOrTransactionsExists(approaches ?? []);
        let multipleSelection = column[multipleSelectionAlias as keyof typeof column] as string;
        const multipleSelectedIsOther = multipleSelection === VALUATIONS_OTHER_LABEL;
        const benchmarkBasedExpression = getBenchmarkExpression({ row, columnLegend, multipleSelection });

        let expression = '';
        const isSecondColumn = columnLegend === REM_TVT_SECOND_COLUMN;
        const selectedApproach = getCurrentSelectedApproach({
          approaches,
          approach: tableData.approach,
          isSecondColumn,
        }) as ValuationsApproach;

        if (MULTIPLE_ALIASES.includes(row.alias)) {
          if ([SELECTION_A, SELECTION_B].includes(multipleSelection)) {
            const approachData = selectedApproach?.valuations_approach_gpt ?? selectedApproach?.valuations_approach_gpc;
            const percentileNumber
            = multipleSelection === SELECTION_A
              ? approachData?.percentile_selection_a
              : approachData?.percentile_selection_b;
            multipleSelection = percentileNumber ? percentileNumber.toString() : '';
          }

          expression = getExpressionForMultiple({
            approach: selectedApproach,
            alias: row.alias,
            columnLegend,
            selectedMultipleValue: multipleSelection,
          });
        } else {
          expression = getExpressionToUse({
            benchmarkBasedExpression,
            rowConfigExpression: getExpr(row, columnLegend),
            multipleSelectedIsOther,
          });
        }

        const parsedValue = parseValue(
        // by passing panel id for the cell value, the select components are happy
          APPROACH_SELECTIONS_ALIASES.includes(row.alias) && selectedApproach?.panelId ? selectedApproach.panelId : value,
          type,
          null,
          null,
          row.dbType
        );

        const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;
        const multipleOptions = getSelectionCellOptions({
          isDCF: true,
          specificApproach,
        });

        // TODO: Check this!
        const percentileSelections = (rowConfig.find(({ alias }) => SELECT_MULTIPLE_ALIASES.includes(alias)) as any)
          ?.percentileSelections;
        const pickedSelectionValue: number | null = SELECT_MULTIPLE_ALIASES.includes(row.alias)
          ? mapVariableLabelsToSelectionIndex({
            selection: value,
            options: multipleOptions,
            specificApproach: percentileSelections,
          })
          : null;

        const cellValue = getCellValue({ pickedSelectionValue, parsedValue });

        let cell = {
          ...row,
          key,
          type,
          alias: getValueByPropOrFallback(row, 'alias', ''),
          className: '',
          customKey,
          columnId: column.id,
          parentColumn: column.parentColumn,
          columnLegend,
          columnOrder: column.order,
          rowNumber,
          expr: expression,
          value: cellValue,
        } as any;

        cell = updateReadOnlyState(cell, multipleSelectedIsOther);

        if (customProps) {
          cell = cellUpdateMixin({ cell, cfTVTRMSheetName, dcfTVTEMSheetName });
        }

        if (MULTIPLE_ALIASES.includes(cell.alias)) {
          cell = { ...cell, readOnly: !multipleSelectedIsOther };
        }

        if (APPROACH_SELECTIONS_ALIASES.includes(row.alias) && !transactionCompsOrPublicCompsExists) {
          cell = {
            ...cell,
            readOnly: true,
            value: 'N/A',
            dataEditor: undefined,
            valueViewer: undefined,
            forceComponent: false,
          };
        }

        if (!cell.ignoreCell) {
          cells = {
            ...cells,
            [key]: cell,
          };
        }
      };

      // Parse body cells
      columns.forEach((column, columnIndex) => {
        const columnLegend = alphabet[columnIndex];
        rowConfig.forEach(handleRow({ column, columnLegend }));
      });

      return cells;
    };

export default getParser;
