import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { PAGE_DOWN_CODE, PAGE_UP_CODE } from 'common/constants/general';
import { useStore } from 'common/store';
import { LayoutContextValues, NavItems } from 'common/types/store';
import LayoutContext from 'context/LayoutContext';
import { getArrayValue, getNumberValue, getStringValue } from 'utilities';
import { TabSubMenu } from './components';
import useStyles from './styles';
import { HorizontalNavigationProps, NavigationParams } from './types';

const HorizontalNavigation: FC<HorizontalNavigationProps> = props => {
  const { children } = props;

  const { navItems = [] } = useContext(LayoutContext) as unknown as LayoutContextValues;
  const store = useStore() ?? [];
  const [{ isShowLoadingProgress = false }] = store.length ? store : [{}];

  const classes = useStyles();

  const { tableSlugParam, tabSlugParam } = useParams<NavigationParams>();
  const slugParam = useMemo(() => getStringValue(tableSlugParam ?? tabSlugParam), [tabSlugParam, tableSlugParam]);

  const selectedNavItem = useMemo(() => {
    if (slugParam && navItems && !isEmpty(navItems)) {
      const result = navItems?.find(navItem => navItem.slug === slugParam);

      if (result) return result?.itemKey;
    }

    return false;
  }, [navItems, slugParam]);

  const handleKeyup = useCallback(
    event => {
      const key = event.key || event.code;
      const initiatingKey = event.ctrlKey || event.metaKey || event.altKey;

      const lastItemIndex = getNumberValue(getArrayValue(navItems)?.length) - 1;
      const currentPosition = navItems?.findIndex(navItem => navItem.slug === slugParam) ?? -1;

      if (initiatingKey) {
        if (key === PAGE_DOWN_CODE && currentPosition < lastItemIndex) {
          navItems?.[currentPosition + 1]?.callback?.();
        } else if (key === PAGE_UP_CODE && currentPosition > 0) {
          navItems?.[currentPosition - 1]?.callback?.();
        }
      }
    },
    [navItems, slugParam]
  );

  // Adding event listener for keyboard navigation
  useEffect(() => {
    if (navItems) document.addEventListener('keyup', handleKeyup);

    return () => document.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup, navItems, tableSlugParam]);

  const handleChange = (event: ChangeEvent<unknown>, selectedItem: NavItems['itemKey']): void => {
    const targetClass = (event as ChangeEvent<HTMLDivElement>)?.target?.classList;

    if (targetClass?.length && !targetClass?.contains('icon')) {
      // Execute the callback function of the selected item
      navItems?.find(navItem => navItem?.itemKey === selectedItem)?.callback?.();
    }
  };

  return (
    <Paper className={classes.root} elevation={0} id="horizontal-nav" square>
      <Grid
        alignItems="center"
        className={classes.container}
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        wrap="nowrap">
        <Grid className={classes.menuItems} item>
          {!isEmpty(navItems) && (
            <Tabs
              aria-label="tab bar"
              id="horizontal-nav-tabs"
              indicatorColor="primary"
              onChange={handleChange}
              value={selectedNavItem}>
              {navItems?.map(navItem => {
                if (!isEmpty(navItem?.subMenu)) {
                  return (
                    <TabSubMenu
                      item={navItem}
                      onChange={handleChange}
                      key={`tab-${navItem?.id}`}
                      disabled={isShowLoadingProgress}
                    />
                  );
                }

                return (
                  <Tab
                    className={clsx(classes.navItem, {
                      [classes.selected]: selectedNavItem !== navItem.itemKey,
                      [classes.hidden]: navItem?.hidden,
                    })}
                    disabled={isShowLoadingProgress ?? navItem?.disabled ?? false}
                    id={`tab-${navItem?.id}`}
                    key={`tab-${navItem?.id}`}
                    label={navItem?.title}
                    value={navItem?.itemKey}
                  />
                );
              })}
            </Tabs>
          )}
        </Grid>

        <Grid className={classes.navActions} id="horizontal-nav-actions" item>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HorizontalNavigation;
