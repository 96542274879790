import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { GridDate as GridDateBase } from 'components/Grid';
import { endOfDay } from 'utilities';

const GridDate = ({ cell, maxDate }) => {
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const maxDateEndOfDay = useMemo(() => {
    if (!maxDate) return undefined;
    return endOfDay(maxDate);
  }, [maxDate]);

  const handleOnChange = useCallback(
    date => {
      onCellsChanged([{ cell, value: date }]);
    },
    [cell, onCellsChanged]
  );

  const { value, readOnly, disabled } = cell;

  return (
    <GridDateBase
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      onCellsChanged={handleOnChange}
      maxDate={maxDateEndOfDay}
    />
  );
};

GridDate.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  maxDate: PropTypes.string,
};

export default GridDate;
