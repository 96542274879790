/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  CAP_TABLE_CELLS,
  CAP_TABLE_FIELDS,
  CAP_TABLE_TOTAL_FIELDS,
  INVESTMENT_LOAN_ROWS,
  ROWS_TO_RESET_WHEN_CHANGING_SECURITY,
  SHARES_LEDGER_ROW_NUMBER,
} from 'common/constants/fund-ownership';
import { UNISSUED_OPTIONS } from 'common/constants/securityTypes';
import { useStore } from 'common/store';
import { CustomSelect, withTooltip } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { toString } from 'utilities';
import emptyDialogData from '../data/sharesLedgerTemplate.json';
import { reverseParser } from '../utilities';

const SecurityList = ({ cell, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const [{ captableInfo }] = useStore();
  const { cells, columns, tableData, onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const { columnLegend, value: cellValue, key, readOnly } = cell;
  const securities = captableInfo?.securities || [];

  const securityList = useMemo(() => {
    if (securities) {
      return securities.filter(s => toString(s.security_type) !== toString(UNISSUED_OPTIONS));
    }
    return [];
  }, [securities]);

  const replaceCaptableValues = ({ capTableData, capTableKey, capTableFields, newSecurity }) => {
    const tmpState = { ...capTableData };
    if (newSecurity && capTableFields.includes(tmpState[capTableKey].alias)) {
      tmpState[capTableKey].value = newSecurity[tmpState[capTableKey].alias];
    }
    if (CAP_TABLE_TOTAL_FIELDS.includes(tmpState[capTableKey].alias)) {
      tmpState[capTableKey].value = tableData.capTable[tmpState[capTableKey].alias];
    }

    return tmpState;
  };

  const resetValues = newValue => {
    const tmpState = { ...cells };
    const newSecurity
      = tableData.capTable.securities.find(security => toString(security.id) === toString(newValue)) || {};

    ROWS_TO_RESET_WHEN_CHANGING_SECURITY.forEach(rowNumber => {
      const cellKey = columnLegend + rowNumber;

      if (tmpState[cellKey]) {
        // Set values to 0
        tmpState[cellKey].value = tmpState[cellKey].defaultValue;

        // Reset captable values
        const updatedCell = replaceCaptableValues({
          capTableData: tmpState,
          capTableKey: cellKey,
          capTableFields: CAP_TABLE_FIELDS,
          newSecurity,
        });

        tmpState[cellKey] = updatedCell[cellKey];

        if (INVESTMENT_LOAN_ROWS.includes(rowNumber)) {
          tmpState[cellKey].expr = '0';
        }
        // Reset shares ledger
        if (rowNumber === SHARES_LEDGER_ROW_NUMBER) {
          tmpState[cellKey].value = emptyDialogData;
        }
      }
    });

    CAP_TABLE_CELLS.forEach(cellAlias => {
      const cellKey = `captable_${cellAlias}_${columnLegend}`;
      if (tmpState[cellKey]) {
        tmpState[cellKey].value = newSecurity[cellAlias];
      }
    });

    if (newValue) {
      onCellsChanged([{ cell, value: newValue.toString() }]);
    } else if (!isEmpty(securityList)) {
      onCellsChanged([{ cell, value: securityList[0].id.toString() }]);
    }

    reverseParser({
      cells: tmpState,
      columns,
    });
  };

  const handleChange = value => {
    resetValues(value);
  };

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(securityList)) {
      securityList.forEach(security => {
        if (security.convertible_notes.length === 0) {
          tmpOptions.push({
            value: security.id,
            label: security.name,
          });
        }
      });
    }

    return tmpOptions;
  }, [securityList]);

  if (isEmpty(securityList)) {
    return <span>No securities available</span>;
  }

  if (!isEmpty(securityList)) {
    return (
      <CustomSelect
        id={`security-list-${key}`}
        value={cellValue}
        options={getOptions}
        onChange={handleChange}
        onCommit={onCommit}
        onRevert={onRevert}
        onEmptyValue="SELECT SECURITY"
        disabled={readOnly}
        disableUnderline
      />
    );
  }

  return <span>No securities available</span>;
};

SecurityList.propTypes = {
  cell: PropTypes.shape({
    columnLegend: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    key: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
  }).isRequired,
};

export default withTooltip(SecurityList);
