/* eslint-disable no-param-reassign */
import { useCallback, useState } from 'react';
import { isEmpty, isEqual, keyBy } from 'lodash';
import { OPM } from 'common/constants/allocations';
import { AllocationService } from 'services';
import { useResponse } from 'services/hooks';
import { currentISODate, toString, validateWeighting } from 'utilities';
import calculateWeightedPerShareValues from './calculateWeightedPerShareValue';

function getAndAddCell(spreadsheets, onCellsChanged) {
  return s => {
    const cellKey = `${s.security.id}-${s.col}`;
    const cell
      = spreadsheets.backsolveSheet.cells[cellKey]
      || Object.values(spreadsheets.backsolveSheet.cells).find(c => c.securityName === s.security.name && c.y === s.col);
    if (cell) {
      const value = s.value ?? 0;
      onCellsChanged(cell, value);
    }
  };
}

function checkEmptyRows(newDistribution, spreadsheets, onCellsChanged, data) {
  const newRows = newDistribution.map(dist => dist.presentValues);
  if (!isEmpty(newRows)) {
    newRows.forEach(securities => {
      if (!isEmpty(securities)) {
        securities.forEach(getAndAddCell(spreadsheets, onCellsChanged));
      }
    });

    const appliedMethodologies = data.applied_methodologies;
    const totalPresentShareValues = calculateWeightedPerShareValues(newRows, appliedMethodologies);

    data.basket.forEach((b, i) => {
      const presentValue = totalPresentShareValues.find(tpsv => Number(tpsv.securityId) === Number(b.security.id));
      const cell = spreadsheets.securitySheet.cells[`C${i + 2}`];

      onCellsChanged(cell, presentValue?.value ?? 0);
    });

    spreadsheets.securitySheet.tableData.securitiesBasket.target_value = data.target_value;
  }
}

function changeIfBacksolveSummarySheet(spreadsheets, onCellsChanged, equityValue) {
  if (spreadsheets.backsolveSummarySheet) {
    onCellsChanged(spreadsheets.backsolveSummarySheet.cells.B2, equityValue);
  }
}

const populateBasket = (currentNonZeroRows, securityList) => {
  const currentBasket = [];
  currentNonZeroRows.forEach(row => {
    const security = securityList?.find(s => toString(s.id) === toString(row.security));

    if (security) {
      currentBasket.push({
        security: {
          id: security.id,
          name: security.name,
        },
        shares: Number(row.shares),
        value: Number(row.value),
      });
    }
  });
  return currentBasket;
};

const populateAppliedMethodologies = (appliedMethodologies, maturity, volatility) => {
  const methodologies = appliedMethodologies.map(m => ({
    allocation_method: m.allocation_method,
    cap_table: Number(m.cap_table),
    weight: parseFloat(m.weight),
    maturity: Number(m.allocation_method) === Number(OPM) ? parseFloat(maturity) : 0,
    volatility: Number(m.allocation_method) === Number(OPM) ? parseFloat(volatility) : 0,
    opm_backsolve_date: m.opm_backsolve_date,
  }));

  return methodologies;
};

const getOPMValuesFromCells = opmData => {
  const { maturity: mCell, volatility: vCell, opm_backsolve_date } = keyBy(opmData, 'alias');
  const maturity = mCell?.value || 0;
  const backsolveDate = opm_backsolve_date?.value;
  const volatility = vCell?.value || 0;
  return { maturity, volatility, backsolveDate };
};

const mapValuesWithMethodology = (values, index, methodology) =>
  values?.map(v => ({ ...v, col: index + 1, allocationMethod: methodology.allocation_method })) || [];

const useCalculateEquityValue = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [scenarioEquityValue, setScenarioEquityValue] = useState(0);
  const [prevAllocationParams, setPrevAllocationParams] = useState({});
  const { processErrorResponse } = useResponse();

  const fetchScenarioEquityValue = useCallback(async data => {
    const service = new AllocationService();

    const response = await service.calculateBacksolveEquityValue(data);

    if (response) {
      const { equity_value: equityValue } = response;
      return equityValue;
    }

    return 0;
  }, []);

  const updateScenarioValues = async ({ backsolveData, exitDate, equityValue, scenarioType, backsolveDate }) => {
    const { applied_methodologies: methodologies } = backsolveData;

    const service = new AllocationService();

    const scenarioValues = await Promise.all(
      methodologies.map(async (m, index) => {
        const response = await service.postScenarioValues({
          scenarioType,
          exitDate,
          equityValue,
          captableId: m.cap_table,
          scenarioMethod: m.allocation_method,
          maturity: m.maturity,
          volatility: m.volatility,
          backsolveDate,
        });

        if (!response) return {};

        const {
          present_values: presentValues,
          aggregate_values: aggregateValues,
          future_values: futureValues,
        } = response;

        return {
          presentValues: mapValuesWithMethodology(presentValues, index, m),
          aggregateValues: mapValuesWithMethodology(aggregateValues, index, m),
          futureValues: mapValuesWithMethodology(futureValues, index, m),
        };
      })
    );

    return scenarioValues;
  };

  const calculateEquityValue = useCallback(
    async ({
      scenarioType,
      allocation,
      appliedMethodologies,
      // opmData is the opmTable cells
      opmData,
      securityRows,
      targetValue,
      prevOpmData,
      editableLedgerCells,
      prevEditableLedgerCells,
      targetValueData,
      prevTargetValueData,
      securityList,
      spreadsheets,
      onCellsChanged,
    }) => {
      const exitDate = currentISODate();
      const rows = securityRows;
      const { maturity, volatility } = getOPMValuesFromCells(opmData);

      const currentNonZeroRows = securityRows.filter(row => row.shares > 0);

      const getScenarioData = () => {
        const basket = populateBasket(currentNonZeroRows, securityList);
        const appliedMethodologiesData = populateAppliedMethodologies(appliedMethodologies, maturity, volatility);

        const requestData = {
          applied_methodologies: appliedMethodologiesData,
          target_value: Number(targetValue),
          basket,
          allocation,
        };

        return requestData;
      };

      const { maturity: prevMaturity, volatility: prevVolatility } = keyBy(prevOpmData, 'alias');

      const cellsChanged = !isEqual(editableLedgerCells, prevEditableLedgerCells);
      const targetValueChanged = !isEqual(targetValueData, prevTargetValueData);
      const maturityChanged = toString(maturity) !== toString(prevMaturity?.value);
      const volatilityChanged = toString(volatility) !== toString(prevVolatility?.value);

      const dataChanged = cellsChanged || targetValueChanged || maturityChanged || volatilityChanged;

      const data = getScenarioData();

      const backsolveDate = data?.applied_methodologies?.find(am => am.opm_backsolve_date)?.opm_backsolve_date;
      const validateData = () => {
        let isValid = true;

        const isValidWeight = validateWeighting(data.applied_methodologies);

        isValid = data.basket.length === rows.length && targetValue && allocation && isValidWeight;
        const existsOPM = data.applied_methodologies.some(am => Number(am.allocation_method) === Number(OPM));

        if (existsOPM) {
          data.applied_methodologies
            .filter(am => Number(am.allocation_method) === Number(OPM))
            .forEach(am => {
              if (!(am.maturity && am.maturity > 0 && am.volatility && am.volatility > 0)) {
                isValid = false;
              }
            });
        }

        return isValid;
      };

      const dataIsValid = validateData();

      if (dataChanged && dataIsValid) {
        let returnValue = {};
        try {
          setIsLoading(true);

          const equityValue = await fetchScenarioEquityValue(data);
          const allocationParams = {
            backsolveData: data,
            exitDate,
            backsolveDate,
            equityValue,
            scenarioType,
          };

          setScenarioEquityValue(equityValue);
          let newDistribution = [];
          if (!isEqual(allocationParams, prevAllocationParams)) {
            newDistribution = await updateScenarioValues(allocationParams);
            setPrevAllocationParams(allocationParams);
            checkEmptyRows(newDistribution, spreadsheets, onCellsChanged, data);
          }
          changeIfBacksolveSummarySheet(spreadsheets, onCellsChanged, equityValue);
          returnValue = { equityValue, newDistribution };
        } catch (error) {
          const errorMessages = JSON.parse(error.response.text);
          const [tmpMessages] = Object.values(errorMessages);

          processErrorResponse({
            error,
            defaultErrorMessage: `${tmpMessages.join('. ')}.`,
            action: 'get scenario equity value',
          });
        } finally {
          setIsLoading(false);
        }
        return returnValue;
      }
      return {};
    },
    [fetchScenarioEquityValue, prevAllocationParams, processErrorResponse]
  );
  return [calculateEquityValue, isLoading, scenarioEquityValue];
};

export default useCalculateEquityValue;
