import { TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import {
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getApproachesByType } from 'pages/ValuationsAllocation/util/getApproachesByType';
import { getGpcAndGptForDropdown } from 'pages/ValuationsAllocation/util/getGpcAndGptForDropdown';
import createDCFData from './dcf/createDCFData';
import createDCFNOLCarryoverData from './dcfNOLCarryover/createDCFNOLCarryoverData';
import createDCFSummaryData from './dcfSummary/createDCFSummaryData';
import {
  createTVTEMData,
  createTVTHMData,
  createTVTPGData,
  createTVTREMData,
  createTVTRMData,
} from './dcfTerminalValue/createTerminalValueData';
import { TVTDataProps } from './dcfTerminalValue/types';
import createDCFWACCData from './dcfWACC/createDCFWACCData';
import DCF from './DiscountCashFlow';
import { CreateDCFConfigProps } from './types';

const createDCFConfig = (props: CreateDCFConfigProps) => {
  const { approach, approaches, dcfProps, dcfAttributes, benchmarkApproach, isDisabled } = props;
  const name = getApproachTableName({ approach });

  const filteredApproaches = getApproachesByType({
    approaches,
    approachTypes: [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH],
  });

  const approachOptions = getGpcAndGptForDropdown(filteredApproaches);

  const dcfWaccSheet = createDCFWACCData({
    approach,
    dcfProps,
    dcfAttributes,
    isDisabled,
  });

  const dcfSheet = createDCFData({
    approach,
    dcfProps,
    dcfWaccSheet,
    dcfAttributes,
    isDisabled,
  });

  const dcfNolSheet = createDCFNOLCarryoverData({
    approach,
    dcfProps,
    mainTableName: dcfSheet.name,
    dcfAttributes,
    isDisabled,
  });

  const tvtProps: TVTDataProps = {
    approach,
    approaches,
    approachOptions,
    mainTableName: dcfSheet.name,
    dcfWaccSheet,
    dcfAttributes,
    benchmarkApproach,
    name,
    isDisabled,
  };

  const dcfTVTPGSheet = createTVTPGData(tvtProps);
  const dcfTVTHMSheet = createTVTHMData(tvtProps);
  const dcfTVTRMSheet = createTVTRMData(tvtProps);
  const dcfTVTEMSheet = createTVTEMData(tvtProps);
  const dcfTVTREMSheet = createTVTREMData({
    ...tvtProps,
    dcfTVTRMSheetName: dcfTVTRMSheet.name,
    dcfTVTEMSheetName: dcfTVTEMSheet.name,
  });

  const sheetMap = {
    [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: dcfTVTPGSheet,
    [TERMINAL_VALUE_OPTIONS.H_MODEL]: dcfTVTHMSheet,
    [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: dcfTVTRMSheet,
    [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: dcfTVTEMSheet,
    [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: dcfTVTREMSheet,
  };

  const dcfSummarySheet = createDCFSummaryData({
    approach,
    dcfProps,
    dcfWaccSheet,
    dcfNolSheet,
    dcfTerminalValueSheet: sheetMap[approach?.valuations_approach_dcf.terminal_value ?? ''],
    dcfSheet,
    dcfAttributes,
    isDisabled,
  });

  return {
    name,
    approach,
    spreadsheets: {
      dcf: dcfSheet,
      dcfSummary: dcfSummarySheet,
      dcfTVTPG: dcfTVTPGSheet,
      dcfTVTHM: dcfTVTHMSheet,
      dcfTVTRM: dcfTVTRMSheet,
      dcfTVTEM: dcfTVTEMSheet,
      dcfTVTREM: dcfTVTREMSheet,
      dcfWacc: dcfWaccSheet,
      dcfNol: dcfNolSheet,
    },
    enterpriseValueReference: dcfSummarySheet.name,
    value: approach?.enterprise_value,
    component: DCF,
  };
};

export default createDCFConfig;
