import { NOT_APPLICABLE } from 'common/constants/general';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  SHEET_ALIASES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { generateColumnKey, getArrayValue, getNumberValue, getStringValue } from 'utilities';
import { CreateColumnsParams, PerformanceMetricsColumn } from './types';

const { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } = SHEET_ALIASES_CONSTANTS;

const createColumns = (params: CreateColumnsParams) => {
  const { valuationsApproachGpc, financials } = params;

  const comparisons = getArrayValue(valuationsApproachGpc?.gpc_comparison);

  // LTM Revenue
  const comparisonsLTMRevenue = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ltm_revenue),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ltm_revenue),
      },
    }
  ) as PerformanceMetricsColumn;

  // LTM Revenue Growth
  const comparisonsLTMRevenueGrowth = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ltm_revenue_growth),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value:
          financials?.ltm_revenue_growth_rate === NOT_APPLICABLE
            ? financials?.ltm_revenue_growth_rate
            : getNumberValue(financials?.ltm_revenue_growth_rate),
      },
    }
  ) as PerformanceMetricsColumn;

  // NTM Revenue
  const comparisonsNTMRevenue = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: Number(current?.ntm_revenue || 0) !== 0 ? getNumberValue(current?.ntm_revenue) : NOT_APPLICABLE,
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ntm_revenue),
      },
    }
  ) as PerformanceMetricsColumn;

  // NTM Revenue Growth
  const comparisonsNTMRevenueGrowth = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value:
          Number(current?.ntm_revenue_growth || -1) !== -1
            ? getNumberValue(current?.ntm_revenue_growth)
            : NOT_APPLICABLE,
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value:
          financials?.ntm_revenue_growth_rate === NOT_APPLICABLE
            ? financials?.ntm_revenue_growth_rate
            : getNumberValue(financials?.ntm_revenue_growth_rate),
      },
    }
  ) as PerformanceMetricsColumn;

  // LTM EBITDA
  const comparisonsLTMEBITDA = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ltm_ebitda),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ltm_ebitda),
      },
    }
  ) as PerformanceMetricsColumn;

  // NTM EBITDA
  const comparisonsNTMEBITDA = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ntm_ebitda),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ntm_ebitda),
      },
    }
  ) as PerformanceMetricsColumn;

  // LTM Gross Margin
  const comparisonsLTMGrossMargin = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ltm_gross_margin),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ltm_gross_margin),
      },
    }
  ) as PerformanceMetricsColumn;

  // LTM EBITDA Margin
  const comparisonsLTMEBITDAMargin = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.ltm_ebitda_margin),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(financials?.ltm_ebitda_margin),
      },
    }
  ) as PerformanceMetricsColumn;

  // Volatility 1 year
  const comparisonsVolatility1year = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.price_volume_historical_1yr),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(valuationsApproachGpc?.one_year_volatility ?? 0),
      },
    }
  ) as PerformanceMetricsColumn;

  // Volatility 2 years
  const comparisonsVolatility2years = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.price_volume_historical_2yr),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(valuationsApproachGpc?.two_year_volatility ?? 0),
      },
    }
  ) as PerformanceMetricsColumn;

  // Volatility 5 years
  const comparisonsVolatility5years = comparisons?.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.price_volume_historical_5yr),
      },
    }),
    // Set Company value
    {
      [PERFORMANCE_METRICS_SPREADSHEET_COMPANY]: {
        value: getNumberValue(valuationsApproachGpc?.five_year_volatility ?? 0),
        9: valuationsApproachGpc?.one_year_volatility_percentile_selection,
        10: valuationsApproachGpc?.two_year_volatility_percentile_selection,
        11: valuationsApproachGpc?.five_year_volatility_percentile_selection,
      },
    }
  ) as PerformanceMetricsColumn;

  return [
    comparisonsLTMRevenue,
    comparisonsLTMRevenueGrowth,
    comparisonsNTMRevenue,
    comparisonsNTMRevenueGrowth,
    comparisonsLTMEBITDA,
    comparisonsNTMEBITDA,
    comparisonsLTMGrossMargin,
    comparisonsLTMEBITDAMargin,
    comparisonsVolatility1year,
    comparisonsVolatility2years,
    comparisonsVolatility5years,
  ];
};

export default createColumns;
