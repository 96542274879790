import { CreateConstantSheetParams } from './types';
import { Cells, Rows } from '../../../../../common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from '../../../../../components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getNumberValue } from '../../../../../utilities';
import { VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS } from '../../../common/constants/valuationSummary/sheetConfigs';
import { ValuationSummaryParserParams } from '../config/customParser/types';
import { customValidations } from '../config/customValidations';

const createConstantSheet = (params: CreateConstantSheetParams) => {
  const { otherFinancialStatements, isUniformCurrency, companyExchangeRate } = params;

  const getValue = (value: number | string | undefined | null) => {
    const numberValue = getNumberValue(value);
    return isUniformCurrency ? numberValue : numberValue * getNumberValue(companyExchangeRate ?? 1);
  };

  const rowConfig: Rows[] = otherFinancialStatements.flatMap(financialStatement => [
    {
      alias: `ltm_revenue_growth_${financialStatement.id}`,
      value: getValue(financialStatement.ltm?.revenue_growth_rate),
    },
    {
      alias: `ntm_revenue_growth_${financialStatement.id}`,
      value: getValue(financialStatement.ntm?.revenue_growth_rate),
    },
    {
      alias: `ltm_cash_${financialStatement.id}`,
      value: getValue(financialStatement.ltm?.balance_sheet?.total_cash_equivalents),
    },
    {
      alias: `ltm_debt_${financialStatement.id}`,
      value: getValue(financialStatement.ltm?.balance_sheet?.total_debt),
    },
  ]) as unknown as Rows[];

  const parser = (parserParams: ValuationSummaryParserParams) => {
    let cells = {} as Cells;
    parserParams.rowConfig.forEach((row, rowIndex) => {
      const rowNumber = rowIndex + 1;
      const columnLegend = 'A';
      const cellKey = columnLegend + rowNumber;
      cells = {
        ...cells,
        [cellKey]: {
          ...row,
          key: cellKey,
          customKey: row.alias,
        },
      };
    });
    return cells;
  };

  return new SpreadsheetConfig({
    parser,
    rowConfig,
    tableData: [],
    totalParser: undefined,
    columns: [{}],
    allowConfirmAndDeleteColumn: false,
    allowCopyColumn: false,
    allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
    alwaysDisplayLegend: false,
    currencyFormatter: false,
    customValidations: customValidations as unknown as SpreadsheetConfig['customValidations'],
    format: undefined,
    name: 'constants',
    // page: VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
    showPreviousColsDivider: false,
    showTitlesColumn: true,
    showToolbar: false,
    showTotalColumn: false,
    tableTerms: VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
    unitsFormatter: false,
  });
};

export default createConstantSheet;
