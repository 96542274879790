import React from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { GridButtonWithTooltipProps } from './types';

const GridButtonWithTooltip = (props: GridButtonWithTooltipProps) => {
  const { buttonText, tooltipText, onClick, disabled, className } = props;
  return (
    <Grid item>
      <div>
        <Tooltip arrow title={tooltipText}>
          <span>
            <Button disabled={disabled} onClick={onClick} className={className}>
              {buttonText}
            </Button>
          </span>
        </Tooltip>
      </div>
    </Grid>
  );
};

export default GridButtonWithTooltip;
