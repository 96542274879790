import { METRICS } from 'pages/Valuations/approaches/backsolveApproach/constants';
import { ENTERPRISE_VALUE_OPTION } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { SPECIFIED_MULTIPLE } from 'pages/ValuationsAllocation/common/constants/futureExit';
import {
  VALUATIONS_APPROACH_BACKSOLVE_PROPERTY,
  VALUATIONS_APPROACH_DCF_PROPERTY,
  VALUATIONS_APPROACH_EV_PROPERTY,
  VALUATIONS_APPROACH_FUTURE_EXIT_PROPERTY,
  VALUATIONS_APPROACH_GPC_PROPERTY,
  VALUATIONS_APPROACH_GPT_PROPERTY,
  VALUATIONS_APPROACH_SSV_PROPERTY,
  VALUATIONS_APPROACH_TYPES_PROPERTIES_MAP,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { findFirstBenchmarkApproach } from 'pages/ValuationsAllocation/hooks/useCreateValuation/util';
import { generateDCFFinancialPeriods } from 'pages/ValuationsAllocation/util/generateDCFFinancialPeriods';
import { getObjectValue, getStringValue } from 'utilities';
import { GetGenericApproachTypeObjectParams } from './types';
import { MULTIPLE_OPTIONS, TERMINAL_VALUE_OPTIONS } from '../../../Valuations/util/constants';
import { normalizeShareValues } from '../../approaches/SpecifiedShareValues/utils';
import { SecurityShareValue } from '../../approaches/SpecifiedShareValues/utils/types';
import { publicCompsOrTransactionsExists } from '../publicCompsOrTransactionsExists';

const BASKET = {
  security: '',
  shares: '',
  value: '',
  per_share: '',
};

export const EMPTY_BASKET = {
  basket: [BASKET],
  target_value: undefined,
};

const getGenericApproachTypeObject = (params: GetGenericApproachTypeObjectParams) => {
  const { approach, approaches, financialStatementPeriods, capTableList } = params;
  const { name } = getObjectValue(approach);
  const defaultToOtherInDCF = !publicCompsOrTransactionsExists(approaches);

  const hasBenchmarkApproach = findFirstBenchmarkApproach(approaches);
  const shouldBeBenchmarkApproach = hasBenchmarkApproach === null;

  const defaultCapTable = capTableList?.find(capTable => capTable?.is_primary);

  const approachTypesPropertiesMap = new Map([
    [
      VALUATIONS_APPROACH_FUTURE_EXIT_PROPERTY,
      {
        dilution_percentage: null,
        discount_rate: null,
        exit_date: '',
        financials_metric_value: 0,
        less_debt: null,
        plus_cash: null,
        present_enterprise_value: 0,
        referenced_approach_multiple: SPECIFIED_MULTIPLE,
        referenced_approach_multiple_value: 0,
        cap_table: defaultCapTable?.id ?? null,
        allocation_method: ALLOCATION_SCENARIO_METHOD_CSE,
      },
    ],
    [
      VALUATIONS_APPROACH_BACKSOLVE_PROPERTY,
      {
        adjust_for_market: false,
        adjustment_type_selection: ENTERPRISE_VALUE_OPTION,
        applied_methodologies: [
          {
            allocation_method: ALLOCATION_SCENARIO_METHOD_WATERFALL.toString(),
            cap_table: defaultCapTable?.id,
            present_share_values: [],
            weight: 1,
          },
        ],
        as_of_date: '',
        cap_table: defaultCapTable?.id ?? null,
        metric: METRICS[0],
        securities_basket: {
          basket: [
            {
              ...BASKET,
            },
          ],
          target_value: undefined,
        },
      },
    ],
    [
      VALUATIONS_APPROACH_GPC_PROPERTY,
      {
        gpc_comparison: [],
        is_benchmark: shouldBeBenchmarkApproach,
        ltm_ebitda_selected_multiple: null,
        ltm_ebitda_weighting: null,
        ltm_revenue_selected_multiple: null,
        ltm_revenue_weighting: null,
        multiple_basis: ENTERPRISE_VALUE_OPTION,
        ntm_ebitda_selected_multiple: null,
        ntm_ebitda_weighting: null,
        ntm_revenue_selected_multiple: null,
        ntm_revenue_weighting: null,
        percentile_selection_a: 75,
        percentile_selection_b: 25,
        reference_for_volatility: null,
      },
    ],
    [
      VALUATIONS_APPROACH_GPT_PROPERTY,
      {
        gpt_transactions: [],
        is_benchmark: shouldBeBenchmarkApproach,
        ltm_ebitda_selected_multiple: null,
        ltm_ebitda_selection: null,
        ltm_ebitda_weighting: null,
        ltm_revenue_selected_multiple: null,
        ltm_revenue_selection: null,
        ltm_revenue_weighting: null,
        percentile_selection_a: 75,
        percentile_selection_b: 25,
      },
    ],
    [
      VALUATIONS_APPROACH_DCF_PROPERTY,
      {
        balance_sheet_ltm_cash: null,
        dcf_financial_period: generateDCFFinancialPeriods(financialStatementPeriods),
        high_growth_rate: null,
        long_term_growth_rate: null,
        nol_ebit_post_tax_cut: null,
        nol_ebit_pre_tax_cut: null,
        nol_present_value_savings: null,
        present_value_of_terminal_value: null,
        terminal_value: TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH,
        wacc_cost_of_debt: 0.0,
        wacc_cost_of_equity: 0.0,
        wacc_invested_capital_debt: 0.0,
        wacc_invested_capital_equity: 0.0,
        wacc: 0.0,
        years_of_high_growth: null,
        // Once validations are in place maybe pull these out and set them back to being null
        ebitda_gpc_multiple_selection: defaultToOtherInDCF ? MULTIPLE_OPTIONS.OTHER : MULTIPLE_OPTIONS.MEAN,
        ebitda_gpc_multiple: null,
        market_value: null,
        revenue_gpc_multiple_selection: defaultToOtherInDCF ? MULTIPLE_OPTIONS.OTHER : MULTIPLE_OPTIONS.MEAN,
        revenue_gpc_multiple: null,
      },
    ],
    [
      VALUATIONS_APPROACH_EV_PROPERTY,
      {
        enterprise_value: 0,
        equity_value: null,
        valuation_notes: '',
      },
    ],
    [
      VALUATIONS_APPROACH_SSV_PROPERTY,
      {
        cap_table: defaultCapTable?.id ?? null,
        enterprise_value: 0,
        equity_value: null,
        share_values: normalizeShareValues({
          share_values: defaultCapTable?.securities as SecurityShareValue[],
        }),
      },
    ],
  ]);

  const approachProperty = getStringValue(
    VALUATIONS_APPROACH_TYPES_PROPERTIES_MAP.get(getStringValue(approach?.approach_type))
  );

  const objectValue = approachTypesPropertiesMap.get(approachProperty);

  return {
    [approachProperty]: {
      ...objectValue,
      name,
    },
  };
};

export default getGenericApproachTypeObject;
