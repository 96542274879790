import { toString } from 'utilities';

const getCellValue = cell => parseFloat(cell?.value ? cell.value : 0);

function getRelatedUpdates(relatedCells, cell, weightToSubtract, MAX_PERCENTAGE) {
  return relatedCells
    .filter(relatedCell => relatedCell.key !== cell.key)
    .map(linkedCell => {
      const value
        = Math.round(
          // Number.Epsilon should prevent weird stuff from happening during division
          (getCellValue(linkedCell) - weightToSubtract + Number.EPSILON) * MAX_PERCENTAGE
        ) / MAX_PERCENTAGE;
      return {
        cell: linkedCell,
        value: value < 0 ? 0 : value,
      };
    });
}

const percentageLinkedCellUpdate = ({ cell, prevCells, cellsUpdates, percentageCells, MAX_PERCENTAGE = 1 }) => {
  if (!prevCells) return;
  const value = getCellValue(cell);
  if (getCellValue(prevCells[cell.key]) !== value && value >= 0 && value <= MAX_PERCENTAGE) {
    // 1. find all percentage cells that are not invalid
    // 2. Calculate the total percentage to see if it exceeds 100%
    let totalPercentage = percentageCells.reduce((total, nextCell) => getCellValue(nextCell) + total, 0);
    // 3. If the totalPercentage exceeds 100, recalculate the other weights
    if (totalPercentage > MAX_PERCENTAGE) {
      // 4. Calculate the extra percentage, and divide among other percentage cells
      const extraPercentage = totalPercentage - MAX_PERCENTAGE;
      const otherCells = percentageCells.filter(c => c.value);
      const otherCellLength = otherCells.length - 1;
      const percentageToSubtract = extraPercentage / otherCellLength;
      const updates = getRelatedUpdates(otherCells, cell, percentageToSubtract, MAX_PERCENTAGE);
      // 5. if there is any remaining weight, subtract it from the cells until it is gone,
      // or no cell value can be reduced
      totalPercentage = updates.reduce((total, update) => update.value + total, value);
      const remainingPercentage = totalPercentage - MAX_PERCENTAGE;
      updates.reduce((leftover, update) => {
        // we first subtract as much leftover as possible from the update.value
        const updateDiff = leftover - update.value;
        let newValue = update.value - leftover;
        newValue = newValue < 0 ? 0 : newValue;
        cellsUpdates.push({
          cell: update.cell,
          value: toString(newValue),
        });
        return updateDiff < 0 ? 0 : updateDiff;
      }, remainingPercentage);
    }
  }
};

export default percentageLinkedCellUpdate;
