/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isArray, isEmpty, isUndefined, size, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import { ConfirmUnsavedChanges } from 'components/Spreadsheet/components';
import { DialogContext } from 'context';
import UnsavedChanges from 'context/UnsavedChanges';
import { useTableValidation } from 'services/hooks';
import { compareChanges, mergeCells, onCellsChanged, removeClassName } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import getCancelDialogOptions from 'utilities/getCancelDialogOptions';
import { CellReferenceBar, ContentColumns, TitlesColumn, TotalsColumn } from './components';
import RowIndicator from './components/RowIndicator';
import FeaturedSpreadsheetContext from './context/FeaturedSpreadsheetContext';

const useStyles = makeStyles({
  relative: {
    position: 'relative',
  },
});

const FeaturedSpreadsheet = ({
  className,
  columns,
  dbColumns,
  tableTerms,
  cells,
  totalsCells,
  updateTotalsCells,
  tableData,
  rowConfig,
  customTitles,
  rowGroups,
  toggleRows,
  updateCells,
  afterCellChanged,
  setRowGroups,
  parser,
  totalParser,
  conditions,
  validations,
  disabled,
  hasColTitle,
  deleteColumn,
  allowCloneColumn,
  allowConfirmAndDeleteColumn,
  customDeleteConfirmation,
  showTotalColumn,
  cloneColumn,
  children,
  allowReorderColumns,
  reverseParser,
  setColumns,
  showToolbar,
  referencedValues,
  setCollapsibleColumns,
  collapsibleColumns,
  deleteRowFn,
  ignoreCurrencyChangeNumber,
  matchCurrencyPadding,
  showPreviousColsDivider,
  colTitleRow,
  onTitleCellsChanges,
  setTitleCells,
  validatedTitleCells,
  initialUnits,
  page,
  currencyFormatter,
  unitsFormatter,
  pageFieldAttributes,
  format: sharedFormat,
  formatDispatch: sharedFormatDispatch,
  displayRowIndicator = true,
  displayColumnIndicator = true,
  allowCopyColumn = false,
  allowDeleteColumn = false,
  allowCopyRows,
  allowAddMultipleRows = false,
  addMultipleRows,
  allowAddMultipleColumns = false,
  addMultipleColumns,
  allowAddSingleRow = false,
  addSingleRow,
  allowDeleteRow = false,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState();
  const [grid, setGrid] = useState();
  const [sideColumnsWidth, setSideColumnsWidth] = useState();
  const [activeCell, setActiveCell] = useState();
  const [toolbarWidth, setToolbarWidth] = useState();
  const [selectedCell, setSelectedCell] = useState();

  const { clearSelectedFlag, setClearSelected } = useDataSheetDialogActions();

  const [tableFormat, tableFormatDispatch] = useFormat({
    page,
    sharedFormat,
    units: initialUnits,
  });

  const tableRef = useRef();
  const titlesColumnRef = useRef();
  const totalsColumnRef = useRef();
  const contentColumnRef = useRef();

  const { action, setAction } = useContext(UnsavedChanges);
  const { validateCells } = useTableValidation();

  const totalColumns = useMemo(() => (columns ? columns.length : 0), [columns]);
  const alphabet = alphabetGenerator([], totalColumns);

  const dialogContext = useContext(DialogContext);

  const format = useMemo(() => sharedFormat || tableFormat, [sharedFormat, tableFormat]);
  const formatDispatch = useMemo(() => sharedFormatDispatch || tableFormatDispatch, []);

  const isActiveCellDefined = useMemo(
    () =>
      // IMPORTANT: hardcoding 'false' to have the hidden legends feature on hold
      /* !isUndefined(activeCell) && !isEmpty(activeCell) */
      false,
    [activeCell]
  );

  const handleDeleteColumn = (columnIndex, cb) => {
    if (allowConfirmAndDeleteColumn && !customDeleteConfirmation) {
      const { showDialog } = dialogContext;
      const ContentComponent = <ConfirmationDelete itemName={tableTerms.columnName} />;

      showDialog(
        getCancelDialogOptions(
          () => ContentComponent,
          () => deleteColumn(columnIndex)
        )
      );
    } else {
      deleteColumn(columnIndex, cb);
    }
  };

  const applyConditions = async state => {
    const tmpState = { ...state };
    if (conditions) {
      const tmpCells = Object.entries(state).map(([, cell]) => cell);
      tmpCells.forEach(cell => {
        conditions({
          cell,
          tableData,
          cells: tmpState,
          // eslint-disable-next-line no-use-before-define
          onCellsChanged: computeCellsChanges,
        });
      });
    }
    return tmpState;
  };

  const applyParser = async updatedColumns => {
    const parsedCells = await parser({
      columns: updatedColumns,
      rowConfig,
      tableData,
    });
    const enhancedCells = await applyConditions(parsedCells);
    updateCells(enhancedCells);
  };

  const applyExpressions = async (changes, state) => {
    const computedCells = await onCellsChanged(changes, state);

    return computedCells;
  };

  const applyValidations = async (changes, state) => {
    const tmpState = { ...state };

    // Prepare data to be validated
    const cellsToValidate = changes.map(change => state[change.cell.key]);

    // Validate cells
    const validationsData = validateCells({
      cellsToValidate,
      customValidations: validations,
      tableData,
      parsedColumns: tmpState,
    });

    if (validationsData) {
      const { validatedCells } = validationsData;

      // Update state
      validatedCells.forEach(cell => {
        tmpState[cell.key] = cell;
      });
    }

    return tmpState;
  };

  /* this can be modified/inverted in case we want to have by default the legends hidden and
  present if we're displaying the cell reference bar  */
  const getClassnamesForLegends = (classesString, isToolbarVisible) =>
    isToolbarVisible ? `${classesString} hidden-legend` : classesString;

  const generateGrid = rowConfig => {
    if (columns) {
      const legendRow = [];
      const sortedColumns = sortBy(columns, ['order']);

      for (let colIndex = 0; colIndex < totalColumns; colIndex += 1) {
        const colLegend = alphabet[colIndex];
        const column = sortedColumns[colIndex];
        const columnId = column.id || 0;
        const columnRef = cells[colLegend + 1] ? cells[colLegend + 1].columnRef : '';

        const legendCell = {
          readOnly: true,
          className: getClassnamesForLegends('legend col-legend', isActiveCellDefined),
          value: colLegend,
          columnId,
          colLegend,
          columnRef,
          isLegend: true,
          disableEvents: true,
          isChildWithDivider: column.isChildWithDivider,
          isParentWithDivider: column.isParentWithDivider,
          isParent: column.isParent,
          parentColumn: column.parentColumn,
        };

        legendRow.push(legendCell);
      }

      const updatedRows = [legendRow];
      // each row is an object that stores configuration for a row in the grid/table
      rowConfig.forEach((row, rowIndex) => {
        const tableRow = [];

        const isColTitle = rowIndex === colTitleRow;
        // totalColumns is memoized in the top and cells is a prop passed from CapTable
        for (let colIndex = 0; colIndex < totalColumns; colIndex += 1) {
          const colLegend = alphabet[colIndex];
          const cellKey = colLegend + (rowIndex + 1);
          let cell;
          // ignoreRowCopy allows us to optionally ignore adding default props to a given cell
          if (row.ignoreRowCopy) {
            cell = {
              readOnly: true,
              ...(cells[cellKey] ? cells[cellKey] : {}),
            };
          } else {
            cell = {
              readOnly: true,
              ...row,
              ...(cells[cellKey] ? cells[cellKey] : {}),
            };
          }

          cell.currency = format?.currency;
          cell.ignoreCurrencyChangeNumber = ignoreCurrencyChangeNumber;

          if (cell.component) {
            const newProps = { cell, disabled };
            cell.component = React.cloneElement(cell.component, newProps);
          }

          if (hasColTitle && isColTitle) {
            cell.className = cell.className ? `${cell.className} table-header` : 'table-header';
          }

          if (rowIndex === 0 && (currencyFormatter || unitsFormatter)) {
            cell.className = cell.className ? `${cell.className} formatter-cell` : 'formatter-cell';
          }

          if (cell.hidden) cell.value = null;

          if (disabled) {
            cell.readOnly = true;
            cell.className = removeClassName(cell.className, 'read-only--white');
          }

          if (row.ignoreRowCopy && row.isClassNameRequired) {
            const { className } = row;
            cell.className = cell.className ? `${cell.className} ${className}` : className;
          }

          tableRow.push(cell);
        }
        updatedRows.push(tableRow);
      });

      mergeCells(updatedRows);
      setGrid(updatedRows);
    }
  };

  const enableUnsavedChanges = () => {
    setAction({
      wrapper: ConfirmationDialog,
      content: ConfirmUnsavedChanges,
    });
  };

  const checkChanges = async cells => {
    if (
      isArray(columns) // valid columns
      && !action // ignore if has change
      && !disabled // ignore if all data is disabled
    ) {
      const initialTableCells = await parser({
        columns,
        rowConfig,
        tableData,
      });

      if (initialTableCells) {
        const hasChanges = compareChanges(initialTableCells, cells);

        if (hasChanges) {
          enableUnsavedChanges();
        } else {
          setAction(false);
        }
      }
    }
  };

  // Compute cell formulas
  const computeCellsChanges = async (changes, updatedCells) => {
    let tmpChanges = [...changes];
    let tmpState = { ...(updatedCells || cells) };

    if (afterCellChanged) {
      tmpChanges = afterCellChanged(tmpChanges, tmpState, rowConfig, tableData);
    }

    // Apply expressions
    tmpState = await applyExpressions(tmpChanges, tmpState);

    // Apply conditions
    tmpState = await applyConditions(tmpState);

    // Apply validations
    tmpState = await applyValidations(tmpChanges, tmpState);

    // Update state
    updateCells(tmpState);

    await checkChanges(tmpState);

    if (toggleRows) {
      toggleRows(tmpState);
    }
  };

  const allCellsHasBeenGenerated = () => {
    if (!isEmpty(cells) && !isEmpty(columns) && !isEmpty(rows)) {
      const totalCells = size(cells);
      const shouldExistMoreThan = size(columns) * size(rows);
      return totalCells >= shouldExistMoreThan;
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(cells) && rows) {
      generateGrid(rows);
    }
  }, [format, isActiveCellDefined]);

  useEffect(() => {
    if (columns && columns.length > 0 && parser) {
      applyParser(columns).catch(e => {
        throw new Error(e);
      });
    }
  }, [columns, tableData?.calculations]);

  useEffect(() => {
    if (isArray(rowConfig)) {
      const visibleRows = rowConfig.filter(({ isVisible }) => isVisible !== false);
      setRows(visibleRows);
    }
  }, [rowConfig]);

  useEffect(() => {
    if (!disabled && dbColumns && columns) {
      // In the financials page, only parent columns have IDs until it is saved
      const columnsLength = columns.filter(c => c.id || (!c.isParent && !c.isVirtualColumn)).length;
      const dbColumnsLength = dbColumns.filter(dbc => !dbc.is_hidden).length;
      if (dbColumnsLength !== columnsLength) {
        enableUnsavedChanges();
      } else {
        setAction(false);
      }
    }
  }, [columns, dbColumns]);

  useEffect(() => {
    if (!isEmpty(cells) && rows) {
      generateGrid(rows);

      if (!isEmpty(columns) && showTotalColumn && allCellsHasBeenGenerated()) {
        const parsedTotalsCells = totalParser({
          rows,
          columns,
          cells,
        });
        updateTotalsCells(parsedTotalsCells);
      }
    } else {
      setGrid(null);
    }
  }, [cells, rows, rowGroups, collapsibleColumns, disabled]);

  useEffect(() => {
    const contentColumns = contentColumnRef?.current;
    if (contentColumns) {
      setToolbarWidth(sideColumnsWidth + contentColumns.offsetWidth);
    }
  }, [sideColumnsWidth, collapsibleColumns]);

  useLayoutEffect(() => {
    if (titlesColumnRef.current && totalsColumnRef.current) {
      const titlesWidth = titlesColumnRef.current.offsetWidth;
      const totalsWidth = totalsColumnRef.current.offsetWidth;
      setSideColumnsWidth(titlesWidth + totalsWidth);
    }
  }, [titlesColumnRef.current, totalsColumnRef.current, grid]);

  if (isUndefined(columns) || (!isEmpty(columns) && !grid)) return <GridSkeleton />;

  return (
    <div
      data-testid={tableTerms.tableSlug}
      id={`${tableTerms.tableSlug}-spreadsheet-table`}
      className={classes.relative}>
      {children}
      {!isEmpty(columns) && !isEmpty(grid) && (
        <FeaturedSpreadsheetContext.Provider
          value={{
            rows,
            columns,
            cells,
            totalsCells,
            updateCells,
            updateTotalsCells,
            tableTerms,
            tableData,
            rowGroups,
            setRowGroups,
            titlesColumnRef,
            totalsColumnRef,
            onCellsChanged: computeCellsChanges,
            setColumns,
            allowReorderColumns,
            reverseParser,
            disabled,
            activeCell,
            setActiveCell,
            deleteRowFn,
            setCollapsibleColumns,
            collapsibleColumns,
            ignoreCurrencyChangeNumber,
            showPreviousColsDivider,
            onTitleCellsChanges,
            setTitleCells,
            validatedTitleCells,
            initialUnits,
            format,
            formatDispatch,
            page,
            getClassnamesForLegends,
            clearSelectedFlag,
            setClearSelected,
            displayLegend: true,
            pageFieldAttributes,
            selectedCell,
            setSelectedCell,
            allowCloneColumn,
            cloneColumn,
            allowConfirmAndDeleteColumn,
            displayRowIndicator,
            displayColumnIndicator,
            allowCopyColumn,
            allowDeleteColumn,
            handleDeleteColumn,
            allowCopyRows,
            allowAddMultipleRows,
            addMultipleRows,
            allowAddMultipleColumns,
            addMultipleColumns,
            allowAddSingleRow,
            addSingleRow,
            allowDeleteRow,
          }}>
          {showToolbar && !isEmpty(activeCell) && (
            <div style={{ width: toolbarWidth }}>
              <CellReferenceBar
                id={tableTerms.tableSlug}
                cell={activeCell}
                referencedValues={referencedValues}
                matchCurrencyPadding={matchCurrencyPadding}
              />
            </div>
          )}
          <Box id={`spreadsheet-table__${tableTerms.tableSlug}`} ref={tableRef} display="flex">
            <div ref={titlesColumnRef} id="spreadsheet-table__title-columns" className="titles-columns-container">
              <RowIndicator tableRef={tableRef} />
              <TitlesColumn
                hasColTitle={hasColTitle}
                className={className}
                rows={customTitles || rows}
                currencyFormatter={currencyFormatter}
                unitsFormatter={unitsFormatter}
                isToolbarVisible={isActiveCellDefined}
              />
            </div>
            <div
              className={classes.relative}
              style={{
                maxWidth: `calc(100% - ${sideColumnsWidth}px)`,
              }}
              id="spreadsheet-table__content-columns"
              ref={contentColumnRef}>
              <ContentColumns data={grid} className={className} tableSlug={tableTerms.tableSlug} />
            </div>
            <div ref={totalsColumnRef} id="spreadsheet-table__total-column" className="total-columns-container">
              {showTotalColumn && (
                <TotalsColumn
                  className={className}
                  totalParser={totalParser}
                  currency={format.currency}
                  isToolbarVisible={isActiveCellDefined}
                  currencyFormatter={currencyFormatter}
                  unitsFormatter={unitsFormatter}
                />
              )}
            </div>
          </Box>
        </FeaturedSpreadsheetContext.Provider>
      )}
    </div>
  );
};

FeaturedSpreadsheet.defaultProps = {
  className: '',
  rowConfig: [],
  tableTerms: {
    tableName: 'Table',
    columnName: 'Column',
    pluralColumnName: 'Columns',
  },
  referencedValues: {},
  hasColTitle: true,
  showTotalColumn: true,
  disabled: false,
  allowConfirmAndDeleteColumn: true,
  allowCloneColumn: false,
  customDeleteConfirmation: false,
  initialUnits: `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
  ignoreCurrencyChangeNumber: 0,
  showPreviousColsDivider: false,
  colTitleRow: 0,
};

FeaturedSpreadsheet.propTypes = {
  className: PropTypes.string,
  dbColumns: PropTypes.array,
  columns: PropTypes.array,
  rowConfig: PropTypes.array,
  customTitles: PropTypes.array,
  tableData: PropTypes.object,
  cells: PropTypes.object,
  totalsCells: PropTypes.object,
  rowGroups: PropTypes.object,
  tableTerms: PropTypes.object,
  updateCells: PropTypes.func,
  afterCellChanged: PropTypes.func,
  updateTotalsCells: PropTypes.func,
  parser: PropTypes.func,
  totalParser: PropTypes.func,
  conditions: PropTypes.func,
  deleteColumn: PropTypes.func,
  cloneColumn: PropTypes.func,
  validations: PropTypes.func,
  toggleRows: PropTypes.func,
  setRowGroups: PropTypes.func,
  showTotalColumn: PropTypes.bool,
  disabled: PropTypes.bool,
  allowCloneColumn: PropTypes.bool,
  allowConfirmAndDeleteColumn: PropTypes.bool,
  hasColTitle: PropTypes.bool,
  customDeleteConfirmation: PropTypes.bool,
  children: PropTypes.any,
  setColumns: PropTypes.func,
  allowReorderColumns: PropTypes.bool,
  reverseParser: PropTypes.func,
  showToolbar: PropTypes.bool,
  referencedValues: PropTypes.object,
  collapsibleColumns: PropTypes.object,
  setCollapsibleColumns: PropTypes.func,
  deleteRowFn: PropTypes.func,
  initialUnits: PropTypes.string,
  ignoreCurrencyChangeNumber: PropTypes.number,
  matchCurrencyPadding: PropTypes.bool,
  showPreviousColsDivider: PropTypes.bool,
  colTitleRow: PropTypes.number,
  onTitleCellsChanges: PropTypes.func,
  setTitleCells: PropTypes.func,
  validatedTitleCells: PropTypes.object,
  page: PropTypes.string,
  currencyFormatter: PropTypes.bool,
  unitsFormatter: PropTypes.bool,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
  pageFieldAttributes: PropTypes.object,
  displayRowIndicator: PropTypes.bool,
  displayColumnIndicator: PropTypes.bool,
  allowCopyColumn: PropTypes.bool,
  allowDeleteColumn: PropTypes.bool,
  allowCopyRows: PropTypes.bool,
  allowAddMultipleRows: PropTypes.bool,
  addMultipleRows: PropTypes.func,
  allowAddMultipleColumns: PropTypes.bool,
  addMultipleColumns: PropTypes.func,
  allowAddSingleRow: PropTypes.bool,
  addSingleRow: PropTypes.func,
  allowDeleteRow: PropTypes.bool,
};

export default FeaturedSpreadsheet;
