import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ADD_SUBFOLDER_LABEL, CURRENT_DOCUMENTS_LABEL } from 'common/constants/documents';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocumentsDragAndDrop } from 'services/hooks';
import theme from 'theme';
import { shortDate } from 'utilities';
import AddFolderDialog from './AddFolderDialog/AddFolderDialog';
import DocumentsWithoutFolder from './DocumentsWithoutFolder';
import Folders from './Folders/Folders';
import useFolderValidations from './Folders/useFolderValidations';
import NumberOfDocsAndSubfolders from './NumberOfDocsAndSubfolders';

const useStyles = makeStyles({
  accordion: {
    '&& .MuiAccordion-root.Mui-expanded:last-child': {
      width: '100%',
    },
    '&& .MuiAccordion-rounded:first-child': {
      width: '100%',
    },
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
      borderRadius: '0rem',
    },
  },
  accordionSummary: {
    borderBottom: `0.18rem solid ${theme.palette.primary[700]}`,
  },
  selectedMDChip: {
    backgroundColor: `${theme.palette.cyan}`,
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
    marginLeft: '1rem',
    '&& .MuiTypography-root.MuiTypography-body1': {
      color: `${theme.palette.primary[700]}`,
      fontSize: '0.625rem',
    },
  },
});
const CurrentDocuments = ({
  expandedStates,
  accordionClickHandler,
  currentDate,
  selectedMeasurementDate,
  displayOnly,
  currentDocumentsProcessed,
}) => {
  const classes = useStyles();
  const { isLoading } = useDocumentsContext();
  const validations = useFolderValidations();
  const { handleDragOver, handleDragLeave, handleDrop } = useDocumentsDragAndDrop({
    validations,
    selectedMeasurementDateId: selectedMeasurementDate,
  });
  const [openFolderDialog, setOpenFolderDialog] = useState(false);

  const onCloseFolderDialog = () => {
    setOpenFolderDialog(false);
  };

  function sumFilesInFolder(documentsInFolders) {
    let sum = 0;
    if (!isEmpty(documentsInFolders)) {
      documentsInFolders.forEach(folder => {
        sum += folder.files.length;
        if (folder.subFolders.length > 0) {
          sum += sumFilesInFolder(folder.subFolders);
        }
      });
    }
    return sum;
  }

  return (
    <Accordion
      className={classes.accordion}
      id="documents"
      expanded={expandedStates?.currentDocuments || false}
      TransitionProps={{ unmountOnExit: true }}
      onChange={(event, expanded) => accordionClickHandler(event, expanded, 'currentDocuments')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="accordion-content"
        id="accordion-header"
        className={classes.accordionSummary}>
        <Typography style={{ color: `${theme.palette.primary[700]}`, fontWeight: 'bold' }}>
          Measurement Date {shortDate(currentDate?.date)}
        </Typography>
        <NumberOfDocsAndSubfolders
          numberOfDocuments={
            (currentDocumentsProcessed.filesWithoutFolders?.length ?? 0)
            + sumFilesInFolder(currentDocumentsProcessed?.folders ?? [])
          }
          numberOfFolders={currentDocumentsProcessed.folders?.length ?? 0}
        />
        <div className={classes.selectedMDChip}>
          <Typography>{CURRENT_DOCUMENTS_LABEL}</Typography>
        </div>
      </AccordionSummary>
      {isLoading && <LinearProgress color="primary" />}
      <div
        className="droppable"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={event => handleDrop(event, null)}>
        <AccordionDetails style={{ padding: '0rem' }}>
          <DocumentsWithoutFolder
            isCurrentData
            displayOnly={displayOnly}
            documents={currentDocumentsProcessed.filesWithoutFolders}
            documentsInFolders={currentDocumentsProcessed.folders}
            measurementDate={currentDocumentsProcessed.measurementDate}
          />
        </AccordionDetails>
        {!isEmpty(currentDocumentsProcessed.folders) && (
          <AccordionDetails style={{ padding: '0 0 0 2rem' }}>
            <div id="folders-content">
              <Backdrop id="folder-backdrop" open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Folders
                documentId={currentDocumentsProcessed.documentId}
                documentsFolders={currentDocumentsProcessed.folders}
                isCurrentData
                selectedMeasurementDate={selectedMeasurementDate}
                measurementDate={currentDocumentsProcessed.measurementDate}
              />
            </div>
          </AccordionDetails>
        )}
      </div>
      {openFolderDialog && (
        <AddFolderDialog
          open={openFolderDialog}
          onClose={onCloseFolderDialog}
          title={ADD_SUBFOLDER_LABEL}
          currentFolders={currentDocumentsProcessed.folders}
        />
      )}
    </Accordion>
  );
};

CurrentDocuments.propTypes = {
  expandedStates: PropTypes.shape({
    currentDocuments: PropTypes.bool,
  }),
  accordionClickHandler: PropTypes.func,
  currentDate: PropTypes.shape({
    date: PropTypes.string,
  }),
  selectedMeasurementDate: PropTypes.number,
  displayOnly: PropTypes.string,
  currentDocumentsProcessed: PropTypes.shape({
    filesWithoutFolders: PropTypes.arrayOf(PropTypes.shape({})),
    folders: PropTypes.arrayOf(PropTypes.shape({})),
    measurementDate: PropTypes.shape({}),
    documentId: PropTypes.number,
  }),
};

export default CurrentDocuments;
