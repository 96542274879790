import React from 'react';
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { firmSummaryUrl } from 'common/config/urls';
import { getInitials } from 'utilities';

const useStyles = makeStyles(theme => ({
  avatar: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.5),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.white,
  },
}));

const FirmOption = ({ slug, name }) => {
  const classes = useStyles();
  return (
    <Link to={firmSummaryUrl(slug)} data-testid={slug}>
      <ListItem button>
        <ListItemIcon>
          <Avatar className={classes.avatar}>{getInitials({ names: name })}</Avatar>
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

FirmOption.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
};

export default FirmOption;
