import React from 'react';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  FilteredMultipleSelect,
  FilteredMultipleViewer,
  MetricValueSelect,
  MetricValueViewer,
} from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/components/';
import {
  FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE,
  LTM_REVENUE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
  SPECIFIED_MULTIPLE,
} from 'pages/ValuationsAllocation/common/constants/futureExit/';
import {
  FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY,
  FE_FUTURE_EQUITY_SPREADSHEET_LESS_DEBT_CUSTOM_KEY,
  FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE_CUSTOM_KEY,
  FE_FUTURE_EQUITY_SPREADSHEET_PLUS_CASH_CUSTOM_KEY,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { formatDate, getObjectValue, getSlugValue } from 'utilities';
import { RowConfigParams } from './types';

const {
  FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE,
  FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
  FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
  FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_CASH,
  FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE,
  FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
} = SHEET_ALIASES_CONSTANTS;

const { FE_FUTURE_EQUITY_VALUE_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const rowConfig = (params: RowConfigParams): Rows => {
  const { isDisabled, valuationsApproachFutureExitApproach, measurementDate } = params;
  const { financials_metric: financialsMetric, referenced_approach_multiple: referencedApproachMultiple }
    = getObjectValue(valuationsApproachFutureExitApproach);
  const formattedDate = formatDate({ date: measurementDate?.date ?? new Date() });

  const applyBlueText = !isDisabled ? 'blue-text' : '';

  return [
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_HEADER_TITLE
      )} division-bottom-only`,
      gridType: 'string',
      ignoreRowCopy: true,
      largeHeader: true,
      readOnly: true,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.FUTURE_EQUITY_VALUE_TITLE.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
      customKey: FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE)}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.EXIT_DATE.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
      customKey: FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE_CUSTOM_KEY,
      className: `${applyBlueText} ${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE
      )}`,
      gridType: 'number',
      ignoreRowCopy: true,
      readOnly: true,
      forceComponent: true,
      isEditableTitleCell: !isDisabled,
      useScalarSpreadsheetCell: true,
      dropdown: true,
      isRequired: !isDisabled,
      value: financialsMetric ?? LTM_REVENUE,
      valueViewer: (props: any) => <MetricValueViewer {...props} />,
      dataEditor: (props: any) => <MetricValueSelect {...props} />,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.VALUATION_APPROACH.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
      className: `${applyBlueText} ${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: referencedApproachMultiple !== SPECIFIED_MULTIPLE || isDisabled,
      forceComponent: true,
      isEditableTitleCell: !isDisabled,
      useScalarSpreadsheetCell: true,
      dropdown: true,
      isRequired: !isDisabled,
      value: referencedApproachMultiple,
      valueViewer: (props: any) => <FilteredMultipleViewer {...props} />,
      dataEditor: (props: any) => <FilteredMultipleSelect {...props} />,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.ENTERPRISE_VALUE.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_CASH,
      customKey: FE_FUTURE_EQUITY_SPREADSHEET_PLUS_CASH_CUSTOM_KEY,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_CASH
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.PLUS_CASH.TITLE} ${formattedDate}`,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
      customKey: FE_FUTURE_EQUITY_SPREADSHEET_LESS_DEBT_CUSTOM_KEY,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_DEBT
      )} subtitle-indented`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: `(${FE_FUTURE_EQUITY_VALUE_SPREADSHEET.LESS_DEBT.TITLE} ${formattedDate})`,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE,
      className: `border-bottom ${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.FUTURE_EQUITY_VALUE.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
      className: `${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: isDisabled,
      isRequired: !isDisabled,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.DILUTION_FUTURE_INVESTMENTS.TITLE,
    } as Row,
    {
      alias: FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
      customKey: FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY,
      className: `bold-row ${FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE}-${getSlugValue(
        FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS
      )}`,
      gridType: 'string',
      ignoreRowCopy: true,
      readOnly: true,
      value: FE_FUTURE_EQUITY_VALUE_SPREADSHEET.FUTURE_VALUE_CURRENT_SHAREHOLDERS.TITLE,
    } as Row,
  ];
};

export default rowConfig;
