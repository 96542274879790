/* eslint-disable import/no-unresolved,no-param-reassign */
import { sortBy } from 'lodash';
import uuid from 'react-uuid';
import { SECURITY_REFERENCE_ALIAS } from 'common/constants/cap-table';
import { parseDatabaseValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import rowConfig from '../data/rowConfig';

const reverseParser = ({ cells, columns, fieldAttributes = {}, allowEmptyValues = true }) => {
  const sortedColumns = sortBy(columns, ['order']);
  const localConfig = rowConfig(sortedColumns);
  const alphabet = alphabetGenerator([], sortedColumns.length);

  function parseValue(cell, key, column, type, value, defaultValue, format, gridType, decimalPlaces) {
    if (cell.alias && cells[key]) {
      const cellAllowEmptyValues = cell.allowEmptyValues || allowEmptyValues;
      column.columnRef = column.security_ref;
      column[cell.alias] = parseDatabaseValue({
        type,
        value,
        defaultValue,
        format,
        allowEmptyValues: cellAllowEmptyValues,
        gridType,
        decimalPlaces,
      });
    }
  }

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    column.order = columnIndex;
    // replace updated values
    localConfig.forEach((cell, cellIndex) => {
      if (!cell.totalKey) {
        const key = columnLegend + (cellIndex + 1);
        const defaultValue = cell.defaultValue || null;
        const type = cell.dbType || null;
        const format = cell.format || null;
        let value = cells[key] ? cells[key].value : defaultValue;
        const gridType = cell?.gridType;

        if (cell.alias === SECURITY_REFERENCE_ALIAS) {
          value = value || uuid();
        }
        const { alias: rowAlias } = cell;
        const decimalPlaces = fieldAttributes[rowAlias]?.decimal_places;

        parseValue(cell, key, column, type, value, defaultValue, format, gridType, decimalPlaces);
      }
    });
  });

  return sortedColumns;
};
export default reverseParser;
