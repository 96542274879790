import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  PRIMARY_CAP_TABLE_BY_CMD_DEFAULT_ERROR_MESSAGE,
  PRIMARY_CAP_TABLE_BY_CMD_QUERY_KEY,
} from 'common/constants/services/capTables';
import { CaptableService } from 'services';
import { throwError } from 'utilities';
import {
  GetPrimaryCapTableByCMDHook,
  GetPrimaryCapTableByCMDParams,
  GetPrimaryCapTableByCMDResponse,
  UseGetPrimaryCapTableByCMD,
} from './types';

const getPrimaryCapTableByCMD = async (params: GetPrimaryCapTableByCMDParams) => {
  const { companyMeasurementDateId } = params;

  if (isNil(companyMeasurementDateId)) return null;

  const capTableService = new CaptableService();

  try {
    const primaryCapTable = (await capTableService.getPrimaryCaptableByCompanyDate(
      companyMeasurementDateId
    )) as GetPrimaryCapTableByCMDResponse;

    if (!primaryCapTable) return throwError(PRIMARY_CAP_TABLE_BY_CMD_DEFAULT_ERROR_MESSAGE);

    return primaryCapTable;
  } catch (error) {
    return throwError(PRIMARY_CAP_TABLE_BY_CMD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetPrimaryCapTableByCMD: UseGetPrimaryCapTableByCMD = params => {
  const { companyMeasurementDate, shouldQueryAutomatically = true } = params;

  const companyMeasurementDateId = companyMeasurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetPrimaryCapTableByCMDHook['data'], Error>(
    {
      enabled: !isNil(companyMeasurementDateId) && shouldQueryAutomatically,
      queryFn: () => getPrimaryCapTableByCMD({ companyMeasurementDateId }),
      queryKey: [PRIMARY_CAP_TABLE_BY_CMD_QUERY_KEY, companyMeasurementDateId],
      refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
    }
  );

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetPrimaryCapTableByCMD;
