import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { customParser } from './config/customParser';
import { customReverseParser } from './config/customReverseParser';
import { getRowConfig } from './config/rowConfig';
import { CapTableSelectionConfig } from './types';
import { SSV_TABLE_NAMES } from '../../constants';

const createCapTableSelectionConfig = ({
  approach,
  ssvAttributes,
  capTableList,
  isDisabled,
}: CapTableSelectionConfig) => {
  const contentColumns = [[], approach.valuations_approach_ssv];

  const name = getApproachTableName({ approach, tableSuffix: SSV_TABLE_NAMES.CAP_TABLE_SELECTION });

  return new SpreadsheetConfig({
    name,
    currencyFormatter: false,
    unitsFormatter: false,
    columns: contentColumns,
    rowConfig: getRowConfig({ capTableList, isDisabled }),
    format: undefined,
    totalParser: undefined,
    tableData: { isDisabled },
    parser: customParser,
    reverseParser: customReverseParser as unknown as SpreadsheetConfig['reverseParser'],
    initialObj: approach as unknown as SpreadsheetConfig['initialObj'],
    allowConfirmAndDeleteColumn: false,
    allowDeleteColumn: false,
    handleDeleteColumn: undefined,
    fieldAttributes: ssvAttributes,
    displayRowIndicator: false,
    displayColumnIndicator: false,
    showTitlesColumn: false,
  });
};

export default createCapTableSelectionConfig;
