import {
  DCF_TVT_DISCOUNT_PERIODS_ROW_NUMBER,
  DCF_TVT_ESTIMATED_EXIT_ROW_NUMBER,
  DCF_TVT_PV_OF_TERMINAL_VALUE_ROW_NUMBER,
  DCF_TVT_TERMINAL_YEAR_ROW_NUMBER,
  DCF_TVT_WACC_ROW_NUMBER,
  REVENUE_APPROACH_SELECTION,
  REVENUE_GPC_MULTIPLE,
  REVENUE_GPC_MULTIPLE_SELECTION,
  VALUES_OPTIONS,
} from 'pages/Valuations/util/constants';
import {
  DISCOUNT_PERIODS_CONFIG,
  ESTIMATED_EXIT_VALUE_CONFIG,
  MULTIPLE_CONFIG,
  PV_OF_TERMINAL_VALUE_CONFIG_2,
  SELECT_APPROACH_CONFIG,
  SELECT_MULTIPLE_CONFIG,
  TERMINAL_VALUE_CONFIG,
  TERMINAL_YEAR_REVENUE_CONFIG,
  WACC_CONFIG,
} from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcfTerminalValue/util/rowConfigConstants';
import { EBITDARevenueParams } from 'pages/ValuationsAllocation/util/updateDCFOptions/types';

export default ({
  mainTableName: mainTableReference,
  dcfWaccSheet: dcfWacc,
  percentileSelections,
  benchmarkType,
  options = VALUES_OPTIONS,
  approachOptions,
  isDisabled,
}: EBITDARevenueParams) => [
  TERMINAL_VALUE_CONFIG(isDisabled),
  TERMINAL_YEAR_REVENUE_CONFIG(mainTableReference),
  SELECT_APPROACH_CONFIG(REVENUE_APPROACH_SELECTION, approachOptions, isDisabled),
  SELECT_MULTIPLE_CONFIG(REVENUE_GPC_MULTIPLE_SELECTION, dcfWacc, percentileSelections, isDisabled),
  MULTIPLE_CONFIG(REVENUE_GPC_MULTIPLE, options, benchmarkType, isDisabled),
  ESTIMATED_EXIT_VALUE_CONFIG(DCF_TVT_TERMINAL_YEAR_ROW_NUMBER, DCF_TVT_ESTIMATED_EXIT_ROW_NUMBER),
  WACC_CONFIG(dcfWacc),
  DISCOUNT_PERIODS_CONFIG(mainTableReference),
  PV_OF_TERMINAL_VALUE_CONFIG_2(
    DCF_TVT_WACC_ROW_NUMBER,
    DCF_TVT_DISCOUNT_PERIODS_ROW_NUMBER,
    DCF_TVT_PV_OF_TERMINAL_VALUE_ROW_NUMBER
  ),
];
