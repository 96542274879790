import { CompanyList } from 'common/types/company';
import { Fund } from 'common/types/fund';

export type RefreshCapitalIqDialogProps = {
  open: boolean;
  onClose: () => void;
};
export const stateStatusMap = {
  complete: 'success',
  failed: 'error',
  pending: 'loading',
  timeout: 'loading',
  polling: 'loading',
  initial: 'loading',
};
export interface CompanyIdMap {
  [key: number]: CompanyList;
}
export interface FundIdMap {
  [key: number]: Fund;
}
