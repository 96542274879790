import { isNil } from 'lodash';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { getNumberValue, getObjectValue } from 'utilities';
import { CreateColumns, ModifiedPresentEquityColumnCellData } from './types';

const {
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const createColumns: CreateColumns = params => {
  const { valuationsApproachFutureExitApproach, lessCashTotal, plusDebtTotal, measurementDate } = params;
  const { discount_rate: discountRate } = getObjectValue(valuationsApproachFutureExitApproach);

  // Modified Present Equity Value
  const modifiedPresentEquityValue = {
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE]: {
      value: measurementDate,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE]: {
      value: null,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT]: {
      value: null,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE]: {
      value: !isNil(discountRate) ? getNumberValue(discountRate) : null,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS]: {
      value: null,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE]: {
      value: null,
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT]: {
      value: getNumberValue(plusDebtTotal),
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH]: {
      value: getNumberValue(lessCashTotal),
    } as ModifiedPresentEquityColumnCellData,
    [FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE]: {
      value: null,
    } as ModifiedPresentEquityColumnCellData,
  };

  return [modifiedPresentEquityValue];
};

export default createColumns;
