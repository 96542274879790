import { oneDecimalPercentFormat } from 'common/formats/formats';
import { VOLATILITY_CUSTOM_KEY } from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/constants';
import {
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getNumberValue } from 'utilities';
import { HandleVolatilityParams } from './types';

const handleVolatility = (params: HandleVolatilityParams) => {
  const { cell, isApproachWithOPM, scenarioMethod, scenarioType, relatedApproachOPMTableName } = params;

  cell.dbDecimalPlaces = 4;
  cell.dbType = 'number';
  cell.format = oneDecimalPercentFormat;
  cell.greaterThan = 0;
  cell.gridType = 'percentage';
  cell.isRequired = true;

  if (
    [ALLOCATION_SCENARIO_TYPE_BACKSOLVE, ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT].includes(getNumberValue(scenarioType))
  ) {
    cell.readOnly = true;
    if ([ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(scenarioMethod)) && isApproachWithOPM) {
      cell.expr = `=${relatedApproachOPMTableName}.${VOLATILITY_CUSTOM_KEY}`;
    }
  }

  if (![ALLOCATION_SCENARIO_METHOD_OPM].includes(getNumberValue(scenarioMethod)) && !isApproachWithOPM) {
    cell.hidden = true;
    cell.isRequired = false;
    cell.readOnly = true;
  }
};

export default handleVolatility;
