import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BALANCE_SHEET_NAME,
  BALANCE_SHEET_PROP,
  INCOME_STATEMENT_PROP,
  INCOME_STATEMENT_SHEET_NAME,
} from 'common/constants/financials';
import { useStore } from 'common/store';
import { useVersionsFilter } from 'pages/Financials/utilities/index';
import loadFinancialStatementData from 'pages/Financials/utilities/loadFinancialStatementData';
import { useDocuments, useReadFieldAttributes } from 'services/hooks';
import { useGetCompanyFiscalYearData } from 'services/hooks/company';
import { useGetCompanyMeasurementDateByMD } from 'services/hooks/companyMeasurementDates';
import {
  useCreateFinancialStatementVersion,
  useListByMeasurementPeriod,
  useRetrieveFinancialStatement,
} from 'services/hooks/financialStatement';
import { useGetMeasurementDatesByCompanyId } from 'services/hooks/firm';
import {
  BALANCE_SHEET_MODEL_NAME,
  FINANCIALS_PAGE,
  INCOME_STATEMENT_MODEL_NAME,
  KPI_DATA_MODEL_NAME,
} from 'services/hooks/useReadFieldAttributes/constants';
import { dbShortDate, getYearFromIsoFormattedDate } from 'utilities';

// this should load data necessary to render the financials tables, but does not directly relate to
// specific UI surrounding the page
const useLoadFinancials = ({
  selectedMeasurementDate,
  filesToSave,
  setFilesToSave,
  notes,
  saveNotes,
  notesHasChanged,
}) => {
  const [retrieve] = useRetrieveFinancialStatement();
  const [getCompanyMeasurementDate] = useGetCompanyMeasurementDateByMD();
  const [getFinancialsVersions] = useListByMeasurementPeriod();
  const [measurementDates, getMeasurementDates] = useGetMeasurementDatesByCompanyId();
  const getFiscalYearData = useGetCompanyFiscalYearData();
  const [fiscalYearData, setFiscalYearData] = useState();
  const [createdVersion, createVersion] = useCreateFinancialStatementVersion();
  const [{ companyInfo }] = useStore();
  const { addReferenceForExistingDocuments } = useDocuments();
  const fieldAttributesMap = useReadFieldAttributes(FINANCIALS_PAGE);
  const [financialStatement, setFinancialStatement] = useState();
  const [financialVersions, setFinancialVersions] = useState();
  const [companyMeasurementDate, setCompanyMeasurementDate] = useState();
  const [periods, setPeriods] = useState();
  const [collapsibleColumns, setCollapsibleColumns] = useState();
  const [incomeStatement, setIncomeStatement] = useState({});
  const [balanceSheets, setBalanceSheets] = useState({});
  const [kpi, setKpi] = useState({});
  const [additionalHistoricalYears, setAdditionalHistoricalYears] = useState([]);
  const [showAdditionalHistoricalYears, setShowAdditionalHistoricalYears] = useState(false);
  const measurementDateRef = useRef();
  const financialStatementId = financialStatement?.id;

  const fieldAttributes = useMemo(
    () => ({
      incomeStatementAttrs: fieldAttributesMap.get(INCOME_STATEMENT_MODEL_NAME),
      balanceSheetAttrs: fieldAttributesMap.get(BALANCE_SHEET_MODEL_NAME),
      kpiAttrs: fieldAttributesMap.get(KPI_DATA_MODEL_NAME),
    }),
    [fieldAttributesMap]
  );

  const { selectedVersion } = useVersionsFilter({
    financialVersions,
  });

  const history = useHistory();
  const location = useLocation();

  const getVersions = useCallback(async () => {
    try {
      const tmpVersions = await getFinancialsVersions(selectedMeasurementDate?.id);
      setFinancialVersions(tmpVersions);
    } catch (error) {
      throw new Error(error);
    }
  }, [getFinancialsVersions, selectedMeasurementDate]);

  useEffect(() => {
    if (companyInfo?.id) {
      getMeasurementDates(companyInfo.id);
    }
  }, [companyInfo.id, getMeasurementDates]);

  useEffect(
    () => () => {
      setPeriods(undefined);
    },
    [selectedVersion, companyInfo.id]
  );

  useEffect(() => {
    const loadMDBasedData = async () => {
      if (companyMeasurementDate?.id !== selectedMeasurementDate?.cmd_id || isUndefined(companyMeasurementDate?.id)) {
        const cmdPromise = getCompanyMeasurementDate(selectedMeasurementDate?.id);
        const fyDataPromise = getFiscalYearData(selectedMeasurementDate?.cmd_id);
        const tmpVersionsPromise = getFinancialsVersions(selectedMeasurementDate?.id);
        try {
          const [cmd, fyData, tmpVersions] = await Promise.all([cmdPromise, fyDataPromise, tmpVersionsPromise]);

          measurementDateRef.current = selectedMeasurementDate;
          setCompanyMeasurementDate(cmd);
          setFiscalYearData(fyData);
          setFinancialVersions(tmpVersions);

          if (isEmpty(tmpVersions)) {
            setFinancialStatement({
              financial_statement_periods: [],
              company_measurement_date: cmd.id,
            });
          } else {
            setFinancialStatement(undefined);
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    };

    if (selectedMeasurementDate?.id) {
      loadMDBasedData();
    }
  }, [
    companyMeasurementDate,
    companyInfo.id,
    getCompanyMeasurementDate,
    getFinancialsVersions,
    getFiscalYearData,
    selectedMeasurementDate,
  ]);

  useEffect(() => {
    if (selectedVersion?.id) {
      const fetchStatement = async () => {
        try {
          const tmpFinancialStatement = await retrieve(selectedVersion.id);
          setFinancialStatement(tmpFinancialStatement);
        } catch (error) {
          throw new Error(error);
        }
      };
      fetchStatement();
    }
  }, [retrieve, selectedVersion]);

  useEffect(() => {
    if (financialStatement) {
      setShowAdditionalHistoricalYears(financialStatement.show_additional_historical_years || false);
    }
  }, [financialStatement, setShowAdditionalHistoricalYears]);

  const notShowingQuarters = useMemo(() => {
    let areWeNotShowingQuarters = true;
    if (collapsibleColumns) {
      Object.keys(collapsibleColumns).forEach(key => {
        // Check if property value is false
        if (collapsibleColumns[key] === false) {
          areWeNotShowingQuarters = false;
        }
      });
    }
    return areWeNotShowingQuarters;
  }, [collapsibleColumns]);

  const currentFiscalYearEnd = useMemo(() => {
    if (selectedMeasurementDate && fiscalYearData) {
      const md = selectedMeasurementDate;

      const measurementDate = md?.date;
      const currentPeriod = moment(measurementDate);
      const currentFiscalYear = moment(measurementDate);

      currentFiscalYear.set({
        month: fiscalYearData.fiscal_year_end.end_month - 1,
        date: fiscalYearData.fiscal_year_end.end_day,
      });
      if (currentPeriod.isAfter(currentFiscalYear)) {
        currentFiscalYear.add(1, 'years');
      }
      return currentFiscalYear;
    }
  }, [fiscalYearData, selectedMeasurementDate]);

  // update the additional historical years
  useEffect(() => {
    if (
      financialStatement?.financial_statement_periods
      && selectedMeasurementDate?.id
      && selectedMeasurementDate?.cmd_id === financialStatement?.company_measurement_date
      && fiscalYearData
      && currentFiscalYearEnd
    ) {
      const historicalYearsProps = {
        setAdditionalHistoricalYears,
        showAdditionalHistoricalYears: financialStatement.show_additional_historical_years || false,
      };
      loadFinancialStatementData(
        selectedMeasurementDate,
        financialStatement,
        fiscalYearData,
        setPeriods,
        setCollapsibleColumns,
        historicalYearsProps,
        currentFiscalYearEnd
      );
    }
  }, [
    financialStatement,
    fiscalYearData,
    selectedMeasurementDate,
    setPeriods,
    setCollapsibleColumns,
    currentFiscalYearEnd,
  ]);

  useEffect(() => {
    if (createdVersion) {
      getVersions();

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('version', createdVersion?.slug);

      const newSearch = searchParams.toString();

      history.push({
        pathname: location.pathname,
        search: `?${newSearch}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdVersion, getVersions, history, location.pathname]);

  const changePeriodDate = useCallback(
    (periodId, value, tableName) => {
      const periodToChangeIndex = (periods || []).findIndex(({ id }) => id === periodId);
      const financialStatementPeriodIndex = financialStatement?.financial_statement_periods.findIndex(
        ({ id }) => id === periodId
      );

      const propToAccess = {
        [INCOME_STATEMENT_SHEET_NAME]: INCOME_STATEMENT_PROP,
        [BALANCE_SHEET_NAME]: BALANCE_SHEET_PROP,
      };
      const propAccessed = propToAccess[tableName];
      // change the date in the right table, not in the period.

      if (periods && periodToChangeIndex && financialStatementPeriodIndex) {
        const newDate = dbShortDate(value);
        const newYear = getYearFromIsoFormattedDate(newDate);
        /* account for periods not having balance_sheet and income_statement
      if they're not persisted yet */
        periods[periodToChangeIndex] = {
          ...periods[periodToChangeIndex],
          year: newYear,
          [propAccessed]: {
            ...periods[periodToChangeIndex][propAccessed],
            reported_statement_date: newDate,
          },
        };

        if (financialStatement && financialStatementPeriodIndex !== -1) {
          const periodToUpdate = financialStatement.financial_statement_periods[financialStatementPeriodIndex];
          periodToUpdate[propAccessed] = {
            ...periodToUpdate[propAccessed],
            reported_statement_date: newDate,
          };
        }
        return periods;
      }
    },
    [financialStatement, periods]
  );

  const filesParams = useMemo(
    () => ({
      addReferenceForExistingDocuments,
      filesToSave,
      financialStatementId,
      setFilesToSave,
    }),
    [addReferenceForExistingDocuments, filesToSave, financialStatementId, setFilesToSave]
  );

  const notesParams = useMemo(
    () => ({
      notes,
      notesHasChanged,
      saveNotes,
    }),
    [notes, notesHasChanged, saveNotes]
  );

  return {
    periods,
    changePeriodDate,
    collapsibleColumns,
    setCollapsibleColumns,
    financialStatement,
    setFinancialStatement,
    incomeStatement,
    setIncomeStatement,
    balanceSheets,
    setBalanceSheets,
    kpi,
    setKpi,
    selectedVersion,
    filesParams,
    notesParams,
    companyMeasurementDate,
    fieldAttributes,
    measurementDates,
    createdVersion,
    createVersion,
    fiscalYearData,
    setPeriods,
    setShowAdditionalHistoricalYears,
    additionalHistoricalYears,
    setAdditionalHistoricalYears,
    showAdditionalHistoricalYears,
    getVersions,
    notShowingQuarters,
    currentFiscalYearEnd,
  };
};

export default useLoadFinancials;
