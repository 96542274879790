import { CustomReverseParserParams } from './types';
import { CAP_TABLE_ALIAS } from '../../../../constants';

const customReverseParser = ({ cells, initialObj }: CustomReverseParserParams) => {
  const tmpApproach = { ...initialObj.valuations_approach_ssv };
  const capTableSelectionCell = Object.values(cells).find(cell => cell.alias === CAP_TABLE_ALIAS);
  tmpApproach.cap_table = capTableSelectionCell?.value as number;

  return {
    ...initialObj,
    enterprise_value: tmpApproach.enterprise_value,
    valuations_approach_ssv: tmpApproach,
  };
};

export default customReverseParser;
