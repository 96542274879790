/* eslint-disable no-param-reassign */
import moment from 'moment';
import { ensureNoMoreThanTwoDecimalPlaces } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/utilities';
import { ISO_DATE_FORMAT } from 'utilities';

const reverseParser = ({ cells, tableData }) => {
  const { approach, companyName, asOfDate, companyAsOfDatePerformanceMetrics } = tableData;
  if (cells && approach.valuations_approach_gpc.use_multiple_premium_discount) {
    const companyPerformanceMetricRowNumber = cells.companyPerformanceMetric.key.replace(/\D/g, '');
    const tmpCustomCompanyPerfMetrics = {
      id: companyAsOfDatePerformanceMetrics?.[0]?.id,
      as_of_date: moment(asOfDate).format(ISO_DATE_FORMAT),
      name: companyName,
      ltm_revenue: ensureNoMoreThanTwoDecimalPlaces(cells[`A${companyPerformanceMetricRowNumber}`]?.value),
      ltm_revenue_growth: ensureNoMoreThanTwoDecimalPlaces(cells[`B${companyPerformanceMetricRowNumber}`]?.value),
      ntm_revenue: ensureNoMoreThanTwoDecimalPlaces(cells[`C${companyPerformanceMetricRowNumber}`]?.value),
      ntm_revenue_growth: ensureNoMoreThanTwoDecimalPlaces(cells[`D${companyPerformanceMetricRowNumber}`]?.value),
      ltm_ebitda: ensureNoMoreThanTwoDecimalPlaces(cells[`E${companyPerformanceMetricRowNumber}`]?.value),
      ntm_ebitda: ensureNoMoreThanTwoDecimalPlaces(cells[`F${companyPerformanceMetricRowNumber}`]?.value),
      gross_margin: ensureNoMoreThanTwoDecimalPlaces(cells[`G${companyPerformanceMetricRowNumber}`]?.value),
      ebitda_margin: ensureNoMoreThanTwoDecimalPlaces(cells[`H${companyPerformanceMetricRowNumber}`]?.value),
    };
    approach.valuations_approach_gpc.company_as_of_date_performance_metrics = [tmpCustomCompanyPerfMetrics];
  }
};

export default reverseParser;
