import { Cells } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { ExternalValuation } from 'pages/ValuationsAllocation/approaches/ExternalValuation';
import {
  EXTERNAL_VALUATION_KEY,
  SHEET_CONFIG_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/externalValuation';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getBooleanValue, getObjectValue } from 'utilities';
import { createColumns } from './createColumns';
import { customAfterCellsChanged } from './customAfterCellsChanged';
import { Change } from './customAfterCellsChanged/types';
import { customParser, ExternalValuationParserParams } from './customParser';
import { customReverseParser, ExternalValuationReverseParserParams } from './customReverseParser';
import { ExternalValuationCellCustomData } from './customReverseParser/types';
import { customRowConfig } from './customRowConfig';
import { CreateExternalValuationConfigParams } from './types';

const { EXTERNAL_VALUATION_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const createExternalValuationConfiguration = (params: CreateExternalValuationConfigParams) => {
  const { approach, externalValueAttrs, isDisabled } = params;
  const { valuations_approach_ev: externalValuationApproach } = getObjectValue(approach);
  const { is_enterprise_value_calculated: isEnterpriseValueCalculated = false }
    = getObjectValue(externalValuationApproach);

  const name = getApproachTableName({ approach });

  const getColumns = () => createColumns({ externalValuationApproach });

  const columns = getColumns();

  const rowConfig = customRowConfig({ isDisabled });

  const parser = (parserParams: ExternalValuationParserParams) =>
    customParser({
      ...parserParams,
      isEnterpriseValueCalculated: getBooleanValue(isEnterpriseValueCalculated),
    });

  const reverseParser = (reverseParserParams: ExternalValuationReverseParserParams) =>
    customReverseParser({
      ...reverseParserParams,
      approach,
    });

  const afterCellsChanged = (initialChanges: Array<Change>, cells: Cells<ExternalValuationCellCustomData>) =>
    customAfterCellsChanged({
      initialChanges,
      cells,
      approach,
    });

  const sheet = new SpreadsheetConfig({
    afterCellChanged: afterCellsChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    allowConfirmAndDeleteColumn: false,
    allowCopyColumn: false,
    allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
    alwaysDisplayLegend: false,
    columns,
    currencyFormatter: true,
    fieldAttributes: externalValueAttrs,
    format: undefined,
    name,
    page: EXTERNAL_VALUATION_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
    parser,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    rowConfig,
    showPreviousColsDivider: false,
    showTitlesColumn: true,
    showToolbar: true,
    showTotalColumn: false,
    tableData: {},
    tableTerms: EXTERNAL_VALUATION_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
    totalParser: undefined,
    unitsFormatter: true,
    getColumns,
  });

  approach.isDisabled = isDisabled;

  return {
    approach,
    component: ExternalValuation,
    enterpriseValueReference: name,
    name,
    spreadsheets: {
      [EXTERNAL_VALUATION_KEY]: sheet,
    },
    value: approach.enterprise_value,
  };
};

export default createExternalValuationConfiguration;
