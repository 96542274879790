import React, { FC, useContext, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { Dialog } from 'components/Dialogs';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { EffectiveTaxRateChipProps } from './types';
import EffectiveTaxRateLedger from '../EffectiveTaxRateLedger';

const EffectiveTaxRateChip: FC<EffectiveTaxRateChipProps> = props => {
  const { cashTaxRate } = props;
  const { measurementDate, setCashTaxRate, companyMeasurementDate } = useContext(ValuationContext);
  const [showLedger, setShowLedger] = useState(false);
  const handleClick = () => {
    setShowLedger(true);
  };

  const onClose = () => {
    setShowLedger(false);
  };

  return (
    <>
      <Chip
        className="effective-tax-rate-chip"
        label={`${(Number(cashTaxRate?.effective_tax_rate ?? 0) * 100).toFixed(1)} %`}
        clickable
        color={'secondary'}
        size={'small'}
        onClick={handleClick}
        variant="outlined"
      />
      {showLedger && (
        <Dialog onClose={onClose} open={showLedger} hideTitle={true}>
          <EffectiveTaxRateLedger
            closeDialog={onClose}
            cashTaxRate={cashTaxRate}
            measurementDate={measurementDate}
            setCashTaxRate={setCashTaxRate ?? (() => {})}
            companyMeasurementDate={companyMeasurementDate}
          />
        </Dialog>
      )}
    </>
  );
};

export default EffectiveTaxRateChip;
