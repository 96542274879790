import { useMemo } from 'react';
import { FinancialStatementsBasic } from 'common/types/financials';
import { getArrayValue, getNumberValue } from 'utilities';

const useSeparateFinancialStatements = (financialStatementsList?: FinancialStatementsBasic[]) => {
  const otherFinancialStatements = useMemo(
    () => [...getArrayValue(financialStatementsList)],
    [financialStatementsList]
  );

  const financialStatementsIndex = useMemo(
    () => financialStatementsList?.findIndex(financial => financial.is_a_version === false),
    [financialStatementsList]
  );

  const currentFinancialStatements = useMemo(
    () => otherFinancialStatements.splice(getNumberValue(financialStatementsIndex), 1),
    [financialStatementsIndex, otherFinancialStatements]
  );

  return {
    financialStatements: currentFinancialStatements?.[0] as Required<FinancialStatementsBasic>,
    otherFinancialStatements,
  };
};

export default useSeparateFinancialStatements;
