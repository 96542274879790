import { largeCurrencyFormat } from 'common/formats/formats';
import { ValuationSummaryCellCustomData } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import { SHEET_TITLES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getObjectValue } from 'utilities';
import { HandleEnterpriseValueColumnParams } from './types';

const { isCapTableEnterpriseValueColumn } = SHEET_TITLES_CONSTANTS;

const handleEnterpriseValueColumn = (params: HandleEnterpriseValueColumnParams) => {
  const {
    cell,
    colNumber,
    companyExchangeRate,
    financialsCurrency,
    isUniformCurrency,
    panelId,
    previousKey,
    shouldNotDisplayEnterpriseValue,
  } = params;

  cell.readOnly = true;

  const cellData = {
    ...getObjectValue(cell.data),
    isEnterpriseValue: true,
    panelId,
  } as ValuationSummaryCellCustomData;

  if (isUniformCurrency) {
    cell.expr = `=${cell.value}.${VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY}`;
    cell.format = largeCurrencyFormat;
    cell.gridType = 'number';

    // Custom Data for Enterprise Value (Cap Table - Uniform Currency)
    cell.data = cellData;
  } else {
    cell.expr = shouldNotDisplayEnterpriseValue ? '' : `=${cell.value}.${VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY}`;
    cell.format = largeCurrencyFormat;
    cell.gridType = 'number';
    cell.hidden = shouldNotDisplayEnterpriseValue;
    cell.staticCurrency = {
      code: financialsCurrency,
      exchangeRate: 1,
    };

    // Handle Cap Table Enterprise Value
    if (isCapTableEnterpriseValueColumn({ colNumber, isUniformCurrency })) {
      // For Cap Table Approaches, the Enterprise Value should be displayed in the Enterprise Value (not Financials) column
      cell.expr = shouldNotDisplayEnterpriseValue
        ? `=${cell.value}.${VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY}`
        : `=${previousKey} * ${companyExchangeRate}`;
      cell.hidden = false;
      cell.staticCurrency = null;

      // Custom Data for Enterprise Value (Cap Table)
      cell.data = cellData;
    }
  }
};

export default handleEnterpriseValueColumn;
