import React, { FC, useContext, useEffect, useMemo } from 'react';
import { AppBar, Grid, LinearProgress, Toolbar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { isEmpty, isNil } from 'lodash';
import { useParams } from 'react-router-dom';
import { VALID_PAGE_TYPES_TO_EXPORT } from 'common/constants/pageTypes';
import { EQUITY_VALUE_CARD_TITLE, FIRM_TOTAL_CARD_TITLE, LIVE_VALUE_CARDS } from 'common/constants/valuations';
import { useStore } from 'common/store';
import { Currency } from 'common/types/format';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import { ActionButtons, Breadcrumbs, ExtraActionsMenu, HorizontalNavigation, ValueCard } from 'components';
import { DEFAULT_CURRENCY, REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import LayoutContext from 'context/LayoutContext';
import ExcelExportDialog from 'layouts/Main/components/ExcelExportDialog';
import { useGetAllocationValuesByCompany } from 'services/hooks/companies';
import { useAllocationValuesStore } from 'store';
import { getBooleanValue, getNumberValue, getStringValue } from 'utilities';
import { Title } from './components';
import useStyles from './styles';
import { CompanyParams, HeaderProps, ValueCardsProps } from './types';
import RefreshCapitalIqDialog from '../../layouts/Main/components/RefreshCapitalIqDialog/RefreshCapitalIqDialog';

const ValueCards = (props: ValueCardsProps) => {
  const { company, measurementDate } = props;
  const { tableSlugParam } = useParams<CompanyParams>();

  const shouldRenderValueCards = useMemo(
    () => LIVE_VALUE_CARDS.includes(tableSlugParam) && !isEmpty(company) && !isEmpty(measurementDate),
    [company, measurementDate, tableSlugParam]
  );

  const currentEquityValue = useAllocationValuesStore(state => state.currentEquityValue);
  const currentFirmTotal = useAllocationValuesStore(state => state.currentFirmTotal);

  const companyCurrency = useMemo(
    () =>
      ({
        code: company?.financials_currency ?? DEFAULT_CURRENCY,
        units: REGULAR_UNIT,
      } as Currency),
    [company]
  );
  const isLoadingLiveValues = useMemo(
    () => isNil(currentEquityValue) || isNil(currentFirmTotal),
    [currentEquityValue, currentFirmTotal]
  );

  const equityValue = useMemo(() => getNumberValue(currentEquityValue), [currentEquityValue]);

  const firmTotal = useMemo(() => getNumberValue(currentFirmTotal), [currentFirmTotal]);

  if (!shouldRenderValueCards) {
    return null;
  }

  // Display Header Cards on Companies pages
  return (
    <>
      {/* Equity Value */}
      <ValueCard
        currency={companyCurrency}
        isLoading={isLoadingLiveValues}
        title={EQUITY_VALUE_CARD_TITLE}
        value={equityValue}
      />
      {/* Firm Total */}
      <ValueCard
        currency={companyCurrency}
        isLoading={isLoadingLiveValues}
        title={FIRM_TOTAL_CARD_TITLE}
        value={firmTotal}
      />
    </>
  );
};

const Header: FC<HeaderProps> = props => {
  const { children } = props;

  const {
    currentMeasurementDate,
    extraPageActions,
    navItems,
    openExportDialog,
    openCapIqRefreshDialog,
    toggleRefreshCapitalIqDataDialog,
    pageActions,
    pageBreadcrumbs,
    pageTitle,
    pageType,
    toggleExportDialog,
  } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyInfo, isShowLoadingProgress } = storeValue;

  const setCurrentAllocationId = useAllocationValuesStore(state => state.setCurrentAllocationId);
  const currentAllocationVersion = useAllocationValuesStore(state => state.currentAllocationVersion);

  const isValidCompanyMeasurementDate = useMemo(
    () => currentMeasurementDate?.company_id === companyInfo?.id,
    [companyInfo, currentMeasurementDate]
  );

  const { data: allocationValues } = useGetAllocationValuesByCompany({
    company: companyInfo,
    measurementDate: currentMeasurementDate,
    version: currentAllocationVersion,
    shouldQueryAutomatically: !!isValidCompanyMeasurementDate,
  });

  useEffect(() => {
    if (!isNil(allocationValues?.id)) {
      setCurrentAllocationId?.(allocationValues?.id);
    }
  }, [allocationValues, setCurrentAllocationId]);

  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.root} color="inherit" elevation={1} position="fixed">
        <Toolbar className={classes.toolbar}>
          <Grid alignItems="center" container direction="row" justifyContent="space-between" wrap="nowrap">
            {/* Title and Breadcrumbs */}
            <Grid className={classes.titleContainer} item>
              {/* Title */}
              {pageTitle ? <Title pageTitle={pageTitle} /> : <Skeleton height={50} variant="text" width={550} />}
              {/* BreadCrumbs */}
              {pageBreadcrumbs && <Breadcrumbs />}
            </Grid>

            {/* Values Cards and Filters */}
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                wrap="nowrap">
                <ValueCards company={companyInfo} measurementDate={currentMeasurementDate} />
                {/* Filters */}
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>

        {/* Navigation */}
        {!isEmpty(navItems) && (
          <HorizontalNavigation>
            <>
              {pageActions && <ActionButtons />}
              {extraPageActions && <ExtraActionsMenu />}
            </>
          </HorizontalNavigation>
        )}
      </AppBar>

      {/* Loading Progress Bar */}
      {!isEmpty(navItems) && (
        <Grid className={classes.progressBar}>{isShowLoadingProgress && <LinearProgress color="primary" />}</Grid>
      )}

      {/* Excel Export */}
      {VALID_PAGE_TYPES_TO_EXPORT.includes(getStringValue(pageType)) && (
        <ExcelExportDialog onClose={toggleExportDialog} open={getBooleanValue(openExportDialog)} />
      )}
      <RefreshCapitalIqDialog open={openCapIqRefreshDialog} onClose={toggleRefreshCapitalIqDataDialog} />
    </>
  );
};

export default Header;
