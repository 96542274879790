import { Security } from 'common/types/company';
import { SpecifiedShareValuesShareValues } from 'common/types/valuation';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import createCapTableSelectionConfig from './components/CapTableSelection/createCapTableSelectionConfig';
import createSecuritySharesTable from './components/SecurityShares/createSecuritySharesTable';
import SpecifiedShareValues from './SpecifiedShareValues';
import { CreateSpecifiedShareValuesConfigParams } from './types';
import { SecurityWithSharePrices } from './utils/types';

const createSpecifiedShareValuesConfig = ({
  approach,
  ssvAttributes,
  capTableList,
  hideEnterpriseValueRow,
  isDisabled,
}: CreateSpecifiedShareValuesConfigParams) => {
  const capTableSelectionSheet = createCapTableSelectionConfig({
    capTableList,
    approach,
    ssvAttributes,
    isDisabled,
  });

  const securitiesWithSharePrices = ({
    securities,
    share_values,
  }: {
    securities: Security[];
    share_values: SpecifiedShareValuesShareValues[];
  }): SecurityWithSharePrices[] =>
    securities.map(s => ({
      ...s,
      share_price: share_values?.length > 0 ? share_values.find(sv => sv.security === s.id)?.share_price ?? 0 : 0,
    }));

  const selectedCapTable = capTableList.find(ct => ct.id === approach.valuations_approach_ssv?.cap_table);

  let securitySharesSheet = null;

  if (selectedCapTable?.securities) {
    securitySharesSheet = createSecuritySharesTable({
      capTable: {
        ...selectedCapTable,
        securities:
          selectedCapTable.securities.length > 0
            ? securitiesWithSharePrices({
              securities: selectedCapTable.securities,
              share_values: approach.valuations_approach_ssv?.share_values ?? [],
            })
            : [],
      },
      approach,
      hideEnterpriseValueRow,
      isDisabled,
    });
  }

  const name = getApproachTableName({ approach });

  return {
    spreadsheets: {
      capTableSelectionSheet,
      [name]: securitySharesSheet,
    },
    approach,
    value: approach.enterprise_value,
    component: SpecifiedShareValues,
    name,
    enterpriseValueReference: name,
  };
};

export default createSpecifiedShareValuesConfig;
