import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LinearProgress, MenuItem, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RefreshIcon from '@material-ui/icons/Refresh';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormat } from '../../../../common/hooks';
import { useGetPublicCompListBatchByDate } from '../../../../services/hooks';
import { dbShortDate, formatDate } from '../../../../utilities';
import ValuationContext from '../../../ValuationsAllocation/ValuationContext';
import { useRefreshHelpersWithContext } from '../RefreshGPCOption/hooks';
import useStyles from '../RefreshGPCOption/RefreshGPCOption.styles';

const RefreshCalibrationOption = ({ calibration, approaches, setApproaches, closeApproachMenu }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { financialsPeriods, valuation } = useContext(ValuationContext);
  const [format] = useFormat();
  const { getTickerListsByDate, getRefreshedApproaches } = useRefreshHelpersWithContext();
  const [publicCompsDataByDate, fetchGPCCompListsByDate] = useGetPublicCompListBatchByDate();

  useEffect(() => {
    if (!isEmpty(publicCompsDataByDate)) {
      const calibrationApproachId = calibration?.approach?.id ?? 0;
      if (calibrationApproachId !== 0) {
        const calibrationApproach = approaches?.find(appr => appr?.id === calibrationApproachId);
        const refreshedApproaches = getRefreshedApproaches(publicCompsDataByDate, [calibrationApproach], approaches);

        // This is for not lost the user introduced and not saved data when
        // the useEffect on src/pages/Valuations/Valuation.js try to syncronize
        // the valuation.valuations_approaches with the "approaches" state variable.
        // Actually we do the same when we add/remove an approach
        valuation.valuations_approaches = [...refreshedApproaches];

        // redraw approaches Accordion with the updated approach
        setApproaches([...refreshedApproaches]);
      }
      setIsLoading(false);
      closeApproachMenu();
    }
  }, [
    publicCompsDataByDate,
    approaches,
    setApproaches,
    closeApproachMenu,
    getRefreshedApproaches,
    valuation,
    calibration.approach.id,
  ]);

  const refreshCalibrationApproach = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const calibrationApproachId = calibration?.approach?.id ?? 0;
    if (calibrationApproachId !== 0) {
      const calibrationApproach = approaches?.find(appr => appr?.id === calibrationApproachId);
      const tickerListsByDate = getTickerListsByDate(
        [calibrationApproach],
        approaches,
        dbShortDate(calibration?.calibration_date)
      );
      fetchGPCCompListsByDate(tickerListsByDate, format.currency.code, financialsPeriods);
    }
  }, [
    approaches,
    calibration.approach.id,
    calibration.calibration_date,
    fetchGPCCompListsByDate,
    financialsPeriods,
    format.currency.code,
    getTickerListsByDate,
    isLoading,
  ]);

  return (
    <div>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : (
        <LinearProgress color="secondary" variant="determinate" value={100} />
      )}
      <MenuItem onClick={refreshCalibrationApproach}>
        <div className={classes.menuItemRow}>
          <div>
            <Typography color="secondary" variant="h4">
              {isLoading ? 'REFRESHING' : 'REFRESH DATA'}
            </Typography>
            <Typography className={classes.refreshDateLabel} color="secondary" variant="caption">
              LAST REFRESH DATE: {formatDate({ date: calibration.last_refresh_date || new Date().toUTCString() })}
            </Typography>
          </div>
          <div className={classes.refreshIconContainer}>
            {isLoading ? (
              <MoreHorizIcon color="secondary" fontSize="small" />
            ) : (
              <RefreshIcon color="secondary" fontSize="small" />
            )}
          </div>
        </div>
      </MenuItem>
    </div>
  );
};

RefreshCalibrationOption.propTypes = {
  calibration: PropTypes.object,
  approaches: PropTypes.array,
  setApproaches: PropTypes.func,
  closeApproachMenu: PropTypes.func,
};

export default RefreshCalibrationOption;
