import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { EXTERNAL_VALUATION_REFERENCE_TITLE, EXTERNAL_VALUATION_REFERENCE_TYPE } from 'common/constants/documents';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet, Widgets } from 'components';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { EXTERNAL_VALUATION_SPREADSHEET_SLUG } from 'pages/ValuationsAllocation/common/constants/externalValuation';
import { Note } from 'pages/ValuationsAllocation/types';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useDocuments } from 'services/hooks';
import { useNotes } from 'services/hooks/notes';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { ExternalValuationProps } from './types';

const ExternalValuation: FC<ExternalValuationProps> = props => {
  const { approach, isLoading, isSaving, onChange, spreadsheets, workbook } = props;
  const { panelId: approachPanelId, isDisabled = false } = getObjectValue(approach);
  const { externalValuation: externalValuationSpreadsheet } = getObjectValue(spreadsheets);

  const { measurementDate, filesInApproach, setFilesInApproach, setNotesInApproach } = useContext(ValuationContext);

  const [format, formatDispatch] = useFormat({
    page: FINANCIALS_CURRENCY_PAGE,
  }) as UseFormatValues;

  const { filesToSave, setFilesToSave } = useDocuments();
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  // Create External Valuation Sheet props
  const externalValuationSheetProps = useMemo(() => {
    if (externalValuationSpreadsheet) {
      return {
        ...externalValuationSpreadsheet,
        format,
        formatDispatch,
        key: getStringValue(externalValuationSpreadsheet?.name),
        onChange,
        sheet: externalValuationSpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [externalValuationSpreadsheet, format, formatDispatch, onChange, workbook]);

  // Set Approach Document Files
  useEffect(() => {
    if (!isEmpty(filesToSave)) {
      setFilesInApproach?.(prevState => [{ ...prevState, filesToSave, panelId: approachPanelId }]);
    }
  }, [filesToSave, approachPanelId, setFilesInApproach]);

  // Set Document Files to Save
  useEffect(() => {
    if (!isEmpty(filesInApproach)) {
      const approachFiles = filesInApproach?.filter(file => file.panelId === approachPanelId);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: setFilesToSave is not typed
      setFilesToSave?.(getArrayValue(approachFiles?.[0]?.filesToSave));
    } else {
      setFilesToSave?.([]);
    }
  }, [filesInApproach, approachPanelId, setFilesToSave]);

  // Set Approach Notes
  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach?.(prevState => {
        const previousNotes = prevState?.filter(note => note.panelId !== approachPanelId);

        return [...previousNotes, { notes, panelId: approachPanelId, notesHasChanged } as Note];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  return (
    <Grid className={`${VALUATIONS_SLUG}-${EXTERNAL_VALUATION_SPREADSHEET_SLUG}-container`}>
      <CustomScalarSpreadsheet {...externalValuationSheetProps} isLoading={isLoading} isSaving={isSaving} />

      <Widgets
        documentReferencesProps={{
          currentPage: EXTERNAL_VALUATION_REFERENCE_TITLE,
          filesToSave,
          isDisabled,
          referencedFeatureId: getNumberValue(approach?.id),
          referenceType: EXTERNAL_VALUATION_REFERENCE_TYPE,
          selectedMeasurementDate: measurementDate,
          setFilesToSave,
        }}
        notesProps={{
          isApproach: true,
          isDisabled,
          notes,
          onAddNote,
          onDeleteNote,
          onUpdateNotes,
          pageType: VALUATIONS_PAGE_VALUE,
          pageTypeId: getNumberValue(approach?.id),
          pageTypeKey: VALUATIONS_PAGE_KEY,
          setNotes,
        }}
      />
    </Grid>
  );
};

export default ExternalValuation;
