import React from 'react';
import { Divider, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { largeCurrencyFormat } from 'common/formats/formats';
import { formatNumbers } from 'utilities';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '16px 0',
    textAlign: 'center',
  },
  cardTitle: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  cardBody: {
    fontFamily: theme.typography.mainFont,
    fontSize: '1.125em',
  },
  cardSkeleton: {
    width: '25%',
    height: 36,
    margin: 'auto',
  },
  cardDivider: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    width: '20%',
    margin: `${theme.spacing()}px auto`,
  },
}));

const EquityValueCard = ({ title, isLoading, scenarioEquityValue, currency }) => {
  const classes = useStyles();
  const formattedEquityValue = formatNumbers({
    format: largeCurrencyFormat,
    value: scenarioEquityValue,
    currency,
  });

  return (
    <Paper className={classes.card}>
      <small className={classes.cardTitle}>{title}</small>
      <Divider className={classes.cardDivider} />
      {!isLoading ? (
        <p data-testid="equity-value" className={classes.cardBody}>
          {formattedEquityValue}
        </p>
      ) : (
        <div data-testid="equity-value-skeleton">
          <Skeleton className={classes.cardSkeleton} variant="text" />
        </div>
      )}
    </Paper>
  );
};

EquityValueCard.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  scenarioEquityValue: PropTypes.string,
  currency: PropTypes.shape({
    code: PropTypes.string,
    symbol: PropTypes.string,
    measurementDateId: PropTypes.number,
    sourceCurrency: PropTypes.string,
    units: PropTypes.string,
  }),
};

export default EquityValueCard;
