/* eslint-disable no-param-reassign */
import moment from 'moment';
import uuid from 'react-uuid';
import { FIRST_ROW_TITLE, FISCAL_QUARTER, FISCAL_YEAR, FORMAT_YEAR_TITLE } from 'common/constants/financials';
import { template as balanceSheetTemplate } from 'pages/Financials/balance-sheet/data';
import { template as performanceMetricsTemplate } from 'pages/Financials/performance-metrics/data';
import { getYearQuarters } from 'utilities';
import { getReferenceIndex } from './auxiliaryFunctions';
import getFinancialsData from './getFinancialsData';
import incomeStatementTemplate from '../income-statement/data/template';

/**
 *
 * @param {*} getFinancialsDataFn
 * @param {*} periods
 * @param {*} fiscalYearData
 * @param {*} addCollapsedColumn
 * @param {*} scrollToEndOfTables
 * @param {*} tableData
 * @param {*} isPrevYear
 */
const addFinancialYear = async ({
  tableData,
  financialStatement,
  periods,
  fiscalYearData,
  addCollapsedColumn,
  scrollToEndOfTables,
  defaultLimitYear,
  additionalHistoricalYears,
  setAdditionalHistoricalYears,
  isPrevYear = false,
}) => {
  const DEFAULT_DATE_FORMAT = 'MM-DD-YYYY';
  const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';

  const newColumns = [];
  const { incomeStatement, balanceSheet, KPI } = tableData;

  const { financial_statement_periods: statementPeriods } = getFinancialsData(
    incomeStatement,
    balanceSheet,
    KPI,
    financialStatement,
    periods
  );
  /*
  1. get valid periods (is_deleted == false)
  2. create Map from financials statement periods
  3. Get updated periods with mapping
  4. Determine insertion position
  5. create updated array of periods
  */
  // 1.
  const validPeriods = periods.filter(({ is_deleted }) => !is_deleted);

  // 2.
  const fspMap = new Map();

  statementPeriods.forEach(period => {
    const key = `${period.period_type}-${period.statement_date}`;
    fspMap.set(key, period);
  });

  // 3.
  const updatedPeriods = validPeriods.map(period => ({
    ...period,
    ...fspMap.get(`${period.period_type}-${period.statement_date}`),
  }));

  // 4.
  // separate the LTM, NTM periods and sort the rest. Then combine again
  const regularPeriods = updatedPeriods.filter(({ period_type }) =>
    [FISCAL_YEAR, FISCAL_QUARTER].includes(period_type)
  );

  const specialPeriods = updatedPeriods.filter(
    ({ period_type }) => ![FISCAL_YEAR, FISCAL_QUARTER].includes(period_type)
  );

  regularPeriods.sort((a, b) => {
    if (a.period_type === b.period_type) {
      return moment(a.statement_date).diff(moment(b.statement_date));
    }
    return 0;
  });

  const updatedSortedPeriods = [...regularPeriods, ...specialPeriods];

  const referenceIndex = getReferenceIndex(updatedSortedPeriods, isPrevYear);

  const initialColumns = isPrevYear ? [] : [...updatedSortedPeriods].slice(0, referenceIndex + 1);

  const lastColumns = isPrevYear ? updatedSortedPeriods : [...updatedSortedPeriods].slice(referenceIndex + 1);

  const referencePeriod = validPeriods[referenceIndex];

  const newYear = isPrevYear
    ? moment(referencePeriod?.statement_date).subtract(1, 'years')
    : moment(referencePeriod?.statement_date).add(1, 'years');

  let newYearId = uuid();

  const year = newYear.year();
  const isAdditionalHistoricalYear = year <= defaultLimitYear;

  if (isAdditionalHistoricalYear) {
    const tmpAdditionalHistoricalYears = additionalHistoricalYears;
    tmpAdditionalHistoricalYears.unshift({ id: newYearId, year });
    setAdditionalHistoricalYears(tmpAdditionalHistoricalYears);
  }
  // at this point we should determine if this is truly a new period or if we got to bring back a temporarily deleted periods
  // with blank data to avoid an integrity error when trying to save
  const temporarilyDeletedYear = periods.find(p => p.period_type === FISCAL_YEAR && p.year === year.toString());
  if (temporarilyDeletedYear) {
    temporarilyDeletedYear.is_deleted = false;
    const temporarilyDeletedQuarters = periods
      .filter(p => p.parentColumn === temporarilyDeletedYear.id)
      .map(p => ({ ...p, is_deleted: false }));
    newColumns.push(...temporarilyDeletedQuarters);
    newColumns.push(temporarilyDeletedYear);
    newYearId = temporarilyDeletedYear.id;
  } else {
    const quarters = getYearQuarters(newYear, fiscalYearData);

    quarters.forEach(quarter => {
      newColumns.push({
        statement_date: moment(quarter.date, DEFAULT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
        period_type: FISCAL_QUARTER,
        parentColumn: newYearId,
        title: isPrevYear ? quarter.name : FIRST_ROW_TITLE,
        is_projection: !isPrevYear,
        subtitle: isPrevYear ? quarter.date : quarter.name,
        date: isPrevYear ? null : quarter.date,
        name: quarter.name,
        isPrevQuarter: isPrevYear,
        isProjectedQuarter: !isPrevYear,
      });
    });

    newColumns.push({
      statement_date: moment(newYear).format(DISPLAY_DATE_FORMAT),
      period_type: FISCAL_YEAR,
      is_projection: !isPrevYear,
      name: moment(newYear).format('YYYY'),
      id: newYearId,
      title: isPrevYear ? FORMAT_YEAR_TITLE(moment(newYear).format('YYYY')) : FIRST_ROW_TITLE,
      subtitle: isPrevYear ? null : FORMAT_YEAR_TITLE(moment(newYear).format('YYYY')),
      isParent: true,
      isPrevYear,
      isProjectedYear: !isPrevYear,
      isAdditionalHistoricalYear,
      showAdditionalHistoricalYear: true,
      isVisibleColumn: isAdditionalHistoricalYear,
    });
  }

  const incomeStatementColumns = newColumns.map(newColumn => {
    if (newColumn.id && temporarilyDeletedYear) {
      return {
        ...newColumn,
        income_statement: {
          ...newColumn.income_statement,
          ...incomeStatementTemplate,
          name: newColumn.income_statement.name,
        },
        financial_statement_period: newColumn.id,
      };
    }
    const data = newColumn.income_statement ?? {};
    return {
      ...incomeStatementTemplate,
      ...data,
      ...newColumn,
    };
  });

  const performanceMetricsColumns = newColumns.map(newColumn => ({
    ...performanceMetricsTemplate,
    ...newColumn,
  }));

  const balanceSheetColumns = newColumns.map(newColumn => {
    if (newColumn.id && temporarilyDeletedYear) {
      return {
        ...newColumn,
        balance_sheet: {
          ...newColumn.balance_sheet,
          ...balanceSheetTemplate,
          name: newColumn.balance_sheet.name,
        },
        financial_statement_period: newColumn.id,
      };
    }
    const data = newColumn.balance_sheet ?? {};
    return {
      ...balanceSheetTemplate,
      ...data,
      ...newColumn,
    };
  });

  const kpiColumns = newColumns.map(newColumn => ({ ...newColumn, kpi_dataset: [] }));

  /* tableData.<financialSection>.columns doesn't have the temp data
  -> get from updatedPeriods the income_statement prop that has the temp data
  make sure we don't lose the id of parent columns to have the collapsible columns preserved */
  tableData.incomeStatement.columns = updatedSortedPeriods.map((col, idx) => {
    if ('id' in validPeriods[idx]) {
      return { ...col.income_statement, id: validPeriods[idx].id };
    }
    return col.income_statement;
  });

  /* balance sheets don't have LTM periods
  -> it's easier to iterate tableData.balanceSheet.columns */
  tableData.balanceSheet.columns = tableData.balanceSheet.columns.map((col, idx) => {
    if ('id' in col) {
      return { ...updatedSortedPeriods[idx].balance_sheet, id: col.id };
    }
    return updatedSortedPeriods[idx].balance_sheet;
  });

  await tableData.incomeStatement.addColumns(
    isPrevYear ? 0 : tableData.incomeStatement.columns.length - 10,
    incomeStatementColumns
  );

  await tableData.performanceMetrics.addColumns(
    isPrevYear ? 0 : tableData.performanceMetrics.columns.length - 10,
    performanceMetricsColumns
  );

  await tableData.balanceSheet.addColumns(
    isPrevYear ? 0 : tableData.balanceSheet.columns.length - 5,
    balanceSheetColumns
  );

  await tableData.KPI.addColumns(
    isPrevYear ? 0 : tableData.KPI.columns.length, // don't have LTM and NTM periods
    kpiColumns
  );

  addCollapsedColumn(newYearId);

  await tableData.evaluateSpreadsheets([
    tableData.incomeStatement,
    tableData.performanceMetrics,
    tableData.balanceSheet,
    tableData.KPI,
  ]);

  periods.splice(0, periods.length, ...initialColumns, ...newColumns, ...lastColumns);

  scrollToEndOfTables(isPrevYear);
};

export default addFinancialYear;
