import React from 'react';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { SELECTION_A, SELECTION_B } from 'common/constants/valuations';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import GridRowLabel from 'components/Grid/GridRowLabel';
import {
  BOLD_BLUE_ROW_HEADER_CONFIG,
  METRICS,
  TABLE_HEADER_CONFIG,
} from 'pages/Valuations/approaches/backsolveApproach/constants';
import {
  APPLIED_ADJUSTMENT_ALIAS,
  AS_OF_DATE_ALIAS,
  AS_OF_VALUE_TITLE,
  KEY_VALUE_PARSE,
  MARKET_ADJUSTMENT_LEDGER_TITLE,
  MD_DATE_ALIAS,
  MEDIAN_MEDIAN_PARSE,
  METRIC_ALIAS,
  PERCENT_CHANGE_TITLE,
  PERCENTILE_PARSE,
  SELECT_ADJUSTMENT_ALIAS,
  SPECIFIED_ADJUSTMENT_ALIAS,
} from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/constants';
import { formulaProps } from 'pages/Valuations/approaches/backsolveApproach/MarketAdjustmentLedger/util/getRowConfig';
import { getPercentileLabel } from 'pages/ValuationsAllocation/util';
import { isFalseStrict } from 'utilities';
import colConfig from './colConfig';

const NO_BORDER_READ_ONLY = 'no-side-border read-only--white';
const BOLD_NO_BORDER = `${NO_BORDER_READ_ONLY} subtitle-bold`;
const SUBTITLE_BOTTOM_BORDER_ONLY = `${NO_BORDER_READ_ONLY} border-bottom`;

function medianMedianParse(row, index, cells, rowRange) {
  if (row.parseAs === MEDIAN_MEDIAN_PARSE) {
    const rowNumber = index + 1;
    const labelKey = `C${rowNumber}`;
    const valueKey = `D${rowNumber}`;
    // eslint-disable-next-line no-param-reassign
    cells[labelKey] = {
      ...row,
      customKey: undefined,
      className: 'read-only--white',
      valueViewer: props => <GridRowLabel {...props} />,
      key: labelKey,
    };
    // eslint-disable-next-line no-param-reassign
    cells[valueKey] = {
      ...row,
      key: valueKey,
      expr: `=${row.formula}(FILTER_ENABLED([${rowRange}]))`,
    };
  }
}

function percentileParse(row, index, cells, rowRange) {
  if (row.parseAs === PERCENTILE_PARSE) {
    const rowNumber = index + 1;
    const labelKey = `C${rowNumber}`;
    const valueKey = `D${rowNumber}`;
    const value = row.value || row.defaultValue;
    // eslint-disable-next-line no-param-reassign
    cells[labelKey] = {
      ...row,
      value,
      key: labelKey,
    };

    // eslint-disable-next-line no-param-reassign
    cells[valueKey] = {
      ...formulaProps,
      key: valueKey,
      alias: row.alias,
      expr: `=PERCENTILE(FILTER_ENABLED([${rowRange}]), ${labelKey})`,
    };
  }
}

function keyValueParse(row, index, cells, approach, publicComps, rowRange, isDisabled) {
  if (row.parseAs === KEY_VALUE_PARSE) {
    const rowNumber = index + 1;
    const labelKey = `C${rowNumber}`;
    const valueKey = `D${rowNumber}`;
    const value = row.value || row.defaultValue;
    // eslint-disable-next-line no-param-reassign
    cells[labelKey] = {
      ...row,
      key: labelKey,
      customKey: undefined,
      className: 'read-only--white',
      valueViewer: props => <GridRowLabel {...props} />,
    };

    // eslint-disable-next-line no-param-reassign
    cells[valueKey] = {
      ...row,
      key: labelKey,
      readOnly: isDisabled,
      value,
    };

    const savedAdjustment = approach.valuations_approach_backsolve?.adjustment_selected;
    const convertToReadableValue = () => {
      if ([SELECTION_A, SELECTION_B].includes(savedAdjustment)) {
        const percentileNumber
          = savedAdjustment === SELECTION_A
            ? approach.valuations_approach_backsolve.percentile_selection_a
            : approach.valuations_approach_backsolve.percentile_selection_b;
        return getPercentileLabel(Number(percentileNumber - 1));
      }
      return savedAdjustment;
    };

    if (row.alias === SELECT_ADJUSTMENT_ALIAS) {
      // eslint-disable-next-line no-param-reassign
      cells[valueKey].value = convertToReadableValue() || row.defaultValue;
    }

    if (row.alias === SPECIFIED_ADJUSTMENT_ALIAS) {
      // eslint-disable-next-line no-param-reassign
      cells[valueKey].value = approach.valuations_approach_backsolve.specified_adjustment || row.defaultValue;
    }

    if (row.alias === APPLIED_ADJUSTMENT_ALIAS) {
      const selection_obj = {
        '\'Median\'': `D${3 + publicComps.length + 1}`,
        '\'Mean\'': `D${3 + publicComps.length + 2}`,
        [`GET_PERCENTILE_OPTION(C${3 + publicComps.length + 3})`]: `D${3 + publicComps.length + 3}`,
        [`GET_PERCENTILE_OPTION(C${3 + publicComps.length + 4})`]: `D${3 + publicComps.length + 4}`,
        '\'75th Percentile\'': `PERCENTILE(FILTER_ENABLED([${rowRange}]), 75)`,
        '\'25th Percentile\'': `PERCENTILE(FILTER_ENABLED([${rowRange}]), 25)`,
        '\'Specified\'': `D${3 + publicComps.length + 5}`,
      };
      const objExpressionString = Object.entries(selection_obj)
        .map(([key, value]) => `[${key}, ${value}]`)
        .join(',');
      const expr = `=FROM_KEY_VALUE_PAIRS([${objExpressionString}], D${rowNumber - 1})`;
      // eslint-disable-next-line no-param-reassign
      cells[valueKey].expr = expr;
      // eslint-disable-next-line no-param-reassign
      cells[valueKey].readOnly = true;
    }
  }
}

const determineEnabledStatus = ({ gridType, chosenMetric, asOfComp, mdComp, row }) => {
  if (gridType !== GRID_NUMBER_CHECKBOX) return undefined;
  if (isFalseStrict(row.enabled)) return false;
  const asOfCompsMetricValue = asOfComp[chosenMetric] ?? 0;
  const mdCompsMetricValue = mdComp[chosenMetric] ?? 0;
  // numerator is zero
  if (Number(asOfCompsMetricValue) === Number(mdCompsMetricValue)) return false;
  // based on denominator validation
  return Number(asOfCompsMetricValue) !== 0;
};

export default async ({ rowConfig, tableData }) => {
  const { isDisabled } = tableData;
  const { publicComps, approach } = tableData;
  const cells = {};
  cells.A1 = {
    ...TABLE_HEADER_CONFIG,
    value: MARKET_ADJUSTMENT_LEDGER_TITLE,
    rowNumber: 1,
    colSpan: 4,
    valueViewer: props => <GridRowLabel {...props} />,
  };
  cells.A2 = {
    ...BOLD_BLUE_ROW_HEADER_CONFIG,
    value: AS_OF_VALUE_TITLE,
    className: `${BOLD_NO_BORDER} title-align-left`,
  };
  cells.B2 = {
    ...BOLD_BLUE_ROW_HEADER_CONFIG,
    value: tableData.asOfDate,
    alias: AS_OF_DATE_ALIAS,
    readOnly: isDisabled,
    gridDateComponent: GridDate,
    dataEditor: props => <GridDateEditor {...props} />,
    valueViewer: props => <GridDateValueViewer {...props} />,
    className: BOLD_NO_BORDER,
  };
  cells.C2 = {
    ...BOLD_BLUE_ROW_HEADER_CONFIG,
    value: `As of ${tableData.measurementDate}`,
    alias: MD_DATE_ALIAS,
    className: BOLD_NO_BORDER,
  };
  cells.D2 = {
    ...BOLD_BLUE_ROW_HEADER_CONFIG,
    value: PERCENT_CHANGE_TITLE,
    className: BOLD_NO_BORDER,
  };
  cells.A3 = {
    value: '',
    className: SUBTITLE_BOTTOM_BORDER_ONLY,
  };
  cells.B3 = {
    value: approach.valuations_approach_backsolve.metric,
    alias: METRIC_ALIAS,
    readOnly: isDisabled,
    ignoreRowCopy: true,
    dbType: 'string',
    gridType: 'string',
    className: SUBTITLE_BOTTOM_BORDER_ONLY,
    dataEditor: props => <GridSelect menuPosition="fixed" enumerated options={METRICS} {...props} />,
    valueViewer: props => <SelectValueViewer options={METRICS} useLabel {...props} />,
    dropdown: true,
  };
  cells.C3 = {
    expr: '=B3',
    readOnly: true,
    gridType: 'string',
    className: SUBTITLE_BOTTOM_BORDER_ONLY,
  };
  cells.D3 = {
    expr: '=B3',
    readOnly: true,
    gridType: 'string',
    className: SUBTITLE_BOTTOM_BORDER_ONLY,
  };
  const rowRange = publicComps.map((_, j) => `D${j + 3 + 1}`).toString();
  const updatedCells = rowConfig.reduce((cells, row, index) => {
    if (index >= 3 && index < 3 + publicComps.length) {
      const { mdComp, asOfComp } = row;
      const rowCells = colConfig.reduce((sofar, colFn) => {
        const rowNumber = index + 1;
        const col = colFn(row, rowNumber);
        const cell = {
          ...col,
          value: row[col.alias],
          key: col.columnLegend + rowNumber,
          enabled: determineEnabledStatus({
            gridType: col.gridType,
            mdComp,
            asOfComp,
            chosenMetric: approach.valuations_approach_backsolve.metric,
            row,
          }),
        };
        if (index === 3 + publicComps.length - 1) {
          cell.className = 'border-bottom read-only--white';
        }
        return { ...sofar, [cell.key]: cell };
      }, {});
      return { ...cells, ...rowCells };
    }
    medianMedianParse(row, index, cells, rowRange);
    percentileParse(row, index, cells, rowRange);
    keyValueParse(row, index, cells, approach, publicComps, rowRange, isDisabled);

    return cells;
  }, cells);
  Object.entries(updatedCells).forEach(([key, value]) => {
    // eslint-disable-next-line no-param-reassign
    value.key = key;
  });
  return updatedCells;
};
