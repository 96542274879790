import { useContext, useEffect, useMemo, useReducer } from 'react';
import { isEmpty } from 'lodash';
import { setCurrency } from 'common/actions/format';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { MEASUREMENT_DATE_FILTER } from 'common/constants/pageFilters';
import formatReducer from 'common/reducers/format/reducer';
import { useStore } from 'common/store';
import { DEFAULT_CURRENCY, THOUSANDS } from 'components/FeaturedSpreadsheet/constants';
import LayoutContext from 'context/LayoutContext';
import { getCurrency, worldCurrencies } from 'utilities';

const useFormat = props => {
  const { page = FINANCIALS_CURRENCY_PAGE, sourceCurrency, units } = props || {};

  const [{ companyInfo }] = useStore();
  const { pageFilters } = useContext(LayoutContext);

  const selectedCurrency = sourceCurrency ?? DEFAULT_CURRENCY;

  const measurementDateId = useMemo(() => {
    if (pageFilters) {
      const selectedMD = pageFilters.find(filter => filter.id.includes(MEASUREMENT_DATE_FILTER));

      return selectedMD ? selectedMD.selectedValue : null;
    }

    return null;
  }, [pageFilters]);

  const [format, dispatch] = useReducer(formatReducer, {
    currency: {
      code: selectedCurrency,
      sourceCurrency: selectedCurrency,
      symbol: worldCurrencies[selectedCurrency],
      units: units ?? THOUSANDS,
      measurementDateId,
    },
  });

  useEffect(() => {
    // Set current Measurement Date to currency
    if (measurementDateId) {
      dispatch(
        setCurrency({
          measurementDateId,
        })
      );
    }

    // If Source Currency is not empty, set Source Currency
    if (!isEmpty(sourceCurrency)) {
      dispatch(
        setCurrency({
          code: sourceCurrency,
          sourceCurrency,
          symbol: worldCurrencies[sourceCurrency],
        })
      );
      // If companyInfo is not empty, set Company currency
    } else if (!isEmpty(companyInfo)) {
      const code = getCurrency({ companyInfo, page });

      if (code) {
        dispatch(
          setCurrency({
            code,
            sourceCurrency: code,
            symbol: worldCurrencies[code],
          })
        );
      }
    }
  }, [companyInfo, page, measurementDateId, sourceCurrency]);

  return [format, dispatch];
};

export default useFormat;
