import React, { FC } from 'react';
import { TaskProgressModalContentBody } from 'pages/Funds/components/ProcessManagement/TaskProgressModalContentBody';
import { TaskProgressModalContentHeader } from 'pages/Funds/components/ProcessManagement/TaskProgressModalContentHeader';
import { getObjectValue } from 'utilities';
import { TaskProgressModalContentProps } from './types';

const TaskProgressModalContent: FC<TaskProgressModalContentProps> = props => {
  const { measurementDateName, percentageOfProgress, taskDescription, taskProgressDetails } = props;
  const { last_interaction: lastInteraction } = getObjectValue(taskProgressDetails);

  return (
    <>
      <TaskProgressModalContentHeader
        lastInteraction={lastInteraction}
        measurementDateName={measurementDateName}
        percentageOfProgress={percentageOfProgress}
        taskDescription={taskDescription}
      />

      <TaskProgressModalContentBody taskProgressDetails={taskProgressDetails} />
      {/* TODO: Enable this button for when the functionality to send a reminder is complete.  */}
      {/* <TaskProgressModalContentFooter /> */}
    </>
  );
};

export default TaskProgressModalContent;
