import { useQuery } from '@tanstack/react-query';
import { isEqual, isNil } from 'lodash';
import { ERROR_404 } from 'common/config/api';
import {
  COMPANIES_WITH_OWNERSHIP_BY_FUND_DEFAULT_ERROR_MESSAGE,
  COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY,
} from 'common/constants/services/funds';
import { SuperAgentError } from 'common/types/services';
import { FundService } from 'services';
import { throwError } from 'utilities';
import {
  GetCompaniesWithOwnershipByFundHook,
  GetCompaniesWithOwnershipByFundParams,
  GetCompaniesWithOwnershipByFundResponse,
  UseGetCompaniesWithOwnershipByFund,
} from './types';

const getCompaniesWithOwnershipByFund = async (params: GetCompaniesWithOwnershipByFundParams) => {
  const { fundId } = params;

  if (isNil(fundId)) return null;

  const fundService = new FundService();

  try {
    const companiesWithOwnership = (await fundService.getCompaniesWithOwnershipByFund(
      fundId
    )) as GetCompaniesWithOwnershipByFundResponse;

    if (!companiesWithOwnership) return throwError(COMPANIES_WITH_OWNERSHIP_BY_FUND_DEFAULT_ERROR_MESSAGE);

    return companiesWithOwnership;
  } catch (error) {
    // Handle Companies not found for this fund
    if (error instanceof Error) {
      const { response } = error as SuperAgentError;

      if (isEqual(response?.error?.status?.toString(), ERROR_404)) return [] as GetCompaniesWithOwnershipByFundResponse;
    }

    return throwError(COMPANIES_WITH_OWNERSHIP_BY_FUND_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetCompaniesWithOwnershipByFund: UseGetCompaniesWithOwnershipByFund = params => {
  const { fund, shouldQueryAutomatically = true } = params;

  const fundId = fund?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetCompaniesWithOwnershipByFundHook['data'],
    Error
  >({
    enabled: !isNil(fundId) && shouldQueryAutomatically,
    queryFn: () => getCompaniesWithOwnershipByFund({ fundId }),
    queryKey: [COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY, fundId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetCompaniesWithOwnershipByFund;
