import { CAP_TABLE_SELECTION_TITLE } from 'common/constants/valuations';
import { CustomParserParams } from './types';

const customParser = ({ columns, rowConfig }: CustomParserParams) => {
  const capTableSelectionColumn = columns[1];
  let cells = {
    A1: {
      key: 'A1',
      rowNumber: 1,
      dbType: 'string',
      expr: CAP_TABLE_SELECTION_TITLE,
      value: CAP_TABLE_SELECTION_TITLE,
      className: 'header-no-border no-uppercase text-right gray-title-cell blue-text',
    },
  };
  const columnLegend = 'B';
  rowConfig.forEach((row, rowIndex) => {
    const rowNumber = rowIndex + 1;
    const key = `${columnLegend}${rowNumber}`;
    const value = capTableSelectionColumn[row.alias] || row.defaultValue;

    cells = {
      ...cells,
      [key]: {
        ...row,
        key,
        columnLegend,
        value,
        className: row.className || '',
      },
    };
  });

  return cells;
};

export default customParser;
