import { ALLOCATION_SCENARIO_TYPE_BACKSOLVE } from 'pages/ValuationsAllocation/common/constants/allocation';
import { getBacksolveScenarioMethods } from 'pages/ValuationsAllocation/util/getBacksolveScenarioMethods';
import { getObjectValue } from 'utilities';
import { GetAllocationBacksolveParams } from './types';

const getAllocationBacksolve = (params: GetAllocationBacksolveParams) => {
  const { approaches, scenario } = params;

  const { backsolve_valuation: backsolveId, scenario_type: scenarioType } = getObjectValue(scenario);

  const { valuations_approach_backsolve: backsolveValuationApproach } = getObjectValue(
    approaches?.find(approach => approach?.valuations_approach_backsolve?.id === backsolveId)
  );

  const scenearioTypeIsBacksolve = scenarioType === ALLOCATION_SCENARIO_TYPE_BACKSOLVE;

  // Get Backsolve Scenario Methods
  const { backsolveAllocationMethods, backsolveScenarioMethodsNames, isBacksolveWithOPM }
    = getBacksolveScenarioMethods(backsolveValuationApproach);

  return {
    backsolveAllocationMethods,
    backsolveScenarioMethodsNames,
    backsolveValuationApproach,
    isBacksolveWithOPM,
    scenearioTypeIsBacksolve,
  };
};

export default getAllocationBacksolve;
