import { ValuationsApproach } from 'common/types/valuation';
import { VALUATIONS_BENCHMARK_RELATED_APPROACHES_TYPES } from 'pages/ValuationsAllocation/common/constants/valuations';
import { BenchmarkApproach } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { getStringValue } from 'utilities';

const extractSpecificApproachFromApproach = (approach?: ValuationsApproach) => {
  const approachProperty = VALUATIONS_BENCHMARK_RELATED_APPROACHES_TYPES.get(
    getStringValue(approach?.approach_type)
  ) as keyof ValuationsApproach;

  return approach?.[approachProperty] as BenchmarkApproach;
};

export default extractSpecificApproachFromApproach;
