import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Grid, IconButton, Menu, Tab, Tabs, Typography } from '@material-ui/core';
import { Error, MoreVert, Warning } from '@material-ui/icons';
import clsx from 'clsx';
import { useHotKey } from 'common/hooks';
import { useApproachQueryParam } from 'pages/ValuationsAllocation/hooks';
import { getBooleanValue, getNumberValue, getStringValue } from 'utilities';
import useStyles from './styles';
import { TabContentProps, TabsNavigationProps } from './types';

const NAVIGATION_TABS_IDENTIFIER = 'navigations-tabs';

const TabContent: FC<TabContentProps> = props => {
  const { content = null, isActive, mountOnActive, openedTabs, tabIndex } = props;

  const TabContentElement = <>{content}</>;

  // Render content on Mount all Tabs (slower performance at first load)
  if (!mountOnActive) {
    return TabContentElement;
  }

  // Render only Active Tab's Content and Opened Tabs
  if (isActive || openedTabs?.get(getNumberValue(tabIndex))) {
    return TabContentElement;
  }

  return null;
};

const TabsNavigation: FC<TabsNavigationProps> = props => {
  const {
    children,
    handleMenuClose,
    handleMenuOpen,
    invalidTables,
    isDisabled = false,
    menuAnchorEl,
    mountOnActive = false,
    tabs,
    sidePanelOpen = false,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [hoveredTab, setHoveredTab] = useState<number | null>(null);
  const [openedTabs, setOpenedTabs] = useState<Map<number, boolean>>(new Map());

  const classes = useStyles();

  const invalidTabs = useMemo(() => invalidTables?.map(table => getStringValue(table?.id)), [invalidTables]);

  const { tabId, setApproachQueryParam } = useApproachQueryParam();

  const moveToPreviousTab = (currentTab: number) => {
    const previousTabPosition = currentTab - 1;

    let previousTab = previousTabPosition < 0 ? 0 : previousTabPosition;

    // Handle disabled tabs from the current Tab to the first Tab
    while (tabs?.[previousTab]?.disabled && previousTab >= 0) {
      previousTab -= 1;
    }

    // If the first Tab is disabled, keep the current Tab active
    if (previousTab === 0 && tabs?.[previousTab]?.disabled) {
      previousTab = currentTab;
    }

    const id = getStringValue(tabs?.[previousTab]?.id);
    setApproachQueryParam(id);
  };

  const moveToNextTab = (currentTab: number) => {
    const nextTabPosition = currentTab + 1;

    const tabsQuantity = getNumberValue(tabs?.length);

    let nextTab = nextTabPosition >= tabsQuantity ? tabsQuantity - 1 : nextTabPosition;

    // Handle disabled tabs from the current Tab to the last Tab
    while (tabs?.[nextTab]?.disabled && nextTab < tabsQuantity - 1) {
      nextTab += 1;
    }

    // If the last Tab is disabled, keep the current Tab active
    if (nextTab === tabsQuantity - 1 && tabs?.[nextTab]?.disabled) {
      nextTab = currentTab;
    }

    const id = getStringValue(tabs?.[nextTab]?.id);
    setApproachQueryParam(id);
  };

  useHotKey({
    callback: () => moveToPreviousTab(activeTab),
    key: 'PageDown',
    pressedKeys: [],
  });

  useHotKey({
    callback: () => moveToNextTab(activeTab),
    key: 'PageUp',
    pressedKeys: [],
  });

  // Set the Active Tab based on the Query Param
  useEffect(() => {
    if (tabId) {
      const approachTabIndex = tabs?.findIndex(tab => tab?.id === tabId) ?? -1;

      if (approachTabIndex >= 0) {
        setActiveTab(approachTabIndex);
      }
    }
  }, [tabId, tabs]);

  const addOpenedTab = (tabIndex: number) => {
    setOpenedTabs(prevState => new Map(prevState.set(tabIndex, true)));
  };

  const handleChange = (_event: ChangeEvent<object>, value: number) => {
    addOpenedTab(value);

    const id = getStringValue(tabs?.[value]?.id);
    setApproachQueryParam(id);
  };

  const handleOnMouseEnter = (tabIndex: number) => setHoveredTab(tabIndex);
  const handleOnMouseLeave = () => setHoveredTab(null);

  return (
    <Grid className={classes.root} container>
      <AppBar
        className={sidePanelOpen ? classes.desktopAppBar : classes.appBar}
        color="default"
        elevation={0}
        position="fixed">
        <Tabs
          aria-label={NAVIGATION_TABS_IDENTIFIER}
          className={classes.tabsContainer}
          indicatorColor="primary"
          onChange={handleChange}
          scrollButtons="auto"
          textColor="primary"
          value={activeTab}
          variant="scrollable">
          {tabs?.map((tab, tabIndex) => {
            const tabIsActive = activeTab === tabIndex;
            const tabIsHovered = hoveredTab === tabIndex;

            return (
              <Tab
                // Accessibility props for the tabs
                aria-controls={`${NAVIGATION_TABS_IDENTIFIER}-tabpanel-${tabIndex}`}
                className={classes.tab}
                disabled={getBooleanValue(tab?.disabled)}
                id={`${NAVIGATION_TABS_IDENTIFIER}-${tabIndex}`}
                key={getStringValue(tab?.id)}
                label={
                  <Grid alignItems="center" container justifyContent="flex-end" wrap="nowrap">
                    <Grid className={classes.labelContainer} item>
                      <Typography className={clsx(classes.label, { active: tabIsActive, hovered: tabIsHovered })}>
                        {getStringValue(tab?.label)}
                      </Typography>

                      {tab?.disabled && <Warning className={classes.disabledIcon} fontSize="small" />}

                      {invalidTabs?.includes(getStringValue(tab?.id)) && (
                        <Error className={classes.errorIcon} fontSize="small" />
                      )}
                    </Grid>

                    {tab?.menuItems && (
                      <Grid
                        className={clsx(classes.iconContainer, {
                          active: tabIsHovered || tabIsActive,
                        })}
                        item>
                        <IconButton component="span" onClick={handleMenuOpen} size="small" disabled={isDisabled}>
                          <MoreVert fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                }
                onMouseEnter={() => handleOnMouseEnter(tabIndex)}
                onMouseLeave={handleOnMouseLeave}
              />
            );
          })}
        </Tabs>
      </AppBar>

      <Menu
        anchorEl={menuAnchorEl}
        id={`${NAVIGATION_TABS_IDENTIFIER}-menu-${tabs?.[activeTab]?.id}`}
        onClose={handleMenuClose}
        open={Boolean(menuAnchorEl)}>
        {/* Menu Items */}
        {tabs?.[activeTab]?.menuItems?.map(MenuItemOptions => MenuItemOptions)}
      </Menu>

      {/* Render Children */}
      {children && <Box className={classes.childrenContainer}>{children}</Box>}

      {/* Render the Tab Content */}
      {tabs?.map((tab, tabIndex) => {
        const tabIsActive = activeTab === tabIndex;

        return (
          <Grid
            aria-labelledby={`${NAVIGATION_TABS_IDENTIFIER}-${tabIndex}`}
            className={clsx(classes.tabPanel, { active: tabIsActive })}
            container
            hidden={!tabIsActive}
            id={`${NAVIGATION_TABS_IDENTIFIER}-tabpanel-${tabIndex}`}
            key={getStringValue(tab?.id)}
            role="tabpanel">
            <Box p={3}>
              <TabContent
                content={tab?.content}
                isActive={tabIsActive}
                mountOnActive={mountOnActive}
                openedTabs={openedTabs}
                tabIndex={tabIndex}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TabsNavigation;
