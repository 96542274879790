import { isNil } from 'lodash';
import moment from 'moment';
import { handleScenariosWeights } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config/customReverseParser/utils';
import {
  ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getNumberValue, getObjectValue, getStringValue, ISO_DATE_FORMAT, MDY_FORMAT } from 'utilities';
import { HandleScenarioTypeParams } from './types';
import { checkAndResetScenario } from '../checkAndResetScenario';

const handleScenarioType = (params: HandleScenarioTypeParams) => {
  const { approaches, cell, fieldAttributes, scenario, value } = params;

  // Allocation Method
  const allocationMethodApproach = approaches?.find(approach => approach.panelId === value?.toString()?.split('__')[1]);
  const approachType = allocationMethodApproach?.approach_type;
  const scenarioApproachId = allocationMethodApproach?.panelId as string;

  // Future Exit
  const {
    allocation_method: futureExitAllocationMethod,
    dilution_percentage: futureExitDilutionPercentage,
    discount_rate: futureExitDiscountRate,
    exit_date: futureExitExitDate,
  } = getObjectValue(allocationMethodApproach?.valuations_approach_future_exit);

  if (!isNil(allocationMethodApproach)) {
    // Handle Scenarios Weights
    handleScenariosWeights({
      approach: allocationMethodApproach,
      cell,
      fieldAttributes: getObjectValue(fieldAttributes.valuationApproachWeightAttrs),
      scenario,
      shouldSetFullWeight: true,
    });

    switch (approachType) {
      case VALUATIONS_BACKSOLVE_APPROACH:
        checkAndResetScenario({
          scenario,
          scenarioApproachId: allocationMethodApproach.panelId as string,
          scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
          scenarioType: ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
        });

        scenario.approach_uuid = allocationMethodApproach.panelId;
        scenario.backsolve_valuation = allocationMethodApproach?.valuations_approach_backsolve?.id;
        scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_WATERFALL;
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_BACKSOLVE;
        break;

      case VALUATIONS_FUTURE_EXIT_APPROACH:
        checkAndResetScenario({
          scenario,
          scenarioApproachId,
          scenarioMethod: getNumberValue(futureExitAllocationMethod),
          scenarioType: ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
        });

        scenario.approach_uuid = scenarioApproachId;
        scenario.dilution_percentage = futureExitDilutionPercentage;
        scenario.discount_rate = futureExitDiscountRate;
        scenario.exit_date = moment(futureExitExitDate, ISO_DATE_FORMAT, true).isValid()
          ? getStringValue(futureExitExitDate)
          : getStringValue(moment(futureExitExitDate, MDY_FORMAT).format(ISO_DATE_FORMAT));
        scenario.future_exit = allocationMethodApproach?.valuations_approach_future_exit?.id;
        scenario.scenario_method = getNumberValue(futureExitAllocationMethod);
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT;
        break;

      case VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH:
        checkAndResetScenario({
          scenario,
          scenarioApproachId,
          scenarioMethod: ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
          scenarioType: ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
        });

        scenario.approach_uuid = allocationMethodApproach?.panelId;
        scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES;
        scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES;
        scenario.specified_share_values = allocationMethodApproach?.valuations_approach_ssv?.id;
        break;

      default:
        break;
    }
  }
};

export default handleScenarioType;
