import { parseDatabaseValue } from 'utilities';

const reverseParse = (security, cell, cells, fieldAttributes, allowEmptyValues) => {
  const { key } = cell;
  const defaultValue = cell.defaultValue || null;
  const type = cell.dbType || null;
  const format = cell.format || null;
  const value = cells[key] ? cells[key].value : defaultValue;
  const { gridType } = cell;
  const decimalPlaces = fieldAttributes[cell.alias]?.decimal_places || cell?.dbDecimalPlaces;

  const calcValue = parseDatabaseValue({
    type,
    value,
    defaultValue,
    format,
    allowEmptyValues,
    gridType,
    decimalPlaces,
  });
  // eslint-disable-next-line no-param-reassign
  security[cell.alias] = calcValue;
};

const reverseParser = ({ cells, tableData, fieldAttributes, allowEmptyValues }) => {
  const alphabet = ['A', 'B', 'C', 'D'];
  tableData.securitiesBasket.basket.forEach((security, index) => {
    alphabet.forEach(columnLegend => {
      const cell = cells[`${columnLegend}${index + 2}`];
      reverseParse(security, cell, cells, fieldAttributes, allowEmptyValues);
    });
  });
};

export default reverseParser;
