import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  ALLOCATION_VALUES_BY_COMPANY_DEFAULT_ERROR_MESSAGE,
  ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY,
} from 'common/constants/services/companies';
import { CompaniesService } from 'services';
import { throwError } from 'utilities';
import {
  GetAllocationValuesByCompanyHook,
  GetAllocationValuesByCompanyParams,
  GetAllocationValuesByCompanyResponse,
  UseGetAllocationValuesByCompany,
} from './types';

const getAllocationValuesByCompany = async (params: GetAllocationValuesByCompanyParams) => {
  const { companyId, measurementDateId, version } = params;

  if (isNil(companyId) && isNil(measurementDateId)) return null;

  const companiesService = new CompaniesService();

  try {
    const allocationValues = (await companiesService.getAllocationValuesByCompanyId(
      companyId,
      measurementDateId,
      version
    )) as GetAllocationValuesByCompanyResponse;

    if (!allocationValues) return throwError(ALLOCATION_VALUES_BY_COMPANY_DEFAULT_ERROR_MESSAGE);

    return allocationValues;
  } catch (error) {
    return throwError(ALLOCATION_VALUES_BY_COMPANY_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetAllocationValuesByCompany: UseGetAllocationValuesByCompany = params => {
  const { company, measurementDate, version, shouldQueryAutomatically = true } = params;

  const companyId = useMemo(() => company?.id, [company]);
  const measurementDateId = useMemo(() => measurementDate?.id, [measurementDate]);

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetAllocationValuesByCompanyHook['data'],
    Error
  >({
    enabled: !isNil(companyId) && !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getAllocationValuesByCompany({ companyId, measurementDateId, version }),
    queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY, companyId, measurementDateId, version],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetAllocationValuesByCompany;
