import React from 'react';
import { useStore } from 'common/store';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { CapTableListSelectionProps } from './types';

const CapTableListSelection = (props: CapTableListSelectionProps) => {
  const [{ capTableList = [] } = {}] = useStore() ?? [{}];

  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: capTableList,
  };

  return <GridSelect {...props} {...selectProps} />;
};

export default CapTableListSelection;
