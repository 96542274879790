import { GetSelectedApproachProps } from './types';

const getSelectedApproach = (props: GetSelectedApproachProps) => {
  const { approaches, approachId } = props;
  if (approaches?.length && !approachId) {
    const { valuations_approach_gpc, valuations_approach_gpt } = approaches[0];
    return valuations_approach_gpc ?? valuations_approach_gpt;
  }
  const approachIdStr = approachId?.toString();
  return approaches?.find(
    ({ panelId, valuations_approach_gpc, valuations_approach_gpt }) =>
      panelId?.toString() === approachIdStr
      || valuations_approach_gpc?.id?.toString() === approachIdStr
      || valuations_approach_gpt?.id?.toString() === approachIdStr
  );
};

export default getSelectedApproach;
