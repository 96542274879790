import { each, isEqual, isNull } from 'lodash';
import {
  CONVERSION_RATE_ROW_NUMBER,
  CURRENT_OWNERSHIP_ALIAS,
  CURRENT_OWNERSHIP_ROW_NUMBER,
  IS_PARTICIPATION_CAP_ROW_NUMBER,
  LIQUIDATION_PREFERENCE_ROW_NUMBER,
  PARTICIPATION_CAP_ROW_NUMBER,
  PARTICIPATON_ROW_NUMBER,
  SENIORITY_ROW_NUMBER,
  UNDERLYING_SECURITY_ALIAS,
} from 'common/constants/cap-table';
import { OPTION, UNISSUED_OPTIONS, WARRANT } from 'common/constants/securityTypes';
import { objToArray, toString } from 'utilities';
import { getOptions, securityTypeValue } from './underlyingSecurities';

const underlyingCells = {
  [CONVERSION_RATE_ROW_NUMBER]: '0',
  [LIQUIDATION_PREFERENCE_ROW_NUMBER]: '0',
  [SENIORITY_ROW_NUMBER]: '0',
  [PARTICIPATON_ROW_NUMBER]: '1',
  [IS_PARTICIPATION_CAP_ROW_NUMBER]: '1',
  [PARTICIPATION_CAP_ROW_NUMBER]: '0',
};

function currentOwnership(isUnissuedOption, cells, columnLegend, cellsUpdates) {
  if (isUnissuedOption) {
    const linkedCell = cells[columnLegend + CURRENT_OWNERSHIP_ROW_NUMBER];
    cellsUpdates.push({
      cell: linkedCell,
      value: toString(0),
    });
  }
}

function underlyingSecurity(isWarrant, isOption, cell, cells, cellSecurityType, columnLegend, cellsUpdates) {
  if (isWarrant || isOption) {
    if (cell.value?.length) {
      // Find the selected Underlying Security
      const selectedUnderlyingSecurity = getOptions({
        cells,
        securityType: cellSecurityType,
      }).find(item => item.id === cell.value);

      // Add updated cells
      if (selectedUnderlyingSecurity) {
        objToArray(underlyingCells).forEach(([rowNumber]) => {
          const key = selectedUnderlyingSecurity.columnLegend + rowNumber;
          const baseCell = cells[key];
          const linkedCell = cells[columnLegend + rowNumber];

          if (baseCell.value !== linkedCell.value) {
            cellsUpdates.push({
              cell: linkedCell,
              value: toString(baseCell.value),
            });
          }
        });
      }
    } else if (!isNull(cell.value)) {
      // If value is a empty string, clean the linked cells
      objToArray(underlyingCells).forEach(([rowNumber, defaultValue]) => {
        const linkedCell = cells[columnLegend + rowNumber];
        cellsUpdates.push({
          cell: linkedCell,
          value: toString(defaultValue),
        });
      });
    }
  }
}

const linkedCellsUpdates = ({ prevCells, cells }) => {
  if (isEqual(prevCells, cells)) {
    return [];
  }

  const cellsUpdates = [];

  each(cells, cell => {
    const columnLegend = cell.columnLegend || '';
    const cellSecurityType = securityTypeValue(columnLegend, cells);
    const isWarrant = cellSecurityType === WARRANT;
    const isOption = cellSecurityType === OPTION;
    const isUnissuedOption = cellSecurityType === UNISSUED_OPTIONS;

    switch (cell.alias) {
      case UNDERLYING_SECURITY_ALIAS:
        underlyingSecurity(isWarrant, isOption, cell, cells, cellSecurityType, columnLegend, cellsUpdates);
        break;
      case CURRENT_OWNERSHIP_ALIAS:
        currentOwnership(isUnissuedOption, cells, columnLegend, cellsUpdates);
        break;
      default:
        break;
    }
  });

  return cellsUpdates;
};

export default linkedCellsUpdates;
