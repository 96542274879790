import React, { ReactNode, useEffect } from 'react';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { initAnalytics } from 'scalarAnalytics/googleAnalytics';
import useAnalytics from 'scalarAnalytics/useAnalytics';

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const [{ user }] = useStore() as unknown as UseStoreValues;

  useAnalytics();

  useEffect(() => {
    initAnalytics(user ?? null);
  }, [user]);

  return <span>{children}</span>;
};

export default AnalyticsProvider;
