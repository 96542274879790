import React, { FC } from 'react';
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { dashboardCompanyOverviewUrl } from 'dashboard409a/common/config/urls';
import { getInitials } from 'utilities';
import useStyles from './styles';
import { ICompany } from './types';

const Company: FC<ICompany> = props => {
  const { slug, name } = props;

  const classes = useStyles();

  return (
    <Link to={dashboardCompanyOverviewUrl(slug)}>
      <ListItem button>
        <ListItemIcon>
          <Avatar className={classes.avatar}>{getInitials({ names: name })}</Avatar>
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
};

export default Company;
