/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  ALIASES_TO_UPDATE_IN_BALANCE_SHEET,
  ALIASES_TO_UPDATE_INCOME_STATEMENT,
  BALANCE_SHEET_NAME,
  BALANCE_SHEET_PROP,
  HIDE_ADDITIONAL_HISTORICAL_YEARS,
  HIDE_BTN,
  INCOME_STATEMENT_PROP,
  INCOME_STATEMENT_SHEET_NAME,
  SHOW_ADDITIONAL_HISTORICAL_YEARS,
  SHOW_BTN,
} from 'common/constants/financials';
import { useFormat } from 'common/hooks';
import { AllocationFinalMessage } from 'components';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { useFinancials } from 'context/FinancialsContext';
import { rowConfig as balanceSheetRowConfig, template as balanceTemplate } from 'pages/Financials/balance-sheet/data';
import { FinancialsButtons, FinancialsNameDialog, FinancialsVersionDialog } from 'pages/Financials/components/index';
import {
  rowConfig as incomeStatementRowConfig,
  template as incomeTemplate,
} from 'pages/Financials/income-statement/data';
import usePerformancemetricsData from 'pages/Financials/performance-metrics/usePerformanceMetricsData';
import {
  getVisibleColumnsFromPeriods,
  useFinancialsPageHelpers,
  useIncomeStatementOrBalanceSheetData,
  useKpi,
} from 'pages/Financials/utilities';
import { useKeyEventForFabButton } from 'services/hooks';
import { isValidDate } from 'utilities';
import WorkbookContext from '../../../components/ScalarSpreadsheet/utilities/WorkbookContext';
import KpiTable from '../kpi/components/KpiTable';

const sharedClass = 'financial-table';

const useStyles = makeStyles({
  additionalHistoricalYearsContent: {
    marginTop: '2em',
  },
  additionalHistoricalYearsTxt: {
    marginRight: '0.5em',
  },
  additionalHistoricalYearsLink: {
    marginLeft: '0.5em',
    marginBottom: '0.2em',
  },
});

const FinancialsTables = ({
  additionalHistoricalYears,
  changePeriodDate,
  collapsibleColumns,
  companyMeasurementDate,
  createVersion,
  fieldAttributes,
  filesParams,
  financialStatement,
  fiscalYearData,
  getVersions,
  measurementDate,
  measurementDates,
  notesParams,
  notShowingQuarters,
  periods,
  selectedVersion,
  setAdditionalHistoricalYears,
  setCollapsibleColumns,
  setFinancialStatement,
  setShowAdditionalHistoricalYears,
  showAdditionalHistoricalYears,
  currentFiscalYearEnd,
  setIsDisplayingRowNumbers,
  isAllocationFinal,
  isDisabled,
  setIsDisabled,
}) => {
  const classes = useStyles();
  const [format, formatDispatch] = useFormat();
  const tablesRef = useRef({});

  const { useAdjustedEBITDA, setBalanceSheetsData, setIncomeStatementData } = useFinancials();

  // Income Statement Data
  const incomeStatementConfig = useMemo(() => incomeStatementRowConfig(isDisabled), [isDisabled]);

  const {
    spreadsheet: incomeStatementSheet,
    rowGroups: incomeStatementRowGroups,
    setRowGroups: setIncomeStatementRowGroups,
  } = useIncomeStatementOrBalanceSheetData({
    fieldAttributes,
    isBalanceSheet: false,
    measurementDate,
    currentFiscalYearEnd,
    periods,
    setData: setIncomeStatementData,
    config: incomeStatementConfig,
    customTitlesData: {
      className: 'spreadsheet-table-title-financials',
      value: 'Income Statement',
    },
    tableTermsData: {
      columnName: 'Income Statement',
      pluralColumnName: 'Income Statements',
      tableName: 'Income Statement',
      tableSlug: 'income-statement',
    },
    useAdjustedEBITDA,
    isDisabled,
  });

  const { spreadsheet: performanceMetrics } = usePerformancemetricsData({
    periods,
    fiscalYearData,
    isDisabled,
    incomeStatementSheet,
  });

  // BalanceSheet Data
  const balanceSheetConfig = useMemo(() => balanceSheetRowConfig(isDisabled), [isDisabled]);
  const {
    spreadsheet: balanceSheet,
    rowGroups,
    setRowGroups,
  } = useIncomeStatementOrBalanceSheetData({
    fieldAttributes,
    isBalanceSheet: true,
    measurementDate,
    currentFiscalYearEnd,
    periods,
    setData: setBalanceSheetsData,
    config: balanceSheetConfig,
    customTitlesData: {
      className: 'spreadsheet-table-title',
      value: 'Balance Sheet',
    },
    tableTermsData: {
      columnName: 'Balance Sheet',
      pluralColumnName: 'Balance Sheet',
      tableName: 'Balance Sheet',
      tableSlug: 'balance-sheet',
    },
    isDisabled,
  });

  const { spreadsheet: kpiSheet, setTableData: setTableKpiData } = useKpi({
    periods,
    fieldAttributes,
    isDisabled,
  });

  const [spreadsheets, setSpreadsheets] = useState([]);

  const sheetsLoaded = useMemo(
    () =>
      incomeStatementSheet?.yLength === performanceMetrics?.yLength
      && balanceSheet
      && incomeStatementSheet?.tableData?.measurementDate?.id === balanceSheet?.tableData?.measurementDate?.id
      && !isEmpty(kpiSheet?.tableData),
    [incomeStatementSheet, performanceMetrics, balanceSheet, kpiSheet]
  );

  useEffect(() => {
    if (sheetsLoaded) {
      setSpreadsheets([incomeStatementSheet, performanceMetrics, balanceSheet, kpiSheet]);
    }
  }, [incomeStatementSheet, performanceMetrics, balanceSheet, sheetsLoaded, kpiSheet]);

  const { onChange, cells, data, workbook, evaluateSpreadsheets, workbookContextValue } = useWorkbook(spreadsheets);

  const {
    addFinancialYear,
    isNameDialogOpen,
    isNewVersionDialogOpen,
    saveAsNewVersion,
    saveStatementWithNewName,
    setIsNameDialogOpen,
    setOpenNewVersionDialog,
    showDeleteConfirmation,
    showOrHideEarliestHistoricalYears,
  } = useFinancialsPageHelpers({
    additionalHistoricalYears,
    collapsibleColumns,
    companyMeasurementDate,
    createVersion,
    filesParams,
    financialStatement,
    fiscalYearData,
    getVersions,
    measurementDate,
    measurementDates,
    notesParams,
    periods,
    selectedVersion,
    setAdditionalHistoricalYears,
    setCollapsibleColumns,
    setFinancialStatement,
    setShowAdditionalHistoricalYears,
    showAdditionalHistoricalYears,
    tableData: workbook,
    isDisabled,
    isAllocationFinal,
  });

  useKeyEventForFabButton(!(data && cells));

  const handleOnChange = useCallback(
    (cell, value) => {
      if ((cell.isNTM || cell.isLTM) && isValidDate(value)) {
        const updateCellsSynchronization = (updatedColumnsParam, originSheet) => {
          // column sync/desync now depends on which table the date changed
          spreadsheets.forEach(sheet => {
            if (originSheet === sheet.name) {
              sheet.reset({ columns: updatedColumnsParam });
            }
          });
          evaluateSpreadsheets(spreadsheets);
        };

        const updatedPeriods = changePeriodDate(cell.columnId, value, cell.sheet.name);
        const propsToUse = {
          [INCOME_STATEMENT_SHEET_NAME]: {
            accessedProperty: INCOME_STATEMENT_PROP,
            aliasesToUpdate: ALIASES_TO_UPDATE_INCOME_STATEMENT,
            template: incomeTemplate,
          },
          [BALANCE_SHEET_NAME]: {
            accessedProperty: BALANCE_SHEET_PROP,
            aliasesToUpdate: ALIASES_TO_UPDATE_IN_BALANCE_SHEET,
            template: balanceTemplate,
          },
        };

        onChange(cell, value);
        const updatedColumns = getVisibleColumnsFromPeriods({
          ...propsToUse[cell.sheet.name],
          periods: updatedPeriods,
        });
        updateCellsSynchronization(updatedColumns, cell.sheet.name);
      } else if (cell.isEditableTitleCell) {
        const {
          sheet: { tableData: kpiTableData },
        } = cell;
        const { companyKPIs } = kpiTableData;
        const kpiToChange = companyKPIs.find(kpi => kpi.alias === cell.alias);
        kpiToChange.name = value;
        onChange(cell, value);
      } else {
        onChange(cell, value);
      }
    },
    [changePeriodDate, evaluateSpreadsheets, onChange, spreadsheets]
  );

  const columnMarkedAsUpdated = useCallback(() => {
    evaluateSpreadsheets(spreadsheets);
  }, [spreadsheets, evaluateSpreadsheets]);

  useEffect(() => {
    document.addEventListener('columnUpdated', columnMarkedAsUpdated);
    return () => document.removeEventListener('columnUpdated', columnMarkedAsUpdated);
  }, [columnMarkedAsUpdated]);

  const sharedProps = useMemo(
    () => ({
      workbook,
      onChange: handleOnChange,
      collapsibleColumns,
      setCollapsibleColumns,
      customDeleteConfirmation: true,
      deleteColumn: showDeleteConfirmation,
      page: 'financials',
      className: sharedClass,
      notShowingQuarters,
      setIsDisplayingRowNumbers,
    }),
    [
      collapsibleColumns,
      handleOnChange,
      setCollapsibleColumns,
      showDeleteConfirmation,
      workbook,
      notShowingQuarters,
      setIsDisplayingRowNumbers,
    ]
  );

  const onScroll = element => event => {
    const scrollableContainers = document.querySelectorAll(`.${sharedClass}-scrollbar:not(#${event.target.id})`);

    scrollableContainers.forEach(scrollableItem => {
      let { scrollLeft } = element;

      if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
        scrollLeft = scrollableItem.scrollWidth;
      }

      scrollableItem.scrollLeft = scrollLeft;
    });
  };

  useLayoutEffect(() => {
    const scrollAreas = document.querySelectorAll(`.${sharedClass}-scrollbar`);

    if (scrollAreas.length) {
      scrollAreas.forEach(scrollArea => {
        scrollArea.addEventListener('scroll', onScroll(scrollArea));
      });
    }

    return () => {
      scrollAreas.forEach(scrol => {
        scrol.removeEventListener('scroll', onScroll(scrol));
      });
    };
  }, []);

  if (
    incomeStatementSheet?.data
    && performanceMetrics?.data
    && balanceSheet?.data
    && !isEmpty(kpiSheet?.cells)
    && cells
    && data
    && onChange
    && showOrHideEarliestHistoricalYears
    && financialStatement?.company_measurement_date === measurementDate?.cmd_id
  ) {
    window.appGlobal = {
      ...window.AppGlobal,
      allCells: cells,
    };
    return (
      <div ref={tablesRef}>
        {/* Income Statement */}
        {isDisabled && isAllocationFinal && (
          <AllocationFinalMessage
            variant
            tableTerms={{
              tableName: 'Financials Table',
            }}
            openProformaDialog={setOpenNewVersionDialog}
            setIsDisabled={setIsDisabled}
            finalAllocations={[]}
            savesAsProformaConfirmation={() => setOpenNewVersionDialog(true)}
            actions={[
              {
                label: 'Duplicate',
                buttonProps: {
                  variant: 'contained',
                  size: 'small',
                },
                callback: () => setOpenNewVersionDialog(true),
              },
            ]}
          />
        )}

        <WorkbookContext.Provider value={workbookContextValue}>
          <ScalarSpreadsheet
            {...incomeStatementSheet}
            {...sharedProps}
            rowGroups={incomeStatementRowGroups}
            setRowGroups={setIncomeStatementRowGroups}
            format={format}
            formatDispatch={formatDispatch}
            sheet={incomeStatementSheet}
          />
          {!isEmpty(additionalHistoricalYears) && <div style={{ margin: '1rem 0' }} />}
          {!isEmpty(additionalHistoricalYears) && (
            <div className={classes.additionalHistoricalYearsContent}>
              <span className={classes.additionalHistoricalYearsTxt}>
                {!showAdditionalHistoricalYears ? SHOW_ADDITIONAL_HISTORICAL_YEARS : HIDE_ADDITIONAL_HISTORICAL_YEARS}
              </span>
              {additionalHistoricalYears.map((item, idx) => {
                const isLastItem = idx === additionalHistoricalYears.length - 1;
                return <span key={item.year}>{`${item.year}${!isLastItem ? ', ' : ''}`}</span>;
              })}
              <Button color="primary" onClick={showOrHideEarliestHistoricalYears}>
                {!showAdditionalHistoricalYears ? SHOW_BTN : HIDE_BTN}
              </Button>
            </div>
          )}
          <br />
          <br />
          <br />
          {/* Performance Metrics */}
          <ScalarSpreadsheet {...performanceMetrics} {...sharedProps} format={format} sheet={performanceMetrics} />
          <br />
          <br />
          <br />
          {/* Balance Sheet */}
          <ScalarSpreadsheet
            {...balanceSheet}
            {...sharedProps}
            format={format}
            formatDispatch={formatDispatch}
            rowGroups={rowGroups}
            setRowGroups={setRowGroups}
            sheet={balanceSheet}
          />
          <br />
          <br />
          <br />
          <KpiTable
            kpiSheet={kpiSheet}
            sharedProps={sharedProps}
            setTableData={setTableKpiData}
            format={format}
            formatDispatch={formatDispatch}
            isDisabled={isDisabled}
          />
          <FinancialsButtons isDisabled={isDisabled} addFinancialYear={addFinancialYear} />
          <FinancialsNameDialog
            open={isNameDialogOpen}
            onClose={() => setIsNameDialogOpen(false)}
            onSave={saveStatementWithNewName}
            versionName={selectedVersion?.name}
          />
          <FinancialsVersionDialog
            open={isNewVersionDialogOpen}
            onClose={() => setOpenNewVersionDialog(false)}
            onSave={saveAsNewVersion}
          />
        </WorkbookContext.Provider>
      </div>
    );
  }

  return <GridSkeleton />;
};

const selectedVersionShape = PropTypes.shape({
  company_measurement_date: PropTypes.number,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  id: PropTypes.number,
  is_deleted: PropTypes.bool,
  name: PropTypes.string,
  show_additional_historical_years: PropTypes.bool,
  slug: PropTypes.string,
  updated_at: PropTypes.string,
  versioning: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
});

FinancialsTables.propTypes = {
  changePeriodDate: PropTypes.func,
  collapsibleColumns: PropTypes.shape({}),
  fieldAttributes: PropTypes.shape({
    balanceSheetAttrs: PropTypes.shape({}),
    incomeStatementAttrs: PropTypes.shape({}),
  }),
  measurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
  }),
  periods: PropTypes.arrayOf(PropTypes.shape({})),
  setCollapsibleColumns: PropTypes.func,
  additionalHistoricalYears: PropTypes.arrayOf(PropTypes.shape({})),
  companyMeasurementDate: PropTypes.shape({}),
  createVersion: PropTypes.func,
  filesParams: PropTypes.shape({}),
  notesParams: PropTypes.shape({}),
  financialStatement: PropTypes.shape({
    company_measurement_date: PropTypes.number,
  }),
  fiscalYearData: PropTypes.shape({}),
  getVersions: PropTypes.func,
  measurementDates: PropTypes.arrayOf(PropTypes.shape({})),
  notShowingQuarters: PropTypes.bool,
  selectedVersion: selectedVersionShape,
  setAdditionalHistoricalYears: PropTypes.func,
  setFinancialStatement: PropTypes.func,
  setShowAdditionalHistoricalYears: PropTypes.func,
  showAdditionalHistoricalYears: PropTypes.bool,
  showDeleteConfirmation: PropTypes.func,
  currentFiscalYearEnd: PropTypes.object,
  setIsDisplayingRowNumbers: PropTypes.func,
  isAllocationFinal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func,
};

export default FinancialsTables;
