import React, { FC, useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { financialUrl } from 'common/config/urls';
import { ValuationsParams } from 'pages/ValuationsAllocation/types';
import { getStringValue } from 'utilities';
import { RedirectToFinancialButtonProps } from './types';

const SAVE_FINANCIAL_STATEMENT_LABEL = 'Save Financial Statement';

const RedirectToFinancialButton: FC<RedirectToFinancialButtonProps> = params => {
  const { measurementDateSlug } = params;

  const history = useHistory();

  const { firmSlugParam, companySlugParam } = useParams<ValuationsParams>();

  const financialPageUrl = useMemo(
    () => financialUrl(getStringValue(firmSlugParam), getStringValue(companySlugParam)),
    [companySlugParam, firmSlugParam]
  );

  const redirectToFinancial = useCallback(() => {
    history.push(`${financialPageUrl}?date=${measurementDateSlug}`);
  }, [financialPageUrl, history, measurementDateSlug]);

  return (
    <Button color="primary" endIcon={<ArrowBackIcon />} onClick={redirectToFinancial} variant="outlined">
      {SAVE_FINANCIAL_STATEMENT_LABEL}
    </Button>
  );
};

export default RedirectToFinancialButton;
