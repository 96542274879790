import { isEmpty } from 'validate.js';
import slashedToIsoFormat from 'utilities/slashedToIsoFormat';
import { CONVERSION_DATE_ALIAS, PURCHASE_DATE_ALIAS, SALE_DATE_ALIAS, SOLD_PERCENT_ALIAS } from '../constants';

export const purchasedReverseParser = ({ purchasedCells, dividendsCalculator }) => {
  // group purchasedCells by acquisition_ref
  const groupedPurchasedCellsMap = Object.values(purchasedCells).reduce((acc, cell) => {
    acc[cell.acquisition_ref] = acc[cell.acquisition_ref] ? [...acc[cell.acquisition_ref], cell] : [cell];
    return acc;
  }, {});
  const acquisitions = Object.values(groupedPurchasedCellsMap).map(cells => {
    const acquisition = {
      sales: [],
    };
    cells.forEach(cell => {
      acquisition[cell.alias]
        = cell.alias === PURCHASE_DATE_ALIAS && cell.value ? slashedToIsoFormat({ label: cell.value }) : cell.value;
    });
    acquisition.dividends_per_share = dividendsCalculator(acquisition.purchase_date);
    return acquisition;
  });
  return acquisitions;
};

export const soldReverseParser = ({ soldCells, acquisitions }) => {
  const updatedAcquisitions = [...acquisitions];
  // add sales to acquisitions
  return updatedAcquisitions.map(acquisition => {
    const relevantCells = Object.values(soldCells).filter(cell => cell.acquisition_ref === acquisition.acquisition_ref);
    // group cells into an object by their row number
    const groupedCells = relevantCells.reduce((acc, cell) => {
      acc[cell.rowNumber] = acc[cell.rowNumber] ? [...acc[cell.rowNumber], cell] : [cell];
      return acc;
    }, {});
    const updatedSales = Object.values(groupedCells).map(cells => {
      const sale = {};
      cells.forEach(cell => {
        if (cell.alias === SOLD_PERCENT_ALIAS && cell.value) {
          sale[cell.alias] = Number(cell.value).toFixed(2);
        } else if (cell.alias === SALE_DATE_ALIAS && cell.value) {
          sale[cell.alias] = slashedToIsoFormat({ label: cell.value });
        } else {
          sale[cell.alias] = cell.value;
        }
      });
      return sale;
    });
    return { ...acquisition, sales: updatedSales };
  });
};

export const convertedReverseParser = ({ convertedCells, acquisitions }) => {
  const updatedAcquisitions = [...acquisitions];
  // add conversions to acquisitions
  return updatedAcquisitions.map(acquisition => {
    const relevantCells = Object.values(convertedCells).filter(
      cell => cell.acquisition_ref === acquisition.acquisition_ref
    );
    // group cells into an object by their row number
    const groupedCells = relevantCells.reduce((acc, cell) => {
      acc[cell.rowNumber] = acc[cell.rowNumber] ? [...acc[cell.rowNumber], cell] : [cell];
      return acc;
    }, {});
    const updatedConversions = Object.values(groupedCells).map(cells => {
      const conversion = {};
      cells.forEach(cell => {
        conversion[cell.alias]
          = cell.alias === CONVERSION_DATE_ALIAS && cell.value ? slashedToIsoFormat({ label: cell.value }) : cell.value;
      });
      return conversion;
    });
    return { ...acquisition, conversions: updatedConversions };
  });
};

export const commonReverseParser = ({ purchasedCells, soldCells, convertedCells, dividendsCalculator }) => {
  if (isEmpty(purchasedCells) && isEmpty(soldCells) && isEmpty(convertedCells)) return [];
  const acquisitions = purchasedReverseParser({ purchasedCells, dividendsCalculator });
  if (isEmpty(soldCells) && isEmpty(convertedCells)) return acquisitions;

  const acquisitionsPlusSales = soldReverseParser({ soldCells, acquisitions });
  if (isEmpty(convertedCells)) {
    return acquisitionsPlusSales;
  }
  return convertedReverseParser({ convertedCells, acquisitions: acquisitionsPlusSales });
};
