import { APPROACH_WEIGHTING_ALIAS } from 'common/constants/allocations';
import { WEIGHTING_PROBABILITIES_LOWER_THAN_100 } from 'common/constants/messages/validations';
import { roundToN } from 'utilities';

const customValidations = ({ cellsToValidate, cell, addFeedback, removeFeedback }) => {
  const { alias } = cell;
  if (alias === APPROACH_WEIGHTING_ALIAS) {
    const weightCells = Object.values(cellsToValidate).filter(c => c.alias === APPROACH_WEIGHTING_ALIAS);
    const weightTotal = weightCells
      .map(c => parseFloat(c.value) || 0)
      .reduce((total, nextVal) => total + Number(nextVal));
    if (roundToN(weightTotal) !== 1) {
      addFeedback(WEIGHTING_PROBABILITIES_LOWER_THAN_100);
    } else {
      removeFeedback(WEIGHTING_PROBABILITIES_LOWER_THAN_100);
    }
  }
};

export default customValidations;
