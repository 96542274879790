import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getObjectValue } from 'utilities';
import { createColumnsCalibrationPerformance, customParser, rowConfigCalibrationPerformance } from './config';
import reverseParser from './config/customParser/reverseParser';
import { CreatePerformanceMetricsCalibrationDataParams } from './types';
import { PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS } from '../../guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/sheetConfigs';
import afterCellChanged from '../../guidelinePublicCompanies/PerformanceMetrics/config/utils/afterCellChanged';
import conditions from '../../guidelinePublicCompanies/PerformanceMetrics/config/utils/conditions';

const useCreateCalibrationPerformanceMetrics = (params: CreatePerformanceMetricsCalibrationDataParams) => {
  const {
    financials,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    calibration,
    approach,
    company,
    customClasses,
    isDisabled,
    name,
    comparisons,
  } = params;

  const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approach);

  const columns = !isEmpty(financials)
    ? createColumnsCalibrationPerformance({
      valuationsApproachGpc,
      financials,
      gpcComparisonByDate: comparisons,
    })
    : [];

  const config = rowConfigCalibrationPerformance({
    company,
    customClasses,
    valuationsApproachGpc,
    isDisabled,
    gpcComparisonByDate: comparisons,
  });

  // Create Spreadsheet config
  const spreadsheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      columns: columns.splice(1) || [],
      currencyFormatter: true,
      format: undefined,
      name,
      page: PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
      parser: customParser,
      rowConfig: config,
      showToolbar: true,
      tableData: {
        isDisabled,
        calibration,
        columnsPerformanceCalibration,
        rowsPerformanceCalibration,
        gpcComparisonByDate: comparisons,
      },
      tableTerms: PERFORMANCE_METRICS_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
      totalParser: undefined,
      unitsFormatter: true,
      conditions: conditions as unknown as SpreadsheetConfig['conditions'],
      afterCellChanged: afterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
      reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
      initialObj: approach as unknown as SpreadsheetConfig['initialObj'],
    })
    : undefined;

  return { spreadsheet };
};

export default useCreateCalibrationPerformanceMetrics;
