/* eslint-disable no-param-reassign */
import React from 'react';
import { isEmpty, isNil } from 'lodash';
import { INVESTMENT_DATE_ALIAS } from 'common/constants/cap-table';
import {
  CASH_DISTRIBUTION_ALIAS,
  CUSTOM_SECURITY_NAME_ALIAS,
  CUSTOM_SECURITY_NAME_ROW_NUMBER,
  INVESTMENT_CAPITAL_ALIAS,
  LOAN_VALUE_ALIAS,
  PROCEEDS_FROM_SOLD_SHARES_ALIAS,
  SECURITY_ALIAS,
  SECURITY_ROW_NUMBER,
  SHARES_ALIAS,
} from 'common/constants/fund-ownership';
import { CONVERTIBLE_NOTES, OPTION, PREFERRED_STOCK as PREFERRED, WARRANT } from 'common/constants/securityTypes';
import { SelectValueViewer } from 'components';
import { addClassName, removeClassName, removeValidationClasses } from 'utilities';
import { gridShortDate } from 'utilities/datesFormats';
import isModeledAfterNote from './isModeledAfterNote';
import { LOAN_VALUE } from '../../cap-table/components/convertible-notes-ledger/config/constants';
import { CashDistributionLedger, SecurityList } from '../components';
import SharesLedger from '../components/shares-ledger/SharesLedger';

// tableData is object with keys capTable, allocation and fundOwnership
const conditions = ({ cell, cells, tableData, sheet }) => {
  const { alias, className, columnLegend } = cell;
  const { capTable } = tableData || {};
  const { columns } = sheet;

  let securityInfo = {};
  let multipleInvestments = [];
  let hasMultipleInvestmentDates = false;

  if (!cell.key) {
    return cell;
  }

  if (cells.fundOwnership) {
    cells = cells.fundOwnership;
  }

  const { value } = cells[columnLegend + SECURITY_ROW_NUMBER] || {};

  if (capTable?.securities && value) {
    securityInfo = capTable.securities.find(s => Number(s.id) === Number(value)) || {};

    if (securityInfo) {
      multipleInvestments = securityInfo.multiple_investments || [];
      hasMultipleInvestmentDates = multipleInvestments.length > 1;
    }
  }

  const resetCellConfig = () => {
    cell.hidden = true;
    cell.readOnly = true;
    cell.forceComponent = false;
    cell.disableEvents = false;
    cell.component = null;
    cell.dialog = null;
    cell.className = removeClassName(removeValidationClasses(className), 'read-only--white');
  };

  const isSecurityType = type => Number(securityInfo.security_type) === type;

  const modeledAfterConvertibleNote = isModeledAfterNote({ securityInfo });

  const currentCellFO = columns.find(fod => fod.order === cell.columnOrder);
  const currentCustomSecurityName
    = currentCellFO?.custom_security_name || currentCellFO?.emptyColumn?.custom_security_name;
  const isCustomSecurity = !isNil(currentCustomSecurityName);

  const investmentDates = () => {
    if (!isEmpty(securityInfo) && isSecurityType(PREFERRED)) {
      if (hasMultipleInvestmentDates) {
        return multipleInvestments.map(investment => investment.date);
      }

      if (securityInfo.investment_date) {
        return gridShortDate(securityInfo.investment_date);
      }
    }

    return null;
  };

  const getSharesLedgerProps = (includeDates = true) => {
    cell.relatedSecurity = securityInfo.id;
    cell.isCustomSecurity = isCustomSecurity;
    if (modeledAfterConvertibleNote) {
      cell.modeledAfterConvertibleNote = modeledAfterConvertibleNote;
    } else if (includeDates) {
      cell.investmentDates = investmentDates();
    }
    return null;
  };

  const defaultSharesLedgerProps = () => {
    cell.disableBackdropClick = false;
  };

  const isLoanSecurity = () => {
    const equityModel = capTable.securities
      .find(({ security_ref }) => security_ref === securityInfo.security_group_ref)
      ?.convertible_notes?.find(
        ({ security_group_ref }) => security_group_ref === securityInfo.security_ref
      )?.model_as_equity;
    return equityModel === LOAN_VALUE;
  };

  const sharesValueProps = () => {
    cell.readOnly = true;
    cell.dialog = {
      content: <SharesLedger {...getSharesLedgerProps()} />,
      maxWidth: 'lg',
    };
    cell.className = addClassName(className, 'read-only--white');
    defaultSharesLedgerProps();
  };

  // 02. security
  if (alias === SECURITY_ALIAS) {
    const titleCellKey = `${cell.columnLegend}1`;
    if (isCustomSecurity) {
      // Is Custom Security
      cell.gridType = 'string';
      cell.dbType = 'string';
      cell.placeholder = 'ENTER DATA';
      cell.dataEditor = null;
      cell.valueViewer = null;
      const customSecurityName = cell.value || '';
      const customSecurityNameCellKey = `${cell.columnLegend}${CUSTOM_SECURITY_NAME_ROW_NUMBER}`;
      cells[customSecurityNameCellKey].value = customSecurityName;
      cells[titleCellKey].value = customSecurityName;
    } else {
      cell.defaultValue = null;
      cell.gridType = 'number';
      cell.dbType = 'number';
      cell.dataEditor = props => <SecurityList {...props} />;
      cell.valueViewer = props => <SelectValueViewer {...props} />;
      const securityName = capTable.securities.find(s => Number(s.id) === Number(cell.value))?.name || '';
      cells[titleCellKey].value = securityName;
    }
  }

  // 04. Investment Date
  if (alias === INVESTMENT_DATE_ALIAS) {
    resetCellConfig();
    cell.hidden = false;
    cell.disableEvents = true;
    cell.isRequired = false;
    cell.dialog = {
      content: <SharesLedger {...getSharesLedgerProps(false)} />,
      maxWidth: 'lg',
    };
    cell.className = addClassName(className, 'read-only--white');

    if (!isEmpty(securityInfo) && isSecurityType(PREFERRED)) {
      if (securityInfo.has_dividends) {
        if (hasMultipleInvestmentDates) {
          // Set security investment dates as dropdown
          cell.dialog = {
            content: <SharesLedger {...getSharesLedgerProps()} />,
            maxWidth: 'lg',
          };
        } else {
          // Set security investment date as readonly
          cell.readOnly = true;
          cell.value = investmentDates();
          cell.className = removeClassName(className, 'read-only--white');
          cell.dialog = {
            content: <SharesLedger {...getSharesLedgerProps()} />,
            maxWidth: 'lg',
          };
        }
      }
    }

    defaultSharesLedgerProps();
  }

  // 05. Investment Capital, 06. Shares, 12. Proceeds from old shares
  if ([INVESTMENT_CAPITAL_ALIAS, SHARES_ALIAS, PROCEEDS_FROM_SOLD_SHARES_ALIAS].includes(alias)) {
    sharesValueProps();
  }

  if (alias === INVESTMENT_CAPITAL_ALIAS) {
    cell.greaterThan = 0;
    if ((isSecurityType(CONVERTIBLE_NOTES) && isLoanSecurity()) || isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      cell.greaterThan = undefined;
    }
  }

  if (alias === LOAN_VALUE_ALIAS && isLoanSecurity()) {
    sharesValueProps();
  }

  // 11.  Cash Distributions
  if (alias === CASH_DISTRIBUTION_ALIAS) {
    cell.hidden = false;
    cell.readOnly = true;
    cell.dialog = {
      content: <CashDistributionLedger />,
    };
    cell.className = addClassName(className, 'read-only--white');
  }

  // 15.  Custom Security Name
  if (alias === CUSTOM_SECURITY_NAME_ALIAS) {
    if (!isCustomSecurity) {
      cell.value = '';
    }
  }
  return cell;
};

export default conditions;
