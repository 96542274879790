import { getKey, onCellsChanged } from 'utilities';

export default async (ledgerData = [], colConfig = [], rowConfig = [], tableId = '') => {
  const changes = [];
  let cells = {};

  rowConfig.forEach((row, rowIdx) => {
    colConfig.forEach((col, colIdx) => {
      const key = getKey(col.alias, rowIdx, tableId);
      const ledgerCell = ledgerData[colIdx] || {};
      const value = ledgerData[colIdx]?.value || null; // Get the value from the table cells

      const cell = {
        ...row,
        ...ledgerCell,
        key,
        alias: col.alias,
        rowAlias: row.alias || '',
        colAlias: col.alias || '',
        insideLedger: true,
        rowNumber: rowIdx,
        colNumber: colIdx,
        tableId,
      };

      cells[key] = cell;
      changes.push({ cell, value });
    });
  });

  cells = onCellsChanged(changes, cells);

  return cells;
};
