import PropTypes from 'prop-types';
import formatNumber from '../../../utilities/formatNumbers';

const SharesOutstandingValue = ({ cell, state }) => {
  const options = state[cell.dataSourceKey].value || [];
  let totalShares = 0;

  options.forEach(option => {
    // 1.   Sum all the shares
    totalShares += Number(option.shares);
  });

  return formatNumber({ ...cell, value: totalShares });
};

SharesOutstandingValue.propTypes = {
  options: PropTypes.array,
};

export default SharesOutstandingValue;
