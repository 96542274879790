import { ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED, STATUSES_LIST } from 'common/constants/allocations';
import { getSlugValue } from 'utilities';

export const VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_LABEL = 'Make All Final';
export const VALUATIONS_SUMMARY_MAKE_ALL_ALLOCATIONS_FINAL_MESSAGE
  = 'Are you sure you want to make all allocations final?';
export const VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_LABEL = 'Make Final';
export const VALUATIONS_SUMMARY_MAKE_ALLOCATION_FINAL_MESSAGE = 'Are you sure you want to make this allocation final?';
export const VALUATIONS_SUMMARY_NO_PUBLISHED_ALLOCATIONS_FOUND = 'No published or final allocations found';
export const VALUATIONS_SUMMARY_NO_VALUATION_METHODS_PROVIDED = 'No valuation methods provided';
export const VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX = 'company';
export const VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME = 'Column';
export const VALUATIONS_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const VALUATIONS_SUMMARY_SPREADSHEET_TITLE = 'Valuations Summary';
export const VALUATIONS_SUMMARY_SPREADSHEET_SLUG = getSlugValue(VALUATIONS_SUMMARY_SPREADSHEET_TITLE);
export const VALUATIONS_SUMMARY_SPREADSHEET_TABLE = `${VALUATIONS_SUMMARY_SPREADSHEET_SLUG}-table`;
export const VALUATIONS_SUMMARY_WEIGHTED_APPROACH = 'Weighted approach';
export const VALUATIONS_SUMMARY_WEIGHTED_APPROACH_PLURAL = 'Weighted approaches';

export const VALUATIONS_SUMMARY_ALLOCATIONS_STATUSES = Object.freeze([
  {
    label: STATUSES_LIST[ALLOCATIONS_PUBLISHED],
    value: ALLOCATIONS_PUBLISHED,
  },
  {
    label: STATUSES_LIST[ALLOCATIONS_FINAL],
    value: ALLOCATIONS_FINAL,
  },
]);
