import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { MenuOptionsProps } from './types';

const MenuOption = (props: MenuOptionsProps) => {
  const { mainAction, tabBarId, option, optionIndex, onClick, classes } = props;

  const onClickHandler = useCallback(() => {
    if (!option.disabled) {
      onClick(option);
    }
  }, [onClick, option]);

  return (
    <MenuItem
      id={`${mainAction?.id ?? tabBarId}-action-item-${optionIndex + 1}`}
      onClick={onClickHandler}
      className={option.disabled ? classes.disabled : ''}>
      {option.label}
    </MenuItem>
  );
};

export default MenuOption;
