/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React from 'react';
import * as constants from 'common/constants/financials';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { EqualSymbol, NotEqualSymbol } from 'icons';
import reverseParser from 'pages/Financials/balance-sheet/utilities/reverseParser';
import { FinancialsDate } from 'pages/Financials/components';
import { columnUpdateActionCallback } from 'pages/Financials/utilities';
import { gridShortDate, isValidDate } from 'utilities';

const conditions = ({ cell, cells: scope, onCellsChanged, tableData }) => {
  const { alias, isLTM } = cell;
  const { isDisabled } = tableData;

  const addAction = ({ title, isEqual, callback, buttonText, position = 'right' }) => {
    cell.action = {
      position,
      icon: isEqual ? <EqualSymbol /> : <NotEqualSymbol />,
      tooltip: {
        visible: !isEqual,
        title,
        buttonText,
      },
      callback,
    };
  };

  const enableUpdateColumn = args => {
    if (cell.needs_actualization) {
      addAction({
        ...args,
        title: constants.COLUMN_UPDATED_ACTION_TITLE,
        isEqual: true,
        callback: () =>
          columnUpdateActionCallback({
            cell,
            onCellsChanged,
            scope,
            reverseParser,
          }),
        buttonText: constants.COLUMN_UPDATED,
      });
    }
  };

  const enableEditableDate = () => {
    if (isValidDate(cell.value)) {
      cell.readOnly = isDisabled;
      cell.value = gridShortDate(cell.value);
      cell.className = 'date-editor-white';

      cell.gridDateComponent = FinancialsDate;
      cell.dataEditor = props => <GridDateEditor {...props} />;
      cell.valueViewer = props => <GridDateValueViewer {...props} />;
    }
  };

  switch (alias) {
    case constants.FIRST_ROW_ALIAS:
      if (cell.rowSpan === 3) {
        enableUpdateColumn({ position: 'center' });
      }
      break;
    case constants.SECOND_ROW_ALIAS:
      if (isLTM) {
        enableEditableDate();
      } else {
        enableUpdateColumn();
      }
      break;
    default:
      break;
  }

  return cell;
};

export default conditions;
