import { getStringValue } from 'utilities/getStringValue';
import { GetFieldErrorParams } from './types';

const getFieldError = (params: GetFieldErrorParams) => {
  const { error, invalid, label, name } = params;

  if (!invalid) return {};

  return {
    [name]: [getStringValue(label ? `${label} ${error?.message}` : error?.message)],
  };
};

export default getFieldError;
