import { ValuationApproachGPC, ValuationApproachGPT } from 'api';
import { SpecificApproach } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { getArrayValue, getObjectValue } from 'utilities';

const getCompaniesOrTransactions = (specificApproach: SpecificApproach) => {
  const { gpc_comparison: GPCComparison } = getObjectValue(specificApproach as ValuationApproachGPC);
  const { gpt_transactions: GPTTransactions } = getObjectValue(specificApproach as ValuationApproachGPT);

  return getArrayValue(GPCComparison ?? GPTTransactions);
};

export default getCompaniesOrTransactions;
