import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { ONE_HUNDRED_VALUE } from 'common/config/app';
import { InfoCard } from 'components';
import { getNumberValue } from 'utilities';
import { DialogHeaderProps } from './types';

const useStyles = makeStyles(() => ({
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const DialogHeader: FC<DialogHeaderProps> = props => {
  const { effectiveTaxRateInfo } = props;
  const classes = useStyles();
  const efectiveTaxRateInfoValue = getNumberValue(Number(effectiveTaxRateInfo));

  return (
    <div className={classes.infoCards}>
      <InfoCard title="TAX RATE LEDGER" body={`${(efectiveTaxRateInfoValue * ONE_HUNDRED_VALUE).toFixed(1)} %`} />
    </div>
  );
};

export default DialogHeader;
