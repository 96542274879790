import { ConfirmationDialog } from 'components/Dialogs';

const getCancelDialogOptions = (ContentComponent, callback) => ({
  wrapper: ConfirmationDialog,
  content: ContentComponent,
  actions: [
    {
      label: 'Cancel',
      type: 'cancel',
    },
    {
      label: 'Delete',
      variant: 'contained',
      type: 'danger',
      callback,
    },
  ],
});

export default getCancelDialogOptions;
