import { getArrayValue } from 'utilities/getArrayValue';
import { GenerateTotalExpressionParams } from './types';

const generateTotalExpression = (params: GenerateTotalExpressionParams) => {
  const { generateIndex = true, identifier = '@', items = [], skippedIndexes = 0 } = params;

  return getArrayValue(
    items?.map(
      (item, companyIndex: number) =>
        `${identifier}${(generateIndex ? companyIndex : (item as number)) + skippedIndexes}`
    )
  );
};

export default generateTotalExpression;
