import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IconButton, Menu, MenuItem, Popper } from '@material-ui/core';
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ConfirmationDelete, confirmationWithRadioWrapper } from 'components/Dialogs';
import { DialogContext } from 'context';
import { kpiDataTypes } from 'pages/Financials/kpi/config/constants';
import { useResponse } from 'services/hooks';
import theme from 'theme';
import { capitalizeWords } from 'utilities';
import { getColumnLetter } from 'utilities/alphabet-utilities';
import getCancelDialogOptions from 'utilities/getCancelDialogOptions';
import AddMultipleMenuItem from './AddMultipleMenuItem';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';
import { copyToClipboard, getCellRef, getValuesToCopy, hasCellRowSpan } from '../utilities/utilities';

const useStyles = makeStyles(() => ({
  rowIndicator: {
    position: 'absolute',
    left: '0',
    width: '12px',
    backgroundColor: theme.palette.primary[500],
    borderRadius: '3px 0px 0px 3px',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  menuButton: {
    color: theme.palette.white,
    padding: '0px',
  },
  rowIndicatorIcon: {
    fill: theme.palette.white,
    width: 'auto !important',
    fontSize: '0.8rem',
  },
  deleteOption: {
    color: theme.palette.pink,
  },
}));

const RowIndicator = ({ tableRef }) => {
  const classes = useStyles();
  const [showIndicator, setShowIndicator] = useState(false);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cell, setCell] = useState(null);
  const [multipleValue, setMultipleValue] = useState(0);
  const {
    selectedCell,
    setSelectedCell,
    cells,
    displayRowIndicator,
    allowReorderRows,
    allowCopyRows,
    allowAddMultipleRows,
    addMultipleRows,
    allowAddSingleRow,
    addSingleRow,
    displayLegend,
    activeCell,
    tableData,
    allowDeleteRow,
    deleteRowFn,
    allowChangeDataType,
    changeRowDataTypeFn,
  } = useContext(FeaturedSpreadsheetContext);
  const { infoNotification, errorNotification } = useResponse();
  const dialogContext = useContext(DialogContext);

  const getUpdatedCell = useCallback(
    cellInfo => {
      if (!cellInfo || !cells) return null;
      const { key } = cellInfo;
      const updatedCell = cells[key];
      return updatedCell || cellInfo;
    },
    [cells]
  );

  useEffect(() => {
    if (!selectedCell || !displayRowIndicator) return;
    const { start, end, cellInfo } = selectedCell;
    const updatedCell = getUpdatedCell(cellInfo);
    if (!updatedCell) return;
    setCell(updatedCell);
    const { j: startColumn, i: startRow } = start;
    const { i: endRow } = end;
    // This condition will prevent to show the column indicator when the user has select more than one row.
    if (startRow === endRow) {
      const columnLetter = getColumnLetter(startColumn);
      const cellKey = cellInfo.key || `${columnLetter}${startRow}`;
      const cellRef = getCellRef(tableRef, cellKey);
      const hasRowSpan = hasCellRowSpan(cells, cellKey, cellRef);
      if (!hasRowSpan && cellRef) {
        const extraOffsetTop = isEmpty(activeCell) ? 0 : 25;
        setIndicatorPosition({
          top: cellRef.offsetTop + extraOffsetTop,
          height: cellRef.offsetHeight,
        });
        setShowIndicator(true);
      } else {
        setShowIndicator(false);
      }
    }
  }, [selectedCell, cells, tableRef, displayRowIndicator, displayLegend, activeCell, getUpdatedCell]);

  const onCloseDropdown = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleOnMoreOptionsClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleOnCopyClick = useCallback(() => {
    onCloseDropdown();
    if (!selectedCell) return;
    const { rowNumber } = selectedCell;
    if (rowNumber) {
      const valuesToCopy = getValuesToCopy(cells, rowNumber, false);
      copyToClipboard(valuesToCopy, infoNotification, errorNotification);
    }
  }, [cells, selectedCell, infoNotification, errorNotification, onCloseDropdown]);

  const handleOnAddSingleRowClick = useCallback(() => {
    onCloseDropdown();
    addSingleRow();
  }, [addSingleRow, onCloseDropdown]);

  const handleOnAddMultipleClick = () => {
    onCloseDropdown();
    addMultipleRows(multipleValue);
  };

  const removeIndicator = useCallback(() => {
    setSelectedCell(undefined);
    setShowIndicator(false);
  }, [setSelectedCell]);

  const deleteRowCb = useCallback(
    company => {
      deleteRowFn(company);
      removeIndicator();
    },
    [deleteRowFn, removeIndicator]
  );

  const showDeleteDialog = useCallback(
    (company, preferredMessage = null) => {
      const companyName = company.target_name || company.name;
      const CUSTOM_MESSAGE = `Are you sure you want to delete ${
        company.target_name === '' ? 'this' : ''
      } ${companyName}?`;
      const { showDialog } = dialogContext;
      const ContentComponent = <ConfirmationDelete customMessage={preferredMessage || CUSTOM_MESSAGE} />;
      showDialog(
        getCancelDialogOptions(
          () => ContentComponent,
          () => deleteRowCb(company)
        )
      );
    },
    [dialogContext, deleteRowCb]
  );

  const onDeleteTransactionCompany = useCallback(() => {
    const {
      approach: { valuations_approach_gpt: valuationsApproachGpt },
    } = tableData;
    if (valuationsApproachGpt && cell) {
      const { gpt_transactions: gptTransactions } = valuationsApproachGpt;
      if (gptTransactions) {
        const transaction = gptTransactions.find(item => item.row_ref === cell.alias || item.row_ref === cell.row_ref);
        if (transaction) {
          showDeleteDialog(transaction);
        }
      }
    }
  }, [cell, tableData, showDeleteDialog]);

  const onDeleteComparisonCompany = useCallback(() => {
    const {
      approach: { valuations_approach_gpc: valuationsApproachGpc },
    } = tableData;
    if (valuationsApproachGpc && cell) {
      const { gpc_comparison: gpcComparison } = valuationsApproachGpc;
      if (gpcComparison) {
        const comparison = gpcComparison.find(({ cap_iq_id: capIdId }) =>
          [cell.alias, cell.titleAlias].includes(capIdId)
        );
        if (comparison) {
          showDeleteDialog(comparison);
        }
      }
    }
  }, [cell, tableData, showDeleteDialog]);

  const onDeleteKpi = useCallback(() => {
    const { companyKPIs } = tableData;
    if (companyKPIs?.length && cell) {
      const kpi = companyKPIs.find(({ alias }) => cell.alias === alias);
      if (kpi) {
        showDeleteDialog(
          kpi,
          'Are you sure to want delete this KPI? Deleting it will affect all the measurement dates for this company.'
        );
      }
    }
  }, [cell, tableData, showDeleteDialog]);

  const handleOnDeleteRowClick = useCallback(() => {
    onCloseDropdown();
    if (!cell) return;
    if (cell.isGptRow) {
      onDeleteTransactionCompany();
    } else if (cell.isGpcRow) {
      onDeleteComparisonCompany();
    } else if (cell.isKpiRow) {
      onDeleteKpi();
    }
  }, [cell, onDeleteTransactionCompany, onDeleteComparisonCompany, onDeleteKpi, onCloseDropdown]);

  const handleChangeDataType = useCallback(() => {
    if (!cell) return;
    const { showDialog, wrapperProps } = dialogContext;
    const currentDataType = cell.sheet.tableData.companyKPIs.find(({ alias }) => alias === cell.alias).data_type;
    const dataTypeDialogProps = {
      confirmationBaseFn: changeRowDataTypeFn,
      options: kpiDataTypes.map(([value, label]) => ({ value, label: capitalizeWords(label) })),
      cell,
      dialogTitle: 'KPI data types',
      defaultValue: currentDataType,
      open: true,
      onClose: wrapperProps.onClose,
      size: 'xs',
    };
    const wrapper = confirmationWithRadioWrapper(dataTypeDialogProps);
    showDialog({ wrapper });
    onCloseDropdown();
  }, [cell, changeRowDataTypeFn, dialogContext, onCloseDropdown]);

  const allowDelete = useMemo(
    () => allowDeleteRow && deleteRowFn && (cell?.isGptRow || cell?.isGpcRow || cell?.isKpiRow),
    [allowDeleteRow, deleteRowFn, cell]
  );

  // With this function we will determine if do we have to show or not the dropdown menu on the row indicator
  const showMenuOptions = useMemo(() => allowCopyRows || allowDelete, [allowCopyRows, allowDelete]);

  if (!showIndicator) return null;

  return (
    <div
      id={`row-indicator-${cell?.key}`}
      className={classes.rowIndicator}
      style={{ top: indicatorPosition.top, height: indicatorPosition.height }}>
      {showIndicator && allowReorderRows && (
        <IconButton className={classes.menuButton}>
          <MoreVert className={classes.rowIndicatorIcon} />
        </IconButton>
      )}
      {showIndicator && showMenuOptions && (
        <IconButton onClick={handleOnMoreOptionsClick} className={classes.menuButton}>
          <KeyboardArrowRight className={classes.rowIndicatorIcon} />
        </IconButton>
      )}
      {showMenuOptions && (
        <Popper open={open} anchorEl={anchorEl} id={open ? 'transitions-popper' : undefined}>
          <Menu
            className={classes.menuList}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseDropdown}>
            {allowCopyRows && <MenuItem onClick={handleOnCopyClick}>COPY</MenuItem>}
            {allowAddSingleRow && addSingleRow && <MenuItem onClick={handleOnAddSingleRowClick}>ADD NEW</MenuItem>}
            {allowAddMultipleRows && addMultipleRows && (
              <AddMultipleMenuItem
                onAddMultiple={handleOnAddMultipleClick}
                multipleValue={multipleValue}
                setMultipleValue={setMultipleValue}
              />
            )}
            {allowChangeDataType && <MenuItem onClick={handleChangeDataType}>CHANGE DATA TYPE</MenuItem>}
            {allowDelete && (
              <MenuItem className={classes.deleteOption} onClick={handleOnDeleteRowClick}>
                DELETE
              </MenuItem>
            )}
          </Menu>
        </Popper>
      )}
    </div>
  );
};

RowIndicator.propTypes = {
  tableRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default RowIndicator;
