/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty, isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFormat } from 'common/hooks';
import { MessageBox } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import ScalarDatasheet from 'components/ScalarDatasheet';
import UnsavedChanges from 'context/UnsavedChanges';
import { useTableValidation } from 'services/hooks';
import { compareChanges as compareChangesUtility, onCellsChanged, removeClassName } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { Cell, ConfirmUnsavedChanges, Row, ValueEditor } from './components';
import SpreadsheetContext from './context/SpreadsheetContext';
import { hideByVisibility, renderTemplate } from './utilities';
import { GridRowLabel, GridSkeleton } from '../Grid';

const Spreadsheet = ({
  className,
  tableTerms,
  tableData,
  rowConfig,
  parser,
  dbColumns,
  columns,
  parsedSecurities,
  setParsedSecurities,
  hasColTitle,
  showTotalColumn,
  conditions,
  compareChanges,
  disabled,
  validations,
  rowGroups,
  setRowGroups,
  showPlaceholder,
  toggleRows,
  page,
}) => {
  const { action, setAction } = useContext(UnsavedChanges);
  const { validateCells } = useTableValidation();
  const [placeholders] = useState([]);
  const [grid, setGrid] = useState(null);
  const [gridLabel] = useState(true);
  const totalColumns = useMemo(
    () =>
      1 // column title
      + (columns ? columns.length : 0)
      + placeholders.length
      + (showTotalColumn ? 1 : 0),
    [columns, placeholders, showTotalColumn]
  );
  const alphabet = alphabetGenerator([], totalColumns);

  const [{ currency }] = useFormat({ page });

  const setHasChanges = () => {
    setAction({
      wrapper: ConfirmationDialog,
      content: ConfirmUnsavedChanges,
    });
  };

  const checkChanges = parsedSecurities => {
    if (
      isArray(columns) // valid columns
      && !action // ignore if has change
      && !disabled // ignore if all data is disabled
    ) {
      parser({
        columns: dbColumns || columns,
        placeholders,
        rowConfig,
        tableData,
      }).then(initialParsedColumns => {
        if (compareChanges) {
          compareChanges(initialParsedColumns, parsedSecurities);
        } else {
          const hasChanges = compareChangesUtility(initialParsedColumns, parsedSecurities);

          if (hasChanges) {
            setHasChanges();
          } else {
            setAction(false);
          }
        }
      });
    }
  };

  const generateGrid = rowConfig => {
    if (columns) {
      let updatedRows = null;
      const visibleRows = rowConfig.filter(item => item.isVisible !== false);
      updatedRows = visibleRows.map((row, rowIndex) => {
        const newRow = [];
        for (let colIndex = 0; colIndex <= totalColumns; colIndex += 1) {
          const colLegend = colIndex > 0 ? alphabet[colIndex - 1] : alphabet[colIndex];
          const cellKey = colLegend + rowIndex;
          const isCorner = rowIndex === 0 && colIndex === 0;
          const isColLegend = rowIndex === 0 && colIndex > 0; // A, B, C... dont edit
          const isRowLegend = colIndex === 0 && rowIndex > 0; // 1, 2, 3 ... dont edit
          const isRowTitle = colIndex === 1 && rowIndex > 0; // First column with labels
          const isColTitle = rowIndex === 1 && colIndex > 0;
          const isTableBody = colIndex > 1 && rowIndex > 0;
          const isLastColumn = colIndex === totalColumns;
          const totalLabel = 'TOTAL';
          let col = { readOnly: true };
          col.currencyCode = currency.code;

          if (isCorner) {
            col.value = '';
            col.className = gridLabel ? 'legend col-legend row-legend' : 'legend-hidden';
          }

          // A, B, C...
          if (isColLegend) {
            col.value = colLegend;
            col.className = `legend col-legend ${gridLabel ? '' : 'legend-hidden'}`;

            if (colIndex > 1) {
              col.isLegend = isColLegend;
              col.disableEvents = true;
            }
          }

          // First vertical column 1, 2, 3 ...
          if (isRowLegend) {
            col.value = rowIndex;
            col.className = `legend row-legend ${gridLabel ? '' : 'legend-hidden'}`;
          }

          // First column with labels
          if (isRowTitle) {
            col = {
              ...rowConfig[rowIndex],
              component: <GridRowLabel cell={{ ...rowConfig[rowIndex], ...col }} />,
              forceComponent: true,
              className: 'rowTitles',
            };
          }

          if (isTableBody && parsedSecurities[cellKey]) {
            // add values and row configuration properties to each cell
            col = {
              ...col,
              ...row,
              ...parsedSecurities[cellKey],
            };

            //  (needs to be created first)
            if (col.colType === 'placeholder' || col.colType === 'total') {
              col.readOnly = true;
              col.forceComponent = false;
            }

            // render cells templates
            if (col.template) {
              col.component = renderTemplate({
                template: col.template,
                cell: col,
                state: parsedSecurities,
                setState: setParsedSecurities,
                // eslint-disable-next-line no-use-before-define
                onCellsChanged,
                columns,
              });
            }

            if (col.component) {
              col.component = React.cloneElement(
                // component
                col.component,
                // new props
                {
                  cell: col,
                  disabled,
                },
                // new childrens
                null
              );
            }
          } // end of body

          // Rewrite row titles styles
          if (isRowTitle) {
            col.readOnly = true;
            col.className = rowConfig[rowIndex].className ? `${rowConfig[rowIndex].className} row-label` : 'row-label';
          }

          // Render table header
          if (hasColTitle && isColTitle) {
            if (col.className) {
              col.className += ' table-header';
            } else {
              col.className = 'table-header';
            }
          }

          // Render total column
          if (showTotalColumn && isLastColumn) {
            col.forceComponent = false;
            col.readOnly = true;
            col.isRequired = false;
            col.isRequired = false;

            delete col.dialog;

            if (isColTitle) {
              col.value = totalLabel;
            }
          }

          if (isLastColumn) {
            col.readOnly = true;
          }

          if (disabled) {
            col.readOnly = true;
            col.className = removeClassName(col.className, 'read-only--white');
          }

          newRow.push(col);
        } // colConfig

        return newRow;
      }); // rowTitles

      updatedRows = hideByVisibility(updatedRows, rowConfig);

      setGrid(updatedRows);
    }
  };

  const applyExpressions = async (changes, state) => {
    const computedCells = await onCellsChanged(changes, state);

    return computedCells;
  };

  const applyConditions = state => {
    const tmpState = { ...state };

    if (conditions) {
      const tmpCells = Object.entries(state).map(([, cell]) => cell);
      tmpCells.forEach(cell => conditions(cell, tmpState, tableData));
    }

    return tmpState;
  };

  const applyValidations = async (changes, state) => {
    const tmpState = { ...state };

    // Prepare data to be validated
    const cellsToValidate = changes.map(change => state[change.cell.key]);

    // Validate cells
    const validationsData = validateCells({
      cellsToValidate,
      customValidations: validations,
      tableData,
      parsedColumns: tmpState,
    });

    if (validationsData) {
      const { validatedCells } = validationsData;

      // Update state
      validatedCells.forEach(cell => {
        tmpState[cell.key] = cell;
      });
    }

    return tmpState;
  };

  // Compute cell formulas
  const computeCellsChanges = async changes => {
    let tmpState = { ...parsedSecurities };

    // Apply expressions
    tmpState = await applyExpressions(changes, tmpState);

    // Apply conditions
    tmpState = await applyConditions(tmpState);

    // Apply validations
    tmpState = await applyValidations(changes, tmpState);

    // Update state
    setParsedSecurities(tmpState);

    checkChanges(tmpState);

    if (toggleRows) {
      toggleRows(tmpState);
    }
  };

  const applyParser = async () => {
    const tmpParsedSecurities = await parser({
      columns,
      placeholders,
      rowConfig,
      tableData,
      currencyCode: currency.code,
    });
    const parsedSecuritiesWithConditions = await applyConditions(tmpParsedSecurities);
    setParsedSecurities(parsedSecuritiesWithConditions);
  };

  const fetchData = async () => {
    if (columns && columns.length > 0 && parser) {
      try {
        await applyParser();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  // set expressions at start and when securities updates
  useEffect(() => {
    fetchData();
  }, [columns]);

  useEffect(() => {
    if (!disabled) {
      if (
        isArray(dbColumns)
        && isArray(columns)
        && !isEmpty(dbColumns)
        && !isEqual(sortBy(dbColumns, 'id'), sortBy(columns, 'id'))
      ) {
        setHasChanges();
      } else {
        setAction(false);
      }
    }
  }, [columns, dbColumns]);

  // when parsedSecurities updates
  useEffect(() => {
    if (!isEmpty(parsedSecurities)) {
      generateGrid(rowConfig);
    } else {
      setGrid(null);
    }
  }, [parsedSecurities, rowConfig, rowGroups, disabled]);

  // Render empty message
  if (showPlaceholder) {
    return (
      <>
        <br />
        <MessageBox
          title={`The ${tableTerms.tableName} has no ${tableTerms.pluralColumnName} yet`}
          tagline={`Please add a ${tableTerms.columnName.toLowerCase()} to continue`}
          fullWidth={false}
        />
      </>
    );
  }

  // Render skeleton
  if (!grid) {
    return <GridSkeleton />;
  }

  // Render grid
  return (
    grid && (
      <SpreadsheetContext.Provider
        value={{
          parsedColumns: parsedSecurities,
          setParsedColumns: setParsedSecurities,
          onCellsChanged: computeCellsChanges,
          tableTerms,
          tableData,
          columns,
          rowGroups,
          setRowGroups,
        }}>
        <>
          <div id={tableTerms.tableSlug}>
            <PerfectScrollbar className="always-visible">
              <ScalarDatasheet
                className={className}
                dataRenderer={cell => (cell.expr ? cell.expr : cell.value)}
                onCellsChanged={computeCellsChanges}
                data={grid || [{ value: '' }]}
                rowRenderer={Row}
                dataEditor={ValueEditor}
                cellRenderer={Cell}
              />
            </PerfectScrollbar>
          </div>
        </>
      </SpreadsheetContext.Provider>
    )
  );
};

Spreadsheet.defaultProps = {
  className: '',
  rowConfig: [],
  tableTerms: {
    tableName: 'Table',
    columnName: 'Column',
    pluralColumnName: 'Columns',
  },
  hasColTitle: true,
  showTotalColumn: true,
  disabled: false,
};

Spreadsheet.propTypes = {
  className: PropTypes.string,
  dbColumns: PropTypes.array,
  columns: PropTypes.array,
  rowConfig: PropTypes.array,
  tableData: PropTypes.object,
  parsedSecurities: PropTypes.object,
  tableTerms: PropTypes.object,
  setParsedSecurities: PropTypes.func,
  parser: PropTypes.func,
  conditions: PropTypes.func,
  compareChanges: PropTypes.func,
  showTotalColumn: PropTypes.bool,
  disabled: PropTypes.bool,
  hasColTitle: PropTypes.bool,
  validations: PropTypes.func,
  rowGroups: PropTypes.object,
  setRowGroups: PropTypes.func,
  showPlaceholder: PropTypes.bool,
  toggleRows: PropTypes.func,
  page: PropTypes.string,
};

export default Spreadsheet;
