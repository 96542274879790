import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  List,
  MenuItem,
  Select,
} from '@material-ui/core';
import moment from 'moment';
import { ApiService, type MeasurementDateName, RefreshComps } from 'api';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import FormDialog from 'components/Dialogs/FormDialog';
import StatusListItem from 'components/MeasurementDates/components/RolloverStatus/components/StatusListItem';
import useLongGatewayMultiPromiseHandler from 'hooks/useLongGatewayMultiPromiseApiRequest/useLongGatewayMultiPromiseApiRequest';
import {
  COMPLETE,
  INITIAL,
  PENDING,
  POLLING,
  PromiseResult,
} from 'hooks/useLongGatewayMultiPromiseApiRequest/useLongGatewayMultiPromiseApiRequest.props';
import { ISO_DATE_FORMAT } from 'utilities';
import { CompanyIdMap, RefreshCapitalIqDialogProps, stateStatusMap } from './RefreshCapitalIqDialog.props';

const RefreshCapitalIqDialog: React.FC<RefreshCapitalIqDialogProps> = ({ open, onClose }) => {
  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyList } = useMemo(() => storeValue, [storeValue]);
  const [measurementDateNames, setMeasurementDateNames] = useState<Array<MeasurementDateName>>([]);
  const [selectedFundMeasurementDate, setSelectedFundMeasurementDate] = useState<MeasurementDateName | null>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<Set<number>>(new Set());

  const firmId = useMemo(() => storeValue.firmId, [storeValue]);

  useEffect(() => {
    if (firmId) {
      // this is running too often
      ApiService.apiMeasurementDatesAllByFirmRead(firmId.toString()).then(response => {
        setMeasurementDateNames(response);
      });
    }
  }, [firmId]);

  const companyIdMap = useMemo<CompanyIdMap>(
    () =>
      companyList?.reduce((acc, company) => {
        if (company?.company_id) {
          acc[company.company_id] = company;
        }
        return acc;
      }, {} as CompanyIdMap) ?? {},
    [companyList]
  );

  const companyIdNameMap = useMemo<{ [key: number]: string }>(
    () =>
      Object.values(companyIdMap).reduce((acc, company) => {
        if (company?.company_id) {
          acc[company.company_id.toString()] = company.company_name ?? '';
        }
        return acc;
      }, {}),
    [companyIdMap]
  );

  const fundMeasurementDates = useMemo<MeasurementDateName[]>(
    () => measurementDateNames.filter(measurementDate => measurementDate.fmd_id) ?? [],
    [measurementDateNames]
  );

  const uniqueFundMeasurementDatesByDate = useMemo<MeasurementDateName[]>(() => {
    const uniqueDates = new Set(fundMeasurementDates.map(date => date.date));
    return Array.from(uniqueDates)
      .map(date => fundMeasurementDates.find(d => d.date === date) ?? null)
      .filter(d => d) as MeasurementDateName[];
  }, [fundMeasurementDates]);

  const companyMeasurementDates = useMemo<MeasurementDateName[]>(
    () => measurementDateNames.filter(measurementDate => measurementDate.cmd_id) ?? [],
    [measurementDateNames]
  );

  const companyMeasurementDatesByFund = useMemo<MeasurementDateName[]>(() => {
    if (selectedFundMeasurementDate) {
      return companyMeasurementDates.filter(
        measurementDate => measurementDate.date === selectedFundMeasurementDate.date
      );
    }
    return [];
  }, [selectedFundMeasurementDate, companyMeasurementDates]);

  useEffect(() => {
    setSelectedCompanies(new Set(companyMeasurementDatesByFund.map(cmd => Number(cmd.company_id ?? 0))));
  }, [companyMeasurementDatesByFund]);

  const handleCompanyCheckboxChange = useCallback((companyId: number) => {
    setSelectedCompanies(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(companyId)) {
        newSelected.delete(companyId);
      } else {
        newSelected.add(companyId);
      }
      return newSelected;
    });
  }, []);

  const refreshDate = useMemo(() => {
    //   current date
    const date = moment(new Date());
    return date.format(ISO_DATE_FORMAT);
  }, []);

  const apiData = useMemo<Array<RefreshComps>>(
    () =>
      Array.from(selectedCompanies).map(c => ({
        company_id: c,
        date: selectedFundMeasurementDate?.date ?? '',
        refresh_date: refreshDate,
      })),
    [selectedCompanies, selectedFundMeasurementDate, refreshDate]
  );

  const pollComps = useCallback(
    (params: RefreshComps) =>
      ApiService.apiValuationPollRefreshCompsRead(params.company_id, params.date, params.refresh_date as string),
    []
  );

  const { result, doRequest, processStatus } = useLongGatewayMultiPromiseHandler<RefreshComps, boolean>(
    apiData,
    ApiService.apiValuationRefreshCompsCreate,
    pollComps,
    5000,
    24
  );

  const resultMap = useMemo(() => {
    const resultList: PromiseResult<boolean, RefreshComps>[] = Object.values(result);
    return resultList.map(res => ({
      id: res.promiseParams.company_id.toString(),
      status: stateStatusMap[res.state],
      errorMsg: '',
    }));
  }, [result]);

  const startRefresh = useCallback(() => {
    doRequest();
  }, [doRequest]);

  return (
    <FormDialog
      isValid
      open={open}
      onClose={onClose}
      doPostSaveClose={false}
      onSave={processStatus === COMPLETE ? onClose : startRefresh}
      showCancelButton={processStatus !== COMPLETE}
      customButtonLabel={processStatus === COMPLETE ? 'Close' : 'Update'}
      title="Refresh Capital Iq Data">
      <>
        {processStatus === INITIAL && (
          <FormControl fullWidth>
            <InputLabel id="fund-measurement-date-label">Fund Measurement Date</InputLabel>
            <Select
              labelId="fund-measurement-date-label"
              value={selectedFundMeasurementDate?.id ?? ''}
              onChange={e => {
                const selectedId = e.target.value;
                const selectedDate = uniqueFundMeasurementDatesByDate.find(date => date.id === selectedId) || null;
                setSelectedFundMeasurementDate(selectedDate);
              }}>
              {uniqueFundMeasurementDatesByDate.map(date => (
                <MenuItem key={date.id} value={date.id}>
                  {date.name}
                </MenuItem>
              ))}
            </Select>
            <FormGroup>
              {companyMeasurementDatesByFund.map(measurementDate => {
                const company = companyIdMap[Number(measurementDate.company_id ?? 0)];
                return (
                  <FormControlLabel
                    key={measurementDate.cmd_id}
                    control={
                      <Checkbox
                        checked={selectedCompanies.has(company.company_id ?? 0)}
                        onChange={() => handleCompanyCheckboxChange(company.company_id ?? 0)}
                      />
                    }
                    label={company.company_name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        )}
        {[PENDING, POLLING, COMPLETE].includes(processStatus) && (
          <List>
            {resultMap.map((res, index) => (
              <StatusListItem index={index} key={res.id} idNameMap={companyIdNameMap} item={res} />
            ))}
          </List>
        )}
      </>
    </FormDialog>
  );
};

export default RefreshCapitalIqDialog;
