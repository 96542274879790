import React, { useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { sortBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { worldCurrencies } from 'utilities';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const CurrencyList = ({ label, formCurrency, handleCurrencyChange }) => {
  const classes = useStyles();
  const currencies = Object.keys(worldCurrencies).map(currency => ({ value: currency, label: currency }));

  const uniqueCurrencies = uniqBy(currencies, 'label');

  const selectedCurrency = useMemo(
    () => uniqueCurrencies.find(c => c.value === formCurrency) || null,
    [uniqueCurrencies, formCurrency]
  );

  return (
    <Autocomplete
      id={label.toLowerCase().replaceAll(' ', '-')}
      options={sortBy(uniqueCurrencies, 'label')}
      style={{ width: '100%' }}
      autoHighlight
      getOptionLabel={option => option.label}
      onChange={handleCurrencyChange}
      value={selectedCurrency}
      classes={{
        popper: classes.autocomplete,
      }}
      renderInput={params => (
        <TextField {...params} fullWidth label={label} name={label.toLowerCase().replaceAll(' ', '-')} />
      )}
      className={classes.marginBottom}
      disableClearable={true}
    />
  );
};

CurrencyList.propTypes = {
  label: PropTypes.string.isRequired,
  formCurrency: PropTypes.string,
  handleCurrencyChange: PropTypes.func.isRequired,
};

export default CurrencyList;
