import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil, pickBy } from 'lodash';
import { FUNDS_BY_FIRM_QUERY_KEY } from 'common/constants/services/firms';
import {
  COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY,
  FUND_ALLOCATIONS_BY_MD_QUERY_KEY,
  FUND_INVESTMENTS_BY_MD_QUERY_KEY,
  FUND_SUMMARY_BY_MD_QUERY_KEY,
  UPDATE_FUND_DEFAULT_ERROR_MESSAGE,
  UPDATE_FUND_DEFAULT_SUCCESS_MESSAGE,
} from 'common/constants/services/funds';
import { FundService } from 'services';
import { getNumberValue, getStringValue, throwError } from 'utilities';
import { UpdateFundParams, UpdateFundResponse } from './types';

const updateFund = async (params: UpdateFundParams) => {
  const { fund } = params;

  if (isEmpty(pickBy(fund)) || isNil(fund?.id)) return throwError(UPDATE_FUND_DEFAULT_ERROR_MESSAGE);

  const fundService = new FundService();

  try {
    const updatedFund = await fundService.updateFundById(getNumberValue(fund?.id), fund);

    if (updatedFund?.id)
      return {
        fund: updatedFund,
        responseMessage: UPDATE_FUND_DEFAULT_SUCCESS_MESSAGE(getStringValue(updatedFund?.name)),
      } as UpdateFundResponse;

    return throwError(UPDATE_FUND_DEFAULT_ERROR_MESSAGE);
  } catch (error) {
    return throwError(UPDATE_FUND_DEFAULT_ERROR_MESSAGE);
  }
};

const useUpdateFund = () => {
  const queryClient = useQueryClient();

  const updateFundMutation = useMutation<UpdateFundResponse, Error, UpdateFundParams>({
    mutationFn: params => updateFund(params),
  });

  const { isSuccess, mutateAsync } = updateFundMutation;

  if (isSuccess) {
    // Invalidate the queries to refetch the updated data of the Fund
    queryClient.invalidateQueries({
      queryKey: [
        FUNDS_BY_FIRM_QUERY_KEY, // Funds List
        COMPANIES_WITH_OWNERSHIP_BY_FUND_QUERY_KEY, // Companies with Ownership by Fund
        FUND_SUMMARY_BY_MD_QUERY_KEY, // Fund Summary by Measurement Date
        FUND_ALLOCATIONS_BY_MD_QUERY_KEY, // Fund Allocations by Measurement Date
        FUND_INVESTMENTS_BY_MD_QUERY_KEY, // Fund Investments by Measurement Date
      ],
    });

    // Clean the mutation internal state
    updateFundMutation.reset();
  }

  return {
    mutateAsync,
  };
};

export default useUpdateFund;
