import React, { FC } from 'react';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { getArrayValue } from 'utilities';

const CapTableVersionSelector: FC = props => {
  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { capTableList } = storeValue;

  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: getArrayValue(capTableList),
  };

  return <GridSelect {...props} {...selectProps} />;
};

export default CapTableVersionSelector;
