import { DECIMAL_PLACES } from 'common/config/app';
import { parseDatabaseValue } from 'utilities';
import { ParseCellValueParams } from './types';

const parseCellValue = <T>(params: ParseCellValueParams<T>) => {
  const { cell, fieldDecimalPlaces } = params;

  const { dbDecimalPlaces, dbType, defaultValue, format, gridType, value } = cell;

  const decimalPlaces = fieldDecimalPlaces ?? dbDecimalPlaces ?? DECIMAL_PLACES;

  return parseDatabaseValue({
    decimalPlaces,
    format,
    gridType,
    type: dbType,
    value: value ?? defaultValue ?? null,
  });
};

export default parseCellValue;
