/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import { sortBy } from 'lodash';
import { WEIGHTED_EV } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS,
  PRE_TAX_CUTS_JOBS_ACT_ALIAS,
  PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS,
} from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcfNOLCarryover/config/constants';
import { DCFApproachAttributes, DCFPeriodAttributes } from 'pages/ValuationsAllocation/types';
import { parseDatabaseValue, toString } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { CustomDCFFinancialPeriod, CustomValuationApproachDCF, ReverseParserProps, YearType } from './types';

const reverseParser = (props: ReverseParserProps) => {
  const { cells, columns, allowEmptyValues, rowConfig, tableData, sheet, fieldAttributes } = props;
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], sortedColumns.length);
  const { dcfApproachAttrs, dcfPeriodAttrs } = fieldAttributes;

  const getDecimalPlaces = (
    alias: keyof (DCFApproachAttributes | DCFPeriodAttributes),
    dcfApproachAttrs?: DCFApproachAttributes,
    dcfPeriodAttrs?: DCFPeriodAttributes,
    dbDecimalPlaces?: number
  ): number | undefined => {
    const decimalPlaceFromApproach = dcfApproachAttrs?.[alias]?.decimal_places;
    const decimalPlaceFromPeriod = dcfPeriodAttrs?.[alias]?.decimal_places;

    return (
      (typeof decimalPlaceFromApproach === 'number' ? decimalPlaceFromApproach : undefined)
      ?? (typeof decimalPlaceFromPeriod === 'number' ? decimalPlaceFromPeriod : undefined)
      ?? dbDecimalPlaces
    );
  };

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = columnLegend + (cellIndex + 1);
      const defaultValue = cell.defaultValue ?? null;
      const type = cell.dbType ?? null;
      const format = cell.format ?? null;
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;

      const decimalPlaces = getDecimalPlaces(
        cell.alias as keyof (DCFApproachAttributes | DCFPeriodAttributes),
        dcfApproachAttrs,
        dcfPeriodAttrs,
        cell?.dbDecimalPlaces
      );

      const calcValue = parseDatabaseValue({
        type,
        value,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces,
      });

      const valueToAssign: CustomDCFFinancialPeriod[keyof CustomDCFFinancialPeriod] = calcValue;
      const cellAlias = cell.alias as keyof CustomValuationApproachDCF;
      if (columns.length === 1) {
        if (tableData?.approach?.valuations_approach_dcf) {
          (tableData.approach.valuations_approach_dcf[cellAlias] as typeof valueToAssign) = valueToAssign;
        }
        columns.forEach(column => {
          (column[cell.alias as keyof CustomDCFFinancialPeriod] as typeof valueToAssign) = valueToAssign;
        });
        if (cell.alias === WEIGHTED_EV) {
          tableData.approach.enterprise_value = calcValue;
        }
        sheet.columns = columns;
      } else if (
        [PRE_TAX_CUTS_JOBS_ACT_ALIAS, POST_TAX_CUTS_JOBS_ACT_NOL_BALANCE_ALIAS].includes(cell.alias)
        && columnIndex === 0
      ) {
        (tableData.approach.valuations_approach_dcf[cellAlias] as typeof valueToAssign) = valueToAssign;
      } else if (cell.alias === PRESENT_VALUE_OF_NOL_TAX_SAVINGS_ALIAS) {
        if (columnIndex === 0) {
          tableData.approach.valuations_approach_dcf[cell.alias] = toString(0);
        } else {
          tableData.approach.valuations_approach_dcf[cell.alias] += calcValue;
        }
      } else {
        const period = tableData?.approach?.valuations_approach_dcf?.dcf_financial_period?.find(
          ({ year }) => toString(year) === toString(column.year)
        );
        if (period) {
          const valueToAssign: CustomDCFFinancialPeriod[keyof CustomDCFFinancialPeriod] = calcValue;
          (period[cell.alias as keyof CustomDCFFinancialPeriod] as typeof valueToAssign) = valueToAssign;
        }
        const sheetPeriod = sheet.columns.find(({ year }: YearType) => year.toString() === column.year.toString());
        sheetPeriod[cell.alias] = calcValue;
      }
    });
  });
};

export default reverseParser;
