import { GetRowConfigParams } from './types';

const getRowConfig = ({ securities, hideEnterpriseValueRow, isDisabled }: GetRowConfigParams) => [
  {
    largeHeader: true,
    skipParse: true,
  },
  ...securities.map(security => ({
    ignoreRowCopy: true,
    readOnly: isDisabled,
    ...security,
  })),
  {
    className: 'border-top',
    skipParse: true,
  },
  {
    className: hideEnterpriseValueRow ? 'hidden' : '',
    skipParse: true,
  },
];

export default getRowConfig;
