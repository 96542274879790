/* eslint-disable no-restricted-globals,no-param-reassign */
import moment from 'moment';
import uuid from 'react-uuid';
import { CreateColumnsParams, GenerateColumnsParams } from './types';

const generateColumns = (params: GenerateColumnsParams) => {
  const { financialPeriods = [], isAddingNewYear } = params;
  const sizeColumns = financialPeriods.length - 1;
  return financialPeriods?.map((dcfFinancialPeriod, dcfIndex) => {
    const isLastColumn = dcfIndex === sizeColumns;
    const isIndexGreatherThanMinimunOfColumn = dcfIndex >= 2;
    const { financial_statement_period } = dcfFinancialPeriod;
    let date;
    if (financial_statement_period) {
      date = moment(financial_statement_period.statement_date);
    } else {
      date = moment(dcfFinancialPeriod.date);
    }
    const year = date.year();
    const id = uuid();
    const columnId = dcfFinancialPeriod.id ?? id;
    dcfFinancialPeriod.year = year;
    dcfFinancialPeriod.date = date.format('YYYY-MM-DD');
    dcfFinancialPeriod.columnId = columnId;
    dcfFinancialPeriod.isDeleteableColumn
      = isLastColumn && !isAddingNewYear ? isIndexGreatherThanMinimunOfColumn : false;
    return dcfFinancialPeriod;
  });
};

const createColumns = (params: CreateColumnsParams) => {
  const { financialPeriods, thresholdDate, isAddingNewYear = false } = params;
  const dcfFinancialPeriodColumns = generateColumns({ financialPeriods, isAddingNewYear });
  return (
    dcfFinancialPeriodColumns
      .filter(({ date }) => moment(date).isSameOrAfter(thresholdDate, 'day'))
      .sort((a, b) => {
        const { date: dateA } = a;
        const { date: dateB } = b;

        return moment(dateA).isBefore(dateB) ? -1 : 1;
      }) || []
  );
};

export default createColumns;
