/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BREAKPOINT_INFINITY } from 'common/constants/cap-table/capTable';
import { largeCurrencyFormat } from 'common/formats/formats';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { formatNumbers } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { conditions, customValidations, parser, reverseParser, rowConfig } from '../common';

const useCustomBreakpointsTableData = ({ customBreakpointColumns, captableInfo, format, isDisabled }) => {
  const [spreadsheet, setSpreadsheet] = useState();
  const { currency } = format;
  const { symbol: currencySymbol, code: currencyCode } = currency;
  const [columnsCustomBP, setColumnsCustomBP] = useState(null);

  const config = useMemo(() => {
    if (columnsCustomBP) {
      return rowConfig({ data: columnsCustomBP, isCustomBreakpoint: true });
    }
    return null;
  }, [columnsCustomBP]);

  useEffect(() => {
    const updatedCustomBreakpoints = columnsCustomBP || customBreakpointColumns;
    if (updatedCustomBreakpoints) {
      const sizeColumns = updatedCustomBreakpoints.length;
      const alphabet = alphabetGenerator([], sizeColumns + 1);
      const columnsData = updatedCustomBreakpoints.map((bp, bpIndex) => {
        const upperLimit
          = bpIndex === sizeColumns - 1 ? BREAKPOINT_INFINITY : `${currencySymbol}${alphabet[bpIndex + 1]}`;
        const isLastColumn = upperLimit === BREAKPOINT_INFINITY;
        const isDeleteableColumn = bpIndex === sizeColumns - 2; // -2 To ignore the last column
        return {
          ...bp,
          id: bpIndex + 1,
          price: isLastColumn ? BREAKPOINT_INFINITY : bp.price,
          series: bp.series,
          isDeleteableColumn,
        };
      });
      setColumnsCustomBP(columnsData);
    }
  }, [customBreakpointColumns, columnsCustomBP?.length]);

  const tableTerms = useMemo(
    () => ({
      tableName: 'Custom Breakpoints',
      tableSlug: 'custom-breakpoints',
      columnName: 'Custom Breakpoints',
    }),
    []
  );

  // Use this template when the only breakpoint is the Infinity one
  const breakpointTemplate = useMemo(() => {
    if (!captableInfo) return {};
    const series = captableInfo?.securities?.map(security => ({
      id: security.id,
      security: security.name,
      value: 0,
    }));

    if (!series) return {};

    return {
      id: 1,
      name: 'Breakpoint 1',
      price: 0,
      range: `${currencySymbol}0 to ${currencySymbol}B`,
      series,
      total: 0,
    };
  }, [captableInfo]);

  useEffect(() => {
    if (columnsCustomBP && tableTerms) {
      const spreadsheetConfig = new SpreadsheetConfig({
        parser,
        reverseParser,
        rowConfig: config,
        name: 'BreakPoints',
        columns: columnsCustomBP,
        tableData: {
          currencySymbol,
          columns: columnsCustomBP,
          isCustomBreakpointsTable: true,
          isDisabled,
        },
        currency: `${currencyCode} ${currencySymbol}`,
        tableTerms,
        showTotalColumn: false,
        showToolbar: false,
        showPreviousColsDivider: false,
        hasColTitle: true,
        allowConfirmAndDeleteColumn: true,
        allowDeleteOnlySpecificColumn: true,
        disableDeleteBtnOnFirstColumn: true,
        currencyFormatter: false,
        unitsFormatter: false,
        format,
        customValidations,
        conditions,
      });

      setSpreadsheet(spreadsheetConfig);
    }
  }, [columnsCustomBP, tableTerms, isDisabled]);

  const addCustomColumn = () => {
    if (columnsCustomBP) {
      const lastColumn = columnsCustomBP[columnsCustomBP.length - 1];
      const alphabetCustomColumns = alphabetGenerator([], columnsCustomBP.length + 1);

      const getLowerLimit = () => {
        const prevColumn = columnsCustomBP[columnsCustomBP.length - 2];
        if (!prevColumn) return `${currencySymbol}${alphabetCustomColumns[columnsCustomBP.length]}`;
        if (prevColumn.series.every(serie => !serie.value)) {
          return `${currencySymbol}${alphabetCustomColumns[columnsCustomBP.length - 1]}`;
        }

        return formatNumbers({
          value: prevColumn.total,
          format: largeCurrencyFormat,
          currency,
        });
      };

      const getRange = () => {
        const upperLimit = `${currencySymbol}${alphabetCustomColumns[columnsCustomBP.length]}`;
        const lowerLimit = getLowerLimit();
        return `${lowerLimit} to ${upperLimit}`;
      };

      const tmpColumn = {
        id: columnsCustomBP.length,
        name: `Breakpoint ${columnsCustomBP.length}`,
        price: 0,
        range: getRange(),
        series: lastColumn.series.map(serie => ({ ...serie, value: 0 })),
      };

      lastColumn.name = `Breakpoint ${columnsCustomBP.length + 1}`;
      lastColumn.range = `${currencySymbol}${alphabetCustomColumns[columnsCustomBP.length]} to ${BREAKPOINT_INFINITY}`;
      lastColumn.id = columnsCustomBP.length + 1;

      setColumnsCustomBP(cols => [
        ...(cols ?? []).slice(0, columnsCustomBP.length - 1),
        columnsCustomBP.length > 1 ? tmpColumn : breakpointTemplate,
        lastColumn,
      ]);
    }
  };

  const deleteCustomColumn = useCallback(
    columnIndex => {
      const availableColumns = columnsCustomBP ?? [];
      const newLastLowerLimit = formatNumbers({
        format: largeCurrencyFormat,
        value: availableColumns[columnIndex - 1].total,
        currency,
      });
      const newLastColumn = {
        ...availableColumns[columnIndex + 1],
        name: `Breakpoint ${columnIndex + 1}`,
        range: `${newLastLowerLimit} to Infinity`,
      };
      const newColumns = [...availableColumns.slice(0, columnIndex), newLastColumn];

      setColumnsCustomBP(newColumns);
    },
    [columnsCustomBP]
  );

  return {
    spreadsheet,
    addCustomColumn,
    deleteCustomColumn,
  };
};

export default useCustomBreakpointsTableData;
