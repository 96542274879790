import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';
import { SubTitleTextProps } from './types';

const useStyles = makeStyles((theme: ITheme) => ({
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    fontWeight: 'normal',
  },
}));

const SubTitleText = (props: SubTitleTextProps) => {
  const { subTitle } = props;
  const classes = useStyles();

  return <h2 className={classes.subtitle}>{subTitle}</h2>;
};

export default SubTitleText;
