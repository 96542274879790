import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'components/Spreadsheet/components';
import { formatNumbers } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import SingleColumn from './SingleColumn';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';

const TotalsColumn = ({ className, currency, isToolbarVisible, currencyFormatter, unitsFormatter }) => {
  const [totalsGrid, setTotalsGrid] = useState([]);
  const alphabet = alphabetGenerator([], 1);

  const { rows, totalsCells = [], getClassnamesForLegends } = useContext(FeaturedSpreadsheetContext);

  const getCellValue = (isFirstRow, cell) => {
    if (isFirstRow) {
      return 'Total';
    }

    if (cell.hasTotal) {
      return formatNumbers({
        value: cell.value,
        format: cell.format,
        currency,
      });
    }

    return cell.value;
  };

  useEffect(() => {
    const tmpGrid = [
      [
        {
          value: '',
          readOnly: true,
          className: getClassnamesForLegends('legend col-legend', isToolbarVisible),
        },
      ],
    ];

    rows.forEach((row, rowIndex) => {
      const firstRow = rowIndex === 0;
      const rowNumber = rowIndex + 1;
      const key = alphabet[0] + rowNumber;

      const cell = {
        ...row,
        ...(totalsCells[key] ? totalsCells[key] : {}),
        forceComponent: false,
      };

      const classNames = ['cell'];

      if (row.className) {
        classNames.push(row.className);
      }

      if (firstRow) {
        classNames.push('table-header');
      }

      if (firstRow && (currencyFormatter || unitsFormatter)) {
        classNames.push('formatter-cell');
      }

      cell.className = classNames.join(' ');
      cell.value = getCellValue(firstRow, cell);

      tmpGrid.push([cell]);
    });

    setTotalsGrid(tmpGrid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, totalsCells, isToolbarVisible]);

  return <SingleColumn data={totalsGrid} className={className} cellRenderer={Cell} />;
};

TotalsColumn.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array.isRequired,
  currency: PropTypes.object.isRequired,
  isToolbarVisible: PropTypes.bool,
  currencyFormatter: PropTypes.bool,
  unitsFormatter: PropTypes.bool,
};

export default TotalsColumn;
