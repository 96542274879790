import moment from 'moment';
import numeral from 'numeral';
import { HYPHEN_SYMBOL } from 'common/constants/general';
import { gridShortDate, isValidDate as isValidDateFunc } from 'utilities';

const containsLetter = s => s.match(/[a-zA-Z][^xX]+/g);
const containsNumber = s => s.match(/\d+/g);
const containsPercentage = s => s.endsWith('%');
const containsSlashesOrDashes = s => /[/-]/.test(s);
const containsDot = s => /\./.test(s);
const containsComma = s => /,/.test(s);
const containsDollar = s => /^\$/.test(s);
const containsX = s => /[xX]/.test(s);
const containsSpace = s => /\s/.test(s);
const containsReturn = s => /\r/.test(s);

const handleDotSeparator = stringParam => {
  /**
   * This function handles the case where the number has a dot as a decimal separator.
   * If the number is a multiple of 1000, it returns the number without the dot.
   * If the number is not a multiple of 1000, it returns the number.
   *
   * @param {String} stringParam - A string representing a number with a dot as a decimal separator.
   *
   * Test cases:
   * 1. '1.000' -> '1000'
   * 2. '1.000.000' -> '1000000'
   * 3. '1.0001' -> '1.0001'
   * 4. '1.0' -> '1'
   * 5. '1.00' -> '1'
   */
  let sanitizedString = stringParam.trim().startsWith('$') ? stringParam.slice(1) : stringParam.trim();

  if (/(\.\.)|(,,)/.test(sanitizedString)) {
    throw new Error('Invalid number format: Multiple consecutive dots or commas.');
  }

  // Extract all the sections split by '.'
  const parts = sanitizedString.split('.');

  if (sanitizedString.includes('.') && sanitizedString.includes(',')) {
    if (sanitizedString.lastIndexOf('.') > sanitizedString.lastIndexOf(',')) {
      // Consider dot as thousands separator and comma as decimal
      sanitizedString = sanitizedString.split('.').join('');
      sanitizedString = sanitizedString.replace(',', '.');
    } else {
      // Consider comma as thousands separator
      sanitizedString = sanitizedString.split(',').join('');
    }
  } else if (sanitizedString.includes('.')) {
    const lastPart = parts[parts.length - 1];

    if (lastPart === '0' || lastPart === '00') {
      sanitizedString = parts.slice(0, parts.length - 1).join('');
    } else if (lastPart.length <= 2 && parts.length > 1) {
      // Consider dot as decimal separator for the last section
      sanitizedString = `${parts.slice(0, parts.length - 1).join('')}.${lastPart}`;
    } else {
      // Remove all dots
      sanitizedString = parts.join('');
    }
  } else if (sanitizedString.includes(',')) {
    const parts = sanitizedString.split(',');
    const lastPart = parts[parts.length - 1];
    if (lastPart.length <= 2 && parts.length === 2) {
      sanitizedString = parts.join('.');
    } else {
      sanitizedString = sanitizedString.split(',').join('');
    }
  }

  return sanitizedString;
};

const replaceNumberSeparators = stringNumber => {
  let decimalSeparator;
  let thousandsSeparator;
  let numberWithoutThousandsSeparator;
  let tmpStringNumber;

  if (containsReturn(stringNumber)) {
    tmpStringNumber = stringNumber.replace(/\r/g, '');
  } else {
    tmpStringNumber = stringNumber;
  }

  // If it has decimal separator, check if the separator is a dot. If it is, return the number.
  if (containsDot(tmpStringNumber) && !containsComma(tmpStringNumber)) {
    return handleDotSeparator(tmpStringNumber);
  }

  // If it doesn't have decimals, only the thousands separator is removed
  if (!containsDot(tmpStringNumber) || !containsComma(tmpStringNumber)) {
    numberWithoutThousandsSeparator = tmpStringNumber.replace(/[.,]/g, '');
    return numberWithoutThousandsSeparator;
  }

  // Check if the last separator is a comma or a dot
  if (tmpStringNumber.lastIndexOf(',') > tmpStringNumber.lastIndexOf('.')) {
    decimalSeparator = ',';
    thousandsSeparator = '.';
  } else {
    decimalSeparator = '.';
    thousandsSeparator = ',';
  }

  // Remove thousand separators (dots or commas)
  numberWithoutThousandsSeparator = tmpStringNumber.replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '');

  // Replace decimal separator with a dot
  const finalNumber = numberWithoutThousandsSeparator.replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');

  return finalNumber;
};

const sanitize = s => {
  switch (true) {
    case containsX(s):
      return s.replace(/[xX]/g, '');
    case containsDollar(s) && (containsDot(s) || containsComma(s)):
      return replaceNumberSeparators(s);
    case containsSpace(s) && containsReturn(s):
      return s.replace(/\r|\s/g, '');
    default:
      return s;
  }
};

const isValueNumber = col => {
  const satinizedCol = sanitize(col);
  const hasLetter = containsLetter(satinizedCol);
  const hasNumber = containsNumber(satinizedCol);
  return !(hasLetter && hasNumber);
};

const isHyphenSymbol = col => col?.trim() === HYPHEN_SYMBOL;

const parseValue = col => {
  const hasLetter = containsLetter(col) && !containsX(col);
  const sanitizedCol = !hasLetter ? sanitize(col) : col;
  const hasPercentage = containsPercentage(sanitizedCol);
  const isNumber = isValueNumber(sanitizedCol);

  const isDate = containsSlashesOrDashes(sanitizedCol);
  const isDateFormatted = moment(sanitizedCol, 'MM/DD/YYYY', true).isValid();
  const formattedDate = isDateFormatted ? sanitizedCol : moment(sanitizedCol, 'DD/M/YYYY', true).format('MM/DD/YYYY');
  const dateToValidate = formattedDate === 'Invalid date' ? sanitizedCol : formattedDate;
  const isValidDate = isValidDateFunc(dateToValidate);

  // handles pasting 0.0% and having commas as decimal separator
  const percentify = numeral(sanitizedCol.replace(',', '.')).value() || 0;
  const numerify = numeral(sanitizedCol).value() || sanitizedCol;
  const isCommaFollowedByTwoNumbers = sanitizedCol.match(/,\d{2}/g);

  if (isCommaFollowedByTwoNumbers) {
    const replacedComma = sanitizedCol.replace(',', '.');
    return replacedComma;
  }

  let value = sanitizedCol;
  if (isNumber) value = numerify;
  /* max number of decimal places for percentages in backend is 15,
  but when this gets parsed to decimal,
  it adds at least two 0s in the front for single digit percentages (e.g. 2.5% => 0.025),
  so to fixed should be 12 just to be safe */
  if (hasPercentage && isNumber) value = percentify.toFixed(12);
  if (isDate && isValidDate) value = gridShortDate(dateToValidate);

  return value;
};

const pasteParser = (clipboard, maxAllowedCells = null) => {
  const values = [];
  const rows = clipboard.split('\n');
  const filteredRows = rows.filter(row => row !== '');
  const { maxAllowedRows, maxAllowedColumns } = maxAllowedCells;

  filteredRows.forEach((row, rowIndex) => {
    const cols = row.split('\t');
    const tmpRows = [];

    if (rowIndex < maxAllowedRows) {
      cols.forEach((colValue, index) => {
        if (index < maxAllowedColumns) {
          const col = isHyphenSymbol(colValue) ? '0' : colValue;
          const value = parseValue(col);

          tmpRows.push(value);
        }
      });
    }
    values.push(tmpRows);
  });
  return values;
};

export default pasteParser;
