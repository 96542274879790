/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, ButtonGroup, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { isEmpty, partition } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { NOT_APPLICABLE } from 'common/constants/general';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { DATA_UNAVAILABLE } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { Widgets } from 'components';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { ValueEditor } from 'components/Spreadsheet/components';
import CompGroupContext from 'context/CompGroupContext';
import useCellOptions from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/useCellOptions';
import { GPT_TRANSACTION } from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import getRowConfig from 'pages/Valuations/approaches/GuidelineTransactions/config/getRowConfig';
import rowTransformer from 'pages/Valuations/approaches/GuidelineTransactions/config/rowTransformer';
import CompGroupsOptions from 'pages/Valuations/components/CompGroups/components/CompGroupsOptions';
import { PRIVATE_TRANSACTIONS, SORT_ASC, SORT_DESC, UPDATED_BENCHMARK_ROWS } from 'pages/Valuations/util/constants';
import { extractSpecificApproachFromApproach } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useNotes } from 'services/hooks/notes';
import { getStringValue, sortByDate, sortByNumber, sortByString } from 'utilities';

const GuidelineTransactions = ({ spreadsheets, approach, onChange, workbook, financials }) => {
  const { compsSheet } = spreadsheets;
  const { tableData } = compsSheet;
  const { valuations_approach_gpt } = approach;
  const approachPanelId = approach.panelId;
  const compGroupsRef = useRef(null);
  const [openCompGroupsOptions, setOpenCompGroupsOptions] = useState(false);
  const [lastSortedColumn, setLastSortedColumn] = useState(null);
  const { compGroups, setAreThereChanges, setNotesInApproach } = useContext(ValuationContext);
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const { isDisabled, gpcOptions } = tableData;

  const [format, formatDispatch] = useFormat();

  const [{ companyInfo }] = useStore();

  const { resetConfiguration } = useContext(ValuationContext);

  const [cellOptions, handleOnChange, isLoading] = useCellOptions(onChange, approach.valuations_approach_gpt);

  const updateApproach = useCallback(
    updatedTableData => {
      const approachData = updatedTableData.approach;
      const specificApproach = extractSpecificApproachFromApproach(approachData);
      compsSheet.reset({
        rowConfig: getRowConfig({
          allCompGroups: compGroups.results,
          approach: specificApproach,
          companyName: companyInfo.name,
          gpcOptions,
          isDisabled,
        }),
        // Setting these two parameters to null (`financialsPeriods`, `financialStatementId`) because we cannot define the function without them, but we don't need them in this case.
        columns: rowTransformer(specificApproach, financials, null, null, approachData?.updatedSortedColumn || {}),
      });
      if (specificApproach.is_benchmark) {
        document.dispatchEvent(new Event(UPDATED_BENCHMARK_ROWS));
      }
      resetConfiguration();
    },
    [compGroups, companyInfo, compsSheet, financials, gpcOptions, isDisabled, resetConfiguration]
  );

  const handleToggle = () => {
    setOpenCompGroupsOptions(prevOpen => !prevOpen);
  };

  const setNumericValues = useCallback(
    (transactionRow, transactionData) => {
      const { transactionAttrs } = compsSheet.fieldAttributes;
      // these three could be 'N/A' or 'Data Unavailable' so we want them to be null in that case.
      // If not, just display the value and round as needed
      const { transaction_implied_enterprise_value, transaction_target_ltm_ebitda, transaction_target_ltm_revenue }
        = transactionData;
      const notAvailable = [NOT_APPLICABLE, DATA_UNAVAILABLE, undefined, null];

      if (!notAvailable.includes(transaction_implied_enterprise_value)) {
        transactionRow.enterprise_value = Number(transaction_implied_enterprise_value).toFixed(
          transactionAttrs.enterprise_value
        );
      } else {
        transactionRow.enterprise_value = null;
      }
      if (!notAvailable.includes(transaction_target_ltm_ebitda)) {
        transactionRow.ltm_ebitda = Number(transaction_target_ltm_ebitda).toFixed(transactionAttrs.ltm_ebitda);
      } else {
        transactionRow.ltm_ebitda = null;
      }
      if (!notAvailable.includes(transaction_target_ltm_revenue)) {
        transactionRow.ltm_revenue = Number(transaction_target_ltm_revenue).toFixed(transactionAttrs.ltm_revenue);
      } else {
        transactionRow.ltm_revenue = null;
      }
    },
    [compsSheet.fieldAttributes]
  );

  const handleTransactionCellChange = useCallback(
    async (cell, expression) => {
      const changeResponse = await handleOnChange(cell, expression);
      if (changeResponse) {
        const { gpt_transactions } = tableData.approach.valuations_approach_gpt;
        const transactionToUpdate = gpt_transactions.find(({ row_ref }) => row_ref === cell.alias);
        transactionToUpdate.target_name = changeResponse.transaction_target_name;
        transactionToUpdate.name = changeResponse.transaction_target_name;
        transactionToUpdate.transaction_deal_resolution = changeResponse.transaction_deal_resolution;
        transactionToUpdate.transaction_comments = changeResponse.transaction_comments;
        transactionToUpdate.business_description = changeResponse.business_description;
        transactionToUpdate.transaction_date
          = changeResponse.transaction_closed_date ?? changeResponse.transaction_agreement_date;
        transactionToUpdate.acquirer_name = changeResponse.transaction_buyer_name;
        setNumericValues(transactionToUpdate, changeResponse);
        transactionToUpdate.comes_from_capital_iq = true;
        // this will regenerate the row config and apply the parser again
        updateApproach({ ...tableData, approach: { ...tableData.approach } });
      }
    },
    [handleOnChange, tableData, updateApproach, setNumericValues]
  );

  const isSame = (currentItem, transaction) => {
    const isSameTargetName = currentItem.target_name.toLowerCase() === transaction.target_name.toLowerCase();
    const isSameAcquirerName = currentItem.acquirer_name.toLowerCase() === transaction.acquirer_name.toLowerCase();
    return isSameTargetName && isSameAcquirerName;
  };

  const getTransactionsData = useCallback(
    (transactions, transactionsToDelete, compGroupsData, useLatestVersion) => {
      const { gpt_transactions } = extractSpecificApproachFromApproach(tableData.approach);
      const remainingTransactions = gpt_transactions.filter(
        currentTransaction => !transactionsToDelete.includes(currentTransaction.id || currentTransaction.temp_id)
      );
      const transactionsWithUpdatedCompGroup = remainingTransactions.map(transaction => {
        const transactionCompGroupId = transaction.pt_approach_comp_group || transaction.compgroupId;
        if (compGroupsData) {
          const hasCompGroup = compGroupsData.some(compGroup => compGroup.id === transactionCompGroupId);
          if (!hasCompGroup) {
            const { pt_approach_comp_group, comp_group_id, comp_group_name, temp_ref, ...newTransactionObj }
              = transaction;
            return newTransactionObj;
          }
        }

        return transaction;
      });

      if (transactions) {
        transactions.forEach(transaction => {
          const currentTransactionIdx = transactionsWithUpdatedCompGroup.findIndex(current =>
            isSame(current, transaction)
          );
          if (currentTransactionIdx !== -1) {
            const currentTransactionData = transactionsWithUpdatedCompGroup[currentTransactionIdx];
            if (useLatestVersion) {
              transactionsWithUpdatedCompGroup[currentTransactionIdx] = {
                id: currentTransactionData.id,
                ...transaction,
              };
            } else {
              const { comp_group_id, comp_group_name, temp_ref, pt_approach_comp_group, ...updatedCurrentTransaction }
                = currentTransactionData;
              transactionsWithUpdatedCompGroup[currentTransactionIdx] = {
                id: currentTransactionData.id,
                ...updatedCurrentTransaction,
              };
            }
          } else {
            transactionsWithUpdatedCompGroup.push(transaction);
          }
        });
      }

      return transactionsWithUpdatedCompGroup;
    },
    [tableData]
  );

  const getCompGroupsData = useCallback(
    (transactions, compGroupToDelete) => {
      const { valuationapproachptcompgroup_set } = extractSpecificApproachFromApproach(tableData.approach);
      const compGroupsData
        = valuationapproachptcompgroup_set?.filter(item => compGroupToDelete !== item.comp_group) || [];
      if (transactions) {
        const newCompGroups = transactions
          .filter(transaction => transaction.comp_group_id && compGroupToDelete !== transaction.comp_group_id)
          .filter(item => !compGroupsData.some(compGroupData => compGroupData.id === item.comp_group_id))
          .map(transactionCompGroupItem => ({
            isNew: true,
            comp_group: transactionCompGroupItem.comp_group_id,
            comp_group_name: transactionCompGroupItem.comp_group_name,
            use_latest_comp_group_version: true,
          }));

        const uniqueNewCompGroupsData = [...new Map(newCompGroups.map(item => [item.comp_group, item])).values()];
        return [...compGroupsData, ...uniqueNewCompGroupsData];
      }
    },
    [tableData]
  );

  const getDeletedCompGroupsIDs = useCallback(
    compGroupToDelete => {
      const { valuationapproachptcompgroup_set } = tableData.approach.valuations_approach_gpt;
      const findMatch = compGroupItem => compGroupToDelete === compGroupItem.comp_group;
      return valuationapproachptcompgroup_set
        .filter(compGroupItem => !compGroupItem.isNew && findMatch(compGroupItem))
        .map(compGroupItem => compGroupItem.id);
    },
    [tableData]
  );

  const saveCompGroups = useCallback(
    (transactions, useLatestVersion, transactionsToDelete, compGroupToDelete) => {
      const compGroupsData = getCompGroupsData(transactions, compGroupToDelete);
      const gptTransactions = getTransactionsData(transactions, transactionsToDelete, compGroupsData, useLatestVersion);
      const specificApproach = extractSpecificApproachFromApproach(tableData.approach);
      const valuationApproachGPT = {
        ...specificApproach,
        gpt_transactions: gptTransactions,
        valuationapproachptcompgroup_set: compGroupsData,
      };

      if (!isEmpty(transactionsToDelete)) {
        valuationApproachGPT.deleted_gpt_transactions = transactionsToDelete;
      }
      if (compGroupToDelete) {
        const deletedCompGroups = getDeletedCompGroupsIDs(compGroupToDelete);
        valuationApproachGPT.deleted_comp_groups = deletedCompGroups;
      }
      tableData.approach.valuations_approach_gpt = valuationApproachGPT;
      updateApproach({
        ...tableData,
        approach: {
          ...tableData.approach,
          valuations_approach_gpt: valuationApproachGPT,
        },
      });
    },
    [getCompGroupsData, getDeletedCompGroupsIDs, getTransactionsData, tableData, updateApproach]
  );

  const deleteCompGroup = useCallback(
    compGroupToDelete => {
      const { gpt_transactions, valuationapproachptcompgroup_set, deleted_gpt_transactions, deleted_comp_groups }
        = tableData.approach.valuations_approach_gpt;
      const currentDeletedTransactions = deleted_gpt_transactions || [];
      const currentDeletedCompGroups = deleted_comp_groups || [];

      const transactionResult = partition(gpt_transactions, item => {
        const compGroupId = item.pt_approach_comp_group || item.comp_group_id;
        return compGroupId === compGroupToDelete;
      });

      const compGroupData = valuationapproachptcompgroup_set.filter(compGroup => {
        const compGroupId = compGroup.id || compGroup.comp_group;
        return compGroupId !== compGroupToDelete;
      });

      const transactionsToDelete = transactionResult[0];
      const remainingTransactions = transactionResult[1];

      const updatedApproach = {
        gpt_transactions: remainingTransactions,
        valuationapproachptcompgroup_set: compGroupData,
        deleted_comp_groups: [...new Set([...currentDeletedCompGroups, ...[compGroupToDelete]])],
      };

      if (transactionsToDelete) {
        updatedApproach.deleted_gpt_transactions = [...currentDeletedTransactions, ...transactionsToDelete]
          ?.filter(({ id }) => id)
          ?.map(({ id }) => id);
      }
      tableData.approach.valuations_approach_gpt = {
        ...tableData.approach.valuations_approach_gpt,
        ...updatedApproach,
      };
      updateApproach(tableData);
    },
    [tableData, updateApproach]
  );

  const generateNewCompanies = count => {
    const companiesArray = [];

    for (let i = 0; i < count; i++) {
      const newCompany = {
        row_ref: uuid(),
        name: GPT_TRANSACTION,
        target_name: '',
        transaction_deal_resolution: null,
        transaction_comments: null,
        business_description: null,
        acquirer_name: null,
        transaction_date: null,
        enterprise_value: null,
        ltm_revenue: null,
        ltm_ebitda: null,
        ltm_revenue_enabled: true,
        ltm_ebitda_enabled: true,
        isNew: true,
        comes_from_capital_iq: false,
      };
      companiesArray.push(newCompany);
    }

    return companiesArray;
  };

  const addCompany = (newRows = 1) => {
    const {
      tableData: { approach: compSheetTableData },
    } = compsSheet;

    const newCompanies = generateNewCompanies(newRows);

    compSheetTableData.valuations_approach_gpt = {
      ...compSheetTableData.valuations_approach_gpt,
      gpt_transactions: [...compSheetTableData.valuations_approach_gpt.gpt_transactions, ...newCompanies],
    };
    updateApproach({ ...compsSheet.tableData, approach: compSheetTableData });
  };

  const removeGT = deletedTransaction => {
    const { valuationapproachptcompgroup_set, deleted_comp_groups } = valuations_approach_gpt;
    const gtId = deletedTransaction.comp_group_id || deletedTransaction.pt_approach_comp_group;
    const currentDeletedCompGroups = deleted_comp_groups || [];

    const {
      tableData: { approach: compSheetTableData },
    } = compsSheet;
    // 1. update the specific approach to no longer include this thing
    const filteredTransactions = compSheetTableData?.valuations_approach_gpt?.gpt_transactions.filter(transaction => {
      const transactionId = transaction.row_ref || transaction.id;
      const deletedTransactionId = deletedTransaction.row_ref || deletedTransaction.id;
      return transactionId !== deletedTransactionId;
    });

    // Remove de comp group relation of the other companies
    // with the same comp group as the deleted one
    filteredTransactions.forEach(transaction => {
      if (transaction.pt_approach_comp_group === gtId) {
        delete transaction.pt_approach_comp_group;
      }
      if (transaction.comp_group_id === gtId) {
        delete transaction.comp_group_id;
      }
    });

    const objectKeyCompGroup = valuationapproachptcompgroup_set?.find(
      item => item.id === deletedTransaction.pt_approach_comp_group
    );

    const valuationKeysToSave = valuationapproachptcompgroup_set?.filter(
      item => item.comp_group !== objectKeyCompGroup?.comp_group
    );

    // 2. get the id of the transaction to delete
    const deletedId = deletedTransaction.id || deletedTransaction.row_ref;

    // 3. find the existing list of deleted transactions
    let { deleted_gpt_transactions } = compSheetTableData.valuations_approach_gpt;

    // 4. if there is a deleted id, include it in the list if one exists
    if (deletedId && !deletedTransaction.isNew) {
      deleted_gpt_transactions = deleted_gpt_transactions
        ? [...compSheetTableData.valuations_approach_gpt.deleted_gpt_transactions, deletedId]
        : [deletedId];
    }

    // 5. update the approach
    compSheetTableData.valuations_approach_gpt = {
      ...compSheetTableData.valuations_approach_gpt,
      gpt_transactions: filteredTransactions,
      deleted_gpt_transactions,
      valuationapproachptcompgroup_set: valuationKeysToSave,
    };

    // 6. if the pt has a comp group, remove it from the list of deleted comp groups
    if (deletedTransaction.pt_approach_comp_group) {
      compSheetTableData.valuations_approach_gpt = {
        ...compSheetTableData.valuations_approach_gpt,
        deleted_comp_groups: [...currentDeletedCompGroups, ...[gtId]],
      };
    }
    updateApproach({ ...compsSheet.tableData, approach: compSheetTableData });
    setAreThereChanges(true);
  };

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged }];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  const compGroupContextValue = useMemo(
    () => ({
      saveCompGroups,
      deleteCompGroup,
      tableData,
      approachType: PRIVATE_TRANSACTIONS,
      cellOptions,
    }),
    [saveCompGroups, deleteCompGroup, tableData, cellOptions]
  );

  const sortGPT = useCallback(
    (key, isStringColumn = false, isDateColumn = false) => {
      const currentSortOrderColumn = getStringValue(lastSortedColumn?.[key]);
      const currentSortedColumnIsDesc = currentSortOrderColumn === SORT_DESC;

      const updatedSortedColumn = {
        [key]: currentSortedColumnIsDesc ? SORT_ASC : SORT_DESC,
      };

      const sortedTransactions = tableData.approach.valuations_approach_gpt.gpt_transactions.sort((a, b) => {
        if (a?.[key] && b?.[key]) {
          setLastSortedColumn(updatedSortedColumn);

          const sortOrder = currentSortedColumnIsDesc ? SORT_ASC : SORT_DESC;
          if (isDateColumn) {
            return sortByDate(a, b, key, sortOrder);
          }
          if (isStringColumn) {
            return sortByString(a, b, key, sortOrder);
          }
          return sortByNumber(a, b, key, sortOrder);
        }
        return 0;
      });

      const valuationApproachGPT = {
        ...tableData.approach.valuations_approach_gpt,
        gpt_transactions: sortedTransactions.map((comp, index) => ({
          ...comp,
          order: index,
        })),
      };

      tableData.approach.valuations_approach_gpt = valuationApproachGPT;
      updateApproach({
        ...tableData,
        approach: {
          ...tableData.approach,
          valuations_approach_gpt: valuationApproachGPT,
          updatedSortedColumn,
        },
      });
    },
    [tableData, lastSortedColumn, updateApproach]
  );

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CompGroupContext.Provider value={compGroupContextValue}>
        {isLoading && <LinearProgress />}
        <ScalarSpreadsheet
          {...compsSheet}
          key={compsSheet.name}
          onChange={handleTransactionCellChange}
          sheet={compsSheet}
          workbook={workbook}
          format={format}
          formatDispatch={formatDispatch}
          deleteRowFn={removeGT}
          tableTerms={{
            tableSlug: 'valuation-gpt',
          }}
          accessSheet
          addMultipleRows={addCompany}
          editorForTitles={ValueEditor}
          allowSortColumn
          sortColumnFn={sortGPT}
          sortedColumn={lastSortedColumn}
        />
      </CompGroupContext.Provider>
      <br />
      <Widgets
        notesProps={{
          pageType: VALUATIONS_PAGE_VALUE,
          pageTypeKey: VALUATIONS_PAGE_KEY,
          pageTypeId: approach.id,
          notes,
          isApproach: true,
          setNotes,
          onAddNote,
          onUpdateNotes,
          onDeleteNote,
          isDisabled,
        }}
      />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} container direction="column" alignItems="flex-end" justifyContent="flex-end">
          <>
            <ButtonGroup
              color="primary"
              variant="outlined"
              aria-label="split button"
              style={{ borderRadius: '2.688rem' }}>
              <Button
                id="add-comparable-transaction-btn"
                onClick={() => addCompany()}
                disabled={isDisabled}
                style={{
                  borderBottomLeftRadius: '2.688rem',
                  borderTopLeftRadius: '2.688rem',
                }}>
                Add Comparable Transaction
              </Button>
              <Button
                id="comparable-group-btn"
                ref={compGroupsRef}
                size="small"
                aria-controls={openCompGroupsOptions ? 'split-button-menu' : undefined}
                aria-expanded={openCompGroupsOptions ? 'true' : undefined}
                aria-label="select comp groups options"
                aria-haspopup="menu"
                onClick={handleToggle}
                disabled={isDisabled}
                style={{
                  borderBottomRightRadius: '2.688rem',
                  borderTopRightRadius: '2.688rem',
                }}>
                {openCompGroupsOptions ? (
                  <ArrowDropUpIcon id="comp-groups-up" />
                ) : (
                  <ArrowDropDownIcon id="comp-groups-down" />
                )}
              </Button>
            </ButtonGroup>
            <CompGroupsOptions
              setOpenCompGroupsOptions={setOpenCompGroupsOptions}
              compGroupsRef={compGroupsRef}
              openCompGroupsOptions={openCompGroupsOptions}
              saveCompGroups={saveCompGroups}
              deleteCompGroup={deleteCompGroup}
              onRemoveCompItem={removeGT}
              tableData={compsSheet.tableData}
              approachType={PRIVATE_TRANSACTIONS}
            />
          </>
        </Grid>
      </Grid>
    </Box>
  );
};

GuidelineTransactions.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  approach: PropTypes.object,
  workbook: PropTypes.object,
  financials: PropTypes.object,
};

export default GuidelineTransactions;
