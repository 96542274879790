import { getStringValue } from 'utilities';
import { GetExpressionParams } from './types';

const getExpression = (params: GetExpressionParams) => {
  const { columnLegend, expr, identifier = '@' } = params;

  // Regex with global search flag
  const regex = new RegExp(identifier, 'g');

  return getStringValue(expr?.replace(regex, columnLegend));
};

export default getExpression;
