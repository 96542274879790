/* eslint-disable no-param-reassign */
import { mapValues } from 'lodash';
import { ENTER_DATA } from 'common/constants/inputs';
import { reverseParser as baseReverseParser } from 'utilities';
import isExpression from 'utilities/isExpression';
import * as CONSTANTS from './constants';

const reverseParser = ({ cells, columns, allowEmptyValues, rowConfig, fieldAttributes }) => {
  const checkPairCells = (cell, key, tmpColumn) => {
    if (CONSTANTS.ROW_WITH_PAIRS.includes(cell.alias)) {
      const statusKey = `${cell.alias}_enabled`;
      const evaluatedCell = cells[key];
      const cellExpr = evaluatedCell?.expr || '';
      const isExpr = isExpression(cellExpr.toString());
      tmpColumn = {
        ...tmpColumn,
        // A cell is enabled when it's not calculated (it's not an expr).
        [statusKey]: !isExpr,
      };
    }
    return tmpColumn;
  };

  const baseReverseParserData = baseReverseParser({
    cells,
    columns,
    allowEmptyValues,
    rowConfig,
    checkPairCells,
    fieldAttributes,
  });

  const cellsToEvaluate = [
    CONSTANTS.CONVERSION_DATE_ALIAS,
    CONSTANTS.INTEREST_AT_MEASUREMENT_DATE_ALIAS,
    CONSTANTS.VALUE_AT_CONVERSION_ALIAS,
    CONSTANTS.VALUE_AT_CONVERSION_ALIAS,
    CONSTANTS.CONVERSION_PRE_MONEY_ALIAS,
    CONSTANTS.PRE_MONEY_SHARE_COUNT_ALIAS,
    CONSTANTS.CONVERSION_PRICE_PER_SHARE_ALIAS,
    CONSTANTS.EXPECTED_SHARES_ALIAS,
  ];

  return baseReverseParserData.map(itemColumn =>
    mapValues(itemColumn, (cellValue, cellAlias) => {
      if (
        cellsToEvaluate.includes(cellAlias)
        && itemColumn[CONSTANTS.EQUITY_CONVERSION_MODEL_ALIAS] === CONSTANTS.LOAN_VALUE
      ) {
        return null;
      }
      return cellValue === ENTER_DATA ? '' : cellValue;
    })
  );
};

export default reverseParser;
