/* eslint-disable no-param-reassign */
import { REVERT_APPROACH_SELECTION } from 'pages/Valuations/util/constants';
import { getObjectValue } from 'utilities';
import { UpdateApproachesSelectionsInTableDataProps } from './types';
import { CustomValuationApproachDCF } from '../../utilities/types';

const updateApproachesSelectionsInTableData = ({
  tableData,
  valueFromSelector,
  updatedAlias,
}: UpdateApproachesSelectionsInTableDataProps) => {
  // 1. set reference if the selected approach isn't persisted yet (subtle difference for the backend)
  // 2. pass panel id and not PT or PC id if the selected approach isn't persisted yet
  // 3. make sure we're not setting two approaches for the same tvt (e.g. both PT AND PC for revenue)
  const { approaches } = getObjectValue(tableData);
  const matchingPersistedApproach = approaches?.find(({ panelId }) => panelId === valueFromSelector);
  const isApproachPersisted = Boolean(matchingPersistedApproach?.id);
  const aliasToUpdate = (
    isApproachPersisted ? updatedAlias : `${updatedAlias.split('_').slice(0, -1).join('_')}_reference`
  ) as keyof CustomValuationApproachDCF;
  const valueToSet = isApproachPersisted
    ? matchingPersistedApproach?.valuations_approach_gpc?.id ?? matchingPersistedApproach?.valuations_approach_gpt?.id
    : valueFromSelector;
  if (tableData.approach.valuations_approach_dcf) {
    (tableData.approach.valuations_approach_dcf[aliasToUpdate] as string | number | undefined) = valueToSet;
    const revertUpdatedAlias = REVERT_APPROACH_SELECTION[
      updatedAlias as keyof typeof REVERT_APPROACH_SELECTION
    ] as keyof CustomValuationApproachDCF;
    (tableData.approach.valuations_approach_dcf[revertUpdatedAlias] as null) = null;
    const revertUpdateReference = `${revertUpdatedAlias
      .split('_')
      .slice(0, -1)
      .join('_')}_reference` as keyof CustomValuationApproachDCF;
    (tableData.approach.valuations_approach_dcf[revertUpdateReference] as null | undefined) = isApproachPersisted
      ? undefined
      : null;
  }
};

export default updateApproachesSelectionsInTableData;
