import { MULTIPLE_ALIASES, SELECT_MULTIPLE_ALIASES } from 'pages/Valuations/util/constants';

const obtainMapForSelectMultipleAlias = () => {
  const pairsSelectMultipleAppliedMultiple = SELECT_MULTIPLE_ALIASES.map((alias, index): any => [
    alias,
    MULTIPLE_ALIASES[index],
  ]);
  return new Map(pairsSelectMultipleAppliedMultiple);
};

export default obtainMapForSelectMultipleAlias;
