import { SecurityBasket } from 'common/types/valuation';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { GetBacksolveBasketParams } from './types';

const getBacksolveBasket = (params: GetBacksolveBasketParams) => {
  const { backsolveScuritiesBasket, securities } = params;

  return backsolveScuritiesBasket?.basket
    ?.map(security => {
      const { security: securityReference, shares: securityShares, value: securityValue } = getObjectValue(security);

      const currentSecurity = securities?.find(
        shareSecurity => getNumberValue(shareSecurity?.id) === getNumberValue(securityReference as number)
      );

      // Security not found
      if (!currentSecurity) return null;

      const { name: securityName, id: securityId } = getObjectValue(currentSecurity);

      return {
        security: {
          id: getNumberValue(securityId),
          name: getStringValue(securityName),
        },
        shares: getNumberValue(securityShares),
        value: getNumberValue(securityValue),
      } as SecurityBasket;
    })
    .filter(Boolean) as SecurityBasket[];
};

export default getBacksolveBasket;
