import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { flowRight, isEmpty, isNil, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { BACKTICK_CHAR, BACKTICK_CODE, EQUAL_CHAR, EQUAL_CODE } from 'common/constants/general';
import { useStore } from 'common/store';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withCellEventListener from 'components/ScalarSpreadsheet/utilities/withCellEventListener';
import {
  VALUATION_EXPAND_GPC_EXTRA_INFO_KEY,
  VALUATION_GPC_TABLE_SLUG,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { getColumnLetter } from 'utilities/alphabet-utilities';
import useValidateDateGridComponent from 'utilities/useValidateDateGridComponent';
import CellShape from './CellShape';
import GPCHideInfoTooltip, { VALUATION_GPC_EXTRA_INFO_TOOLTIP_VISIBLE_TIME } from './GPCHideInfoTooltip';
import { getLocalStorageItem, getUserPreferences, setUserPreferences } from '../utilities';

const useStyles = makeStyles({
  hoverSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '10px',
    height: '100%',
    zIndex: 100,
    '&:hover': {
      cursor: 'e-resize',
    },
    '&.expanded:hover': {
      cursor: 'w-resize',
    },
    '& > .left-border': {
      height: 'inherit',
      backgroundColor: '#8A9097',
      position: 'absolute',
      '&.hovered': {
        width: '2px',
      },
    },
  },
});

const Cell = props => {
  const cellClasses = useStyles();
  const prevActiveCellRef = useRef();

  const {
    attributesRenderer,
    cell,
    className,
    backgroundColor,
    col,
    inLedger,
    onContextMenu,
    onFocus,
    onMouseDown,
    onMouseOver,
    row,
    selected,
  } = props;

  const {
    childIndex,
    colSpan,
    isChildWithDivider,
    isParent,
    isParentWithDivider,
    isVisibleColumn,
    parentColumn,
    rowSpan,
    shouldDisplayTooltip = false,
  } = cell;

  const {
    activeCell,
    collapsibleColumns = {},
    displayLegend = true,
    displayNumbersAndColumns,
    isReadOnly,
    notShowingQuarters,
    setActiveCell,
    setCollapsibleColumns,
    setDisplayNumbersAndColumns,
    tableTerms,
  } = useContext(FeaturedSpreadsheetContext);

  const { tableSlug = undefined } = tableTerms ?? {};

  const attributes = useMemo(
    () => (attributesRenderer ? attributesRenderer(cell, row, col) : {}),
    [attributesRenderer, cell, row, col]
  );

  const columnId = attributes['data-column-id'];
  const parentId = attributes['data-col-parent'];

  const keyUpHandler = useCallback(
    event => {
      const key = event.key || event.code;

      if (key) {
        // Do not attempt to set the active cell if the function is not available in the context.
        // The function is valid for the ScalarSpreadsheet cells only.
        const initiatingKey = event.ctrlKey || event.metaKey || event.altKey;
        // add support for using ctrl + = in Mac
        const backtickOrEqualHitted = [BACKTICK_CHAR, BACKTICK_CODE, EQUAL_CHAR, EQUAL_CODE].includes(key);
        if (initiatingKey && backtickOrEqualHitted && setActiveCell && cell.expr) {
          let tmpCell = {};
          const isActiveCellEmpty = isEmpty(activeCell);

          if (isActiveCellEmpty) {
            tmpCell = { ...cell };
          }

          // This will fire the first time that the key combination is pressed,
          // because by default we don't have the numbers, column legends and formula bar visible
          if (isActiveCellEmpty && !displayNumbersAndColumns && setDisplayNumbersAndColumns) {
            setDisplayNumbersAndColumns(true);
          }
          // This will fire the second time that the key combination is pressed
          // At this point we should see the numbers and column legends
          else if (isActiveCellEmpty && displayNumbersAndColumns) {
            setActiveCell(tmpCell);
          }
          // This will fire the third time that the key combination is pressed
          // This time the numbers, column legends and formula bar will be hidden again
          else if (!isActiveCellEmpty && displayNumbersAndColumns && setDisplayNumbersAndColumns) {
            setDisplayNumbersAndColumns(false);
            setActiveCell(undefined);
          }
        }
      }
    },
    [activeCell, cell, setActiveCell, displayNumbersAndColumns, setDisplayNumbersAndColumns]
  );

  const getClasses = useCallback(() => {
    let isVisible = false;
    if (!isNil(isVisibleColumn)) {
      isVisible = isVisibleColumn;
    } else {
      isVisible = isParent || isUndefined(collapsibleColumns) || !collapsibleColumns[parentColumn];
    }
    const hideLegend = !displayLegend && cell.isLegend;
    // if the isReadOnly variable is true, then add the 'read-only' string to the className variable if it isn't already there
    let cellClassName = className || '';
    if (isReadOnly && !className.includes('read-only')) {
      cellClassName += ' read-only';
    }

    const classes = [
      cellClasses.root,
      cellClassName,
      isVisible && !hideLegend ? 'visible' : 'hidden',
      isChildWithDivider && isVisible ? 'child-with-divider' : '',
      isParentWithDivider && notShowingQuarters ? 'parent-with-divider' : '',
    ];

    return classes.join(' ');
  }, [
    isReadOnly,
    cell.isLegend,
    className,
    cellClasses.root,
    collapsibleColumns,
    displayLegend,
    isChildWithDivider,
    isParent,
    isParentWithDivider,
    isVisibleColumn,
    parentColumn,
    notShowingQuarters,
  ]);

  const classes = useMemo(() => getClasses(), [getClasses]);

  useEffect(() => {
    if (selected && !inLedger) {
      document.addEventListener('keydown', keyUpHandler);
    }

    return () => document.removeEventListener('keydown', keyUpHandler);
  }, [selected, inLedger, keyUpHandler]);

  useEffect(() => {
    prevActiveCellRef.current = activeCell;
  }, [activeCell]);

  useEffect(() => {
    const prevActiveCell = prevActiveCellRef.current;
    if (selected && !isEmpty(prevActiveCell) && prevActiveCell.key !== cell.key) {
      // Attempting to set the active cell breaks if there is more than one active cell
      setActiveCell({ ...cell, row, col });
    }
  }, [prevActiveCellRef, cell, col, selected, row, setActiveCell]);

  const cellClassName = useMemo(
    () =>
      [cellClasses.hoverSection, !isEmpty(collapsibleColumns) && !collapsibleColumns[columnId] ? 'expanded' : ''].join(
        ' '
      ),
    [cellClasses.hoverSection, collapsibleColumns, columnId]
  );

  // we use this piece of state to determine of the cell was double clicked into or not.
  // child component (basically the ValueEditor, which is the only place this is relevant) is
  // responsible to unset this, otherwise it stays this way in perpetuity, which is fine
  // if it is not looking to make use of this feature.
  const [doubleClicked, setDoubleClicked] = useState(false);
  const { doubleClickEvent, cellContent } = useValidateDateGridComponent(props, doubleClicked, setDoubleClicked);

  const onHover = useCallback(
    hover => {
      const cells = document.querySelectorAll(`td[data-column-id="${columnId}"] .left-border`);

      if (!isEmpty(collapsibleColumns) && cells) {
        cells.forEach(item => {
          if (hover) {
            item.classList.add('hovered');
          } else {
            item.classList.remove('hovered');
          }
        });
      }
    },
    [collapsibleColumns, columnId]
  );

  const endHover = useCallback(() => {
    onHover(false);
  }, [onHover]);

  const startHover = useCallback(() => {
    onHover(true);
  }, [onHover]);
  const dataCellKey = attributes.id || `CELL-${getColumnLetter(col)}${row}`;

  // GPC Extra Info
  const [{ user }] = useStore();

  const expandGPCLocalStorage = getLocalStorageItem(VALUATION_EXPAND_GPC_EXTRA_INFO_KEY);
  const isGPC = tableSlug === VALUATION_GPC_TABLE_SLUG;
  const userPreferences = getUserPreferences(user, expandGPCLocalStorage);
  const shouldExpandGPC = !!(userPreferences || isNull(userPreferences) || isNull(expandGPCLocalStorage));

  const [hasBeenExpandedOnMount, setHasBeenExpandedOnMount] = useState(false);
  const [shouldHideTooltip, setShouldHideTooltip] = useState(false);
  const [shouldRenderTooltip, setShouldRenderTooltip] = useState(shouldDisplayTooltip);

  const isCollapsableColumn = useMemo(() => !!(isParent || childIndex === 1), [childIndex, isParent]);
  const dataParentIsExpanded = useMemo(
    () => !isEmpty(collapsibleColumns) && isCollapsableColumn && !collapsibleColumns[columnId],
    [collapsibleColumns, columnId, isCollapsableColumn]
  );
  const shouldSavePreferences = useMemo(() => isCollapsableColumn && isGPC, [isCollapsableColumn, isGPC]);

  const onExpand = useCallback(() => {
    const colId = isParent ? columnId : parentId;
    let isCollapsed = true;

    setCollapsibleColumns(prevCols => {
      isCollapsed = !prevCols[colId];

      return {
        ...prevCols,
        [colId]: isCollapsed,
      };
    });

    const childrenCells = document.querySelectorAll('td.child-with-divider');
    const parentCells = document.querySelectorAll('td.parent-with-divider');

    if (childrenCells?.length) {
      parentCells.forEach(parentCell => {
        parentCell.classList.add('hide');
      });
    } else {
      parentCells.forEach(parentCell => {
        parentCell.classList.remove('hide');
      });
    }

    return !isCollapsed;
  }, [columnId, isParent, parentId, setCollapsibleColumns]);

  // Save the GPC Extra Info Tooltip preference
  const expandSavingPreferences = useCallback(() => {
    if (shouldSavePreferences) {
      setUserPreferences(user, VALUATION_EXPAND_GPC_EXTRA_INFO_KEY, {
        ...JSON.parse(getLocalStorageItem(VALUATION_EXPAND_GPC_EXTRA_INFO_KEY)),
        [user?.id]: !dataParentIsExpanded,
      });
    } else {
      // On financials page we don't need to save the preferences (at least for now)
      onExpand();
    }
  }, [dataParentIsExpanded, shouldSavePreferences, user, onExpand]);

  // Expand or collapse the GPC Extra Info Tooltip based on the local storage value
  const handleStorageEvent = useCallback(() => {
    const currentLocalStorageItem = getLocalStorageItem(VALUATION_EXPAND_GPC_EXTRA_INFO_KEY);
    const shouldExpandExtraInfo = getUserPreferences(user, currentLocalStorageItem);

    if (isNull(currentLocalStorageItem)) return;

    switch (shouldExpandExtraInfo) {
      case true:
        if (!dataParentIsExpanded) onExpand();
        break;

      case false:
        if (dataParentIsExpanded) onExpand();
        break;

      // null
      default:
        break;
    }
  }, [dataParentIsExpanded, onExpand, user]);

  // Check if the GPC Extra Info Tooltip should be displayed
  useEffect(() => {
    if (shouldDisplayTooltip) {
      if (shouldExpandGPC && !hasBeenExpandedOnMount) onExpand();

      setHasBeenExpandedOnMount(true);
    }
  }, [hasBeenExpandedOnMount, shouldDisplayTooltip, onExpand, shouldExpandGPC]);

  // Hide the GPC Extra Info Tooltip after 10 seconds
  useEffect(() => {
    let fadeOutTimer;
    let removeTimer;

    if (shouldDisplayTooltip && userPreferences) {
      fadeOutTimer = setTimeout(() => setShouldHideTooltip(true), VALUATION_GPC_EXTRA_INFO_TOOLTIP_VISIBLE_TIME);
      removeTimer = setTimeout(
        () => setShouldRenderTooltip(false),
        VALUATION_GPC_EXTRA_INFO_TOOLTIP_VISIBLE_TIME + 1000
      );
    }

    return () => {
      if (shouldDisplayTooltip) {
        clearTimeout(fadeOutTimer);
        clearTimeout(removeTimer);
      }
    };
  }, [shouldDisplayTooltip, userPreferences]);

  // Listen to the storage event to expand or collapse the GPC Extra Info Tooltip
  useEffect(() => {
    if (shouldDisplayTooltip) window.addEventListener('storage', handleStorageEvent);

    return () => {
      if (shouldDisplayTooltip) window.removeEventListener('storage', handleStorageEvent);
    };
  }, [handleStorageEvent, shouldDisplayTooltip]);

  const cellStyle = useMemo(() => {
    if (backgroundColor) {
      return {
        ...cell.style,
        backgroundColor,
      };
    }
    return cell.style;
  }, [cell, backgroundColor]);

  return (
    <td
      className={classes}
      onMouseDown={onMouseDown}
      onMouseOver={onMouseOver}
      onContextMenu={onContextMenu}
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={cellStyle}
      onFocus={onFocus}
      data-parent-expanded={dataParentIsExpanded}
      data-cell-id={dataCellKey}
      data-row-span={rowSpan}
      {...doubleClickEvent}
      {...attributes}>
      {isCollapsableColumn && (
        <>
          {shouldExpandGPC && shouldRenderTooltip && <GPCHideInfoTooltip hideTooltip={shouldHideTooltip} />}

          <Grid
            className={cellClassName}
            onBlur={endHover}
            onClick={expandSavingPreferences}
            onFocus={startHover}
            onKeyDown={event => {
              if (event.key === 'Enter' || event.key === ' ') expandSavingPreferences();
            }}
            onMouseLeave={endHover}
            onMouseOver={startHover}>
            <Grid className="left-border" />
          </Grid>
        </>
      )}
      {cellContent}
    </td>
  );
};

Cell.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  cell: PropTypes.shape(CellShape),
  selected: PropTypes.bool,
  editing: PropTypes.bool,
  updated: PropTypes.bool,
  attributesRenderer: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseOver: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onFocus: PropTypes.func,
  children: PropTypes.element,
  inLedger: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Cell.defaultProps = {
  selected: false,
  editing: false,
  updated: false,
  attributesRenderer: () => {},
};
const enhance = flowRight(withCellEventListener);
export default enhance(Cell);
