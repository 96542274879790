import { MAX_CHAR_LENGTH } from 'common/constants/general';
import { largeCurrencyFormat } from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/externalValuation';
import {
  EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE_ROW_NUMBER,
  EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE_ROW_NUMBER,
} from 'pages/ValuationsAllocation/common/constants/externalValuation/sheetAliases';
import {
  VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
  VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { VALUATION_SUMMARY_KEY } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import {
  VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY,
  VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary/sheetAliases';
import { getExpression, getObjectValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { CellParserParams, ExternalValuationParserParams } from './types';

const {
  EXTERNAL_VALUATION_SPREADSHEET_HEADER_TITLE,
  EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE,
  EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, row, rowIndex, isEnterpriseValueCalculated } = params;

  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + rowNumber;

  const { value: columnValue } = getObjectValue(column[row.alias]);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  const enterPriseValueExpr = `=${VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY}+${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}-${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;
  const equityValueExpr = `=${VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY}-${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY}+${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`;

  // Parse Cell based on Row alias
  switch (row.alias) {
    case EXTERNAL_VALUATION_SPREADSHEET_HEADER_TITLE:
      cell.value = '';
      break;

    case EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE:
      cell.className = !isEnterpriseValueCalculated ? 'gray-cell' : '';
      cell.customKey = VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY;
      // Equity Value = Enterprise Value - Debt + Cash
      cell.expr = !isEnterpriseValueCalculated ? equityValueExpr : null;
      cell.exprPair = equityValueExpr;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      cell.rowNumberPair = EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE_ROW_NUMBER;
      break;

    case EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE:
      cell.className = isEnterpriseValueCalculated ? 'gray-cell' : '';
      cell.customKey = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
      // Enterprise Value = Equity Value + Debt - Cash
      cell.expr = isEnterpriseValueCalculated ? enterPriseValueExpr : null;
      cell.exprPair = enterPriseValueExpr;
      cell.format = largeCurrencyFormat;
      cell.gridType = 'number';
      cell.rowNumberPair = EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE_ROW_NUMBER;
      break;

    default:
      break;
  }

  // Set default Cell values
  const {
    allowEmptyValues = true,
    customKey = null,
    dbType = 'string',
    expr = '',
    format = null,
    gridType = 'string',
    isTextArea = false,
    maxNumberChars = MAX_CHAR_LENGTH,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowEmptyValues,
      columnLegend,
      customKey,
      dbType,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      isTextArea,
      maxNumberChars,
      value,
    } as Cell,
  };
};

const customParser = (params: ExternalValuationParserParams) => {
  const { columns, rowConfig, isEnterpriseValueCalculated } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
          isEnterpriseValueCalculated,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
