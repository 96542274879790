import React from 'react';
import { CAP_TABLE_SELECTION_ALIAS, CAP_TABLE_SELECTION_TITLE } from 'common/constants/valuations';
import { Cell } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { CapTableListSelection } from 'pages/ValuationsAllocation/components/CapTableListSelection';
import { CapTableListSelectionProps } from 'pages/ValuationsAllocation/components/CapTableListSelection/types';
import { GetRowConfigParams } from './types';

const getRowConfig = ({ capTableList, isDisabled }: GetRowConfigParams) => {
  const selectProps = {
    menuPosition: 'fixed',
    objectOptions: { displayKey: 'name', valueKey: 'id' },
    options: capTableList,
  };
  return [
    {
      value: CAP_TABLE_SELECTION_TITLE,
      alias: CAP_TABLE_SELECTION_ALIAS,
      rowNumber: 1,
      customKey: 'capTableSelection',
      readOnly: isDisabled,
      dbType: 'string',
      defaultValue: capTableList?.find(ct => ct.is_primary)?.id,
      expr: '',
      dataEditor: (props: CapTableListSelectionProps) => <CapTableListSelection {...props} />,
      valueViewer: (props: Cell) => <SelectValueViewer {...props} {...selectProps} />,
      ignoreRowCopy: true,
    },
  ];
};

export default getRowConfig;
