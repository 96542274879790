import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { F2_KEY } from 'common/constants/general';
import { GET_ENTER_DATA } from 'common/constants/inputs';
import { LedgerContext } from 'context';
import { formatNumbers, toString } from 'utilities';

const useStyles = makeStyles({
  chipContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'absolute',
    left: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const buttonStyle = {
  background: 'none',
  border: 'none',
  color: 'inherit',
  width: '100%',
  textAlign: 'right',
};

function checkEnterMessageConditions(cell) {
  return (
    ((!isUndefined(cell.value) && !cell.hidden) || cell.value)
    && cell.gridType
    && (cell.gridType === 'number' || cell.gridType === 'percentage')
    && !Number.isNaN(cell.value)
  );
}

const CurrencyAdjustedValueViewer = ({ cell, selected, onDoubleClick }) => {
  const { exchangeRateMap } = useContext(LedgerContext);
  const [displayCurrency, setDisplayCurrency] = useState('');
  const [isChipClicked, setIsChipClicked] = useState(false);
  const [isChipVisible, setIsChipVisible] = useState(false);
  const classes = useStyles();

  const onChipClick = useCallback(() => {
    setIsChipClicked(prevState => !prevState);
  }, []);

  useEffect(() => {
    if (isChipClicked) {
      setDisplayCurrency(exchangeRateMap.fundCurrency);
    } else {
      setDisplayCurrency(cell.currency.code);
    }
  }, [isChipClicked, exchangeRateMap, cell]);

  const keyUpHandler = useCallback(
    event => {
      const key = event.key || event.keyCode;
      if (selected && key && key.toUpperCase() === F2_KEY) {
        onDoubleClick();
      }
    },
    [onDoubleClick, selected]
  );

  const spanId = useMemo(() => {
    if (cell.extraAttr) {
      return `${cell.extraAttr}-${cell.alias}-${cell.key}_viewer`;
    }

    return `${cell.alias}-${cell.key}_viewer`;
  }, [cell]);

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);
    return () => document.removeEventListener('keyup', keyUpHandler);
  }, [keyUpHandler, selected]);

  const value = useMemo(() => {
    if (checkEnterMessageConditions(cell)) {
      if (!cell.readOnly && !toString(cell.value).length) {
        return cell.enterDataMessage || GET_ENTER_DATA(cell.isRequired, cell.enterDataMessage);
      }
      // Override number of decimals and display the entire number to highlight the errors
      if (cell.allowDecimals === false && cell.tooltipMessages?.length > 0) return cell.value;
      if (!isChipClicked) {
        return formatNumbers(cell);
      }
      return formatNumbers({
        ...cell,
        value: cell.value * exchangeRateMap[cell.relatedDate],
        currency: {
          ...cell.currency,
          code: exchangeRateMap.fundCurrency,
        },
      });
    }
    if (!cell.readOnly && !cell.hidden && !cell.value && cell.placeholder) {
      return cell.placeholder;
    }

    return cell.value;
  }, [cell, exchangeRateMap, isChipClicked]);

  const exchangeRateTooltip = useMemo(() => {
    if (isNull(cell.relatedDate)) {
      return 'Please select a date to see the exchange rate';
    }
    if (isUndefined(exchangeRateMap?.[cell.relatedDate])) {
      return 'Loading exchange rate';
    }
    if (exchangeRateMap?.[cell.relatedDate] === -1) {
      return 'Oops! An error occurred loading the exchange rate.';
    }
    return `1 ${cell.currency.code} -> ${exchangeRateMap[cell.relatedDate]} ${exchangeRateMap.fundCurrency}`;
  }, [exchangeRateMap, cell]);

  const showChip = useCallback(() => {
    setIsChipVisible(true);
  }, []);

  const hideChip = useCallback(() => {
    setIsChipVisible(false);
  }, []);

  const isChipDisabled = useMemo(
    () =>
      isNull(cell.relatedDate)
      || isUndefined(exchangeRateMap?.[cell.relatedDate])
      || exchangeRateMap?.[cell.relatedDate] === -1,
    [exchangeRateMap, cell]
  );

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      // Prevent the default action to stop scrolling when space is pressed
      e.preventDefault();
      showChip();
    }
  };

  return (
    <button
      type="button"
      tabIndex="0"
      onMouseOver={showChip}
      onFocus={showChip}
      onMouseLeave={hideChip}
      onKeyDown={handleKeyDown}
      style={buttonStyle}>
      <span id={spanId} className={`text-cell ${cell.className}`}>
        {displayCurrency && isChipVisible && (
          <div className={classes.chipContainer} style={{ cursor: 'pointer' }}>
            <Tooltip title={exchangeRateTooltip} placement="top">
              <span>
                <Chip
                  label={displayCurrency}
                  size="small"
                  color="secondary"
                  disabled={isChipDisabled}
                  onClick={onChipClick}
                />
              </span>
            </Tooltip>
          </div>
        )}
        {value}
      </span>
    </button>
  );
};

CurrencyAdjustedValueViewer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cell: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alias: PropTypes.string,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltipMessages: PropTypes.arrayOf(PropTypes.string),
    allowDecimals: PropTypes.bool,
    enterDataMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    gridType: PropTypes.string,
    currency: PropTypes.shape({
      code: PropTypes.string,
      symbol: PropTypes.string,
    }),
    relatedDate: PropTypes.string,
    extraAttr: PropTypes.string,
  }),
  selected: PropTypes.bool,
  onDoubleClick: PropTypes.func,
};

export default CurrencyAdjustedValueViewer;
