import { getStringValue } from 'utilities';
import { MatchPatternParams } from './types';

export const matchPattern = (params: MatchPatternParams) => {
  const { label, patterns } = params;

  const patterResult = patterns?.map(pattern => ({ result: pattern.test(getStringValue(label)), pattern }));
  const isValidResult = patterResult?.find(pattern => pattern.result);

  // By slicing from 2 to 4 we get the 'rd' which will split a label like '33rd Percentile'
  return isValidResult ? isValidResult.pattern.toString().slice(2, 4) : '';
};

export default matchPattern;
