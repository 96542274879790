import React from 'react';
import { VALUATIONS_DEFAULT_CURRENCY, VALUATIONS_DEFAULT_CURRENCY_SYMBOL } from 'common/constants/valuations';
import { TableContainer } from 'pages/Valuations/approaches/guidelinePublicCompanies/AddGpcDialogue/components';
import {
  EBITDA,
  ENTERPRISE_VALUE_MILLIONS,
  MARKET_CAP_MILLIONS,
  REVENUE,
  STOCK_PRICE,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { getCurrency } from 'pages/ValuationsAllocation/util/getCurrency';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import {
  GetGPCTableRowsParams,
  GetMultipleParams,
  GetTableRowParams,
  GPCTableRowsResult,
  TableRowValue,
} from './types';

const getTableRow = (params: GetTableRowParams): TableRowValue => {
  const { label, valueProps } = params;
  const { number, multiplier, prepend, append, decimalsToDisplay } = getObjectValue(valueProps);

  return {
    label,
    value: (
      <TableContainer
        append={append}
        decimalsToDisplay={decimalsToDisplay}
        multiplier={multiplier}
        number={getNumberValue(number)}
        prepend={prepend}
      />
    ),
  };
};

const getMultiple = (params: GetMultipleParams) => {
  const { enterpriseValue, revenue } = params;

  if (enterpriseValue && revenue) {
    return enterpriseValue / revenue;
  }
  return 0;
};

const getGPCTableRows = (params: GetGPCTableRowsParams): GPCTableRowsResult | null => {
  const { financialsCurrency, gpcData } = params;

  if (!gpcData) return null;

  const companyName = getStringValue(gpcData.company_name?.value);
  const businessDescription = getStringValue(gpcData.business_description?.value);
  const tickerSymbol = getStringValue(gpcData.ticker_symbol?.value);
  const closePrice = getNumberValue(gpcData.close_price?.value);
  const enterpriseValue = getNumberValue(gpcData.enterprise_value?.value);
  const ltmRevenue = getNumberValue(gpcData.ltm_revenue?.value);
  const ltmEbitda = getNumberValue(gpcData.ltm_ebitda?.value);
  const marketCap = getNumberValue(gpcData.market_cap?.value);

  return {
    companyName,
    businessDescription,
    ticker: tickerSymbol,
    tableRows: [
      getTableRow({
        label: STOCK_PRICE,
        valueProps: {
          number: closePrice,
          prepend: getCurrency({
            currencyKey: financialsCurrency,
            defaultValue: VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
          }),
          decimalsToDisplay: 0,
        },
      }),
      getTableRow({
        label: ENTERPRISE_VALUE_MILLIONS({
          currencyCode: financialsCurrency ?? VALUATIONS_DEFAULT_CURRENCY,
          currencySymbol: getCurrency({
            currencyKey: financialsCurrency,
            defaultValue: VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
          }),
        }),
        valueProps: {
          number: enterpriseValue,
          prepend: getCurrency({
            currencyKey: financialsCurrency,
            defaultValue: VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
          }),
          decimalsToDisplay: 2,
          multiplier: 1 / 10 ** 6,
        },
      }),
      getTableRow({
        label: MARKET_CAP_MILLIONS({
          currencyCode: financialsCurrency ?? VALUATIONS_DEFAULT_CURRENCY,
          currencySymbol: getCurrency({
            currencyKey: financialsCurrency,
            defaultValue: VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
          }),
        }),
        valueProps: {
          number: marketCap,
          prepend: getCurrency({
            currencyKey: financialsCurrency,
            defaultValue: VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
          }),
          decimalsToDisplay: 0,
          multiplier: 1 / 10 ** 6,
        },
      }),
      getTableRow({
        label: REVENUE,
        valueProps: {
          number: getMultiple({ enterpriseValue, revenue: ltmRevenue }),
          append: 'x',
          decimalsToDisplay: 2,
        },
      }),
      getTableRow({
        label: EBITDA,
        valueProps: {
          number: getMultiple({ enterpriseValue, revenue: ltmEbitda }),
          append: 'x',
          decimalsToDisplay: 2,
        },
      }),
    ],
  };
};

export default getGPCTableRows;
