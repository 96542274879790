import { INCOME_STATEMENT_PROP } from 'common/constants/financials';
import { getValueByPropOrFallback } from 'utilities';
import updateFiscalQuarters from './updateFiscalQuarters';

const getVisibleColumnsFromPeriods = ({ periods, aliasesToUpdate, accessedProperty, template }) => {
  const cleanedPeriods = periods.map(period => {
    const data = getValueByPropOrFallback(period, accessedProperty, {});
    const info = {
      ...period,
    };

    delete info.income_statement;
    delete info.balance_sheet;
    delete info.kpi_dataset;
    delete info.statement_date;
    delete info.period_type;
    delete info.is_projection;

    return {
      ...template,
      ...data,
      ...info,
    };
  });

  const filterCondition
    = accessedProperty === INCOME_STATEMENT_PROP
      ? period => !period.is_deleted
      : period => !period.is_deleted && !period.isNTM;

  const updatedPeriods = updateFiscalQuarters({
    conditions: ['isLTMQuarter', 'isNTMQuarter', 'isParent'],
    allColumns: cleanedPeriods,
    aliasesToUpdate,
  });
  return updatedPeriods.filter(period => filterCondition(period));
};

export default getVisibleColumnsFromPeriods;
