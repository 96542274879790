/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  baseCompanyUrl,
  breakpointAnalysisUrl,
  captableUrl,
  companySummaryUrl,
  documentGenerationJobsUrl,
  documentsUrl,
  financialUrl,
  fundOwnershipUrl,
  valuationUrl,
  waterfallUrl,
} from 'common/config/urls';
import { WATERFALL_SLUG, WATERFALL_TITLE } from 'common/constants/allocations';
import {
  BREAKPOINT_ANALYSIS_SLUG,
  BREAKPOINT_ANALYSIS_TITLE,
  CAP_TABLE_SLUG,
  CAP_TABLE_TITLE,
  FUND_OWERNERSHIP_TITLE,
  FUND_OWNERSHIP_SLUG,
} from 'common/constants/cap-table';
import { COMPANIES_SUMMARY_SLUG, COMPANIES_SUMMARY_TITLE } from 'common/constants/companies';
import {
  DOCUMENT_GENERATION_JOBS_SLUG,
  DOCUMENT_GENERATION_JOBS_TITLE,
  DOCUMENTS_SLUG,
  DOCUMENTS_TITLE,
} from 'common/constants/documents';
import { FINANCIAL_SLUG, FINANCIAL_TITLE } from 'common/constants/financials';
import { MISSING_MD_MESSAGE, MISSING_MD_TITLE } from 'common/constants/messages/validations';
import { EDIT_COMPANY, EXCEL_EXPORT } from 'common/constants/pageActions';
import { VERSION_QUERY_PARAM } from 'common/constants/pageFilters';
import { VALUATIONS_SLUG, VALUATIONS_TITLE } from 'common/constants/valuations';
import { useStore } from 'common/store';
import MissingMeasurementDateMessage from 'components/MissingMeasurementDateMessage';
import withCompany from 'components/WithCompany';
import LayoutContext from 'context/LayoutContext';
import {
  CapTableView,
  Documents as DocumentsView,
  Financials as FinancialPage,
  Portfolio as PortfolioView,
  ValuationsAllocation as ValuationsAllocationView,
  Waterfall as WaterfallView,
} from 'pages';
import { TAB_QUERY_PARAM } from 'pages/ValuationsAllocation/common/constants/valuations';
import { extractDate, removeParamFromQueryParams } from 'utilities';
import { DOCUMENT_GENERATION_JOBS } from '../../common/constants/features';
import DocumentGenerationJobs from '../../pages/DocumentGenerationJobs';
import useCheckFeatures from '../FeatureEnabler/hooks';

const CompanyContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const [, checkFeature] = useCheckFeatures();

  const [{ companyInfo, firmInfo }] = useStore();
  const {
    setPageTitle,
    setNavItems,
    setShowAlert,
    setAlertMessage,
    setCommonMeasurementDate,
    toggleMainForm,
    toggleExportDialog,
    updateExtraPageActions,
  } = useContext(LayoutContext);

  const isLoaded = useMemo(
    () => (!isEmpty(firmInfo) && !isEmpty(companyInfo) ? baseCompanyUrl(firmInfo.slug, companyInfo.slug) : null),
    [firmInfo, companyInfo]
  );

  const parsedQuery = useMemo(() => queryString.parse(location.search), [location]);

  useEffect(() => {
    if (parsedQuery?.date) {
      setCommonMeasurementDate(
        extractDate({
          dateSlug: parsedQuery.date,
        })
      );
    }
  }, [parsedQuery?.date]);

  const navigateToUrl = getUrl => {
    if (isLoaded) {
      const newPathName = getUrl(firmInfo.slug, companyInfo.slug);

      // Use the measurement date query string if available
      if (parsedQuery.date) {
        // Delete the Version query param if it exists
        if (VERSION_QUERY_PARAM in parsedQuery) delete parsedQuery[VERSION_QUERY_PARAM];

        // Delete the Valuations Approach query param if it exists
        if (TAB_QUERY_PARAM in parsedQuery) delete parsedQuery[TAB_QUERY_PARAM];
      }

      history.push({ pathname: newPathName, search: queryString.stringify(parsedQuery) });
    }
  };

  const capTableSubMenu = [
    {
      callback: () => navigateToUrl(fundOwnershipUrl),
      hidden: true,
      id: 'fund-ownership',
      itemKey: 3,
      slug: FUND_OWNERSHIP_SLUG,
      title: FUND_OWERNERSHIP_TITLE,
    },
    {
      callback: () => navigateToUrl(breakpointAnalysisUrl),
      hidden: true,
      id: 'breakpoint-analysis',
      itemKey: 4,
      slug: BREAKPOINT_ANALYSIS_SLUG,
      title: BREAKPOINT_ANALYSIS_TITLE,
    },
  ];

  const menuItems = useMemo(
    () => [
      {
        callback: () => navigateToUrl(companySummaryUrl),
        id: 'summary',
        itemKey: 0,
        slug: COMPANIES_SUMMARY_SLUG,
        title: COMPANIES_SUMMARY_TITLE,
      },
      {
        callback: () => navigateToUrl(financialUrl),
        id: 'financial',
        itemKey: 1,
        slug: FINANCIAL_SLUG,
        title: FINANCIAL_TITLE,
      },
      {
        callback: () => navigateToUrl(captableUrl),
        id: 'captable',
        itemKey: 2,
        slug: CAP_TABLE_SLUG,
        subMenu: capTableSubMenu,
        title: CAP_TABLE_TITLE,
      },
      ...capTableSubMenu,
      {
        callback: () => navigateToUrl(valuationUrl),
        id: 'valuation',
        itemKey: 5,
        slug: VALUATIONS_SLUG,
        title: VALUATIONS_TITLE,
      },
      {
        callback: () => navigateToUrl(waterfallUrl),
        id: 'waterfall',
        itemKey: 6,
        slug: WATERFALL_SLUG,
        title: WATERFALL_TITLE,
      },
      {
        callback: () => navigateToUrl(documentsUrl),
        id: 'documents',
        itemKey: 7,
        slug: DOCUMENTS_SLUG,
        title: DOCUMENTS_TITLE,
      },
      ...(checkFeature(DOCUMENT_GENERATION_JOBS)
        ? [
          {
            callback: () => navigateToUrl(documentGenerationJobsUrl),
            id: 'documents_generation_jobs',
            itemKey: 8,
            slug: DOCUMENT_GENERATION_JOBS_SLUG,
            title: DOCUMENT_GENERATION_JOBS_TITLE,
          },
        ]
        : []),
    ],
    [companyInfo, isLoaded, parsedQuery]
  );

  const getPath = slug => `/firms/:firmSlugParam/companies/:companySlugParam/${slug}`;

  // Hides alert box when browsing to another location
  useEffect(
    () => () => {
      setShowAlert(false);
      setAlertMessage();
    },
    [history.location]
  );

  useEffect(() => {
    if (!isEmpty(companyInfo) && companyInfo.name) {
      setPageTitle(companyInfo.name);
      setNavItems(menuItems);
    }

    return () => {
      setPageTitle(null);
      setNavItems(null);
    };
  }, [companyInfo, location]);

  useEffect(() => {
    const extraActions = [
      {
        label: EDIT_COMPANY,
        callback: toggleMainForm,
        isActive: true,
      },
    ];
    extraActions.push({
      label: EXCEL_EXPORT,
      callback: toggleExportDialog,
      isActive: companyInfo?.measurement_date_id,
    });

    updateExtraPageActions(extraActions);
  }, [toggleMainForm, companyInfo, updateExtraPageActions]);

  if (!isEmpty(companyInfo) && !companyInfo?.measurement_date_id) {
    removeParamFromQueryParams('date');
    return <MissingMeasurementDateMessage title={MISSING_MD_TITLE} tagline={MISSING_MD_MESSAGE} />;
  }

  return (
    <Switch>
      <Route path={getPath(COMPANIES_SUMMARY_SLUG)}>
        <PortfolioView />
      </Route>
      <Route path={getPath(FINANCIAL_SLUG)}>
        <FinancialPage />
      </Route>
      <Route path={getPath(VALUATIONS_SLUG)}>
        <ValuationsAllocationView />
      </Route>
      <Route path={getPath(WATERFALL_SLUG)}>
        <WaterfallView />
      </Route>
      <Route path={getPath(DOCUMENTS_SLUG)}>
        <DocumentsView />
      </Route>
      <Route path={getPath(DOCUMENT_GENERATION_JOBS_SLUG)}>
        <DocumentGenerationJobs />
      </Route>
      <Route path={getPath(':tableSlugParam')}>
        <CapTableView />
      </Route>
    </Switch>
  );
};

export default withCompany(CompanyContainer);
