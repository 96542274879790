import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import uuid from 'react-uuid';
import { ParserParams } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { SHEET_CONFIG_CONSTANTS } from 'pages/Portfolio/common/constants/companySummary';
import {
  CompanySummaryColumn,
  createColumns,
  customParser,
  rowConfig,
} from 'pages/Portfolio/pages/CompanySummary/config';
import { RowData } from 'pages/Portfolio/pages/CompanySummary/config/rowConfig';
import { getArrayValue } from 'utilities';
import {
  CompanyFundInvestment,
  CompanySummaryInitialObject,
  FundInvestmentsWithId,
  FundsSecuritiesIndexes,
  UseCompanySummarySheet,
} from './types';

const { COMPANY_SUMMARY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const EXCLUDE_INDEX_AND_SUBHEADER_AND_SUBTOTAL = 3;

const useCompanySummarySheet: UseCompanySummarySheet = params => {
  const { companySlug, funds = [], firmSlug, queryParams } = params;

  const fundsSecuritiesWithId = useMemo(
    () =>
      getArrayValue(
        funds.map(fund => ({
          ...fund,
          investments: fund.investments?.map(investment => ({ ...investment, id: uuid() })),
        })) as FundInvestmentsWithId[]
      ),
    [funds]
  );

  const fundsInvestments = useMemo(
    () =>
      getArrayValue(
        fundsSecuritiesWithId?.reduce((accumulator, current) => {
          const { fund_slug: fundSlug, investments = [] } = current;

          const fundSecurities = getArrayValue(
            investments?.map(
              investment =>
                ({
                  ...investment,
                  fundSlug,
                } as CompanyFundInvestment)
            )
          );

          return [...accumulator, ...fundSecurities];
        }, [] as CompanyFundInvestment[])
      ),
    [fundsSecuritiesWithId]
  );

  const fundsSecuritiesIndexes = useMemo(() => {
    let lastFundSlug = '';
    let lastIndex = 0;

    return fundsInvestments.reduce((accumulator, current) => {
      const { fundSlug: fundTotalKey = '' } = current;

      const fundRows = getArrayValue(accumulator?.[fundTotalKey]);

      if (lastFundSlug && fundTotalKey === lastFundSlug) {
        lastIndex += 1;
      } else {
        lastIndex += EXCLUDE_INDEX_AND_SUBHEADER_AND_SUBTOTAL;
      }

      // Update Last iterated Fund
      lastFundSlug = fundTotalKey;

      return {
        ...accumulator,
        [fundTotalKey]: [...fundRows, lastIndex],
      };
    }, {} as FundsSecuritiesIndexes);
  }, [fundsInvestments]);

  // Create row config for Investments Spreadsheet
  const config = useMemo(
    () => rowConfig({ companySlug, firmSlug, funds: fundsSecuritiesWithId, queryParams }),
    [companySlug, firmSlug, fundsSecuritiesWithId, queryParams]
  );

  // Create columns for Investments Spreadsheet
  const columns = useMemo(() => {
    if (!isEmpty(fundsInvestments)) return createColumns({ fundsInvestments });

    return [];
  }, [fundsInvestments]);

  // Create parser with custom props
  const parser = useCallback(
    (props: ParserParams<CompanySummaryColumn, CompanySummaryInitialObject, RowData>) =>
      customParser({
        ...props,
      }),
    []
  );

  // Create Spreadsheet config
  const spreadsheet = useMemo(() => {
    if (!isEmpty(columns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        allowConfirmAndDeleteColumn: false,
        alwaysDisplayLegend: false,
        colTitleRow: 0, // Add header row styles
        columns,
        currencyFormatter: true,
        format: undefined,
        hasColTitle: true, // Add styles to header row (colTitleRow)
        initialObj: { fundsSecuritiesIndexes } as unknown as SpreadsheetConfig['initialObj'],
        name: COMPANY_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName,
        page: COMPANY_SUMMARY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
        parser,
        rowConfig: config,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData: { columns },
        tableTerms: COMPANY_SUMMARY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
        totalParser: undefined,
        unitsFormatter: true,
      });

      return spreadsheetConfig;
    }

    return null;
  }, [columns, config, fundsSecuritiesIndexes, parser]);

  return {
    spreadsheet,
  };
};

export default useCompanySummarySheet;
