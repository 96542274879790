/* eslint-disable no-param-reassign */
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import {
  APPROACH_TABLE_ID,
  BACKSOLVE,
  BACKSOLVE_VALUATION_TABLE_ID,
  BASKET_TABLE_ID,
  CAP_TABLE_ROW_NUMBER,
  DISCOUNT_RATE_ALIAS,
  EXIT_DATE_ALIAS,
  FUTURE_EXIT,
  FUTURE_EXIT_TABLE_ID,
  MATURITY_ALIAS,
  OPM_TABLE_ID,
  SSV,
  SSV_METHOD,
  SSV_TABLE_ID,
  TARGET_VALUE_TABLE_ID,
  VOLATILITY_ALIAS,
} from 'common/constants/allocations';
import { DISPLAY_DATE_FORMAT } from 'pages/Financials/data';
import { getLedgerKey, getValueByPropOrFallback, parseDatabaseValue, toString } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import rowConfig from '../data/rowConfig';

const numberType = { type: 'string', format: { validateFloat: true } };

const OPM_FIELDS = {
  [MATURITY_ALIAS]: numberType,
  [VOLATILITY_ALIAS]: numberType,
};

const treatValues = (alias, value) => {
  if (alias === 'scenario_method') return toString(value);
  if (alias === 'scenario_type') {
    if (value === SSV_METHOD) return SSV;
    return parseInt(value, 10);
  }
  if (alias === EXIT_DATE_ALIAS) {
    const date = value === '' ? moment() : moment(value);
    return date.format(DISPLAY_DATE_FORMAT);
  }
  return value;
};

const getLedgerCellsByTable = (ledgerCells, tableKey) => ledgerCells?.[tableKey] || [];

function parseCellValueIfPresent({
  alias,
  cells,
  key,
  tmpColumn,
  type,
  value,
  format,
  defaultValue,
  gridType,
  decimalPlaces,
}) {
  if (alias && cells[key]) {
    // eslint-disable-next-line no-param-reassign
    tmpColumn[alias] = parseDatabaseValue({
      type,
      value,
      format,
      defaultValue,
      gridType,
      decimalPlaces,
    });
  }
}

function handleScenarioValues(column, cell, cells, tableData) {
  const tmpColumn = column;
  tmpColumn.columnRef = cell.columnRef;
  // map scenario values to the column
  const scenarioValueCells = Array.from(
    new Set(
      Object.values(cells)
        .filter(({ parent }) => parent)
        .filter(({ columnLegend }) => columnLegend === column.columnLegend)
    )
  );
  // reduce the scenario values to an object, with keys set to each of the scenarioValueCells parent keys. Each of these values should be an array consisting of
  // an object with the related security's id and name, which we can find from th cell's security attribute and matching it with tableData.captable.securities
  // and the value of the cell
  const scenarioValues = scenarioValueCells.reduce((acc, { parent, security, value }) => {
    let securityObj = cells[`${cell.columnLegend}${CAP_TABLE_ROW_NUMBER}`].value.securities?.find(
      ({ name }) => name === security
    );
    if (securityObj) {
      securityObj = {
        id: securityObj.id,
        name: securityObj.name,
      };
      if (!acc[parent]) {
        acc[parent] = [];
      }
      acc[parent].push({
        security: securityObj,
        value,
      });
    }
    return acc;
  }, {});
  tmpColumn.scenario_values = scenarioValues;
  return tmpColumn;
}

function handleSpecifiedShareValues(specifiedShareValuesData, tmpColumn) {
  if (!isEmpty(specifiedShareValuesData) && tmpColumn.scenario_method === SSV) {
    tmpColumn.specified_share_values = specifiedShareValuesData;
  } else {
    // delete specified_share_values from tmpColumn
    tmpColumn.specified_share_values = null;
    delete tmpColumn.specified_share_values;
  }
}

const reverseParser = ({ cells, columns, tableData, fieldAttributes = {} }) => {
  const sortedColumns = sortBy(columns, ['order']);
  const config = rowConfig({});
  if (isEmpty(cells) || isEmpty(columns)) {
    return [];
  }

  const alphabet = alphabetGenerator([], columns.length);

  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    const cell = cells[columnLegend + 1];
    const tmpColumn = handleScenarioValues(column, cell, cells, tableData);
    const totalAggregateValue
      = column.scenario_values?.aggregate_values?.reduce((total, { value }) => total + Number(value), 0) || 0;

    tmpColumn.total_aggregate_value = totalAggregateValue;
    config.forEach(({ alias, dbType, defaultValue, gridType, format }, propertyIndex) => {
      const decimalPlaces = fieldAttributes[alias]?.decimal_places;
      const key = columnLegend + (propertyIndex + 1);
      const type = dbType || null;
      const value = treatValues(alias, cells?.[key]?.value);
      parseCellValueIfPresent({
        alias,
        cells,
        key,
        tmpColumn,
        type,
        value,
        format,
        defaultValue,
        gridType,
        decimalPlaces,
      });
    });

    const ledgerKey = getLedgerKey(columnLegend);
    const ledgerCells = cells[ledgerKey];
    const futureExitData = ledgerCells?.[FUTURE_EXIT_TABLE_ID] || {};
    const opmCells = getLedgerCellsByTable(ledgerCells, OPM_TABLE_ID);
    const basket = getLedgerCellsByTable(ledgerCells, BASKET_TABLE_ID);
    const targetRows = getLedgerCellsByTable(ledgerCells, TARGET_VALUE_TABLE_ID);
    const backsolveValuation = ledgerCells?.[BACKSOLVE_VALUATION_TABLE_ID] || {};
    const approachWeightLedger = getLedgerCellsByTable(ledgerCells, APPROACH_TABLE_ID);

    let backsolveValuationData = null;
    if (tmpColumn.scenario_type === BACKSOLVE) {
      backsolveValuationData = {
        name: 'Backsolve',
        ...backsolveValuation,
        volatility: tmpColumn.volatility,
        maturity: tmpColumn.maturity,
        allocation_method: null,
        securities_basket: {
          basket,
          target_value: targetRows[0]?.value,
        },
        cap_table: tmpColumn.cap_table.id,
        implied_equity_value: tmpColumn.equity_value,
      };
    }

    const specifiedShareValuesData = ledgerCells?.[SSV_TABLE_ID];
    const allocationsScenariosWeights = approachWeightLedger.map(
      ({ approachWeighting, approachId, scenarioId, equityValue, approachScenarioId }) => {
        const weight = Number(approachWeighting || 0).toFixed(
          getValueByPropOrFallback(fieldAttributes.weighting_probability, 'decimal_places', 3)
        );
        return {
          weight,
          valuation_approach_id: approachId,
          allocation_scenario_id: scenarioId,
          equity_value: equityValue,
          id: approachScenarioId,
        };
      }
    );
    if (tmpColumn.scenario_type === FUTURE_EXIT) {
      const discountRate = parseDatabaseValue({
        value: futureExitData.discount_rate,
        type: 'number',
        decimalPlaces: fieldAttributes[DISCOUNT_RATE_ALIAS]?.decimal_places,
      });
      const exitDate = treatValues(EXIT_DATE_ALIAS, futureExitData.exit_date);
      tmpColumn.future_exit = {
        ...futureExitData,
        [DISCOUNT_RATE_ALIAS]: discountRate,
        [EXIT_DATE_ALIAS]: exitDate,
      };
      tmpColumn.discount_rate = discountRate;
      tmpColumn.exit_date = exitDate;
    } else {
      tmpColumn.future_exit = undefined;
    }
    tmpColumn.future_exit
      = tmpColumn.scenario_type === FUTURE_EXIT
        ? {
          ...futureExitData,
          [DISCOUNT_RATE_ALIAS]: parseDatabaseValue({
            value: futureExitData.discount_rate,
            type: 'number',
            decimalPlaces: fieldAttributes[DISCOUNT_RATE_ALIAS]?.decimal_places,
          }),
          [EXIT_DATE_ALIAS]: treatValues(EXIT_DATE_ALIAS, futureExitData.exit_date),
        }
        : undefined;
    tmpColumn.backsolve_valuation = backsolveValuationData;
    handleSpecifiedShareValues(specifiedShareValuesData, tmpColumn);
    tmpColumn.allocations_scenarios_weights = allocationsScenariosWeights;

    opmCells.forEach(({ alias, value }) => {
      const field = OPM_FIELDS[alias];
      const decimalPlaces = fieldAttributes[alias]?.decimal_places;
      if (field) {
        tmpColumn[alias] = parseDatabaseValue({
          ...field,
          value,
          decimalPlaces,
        });
      }
    });
  });

  return columns;
};

export default reverseParser;
