/* eslint-disable import/no-unresolved */
import { updateNeedsActualizationState } from 'pages/Financials/utilities';
import { reverseParser as baseReverseParser } from 'utilities';
import rowConfig from '../data/rowConfig';

const reverseParser = ({ cells, columns, fieldAttributes, allowEmptyValues = true }) => {
  const localConfig = rowConfig(columns);

  const baseReverseParsedColumns = baseReverseParser({
    cells,
    columns,
    allowEmptyValues,
    rowConfig: localConfig,
    fieldAttributes,
  });

  return updateNeedsActualizationState(baseReverseParsedColumns, cells);
};
export default reverseParser;
