/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { companiesAction, fundsAction, globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { firmCompGroupsUrl, firmSummaryUrl } from 'common/config/urls';
import { CREATE_FIRM_ENABLER } from 'common/constants/features';
import { userProfileTab } from 'common/constants/paths';
import {
  FILTER_FIRM_NUMBER,
  FIRMS_MENU_SLUG,
  ITEM_HEIGHT,
  SELECT_A_FIRM,
  SELECT_FIRM,
  USER_MENU_SLUG,
} from 'common/constants/sidebar';
import { ACCOUNT_SETTINGS_TAB } from 'common/constants/user';
import { useStore } from 'common/store';
import FeatureEnabler from 'components/FeatureEnabler';
import FeaturesContext from 'context/FeaturesContext';
import UnsavedChanges from 'context/UnsavedChanges';
import { ShowUserback } from 'providers/UserbackProvider';
import { useLogOutUser } from 'services/hooks/auth';
import { getInitials } from 'utilities';
import AddFirmItem from './AddFirmItem';
import MenuSkeleton from './BottomMenuItems.skeleton';
import FirmFilter from './FirmFilter';
import { useFindPath } from '../utilities/useFindPath';

const overflowtext = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const useStyles = makeStyles(theme => ({
  avatar: props => ({
    backgroundColor: 'transparent',
    border: '2px solid white',
    fontWeight: 700,
    fontSize: '1rem',
    marginLeft: !props.open && `-${theme.spacing(1)}px`, // Centers the avatar if drawer is closed
  }),
  bottomMenuItemTextPrimary: {
    ...overflowtext,
    color: 'white',
    fontWeight: 700,
  },
  bottomMenuItemTextSecondary: {
    ...overflowtext,
  },
  secondaryLink: {
    color: 'white',
  },
  bottomMenuItemSubMenuIcon: {
    color: 'white',
  },
  menuItemsLabel: {
    fontFamily: theme.typography.secondaryFont,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  menuItem: {
    fontFamily: theme.typography.secondaryFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '30px',
  },
  menuItemIcon: {
    paddingRight: '.5em',
  },
}));

const BottomMenuItems = ({ setOpenSidebar, open, ...rest }) => {
  const classes = useStyles(rest);
  const history = useHistory();
  const [{ firmList, firmId, user, firmInfo }, dispatch] = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const logOutUser = useLogOutUser();
  const { setAction } = useContext(UnsavedChanges);
  const { features } = useContext(FeaturesContext);

  const path = useFindPath();

  useEffect(() => {
    if (!open) {
      // If the dialog is false
      if (isNull(anchorEl)) {
        // ...and if firm list it's not open (without anchorEl)
        setFilterSearch('');
      } else {
        // ...and anchorEl exist
        setAnchorEl(null);
      }
    }
  }, [open, anchorEl]);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Do not show confirmation dialog if there are pending changes but the user wants to leave
    setAction(false);
    logOutUser();
  };

  const createFirmFeatureStatus = useMemo(() => {
    if (features && CREATE_FIRM_ENABLER in features) {
      return features[CREATE_FIRM_ENABLER];
    }
  }, [features]);

  const handleFirmSelection = firm => {
    if (firm?.id && firm.slug) {
      // Navigate to firm summary
      dispatch(companiesAction.setCompanyList(null));
      dispatch(fundsAction.setFundList(null));
      history.push(firmSummaryUrl(firm.slug));
    }

    handleMenuClose();
    setOpenSidebar(false);
  };

  const handleAddFirm = () => {
    handleMenuClose();
    setOpenSidebar(false);
    dispatch(globalAction.showFirmStepper(true));
  };

  const selectedFirm = useMemo(() => {
    if (isEmpty(firmList) || !firmId) {
      return SELECT_A_FIRM;
    }

    return firmList.find(firm => firm.id === firmId);
  }, [firmList, firmId]);

  const notSelectedFirms = useMemo(() => {
    let tmpList;
    if (isEmpty(firmList) || firmList === ERROR_403) {
      tmpList = [];
    } else if (firmList.length && firmId) {
      let filteredFirm = [];
      // Get the list of firms without the selected one
      filteredFirm = firmList
        .filter(firm => firm.id !== firmId)
        .map(firm => ({
          id: firm.id,
          title: firm.name,
          slug: firm.slug,
          to: '/#',
          disabled: false,
          callback: () => handleFirmSelection(firm),
        }));
      if (filterSearch) {
        filteredFirm = filteredFirm.filter(firm => {
          const firmTitle = firm.title.toLowerCase();
          return firmTitle.includes(filterSearch);
        });
      }
      if (filterSearch && !filteredFirm.length) {
        filteredFirm.push({
          title: 'Not found',
          disabled: true,
        });
      }
      return filteredFirm;
    }

    if (typeof path === 'string' && path === firmCompGroupsUrl(0)) return [];

    return tmpList;
  }, [firmList, firmId, filterSearch, path]);

  const items = [];

  if (firmInfo?.slug) {
    items.push({
      title: 'Account Settings',
      slug: 'account-settings',
      url: () => `${userProfileTab(firmInfo.slug, ACCOUNT_SETTINGS_TAB)}`,
      callback: () => {
        handleMenuClose();
        setOpenSidebar(false);
      },
    });
  }

  if (process.env.REACT_APP_USERBACK_ACCESS_TOKEN) {
    items.push({
      title: 'Feedback',
      slug: 'feedback',
      callback: () => {
        ShowUserback(user);
        handleMenuClose();
        setOpenSidebar(false);
      },
    });
  }

  const userMenuItems = useMemo(() => items, [firmInfo]);

  const menuItems = useMemo(() => {
    if (isEmpty(selectedFirm) || isEmpty(user) || (isEmpty(userMenuItems) && firmInfo?.slug)) {
      return [];
    }

    const userSection = {
      textPrimary: user.name || user.username,
      textSecondary: (
        <Link className={classes.secondaryLink} to="#" onClick={handleLogout}>
          Log Out
        </Link>
      ),
      slug: USER_MENU_SLUG,
      avatar: <Avatar className={classes.avatar} src={user.profile?.picture_url} />,
      subItems: userMenuItems,
    };

    return selectedFirm?.slug
      ? [
        {
          textPrimary: selectedFirm?.name || SELECT_A_FIRM,
          textSecondary: selectedFirm?.slug && (
            <Link className={classes.secondaryLink} to={firmSummaryUrl(selectedFirm.slug)}>
                View Details
            </Link>
          ),
          slug: FIRMS_MENU_SLUG,
          avatar: <Avatar className={classes.avatar}>{getInitials({ names: selectedFirm.name })}</Avatar>,
          subItems: notSelectedFirms,
          subItemsLabel:
              firmList?.length > FILTER_FIRM_NUMBER ? <FirmFilter setFilterSearch={setFilterSearch} /> : SELECT_FIRM,
        },
        userSection,
      ]
      : [userSection];
  }, [selectedFirm, notSelectedFirms, user, userMenuItems]);

  const shouldDisplayMoreVert = menuItem =>
    user.is_superuser || createFirmFeatureStatus || menuItem.slug === USER_MENU_SLUG || menuItem.subItems.length > 0;

  const displayAddFirm = () => {
    if (user.is_superuser) return <AddFirmItem handleAddFirm={handleAddFirm} classes={classes} />;
    return (
      <FeatureEnabler enabler={CREATE_FIRM_ENABLER} hideComponent>
        <AddFirmItem handleAddFirm={handleAddFirm} classes={classes} />
      </FeatureEnabler>
    );
  };

  if (isUndefined(notSelectedFirms) || (isEmpty(userMenuItems) && firmInfo?.slug)) {
    return <MenuSkeleton menuItems={menuItems} />;
  }

  return (
    <List>
      {menuItems.map(menuItem => (
        <div key={menuItem.slug} id={`sidebar-bottom__menu-${menuItem.slug}`}>
          <ListItem>
            <ListItemIcon className="sidebar-bottom__menu-icon">{menuItem.avatar}</ListItemIcon>
            <ListItemText
              primary={menuItem.textPrimary}
              secondary={menuItem.textSecondary}
              primaryTypographyProps={{ className: classes.bottomMenuItemTextPrimary }}
              secondaryTypographyProps={{ className: classes.bottomMenuItemTextSecondary }}
              className={`sidebar-bottom__menu-text ${classes.bottomMenuItemText}`}
            />

            {open && shouldDisplayMoreVert(menuItem) && (
              <IconButton
                id={`${menuItem.slug}-button`}
                aria-label="more"
                aria-controls={menuItem.slug}
                aria-haspopup="true"
                component="span"
                onClick={handleMenuClick}
                className="sidebar-bottom__menu-button">
                <MoreVert className={classes.bottomMenuItemSubMenuIcon} fontSize="small" />
              </IconButton>
            )}
          </ListItem>
          {(menuItem.subItems.length > 0 || createFirmFeatureStatus) && (
            <Menu
              id={`sidebar-bottom__dropdown-${menuItem.slug}`}
              anchorEl={anchorEl}
              open={open && anchorEl ? anchorEl.getAttribute('aria-controls') === menuItem.slug : false}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT,
                },
                square: true,
              }}>
              {menuItem.subItemsLabel && menuItem.subItems.length > 0 && (
                <MenuItem className={classes.menuItemsLabel}>{menuItem.subItemsLabel}</MenuItem>
              )}
              {menuItem.subItems.map(option => (
                <div key={option.slug}>
                  {/* Take into account individual firms */}
                  {option.disabled ? (
                    <MenuItem disabled className={classes.menuItem}>
                      {option.title}
                    </MenuItem>
                  ) : (
                    <Link
                      to={!(!!option.to || !!option.url) ? '#' : option.url || firmSummaryUrl(option.slug)}
                      disabled={option.disabled}>
                      <MenuItem
                        key={option.slug}
                        onClick={option.callback}
                        className={`
                              sidebar-bottom__dropdown-item
                              sidebar-bottom__dropdown-item-${option.slug}
                              ${classes.menuItem}
                            `}>
                        {option.icon} {option.title}
                      </MenuItem>
                    </Link>
                  )}
                </div>
              ))}
              {menuItem.slug === FIRMS_MENU_SLUG && displayAddFirm()}
            </Menu>
          )}
        </div>
      ))}
    </List>
  );
};

BottomMenuItems.propTypes = {
  setOpenSidebar: PropTypes.func,
  open: PropTypes.bool,
};

export default BottomMenuItems;
