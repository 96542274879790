/**
 * Widgets Component
 *
 * @component
 * @description This component serves as a container for displaying document references and notes widgets based on the specified page origin.
 *
 * @param {string} pageOrigin - The origin or type of the current page.
 * @param {Object} documentReferencesProps - Properties for the DocumentReferences component.
 * @param {Object} notesProps - Properties for the Notes component.
 * @returns {JSX.Element|null} - A JSX element representing the Widgets component or null if no content should be displayed.
 */
import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { isEmpty } from 'lodash';
import DocumentReferences from 'components/Documents/DocumentReferences';
import { Notes } from 'components/Notes';
import { IWidget } from './types';

const Widgets: FC<IWidget> = props => {
  const { documentReferencesProps, notesProps } = props;
  // If documentReferencesProps and notesProps doesn't exist or are empty
  if (isEmpty(documentReferencesProps) && !notesProps && isEmpty(notesProps)) return null;
  return (
    <div className="widgets-container">
      <Grid container spacing={3} style={{ paddingBottom: 40 }}>
        {documentReferencesProps && (
          <Grid item xs={12} sm={12} md={6}>
            <Paper className="widget-content">
              <DocumentReferences {...documentReferencesProps} />
            </Paper>
          </Grid>
        )}
        {notesProps && (
          <Grid item xs={12} sm={12} md={6}>
            <Paper className="widget-content">
              <Notes {...notesProps} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Widgets;
