import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'validate.js';
import { captableAction } from 'common/actions';
import { CAP_TABLE_REFERENCE_TITLE, CAP_TABLE_REFERENCE_TYPE } from 'common/constants/documents';
import { CAP_TABLE_PAGE_KEY } from 'common/constants/notes';
import { useStore } from 'common/store';
import { Widgets } from 'components/Widgets';
import { useDocuments } from 'services/hooks';
import { useNotes } from 'services/hooks/notes';
import { getObjectValue, gridShortDate } from 'utilities';
import CaptableTable from './components/CaptableTable';

const CapTable = props => {
  const { selectedMeasurementDate, saveAsNewProforma, capTableId } = props;
  const { filesToSave, setFilesToSave, addReferenceForExistingDocuments } = useDocuments();
  const { notes, setNotes, notesHasChanged, saveNotes, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const [isDisplayingRowNumbers, setIsDisplayingRowNumbers] = useState(false);
  const [isDisabled, setIsDisabled] = useState();
  const [{ captableInfo }] = useStore();
  const { id: currentCapTableId } = getObjectValue(captableInfo);

  const measurementDate = useMemo(
    () => (selectedMeasurementDate ? gridShortDate(selectedMeasurementDate.date) : undefined),
    [selectedMeasurementDate]
  );

  useEffect(() => {
    if (currentCapTableId) {
      (async () => {
        try {
          const response = await captableAction.verifyIfHasFinalAllocation(currentCapTableId);
          setIsDisabled(!isEmpty(response));
        } catch (error) {
          setIsDisabled(false);
          throw new Error(error);
        }
      })();
    }
  }, [currentCapTableId]);

  return (
    <>
      <CaptableTable
        measurementDate={measurementDate}
        selectedMeasurementDate={selectedMeasurementDate}
        saveAsNewProforma={saveAsNewProforma}
        addReferenceForExistingDocuments={addReferenceForExistingDocuments}
        filesToSave={filesToSave}
        setIsDisplayingRowNumbers={setIsDisplayingRowNumbers}
        setDocumentDisabledStatus={setIsDisabled}
        notes={notes}
        saveNotes={saveNotes}
        notesHasChanged={notesHasChanged}
      />
      <Widgets
        documentReferencesProps={{
          selectedMeasurementDate,
          referenceType: CAP_TABLE_REFERENCE_TYPE,
          currentPage: CAP_TABLE_REFERENCE_TITLE,
          referencedFeatureId: capTableId,
          filesToSave,
          setFilesToSave,
          isDisplayingRowNumbers,
          isDisabled,
        }}
        notesProps={{
          pageType: CAP_TABLE_REFERENCE_TYPE,
          pageTypeKey: CAP_TABLE_PAGE_KEY,
          pageTypeId: capTableId,
          notes,
          setNotes,
          onAddNote,
          onUpdateNotes,
          onDeleteNote,
          isDisabled,
        }}
      />
    </>
  );
};

CapTable.propTypes = {
  selectedMeasurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    id: PropTypes.number,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  saveAsNewProforma: PropTypes.func.isRequired,
  capTableId: PropTypes.number,
};

export default CapTable;
