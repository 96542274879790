import React, { FC, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { PROCESS_MANAGEMENT_ENABLER } from 'common/constants/features';
import {
  FUNDS_ADD_INVESTMENTS,
  FUNDS_FORBIDDEN_FUND_SUMMARY,
  FUNDS_GET_FUND_SUMMARY_ACCESS,
  FUNDS_NO_INVESTMENTS,
  FUNDS_SUMMARY_TITLE,
} from 'common/constants/funds';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { UseFormatValues } from 'common/types/format';
import { UseStoreValues } from 'common/types/store';
import { CustomScalarSpreadsheet, MessageBox } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import useCheckFeatures from 'components/FeatureEnabler/hooks';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { FUND_SUMMARY_ONLY_INCLUDES_PUBLISHED_VALUATIONS } from 'pages/Funds/common/constants/fundSummary';
import { InformationMessage, SectionHeading } from 'pages/Funds/components';
import { useFundSummarySheet } from 'pages/Funds/hooks';
import { FundsParams } from 'pages/Funds/types';
import { useGetTasksProgressByFirm } from 'services/hooks/firms';
import { useGetFundSummaryByMD } from 'services/hooks/funds';
import { getArrayValue, getBooleanValue, getObjectValue } from 'utilities';
import { FundSummaryProps } from './types';

const FundSummary: FC<FundSummaryProps> = props => {
  const { fund, includeTitle = true, measurementDate } = props;

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { firmInfo, firmList, isRestrictedUser } = storeValue;
  const { show_process_management_column: showProcessManagementColumn = false } = getObjectValue(firmInfo);

  const { firmSlugParam } = useParams<FundsParams>();

  const [features, checkFeature] = useCheckFeatures();

  const { data: fundSummary, isFetching, isLoading } = useGetFundSummaryByMD({ fund, measurementDate });

  const activeFirm = useMemo(() => {
    if (!isEmpty(firmInfo) && isRestrictedUser) {
      return firmInfo;
    }

    if (firmSlugParam && !isEmpty(firmList)) {
      const currentFirm = firmList?.find(firm => firm.slug === firmSlugParam) ?? null;

      return currentFirm;
    }

    return null;
  }, [firmInfo, firmList, firmSlugParam, isRestrictedUser]);

  const isProcessManagementEnabled = useMemo(
    () => (!isEmpty(features) ? checkFeature(PROCESS_MANAGEMENT_ENABLER) && showProcessManagementColumn : false),
    [checkFeature, features, showProcessManagementColumn]
  );

  const {
    data: tasksProgress,
    isFetching: isFetchingTasksProgress,
    isLoading: isLoadingTasksProgress,
  } = useGetTasksProgressByFirm({ firm: activeFirm, shouldQueryAutomatically: isProcessManagementEnabled });

  const { spreadsheet: fundSummarySheet } = useFundSummarySheet({
    fundSummary,
    isProcessManagementEnabled,
    measurementDate,
    tasksProgress,
  });

  const spreadsheets = useMemo(() => (fundSummarySheet?.yLength ? [fundSummarySheet] : []), [fundSummarySheet]);

  const { onChange, workbook } = useWorkbook(spreadsheets);

  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: fund?.currency?.toString(),
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  // Create fundSummarySheet props
  const fundSummarySheetProps = useMemo(() => {
    if (fundSummarySheet)
      return {
        ...fundSummarySheet,
        data: getArrayValue(fundSummarySheet?.data),
        format: {
          ...format,
          currency: {
            ...format.currency,
            measurementDateId: measurementDate?.id,
          },
        },
        formatDispatch,
        onChange,
        sheet: fundSummarySheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [format, formatDispatch, fundSummarySheet, onChange, workbook, measurementDate]);

  // If fundSummary status is 403
  const forbiddenAccess = useMemo(() => isEqual(fundSummary?.status?.toString(), ERROR_403), [fundSummary]);

  // If at least one Company is not published, display the message
  const shouldDisplayOnlyPublishedMessage = useMemo(
    () => getBooleanValue(fundSummary?.companies?.some(company => !company?.is_published)),
    [fundSummary]
  );

  // Show Loading Progress
  useEffect(() => {
    dispatch?.(globalAction.showLoadingProgress(isLoading || (isProcessManagementEnabled && isLoadingTasksProgress)));
  }, [dispatch, isLoading, isLoadingTasksProgress, isProcessManagementEnabled]);

  // Loading Grid Skeleton while fetching data
  if (isLoading || (isProcessManagementEnabled && isLoadingTasksProgress))
    return <GridSkeleton maxColumns={10} maxRows={4} />;

  // Forbidden access to the Fund Summary page
  if (forbiddenAccess)
    return (
      <MessageBox fullWidth={false} tagline={FUNDS_GET_FUND_SUMMARY_ACCESS} title={FUNDS_FORBIDDEN_FUND_SUMMARY} />
    );

  // No published Investments on this Fund
  if (isEmpty(fundSummary?.companies))
    return <MessageBox fullWidth={false} tagline={FUNDS_ADD_INVESTMENTS} title={FUNDS_NO_INVESTMENTS} />;

  return (
    <Grid>
      {fundSummarySheetProps && fundSummarySheet && !isEmpty(fundSummary) && (
        <>
          {includeTitle && <SectionHeading title={FUNDS_SUMMARY_TITLE} />}

          <CustomScalarSpreadsheet
            {...fundSummarySheetProps}
            isLoading={isFetching || (isProcessManagementEnabled && isFetchingTasksProgress)}
          />

          <InformationMessage
            displayMessage={shouldDisplayOnlyPublishedMessage}
            message={FUND_SUMMARY_ONLY_INCLUDES_PUBLISHED_VALUATIONS}
          />
        </>
      )}
    </Grid>
  );
};

export default FundSummary;
