/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { isEmpty, mean } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import * as CTC from 'common/constants/cap-table';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import * as FOC from 'common/constants/fund-ownership';
import * as validationMessages from 'common/constants/messages/validations';
import { COMMON_STOCK, CONVERTIBLE_NOTES, OPTION, WARRANT } from 'common/constants/securityTypes';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { LedgerDialog } from 'components/Dialogs';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import FundOwnershipContext from 'context/FundOwnershipContext';
import useFundOwnershipFundCell from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useFundOwnershipFundCell';
import { roundToN, toString } from 'utilities';
import slashFormat from 'utilities/slashFormat';
import { Outstanding, PurchasedTable, SoldTable } from './components';
import ConvertedTable from './components/converted-table/ConvertedTable';
import { AMOUNT_ALIAS, PROCEEDS_ALIAS, PURCHASE_DATE_ALIAS } from './constants';
import calculateConvertibleNoteOutstandingFromCells from './utilities/calculateConvertibleNoteOutstandingFromCells';
import calculateRegularOutstandingFromCells from './utilities/calculateRegularOutstandingFromCells';
import dividendsAtDate from './utilities/dividendsAtDate';
import { commonReverseParser } from './utilities/reverseParsers';
import { LOAN_VALUE } from '../../../cap-table/components/convertible-notes-ledger/config/constants';
import isModeledAfterNote from '../../utilities/isModeledAfterNote';

const rowNumbers = {
  investmentDate: FOC.INVESTMENT_DATE_ROW_NUMBER,
  investmentCapital: FOC.INVESTMENT_CAPITAL_ROW_NUMBER,
  loanValue: FOC.LOAN_VALUE_ROW_NUMBER,
  shares: FOC.SHARES_ROW_NUMBER,
  proceedsFromSoldShares: FOC.PROCEEDS_FROM_SOLD_SHARES_ROW_NUMBER,
  liquidationPreferencePlus: FOC.LIQUIDATION_PREFERENCE_PLUS_ROW_NUMBER,
};

const isSoldDataValid = data =>
  Array.isArray(data)
  && data.length > 0
  && data.every(item => typeof item === 'object' && (AMOUNT_ALIAS in item || PROCEEDS_ALIAS in item));

const SharesLedger = ({ cell, closeDialog, investmentDates }) => {
  const [{ captableInfo }] = useStore();

  const { cells, onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  // state for cells of tables
  const [purchasedCells, setPurchasedCells] = useState();
  const [soldCells, setSoldCells] = useState();
  const [convertedCells, setConvertedCells] = useState();
  const {
    isDisabled,
    validSecurities,
    fundList,
    selectedMeasurementDate,
    measurementDate,
    tableData: {
      fundOwnership: { fund_ownership_detail },
    },
  } = useContext(FundOwnershipContext);
  // if its true all tables will start to do his own validation
  const [doFullValidation, setDoFullValidation] = useState(false);

  const dataSourceKey = useMemo(() => `${cell.columnLegend + FOC.SHARES_LEDGER_ROW_NUMBER}`, [cell.columnLegend]);
  const relatedSecurityId = useMemo(() => {
    if (cell?.isCustomSecurity) return null;
    if (cell?.relatedSecurity) {
      return Number(cell.relatedSecurity);
    }
    return Number(cells[cell.columnLegend + FOC.SECURITY_ROW_NUMBER]?.value);
  }, [cell?.relatedSecurity, cells, dataSourceKey]);

  const { securities } = captableInfo;
  const currentSecurity = securities.find(sec => sec.id === relatedSecurityId);
  const isWarrantOrOption = [WARRANT, OPTION].includes(currentSecurity?.security_type);

  const dividendsAtDateMemo = useCallback(
    investmentDate =>
      dividendsAtDate({
        hasCumulativeDividends: !!currentSecurity?.has_dividends,
        hasCompounding: !!currentSecurity?.has_compounding,
        investmentDate,
        security: currentSecurity,
        selectedMeasurementDate: measurementDate,
      }),
    [currentSecurity, measurementDate]
  );

  const startingAcquisitions = useMemo(
    () =>
      cell?.acquisitions.map(acq => {
        const tempRef = uuid();
        const dividends = dividendsAtDateMemo(acq.purchase_date);
        const sales = acq.sales.map(sale => ({ ...sale, acquisition_ref: tempRef, sale_ref: uuid() }));
        const conversions = acq.conversions.map(conversion => ({
          ...conversion,
          acquisition_ref: tempRef,
          conversion_ref: uuid(),
        }));
        return {
          ...acq,
          acquisition_ref: tempRef,
          sales,
          cash_distributions: acq.cash_distributions,
          conversions,
          deleted_sales: [],
          deleted_conversions: [],
          dividends_per_share: dividends,
        };
      }) || [],
    [cell]
  );

  const [acquisitionsData, setAcquisitionsData] = useState({
    acquisitions: startingAcquisitions,
  });
  const [deletedAcquisitions, setDeletedAcquisitions] = useState([]);

  // this will be used to synchronize input changes with the acquisitionsData state
  const [format] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    units: `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
  });

  // if its true means that at least one table not finish his validation process yet
  const [isValidatingTables, setIsValidatingTables] = useState(false);

  const fund = useFundOwnershipFundCell({ cells, cell, fundList });

  // function to update all tables validation status, when all finish is own validation this
  // function set isValidatingTables to false
  const updateValidationStatus = validationStatus => {
    setIsValidatingTables(isValidatingTables || validationStatus);
  };

  // validation result per table
  const [isValidPurchasedTable, setIsValidPurchasedTable] = useState(true);
  const [isValidConvertedTable, setIsValidConvertedTable] = useState(true);
  const [isValidSoldTable, setIsValidSoldTable] = useState(true);
  const [isValidOutstandingTable, setIsValidOutstandingTable] = useState(true);

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const { currency } = format;

  const isDifferentCurrency = useMemo(() => fund?.currency && fund.currency !== format.currency.code, [fund, format]);

  const modeledAfterConvertibleNote = useMemo(() => {
    if (cell?.modeledAfterConvertibleNote) {
      return cell.modeledAfterConvertibleNote;
    }
    const referencedSecurity = validSecurities.find(sec => toString(sec.id) === toString(relatedSecurityId)) || {};
    return isModeledAfterNote({ securityInfo: referencedSecurity });
  }, [cell.modeledAfterConvertibleNote, validSecurities, relatedSecurityId]);

  const convertibleNote = useMemo(() => {
    const referencedSecurity = validSecurities?.find(sec => toString(sec.id) === toString(relatedSecurityId)) || {};
    const relatedNoteSecurity = captableInfo?.securities?.find(
      sec => sec.security_ref === referencedSecurity.security_group_ref
    );

    return relatedNoteSecurity?.convertible_notes?.find(
      note => note.security_group_ref === referencedSecurity.security_ref
    );
  }, [modeledAfterConvertibleNote, captableInfo, validSecurities]);

  const loanNote = useMemo(() => {
    if (convertibleNote?.model_as_equity === LOAN_VALUE) {
      return convertibleNote;
    }
    return null;
  }, [convertibleNote]);

  const loanValueRatio = useMemo(() => {
    if (loanNote) {
      return loanNote.loan_value / loanNote.note_principle_amount;
    }
    return 1;
  }, [loanNote]);

  const outstandingCalculations = useMemo(() => {
    if (modeledAfterConvertibleNote) {
      return calculateConvertibleNoteOutstandingFromCells({
        purchasedCells,
        soldCells,
        convertedCells,
        loanNote,
        loanValueRatio,
      });
    }
    return calculateRegularOutstandingFromCells({ purchasedCells, soldCells });
  }, [purchasedCells, soldCells, convertedCells, modeledAfterConvertibleNote, loanNote, loanValueRatio]);

  const outstandingShares = outstandingCalculations.sharesOutstanding;
  const outstandingCostBasis = outstandingCalculations.costBasisOutstanding;
  const outstandingAmount = outstandingCalculations.amountOutstanding;
  const { outstandingLoanValue, totalInvested } = outstandingCalculations;

  const reverseParsedAcquisitions = useMemo(() => {
    if (acquisitionsData.acquisitions && !isEmpty(purchasedCells)) {
      return commonReverseParser({
        purchasedCells,
        soldCells,
        convertedCells,
        dividendsCalculator: dividendsAtDateMemo,
      });
    }
  }, [purchasedCells, acquisitionsData, soldCells, convertedCells, dividendsAtDateMemo]);

  const updateFields = () => {
    const cellsChanged = [];
    const tmpState = { ...cells };
    const conversions = reverseParsedAcquisitions.map(acq => acq.conversions ?? []).flat();
    const sales = reverseParsedAcquisitions.map(acq => acq.sales).flat();
    const referencedSecurity = validSecurities.find(sec => toString(sec.id) === toString(relatedSecurityId)) || {};

    let balanceSharesForNotes = 0;
    // calculate share balance in ledger for security modeled after note
    if (conversions.length && ![COMMON_STOCK, CONVERTIBLE_NOTES].includes(referencedSecurity.security_type)) {
      // get the outstanding amount and the note principle amount and calculate a ratio
      // multiply that ratio by the expected shares from the convertible note
      // if the outstanding amount is greater than or equal to the note principle amount, just use the expected shares
      const sharesRatio = outstandingAmount / convertibleNote.note_principle_amount;
      balanceSharesForNotes
        = sharesRatio >= 1 ? convertibleNote.expected_shares : sharesRatio * convertibleNote.expected_shares;
    }
    // Update invested date
    const investmentDateKey = cell.columnLegend + rowNumbers.investmentDate;
    const acquisitionsRef = reverseParsedAcquisitions;
    const investmentDate = acquisitionsRef?.length ? slashFormat(acquisitionsRef[0][PURCHASE_DATE_ALIAS]) : 'Not Set';
    cellsChanged.push({
      cell: tmpState[investmentDateKey],
      value: investmentDate,
    });

    // Update invested capital
    const investmentCapitalKey = cell.columnLegend + rowNumbers.investmentCapital;
    cellsChanged.push({
      cell: tmpState[investmentCapitalKey],
      value: roundToN(totalInvested).toString(),
    });

    // Update Loan Value
    const loanValueKey = cell.columnLegend + rowNumbers.loanValue;
    cellsChanged.push({
      cell: tmpState[loanValueKey],
      value: loanNote ? roundToN(outstandingLoanValue).toString() : '0',
    });

    // Update shares
    const sharesKey = cell.columnLegend + rowNumbers.shares;
    cellsChanged.push({
      cell: tmpState[sharesKey],
      value: modeledAfterConvertibleNote ? balanceSharesForNotes.toString() : outstandingShares.toString(),
    });

    // Update Liquidation Preference Plus
    const liquidationPreferencePlusKey = cell.columnLegend + rowNumbers.liquidationPreferencePlus;
    cellsChanged.push({
      cell: tmpState[liquidationPreferencePlusKey],
      value: `=${outstandingShares.toString()}*CAPTABLE("${CTC.ISSUE_PRICE_TITLE}", ${cell.columnLegend}${
        FOC.SECURITY_ROW_NUMBER
      })*CAPTABLE("${CTC.LIQUIDATION_PREFERENCE_TITLE}", ${cell.columnLegend}${FOC.SECURITY_ROW_NUMBER})+${
        outstandingValuesForDividends.cumulative_dividends
      }`,
    });

    // Update proceeds from sold shares
    const proceedsFromSoldSharesKey = cell.columnLegend + rowNumbers.proceedsFromSoldShares;
    let proceedsFromSoldShares = 0;

    if (isSoldDataValid(sales)) {
      proceedsFromSoldShares = sales
        .map(item => Number(modeledAfterConvertibleNote && !loanNote ? item.amount : item.proceeds))
        .reduce((prev, next) => prev + next, 0);
    }
    cellsChanged.push({
      cell: tmpState[proceedsFromSoldSharesKey],
      value: proceedsFromSoldShares.toString(),
    });
    // Send cell change method
    onCellsChanged(cellsChanged);
  };

  const updateAcquisitionInColumnCells = useCallback(() => {
    // recover ids for objects
    const currentFod = fund_ownership_detail.find(({ columnRef }) => columnRef === cell.columnRef);
    // need to make sure the children point to the right acquisitions
    const allSales = reverseParsedAcquisitions.map(acq => acq.sales).flat();
    const allConversions = reverseParsedAcquisitions.map(acq => acq.conversions ?? []).flat();

    const updatedAcquisitions = reverseParsedAcquisitions.map(acquisition => ({
      ...acquisition,
      id: startingAcquisitions.find(acq => acq.acquisition_ref === acquisition.acquisition_ref)?.id ?? 0,
      sales: allSales.filter(sale => sale.acquisition_ref === acquisition.acquisition_ref),
      fund_ownership_detail: currentFod?.id ?? undefined,
      cash_distributions: acquisition.cash_distributions ?? [],
      conversions: allConversions.filter(conversion => conversion.acquisition_ref === acquisition.acquisition_ref),
    }));
    // this should update the acquisitions information for the whole column
    const { columnLegend: currentColumnLegend, sheet } = cell;

    Object.values(sheet.cells)
      .filter(({ columnLegend }) => currentColumnLegend === columnLegend)
      .forEach(cell => {
        cell.acquisitions = updatedAcquisitions;
        cell.deleted_acquisitions = deletedAcquisitions;
      });
  }, [cell, reverseParsedAcquisitions, fund_ownership_detail, startingAcquisitions, deletedAcquisitions]);

  const save = () => {
    // In convertible notes, the balance owned should be greater than 0, not equal to 0
    const isOutstandingAmountValid = convertibleNote ? outstandingAmount > 0 : outstandingAmount >= 0;

    if (
      isValidPurchasedTable
      && isValidSoldTable
      && isValidOutstandingTable
      && outstandingShares >= 0
      && outstandingCostBasis >= 0
      && isOutstandingAmountValid
    ) {
      const tmpParsedColumns = { ...cells };
      // if modeledAfterConvertibleNote, should change how outstanding is represented.
      let outstanding = {
        shares: outstandingShares,
        cost_basis: outstandingCostBasis,
      };

      if (currentSecurity?.has_dividends) {
        outstanding = {
          ...outstanding,
          cumulative_dividends: outstandingValuesForDividends.cumulative_dividends,
          total_shares_outstanding: outstandingValuesForDividends.total_shares_outstanding,
        };
      } else if (modeledAfterConvertibleNote) {
        outstanding = { amount: outstandingAmount };
      }
      const cellKey = cell.linkedCells.values().next().value.key;
      tmpParsedColumns[cellKey].value = outstanding;
      updateAcquisitionInColumnCells();
      updateFields();
      closeDialog();
    } else {
      setIsAlertVisible(true);
    }
  };

  // if all tables has been notified to start validation process and also they already
  // finish, then we have to save
  useEffect(() => {
    if (!isValidatingTables && doFullValidation) {
      save();
      // stop notify to do validation
      setDoFullValidation(false);
    }
  }, [isValidatingTables]);

  const outstandingValuesForDividends = useMemo(() => {
    if (currentSecurity?.has_dividends) {
      const purchased = reverseParsedAcquisitions;
      const dividends = purchased?.map(p => Number(p.dividends_per_share));
      const meanDividends = dividends?.length ? mean(dividends) : 0;
      const cumulative_dividends = meanDividends * outstandingShares;
      const total_shares_outstanding
        = (currentSecurity.dividend_payment_type * (meanDividends * outstandingShares)) / currentSecurity.issue_price
        + outstandingShares;
      return { cumulative_dividends, total_shares_outstanding };
    }
    return { cumulative_dividends: 0, total_shares_outstanding: 0 };
  }, [reverseParsedAcquisitions, currentSecurity, outstandingShares]);

  const sharedProps = {
    cell,
    isAlertVisible,
    setIsAlertVisible,
    doFullValidation,
    updateValidationStatus,
    isDisabled,
    modeledAfterConvertibleNote,
    dataSourceKey,
    isDifferentCurrency,
    selectedMeasurementDate,
    fundCurrency: fund?.currency,
    acquisitionsData,
    setAcquisitionsData,
    reverseParsedAcquisitions,
    setDeletedAcquisitions,
  };

  const errorMessage = useMemo(() => {
    if (
      isValidPurchasedTable
      && isValidSoldTable
      && isValidConvertedTable
      && !modeledAfterConvertibleNote
      && (outstandingShares >= 0 || outstandingCostBasis >= 0)
    ) {
      return validationMessages.NEGATIVE_OUTSTANDINGS;
    }

    if (outstandingAmount <= 0) {
      return validationMessages.REFERENCED_VALUE_MUST_BE_POSITIVE('Balance Owned');
    }

    return validationMessages.FIX_BEFORE_CONTINUE;
  }, [
    outstandingShares,
    isValidSoldTable,
    isValidPurchasedTable,
    isValidConvertedTable,
    isValidOutstandingTable,
    modeledAfterConvertibleNote,
  ]);

  const titleLedger = useMemo(() => {
    if (modeledAfterConvertibleNote) {
      if (loanNote) {
        return 'Note - Investment Ledger';
      }
      return 'Convertible Notes - Investment Ledger';
    }
    if (cell?.isCustomSecurity) {
      return 'Custom Security Ownership Ledger';
    }
    return 'Shares Ledger';
  }, [cell, loanNote]);

  const disabledStatus = useMemo(
    () => isDisabled || acquisitionsData.acquisitions.length === 0,
    [isDisabled, acquisitionsData]
  );

  return (
    <LedgerDialog
      id="shares-ledger"
      title={titleLedger}
      onSave={() => setDoFullValidation(true)}
      onClose={closeDialog}
      showDeleteColumn
      disabled={disabledStatus}>
      <Collapse in={isAlertVisible}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsAlertVisible(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {errorMessage}
        </Alert>
        <br />
      </Collapse>
      <PurchasedTable
        investmentDates={investmentDates}
        setIsValid={setIsValidPurchasedTable}
        currency={currency}
        format={format}
        {...sharedProps}
        setPurchasedCells={setPurchasedCells}
        purchasedCells={purchasedCells}
        dividendsCalculator={dividendsAtDateMemo}
        isWarrantOrOption={isWarrantOrOption}
      />
      <br />
      <SoldTable
        setIsValid={setIsValidSoldTable}
        currency={currency}
        format={format}
        isLoanNote={!!loanNote}
        {...sharedProps}
        soldCells={soldCells}
        setSoldCells={setSoldCells}
      />
      <br />
      {modeledAfterConvertibleNote && !loanNote && (
        <>
          <ConvertedTable
            setIsValid={setIsValidConvertedTable}
            currency={currency}
            {...sharedProps}
            convertedCells={convertedCells}
            setConvertedCells={setConvertedCells}
          />
          <br />
        </>
      )}
      <Outstanding
        setIsValid={setIsValidOutstandingTable}
        currency={currency}
        {...sharedProps}
        outstandingShares={outstandingShares}
        outstandingCostBasis={outstandingCostBasis}
        outstandingAmount={outstandingAmount}
        relatedSecurity={relatedSecurityId}
        format={format}
        outstandingLoanValue={outstandingLoanValue}
        isLoanNote={!!loanNote}
        purchasedCells={purchasedCells}
        soldCells={soldCells}
        outstandingValuesForDividends={outstandingValuesForDividends}
      />
    </LedgerDialog>
  );
};

SharesLedger.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
  relatedSecurity: undefined,
};

SharesLedger.propTypes = {
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
  investmentDates: PropTypes.any,
  relatedSecurity: PropTypes.any,
};

export default React.memo(SharesLedger);
