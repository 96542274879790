import { range } from 'mathjs';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { generateColumnKey, getArrayValue, getStringValue } from 'utilities';
import { ComparisonCompaniesParams, RowConfigCalibrationParams } from './types';
import { oneDecimalPercentFormatValidateFloatTrue } from '../../../../../../../common/formats/formats';
import { PERCENTILE_RANKING_TITLE } from '../../../../guidelinePublicCompanies/constants';
import { getMedianExpression } from '../../../../guidelinePublicCompanies/PerformanceMetrics/config/utils/utils';

const comparisonCompanies = (params: ComparisonCompaniesParams): Rows => {
  const { gpcComparison } = params;

  return getArrayValue(
    gpcComparison
      ?.filter(comparison => comparison.name !== '0')
      .map(
        comparison =>
          ({
            alias: generateColumnKey({
              name: getStringValue(comparison.cap_iq_id ?? comparison?.name ?? comparison?.company_name ?? ''),
              prefix: PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
            }),
            gridType: 'string',
            ignoreRowCopy: true,
            readOnly: true,
            className: 'row-label-indented',
            value: getStringValue(comparison?.name || comparison?.company_name),
            isClassNameRequired: true,
          } as Row)
      )
  );
};

const rowConfigCalibrationPerformance = (params: RowConfigCalibrationParams): Rows => {
  const { valuationsApproachGpc, company } = params;
  const { gpcComparisonByDate } = params;
  let gpcComparison = getArrayValue(valuationsApproachGpc?.gpc_comparison);

  if (gpcComparisonByDate !== undefined) {
    gpcComparison = gpcComparisonByDate;
  }

  const basedIndex = 1;
  const rangeStart = 1;
  const accountForTitleAndSubtitle = 2;
  const gpcRange = range(rangeStart, gpcComparison.length + basedIndex)
    .map(rowNumber => `@${rowNumber + accountForTitleAndSubtitle}`) // +2 to account for the title and subtitle rows
    .toString()
    .replace(/"/g, '');

  return [
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
      className: 'table-header full-width-label',
      gridType: 'string',
      ignoreRowCopy: true,
      isParentWithDivider: false,
      readOnly: true,
      rowSpan: 1,
      columnSpan: 2,
      value: SHEET_TITLES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_HEADERS[0].value,
      isTitleOrHeader: true,
    } as Row,
    ...(gpcComparison.length > 0 ? comparisonCompanies({ gpcComparison }) : []),
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
      displayBold: true,
      className: 'bold-row',
      format: oneDecimalPercentFormatValidateFloatTrue,
      gridType: 'percentage',
      ignoreRowCopy: true,
      readOnly: true,
      value: company?.name,
      expr: getMedianExpression(gpcRange),
    } as Row,
    {
      alias: SHEET_ALIASES_CONSTANTS.PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS,
      hidden: false,
      gridType: 'percentage',
      format: oneDecimalPercentFormatValidateFloatTrue,
      value: PERCENTILE_RANKING_TITLE,
      forceComponent: true,
      readOnly: true,
      valueViewer: null,
      dataEditor: null,
      isVolatility: null,
      dropdown: false,
      useScalarSpreadsheetCell: false,
      options: null,
    } as Row,
  ];
};

export default rowConfigCalibrationPerformance;
