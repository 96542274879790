/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import useExchangeRateMap from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useExchangeRateMap';
import { objToArray } from 'utilities';
import { colConfig } from './colConfig';
import updateCostCellsIfDifferent from '../../../../utilities/updateCostCellsIfDifferent';
import conditions from '../../conditions';
import { AMOUNT_ALIAS, CONVERSION_DATE_ALIAS } from '../../constants';

const emptyRow = { purchase_date: null, conversion_date: null, shares: null, amount: null, acquisition_ref: null };

const ConvertedTable = ({
  setIsAlertVisible,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  isDisabled,
  isDifferentCurrency,
  fundCurrency,
  selectedMeasurementDate,
  convertedCells,
  setConvertedCells,
  acquisitionsData,
  setAcquisitionsData,
  reverseParsedAcquisitions,
}) => {
  const { format } = useContext(FeaturedSpreadsheetContext);
  const [rows, setRows] = useState();
  // becomes populated with array of objs with keys date, shares, amount

  const { exchangeRateMap } = useExchangeRateMap({
    fundCurrency,
    format,
    shares: rows,
    selectedMeasurementDate,
    isDifferentCurrency,
    cellKeys: [AMOUNT_ALIAS],
    dateProp: CONVERSION_DATE_ALIAS,
  });

  const disabledCondition = useMemo(
    () => isDisabled || isEmpty(acquisitionsData?.acquisitions),
    [isDisabled, acquisitionsData]
  );

  const addRow = useCallback(() => {
    const newestAcquisition = reverseParsedAcquisitions.reduce((acc, curr) =>
      acc.purchase_date > curr.purchase_date ? acc : curr
    );
    const newRow = {
      ...emptyRow,
      acquisition_ref: newestAcquisition.acquisition_ref,
      purchase_date: newestAcquisition.purchase_date,
      conversion_ref: uuid(),
    };
    newestAcquisition.conversions.push(newRow);
    const insertionIndex = acquisitionsData?.acquisitions.findIndex(
      acq => acq.acquisition_ref === newestAcquisition.acquisition_ref
    );
    const updatedAcquisitions = [...reverseParsedAcquisitions];
    updatedAcquisitions[insertionIndex] = newestAcquisition;
    setAcquisitionsData({ ...acquisitionsData, acquisitions: updatedAcquisitions });
  }, [reverseParsedAcquisitions, acquisitionsData, setAcquisitionsData]);

  const deleteRow = useCallback(
    rowIndex => {
      if (!rows) return;
      // find the acquisition the share conversion belongs to
      const updatedAcquisitionsData = { ...acquisitionsData };
      const acquisitionRef = rows[rowIndex].acquisition_ref;
      const acquisition = updatedAcquisitionsData?.acquisitions.find(acq => acq.acquisition_ref === acquisitionRef);
      // find the conversion in the acquisition and then remove it
      const conversionIndex = acquisition.conversions.findIndex(
        conv => conv.sale_ref === rows[rowIndex].conversion_ref
      );
      if (acquisition.conversions[conversionIndex]?.id) {
        acquisition.deleted_conversions.push(acquisition.conversions[conversionIndex].id);
      }
      acquisition.conversions.splice(conversionIndex, 1);
      setAcquisitionsData(updatedAcquisitionsData);
    },
    [acquisitionsData, rows, setAcquisitionsData]
  );

  const config = useMemo(() => colConfig(acquisitionsData.acquisitions), [acquisitionsData]);

  // Set Rows
  useEffect(() => {
    if (isEmpty(acquisitionsData?.acquisitions)) {
      setRows([]);
    } else {
      const conversions = acquisitionsData.acquisitions.map(acq => acq.conversions).flat();
      if (!isEmpty(conversions)) {
        const rowsToSet = conversions.map(conversion => ({
          ...conversion,
          purchase_date: acquisitionsData?.acquisitions.find(acq => acq.acquisition_ref === conversion.acquisition_ref)
            ?.purchase_date,
          id: conversion.id ?? 0,
        }));
        setRows(rowsToSet);
      }
    }
  }, [acquisitionsData.acquisitions]);

  // Set cells
  useEffect(() => {
    if (Array.isArray(rows)) {
      let parsedCells = cellsParser(rows, config);
      parsedCells = Object.entries(parsedCells).reduce((acc, [key, cell]) => {
        const updatedCell = updateCostCellsIfDifferent({
          currencyAdjustedValueViewerCells: [AMOUNT_ALIAS],
          isDifferentCurrency,
          parsedCells,
          cell,
          relatedDateAlias: CONVERSION_DATE_ALIAS,
        });
        return { ...acc, [key]: updatedCell };
      }, {});
      const tmpCells = objToArray(parsedCells).map(([, cellObj]) => cellObj);
      tmpCells.forEach(cellObj =>
        conditions({
          cell: cellObj,
          tableData: acquisitionsData,
          tableCells: parsedCells,
        })
      );
      setConvertedCells(parsedCells);
    }
  }, [rows, config, isDifferentCurrency, setConvertedCells, acquisitionsData]);

  return (
    <>
      <h4>Converted</h4>
      <br />
      <LedgerTable
        colConfig={config}
        rows={rows}
        emptyRow={emptyRow}
        tableTerms={{
          tableName: 'Converted Notes',
          tableSlug: 'converted-notes',
          columnName: 'Note Conversion',
          pluralColumnName: 'Notes Conversion',
        }}
        setIsAlertVisible={setIsAlertVisible}
        disabled={disabledCondition}
        addRow={addRow}
        deleteRow={deleteRow}
        cells={convertedCells}
        setCells={setConvertedCells}
        data={rows}
        doFullValidation={doFullValidation}
        setIsValid={setIsValid}
        updateValidationStatus={updateValidationStatus}
        tableData={{ acquisitions: reverseParsedAcquisitions }}
        conditions={conditions}
        currency={format.currency}
        exchangeRateMap={exchangeRateMap}
      />
    </>
  );
};

ConvertedTable.propTypes = {
  isDisabled: PropTypes.bool,
  setIsAlertVisible: PropTypes.func,
  doFullValidation: PropTypes.bool,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  isDifferentCurrency: PropTypes.bool,
  fundCurrency: PropTypes.string,
  selectedMeasurementDate: PropTypes.shape({
    cmd_id: PropTypes.number,
    date: PropTypes.string,
    id: PropTypes.number,
    company_id: PropTypes.number,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  dialogData: PropTypes.shape({
    purchased: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        amount: PropTypes.string,
      })
    ),
    converted: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        shares: PropTypes.string,
        amount: PropTypes.string,
      })
    ),
    sold: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        amount: PropTypes.string,
      })
    ),
    outstanding: PropTypes.shape({
      amount: PropTypes.number,
    }),
  }),
  acquisitionsData: PropTypes.shape({
    acquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setAcquisitionsData: PropTypes.func,
  reverseParsedAcquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  setConvertedCells: PropTypes.func,
  convertedCells: PropTypes.shape({}),
};

export default ConvertedTable;
