import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { companySummaryUrl } from 'common/config/urls';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { UseFormatValues } from 'common/types/format';
import { UseStoreValues } from 'common/types/store';
import { CustomScalarSpreadsheet } from 'components';
import { DEFAULT_CURRENCY, REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { HeadingWithLink } from 'pages/Funds/components';
import { useScheduleOfInvestmentsSheet } from 'pages/Funds/hooks';
import { getArrayValue, getStringValue } from 'utilities';
import useStyles from './styles';
import { CompanyInvestmentsProps } from './types';

export const LoadingFundInvestments: FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.section}>
      <GridSkeleton maxColumns={10} maxRows={4} />
    </Grid>
  );
};

const CompanyInvestments: FC<CompanyInvestmentsProps> = props => {
  const { company, isLoading, isFetching, measurementDate } = props;

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { firmInfo } = storeValue;

  const firmSlug = getStringValue(firmInfo?.slug);
  const queryParams = measurementDate?.slug ? `?date=${measurementDate.slug}` : '';

  // Get Company Summary URL
  const companyUrl = `${companySummaryUrl(firmSlug, getStringValue(company?.company_slug))}${queryParams}`;

  const classes = useStyles();

  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: company?.company_captable_currency ?? DEFAULT_CURRENCY,
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  const { spreadsheet: scheduleOfInvestmentsSheet } = useScheduleOfInvestmentsSheet({
    company,
    firmSlug,
    queryParams,
  });

  const spreadsheets = useMemo(
    () => (scheduleOfInvestmentsSheet?.yLength ? [scheduleOfInvestmentsSheet] : []),
    [scheduleOfInvestmentsSheet]
  );

  const { onChange, workbook } = useWorkbook(spreadsheets);

  // Create scheduleOfInvestmentsSheet props
  const scheduleOfInvestmentsSheetProps = useMemo(() => {
    if (scheduleOfInvestmentsSheet)
      return {
        ...scheduleOfInvestmentsSheet,
        data: getArrayValue(scheduleOfInvestmentsSheet?.data),
        format,
        formatDispatch,
        onChange,
        sheet: scheduleOfInvestmentsSheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [scheduleOfInvestmentsSheet, format, formatDispatch, onChange, workbook]);

  // Loading Grid Skeleton while fetching data
  if (isLoading) return <LoadingFundInvestments />;

  return (
    <Grid className={classes.section}>
      {scheduleOfInvestmentsSheetProps && scheduleOfInvestmentsSheet && !isEmpty(company?.investments) && (
        <>
          <HeadingWithLink title={getStringValue(company?.company_name)} url={companyUrl} />

          <CustomScalarSpreadsheet {...scheduleOfInvestmentsSheetProps} isLoading={isFetching} />
        </>
      )}
    </Grid>
  );
};

export default CompanyInvestments;
