import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons//ArrowDropDown';
import Skeleton from '@material-ui/lab/Skeleton';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  ADD_NEW_DOCUMENT_BUTTON,
  CLICK_TO_DOWNLOAD,
  DIALOG_CUSTOM_BUTTON_LABEL,
  DIALOG_DROP_ZONE_TITLE,
  DIALOG_SELECTOR_TITLE,
  DIALOG_TITLE,
  DROP_ZONE_TITLE,
  REQUEST_NEW_DOCUMENT,
  SAVE_TO_ADD_REFERENCES,
  SUBTITLE,
  WIDGET_TITLE,
} from 'common/constants/documents';
import { EXPORT_VIEWER_VALUE, LIMITED_VIEWER_VALUE } from 'common/constants/user';
import DropZone from 'components/DropZone';
import { LayoutContext } from 'context';
import { DocumentsContext } from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import theme from 'theme';
import DocumentDialog from './DocumentDialog';
import DocumentInAccordionSummary from './DocumentInAccordionSummary';
import DocumentReferencesFooter from './DocumentReferencesFooter';
import FileAlerts from './FileAlerts';
import ReferencedDocumentsList from './ReferencedDocumentsList';
import RequestDocumentDialog from './RequestDocumentDialog';
import validFileTypes from './validFileTypes';

const useStyles = makeStyles({
  boxContainer: {
    border: `0.063rem solid ${theme.palette.gray[300]}`,
    padding: '0 0.5rem',
    '& .MuiIconButton-edgeEnd': {
      marginRight: '0 !important',
    },
  },
  accordionSummary: {
    padding: '0',
  },
  widgetTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginRight: '0.4rem',
  },
  expandIcon: {
    '& .MuiIconButton-edgeEnd': {
      marginRight: '0px !important',
    },
  },
  divSummaryDocumentsContainer: {
    width: ({ filesListWidth }) => filesListWidth || '25rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  documentInAccordionSummary: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noReferencedId: {
    margin: '3rem 0',
    fontWeight: 'bold',
    color: theme.palette.grey[500],
  },
});

const DocumentReferences = ({
  selectedMeasurementDate,
  currentPage,
  referenceType,
  referencedFeatureId,
  filesToSave,
  setFilesToSave,
  isDisabled,
}) => {
  const [containerWidth, setContainerWidth] = useState(250);
  const { currentFirmRole, viewOnlyUser } = useContext(LayoutContext);
  const filesListWidth = `${(parseFloat(containerWidth) || 0) - 20}px`;
  const classes = useStyles({ containerWidth, filesListWidth });
  const [openAddNewDocument, setOpenAddNewDocument] = useState(false);
  const [openRequestNewDocument, setOpenRequestNewDocument] = useState(false);
  const [documents, setDocuments] = useState();
  const [referencedDocuments, setReferencedDocuments] = useState();
  const [droppedFiles, setDroppedFiles] = useState([]);
  const fromDocumentFeature = true;
  const fromReferenceWidget = true;
  const {
    getReferencesByMeasurementDateId,
    foundReferencedDocuments,
    currentDocuments,
    getDocuments,
    setIsLoading,
    downloadDocument,
    setCompanyDocuments,
    setCurrentDocuments,
  } = useDocuments();
  const [isOver, setIsOver] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const stopPropagation = e => e.stopPropagation();

  const areDocumentsActionsDisabled = useMemo(
    () => [EXPORT_VIEWER_VALUE, LIMITED_VIEWER_VALUE].includes(currentFirmRole),
    [currentFirmRole]
  );

  const preventDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragOver = event => {
    preventDefault(event);
  };

  const dragEnter = event => {
    preventDefault(event);
    setIsOver(true);
  };

  const dragLeave = event => {
    preventDefault(event);
    setIsOver(false);
  };

  const handleFiles = files => {
    setDroppedFiles(files);
  };

  const onDrop = event => {
    setIsOver(false);
    preventDefault(event);
    const { files } = event.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleOpen = useCallback(
    dialogType => {
      const actionFunctionsDialog = {
        [ADD_NEW_DOCUMENT_BUTTON]: () => setOpenAddNewDocument(true),
        [REQUEST_NEW_DOCUMENT]: () => setOpenRequestNewDocument(true),
      };
      actionFunctionsDialog[dialogType]();
    },
    [setOpenAddNewDocument, setOpenRequestNewDocument]
  );

  const handleClose = () => {
    setOpenAddNewDocument(false);
    setOpenRequestNewDocument(false);
  };

  const handleDocuments = useCallback(async () => {
    await getDocuments(selectedMeasurementDate.id);
    const docs = await getReferencesByMeasurementDateId(selectedMeasurementDate.id);
    if (Array.isArray(docs)) {
      setDocuments(docs);
    }
    setIsFileUploaded(false);
  }, [getDocuments, getReferencesByMeasurementDateId, selectedMeasurementDate]);

  const updateReferencedDocuments = useCallback(
    documentsParam => {
      handleDocuments();
      setReferencedDocuments(foundReferencedDocuments(documentsParam, currentPage, referencedFeatureId));
    },
    [currentPage, foundReferencedDocuments, handleDocuments, referencedFeatureId]
  );

  const processFile = async files => {
    setIsOver(false);
    const fileList = [...files];
    setDroppedFiles(prevState => [...prevState, ...fileList]);
  };

  const handleDownload = async documentParam => {
    setIsLoading(true);
    await downloadDocument(documentParam);
    setIsLoading(false);
  };

  const fetchDocuments = async () => {
    if (selectedMeasurementDate) {
      try {
        await handleDocuments();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToSave]);

  useEffect(() => {
    if (documents) {
      setReferencedDocuments(foundReferencedDocuments(documents, currentPage, referencedFeatureId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    setDroppedFiles([]);
  }, [isFileUploaded]);

  const getWidthById = useCallback(id => {
    const element = document.getElementById(id);
    if (element) {
      const width = element.offsetWidth;
      return width;
    }
    return null; // Return null if element with given ID is not found
  }, []);

  const adjustDocumentReferencesWidth = useCallback(() => {
    const accordionWidth = getWidthById('document-references-accordion');
    const titleWidth = getWidthById('document-references-title');
    const counterWidth = getWidthById('document-references-counter');
    // This "extraSpace" is approximately the width used by the arrow on the right side
    // of the accordion header and the paddings of all the elements in general.
    const extraSpace = 120;
    const totalWidth = accordionWidth - (titleWidth + counterWidth + extraSpace);
    setContainerWidth(totalWidth);
  }, [getWidthById]);

  useEffect(() => {
    const handleResize = () => {
      adjustDocumentReferencesWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (referencedDocuments) {
      adjustDocumentReferencesWidth();
    }
  }, [referencedDocuments, adjustDocumentReferencesWidth]);

  const documentListProps = {
    selectedMeasurementDate,
    referenceType,
    referencedFeatureId,
    currentPage,
    isDisabled,
    areDocumentsActionsDisabled,
  };

  const documentsContextProps = useMemo(
    () => ({
      documents,
      updateReferencedDocuments,
      currentPage,
      currentDocuments,
      filesToSave,
      setCompanyDocuments,
      setCurrentDocuments,
    }),
    [
      documents,
      updateReferencedDocuments,
      currentPage,
      currentDocuments,
      filesToSave,
      setCompanyDocuments,
      setCurrentDocuments,
    ]
  );

  const documentDialogProps = {
    referenceType,
    referencedFeatureId,
    referencedDocuments,
    setDroppedFiles,
  };

  if (!referencedDocuments) return <Skeleton height="54px" style={{ transform: 'none' }} />;

  return (
    <DocumentsContext.Provider value={documentsContextProps}>
      <Accordion
        id="document-references-accordion"
        className={classes.boxContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={onDrop}
        onChange={(__event, expanded) => setIsAccordionExpanded(expanded)}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className={classes.expandIcon} />}
          aria-controls="accordion-widget-content"
          id="accordion-widget-header">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    id="document-references-title"
                    variant="overline"
                    style={{ fontSize: '0.875rem', fontWeight: 'bold', marginRight: '0.4rem' }}>
                    {WIDGET_TITLE}
                  </Typography>
                </Grid>
                <Grid item>
                  {!isAccordionExpanded && referencedDocuments && (
                    <div className={classes.divSummaryDocumentsContainer}>
                      {referencedDocuments.map(document => (
                        <Tooltip
                          disableHoverListener={areDocumentsActionsDisabled}
                          title={CLICK_TO_DOWNLOAD}
                          key={document.file?.id}>
                          <Typography
                            onClick={stopPropagation}
                            variant="caption"
                            className={classes.documentInAccordionSummary}>
                            <DocumentInAccordionSummary
                              areActionsDisabled={areDocumentsActionsDisabled}
                              referencedDocuments={referencedDocuments}
                              onClickHandler={handleDownload}
                              document={document}
                            />
                          </Typography>
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {!isAccordionExpanded && referencedDocuments && (
                <Typography id="document-references-counter" variant="caption" style={{ fontSize: '0.875rem' }}>
                  Files: {referencedDocuments.length}
                </Typography>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        {referencedFeatureId ? (
          <>
            <AccordionDetails>
              <div style={{ width: '100%' }}>
                {isOver ? (
                  <DropZone
                    setFileSrc={processFile}
                    titleArray={DROP_ZONE_TITLE}
                    subTitle={SUBTITLE}
                    maxSizeAllowedInMb={15}
                    validFileTypes={validFileTypes}
                    fromDocumentFeature={fromDocumentFeature}
                  />
                ) : (
                  <ReferencedDocumentsList {...documentListProps} documents={referencedDocuments} />
                )}
                {!isEmpty(droppedFiles) && (
                  <div style={{ margin: '1rem 0' }}>
                    <FileAlerts
                      style={{ marginTop: '1rem' }}
                      droppedFiles={droppedFiles}
                      selectedMeasurementDateId={selectedMeasurementDate.id}
                      validFileTypes={validFileTypes}
                      fromReferenceWidget={fromReferenceWidget}
                      setIsFileUploaded={setIsFileUploaded}
                      setFilesToSave={setFilesToSave}
                    />
                  </div>
                )}
              </div>
            </AccordionDetails>
            <DocumentReferencesFooter isDisabled={isDisabled || viewOnlyUser} onClickHandler={handleOpen} />
          </>
        ) : (
          <AccordionDetails style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography align="center" className={classes.noReferencedId}>
              {SAVE_TO_ADD_REFERENCES}
            </Typography>
          </AccordionDetails>
        )}
      </Accordion>
      <DocumentDialog
        {...documentDialogProps}
        isValid
        confirmCancel
        addSelectComponent
        fromReferenceWidget
        open={openAddNewDocument}
        onClose={handleClose}
        title={DIALOG_TITLE}
        selectorTitle={DIALOG_SELECTOR_TITLE}
        dropZoneTitle={DIALOG_DROP_ZONE_TITLE}
        endIconSelector={<ArrowDropDownIcon />}
        selectedMeasurementDateId={selectedMeasurementDate?.id}
        customButtonLabel={DIALOG_CUSTOM_BUTTON_LABEL}
        isDisabled
      />
      <RequestDocumentDialog
        open={openRequestNewDocument}
        onClose={handleClose}
        currentDocuments={currentDocuments}
        companyMeasurementDateId={selectedMeasurementDate?.cmd_id}
      />
    </DocumentsContext.Provider>
  );
};

DocumentReferences.propTypes = {
  selectedMeasurementDate: PropTypes.object,
  currentPage: PropTypes.string,
  referenceType: PropTypes.string,
  referencedFeatureId: PropTypes.number,
  filesToSave: PropTypes.array,
  setFilesToSave: PropTypes.func,
  customMaxWidth: PropTypes.string,
  isDisplayingRowNumbers: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default DocumentReferences;
