import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { TIMEZONE } from 'common/constants/firms';
import { getStringValue } from 'utilities';
import { AnsweredAtProps } from './types';

const AnsweredAt: FC<AnsweredAtProps> = props => {
  const { date } = props;

  return (
    <Typography
      style={{
        fontWeight: 600,
        fontSize: '0.75rem',
        color: '#888888',
        marginTop: '0.5rem',
        marginLeft: 'auto',
      }}>
      Answered at:{' '}
      {new Date(getStringValue(date?.toString())).toLocaleDateString('en-US', {
        timeZone: TIMEZONE,
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      })}
    </Typography>
  );
};

export default AnsweredAt;
