import { Row } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getWaccDataApproach } from 'pages/Valuations/util/util';
import reverseParser from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/reverseParser';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utilities';
import parser from './config/parser';
import getRowConfig from './config/rowConfig';
import { ColType, CreateDCFWACCDataProps, ParseDataToColsParams, WaccData } from './config/types';

export const parseDataToCols = ({ rowConfig, waccData }: ParseDataToColsParams) => [
  rowConfig.reduce((col: ColType, row: Row) => {
    // eslint-disable-next-line no-param-reassign
    col[row.alias as keyof WaccData] = waccData[row.alias as keyof WaccData];
    return col;
  }, {} as ColType),
];

const createDCFWACCData = (props: CreateDCFWACCDataProps) => {
  const { approach, dcfProps, dcfAttributes, isDisabled } = props;
  const { cashTaxRate } = getObjectValue(dcfProps);
  const name = getApproachTableName({ approach, tableSuffix: 'wacc' });

  const waccData = getWaccDataApproach(approach, 'valuations_approach_dcf') as WaccData;

  const rowConfig = getRowConfig({
    cashTaxRate,
    isDisabled,
  }) as Row[];

  const getColumns = () => parseDataToCols({ rowConfig, waccData });
  const columns = getColumns();

  return new SpreadsheetConfig({
    name,
    tableData: { approach },
    rowConfig,
    columns,
    parser,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    showToolbar: true,
    hasColTitle: true,
    showTotalColumn: false,
    currencyFormatter: true,
    unitsFormatter: true,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'wacc-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    getColumns,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    format: undefined,
    totalParser: undefined,
  });
};

export default createDCFWACCData;
