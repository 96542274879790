import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DATE_ALIAS } from 'common/constants/general';
import { setQueryString } from 'utilities';
import { UpdateDateOnQueryStringHook, UseUpdateDateOnQueryString } from './types';

const useUpdateDateOnQueryString: UseUpdateDateOnQueryString = () => {
  const history = useHistory();
  const location = useLocation();

  const updateDateOnQueryString: UpdateDateOnQueryStringHook['updateDateOnQueryString'] = useCallback(
    params => {
      const { date } = params;

      setQueryString({
        history,
        paramName: DATE_ALIAS,
        paramValue: date,
        pathname: location.pathname,
        searchParams: location.search,
      });
    },
    [history, location.pathname, location.search]
  );

  return { updateDateOnQueryString };
};

export default useUpdateDateOnQueryString;
