/* eslint-disable import/no-unresolved */
import { isNil, isNull, sortBy } from 'lodash';
import uuid from 'react-uuid';
import { DATE_ALIAS } from 'common/constants/allocations';
import * as FOC from 'common/constants/fund-ownership';
import { onCellsChanged } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { shortDate } from 'utilities/datesFormats';

const fundOwnershipFields = [FOC.SHARES_LEDGER_ALIAS, FOC.CASH_DISTRIBUTION_LEDGER_ALIAS];

const allocationFields = [DATE_ALIAS];

const parser = async ({ columns, rowConfig, tableData }) => {
  const { securities } = tableData.capTable;
  const sortedColumns = sortBy(columns, ['order']);
  const alphabet = alphabetGenerator([], columns.length);
  const changes = [];

  let colIndex = 0;
  let cells = {};
  let columnSecurity = '';

  const treatValues = (alias, value) => {
    if (alias === 'investment_date') return shortDate(value);
    if (['date', 'title'].includes(alias)) return value;
    if (typeof value === 'string') return value;
    if (typeof value === 'object') return value;
    if (typeof value === 'boolean') return Number(value);
    if (isNil(value)) return 0;
    if (!Number.isNaN(value)) return Number(value);
    if (Number.isNaN(value)) return value;
    return value;
  };

  const replacePlaceholders = (columnLegend, id, str = '') => {
    const newExpression = str
      .replace(/{{columnLegend}}/g, columnLegend)
      .replace(/{{columnSecurity}}/g, columnSecurity)
      .replace(/{{totalSharesOutstanding}}/g, tableData?.calculations?.[id]?.totalSharesOutstanding)
      .replace(/{{cumulativeDividends}}/g, tableData?.calculations?.[id]?.cumulativeDividends);
    return newExpression;
  };

  const getLinkedCells = (linkedCells, columnLegend, columnLegends) =>
    new Set(
      linkedCells.flatMap(linkedCell => {
        if (linkedCell.includes('@@')) {
          return columnLegends.map(legend => linkedCell.replace('@@', legend));
        }
        return linkedCell.replace('@', columnLegend);
      })
    );

  // Add content columns, iterating through each sorted column
  sortedColumns.forEach(foColumn => {
    const columnLegend = alphabet[colIndex];
    const columnRef = foColumn.column_ref || foColumn.columnRef || uuid();
    let rowIndex = 1;

    // Get the selected column security
    const selectedSecurity = foColumn.security || '';

    const matchedSecurity = securities.find(security => security.id.toString() === selectedSecurity.toString());

    columnSecurity = matchedSecurity ? matchedSecurity.name : null;

    // Add properties from the sorted columns using the order of the row config
    rowConfig.forEach(({ alias, expr, dataSourceKey, defaultValue, format, className, linkedCellSymbols }) => {
      const key = columnLegend + rowIndex;
      const exprAux = replacePlaceholders(columnLegend, foColumn.id, expr);
      const customSecurityName = foColumn.custom_security_name || foColumn.emptyColumn?.custom_security_name || '';
      const isCustomSecurityColumn = isNull(foColumn.security) && !isNull(customSecurityName);
      const isCustomSecurityCell = alias === FOC.CUSTOM_SECURITY_NAME_ALIAS && isCustomSecurityColumn;
      const currentValue = isCustomSecurityCell ? customSecurityName : foColumn[alias];
      const value = treatValues(alias, currentValue);

      const foCell = {
        className,
        value,
        alias,
        key,
        format,
        expr: exprAux,
        dataSourceKey: replacePlaceholders(columnLegend, dataSourceKey),
        columnId: foColumn.id,
        columnRef,
        columnLegend,
        defaultValue,
        columnOrder: foColumn.order,
        linkedCellSymbols: getLinkedCells(linkedCellSymbols || [], columnLegend, alphabet),
        acquisitions: foColumn.acquisitions || [],
      };

      if (alias === FOC.SECURITY_ALIAS && isCustomSecurityColumn) {
        foCell.value = customSecurityName;
      }

      cells = {
        ...cells,
        [key]: foCell,
      };

      if ([FOC.FULLY_DILUTED_OWNERSHIP_ROW_NUMBER, FOC.LIQUIDATION_PREFERENCE_PLUS_ROW_NUMBER].includes(rowIndex)) {
        changes.push({
          cell: {
            ...cells[key],
          },
          value: exprAux,
        });
      }

      rowIndex += 1;
    });

    // Add Cap Table hidden fields
    const getCells = (fields, getValue) => {
      fields.forEach(alias => {
        const key = `${alias}_${columnLegend}`;
        const value = getValue(alias);

        cells = {
          ...cells,
          [key]: {
            key,
            value,
            alias,
            columnLegend,
          },
        };
      });
    };

    // Add fund ownership hidden properties
    const getFundOwnershipValue = alias => foColumn[alias] || null;
    getCells(fundOwnershipFields, getFundOwnershipValue);

    // Add allocation hidden fields
    const getAllocationValue = alias => treatValues(alias, tableData.allocation[alias]);
    getCells(allocationFields, getAllocationValue);

    colIndex += 1;
  });

  // Compute expressions
  cells = await onCellsChanged(changes, cells);
  return cells;
};

export default parser;
