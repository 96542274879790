import { isEmpty } from 'validate.js';
import { Cell } from 'common/types/scalarSpreadsheet';
import {
  DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER,
  DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER,
  DCF_TVT_COMBINED_PV_OF_TERMINAL_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_SELECTED_APPROACH_ROW_NUMBER,
  DCF_TVT_COMBINED_TERMINAL_VALUE_ROW_NUMBER,
  DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER,
  DCF_TVT_COMBINED_WACC_ROW_NUMBER,
} from 'pages/Valuations/util/constants';
import parser from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcfTerminalValue/util/getParser';
import { EBITDARevenueParams } from 'pages/ValuationsAllocation/util/updateDCFOptions/types';
import getCombinedColumns from './getCombinedColumns';
import rowConfig from './rowConfig';
import { GetCombinedColumnsProps, ParseMixinProps } from './types';
import { RowConfigsProps } from '../../types';

const parseMixin = (props: ParseMixinProps): Cell => {
  const { cell, cfTVTRMSheetName, dcfTVTEMSheetName } = props;
  switch (cell.rowNumber) {
    case DCF_TVT_COMBINED_TERMINAL_YEAR_ROW_NUMBER: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A2`,
        };
      }
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A2`,
        };
      }
      return cell;
    }
    case DCF_TVT_COMBINED_SELECTED_APPROACH_ROW_NUMBER: {
      if (cell.columnLegend === 'A') {
        return {
          ...cell,
          expr: `=${cfTVTRMSheetName}.A3`,
          isExpr: true,
        };
      }
      if (cell.columnLegend === 'B' && isEmpty(cell.value)) {
        return {
          ...cell,
          expr: `=${dcfTVTEMSheetName}.A3`,
          isExpr: true,
        };
      }
      return cell;
    }
    case DCF_TVT_COMBINED_MULTIPLE_ROW_NUMBER: {
      return { ...cell, readOnly: cell.readOnly };
    }
    case DCF_TVT_COMBINED_TERMINAL_VALUE_ROW_NUMBER:
    case DCF_TVT_COMBINED_AVERAGE_EXIT_VALUE_ROW_NUMBER:
    case DCF_TVT_COMBINED_WACC_ROW_NUMBER:
    case DCF_TVT_COMBINED_DISCOUNT_PERIODS_ROW_NUMBER:
    case DCF_TVT_COMBINED_PV_OF_TERMINAL_VALUE_ROW_NUMBER:
      if (cell.columnLegend === 'B') {
        return {
          ...cell,
          ignoreCell: true,
        };
      }
      return {
        ...cell,
        colSpan: 2,
      };
    default:
      return cell;
  }
};

const getConfig = ({ columnsProps }: RowConfigsProps) => ({
  className: 'revenue-ebitda-multiple-terminal-table',
  rowConfig: rowConfig(columnsProps as EBITDARevenueParams),
  parser: parser({
    cellUpdateMixin: parseMixin,
    cfTVTRMSheetName: columnsProps.dcfTVTRMSheetName,
    dcfTVTEMSheetName: columnsProps.dcfTVTEMSheetName,
  }),
  columns: getCombinedColumns(columnsProps as GetCombinedColumnsProps),
});

export default getConfig;
