const isStringNumeric = str => {
  if (typeof str !== 'string') return false; // we only process strings!
  // use type coercion to parse the _entirety_ of the string
  // (`parseFloat` alone does not do this)...
  return (
    !Number.isNaN(str)
    // ...and ensure strings of whitespace fail
    && !Number.isNaN(parseFloat(str))
  );
};
export default isStringNumeric;
