/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import * as constants from 'common/constants/financials';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { EqualSymbol, NotEqualSymbol } from 'icons';
import { FinancialsDate } from 'pages/Financials/components';
import reverseParser from 'pages/Financials/income-statement/utilities/reverseParser';
import { columnUpdateActionCallback, getQuarterRows } from 'pages/Financials/utilities';
import { gridShortDate, isValidDate, sumValuesFilteredCells, toString } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';

const editableFields = [
  constants.TOTAL_REVENUE_ROW_NUMBER,
  constants.TOTAL_COST_OF_SALES_ROW_NUMBER,
  constants.OPERATING_EXPENSES_ROW_NUMBER,
  constants.ADJUSTED_EBITDA_ROW_NUMBER,
  constants.DEPRECIATION_EXPENSE_ROW_NUMBER,
  constants.AMORTIZATION_EXPENSE_ROW_NUMBER,
  constants.INTEREST_EXPENSE_ROW_NUMBER,
  constants.OTHER_EXPENSE_ROW_NUMBER,
  constants.INCOME_TAXES_ROW_NUMBER,
];

const conditions = ({ cell, tableData, cells: scope, sheet, onCellsChanged }) => {
  const { alias, columnLegend, isLTM, isNTM, rowNumber, value: currentCellValue, isParent: isYearCell } = cell;
  const { isDisabled } = tableData;

  const { columns } = tableData;
  const alphabet = alphabetGenerator([], columns.length);
  const colNumber = alphabet.findIndex(char => char === columnLegend);

  const sumRowQuarters = ({ col, row }) => {
    const quarterRows = getQuarterRows({ alphabet, col, row });
    let total = 0;

    quarterRows.forEach(cellKey => {
      total += Number(sheet.cells[cellKey]?.value);
    });

    return total;
  };

  const setFormula = ({ targetCell, formula }) => {
    const celChanged = [
      {
        cell: targetCell,
        value: formula,
      },
    ];

    onCellsChanged(celChanged, scope);
  };

  const setEqualSymbol = cell => {
    if (cell.action) {
      cell.action = {
        ...cell.action,
        icon: <EqualSymbol />,
      };
      cell.dispatchEvent();
    }
  };

  const enableAutoSum = ({ targetCell, title, isEqual, callback, buttonText, type = null, position = 'right' }) => {
    if (targetCell) {
      targetCell.action = {
        position,
        type,
        icon: isEqual ? <EqualSymbol /> : <NotEqualSymbol />,
        tooltip: {
          visible: !isEqual,
          title,
          buttonText,
          enterDelay: 500,
          enterNextDelay: 500,
        },
        callback,
      };
    }
  };

  const addExtraAction = ({ targetCell, title, isEqual, callback, buttonText, type, position = 'right' }) => {
    if (!targetCell.action) {
      enableAutoSum({
        targetCell,
        title,
        isEqual,
        buttonText,
        callback,
        type,
        position,
      });
    } else if (targetCell.action.type !== 'update_col') {
      targetCell.extraActions = [
        {
          title,
          buttonText,
          callback,
          type,
        },
      ];
    }
  };

  const enableColumnAutoSum = args => {
    let syncedRows = 0;
    const changes = [];

    editableFields.forEach(row => {
      const currentCell = sheet.cells[columnLegend + row];
      const currentFormula = currentCell?.expr;
      const expectedFormula = currentCell?.defaultExpr;

      if (currentFormula === expectedFormula) {
        syncedRows += 1;
      }

      changes.push({
        cell: currentCell,
        value: expectedFormula,
      });
    });

    const columnAutosumCallback = () => {
      onCellsChanged(changes, scope);
      changes.forEach(change => setEqualSymbol(change.cell));
      const { cells: allCells } = sheet;
      Object.values(allCells).forEach(c => c.dispatchEvent());
      Object.values(window.appGlobal.allCells.performanceMetrics).forEach(c => c.dispatchEvent());
    };

    !isDisabled
      && enableAutoSum({
        ...args,
        targetCell: cell,
        isEqual: editableFields.length === syncedRows,
        title: constants.SUM_QUARTERS_ACTION_MESSAGE,
        callback: () => {
          columnAutosumCallback();
          setEqualSymbol(cell);
        },
      });
  };

  const enableUpdateColumn = () => {
    if (cell.needs_actualization) {
      addExtraAction({
        targetCell: cell,
        title: constants.COLUMN_UPDATED_ACTION_TITLE,
        callback: () =>
          columnUpdateActionCallback({
            cell,
            scope,
            onCellsChanged,
            reverseParser,
          }),
        type: 'update_col',
        buttonText: constants.COLUMN_UPDATED,
      });
    }
  };

  const enableEditableDate = () => {
    if (isValidDate(cell.value)) {
      cell.readOnly = isDisabled;
      cell.className = 'date-editor-white';
      cell.value = gridShortDate(cell.value);
      cell.gridDateComponent = FinancialsDate;
      cell.dataEditor = props => <GridDateEditor {...props} />;
      cell.valueViewer = props => <GridDateValueViewer {...props} />;
    }
  };

  if (isYearCell || isLTM || isNTM || cell.date) {
    switch (alias) {
      case constants.FIRST_ROW_ALIAS:
        if (cell.rowSpan === 3) {
          enableColumnAutoSum({ position: 'center' });
          enableUpdateColumn();
        }
        break;
      case constants.SECOND_ROW_ALIAS:
        if (isLTM || isNTM) {
          enableEditableDate();
        } else if (cell.isProjectionPeriod && cell.year) {
          enableColumnAutoSum();
          enableUpdateColumn();
        }
        break;
      case constants.TOTAL_REVENUE_ALIAS:
      case constants.TOTAL_COST_OF_SALES_ALIAS:
      case constants.OPERATING_EXPENSES_ALIAS:
      case constants.ADJUSTED_EBITDA_ALIAS:
      case constants.DEPRECIATION_EXPENSE_ALIAS:
      case constants.AMORTIZATION_EXPENSE_ALIAS:
      case constants.INTEREST_EXPENSE_ALIAS:
      case constants.OTHER_EXPENSE_ALIAS:
      case constants.INCOME_TAXES_ALIAS:
        let alreadyEqual = true;

        if (isYearCell) {
          const totalRowQuarter = sumRowQuarters({ col: colNumber, row: rowNumber });

          if (isEmpty(toString(cell.value)) && isEmpty(cell.expr)) {
            cell.expr = cell.defaultExpr;
          } else {
            alreadyEqual = totalRowQuarter === Number(currentCellValue);
          }
        } else if (isLTM || isNTM) {
          if (isEmpty(toString(cell.value))) {
            cell.expr = cell.defaultExpr;
          } else {
            const targetCellKey = cell.defaultExpr.replace('=', '');
            const targetCell = sheet.cells[targetCellKey];
            alreadyEqual = Number(cell.value) === Number(targetCell?.value);
          }
        } else {
          const gridCells = Object.values(scope[sheet.name]);
          const year = String(moment(cell.date).year());
          const yearCell = gridCells.find(tmpCell => tmpCell.rowNumber === cell.rowNumber && tmpCell.year === year);

          const sumQuarters = sumValuesFilteredCells({
            cells: scope[sheet.name],
            year,
            rowNumber: cell.rowNumber,
          });

          alreadyEqual = Number(yearCell?.value) === sumQuarters;

          // Set the action on the parent cell to display the correct icon
          if (!isDisabled) {
            enableAutoSum({
              targetCell: yearCell,
              isEqual: alreadyEqual,
              title: constants.SUM_QUARTERS_FOR_ROW_ACTION_MESSAGE,
              callback: () => {
                setFormula({
                  targetCell: yearCell,
                  formula: yearCell.defaultExpr,
                });
                setEqualSymbol(cell);
              },
            });
          }
        }

        const callback = () => {
          setFormula({
            targetCell: cell,
            formula: cell.defaultExpr,
          });
          setEqualSymbol(cell);
        };

        // Enable the tooltip on year cells only
        if (cell.year && !isDisabled) {
          enableAutoSum({
            targetCell: cell,
            isEqual: alreadyEqual,
            title: constants.VALUE_MANUALLY_INPUTTED,
            callback,
          });
        }
        break;
      default:
    }
  } else if (alias === constants.SECOND_ROW_ALIAS) {
    enableUpdateColumn();
  }

  return cell;
};

export default conditions;
