import {
  EQUITY_VALUE_ALIAS,
  SECURITY_ALIAS,
  SHARE_PRICE_ALIAS,
} from 'pages/ValuationsAllocation/approaches/SpecifiedShareValues/constants';
import { normalizeShareValues } from 'pages/ValuationsAllocation/approaches/SpecifiedShareValues/utils';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import { parseDatabaseValue } from 'utilities';
import { CellsWithIds, CellWithId, ReverseParserParams } from './types';

const reverseParse = (cell: CellWithId, cells: CellsWithIds, allowEmptyValues: boolean) => {
  const { key, alias, id, gridType } = cell;

  // Early return to handle the security cell (no further processing is needed)
  if (alias === SECURITY_ALIAS) {
    return id;
  }

  const defaultValue = cell.defaultValue || null;
  const type = cell.dbType || null;
  const format = cell.format || null;
  const value = cells[key] ? cells[key].value : defaultValue;
  const decimalPlaces = cell?.dbDecimalPlaces;

  const calcValue = parseDatabaseValue({
    type,
    value,
    format,
    allowEmptyValues,
    gridType,
    decimalPlaces,
  });

  if (alias === SHARE_PRICE_ALIAS) {
    return Number(calcValue);
  }

  return calcValue;
};

const customReverseParser = (params: ReverseParserParams) => {
  const { cells, tableData, initialObj, allowEmptyValues } = params;
  const tableDataSecurities = tableData.securities;
  const alphabet = ['A', 'B', 'C', 'D'];
  const tmpApproach = initialObj.valuations_approach_ssv;

  if (!tmpApproach) {
    throw new Error('Valuations approach SSV is missing');
  }

  tableDataSecurities.forEach((security, index) => {
    alphabet.forEach(columnLegend => {
      const cell = cells[`${columnLegend}${index + 2}`];
      // eslint-disable-next-line no-param-reassign
      security[cell.alias] = reverseParse(cell, cells, allowEmptyValues);
    });
  });

  const cellsArray = Object.values(cells);

  tmpApproach.share_values = normalizeShareValues({
    share_values: tableDataSecurities,
  });
  tmpApproach.cap_table = tableData.capTable.id;
  tmpApproach.enterprise_value = cellsArray.find(c => c.customKey === VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY)
    ?.value as string;
  tmpApproach.equity_value = cellsArray.find(c => c.alias === EQUITY_VALUE_ALIAS)?.value as string;

  return {
    ...initialObj,
    enterprise_value: tmpApproach.enterprise_value,
    equity_value: tmpApproach.equity_value,
    valuations_approach_ssv: tmpApproach,
  };
};

export default customReverseParser;
