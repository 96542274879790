import React, { useCallback, useContext } from 'react';
import { globalAction } from 'common/actions';
import { ALLOCATIONS_FINAL } from 'common/constants/allocations';
import { useStore } from 'common/store';
import { AllocationsStatuses } from 'common/types/allocation';
import { DialogContextValues } from 'common/types/dialogContext';
import { UseStoreValues } from 'common/types/store';
import { ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import { ValuationDialogContent } from 'pages/Funds/components';
import { useMakeAllocationsFinal } from 'services/hooks/allocations';
import { getObjectValue } from 'utilities';
import { ConfirmActionParams, UpdateAllocationStatusParams, UseAllocationStatus } from './types';

const CANCEL_LABEL = 'Cancel';
const DIALOG_TITLE = 'Confirmation';

const useAllocationStatus: UseAllocationStatus = params => {
  const { setAllocationsStatuses } = params;

  const [, dispatch] = useStore() as unknown as UseStoreValues;

  const { showDialog } = useContext(DialogContext) as unknown as DialogContextValues;

  const { mutateAsync: makeAllocationsFinal } = useMakeAllocationsFinal();

  const updateAllocationsStatuses = useCallback(
    async (updateParams: UpdateAllocationStatusParams) => {
      const { allocationsIds, allocationStatus } = updateParams;

      dispatch?.(globalAction.showLoadingProgress(true));

      if (allocationStatus === ALLOCATIONS_FINAL) {
        const makeAllocationsFinalResponse = await makeAllocationsFinal({ allocationsIds });

        const { allocationsIds: allocationsIdsFinal, allocationsStatuses: allocationsStatusFinal }
          = getObjectValue(makeAllocationsFinalResponse);

        if (allocationsIdsFinal) {
          // Update allocations statuses innmediatly after update allocations status (meanwhile the query is refetching)
          const updatedAllocationsStatuses = allocationsIdsFinal?.reduce(
            (accumulator, current) => ({ ...accumulator, [current]: allocationsStatusFinal ?? ALLOCATIONS_FINAL }),
            {}
          ) as AllocationsStatuses;

          setAllocationsStatuses(previousState => ({ ...previousState, ...updatedAllocationsStatuses }));
        }
      }

      return dispatch?.(globalAction.showLoadingProgress(false));
    },
    [dispatch, makeAllocationsFinal, setAllocationsStatuses]
  );

  const confirmMakeAllocationsFinal = useCallback(
    (confirmParams: ConfirmActionParams) => {
      const { cancelCallback, dialogMessage, successCallback, successLabel } = confirmParams;

      showDialog({
        actions: [
          {
            callback: cancelCallback,
            label: CANCEL_LABEL,
            type: 'cancel',
            variant: 'text',
          },
          {
            callback: successCallback,
            label: successLabel,
            type: 'success',
            variant: 'contained',
          },
        ],
        content: () => <ValuationDialogContent message={dialogMessage} />,
        wrapper: ConfirmationDialog,
        wrapperProps: {
          title: DIALOG_TITLE,
        },
      });
    },
    [showDialog]
  );

  return {
    confirmMakeAllocationsFinal,
    updateAllocationsStatuses,
  };
};

export default useAllocationStatus;
