import { useContext, useEffect, useMemo, useState } from 'react';
import { BacksolveApproach } from 'common/types/valuation';
import {
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  INDEX_AFTER_ALLOCATION_SCENARIO_METHODS,
  VALUATIONS_BACKSOLVE_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getBacksolveScenarioMethods } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { toString } from 'utilities';

const useFilteredAllocationMethods = () => {
  const { allocationMethodsOptions, approaches } = useContext(ValuationContext);
  const [filteredAllocationMethodsOptions, setFilteredAllocationMethodsOptions] = useState(allocationMethodsOptions);

  const allocationMethodsWithBacksolveMarketAdjustment = useMemo(() => {
    const existingIds = new Set(allocationMethodsOptions?.map(opt => opt.id));

    return (
      (approaches || [])
        .filter(
          approach =>
            approach.approach_type === VALUATIONS_BACKSOLVE_APPROACH
            && !approach.valuations_approach_backsolve?.adjust_for_market
        )
        .map(approach => {
          const { backsolveScenarioMethodsNames, isBacksolveWithOPM } = getBacksolveScenarioMethods(
            approach.valuations_approach_backsolve
          );
          return {
            id: `allocation-method__${approach.id}`,
            approachEquityValue: toString(approach.valuations_approach_backsolve?.implied_equity_value),
            approachPanelId: toString(approach.id),
            approachValuation: approach.valuations_approach_backsolve,
            approachValuationId: approach.valuations_approach_backsolve?.id,
            scenarioType: ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
            scenarioMethod: ALLOCATION_SCENARIO_METHOD_WATERFALL,
            isBacksolveWithOPM,
            name: `${backsolveScenarioMethodsNames} ${approach.name}`,
          };
        })
        /* Exclude choices already present in allocationMethodsOptions.
      This scenario occurs when the market adjustment is initially
      disabled so it is already present in allocationMethodsOptions */
        .filter(method => !existingIds.has(method.id))
    );
  }, [approaches, allocationMethodsOptions]);

  useEffect(() => {
    const tmpAllocationMethodsOptions = [
      ...(allocationMethodsOptions ?? []).filter(
        opt => !(opt.approachValuation as BacksolveApproach)?.adjust_for_market
      ),
    ];
    const updatedOptions = [...tmpAllocationMethodsOptions];
    updatedOptions.splice(
      INDEX_AFTER_ALLOCATION_SCENARIO_METHODS,
      0,
      ...allocationMethodsWithBacksolveMarketAdjustment
    );
    setFilteredAllocationMethodsOptions(updatedOptions);
  }, [allocationMethodsOptions, allocationMethodsWithBacksolveMarketAdjustment]);

  return filteredAllocationMethodsOptions;
};

export default useFilteredAllocationMethods;
