import React from 'react';
import { makeStyles } from '@material-ui/core';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getObjectValue, getStringValue } from 'utilities';
import { DCFSummaryTableProps } from './types';

const useStyles = makeStyles(() => ({
  spreadsheet: {
    '& td.table-header': {
      height: '53px',
      whiteSpace: 'normal !important',
    },
  },
}));

const DCFSummaryTable = (props: DCFSummaryTableProps) => {
  const { format, formatDispatch, spreadsheets, onChange, workbook } = props;
  const { dcfSummary: dcfSummarySpreadsheet } = getObjectValue(spreadsheets);

  const classes = useStyles();

  const dcfSummarySheetProps = {
    ...dcfSummarySpreadsheet,
    sheet: dcfSummarySpreadsheet,
    key: getStringValue(dcfSummarySpreadsheet?.name),
    workbook,
    onChange,
    format,
    formatDispatch,
    className: classes.spreadsheet,
  } as unknown as typeof ScalarSpreadsheet;

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore: ScalarSpreadsheet is not typed */}
      <ScalarSpreadsheet {...dcfSummarySheetProps} />
    </div>
  );
};

export default DCFSummaryTable;
