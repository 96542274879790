import { NewAllocationScenario } from 'common/types/allocation';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { WeightingProbability } from 'pages/ValuationsAllocation/types';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { CustomReverseParserParams } from './types';
import {
  handleEnterpriseValue,
  handleEquityValue,
  handleScenariosWeights,
  handleScenarioWeightingProbability,
} from './utils';

const {
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
} = SHEET_ALIASES_CONSTANTS;

const customReverseParser = (params: CustomReverseParserParams) => {
  const { allocationScenarios, approaches, cells, fieldAttributes, updateWeightingProbabilities } = params;

  Object.values(cells).forEach(cell => {
    const { alias, data } = getObjectValue(cell);
    const {
      isApproach = false,
      isEnterpriseValue = false,
      isEquityValue = false,
      panelId,
      scenarioId,
      scenarioRef,
    } = getObjectValue(data);

    // Approach
    const approach = getObjectValue(approaches?.find(valuationApproach => valuationApproach?.panelId === panelId));

    // Scenario
    const scenario = getObjectValue(
      (allocationScenarios as NewAllocationScenario[])?.find(allocationScenario => {
        const { id: currentScenarioId, scenario_ref: currentScenarioRef } = getObjectValue(allocationScenario);

        const matchScenarioIds = currentScenarioId && scenarioId && currentScenarioId === scenarioId;
        const matchScenarioRefs = currentScenarioRef && scenarioRef && currentScenarioRef === scenarioRef;

        return matchScenarioIds || matchScenarioRefs;
      })
    );

    // Handle Approaches
    if (isApproach) {
      // Handle Approaches Allocation Scenarios Weights
      handleScenariosWeights({
        approach,
        cell,
        fieldAttributes,
        scenario,
      });

      // Handle Approaches Enterprise Value
      handleEnterpriseValue({
        approach,
        cell,
        isEnterpriseValue,
      });

      // Handle Approaches Equity Value
      handleEquityValue({
        approach,
        cell,
        isEquityValue,
      });
    }

    // Scenario Weighting/Probability
    const scenarioWeightingProbability = getNumberValue(parseCellValue({ cell }));

    const weightingProbability = {
      allocation_scenario_id: scenarioId,
      weight: scenarioWeightingProbability,
    } as WeightingProbability;

    switch (alias) {
      // Handle Scenario Equity Value
      case VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE:
        handleEquityValue({ approach, cell, isEquityValue: true });
        break;

      // Handle Scenario Weighting/Probability
      case VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY:
        handleScenarioWeightingProbability({
          scenario,
          scenarioWeightingProbability: getStringValue(scenarioWeightingProbability?.toString() ?? '0'),
          updateWeightingProbabilities,
          weightingProbability,
        });
        break;

      default:
        break;
    }
  });
};

export default customReverseParser;
