import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { TABLE_SECOND_TITLE, VT_EQUITY_ALLOCATION_RESULTS_ALIAS } from './rowConfig';

export default async ({ columns, rowConfig }: any) => {
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);

  rowConfig.forEach((row: any, rowIdx: any) => {
    columns.forEach((col: any, colIdx: any) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;
      const className = rowNumber === 1 ? 'row-label-title header-no-border' : `${row.className}`;
      const colSpan
        = (row.alias === VT_EQUITY_ALLOCATION_RESULTS_ALIAS || rowNumber === 1) && colIdx === 0 ? columns.length : 1;

      let value = col[row.alias];

      if (row.alias === VT_EQUITY_ALLOCATION_RESULTS_ALIAS) {
        value = '';
      }

      if (rowNumber === 1) {
        value = TABLE_SECOND_TITLE;
      }

      cells = {
        ...cells,
        [key]: {
          ...row,
          key,
          rowSpan: 1,
          columnLegend,
          value,
          className,
          colSpan,
        },
      };
    });
  });

  return cells;
};
