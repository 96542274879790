import { Dispatch, SetStateAction } from 'react';
import { IStandaloneCompaniesPermissions } from 'dashboard409a/states/features/user';
import { getArrayValue } from 'utilities';

const getFilteredCompanies = (
  search: string,
  companies: IStandaloneCompaniesPermissions[] | undefined,
  setFilteredCompaniesList: Dispatch<SetStateAction<IStandaloneCompaniesPermissions[]>>
) => {
  // Filtered list with search data
  if (search.trim()) {
    const searchResults = getArrayValue(
      companies?.filter(company => company.name.toLocaleLowerCase().trim().includes(search.toLocaleLowerCase().trim()))
    );

    setFilteredCompaniesList(searchResults);
  }
  // Full list
  else {
    setFilteredCompaniesList(getArrayValue(companies));
  }
};

export default getFilteredCompanies;
