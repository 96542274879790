import { useCallback } from 'react';
import { ValuationsApproach } from 'common/types/valuation';
import createBacksolveConfig from 'pages/Valuations/approaches/backsolveApproach/createBacksolveConfig';
import createGpcConfig from 'pages/Valuations/approaches/guidelinePublicCompanies/createGpcConfig';
import createPublicTransactionsConfig from 'pages/Valuations/approaches/GuidelineTransactions/createPublicTransactionsConfig';
import createExternalValuationConfiguration from 'pages/ValuationsAllocation/approaches/ExternalValuation/config/createExternalValuationConfiguration';
import createFutureExitConfig from 'pages/ValuationsAllocation/approaches/FutureExit/createFutureExitConfig';
import createSpecifiedShareValuesConfig from 'pages/ValuationsAllocation/approaches/SpecifiedShareValues/createSpecifiedShareValuesConfig';
import {
  VALUATIONS_APPROACH_TYPE_NOT_IMPLEMENTED_MESSAGE,
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_BENCHMARK_APPROACH_TYPES,
  VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH,
  VALUATIONS_EXTERNAL_VALUATION_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  extractSpecificApproachFromApproach,
  FinancialsTransposed,
  getGPCOptions,
} from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { UseMapApproachToConfigParams } from './types';
import createDCFConfig from '../../approaches/DiscountCashFlow/createDCFConfig';

const APPROACH_CONFIGURATION_CREATOR = Object.freeze({
  [VALUATIONS_BACKSOLVE_APPROACH]: createBacksolveConfig,
  [VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH]: createDCFConfig,
  [VALUATIONS_EXTERNAL_VALUATION_APPROACH]: createExternalValuationConfiguration,
  [VALUATIONS_FUTURE_EXIT_APPROACH]: createFutureExitConfig,
  [VALUATIONS_PUBLIC_COMPANIES_APPROACH]: createGpcConfig,
  [VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH]: createPublicTransactionsConfig,
  [VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH]: createSpecifiedShareValuesConfig,
});

const getPotentialsBenchmarkApproaches = (approaches?: ValuationsApproach[]) =>
  approaches?.filter(approach => VALUATIONS_BENCHMARK_APPROACH_TYPES.includes(getStringValue(approach?.approach_type)));

const useMapApproachToConfig = (params: UseMapApproachToConfigParams) => {
  const {
    approaches,
    approachFinancialMap,
    backsolveAttributes,
    capTable,
    capTableVersions,
    companyExchangeRate,
    companyInfo: company,
    compGroups,
    discountedCashFlowAttributes,
    discountedCashFlowProperties,
    externalValuationAttributes,
    financialPeriods,
    format,
    futureExitAttributes,
    guidelinePublicCompaniesAttributes,
    guidelinePublicTransactionsAttributes,
    isValuationDisabled,
    measurementDate,
    performanceMetricsCustomClasses,
    specifiedShareValuesAttributes,
    valuation,
    financials,
  } = params;

  const { allocation_id: allocationId } = getObjectValue(valuation);

  const mapApproachToConfiguration = useCallback(
    currentApproach => {
      const approach = getObjectValue(currentApproach);

      const configCreator
        = APPROACH_CONFIGURATION_CREATOR?.[
          getStringValue(approach?.approach_type) as keyof typeof APPROACH_CONFIGURATION_CREATOR
        ];

      if (!configCreator) {
        throw new Error(VALUATIONS_APPROACH_TYPE_NOT_IMPLEMENTED_MESSAGE);
      }

      const financialStatement
        = approachFinancialMap?.[getStringValue(approach?.panelId ?? approach?.panel_id ?? approach?.id?.toString())]
        ?? ({} as FinancialsTransposed);

      const potentialBenchmarkApproaches = getPotentialsBenchmarkApproaches(approaches);

      const benchmarkApproach = getObjectValue(
        potentialBenchmarkApproaches?.find(
          potentialBenchmark => extractSpecificApproachFromApproach(potentialBenchmark)?.is_benchmark
        )
      );

      const { gpcApproaches, gpcOptions } = getGPCOptions(approaches);

      switch (approach?.approach_type) {
        case VALUATIONS_BACKSOLVE_APPROACH:
          approach.allocationId = getNumberValue(allocationId);

          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_BACKSOLVE_APPROACH]({
            approach,
            approaches,
            backsolveAttributes,
            captable: capTable,
            capTableList: capTableVersions,
            isDisabled: isValuationDisabled,
            measurementDate,
            financials,
          });

        case VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH]({
            approach,
            approaches,
            benchmarkApproach,
            dcfAttributes: discountedCashFlowAttributes,
            dcfProps: discountedCashFlowProperties,
            isDisabled: isValuationDisabled,
          });

        case VALUATIONS_EXTERNAL_VALUATION_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_EXTERNAL_VALUATION_APPROACH]({
            approach,
            externalValueAttrs: externalValuationAttributes,
            isDisabled: isValuationDisabled,
          });

        case VALUATIONS_FUTURE_EXIT_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_FUTURE_EXIT_APPROACH]({
            approach,
            approaches,
            company,
            companyExchangeRate,
            financials: financialStatement,
            futureExitAttributes,
            isDisabled: isValuationDisabled,
            measurementDate,
            futureExitRiskFreeRate: 0,
          });

        case VALUATIONS_PUBLIC_COMPANIES_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_PUBLIC_COMPANIES_APPROACH]({
            approach,
            companyName: getStringValue(company?.name),
            compGroups,
            customClasses: performanceMetricsCustomClasses,
            financials: financialStatement,
            financialsPeriods: financialPeriods,
            fiscalYearEnd: getObjectValue(company?.fiscal_year_end),
            gpcAttributes: getObjectValue(guidelinePublicCompaniesAttributes),
            isDisabled: isValuationDisabled,
            measurementDate,
          });

        case VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH]({
            approach,
            companyInfo: company,
            compGroups,
            financials: financialStatement,
            format,
            gpcApproaches,
            gpcOptions,
            gptAttributes: guidelinePublicTransactionsAttributes,
            isDisabled: isValuationDisabled,
          });

        case VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH:
          return APPROACH_CONFIGURATION_CREATOR[VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH]({
            approach,
            capTableList: capTableVersions ?? [],
            hideEnterpriseValueRow: false,
            isDisabled: Boolean(isValuationDisabled),
            ssvAttributes: specifiedShareValuesAttributes ?? {},
          });

        default:
          return null;
      }
    },
    [
      allocationId,
      approachFinancialMap,
      approaches,
      backsolveAttributes,
      capTable,
      capTableVersions,
      compGroups,
      company,
      companyExchangeRate,
      discountedCashFlowAttributes,
      discountedCashFlowProperties,
      externalValuationAttributes,
      financialPeriods,
      financials,
      format,
      futureExitAttributes,
      guidelinePublicCompaniesAttributes,
      guidelinePublicTransactionsAttributes,
      isValuationDisabled,
      measurementDate,
      performanceMetricsCustomClasses,
      specifiedShareValuesAttributes,
    ]
  );

  return mapApproachToConfiguration;
};

export default useMapApproachToConfig;
