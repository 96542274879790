import { EBITDARevenueParams } from 'pages/ValuationsAllocation/util/updateDCFOptions/types';
import ebitdaRowConfig from './ebitdaRowConfig';
import revenueRowConfig from './revenueRowConfig';
import { RowConfigsProps } from '../../types';
import getColumns from '../../util/getColumns';

const getConfig = ({ columnsProps, ebitda }: RowConfigsProps) => ({
  className: 'multiple-terminal-table',
  rowConfig: ebitda
    ? ebitdaRowConfig(columnsProps as EBITDARevenueParams)
    : revenueRowConfig(columnsProps as EBITDARevenueParams),
  columns: getColumns({
    valuationApproachDCF: columnsProps.valuationApproachDCF,
    dCFFinancialPeriod: columnsProps.dCFFinancialPeriod,
    waccData: columnsProps.waccData,
  }),
});

export default getConfig;
