/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { COMPANY_TYPE, FIRM_TYPE, FUND_TYPE } from 'common/constants/pageTypes';
import { useStore } from 'common/store';
import { FormDialog } from 'components/Dialogs';
import { GridSkeleton } from 'components/Grid';
import ExcelExportContext from 'context/ExcelExportContext';
import LayoutContext from 'context/LayoutContext';
import { useDocuments, useGetInvestorAppReport } from 'services/hooks';
import {
  useGetAllocationsVersionsByCompanyIdByDateId,
  useGetVersionsByCompanyIdByDateId,
  useHasCompanySummary,
} from 'services/hooks/company';
import { useListByMeasurementPeriod } from 'services/hooks/financialStatement';
import { useHasFirmSummary } from 'services/hooks/firm';
import { getFundInvestmentsByMD, getFundSummaryByMD } from 'services/hooks/funds';
import { formatDateView } from 'utilities';
import { DESCRIPTION_TEXT, DOWNLOAD_LABEL, TABLE_HEADER_FIRST_TITLE, TITLE } from './constants';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: '0 1em!important',
    border: '1px solid #eeeeee',
    lineHeight: 1.75,
  },
  tableCellClean: {
    border: 'none!important',
    padding: '0!important',
    lineHeight: 1.75,
  },
  checkBox: {
    padding: '0!important',
  },
  button: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  paper: {
    width: '100%',
    boxShadow: 'none!important',
  },
  tableHeaderCell: {
    padding: '0 1em!important',
    border: '1px solid #eeeeee',
    lineHeight: 1.75,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    backgroundColor: '#ffffff!important',
  },
  descriptionText: {
    marginBottom: '2em',
    marginLeft: '2.5em',
  },
  ledgerTitle: {
    marginBottom: '.5em',
    marginTop: '1em',
    marginLeft: '1.7em',
    fontWeight: 'bold',
  },
  skeletonContainer: {
    marginLeft: '2.5em',
  },
  formulatedSelect: {
    display: 'inline-block',
    float: 'inline-end',
  },
  includeDocumentsAndNotesFromTabs: {
    marginLeft: '6em',
    marginTop: '3em',
    fontSize: '0.5em',
    color: theme.palette.grey[600],
  },
}));

const ExcelExportRow = ({
  mainKey,
  disabled,
  errors,
  checkBoxName,
  labelText,
  checkBoxValue,
  selectValue,
  selectItems,
  onChangeSelect,
  onChangeCheckbox,
  onClickLink,
  noVersionCol,
  noDownloadCol,
}) => {
  const classes = useStyles();
  return (
    <TableRow key={mainKey}>
      <TableCell className={classes.tableCellClean}>
        <Checkbox
          className={classes.checkBox}
          color="primary"
          value={checkBoxValue}
          checked={checkBoxValue}
          name={checkBoxName}
          onChange={onChangeCheckbox}
          disabled={disabled}
        />
      </TableCell>
      <TableCell className={classes.tableCell} colSpan={noVersionCol ? 2 : 1}>
        <InputLabel error={Array.isArray(errors) && errors.length > 0} disabled={disabled}>
          {labelText}
        </InputLabel>
        {Array.isArray(errors)
          && errors.map(error => (
            <FormHelperText key={uuid()} error>
              {error}
            </FormHelperText>
          ))}
      </TableCell>
      {!noVersionCol && (
        <TableCell className={classes.tableCell} align="right">
          <Select
            error={Array.isArray(errors) && errors.length > 0}
            value={selectValue}
            name={mainKey}
            onChange={onChangeSelect}
            disabled={disabled}>
            {selectItems.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>
      )}
      <TableCell className={classes.tableCell} align="right">
        {!noDownloadCol && (
          <Button
            className={classes.button}
            size="small"
            color="primary"
            endIcon={<ArrowDownwardIcon />}
            onClick={onClickLink}
            disabled={disabled}>
            {DOWNLOAD_LABEL}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const ExcelExportDialog = ({ open, onClose }) => {
  const hasCompanySummary = useHasCompanySummary();
  const hasFirmSummary = useHasFirmSummary();
  const { hasDocuments } = useDocuments();
  const beginReportProcess = useGetInvestorAppReport();
  const [exportData, setExportData] = useState({
    has_summary: false,
    has_captables: false,
    has_fo_captables: false,
    has_breakpoint_captables: false,
    has_waterfall_captables: false,
    has_financial_statements: false,
    has_valuations: false,
    has_firm_summary: false,
    has_fund_summary: false,
    has_fund_investments: false,
    has_documents: false,
    captables: [],
    fo_captables: [],
    breakpoint_captables: [],
    waterfall_captables: [],
    financial_statements: [],
    valuations: [],
  });

  const [exportAllCompany, setExportAllCompany] = useState(true);
  const [exportAllFirm, setExportAllFirm] = useState(true);
  const [exportAllFund, setExportAllFund] = useState(true);
  const [formValues, setFormValues] = useState({
    export_not_formulated: false,
    include_documents_and_notes_from_tabs: true,
  });
  const [{ companyInfo, firmInfo, fundInfo }] = useStore();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const getAllocationVersions = useGetAllocationsVersionsByCompanyIdByDateId();
  const [listFinancialByMeasurementDate] = useListByMeasurementPeriod();
  const getCapTableVersions = useGetVersionsByCompanyIdByDateId();
  const { currentMeasurementDate, pageType } = useContext(LayoutContext);
  const { setDoReportPollCheck, setReportRequestData, setReportUUID, fundsByMeasurementDate, currentSelectedValues }
    = useContext(ExcelExportContext);

  const inputsMap = {
    export_cap_table: 'cap_table',
    export_breakpoints: 'breakpoint_cap_table',
    export_waterfall: 'waterfall_cap_table',
    export_fund_ownership: 'fo_cap_table',
    export_financials: 'financial_statement',
    export_valuations: 'valuation',
  };

  const [errors, setErrors] = useState({});
  const [atLeastOneReport, setAtleastOneReport] = useState(false);

  const updateFormValues = useCallback(
    event => {
      const newValues = {
        ...formValues,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      };
      setFormValues(newValues);
      const auxErrors = { ...errors };
      const errorKey = auxErrors[event.target.name] ? event.target.name : inputsMap[event.target.name];
      delete auxErrors[errorKey];
      setErrors(auxErrors);
    },
    [formValues, errors]
  );

  useEffect(() => {
    const {
      export_cap_table,
      export_summary,
      export_breakpoints,
      export_waterfall,
      export_fund_ownership,
      export_financials,
      export_valuations,
      export_firm_summary,
      export_fund_summary,
      export_fund_investments,
      export_documents,
    } = formValues;

    setAtleastOneReport(
      export_cap_table
        || export_summary
        || export_breakpoints
        || export_waterfall
        || export_fund_ownership
        || export_financials
        || export_valuations
        || export_firm_summary
        || export_fund_summary
        || export_fund_investments
        || export_documents
    );
  }, [formValues]);

  const parseDataForDropDowns = (capTableVersions, financialStatementVersion, allocationVersions) => {
    const captables
      = capTableVersions && capTableVersions.length > 0
        ? capTableVersions.map(capTable => ({ ...capTable, is_main: capTable.is_primary }))
        : [];
    const financial_statements
      = financialStatementVersion && financialStatementVersion.length > 0
        ? financialStatementVersion.map(fs => ({
          ...fs,
          is_main: isNull(fs.versioning),
          name: !fs.versioning ? 'Primary Financial Statement' : fs.name,
        }))
        : [];
    const valuations
      = allocationVersions && allocationVersions.length > 0
        ? allocationVersions.map(alloc => ({
          ...alloc,
          id: alloc.valuation,
          is_main: alloc.version === 1,
          name: `Version ${alloc.version} - ${formatDateView(alloc.name)}`,
        }))
        : [];
    return { captables, financial_statements, valuations };
  };

  useEffect(() => {
    const loadData = async () => {
      setShowSkeleton(true);

      if (!open) {
        setShowSkeleton(false);
        return;
      }

      const fetchDataForCompany = async () => {
        const allocationVersions = await getAllocationVersions(companyInfo.id, currentMeasurementDate.id);
        const capTableVersions = await getCapTableVersions(companyInfo.id, currentMeasurementDate.id);
        const financialStatementVersion = await listFinancialByMeasurementDate(currentMeasurementDate.id);
        const has_summary = await hasCompanySummary(companyInfo.id, currentMeasurementDate.id);
        const hasDocumentsData = await hasDocuments(companyInfo.id, currentMeasurementDate.id);
        const { captables, financial_statements, valuations } = parseDataForDropDowns(
          capTableVersions,
          financialStatementVersion,
          allocationVersions
        );

        const exportData = {
          has_summary: has_summary?.has_company_summary,
          has_captables: capTableVersions && capTableVersions.length > 0,
          has_fo_captables: capTableVersions && capTableVersions.length > 0,
          has_breakpoint_captables: capTableVersions && capTableVersions.length > 0,
          has_waterfall_captables: capTableVersions && capTableVersions.length > 0,
          has_financial_statements: financialStatementVersion && financialStatementVersion.length > 0,
          has_valuations: allocationVersions && allocationVersions.length > 0,
          has_documents: hasDocumentsData.has_documents,
          captables,
          fo_captables: captables,
          breakpoint_captables: captables,
          waterfall_captables: captables,
          financial_statements,
          valuations,
        };

        setExportData(exportData);
      };

      if (pageType === COMPANY_TYPE && !isEmpty(currentMeasurementDate)) {
        await fetchDataForCompany();
      } else if (pageType === FUND_TYPE && !isEmpty(currentMeasurementDate)) {
        const fundId = fundInfo.id;
        const measurementDateId = currentMeasurementDate.id;
        const fundSummary = await getFundSummaryByMD({ fundId, measurementDateId });
        const hasFundSummary = !isEmpty(fundSummary?.companies);
        const fundInvestments = await getFundInvestmentsByMD({
          fundId,
          measurementDateId,
          checkForExport: true,
        });
        const hasFundInvestments = !isEmpty(fundInvestments?.companies);
        setExportData({ ...exportData, has_fund_summary: hasFundSummary, has_fund_investments: hasFundInvestments });
      } else if (pageType === FIRM_TYPE) {
        const data = parseFundByMeasurementDateData(fundsByMeasurementDate);
        const hasSummary = await hasFirmSummary(firmInfo.id, data);
        setExportData({ ...exportData, has_firm_summary: hasSummary?.has_summary });
      }

      setShowSkeleton(false);
    };

    loadData();
  }, [open, companyInfo, firmInfo, fundInfo, currentMeasurementDate]);

  useEffect(() => {
    const {
      has_summary,
      has_captables,
      has_fo_captables,
      has_breakpoint_captables,
      has_waterfall_captables,
      has_financial_statements,
      has_valuations,
      has_documents,
      captables,
      fo_captables,
      breakpoint_captables,
      waterfall_captables,
      financial_statements,
      valuations,
      has_firm_summary,
      has_fund_summary,
      has_fund_investments,
    } = exportData;

    const isMain = array => array?.find(element => element.is_main === true)?.id || null;
    const conditionalValue = (has, exportAllorElementId) => {
      if (has) {
        return exportAllorElementId;
      }
      return typeof exportAllorElementId === 'boolean' ? false : null;
    };

    setFormValues({
      ...formValues,
      export_cap_table: conditionalValue(has_captables, exportAllCompany),
      export_summary: conditionalValue(has_summary, exportAllCompany),
      export_breakpoints: conditionalValue(has_breakpoint_captables, exportAllCompany),
      export_waterfall: conditionalValue(has_waterfall_captables, exportAllCompany),
      export_fund_ownership: conditionalValue(has_fo_captables, exportAllCompany),
      export_financials: conditionalValue(has_financial_statements, exportAllCompany),
      export_valuations: conditionalValue(has_valuations, exportAllCompany),
      export_documents: conditionalValue(has_documents, exportAllCompany),
      export_firm_summary: conditionalValue(has_firm_summary, exportAllFirm),
      export_fund_summary: conditionalValue(has_fund_summary, exportAllFund),
      export_fund_investments: conditionalValue(has_fund_investments, exportAllFund),
      cap_table: currentSelectedValues?.cap_table
        ? currentSelectedValues.cap_table
        : conditionalValue(has_captables, isMain(captables)),
      fo_cap_table: currentSelectedValues?.fo_cap_table
        ? currentSelectedValues.fo_cap_table
        : conditionalValue(has_fo_captables, isMain(fo_captables)),
      breakpoint_cap_table: currentSelectedValues?.breakpoint_cap_table
        ? currentSelectedValues.breakpoint_cap_table
        : conditionalValue(has_breakpoint_captables, isMain(breakpoint_captables)),
      waterfall_cap_table: currentSelectedValues?.waterfall_cap_table
        ? currentSelectedValues.waterfall_cap_table
        : conditionalValue(has_waterfall_captables, isMain(waterfall_captables)),
      financial_statement: currentSelectedValues?.financial_statement
        ? currentSelectedValues.financial_statement
        : conditionalValue(has_financial_statements, isMain(financial_statements)),
      valuation: currentSelectedValues?.valuation
        ? currentSelectedValues.valuation
        : conditionalValue(has_valuations, isMain(valuations)),
    });
  }, [exportData]);

  const toggleSelectedReports = useCallback(() => {
    setFormValues({
      ...formValues,
      export_cap_table: exportData.has_captables ? !exportAllCompany : false,
      export_summary: exportData.has_summary ? !exportAllCompany : false,
      export_breakpoints: exportData.has_breakpoint_captables ? !exportAllCompany : false,
      export_waterfall: exportData.has_waterfall_captables ? !exportAllCompany : false,
      export_fund_ownership: exportData.has_fo_captables ? !exportAllCompany : false,
      export_financials: exportData.has_financial_statements ? !exportAllCompany : false,
      export_valuations: exportData.has_valuations ? !exportAllCompany : false,
      export_firm_summary: exportData.has_firm_summary ? !exportAllFirm : false,
      export_fund_summary: exportData.has_fund_summary ? !exportAllFund : false,
      export_fund_investments: exportData.has_fund_investments ? !exportAllFund : false,
      export_documents: exportData.has_documents ? !exportAllCompany : false,
    });
    setExportAllCompany(!exportAllCompany);
    setExportAllFirm(!exportAllFirm);
    setExportAllFund(!exportAllFund);
    setErrors({});
  }, [formValues, exportData, exportAllCompany, exportAllFirm, exportAllFund]);

  const parseFundByMeasurementDateData = fundsByMeasurementDate =>
    fundsByMeasurementDate.map(fundByMeasurementDate => ({
      fund: fundByMeasurementDate.fund.id,
      measurement_date: fundByMeasurementDate.measurementDate?.id,
    }));

  const generateReport = async (reportFormValues, reportName, closeWhenFinish) => {
    const parsedFundByMeasurementDateData
      = pageType === FIRM_TYPE ? parseFundByMeasurementDateData(fundsByMeasurementDate) : [];

    const requestData = {
      ...reportFormValues,
      export_not_formulated: formValues.export_not_formulated,
      company: pageType === COMPANY_TYPE ? companyInfo?.id : null,
      firm: pageType === FIRM_TYPE ? firmInfo?.id : null,
      fund: pageType === FUND_TYPE ? fundInfo?.id : null,
      measurement_date: currentMeasurementDate?.id || null,
      funds_by_measurement_date: parsedFundByMeasurementDateData,
      include_documents_and_notes_from_tabs: formValues.include_documents_and_notes_from_tabs,
    };

    const reportUUID = uuid();

    setReportRequestData(requestData);
    setReportUUID(reportUUID);
    setDoReportPollCheck(true);

    const reportErrors = await beginReportProcess(reportUUID, requestData);
    if (reportErrors) {
      setErrors(reportErrors);
      // stop polling to avoid multiple requests
      setDoReportPollCheck(false);
    } else if (!reportErrors && closeWhenFinish) {
      return false;
    }
    return true;
  };

  const getCustomReportName = customName => `${customName}_${companyInfo.name}_${currentMeasurementDate.slug}`;
  const getCustomFundReportName = customName => `${customName}_${fundInfo.name}_${currentMeasurementDate.slug}`;
  const getCustomFirmReportName = customName => `${customName}_${firmInfo.name}`;
  const generateCompanySummaryReport = useCallback(
    () => generateReport({ export_summary: true }, getCustomReportName('company_summary')),
    [generateReport, getCustomReportName]
  );
  const generateCapTablesReport = useCallback(
    () =>
      generateReport(
        { export_cap_table: true, cap_table: formValues.cap_table },
        getCustomReportName(`cap_table_${formValues.cap_table}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateFundOwnershipReport = useCallback(
    () =>
      generateReport(
        { export_fund_ownership: true, fo_cap_table: formValues.fo_cap_table },
        getCustomReportName(`fund_ownership_cap_table_${formValues.fo_cap_table}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateBreakpointReport = useCallback(
    () =>
      generateReport(
        { export_breakpoints: true, breakpoint_cap_table: formValues.breakpoint_cap_table },
        getCustomReportName(`breakpoints_cap_table_${formValues.capTable}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateWaterfallReport = useCallback(
    () =>
      generateReport(
        { export_waterfall: true, waterfall_cap_table: formValues.waterfall_cap_table },
        getCustomReportName(`waterfall_cap_table_${formValues.capTable}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateFinancialsReport = useCallback(
    () =>
      generateReport(
        { export_financials: true, financial_statement: formValues.financial_statement },
        getCustomReportName(`financials_${formValues.financial_statement}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateValuationsReport = useCallback(
    () =>
      generateReport(
        { export_valuations: true, valuation: formValues.valuation },
        getCustomReportName(`valuation_${formValues.valuation}`)
      ),
    [generateReport, getCustomReportName, formValues]
  );
  const generateFirmSummaryReport = useCallback(
    () => generateReport({ export_firm_summary: true }, getCustomFirmReportName('firm_summary')),
    [generateReport, getCustomFirmReportName, formValues]
  );
  const generateFundSummaryReport = useCallback(
    () => generateReport({ export_fund_summary: true }, getCustomFundReportName('fund_summary')),
    [generateReport, getCustomFundReportName, formValues]
  );
  const generateFundInvestmentsReport = useCallback(
    () => generateReport({ export_fund_investments: true }, getCustomFundReportName('fund_investments')),
    [generateReport, getCustomFundReportName, formValues]
  );
  const generateDocumentsReport = useCallback(
    () => generateReport({ export_documents: true }, getCustomReportName('documents')),
    [generateReport, getCustomReportName, formValues]
  );
  const classes = useStyles();

  const rowsCompanyData = [
    {
      mainKey: 'export_all',
      checkBoxValue: exportAllCompany,
      checkBoxName: 'export_all',
      onChangeCheckbox: toggleSelectedReports,
      disabled: false,
      labelText: 'Entire Company',
      noVersionCol: true,
      noDownloadCol: true,
    },
    {
      mainKey: 'summary',
      checkBoxValue: formValues?.export_summary,
      checkBoxName: 'export_summary',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_summary,
      labelText: 'Summary',
      onClickLink: generateCompanySummaryReport,
      noVersionCol: true,
    },
    {
      mainKey: 'cap_table',
      errors: errors.cap_table,
      checkBoxValue: formValues?.export_cap_table,
      checkBoxName: 'export_cap_table',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_captables,
      labelText: 'Cap Table',
      selectValue: formValues?.cap_table,
      onChangeSelect: updateFormValues,
      selectItems: exportData.captables,
      onClickLink: generateCapTablesReport,
    },
    {
      mainKey: 'fo_cap_table',
      errors: errors.fo_cap_table,
      checkBoxValue: formValues?.export_fund_ownership,
      checkBoxName: 'export_fund_ownership',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_fo_captables,
      labelText: 'Fund Ownership',
      selectValue: formValues?.fo_cap_table,
      onChangeSelect: updateFormValues,
      selectItems: exportData.fo_captables,
      onClickLink: generateFundOwnershipReport,
    },
    {
      mainKey: 'breakpoint_cap_table',
      errors: errors.breakpoint_cap_table,
      checkBoxValue: formValues?.export_breakpoints,
      checkBoxName: 'export_breakpoints',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_breakpoint_captables,
      labelText: 'Breakpoint Analysis',
      selectValue: formValues?.breakpoint_cap_table,
      onChangeSelect: updateFormValues,
      selectItems: exportData.breakpoint_captables,
      onClickLink: generateBreakpointReport,
    },
    {
      mainKey: 'waterfall_cap_table',
      errors: errors.waterfall_cap_table,
      checkBoxValue: formValues?.export_waterfall,
      checkBoxName: 'export_waterfall',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_waterfall_captables,
      labelText: 'Waterfall Model',
      selectValue: formValues?.waterfall_cap_table,
      onChangeSelect: updateFormValues,
      selectItems: exportData.waterfall_captables,
      onClickLink: generateWaterfallReport,
    },
    {
      mainKey: 'financial_statement',
      errors: errors.financial_statement,
      checkBoxValue: formValues?.export_financials,
      checkBoxName: 'export_financials',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_financial_statements,
      labelText: 'Financials',
      selectValue: formValues?.financial_statement,
      onChangeSelect: updateFormValues,
      selectItems: exportData.financial_statements,
      onClickLink: generateFinancialsReport,
    },
    {
      mainKey: 'valuation',
      errors: errors.valuation,
      checkBoxValue: formValues?.export_valuations,
      checkBoxName: 'export_valuations',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_valuations,
      labelText: 'Valuations',
      selectValue: formValues?.valuation,
      onChangeSelect: updateFormValues,
      selectItems: exportData.valuations,
      onClickLink: generateValuationsReport,
    },
    {
      mainKey: 'documents',
      errors: errors.documents,
      checkBoxValue: formValues?.export_documents,
      checkBoxName: 'export_documents',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_documents,
      labelText: 'Documents',
      selectValue: formValues?.documents,
      onChangeSelect: updateFormValues,
      noVersionCol: true,
      onClickLink: generateDocumentsReport,
    },
  ];

  const rowsFirmData = [
    {
      mainKey: 'export_all',
      checkBoxValue: exportAllFirm,
      checkBoxName: 'export_all',
      onChangeCheckbox: toggleSelectedReports,
      disabled: false,
      labelText: 'Entire Firm',
      noVersionCol: true,
      noDownloadCol: true,
    },
    {
      mainKey: 'summary',
      checkBoxValue: formValues?.export_firm_summary,
      checkBoxName: 'export_firm_summary',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_firm_summary,
      labelText: 'Fund Summaries',
      onClickLink: generateFirmSummaryReport,
      noVersionCol: true,
    },
  ];

  const rowsFundData = [
    {
      mainKey: 'export_all',
      checkBoxValue: exportAllFund,
      checkBoxName: 'export_all',
      onChangeCheckbox: toggleSelectedReports,
      disabled: false,
      labelText: 'Entire Fund',
      noVersionCol: true,
      noDownloadCol: true,
    },
    {
      mainKey: 'summary',
      checkBoxValue: formValues?.export_fund_summary,
      checkBoxName: 'export_fund_summary',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_fund_summary,
      labelText: 'Fund Summary',
      onClickLink: generateFundSummaryReport,
      noVersionCol: true,
    },
    {
      mainKey: 'fund_investments',
      checkBoxValue: formValues?.export_fund_investments,
      checkBoxName: 'export_fund_investments',
      onChangeCheckbox: updateFormValues,
      disabled: !exportData.has_fund_investments,
      labelText: 'Schedule of Investments',
      noVersionCol: true,
      onClickLink: generateFundInvestmentsReport,
    },
  ];

  const toggleIncludeDocumentsAndNotesFromTabs = useCallback(() => {
    setFormValues({
      ...formValues,
      include_documents_and_notes_from_tabs: !formValues.include_documents_and_notes_from_tabs,
    });
  }, [formValues]);

  const rowsData = {
    [COMPANY_TYPE]: rowsCompanyData,
    [FUND_TYPE]: rowsFundData,
    [FIRM_TYPE]: rowsFirmData,
  };
  return (
    <FormDialog
      open={open}
      id="excel-export-dialog"
      onClose={useCallback(
        checkErrors => {
          if (checkErrors) {
            return;
          }
          setErrors({});
          onClose();
        },
        [onClose]
      )}
      onSave={useCallback(() => {
        if (pageType === COMPANY_TYPE && currentMeasurementDate) {
          return generateReport(formValues, getCustomReportName('company_'), true);
        }
        if (pageType === FUND_TYPE && currentMeasurementDate) {
          return generateReport(formValues, getCustomFundReportName('fund_'), true);
        }
        if (pageType === FIRM_TYPE) {
          return generateReport(formValues, getCustomFirmReportName('firm_'), true);
        }
      }, [generateReport, getCustomReportName, getCustomFirmReportName, formValues, pageType, currentMeasurementDate])}
      isValid={isEmpty(errors) && atLeastOneReport}
      customButtonLabel="Export Selected"
      bigDialog
      hideTitle>
      <>
        <Typography variant="h2" color="primary" className={classes.ledgerTitle}>
          {TITLE}
        </Typography>
        <Typography variant="body1" className={classes.descriptionText}>
          {DESCRIPTION_TEXT}
          <Select
            className={classes.formulatedSelect}
            value={formValues.export_not_formulated}
            name="export_not_formulated"
            onChange={updateFormValues}>
            <MenuItem value={false}>Formulated</MenuItem>
            <MenuItem value>Hard Coded</MenuItem>
          </Select>
        </Typography>
        <Paper className={classes.paper}>
          {showSkeleton ? (
            <div className={classes.skeletonContainer}>
              <GridSkeleton maxRows={8} maxColumns={5} />
            </div>
          ) : (
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellClean} />
                    <TableCell className={classes.tableHeaderCell} colSpan={2}>
                      {TABLE_HEADER_FIRST_TITLE[pageType]}
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell} align="center">
                      {DOWNLOAD_LABEL}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsData[pageType].map(props => (
                    <ExcelExportRow key={uuid()} {...props} />
                  ))}
                </TableBody>
              </Table>
              {pageType === COMPANY_TYPE && (
                <FormControl component="fieldset" className={classes.includeDocumentsAndNotesFromTabs}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="include-documents-and-notes-from-tabs"
                          className={classes.checkBox}
                          color="primary"
                          size="small"
                          checked={formValues.include_documents_and_notes_from_tabs}
                          onChange={toggleIncludeDocumentsAndNotesFromTabs}
                        />
                      }
                      label="Include documents and notes from tabs?"
                    />
                  </FormGroup>
                </FormControl>
              )}
            </TableContainer>
          )}
        </Paper>
      </>
    </FormDialog>
  );
};

ExcelExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

ExcelExportRow.propTypes = {
  mainKey: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  checkBoxName: PropTypes.string,
  labelText: PropTypes.string,
  checkBoxValue: PropTypes.bool,
  selectValue: PropTypes.number,
  selectItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any).isRequired),
  onChangeSelect: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickLink: PropTypes.func,
  noVersionCol: PropTypes.bool,
  noDownloadCol: PropTypes.bool,
};

export default ExcelExportDialog;
