/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { flowRight, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { GET_ENTER_DATA } from 'common/constants/inputs';
import RowLabelTooltip from 'components/RowLabelTooltip/RowLabelTooltip';
import { useRevealEllipsisInTooltip } from 'services/hooks';
import { formatNumbers, toString } from 'utilities';
import withAction from '../../WithAction/withAction';
import withDialog from '../../WithDialog/withDialog';
import withLink from '../../WithLink/withLink';
import withRequired from '../../WithRequired/withRequired';
import withTooltip from '../../WithTooltip/withTooltip';

const ValueViewer = ({ cell, selected, onDoubleClick }) => {
  const { tooltipRef, width } = useRevealEllipsisInTooltip(cell.width);

  const keyUpHandler = event => {
    const key = event.key || event.keyCode;
    if (selected && key && key.toUpperCase() === 'F2') {
      onDoubleClick();
    }
  };

  const spanId = useMemo(() => {
    if (cell.extraAttr) {
      return `${cell.extraAttr}-${cell.alias}-${cell.key}_viewer`;
    }

    return `${cell.alias}-${cell.key}_viewer`;
  }, [cell]);

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);
    return () => document.removeEventListener('keyup', keyUpHandler);
  }, [selected]);

  const value = useMemo(() => {
    if (
      ((!isUndefined(cell.value) && !cell.hidden) || cell.value)
      && cell.gridType
      && (cell.gridType === 'number' || cell.gridType === 'percentage')
      // eslint-disable-next-line no-restricted-globals
      && !isNaN(cell.value)
    ) {
      if (!cell.readOnly && !toString(cell.value).length) {
        return cell.enterDataMessage || GET_ENTER_DATA(cell.isRequired, cell.enterDataMessage);
      }
      // Override number of decimals and display the entire number to highlight the errors
      if (cell.allowDecimals === false && cell.tooltipMessages?.length > 0) return cell.value;
      return formatNumbers(cell);
    }
    if (!cell.readOnly && !cell.hidden && !cell.value && cell.placeholder) {
      return cell.placeholder;
    }

    return cell.value;
  }, [cell]);

  const valueOrNA = useMemo(() => (cell.value !== null ? value : 'N/A'), [cell, value]);

  if (
    (['title', 'approachName'].includes(cell.colAlias) || cell.className?.includes('table-header'))
    && !cell.defaultTooltip
  ) {
    return (
      <span className="value-viewer" ref={tooltipRef}>
        <RowLabelTooltip title={value} containerWidth={width}>
          <span id={spanId} className={`text-cell ${cell.className}`}>
            {value}
          </span>
        </RowLabelTooltip>
      </span>
    );
  }

  return (
    <span className="value-viewer">
      <span id={spanId} className={`text-cell ${cell.className}`}>
        {!cell.displayNAforNull ? value : valueOrNA}
      </span>
    </span>
  );
};

ValueViewer.propTypes = {
  value: PropTypes.any,
  cell: PropTypes.object,
  selected: PropTypes.bool,
  onDoubleClick: PropTypes.func,
};

// flowRight is a compose method alternative
const enhance = flowRight(withTooltip, withDialog, withRequired, withLink, withAction);

export default enhance(ValueViewer);
