import React, { FC } from 'react';
import { Avatar, Button, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { getInitials } from 'utilities';
import { useStyles } from './styles';
import { IBottomMenuItem, IIconButtonOnClickEvent } from './types';

const ITEM_HEIGHT = 350;

const BottomMenuItem: FC<IBottomMenuItem> = props => {
  const { item, anchorElement, setAnchorElement } = props;
  const classes = useStyles();

  const handleMenuClick = (event: IIconButtonOnClickEvent<HTMLSpanElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <ListItem>
        <ListItemIcon>
          {item.imageUrl || item?.displayAvatar ? (
            <Avatar className={classes.avatar} src={item.imageUrl} />
          ) : (
            <Avatar className={classes.avatar}>{getInitials({ names: item.primaryText })}</Avatar>
          )}
        </ListItemIcon>

        <ListItemText
          primary={item.primaryText}
          secondary={
            item?.isLink ? (
              <Link className={classes.secondaryLink} to={item.slug}>
                {item.secondaryText}
              </Link>
            ) : (
              item?.handleButtonClick && (
                <Button className={classes.secondaryLink} onClick={item.handleButtonClick} type="button">
                  {item.secondaryText}
                </Button>
              )
            )
          }
          primaryTypographyProps={{ className: classes.bottomMenuItemPrimaryText }}
          secondaryTypographyProps={{ className: classes.bottomMenuItemSecondaryText }}
        />

        {item.subItems && item.subItems.length > 0 && (
          <IconButton
            aria-controls={item.slug}
            aria-haspopup="true"
            aria-label="more"
            component="span"
            id={`${item.slug}-button`}
            onClick={handleMenuClick}>
            <ArrowRight className={classes.bottomMenuItemSubMenuIcon} fontSize="small" />
          </IconButton>
        )}
      </ListItem>

      {/* Submenu */}
      {item.subItems && item.subItems.length > 0 && (
        <Menu
          id={`sidebar-bottom-dropdown-${item.slug}`}
          anchorEl={anchorElement}
          open={anchorElement ? anchorElement.getAttribute('aria-controls') === item.slug : false}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT,
            },
            square: true,
          }}>
          {item.subItemsLabel && <MenuItem className={classes.menuItemsLabel}>{item.subItemsLabel}</MenuItem>}

          {item.subItems.map(option => (
            <div key={`subitem-${option.slug}`}>
              <MenuItem
                key={option.slug}
                onClick={() => {
                  option.handleItemClick();
                  handleMenuClose();
                  // TODO: Close sidebar
                }}
                className={classes.menuItem}>
                {option?.icon && option.icon} {option.title}
              </MenuItem>
            </div>
          ))}
        </Menu>
      )}
    </>
  );
};

export default BottomMenuItem;
