import { isUndefined } from 'lodash';
import { getKey, onCellsChanged } from 'utilities';

export default async (ledgerData = [], tableId = '', colConfig = {}) => {
  const changes = [];
  let cells = {};

  function getExpr(col, rowNumber) {
    return col.expr ? col.expr.replace(/\{\{rowNumber\}\}/g, rowNumber) : '';
  }

  ledgerData.forEach((row, rowIdx) => {
    colConfig.forEach((col, colIdx) => {
      const key = getKey(col.alias, rowIdx, tableId);
      // Get the value from the table cells
      const ledgerCellValue = ledgerData[rowIdx][col.alias];
      const value = !isUndefined(ledgerCellValue) ? ledgerCellValue : null;

      const expr = getExpr(col, rowIdx);

      const cell = {
        ...col,
        expr,
        key,
        alias: col.alias,
        rowAlias: row.alias || '',
        colAlias: col.alias || '',
        insideLedger: true,
        rowNumber: rowIdx,
        colNumber: colIdx,
        tableId,
        value,
      };

      cells[key] = cell;
      changes.push({ cell, value });
    });
  });

  cells = await onCellsChanged(changes, cells);

  return cells;
};
