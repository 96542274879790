/* eslint-disable no-param-reassign */
import { isNull } from 'lodash';
import moment from 'moment';
import { EQUAL_OR_GREATER_THAN } from 'common/constants/messages/validations';
import { addClassName, removeClassName } from 'utilities';
import {
  ACQUISITION_REF,
  AMOUNT_ALIAS,
  CONVERSION_DATE_ALIAS,
  PURCHASE_DATE_ALIAS,
  SALE_DATE_ALIAS,
} from './constants';

const conditions = ({ cell, tableData, tableCells }) => {
  const DATE_CONSISTENCY_WARNING_MESSAGE = 'Date is previous to first purchase date.';

  const { alias, value, className, sourceTable } = cell;
  const tooltipMessages = cell?.tooltipMessages || [];
  const purchasedInfo = tableData?.acquisitions.map(obj => obj.purchase_date) || [];

  const addTooltipMessage = (messageToAdd, classToAdd) => {
    if (!(tooltipMessages || []).includes(messageToAdd)) {
      tooltipMessages.push(messageToAdd);
    }

    tableCells[cell.key] = {
      ...cell,
      className: addClassName(className, classToAdd),
      tooltipMessages,
    };
  };

  const removeTooltipMessage = (messageToDelete, classToRemove) => {
    if (tooltipMessages.includes(messageToDelete)) {
      const index = tooltipMessages.findIndex(message => message === messageToDelete);
      tooltipMessages.splice(index, 1);
    }

    tableCells[cell.key] = {
      ...cell,
      className: removeClassName(className, classToRemove),
      tooltipMessages,
    };
  };

  const getEarliestPurchaseDate = dates => {
    const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
    return sortedDates[0];
  };

  if (
    [CONVERSION_DATE_ALIAS, SALE_DATE_ALIAS].includes(cell.alias)
    && !isNull(value)
    && purchasedInfo.length
    && sourceTable !== 'purchased'
  ) {
    const analyzedDate = moment(new Date(value));
    const firstPurchaseDate = moment(getEarliestPurchaseDate(purchasedInfo));
    const isBeforePurchaseDate = analyzedDate.isBefore(firstPurchaseDate);

    if (isBeforePurchaseDate) {
      addTooltipMessage(DATE_CONSISTENCY_WARNING_MESSAGE, 'warning');
    } else {
      removeTooltipMessage(DATE_CONSISTENCY_WARNING_MESSAGE, 'warning');
    }
  }

  if ([AMOUNT_ALIAS].includes(alias)) {
    if (Number(value) < 0) {
      addTooltipMessage(EQUAL_OR_GREATER_THAN(0), 'warning');
    } else {
      removeTooltipMessage(EQUAL_OR_GREATER_THAN(0), 'warning');
    }
  }

  if (cell.alias === PURCHASE_DATE_ALIAS && sourceTable !== 'purchased') {
    const sameRowCells = Object.values(tableCells).filter(
      c => c.rowNumber === cell.rowNumber && ![ACQUISITION_REF, PURCHASE_DATE_ALIAS].includes(c.alias)
    );
    sameRowCells.forEach(c => {
      c.acquisition_ref = cell.acquisition_ref;
    });
  }

  return cell;
};

export default conditions;
