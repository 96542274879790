import { getSlugValue } from 'utilities';

export const VALUATION_SUMMARY_KEY = 'summary';
export const VALUATION_SUMMARY_LABEL = 'Valuation Summary';
export const VALUATION_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX = 'approach';
export const VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME = 'Column';
export const VALUATION_SUMMARY_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const VALUATION_SUMMARY_SPREADSHEET_TITLE = VALUATION_SUMMARY_LABEL;
export const VALUATION_SUMMARY_SPREADSHEET_SLUG = getSlugValue(VALUATION_SUMMARY_SPREADSHEET_TITLE);
export const VALUATION_SUMMARY_SPREADSHEET_TABLE = `${VALUATION_SUMMARY_SPREADSHEET_SLUG}-table`;
