import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { isArray, isEmpty, isUndefined, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import useDataSheetDialogActions from 'common/hooks/useDataSheetDialogActions';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { CellReferenceBar } from 'components/FeaturedSpreadsheet/components';
import RowIndicator from 'components/FeaturedSpreadsheet/components/RowIndicator';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL } from 'components/FeaturedSpreadsheet/constants';
import CurrencyChipContext from 'components/FeaturedSpreadsheet/context/CurrencyChipContext';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { GridSkeleton } from 'components/Grid';
import { ConfirmUnsavedChanges } from 'components/Spreadsheet/components';
import { DialogContext } from 'context';
import UnsavedChanges from 'context/UnsavedChanges';
import { getNumberValue } from 'utilities';
import getCancelDialogOptions from 'utilities/getCancelDialogOptions';
import { ContentColumns, TitlesColumn, TotalsColumn } from './components';
import WorkbookContext from './utilities/WorkbookContext';

const GET_SIDE_COLUMNS_DELAY = 100;

const useStyles = makeStyles({
  relative: {
    position: 'relative',
  },
});

// cells, columns, rowconfig, and classname al come from the sheetname
const ScalarSpreadsheet = ({
  addMultipleColumns,
  addMultipleRows,
  addSingleRow,
  allowAddMultipleColumns,
  allowAddMultipleRows = false,
  allowAddSingleRow = false,
  allowChangeDataType = false,
  allowCloneColumn,
  allowConfirmAndDeleteColumn,
  allowCopyColumn = false,
  allowCopyRows = false,
  allowDeleteColumn = false,
  allowDeleteOnlySpecificColumn,
  allowDeleteRow,
  allowReorderColumns,
  allowSortColumn = false,
  alwaysDisplayLegend = true,
  cells,
  changeRowDataTypeFn,
  children,
  className: customClassName,
  cloneColumn,
  collapsibleColumns,
  columns,
  customDeleteConfirmation,
  data,
  deleteColumn,
  deleteRowFn,
  disabled,
  disableDeleteBtnOnFirstColumn = false,
  disableVerticalScroll,
  displayColumnIndicator = true,
  displayDeleteRowBtn = false,
  displayRowIndicator = true,
  editorForTitles = undefined,
  fieldAttributes,
  format,
  formatDispatch,
  id,
  ignoreCurrencyChangeNumber,
  initialCurrencyType,
  isLedgerTable = false,
  isReadOnly = false,
  isToolbarVisible,
  linkCurrencyChips,
  matchCurrencyPadding,
  notShowingQuarters = false,
  onChange,
  onTitleCellsChanges,
  page,
  referencedValues,
  reverseParser,
  rowConfig,
  rowGroups,
  setCollapsibleColumns,
  setColumns,
  setIsDisplayingRowNumbers,
  setIsDisplayingToolbar = undefined,
  setRowGroups,
  setSpreadsheetWidth = undefined,
  setTitleCells,
  sheet,
  showPreviousColsDivider,
  showTitlesColumn = true,
  showToolbar,
  showTotalColumn,
  sortColumnFn = undefined,
  sortedColumn = undefined,
  tableData,
  tableTerms,
  titleData,
  toggleRows,
  totalData,
  totalsCells,
  updateCells,
  updateTotalsCells,
  validatedTitleCells,
  workbook,
}) => {
  const classes = useStyles();

  const [grid, setGrid] = useState();
  const [totalGrid, setTotalGrid] = useState();
  const [titleGrid, setTitleGrid] = useState();
  const [rows, setRows] = useState();
  const [sideColumnsWidth, setSideColumnsWidth] = useState();
  const [activeCell, setActiveCell] = useState();
  const [displayNumbersAndColumns, setDisplayNumbersAndColumns] = useState(alwaysDisplayLegend);
  const [toolbarWidth, setToolbarWidth] = useState();
  const { globalCurrency } = useContext(CurrencyChipContext);
  const [currency, setCurrency] = useState(linkCurrencyChips && globalCurrency ? globalCurrency : initialCurrencyType);
  const { clearSelectedFlag, setClearSelected } = useDataSheetDialogActions(isLedgerTable);
  const [selectedCell, setSelectedCell] = useState();
  const { setAction } = useContext(UnsavedChanges);
  const { workbookActiveCell, updateWorkbookActiveCell } = useContext(WorkbookContext);
  const [isSheetActive, setIsSheetActive] = useState(false);

  const enableUnsavedChanges = useCallback(() => {
    setAction({
      wrapper: ConfirmationDialog,
      content: ConfirmUnsavedChanges,
    });
  }, [setAction]);

  const checkChanges = useCallback(
    changes => {
      // check if the cell has changed compared to the original value
      if (changes.length > 0) {
        enableUnsavedChanges();
      } else {
        setAction(false);
      }
    },
    [enableUnsavedChanges, setAction]
  );

  const className = customClassName ?? sheet.name;

  const dialogContext = useContext(DialogContext);

  const tableRef = useRef();
  const titlesColumnRef = useRef();
  const totalsColumnRef = useRef();
  const contentColumnRef = useRef();

  const handleDeleteColumn = useCallback(
    (columnIndex, columnId, wb, cb) => {
      if (allowConfirmAndDeleteColumn && !customDeleteConfirmation) {
        const { showDialog } = dialogContext;
        const ContentComponent = <ConfirmationDelete itemName={tableTerms.columnName} />;

        showDialog(
          getCancelDialogOptions(
            () => ContentComponent,
            () => deleteColumn(columnIndex, columnId, wb, cb)
          )
        );
      } else {
        deleteColumn(columnIndex, columnId, wb, cb);
      }
      enableUnsavedChanges();
    },
    [
      allowConfirmAndDeleteColumn,
      customDeleteConfirmation,
      deleteColumn,
      dialogContext,
      enableUnsavedChanges,
      tableTerms.columnName,
    ]
  );

  const displayLegend = useMemo(
    () => displayNumbersAndColumns || (showToolbar && !isEmpty(activeCell)),
    [activeCell, displayNumbersAndColumns, showToolbar]
  );

  const generateLegendRow = useCallback(() => {
    const legendRow = [];
    const { alphabet } = sheet;
    const totalColumns = sheet.columns.length;
    const sortedColumns = sortBy(columns, ['order']);
    if (columns) {
      for (let colIndex = 0; colIndex < totalColumns; colIndex += 1) {
        const colLegend = alphabet[colIndex];
        const column = sortedColumns[colIndex];
        const columnId = column.id || 0;
        const columnRef = cells?.[colLegend + 1] ? cells[colLegend + 1].columnRef : '';
        const isLTMOrNTMColumnLegend = column?.isLTM || column?.isNTM;

        const isVisibleColumn = column.isAdditionalHistoricalYear
          ? column.isAdditionalHistoricalYear && column.showAdditionalHistoricalYear
          : null;
        const legendCell = {
          readOnly: true,
          className: `legend col-legend ${isLTMOrNTMColumnLegend ? 'ltm-or-ntm-legend' : ''}`,
          value: colLegend,
          columnId,
          colLegend,
          columnRef,
          disableEvents: true,
          isChildWithDivider: column.isChildWithDivider,
          isParentWithDivider: column.isParentWithDivider,
          isParent: column.isParent,
          childIndex: column.quarter,
          parentColumn: column.parentColumn,
          isVisibleColumn,
          isLegend: true,
        };

        legendRow.push(legendCell);
      }
      return legendRow;
    }
  }, [cells, columns, sheet]);

  const legendRow = useMemo(() => generateLegendRow(), [generateLegendRow]);

  const getClassnamesForLegends = useCallback(
    classesString => (isToolbarVisible || !displayLegend ? `${classesString} hidden-legend` : classesString),
    [displayLegend, isToolbarVisible]
  );

  const rowLegendCell = useMemo(
    () => ({
      value: '',
      readOnly: true,
      className: 'legend row-legend col-legend cell read-only',
    }),
    []
  );

  const defaultLegendCell = useMemo(
    () => ({
      ...rowLegendCell,
      className: getClassnamesForLegends('legend col-legend'),
    }),
    [rowLegendCell, getClassnamesForLegends]
  );

  const headerLegendRow = useMemo(() => {
    if (displayNumbersAndColumns) {
      const headerRow = [rowLegendCell];
      if (showTitlesColumn) {
        headerRow.push(defaultLegendCell);
      }
      return headerRow;
    }
    return [defaultLegendCell];
  }, [showTitlesColumn, displayNumbersAndColumns, defaultLegendCell, rowLegendCell]);

  const filterData = useCallback(
    gridData =>
      gridData.filter(
        rowData => rowData.filter(({ isVisible }) => isVisible === true || isUndefined(isVisible)).length > 0
      ),
    []
  );

  const generateGrid = useCallback(
    (titleData, tmpData, totalData) => {
      const titleGridData = filterData(titleData).map(titlesCell => {
        const legendCell = titlesCell[0];
        const titleCell = titlesCell[1];
        const gridCells = [];
        if (titleCell && showTitlesColumn) {
          gridCells.push(titleCell);
        }
        if (legendCell && displayNumbersAndColumns) {
          gridCells.unshift(legendCell);
        }
        return gridCells;
      });
      const gridData = filterData(tmpData);
      const totalGridData = filterData(totalData);

      if (!isToolbarVisible) {
        titleGridData.unshift(headerLegendRow);
        gridData.unshift(legendRow);
        totalGridData.unshift([defaultLegendCell]);
      }

      setTitleGrid(titleGridData);
      setGrid(gridData);
      setTotalGrid(totalGridData);
    },
    [
      showTitlesColumn,
      isToolbarVisible,
      displayNumbersAndColumns,
      legendRow,
      headerLegendRow,
      defaultLegendCell,
      filterData,
    ]
  );

  useEffect(() => {
    if (sheet.data && sheet.totalData) {
      generateGrid(sheet.titleData, sheet.data, sheet.totalData);
    } else {
      setGrid(null);
      setTotalGrid(null);
      setTitleGrid(null);
    }
  }, [cells, data, titleData, totalData, collapsibleColumns, rowGroups, disabled, sheet, generateGrid]);

  useEffect(() => {
    if (linkCurrencyChips && globalCurrency) {
      setCurrency(globalCurrency);
    }
  }, [globalCurrency, linkCurrencyChips]);

  useEffect(() => {
    const contentColumns = contentColumnRef?.current;

    const spreadsheetWidth = getNumberValue(sideColumnsWidth) + getNumberValue(contentColumns?.offsetWidth);
    setToolbarWidth(spreadsheetWidth);
    setSpreadsheetWidth?.(spreadsheetWidth);
  }, [sideColumnsWidth, collapsibleColumns, setSpreadsheetWidth]);

  useEffect(() => {
    if (isArray(rowConfig)) {
      const visibleRows = rowConfig.filter(({ isVisible }) => isVisible !== false);
      setRows(visibleRows);
    }
  }, [rowConfig]);

  useEffect(() => {
    setIsDisplayingToolbar?.(Boolean(showToolbar && !isEmpty(activeCell)));
  }, [activeCell, setIsDisplayingToolbar, showToolbar]);

  useLayoutEffect(() => {
    let getSideColumnsTimer;

    if (titleGrid && totalGrid) {
      getSideColumnsTimer = setTimeout(() => {
        const titlesWidth = getNumberValue(titlesColumnRef.current?.offsetWidth);
        const totalsWidth = getNumberValue(totalsColumnRef.current?.offsetWidth);

        setSideColumnsWidth(titlesWidth + totalsWidth);
      }, GET_SIDE_COLUMNS_DELAY);
    }

    return () => clearTimeout(getSideColumnsTimer);
  }, [titleGrid, totalGrid]);

  const computeCellsChanges = useCallback(
    changes => {
      checkChanges(changes.filter(change => !change.cell.ignoreExpr));
      changes.forEach(({ cell, value }) => {
        onChange(cell, value);
      });
      if (toggleRows) {
        toggleRows();
      }
    },
    [checkChanges, onChange, toggleRows]
  );

  const decoratedReverseParser = useCallback(
    (cells, columns) => {
      if (reverseParser) {
        return reverseParser({ cells, columns, fieldAttributes });
      }
    },
    [reverseParser, fieldAttributes]
  );

  // update the workbooks active cell if we can. The workbook will highlight the referenced cells.
  useEffect(() => {
    if (updateWorkbookActiveCell) {
      updateWorkbookActiveCell(activeCell);
    }
  }, [activeCell, updateWorkbookActiveCell]);

  // inactive sheets won't display the cell reference bar. Should prevent confusion about which cells are actively being referenced
  useEffect(() => {
    const workbookActiveCellSheetName = workbookActiveCell?.cellInfo?.sheet?.name || workbookActiveCell?.sheet?.name;
    const activeCellSheetName = activeCell?.sheet?.name;
    if (!(activeCellSheetName && workbookActiveCellSheetName)) {
      setIsSheetActive(false);
    } else if (activeCellSheetName !== workbookActiveCellSheetName) {
      setIsSheetActive(false);
    } else {
      setIsSheetActive(true);
    }
  }, [workbookActiveCell, activeCell]);

  const contextValue = useMemo(
    () => ({
      rows,
      columns,
      cells,
      totalsCells,
      updateCells,
      updateTotalsCells,
      tableTerms,
      tableData,
      rowGroups,
      setRowGroups,
      titlesColumnRef,
      totalsColumnRef,
      onCellsChanged: computeCellsChanges,
      setColumns,
      allowReorderColumns,
      disabled,
      activeCell,
      setActiveCell,
      displayNumbersAndColumns,
      setDisplayNumbersAndColumns,
      deleteRowFn,
      setCollapsibleColumns,
      collapsibleColumns,
      currency,
      setCurrency,
      ignoreCurrencyChangeNumber,
      showPreviousColsDivider,
      onTitleCellsChanges,
      setTitleCells,
      validatedTitleCells,
      workbook,
      page,
      format,
      formatDispatch,
      getClassnamesForLegends,
      clearSelectedFlag,
      setClearSelected,
      displayLegend,
      isToolbarVisible,
      displayDeleteRowBtn,
      reverseParser: decoratedReverseParser,
      notShowingQuarters,
      selectedCell,
      setSelectedCell,
      allowConfirmAndDeleteColumn,
      allowDeleteOnlySpecificColumn,
      displayRowIndicator,
      displayColumnIndicator,
      allowCopyColumn,
      allowDeleteColumn,
      handleDeleteColumn,
      disableDeleteBtnOnFirstColumn,
      allowCopyRows,
      allowCloneColumn,
      cloneColumn,
      allowAddMultipleRows,
      addMultipleRows,
      allowAddMultipleColumns,
      addMultipleColumns,
      allowAddSingleRow,
      addSingleRow,
      showToolbar,
      allowDeleteRow,
      isReadOnly,
      allowChangeDataType,
      changeRowDataTypeFn,
      allowSortColumn,
      sortColumnFn,
      sortedColumn,
    }),
    [
      activeCell,
      allowReorderColumns,
      cells,
      clearSelectedFlag,
      collapsibleColumns,
      columns,
      computeCellsChanges,
      currency,
      decoratedReverseParser,
      deleteRowFn,
      disabled,
      displayDeleteRowBtn,
      displayLegend,
      format,
      formatDispatch,
      getClassnamesForLegends,
      ignoreCurrencyChangeNumber,
      isToolbarVisible,
      onTitleCellsChanges,
      page,
      rowGroups,
      rows,
      setClearSelected,
      setCollapsibleColumns,
      setColumns,
      setRowGroups,
      setTitleCells,
      showPreviousColsDivider,
      tableData,
      tableTerms,
      totalsCells,
      updateCells,
      updateTotalsCells,
      validatedTitleCells,
      workbook,
      displayNumbersAndColumns,
      notShowingQuarters,
      selectedCell,
      setSelectedCell,
      allowConfirmAndDeleteColumn,
      allowDeleteOnlySpecificColumn,
      displayRowIndicator,
      displayColumnIndicator,
      allowCopyColumn,
      allowDeleteColumn,
      handleDeleteColumn,
      disableDeleteBtnOnFirstColumn,
      allowCopyRows,
      allowCloneColumn,
      cloneColumn,
      allowAddMultipleRows,
      addMultipleRows,
      allowAddMultipleColumns,
      addMultipleColumns,
      allowAddSingleRow,
      addSingleRow,
      showToolbar,
      allowDeleteRow,
      isReadOnly,
      allowChangeDataType,
      changeRowDataTypeFn,
      allowSortColumn,
      sortColumnFn,
      sortedColumn,
    ]
  );

  if (setIsDisplayingRowNumbers) {
    setIsDisplayingRowNumbers(alwaysDisplayLegend);
  }

  if (isUndefined(columns) || (!isEmpty(columns) && !grid && !totalGrid)) {
    return <GridSkeleton />;
  }

  return (
    <div
      data-testid={tableTerms.tableSlug}
      className={`${tableTerms.tableSlug}-sc-spreadsheet ${classes.relative} spreadsheet-container`}
      id={id}>
      {children}
      {!isEmpty(columns)
        && !isEmpty(data)
        && !isEmpty(grid)
        && ((showTotalColumn && !isEmpty(totalGrid)) || !showTotalColumn) && (
        <FeaturedSpreadsheetContext.Provider value={contextValue}>
          {showToolbar && isSheetActive && !isEmpty(activeCell) && (
            <div style={{ width: toolbarWidth }}>
              <CellReferenceBar
                id={tableTerms.tableSlug}
                cell={activeCell}
                referencedValues={referencedValues}
                matchCurrencyPadding={matchCurrencyPadding}
              />
            </div>
          )}
          <Box id={`spreadsheet-table__${tableTerms.tableSlug}`} ref={tableRef} display="flex">
            <div ref={titlesColumnRef} id="spreadsheet-table__title-columns" className="titles-columns-container">
              <RowIndicator tableRef={tableRef} />
              <TitlesColumn
                data={titleGrid}
                className={className}
                currency={format?.currency}
                dataEditor={editorForTitles}
                tableSlug={tableTerms.tableSlug}
              />
            </div>
            <div
              className={classes.relative}
              style={{
                maxWidth: `calc(100% - ${sideColumnsWidth}px)`,
              }}
              id="spreadsheet-table__content-columns"
              ref={contentColumnRef}>
              <ContentColumns
                data={grid}
                className={className}
                tableSlug={tableTerms.tableSlug}
                isLedgerTable={isLedgerTable}
                disableVerticalScroll={disableVerticalScroll}
              />
            </div>
            <div ref={totalsColumnRef} id="spreadsheet-table__total-column" className="total-columns-container">
              {showTotalColumn && <TotalsColumn data={totalGrid} className={className} currency={format.currency} />}
            </div>
          </Box>
        </FeaturedSpreadsheetContext.Provider>
      )}
    </div>
  );
};

ScalarSpreadsheet.defaultProps = {
  allowCloneColumn: false,
  allowConfirmAndDeleteColumn: true,
  allowDeleteOnlySpecificColumn: false,
  className: '',
  colTitleRow: 0,
  customDeleteConfirmation: false,
  disabled: false,
  disableDeleteBtnOnFirstColumn: false,
  enableColumnReordering: false,
  hasColTitle: true,
  ignoreCurrencyChangeNumber: 0,
  initialCurrencyType: `${DEFAULT_CURRENCY} ${DEFAULT_CURRENCY_SYMBOL}`,
  isToolbarVisible: false,
  referencedValues: {},
  rowConfig: [],
  showPreviousColsDivider: false,
  showTotalColumn: true,
  tableTerms: {
    tableName: 'Table',
    columnName: 'Column',
    pluralColumnName: 'Columns',
  },
};

ScalarSpreadsheet.propTypes = {
  addMultipleColumns: PropTypes.func,
  addMultipleRows: PropTypes.func,
  addSingleRow: PropTypes.func,
  afterCellChanged: PropTypes.func,
  allowAddMultipleColumns: PropTypes.bool,
  allowAddMultipleRows: PropTypes.bool,
  allowAddSingleRow: PropTypes.bool,
  allowChangeDataType: PropTypes.bool,
  allowCloneColumn: PropTypes.bool,
  allowConfirmAndDeleteColumn: PropTypes.bool,
  allowCopyColumn: PropTypes.bool,
  allowCopyRows: PropTypes.bool,
  allowDeleteColumn: PropTypes.bool,
  allowDeleteOnlySpecificColumn: PropTypes.bool,
  allowDeleteRow: PropTypes.bool,
  allowReorderColumns: PropTypes.bool,
  allowSortColumn: PropTypes.bool,
  alwaysDisplayLegend: PropTypes.bool,
  cells: PropTypes.object,
  changeRowDataTypeFn: PropTypes.func,
  children: PropTypes.elementType,
  className: PropTypes.string,
  cloneColumn: PropTypes.func,
  collapsibleColumns: PropTypes.object,
  colTitleRow: PropTypes.number,
  columns: PropTypes.array,
  compareChanges: PropTypes.func,
  conditions: PropTypes.func,
  currencyFormatter: PropTypes.bool,
  customDeleteConfirmation: PropTypes.bool,
  customTitles: PropTypes.array,
  data: PropTypes.array.isRequired,
  dbColumns: PropTypes.array,
  deleteColumn: PropTypes.func,
  deleteRowFn: PropTypes.func,
  disabled: PropTypes.bool,
  disableDeleteBtnOnFirstColumn: PropTypes.bool,
  disableVerticalScroll: PropTypes.bool,
  displayColumnIndicator: PropTypes.bool,
  displayDeleteRowBtn: PropTypes.bool,
  displayRowIndicator: PropTypes.bool,
  editorForTitles: PropTypes.func,
  fieldAttributes: PropTypes.object,
  format: PropTypes.object,
  formatDispatch: PropTypes.func,
  hasColTitle: PropTypes.bool,
  id: PropTypes.string,
  ignoreCurrencyChangeNumber: PropTypes.number,
  initialCurrencyType: PropTypes.string,
  isLedgerTable: PropTypes.bool,
  isToolbarVisible: PropTypes.bool,
  linkCurrencyChips: PropTypes.bool,
  matchCurrencyPadding: PropTypes.bool,
  notShowingQuarters: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTitleCellsChanges: PropTypes.func,
  page: PropTypes.string,
  parser: PropTypes.func,
  referencedValues: PropTypes.object,
  reverseParser: PropTypes.func,
  rowConfig: PropTypes.array,
  rowGroups: PropTypes.object,
  setCollapsibleColumns: PropTypes.func,
  setColumns: PropTypes.func,
  setIsDisplayingRowNumbers: PropTypes.func,
  setRowGroups: PropTypes.func,
  setSpreadsheetWidth: PropTypes.func,
  setTitleCells: PropTypes.func,
  sheet: PropTypes.object.isRequired,
  showPlaceholder: PropTypes.bool,
  showPreviousColsDivider: PropTypes.bool,
  showTitlesColumn: PropTypes.bool,
  showToolbar: PropTypes.bool,
  showTotalColumn: PropTypes.bool,
  skeleton: PropTypes.object,
  sortColumnFn: PropTypes.func,
  sortedColumn: PropTypes.shape({}),
  tableData: PropTypes.object,
  tableTerms: PropTypes.object,
  titleData: PropTypes.array,
  toggleRows: PropTypes.func,
  totalData: PropTypes.array,
  totalParser: PropTypes.func,
  totalsCells: PropTypes.object,
  totalTemplate: PropTypes.object,
  unitsFormatter: PropTypes.bool,
  updateCells: PropTypes.func,
  updateTotalsCells: PropTypes.func,
  validatedTitleCells: PropTypes.object,
  validations: PropTypes.func,
  workbook: PropTypes.object,
  setIsDisplayingToolbar: PropTypes.func,
};

export default ScalarSpreadsheet;
