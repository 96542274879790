import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { formatNumbers } from 'utilities';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    borderColor: theme.palette.primary[800],
    color: theme.palette.primary[800],
    height: 15,
    fontSize: 12,
    marginLeft: 5,
    borderRadius: 5,

    '& > span': {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
}));

// eslint-disable-next-line react/display-name
const withChip = WrappedComponent =>
  React.memo(props => {
    const classes = useStyles();
    const [value, setValue] = useState('');

    const calcValue = useCallback(() => {
      if (
        (!isUndefined(props.cell.chip.value) || props.cell.chip.value)
        && props.cell.chip.gridType
        && (props.cell.chip.gridType === 'number' || props.cell.chip.gridType === 'percentage')
        // eslint-disable-next-line no-restricted-globals
        && !isNaN(props.cell.chip.value)
      ) {
        return formatNumbers(props.cell.chip);
      }
      return props.cell.chip.value;
    }, [props.cell]);

    const getValue = useCallback(() => {
      setValue(calcValue());
    }, [calcValue]);

    useEffect(() => {
      if (!isEmpty(props.cell.chip)) {
        getValue();
      }
    }, [getValue, props.cell]);

    const CellContent = useMemo(() => {
      if (props.cell.valueViewer) {
        return <WrappedComponent {...props} />;
      }

      return props.cell.component
        ? React.cloneElement(props.cell.component, { cell: props.cell }, null)
        : formatNumbers(props.cell);
    }, [props]);

    if (isEmpty(props.cell.chip)) {
      return <WrappedComponent {...props} />;
    }

    return (
      <div className={`dialog ${classes.root}`}>
        <div className="dialog__value">
          <span className="value-viewer">{CellContent}</span>
        </div>
        <div className={classes.chipContainer}>
          <Chip label={value} variant="outlined" className={classes.chip} />
        </div>
      </div>
    );
  });

withChip.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

withChip.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default withChip;
