import { ColConfigProps } from 'common/types/scalarSpreadsheet';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { ParserParams } from './types';

export default async (params: ParserParams) => {
  const { columns, rowConfig } = params;
  let cells = {};
  const alphabet = alphabetGenerator([], columns.length);
  const getExpr = (expr: string, columnLegend: string) => expr.replace(/@/g, `${columnLegend}`);

  rowConfig.forEach((row, rowIdx) => {
    columns.forEach((col, colIdx) => {
      const rowNumber = rowIdx + 1;
      const columnLegend = alphabet[colIdx];
      const key = columnLegend + rowNumber;
      const expr = getExpr(row.expr ?? '', columnLegend);
      const value = col[row.alias as keyof ColConfigProps];

      const customKey = colIdx === columns.length - 1 ? row.alias : undefined;

      cells = {
        ...cells,
        [key]: {
          ...row,
          customKey,
          expr,
          key,
          columnLegend,
          value,
          className: '',
        },
      };
    });
  });

  return cells;
};
