import { ApproachesByTypeParams } from './types';

const getApproachesByType = (params: ApproachesByTypeParams) => {
  const { approaches, approachTypes } = params;

  if (!approaches) return [];

  // If there is any approach type to filter, return the approaches list.
  if (!approachTypes.length) return approaches;

  // Lets filter the approaches by type
  const filteredApproaches = approaches.filter(({ approach_type }) => approachTypes.includes(approach_type ?? ''));

  // Return empty array if any approach match with the filter
  if (!filteredApproaches) return [];

  return filteredApproaches;
};

export default getApproachesByType;
