import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  COMPANY_MEASUREMENT_DATE_BY_MD_DEFAULT_ERROR_MESSAGE,
  COMPANY_MEASUREMENT_DATE_BY_MD_QUERY_KEY,
} from 'common/constants/services/companies';
import { CompanyMeasurementDatesService } from 'services';
import { throwError } from 'utilities';
import {
  GetCompanyMeasurementDateByMDHook,
  GetCompanyMeasurementDateByMDParams,
  GetCompanyMeasurementDateByMDResponse,
  UseGetCompanyMeasurementDateByMD,
} from './types';

const getCompanyMeasurementDateByMD = async (params: GetCompanyMeasurementDateByMDParams) => {
  const { measurementDateId } = params;

  if (isNil(measurementDateId)) return null;

  const companyMeasurementDatesService = new CompanyMeasurementDatesService();

  try {
    const companyMeasurementDate = (await companyMeasurementDatesService.byMeasurementDate(
      measurementDateId
    )) as GetCompanyMeasurementDateByMDResponse;

    if (!companyMeasurementDate) return throwError(COMPANY_MEASUREMENT_DATE_BY_MD_DEFAULT_ERROR_MESSAGE);

    return companyMeasurementDate;
  } catch (error) {
    return throwError(COMPANY_MEASUREMENT_DATE_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetCompanyMeasurementDateByMD: UseGetCompanyMeasurementDateByMD = params => {
  const { measurementDate, shouldQueryAutomatically = true } = params;

  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetCompanyMeasurementDateByMDHook['data'],
    Error
  >({
    enabled: !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getCompanyMeasurementDateByMD({ measurementDateId }),
    queryKey: [COMPANY_MEASUREMENT_DATE_BY_MD_QUERY_KEY, measurementDateId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetCompanyMeasurementDateByMD;
