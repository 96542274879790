import { AllocationScenarioDetailSecurityModel } from 'api';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { GetAdjustedColumnNumberParams, GetEquityAllocationHeaderParams, GetSecurityRowNumberParams } from './types';

export const EQUITY_ALLOCATION_PRESENT_VALUE_PER_SHARE_ROWS_OFFSET = 13;
export const EQUITY_ALLOCATION_SCENARIO_WEIGHTING_PROBABILITY_ROW = 10;

export const EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD_TITLE = 'Allocation Method';
export const EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_SCENARIOS_TITLE = 'Allocation Scenarios';
export const EQUITY_ALLOCATION_SPREADSHEET_CAP_TABLE_SELECTION_TITLE = 'Cap Table Selection';
export const EQUITY_ALLOCATION_SPREADSHEET_FUTURE_EQUITY_VALUE_TITLE = 'Future Equity Value';
export const EQUITY_ALLOCATION_SPREADSHEET_MATURITY_TITLE = 'Maturity';
export const EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS_TITLE = 'OPM Inputs';
export const EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE_TITLE = 'Present Equity Value';
export const EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE_TITLE = 'Present Value per Share';
export const EQUITY_ALLOCATION_SPREADSHEET_RISK_FREE_RATE_TITLE = 'Risk Free Rate';
export const EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY_TITLE = 'Scenario Weighting/Probability';
export const EQUITY_ALLOCATION_SPREADSHEET_TOTAL_TITLE = 'Total';
export const EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS_TITLE
  = 'Value Allocated to Security Class';
export const EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY_TITLE = 'Volatility';

export const EQUITY_ALLOCATION_SPREADSHEET_HEADERS = Object.freeze({
  ALLOCATION_METHOD: {
    columnNumber: 0,
    title: EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD_TITLE,
  },
  ENTERPRISE_VALUE: {
    columnNumber: 1,
    title: '',
  },
  EQUITY_VALUE: {
    columnNumber: 2,
    title: '',
  },
});

export const FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER
  = getNumberValue(Object.values(EQUITY_ALLOCATION_SPREADSHEET_HEADERS).pop()?.columnNumber) + 1;

const NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS = [
  EQUITY_ALLOCATION_SPREADSHEET_HEADERS.ALLOCATION_METHOD.columnNumber,
  EQUITY_ALLOCATION_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber,
];

export const getEquityAllocationHeader = (params: GetEquityAllocationHeaderParams) => {
  const { colNumber, financialsCurrency = '', isUniformCurrency } = params;

  let isFinancialsEnterpriseValue = false;
  let shouldAdjustColumnNumber = false;

  if (!isUniformCurrency) {
    // Add Currency Code to the Financials Enterprise Value Title
    isFinancialsEnterpriseValue = colNumber === EQUITY_ALLOCATION_SPREADSHEET_HEADERS.ENTERPRISE_VALUE.columnNumber;
    // Adjust the Column number if is not uniform currency and the column is not the Valuation Approach Name, Approach Type, or Enterprise Value (Financials)
    shouldAdjustColumnNumber = !NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS.includes(colNumber);
  }

  const adjustedColumnNumber = shouldAdjustColumnNumber ? colNumber - 1 : colNumber;

  const valuationsSummaryHeader = Object.values(EQUITY_ALLOCATION_SPREADSHEET_HEADERS).find(
    header => header?.columnNumber === adjustedColumnNumber
  );

  return {
    ...getObjectValue(valuationsSummaryHeader),
    title: isFinancialsEnterpriseValue
      ? `${financialsCurrency} ${valuationsSummaryHeader?.title}`
      : valuationsSummaryHeader?.title,
    columnNumber: adjustedColumnNumber,
  };
};

export const getAdjustedColumnNumber = (params: GetAdjustedColumnNumberParams) => {
  const { colNumber, includeAllColumns = false, isUniformCurrency } = params;

  if (isUniformCurrency) {
    return colNumber;
  }

  if (includeAllColumns) {
    return colNumber - 1;
  }

  // Adjust the Column number if is not uniform currency and the column is not the Valuation Approach Name, Approach Type, or Enterprise Value (Financials)
  return !NOT_UNIFORM_CURRENCY_EXCLUDED_COLUMNS.includes(colNumber) ? colNumber - 1 : colNumber;
};

export const getPresentValuePerShareRowNumber = (securities?: AllocationScenarioDetailSecurityModel[]) => {
  const securitiesQuantity = getNumberValue(securities?.length);

  return securitiesQuantity + EQUITY_ALLOCATION_PRESENT_VALUE_PER_SHARE_ROWS_OFFSET;
};

export const getSecurityRowNumber = (params: GetSecurityRowNumberParams) => {
  const { securityName, securities } = params;

  const securityPosition = getNumberValue(
    securities?.findIndex(security => getStringValue(security?.name) === getStringValue(securityName))
  );

  const presentValuePerShareRowNumber = getPresentValuePerShareRowNumber(securities);

  return (
    securityPosition // Security Index
    + presentValuePerShareRowNumber // Present Value Per Share Row
    + 1 // Index
  );
};
