import React from 'react';
import { DCFContextValues } from './types';

const DCFApproachContext = React.createContext<DCFContextValues>({
  panelId: null,
  format: null,
  onChange: null,
  formatDispatch: () => {},
  currentTerminalValue: null,
  setCurrentTerminalValue: async () => Promise.resolve(),
});

export default DCFApproachContext;
