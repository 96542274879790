import React, { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Button, ButtonGroup, Menu, Popper, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useStore } from 'common/store';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import LayoutContext from 'context/LayoutContext';
import { ITheme } from 'theme/types';
import MenuOption from './MenuOption';
import { PageActionsProps } from './types';

const useStyles = makeStyles((theme: ITheme | Theme) => ({
  menuButton: {
    minWidth: '100px',
    padding: '2px 14px',
  },
  menuButtonTrigger: {
    padding: '2px 0',
  },
  menuList: {
    marginTop: `${theme.spacing(2)}px`,
  },
  disabled: {
    color: theme.palette.grey?.[500] ?? (theme as ITheme).palette.gray[500],
    opacity: 0.5,
    cursor: 'default',
  },
  lockButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const tabBarId = 'test';

const ActionButtons = () => {
  const classes = useStyles();
  const { pageActions } = useContext(LayoutContext) as unknown as LayoutContextValues;
  const { mainAction, secondaryActions } = pageActions as PageActionsProps;
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLElement)>(null);
  const open = Boolean(anchorEl);
  const [{ isShowLoadingProgress }] = useStore() as unknown as UseStoreValues;

  const activeOptions = useMemo(
    () => (secondaryActions ? secondaryActions.filter(option => option.isActive) : []),
    [secondaryActions]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSecondaryItemClick = useCallback(option => {
    setAnchorEl(null);
    option.callback();
  }, []);

  return (
    <>
      <ButtonGroup color="secondary" aria-label="Page actions">
        {mainAction && (
          <Button
            id={`${mainAction?.id || tabBarId}-main-action`}
            variant="contained"
            color="secondary"
            className={`${classes.menuButton} ${mainAction.isLock && classes.lockButton}`}
            onClick={mainAction.callback}
            disabled={mainAction.isActive || !!isShowLoadingProgress}>
            {mainAction.label}
          </Button>
        )}
        {!isEmpty(activeOptions) && (
          <Button
            id={`${mainAction?.id || tabBarId}-dropdown-action`}
            className={`${classes.menuButtonTrigger} ${mainAction.isLock && classes.lockButton}`}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            disabled={!!isShowLoadingProgress}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorEl} id={open ? 'transitions-popper' : undefined}>
        <Menu
          className={classes.menuList}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          {activeOptions.map((option, optionIndex: number) => (
            <Tooltip title={option.tooltip || ''} key={`${mainAction?.id || tabBarId}-action-item-${optionIndex + 1}`}>
              <MenuOption
                mainAction={mainAction}
                tabBarId={tabBarId}
                option={option}
                optionIndex={optionIndex}
                onClick={handleSecondaryItemClick}
                classes={classes}
              />
            </Tooltip>
          ))}
        </Menu>
      </Popper>
    </>
  );
};

export default ActionButtons;
