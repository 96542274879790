/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { uniqueId } from 'underscore';
import { VALIDATED } from 'common/constants/general';
import { useStore } from 'common/store';
import { ConfirmationDelete } from 'components/Dialogs';
import { AddRowButton } from 'components/LedgerTable/components';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { ValueEditor, ValueViewer } from 'components/Spreadsheet/components';
import { CompGroupsContext, DialogContext } from 'context';
import { GPCCompGroups, transactionCompGroups } from 'pages/CompGroups/compGroups.parser';
import AddGpcDialog from 'pages/Valuations/approaches/guidelinePublicCompanies/AddGpcDialogue';
import { PUBLIC_COMPANIES } from 'pages/Valuations/util/constants';
import { transactionIdValidator } from 'pages/ValuationsAllocation/util';
import { useQueryTransaction, useTableValidation } from 'services/hooks';
import useReadFieldAttributes from 'services/hooks/useReadFieldAttributes';
import { GPT_TRANSACTION_MODEL_NAME, VALUATIONS_PAGE } from 'services/hooks/useReadFieldAttributes/constants';
import { addClassName, doesNotExistValue } from 'utilities';
import getCancelDialogOptions from 'utilities/getCancelDialogOptions';
import GpcTableComps from './GpcTableComps';
import TransactionsTableComps from './TransactionsTableComps';
import {
  aliasToAPIProp,
  NAME_ALIAS,
  TARGET_NAME_ALIAS,
  TRANSACTION_DATE_ALIAS,
  VERSIONS_ALIAS,
} from '../constants/constants';
import rowConfig from '../data/rowConfig';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
  },
}));

const AccordionCompGroup = ({
  group,
  GPCCompsGroups,
  transactionCompsGroups,
  setGPCCompsGroups,
  setTransactionCompsGroups,
  setCompsToDelete,
  expandedState,
  setExpandedState,
  setTransactionsValidatedGrids,
  showTransactionCellsWithErrors,
}) => {
  const classes = useStyles();
  const rowTitleClass = 'row-label table-header name-table';
  const [open, setOpen] = useState(false);
  const dialogContext = useContext(DialogContext);
  const [, setGPCCompanies] = useState([]);
  const [transactionRows, setTransactionRows] = useState(group.pt_comps);
  const [{ firmId }] = useStore();
  const { validateCells } = useTableValidation();
  const [transactionCellChanged, setTransactionCellChanged] = useState(false);
  const [transactionDataGrid, setTransactionDataGrid] = useState();
  // it's the same model under the hood so it's ok to do this
  const fieldAttributes = useReadFieldAttributes(VALUATIONS_PAGE);
  const transactionAttributes = fieldAttributes.get(GPT_TRANSACTION_MODEL_NAME);

  const emptyTransactionRow = {
    id: uniqueId(),
    target_name: '',
    acquirer_name: '',
    transaction_date: '',
    enterprise_value: '',
    ltm_revenue: '',
    ltm_revenue_growth: '',
    ltm_ebitda: '',
    ltm_ebitda_margin: '',
    ltm_gross_margin: '',
    cap_iq_transaction_id: null,
    comes_from_capital_iq: false,
  };

  // This data is for name and formValues of the accordions
  const initialFormValues = {
    [NAME_ALIAS]: null,
    [VERSIONS_ALIAS]: null,
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [compName, setCompName] = useState();
  const latestVersion = group.versions?.find(version => version.id === version.latest_version);
  const [selectedVersion, setSelectedVersion] = useState(latestVersion?.id);
  const [selectedComp, setSelectedComp] = useState();
  const [isPreviousVersion, setIsPreviousVersion] = useState(false);
  const { queryTransaction } = useQueryTransaction();

  useEffect(() => {
    if (group) {
      setFormValues({ ...formValues, [VERSIONS_ALIAS]: latestVersion?.id });
    }
  }, [group]);

  useEffect(() => {
    if (formValues[VERSIONS_ALIAS]) {
      setSelectedVersion(formValues[VERSIONS_ALIAS]);
    } else {
      setSelectedVersion(latestVersion?.id || undefined);
    }
  }, [formValues[VERSIONS_ALIAS]]);

  const data = useMemo(
    () => [
      [
        {
          value: rowConfig[0].value,
          readOnly: true,
          className: rowTitleClass,
        },
        {
          gridType: 'string',
          alias: NAME_ALIAS,
          placeholder: 'ENTER NAME',
          value: formValues[NAME_ALIAS] || group.name,
          className: 'comp-group-name-cell',
        },
      ],
      isEmpty(group.versions)
        ? []
        : [
          {
            value: rowConfig[1].value,
            readOnly: true,
            className: rowTitleClass,
          },
          {
            ...rowConfig[1],
            alias: VERSIONS_ALIAS,
            value: formValues[VERSIONS_ALIAS] || latestVersion?.id,
          },
        ],
    ],
    [formValues, compName, latestVersion]
  );

  const isGPCCompGroup = useMemo(() => group.group_type === PUBLIC_COMPANIES, [group.group_type]);

  useEffect(() => {
    if (formValues[NAME_ALIAS]) {
      // find the one that changed, separate the rest, set new combined array
      const sourceCompGroups = isGPCCompGroup ? GPCCompsGroups : transactionCompsGroups;
      const restOfCompGroups = sourceCompGroups.filter(({ id }) => id !== group.id);
      const updatedCompGroup = sourceCompGroups.find(({ id }) => id === group.id);
      const oldName = updatedCompGroup.name.slice();
      updatedCompGroup.name = formValues[NAME_ALIAS];
      updatedCompGroup.changed = true;
      // update expanded state
      const existingExpandedState = { ...expandedState };
      delete existingExpandedState[oldName];
      setExpandedState({
        ...existingExpandedState,
        [formValues[NAME_ALIAS]]: true,
      });

      if (isGPCCompGroup) {
        setGPCCompsGroups([...restOfCompGroups, updatedCompGroup].sort((a, b) => a.id - b.id));
      } else {
        setTransactionCompsGroups([...restOfCompGroups, updatedCompGroup].sort((a, b) => a.id - b.id));
      }
    }
  }, [formValues[NAME_ALIAS]]);

  useEffect(() => {
    if (!isNull(formValues[NAME_ALIAS])) {
      setCompName(formValues[NAME_ALIAS]);
    }
    return () => setCompName(undefined);
  }, [formValues[NAME_ALIAS]]);

  const fixDecimalPlaces = useCallback(
    ({ alias, value, comesFromCapIQ = false }) => {
      if (comesFromCapIQ && value === null) return value;
      // return value as it is if it's not a number
      // eslint-disable no-restricted-globals
      if (isNaN(value)) return value;

      const decimalPlaces = transactionAttributes[alias]?.decimal_places ?? 2;
      return Number(value).toFixed(decimalPlaces);
    },
    [transactionAttributes]
  );

  useEffect(() => {
    if (group) {
      setFormValues(initialFormValues);
    }
  }, [group]);

  const onChange = args => {
    const newValue = args[0].value;
    const { alias } = args[0].cell;
    setFormValues({ ...formValues, [alias]: newValue });
  };

  const selectedCompGroup = useMemo(() => {
    if (isGPCCompGroup) {
      return GPCCompsGroups?.find(comp => comp.id === group.id);
    }
    return transactionCompsGroups?.find(comp => comp.id === group.id);
  }, [isGPCCompGroup, GPCCompsGroups, transactionCompsGroups]);

  const removeItem = item => {
    if (isGPCCompGroup) {
      const foundIndex = GPCCompsGroups.findIndex(gpc => gpc.id === group.id);
      const arrayFiltered = group.gpc_comps.filter(e => e.cap_iq_id !== item.cap_iq_id);
      group.gpc_comps = arrayFiltered;
      const newLocal = {
        ...group,
        changed: true,
      };
      setSelectedComp(arrayFiltered);
      GPCCompsGroups[foundIndex] = newLocal;
    } else {
      const foundIndex = transactionCompsGroups.findIndex(gpc => gpc.id === group.id);
      const arrayFiltered = group.pt_comps.filter(e => e.id !== item.id);
      group.pt_comps = arrayFiltered;
      const newLocal = {
        ...group,
        changed: true,
      };
      setTransactionRows(arrayFiltered);
      setSelectedComp(arrayFiltered);
      transactionCompsGroups[foundIndex] = newLocal;
    }
  };

  const GPCDataGrid = isGPCCompGroup && GPCCompGroups(selectedComp || group?.gpc_comps, removeItem, isPreviousVersion);

  const deleteCompGroup = compGroup => {
    if (isGPCCompGroup) {
      const tmpGroups = [...GPCCompsGroups];
      const GPCToDelete = tmpGroups.find(gpc => gpc.id === compGroup.id);
      GPCToDelete.is_deleted = true;
      const remainingGroups = tmpGroups.filter(gpc => gpc.id !== compGroup.id);
      setCompsToDelete(prevState => [...prevState, GPCToDelete]);
      setGPCCompsGroups(remainingGroups);
    } else {
      const tmpGroups = [...transactionCompsGroups];
      const transactionsToDelete = tmpGroups.find(gpt => gpt.id === compGroup.id);
      transactionsToDelete.is_deleted = true;
      const remainingGroups = tmpGroups.filter(gpt => gpt.id !== compGroup.id);
      setCompsToDelete(prevState => [...prevState, transactionsToDelete]);
      setTransactionCompsGroups(remainingGroups);
    }
    setOpen(false);
  };

  const handleDelete = () => {
    const CUSTOM_MESSAGE = 'Are you sure you want to delete this comp group?';
    const ContentComponent = <ConfirmationDelete customMessage={CUSTOM_MESSAGE} />;
    const { showDialog } = dialogContext;

    showDialog(
      getCancelDialogOptions(
        () => ContentComponent,
        () => deleteCompGroup(selectedCompGroup)
      )
    );
  };

  const addCompanies = companies => {
    const newLocal = {
      ...group,
      changed: true,
    };
    const findIndex = GPCCompsGroups.findIndex(gpc => gpc.id === group.id);

    // Exclude companies without valid data
    const validCompanies = companies.filter(company => company.valid_gpc_results);
    const companiesData = validCompanies.map(validCompany => {
      const { gpc } = validCompany;
      const { company_name: companyName, ticker_symbol: tickerSymbol, cap_iq_id: capIqId } = gpc;

      const companyData = {
        company_name: companyName.value,
        ticker_symbol: tickerSymbol.value,
        cap_iq_id: capIqId.value,
      };
      group.gpc_comps.push(companyData);
      return companyData;
    });

    setGPCCompanies(prevState => [...prevState, ...companiesData]);
    GPCCompsGroups[findIndex] = newLocal;
    setSelectedComp(undefined);
  };

  const getCellsToValidate = useCallback(
    (changes, transactionData) => {
      if (isEmpty(transactionData)) {
        return changes.map(change => ({ ...change.cell, value: change.value }));
      }
      // read transactionData and put new values
      const sameRowCells = transactionDataGrid?.[changes[0].cell.rowIndex] ?? [];

      return sameRowCells.map(cell => {
        let valueFromAPI = transactionData?.[aliasToAPIProp[cell.alias]];
        if (cell.alias === TRANSACTION_DATE_ALIAS && valueFromAPI === undefined) {
          valueFromAPI = transactionData?.transaction_agreement_date;
        }
        const valueToUse = ['Data Unavailable', 'N/A'].includes(valueFromAPI) ? null : valueFromAPI;
        return {
          ...cell,
          readOnly: true,
          value: valueToUse,
          displayNAforNull: true,
        };
      });
    },
    [transactionDataGrid]
  );

  const onCellsChanged = async changes => {
    let transactionData = null;
    const newLocal = {
      ...group,
      changed: true,
    };
    const findIndex = transactionCompsGroups.findIndex(gpc => gpc.id === group.id);
    const updatedValues = changes.map(change => ({
      alias: change.cell.alias,
      value: change.value,
    }));

    if (
      changes.length === 1
      && changes[0].cell.alias === TARGET_NAME_ALIAS
      && transactionIdValidator(changes[0].value)
    ) {
      transactionData = await queryTransaction(changes[0].value);
    }

    const cellsToValidate = getCellsToValidate(changes, transactionData);
    const { validatedCells = [] } = validateCells({ cellsToValidate }) || {};
    // Validate the cells and update the grid
    const newCells = transactionDataGrid?.map((row, index) => {
      const validatedCellsData = validatedCells.filter(validatedCell => validatedCell.rowIndex === index);
      if (!isEmpty(validatedCellsData)) {
        return row.map(cell => {
          const validatedCellData = validatedCellsData.find(
            validatedCellDataParam => validatedCellDataParam.key === cell.key
          );
          if (cell.key === validatedCellData?.key) {
            return validatedCellData;
          }
          return cell;
        });
      }
      return row;
    });

    setTransactionDataGrid(newCells);
    setTransactionCellChanged(true);

    // We perform two validations:
    // 1. Validate the cell that has been changed (see above)
    // 2. Validate if the rest of the cells that are required have a valid value (see below)
    const requiredCellsStatuses = newCells?.map(row => {
      const requiredCells = row.filter(cell => cell.isRequired);
      return requiredCells.every(cell => !doesNotExistValue(cell.value));
    });

    setTransactionsValidatedGrids(status => ({
      ...status,
      [group.id]: {
        status: VALIDATED,
        isValid: validatedCells.every(validatedCell => validatedCell.isValid) && !requiredCellsStatuses.includes(false),
      },
    }));

    // Assign the value to the group.pt_comps
    updatedValues.forEach((value, i) => {
      const index = changes[i].cell.rowIndex;
      const { alias, value: newValue } = value;
      const foundIndex = group.pt_comps.findIndex(comp => comp.alias === alias) + index;
      const transactionToUpdate = group.pt_comps[foundIndex];
      // if we got transactionData we have to treat the values so the API can save them correctly
      if (transactionData) {
        validatedCells.forEach(({ alias, value }) => {
          transactionToUpdate[alias] = fixDecimalPlaces({ alias, value, comesFromCapIQ: true });
        });
        transactionToUpdate.cap_iq_transaction_id = changes[0].value;
        transactionToUpdate.comes_from_capital_iq = true;
        transactionToUpdate.transaction_comments = transactionData.transaction_comments;
        transactionToUpdate.transaction_deal_resolution = transactionData.transaction_deal_resolution;
        transactionToUpdate.business_description = transactionData.business_description;
      } else {
        transactionToUpdate[alias] = fixDecimalPlaces({ alias, value: newValue });
      }
    });

    transactionCompsGroups[findIndex] = newLocal;
  };

  const addRow = () => {
    setTransactionRows(prevState => [...prevState, emptyTransactionRow]);
    if (group.pt_comps) {
      group.pt_comps.push(emptyTransactionRow);
      setSelectedComp(undefined);
    }
  };

  const compGroupsContextValue = useMemo(() => ({ group }), [group]);

  useEffect(() => {
    if (group.pt_comps?.length) {
      const compGroupData
        = !isGPCCompGroup && transactionCompGroups(selectedComp || group?.pt_comps, removeItem, isPreviousVersion);
      setTransactionDataGrid(compGroupData);
    }

    // If the table change, apply the changes to the cells
    if (transactionCellChanged) {
      const newChanges = transactionDataGrid;
      setTransactionDataGrid(newChanges);
    }
    setTransactionCellChanged(false);
  }, [transactionRows, group.pt_comps, selectedVersion, selectedComp, latestVersion]);

  useEffect(() => {
    const compGroupData = !isGPCCompGroup ? transactionCompGroups(group.pt_comps, removeItem, isPreviousVersion) : [];
    setTransactionDataGrid(compGroupData);
  }, [selectedVersion, latestVersion]);

  useEffect(() => {
    const filteredCompByVersion = group.versions.filter(comp => comp.id === selectedVersion);
    if (isGPCCompGroup) {
      setSelectedComp(filteredCompByVersion[0]?.gpc_comps);
    } else {
      setSelectedComp(filteredCompByVersion[0]?.pt_comps);
    }
  }, [selectedVersion]);

  useEffect(() => {
    if (selectedVersion === latestVersion?.id) {
      setIsPreviousVersion(false);
    } else {
      setIsPreviousVersion(true);
    }
  }, [selectedVersion, latestVersion]);

  useEffect(() => {
    setSelectedVersion(latestVersion?.id);
  }, [latestVersion]);

  useEffect(() => {
    if (showTransactionCellsWithErrors) {
      const newCells = transactionDataGrid?.map(row => {
        const validatedCellsData = row.filter(cell => cell.isRequired && doesNotExistValue(cell.value));
        if (!isEmpty(validatedCellsData)) {
          return row.map(cell => {
            const validatedCellData = validatedCellsData.find(
              validatedCellDataParam => validatedCellDataParam.key === cell.key
            );
            if (cell.key === validatedCellData?.key) {
              return {
                ...cell,
                className: addClassName(cell.className, 'error'),
              };
            }
            return cell;
          });
        }
        return row;
      });
      setTransactionDataGrid(newCells);
    }
  }, [showTransactionCellsWithErrors]);

  const handleAccordionCollapsedChange = useCallback((_event, isExpanded, label) => {
    setExpandedState(prevState => ({
      ...prevState,
      [label]: isExpanded,
    }));
  }, []);

  return (
    <CompGroupsContext.Provider value={compGroupsContextValue}>
      <Accordion
        expanded={expandedState?.[group.id] || false}
        key={group.id}
        onChange={(event, expanded) => handleAccordionCollapsedChange(event, expanded, group.id)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <span className={classes.title}>{group.name || ''}</span> {group.name ? '|' : ''}{' '}
            {isGPCCompGroup ? 'Public Comp Group' : 'Transaction Comp Group'}{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
          <ScalarDatasheet
            data={data}
            className="name-table-header"
            onCellsChanged={onChange}
            dataEditor={ValueEditor}
            valueViewer={ValueViewer}
          />
          {isGPCCompGroup
            ? !isEmpty(group.gpc_comps) && <GpcTableComps GPCDataGrid={GPCDataGrid} onCellsChanged={onCellsChanged} />
            : !isEmpty(transactionRows) && (
              <TransactionsTableComps transactionDataGrid={transactionDataGrid} onCellsChanged={onCellsChanged} />
            )}
        </AccordionDetails>
        <div style={{ display: 'flex', padding: '1em' }}>
          {isGPCCompGroup ? (
            <AddGpcDialog
              open={open}
              setOpen={setOpen}
              onAdd={addCompanies}
              firmId={firmId}
              disabled={isPreviousVersion}
              currentComps={group.gpc_comps}
            />
          ) : (
            <AddRowButton
              addRow={addRow}
              tableTerms={{
                columnName: 'Comparable Transaction',
              }}
              disabled={isPreviousVersion}
            />
          )}
          <Button style={{ marginLeft: '10em' }} color="primary" onClick={handleDelete}>
            Delete Group
          </Button>
        </div>
      </Accordion>
    </CompGroupsContext.Provider>
  );
};

export default AccordionCompGroup;

AccordionCompGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    gpc_comps: PropTypes.arrayOf(PropTypes.shape({})),
    pt_comps: PropTypes.arrayOf(
      PropTypes.shape({
        comes_from_capital_iq: PropTypes.bool,
        cap_iq_transaction_id: PropTypes.string,
        transaction_comments: PropTypes.string,
        transaction_deal_resolution: PropTypes.string,
        business_description: PropTypes.string,
      })
    ),
    versions: PropTypes.arrayOf(PropTypes.shape({})),
    group_type: PropTypes.string,
  }),
  setGPCCompsGroups: PropTypes.func,
  setTransactionCompsGroups: PropTypes.func,
  GPCCompsGroups: PropTypes.arrayOf(PropTypes.shape({})),
  transactionCompsGroups: PropTypes.arrayOf(PropTypes.shape({})),
  setCompsToDelete: PropTypes.func,
  expandedState: PropTypes.shape({}),
  setExpandedState: PropTypes.func,
  setTransactionsValidatedGrids: PropTypes.func,
  showTransactionCellsWithErrors: PropTypes.bool,
};
