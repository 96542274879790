import React from 'react';
import PropTypes from 'prop-types';
import { formatNumbers } from 'utilities';

const TableContainer = ({ number, multiplier = 1, prepend = '', append = '', decimalsToDisplay = 0 }) => {
  const getDisplay = () => {
    const format = {
      minimumFractionDigits: decimalsToDisplay,
      maximumFractionDigits: decimalsToDisplay,
    };
    return formatNumbers({
      value: number * multiplier,
      format,
      prefix: prepend,
      suffix: append,
    });
  };
  return <span>{getDisplay()}</span>;
};

TableContainer.propTypes = {
  number: PropTypes.number.isRequired,
  multiplier: PropTypes.number,
  prepend: PropTypes.string,
  append: PropTypes.string,
  decimalsToDisplay: PropTypes.number,
};

export default TableContainer;
