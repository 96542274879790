import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { InfoCard } from 'components';
import { getObjectValue } from 'utilities';
import { DialogHeaderProps } from './types';

const useStyles = makeStyles(() => ({
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const DialogHeader = (props: DialogHeaderProps) => {
  const { waccInfo } = getObjectValue(props);
  const classes = useStyles();

  return (
    <div className={classes.infoCards}>
      <InfoCard title="WEIGHTED AVERAGE COST OF CAPITAL" body={`${Number(waccInfo ?? 0).toFixed(1)} %`} />
    </div>
  );
};

export default DialogHeader;
