import React from 'react';
import { Button, Collapse, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert as AlerMui } from '@material-ui/lab';
import { ITheme } from 'theme/types';
import { AlertProps } from './type';

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    marginBottom: theme.spacing(2),
    '& .MuiAlert-root': {
      borderWidth: 2,
    },
  },
  button: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiAlert-outlinedError': {
      color: theme.palette.error.pink,
      borderColor: theme.palette.error.pink,
      borderLeft: `3px solid ${theme.palette.gray[200]}`,
    },
    '&.MuiAlert-outlinedSuccess': {
      color: theme.palette.secondary[500],
      borderColor: theme.palette.secondary[500],
    },
    '&.MuiAlert-outlinedError .MuiButton-text': {
      color: theme.palette.error.main,
    },
    '&.MuiAlert-outlinedError .MuiButton-contained': {
      color: 'white',
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const Alert = (props: AlertProps) => {
  const { isAlertVisible, children, actions, icon, severity, variant, autoClose, onClose } = props;
  const classes = useStyles();

  const Actions = () => {
    // If autoClose is provided the actions property will be ignored
    if (autoClose) {
      return (
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      );
    }

    if (actions) {
      return (
        <>
          {actions.map(action => (
            <Button key={action.label} className={classes.button} onClick={action.callback} {...action.buttonProps}>
              {action.label}
            </Button>
          ))}
        </>
      );
    }
    return <></>;
  };

  return (
    <Collapse in={isAlertVisible} className={classes.root}>
      <AlerMui variant={variant} severity={severity} action={<Actions />} icon={icon} className={classes.alert}>
        {children}
      </AlerMui>
    </Collapse>
  );
};

export default Alert;
