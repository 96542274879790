import React from 'react';

type CenterAlignedParagraphProps = {
  message: string;
};

const CenterAlignedParagraph = (props: CenterAlignedParagraphProps) => {
  const { message } = props;
  return <p style={{ textAlign: 'center' }}>{message}</p>;
};

export default CenterAlignedParagraph;
