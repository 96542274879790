import { useCallback, useEffect, useState } from 'react';
import {
  COMPLETE,
  FAILED,
  INITIAL,
  MultiPromiseMap,
  PENDING,
  POLLING,
  PromiseResult,
  PromiseState,
  TIMEOUT,
} from './useLongGatewayMultiPromiseApiRequest.props';

// this hook is used to handle multiple promises that are sent to gateway and then polled for a response in case of
// a timeout. The hook will poll for a response on timeout and will stop polling after pollInterval*pollRepeats
const useLongGatewayMultiPromiseHandler = <T, J>(
  promisesParams: Array<T>,
  promiseHandler: (params: T) => Promise<J>,
  pollMethod: (params: T) => Promise<J | undefined>,
  pollInterval: number,
  pollRepeats: number
): { result: MultiPromiseMap<J, T>; doRequest: () => void; processStatus: PromiseState } => {
  const [result, setResult] = useState<MultiPromiseMap<J, T>>({});
  const [processStatus, setProcessStatus] = useState<PromiseState>(INITIAL);
  const [pollTime, setPollTime] = useState<number>();

  // this is used for each request
  const handlePromise = useCallback<(params: T, key: number) => PromiseResult<J, T>>(
    (params: T, key: number) => {
      const promise: Promise<void> = promiseHandler(params)
        .then(response => {
          setResult(prevState => ({
            ...prevState,
            [key]: {
              promise,
              state: COMPLETE,
              key,
              result: response,
              promiseParams: params,
            },
          }));
        })
        .catch(error => {
          if (error.message === TIMEOUT) {
            const numRetries = 0;
            setResult(prevState => ({
              ...prevState,
              [key]: {
                promise,
                state: TIMEOUT,
                key,
                numRetries,
                promiseParams: params,
              },
            }));
          } else {
            setResult(prevState => ({
              ...prevState,
              [key]: {
                promise,
                state: FAILED,
                key,
                promiseParams: params,
              },
            }));
          }
          throw error;
        });

      return {
        promise,
        state: PENDING,
        key,
        promiseParams: params,
      };
    },
  [promiseHandler]
  );

  const getOnfulfilled = useCallback(
    (promiseResult: PromiseResult<J, T>): ((response?: J) => void) =>
      (response?: J) => {
        if (response) {
          setResult(prevState => ({
            ...prevState,
            [promiseResult.key]: {
              promise: promiseResult.promise,
              state: COMPLETE,
              key: promiseResult.key,
              result: response,
              promiseParams: promiseResult.promiseParams,
            },
          }));
        }
      },
    []
  );

  // this function is used to poll for the response on timeout
  const pollPromises = useCallback(() => {
    setTimeout(() => {
      Object.values(result)
        .filter(promiseResult => promiseResult.state === TIMEOUT)
        .forEach(promiseResult => {
          pollMethod(promiseResult.promiseParams).then(getOnfulfilled(promiseResult));
        });
      setPollTime(prevPollTime => (prevPollTime ?? 0) + pollInterval);
    }, pollInterval);
  }, [pollInterval, result, pollMethod, getOnfulfilled]);

  // stops the requests after pollInterval*pollRepeats and manages polling
  useEffect(() => {
    if (pollTime !== null && pollTime !== undefined && pollTime < pollRepeats * pollInterval) {
      pollPromises();
    } else if (pollTime && pollTime >= pollRepeats * pollInterval) {
      setProcessStatus(COMPLETE);
      setPollTime(undefined);
    }
  }, [pollTime, pollInterval, pollRepeats, pollPromises]);

  // checks if all the promises are complete
  useEffect(() => {
    const resultValues = Object.values(result);
    if (resultValues.length && resultValues.every(promiseResult => promiseResult.state === COMPLETE)) {
      setProcessStatus(COMPLETE);
      setPollTime(undefined);
    }
  }, [result]);

  // initiates the polling process after the initial requests are sent off
  useEffect(() => {
    if (processStatus === PENDING) {
      const promiseResults = promisesParams.map((params, index) => handlePromise(params, index));
      setResult(
        promiseResults.reduce(
          (acc, result) => ({
            ...acc,
            [result.key]: result,
          }),
          {}
        )
      );
      setProcessStatus(POLLING);
      pollPromises();
    }
  }, [promisesParams, handlePromise, processStatus, pollPromises]);

  // initiates the requests
  const doRequest = useCallback(() => {
    setProcessStatus('pending');
  }, []);

  return { result, doRequest, processStatus };
};

export default useLongGatewayMultiPromiseHandler;
