import { getBooleanValue } from 'utilities';
import { IsCellNavigableParams } from './types';

const isCellVisible = (element: Element) => getBooleanValue(element?.matches('.visible'));

const isCellNavigable = (params: IsCellNavigableParams) => {
  const { cell, containerRef } = params;

  const cellItem = containerRef.current?.querySelector(`#CELL-${cell.key}`);
  const cellItemParent = cellItem?.parentNode as HTMLTableRowElement;

  // If cellItem is not found and cellItemParent is not found, then cell is not navigable
  if (!cellItem || !cellItemParent) return false;

  const isNavigable = cell?.isNotNavigable !== true;

  // If cell is not navigable
  if (!isNavigable) return false;

  const isVisible = isCellVisible(cellItem) && isCellVisible(cellItemParent);

  // If cell is not visible, then cell is not navigable
  if (!isVisible) return false;

  const shouldAutoScroll = cell?.ignoreAutoScroll !== true;

  if (shouldAutoScroll) {
    // Scroll into cell using requestAnimationFrame to avoid layout thrashing
    requestAnimationFrame(() => {
      cellItem.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'center', // Vertically center the element
        inline: 'nearest', // Horizontally near the element
      });
    });
  }

  // Cell is navigable
  return true;
};

export default isCellNavigable;
