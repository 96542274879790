import { NOT_APPLICABLE as NOT_AVAILABLE } from 'common/constants/general';
import { formatCurrencyValue } from 'pages/ValuationsAllocation/util/formatCurrencyValue';
import { shortDate } from 'utilities';
import { GetTransactionInfoParams, TransactionInfoTableRows } from './types';

const getTransactionInfoTableRows = (params: GetTransactionInfoParams): TransactionInfoTableRows => {
  const { financialsCurrency } = params;
  return {
    targetName: params.transactionData.target_name,
    acquirerInfo: params.transactionData.acquirer_name ?? NOT_AVAILABLE,
    dealResolution: params.transactionData.transaction_deal_resolution ?? NOT_AVAILABLE,
    dealComments: params.transactionData.transaction_comments ?? NOT_AVAILABLE,
    businessDescription: params.transactionData.business_description ?? NOT_AVAILABLE,
    bottomRows: [
      {
        label: 'Transaction Date',
        value: shortDate(params.transactionData.transaction_date) as string,
      },
      {
        label: `LTM Revenue in ${financialsCurrency}`,
        value: formatCurrencyValue(params.transactionData.ltm_revenue, financialsCurrency),
      },
      {
        label: `LTM EBITDA in ${financialsCurrency}`,
        value: formatCurrencyValue(params.transactionData.ltm_ebitda, financialsCurrency),
      },
      {
        label: `Enterprise Value in ${financialsCurrency}`,
        value: formatCurrencyValue(params.transactionData.enterprise_value, financialsCurrency),
      },
    ],
  };
};

export default getTransactionInfoTableRows;
