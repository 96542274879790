import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { SHEET_CONFIG_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utilities';
import { createColumns } from './createColumns';
import { customParser } from './customParser';
import { CustomParserParams } from './customParser/types';
import { customReverseParser } from './customReverseParser';
import { ModifiedPresentEquityReverseParserParams } from './customReverseParser/types';
import { rowConfig } from './rowConfig';
import { CreateModifiedPresentEquityValueSheetParams } from './types';

const { MODIFIED_PRESENT_EQUITY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const createModifiedPresentEquityConfiguration = (params: CreateModifiedPresentEquityValueSheetParams) => {
  const {
    approach,
    futureExitApproachAttrs,
    isDisabled,
    lessCashTotal,
    measurementDate,
    plusDebtTotal,
    ltmData,
    futureEquitySheet,
  } = params;

  const { valuations_approach_future_exit: valuationsApproachFutureExitApproach } = getObjectValue(approach);
  const name = getApproachTableName({
    approach,
    tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.MODIFIED_PRESENT_EQUITY_VALUE,
  });

  const getColumns = () =>
    createColumns({
      valuationsApproachFutureExitApproach,
      lessCashTotal,
      plusDebtTotal,
      measurementDate,
    });

  const columns = getColumns();
  const config = rowConfig({ isDisabled });

  const parser = (parserParams: CustomParserParams) =>
    customParser({
      ...parserParams,
      ltmData,
      futureEquitySheet,
      measurementDate,
    });

  const reverseParser = (reverseParserParams: ModifiedPresentEquityReverseParserParams) =>
    customReverseParser({
      ...reverseParserParams,
      approach,
    });

  const modifiedPresentEquitySheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      allowConfirmAndDeleteColumn: false,
      allowCopyColumn: false,
      allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
      alwaysDisplayLegend: false,
      columns,
      currencyFormatter: true,
      fieldAttributes: futureExitApproachAttrs,
      format: undefined,
      name,
      page: MODIFIED_PRESENT_EQUITY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
      parser,
      reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
      rowConfig: config,
      showPreviousColsDivider: false,
      showTitlesColumn: true,
      showToolbar: true,
      showTotalColumn: false,
      tableData: {},
      tableTerms: MODIFIED_PRESENT_EQUITY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
      totalParser: undefined,
      unitsFormatter: true,
      getColumns,
    })
    : null;

  return modifiedPresentEquitySheet;
};

export default createModifiedPresentEquityConfiguration;
