import { isNil } from 'lodash';
import { BLANK_VALUE } from 'common/constants/general';
import { SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX } from 'pages/Funds/common/constants/scheduleOfInvestments';
import { generateColumnKey, getNumberOrNAValue, getNumberValue, getStringValue, shortDate } from 'utilities';
import { CreateColumnKeyNameParams, CreateColumns, ScheduleOfInvestmentsColumn } from './types';

export const createColumnKeyName = (params: CreateColumnKeyNameParams) => {
  const { name, slug } = params;

  return `${getStringValue(slug)}-${getStringValue(name)}`;
};

const createColumns: CreateColumns = params => {
  const { company, investments } = params;

  // Investment Date
  const securityInvestmentDate = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.investment_date)),
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Invested Capital
  const securityInvestedCapital = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.investment),
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Shares
  const securityShares = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.shares),
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // CSE Shares
  const securityCSEShares = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.cse_shares),
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Fully Diluted Ownership %
  const securityFullyDilutedOwnership = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getNumberValue(current?.diluted_ownership),
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Realized Value
  const securityRealizedValue = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.realized_value) ? getNumberValue(current.realized_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Unrealized Equity
  const securityUnrealizedEquity = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.unrealized_value) ? getNumberValue(current.unrealized_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // Total Value
  const securityTotalValue = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.total_value) ? getNumberValue(current.total_value) : BLANK_VALUE,
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  // MOIC
  const securityMOIC = investments.reduce(
    (accumulator, current, currentIndex) => ({
      ...accumulator,
      [generateColumnKey({
        id: currentIndex,
        name: createColumnKeyName({ name: current?.security_name, slug: company?.company_slug }),
        prefix: SCHEDULE_OF_INVESTMENTS_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: !isNil(current?.x_factor) ? getNumberOrNAValue(current.x_factor) : BLANK_VALUE,
      },
    }),
    {}
  ) as ScheduleOfInvestmentsColumn;

  return [
    securityInvestmentDate,
    securityInvestedCapital,
    securityShares,
    securityCSEShares,
    securityFullyDilutedOwnership,
    securityRealizedValue,
    securityUnrealizedEquity,
    securityTotalValue,
    securityMOIC,
  ];
};

export default createColumns;
