/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { Cells, RowConfigProps } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import { getWaccDataApproach } from 'pages/Valuations/util/util';
import parser from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcfTerminalValue/util/getParser';
import {
  TVT_EM_TABLE_NAME,
  TVT_HM_TABLE_NAME,
  TVT_PG_TABLE_NAME,
  TVT_REM_TABLE_NAME,
  TVT_RM_TABLE_NAME,
} from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/constants';
import {
  extractSpecificApproachFromApproach,
  getApproachTableName,
  getCompaniesOrTransactions,
} from 'pages/ValuationsAllocation/util';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { getObjectValue } from 'utilities';
import getHModelConfig from './config/HModel/getConfig';
import getMultipleTerminalConfig from './config/MultipleTerminal/getConfig';
import getPerpetuityGrowthConfig from './config/PerpetuityGrowth/getConfig';
import getCombinedColumns from './config/RevEbitdaMultiple/getCombinedColumns';
import getRevEbitdaMultipleConfig from './config/RevEbitdaMultiple/getConfig';
import remReverseParser from './config/RevEbitdaMultiple/reverseParser';
import { GetCombinedColumnsProps } from './config/RevEbitdaMultiple/types';
import { CustomReverseParserParams, TVTDataProps } from './types';
import afterCellChanged from './util/afterCellChanged';
import getColumns from './util/getColumns';
import reverseParser from './util/reverseParser';
import { AllCells, Changes, ColumnDataProps, GetColumnsProps, PercentileSelections, TableData } from './util/types';
import { CustomDCFFinancialPeriod } from '../utilities/types';

const terminalValueProps = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: getPerpetuityGrowthConfig,
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: getHModelConfig,
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: getMultipleTerminalConfig,
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: getMultipleTerminalConfig,
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: getRevEbitdaMultipleConfig,
};

export const getTvtConfig = ({
  approach,
  approachOptions,
  mainTableName,
  tvtType,
  dcfWaccSheet,
  dcfTVTRMSheetName,
  dcfTVTEMSheetName,
  benchmarkApproach,
  isDisabled,
}: TVTDataProps) => {
  const { valuations_approach_dcf: valuationApproachDcf } = getObjectValue(approach);

  const waccData = getWaccDataApproach(approach, 'valuations_approach_dcf');

  const dcfFinancialPeriod = valuationApproachDcf?.dcf_financial_period?.length
    ? valuationApproachDcf.dcf_financial_period.reduce(
      (sofar, next) => (sofar && next.year > sofar.year ? next : sofar),
        null as CustomDCFFinancialPeriod | null
    )
    : undefined;

  const dcfFinancialPeriodWithDiscountPeriods = () => {
    if (dcfFinancialPeriod?.discount_periods) {
      const discountPeriodValue = Number(dcfFinancialPeriod.discount_periods) + 0.5;
      dcfFinancialPeriod.terminal_discount_periods = discountPeriodValue.toString();
    }
  };

  const isPeriodWithDiscountPeriods
    = tvtType === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE
    || tvtType === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;

  // things from benchmark approach to read and select multiples
  const specificApproach = extractSpecificApproachFromApproach(benchmarkApproach);
  const selectionOptions = isEmpty(specificApproach)
    ? [VALUATIONS_OTHER_LABEL]
    : getSelectionCellOptions({
      isDCF: true,
      specificApproach,
    });
  const companiesRows = getCompaniesOrTransactions(specificApproach);
  const percentileSelections = {
    percentile_selection_a: specificApproach?.percentile_selection_a ?? 75,
    percentile_selection_b: specificApproach?.percentile_selection_b ?? 25,
  } as PercentileSelections;
  const benchmarkType = benchmarkApproach.approach_type;

  const columnsProps = {
    approachOptions,
    benchmarkType,
    companiesRows,
    dCFFinancialPeriod: (isPeriodWithDiscountPeriods
      ? dcfFinancialPeriodWithDiscountPeriods()
      : dcfFinancialPeriod) as CustomDCFFinancialPeriod,
    dcfTVTEMSheetName,
    dcfTVTRMSheetName,
    dcfWaccSheet,
    isDisabled,
    mainTableName,
    options: selectionOptions,
    percentileSelections,
    valuationApproachDCF: valuationApproachDcf,
    waccData,
  } as ColumnDataProps;

  const getColumnsFn = () =>
    tvtType === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE
      ? getCombinedColumns(columnsProps as GetCombinedColumnsProps)
      : getColumns(columnsProps as GetColumnsProps);
  const columns = getColumnsFn();

  const getConfig = terminalValueProps[tvtType ?? TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH];

  return {
    columns,
    getColumns: getColumnsFn,
    ...getConfig({
      columnsProps,
      ...(tvtType === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE && { ebitda: true }),
    }),
    isDisabled,
  };
};

export const createTVTPGData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}: TVTDataProps) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_PG_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH;
  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customAfterCellChanged = (
    changes: Changes[],
    cells: Cells,
    rowConfig: RowConfigProps,
    tableData: TableData,
    allCells: AllCells
  ) => afterCellChanged(changes, cells, rowConfig, tableData, allCells, approaches);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: customAfterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    tableTerms: {
      tableSlug: 'dcf-terminal-value-pg-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    format: undefined,
    totalParser: undefined,
    ...spreadsheetProps,
  });
};

export const createTVTHMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}: TVTDataProps) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_HM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.H_MODEL;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customAfterCellChanged = (
    changes: Changes[],
    cells: Cells,
    rowConfig: RowConfigProps,
    tableData: TableData,
    allCells: AllCells
  ) => afterCellChanged(changes, cells, rowConfig, tableData, allCells, approaches);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: customAfterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    tableTerms: {
      tableSlug: 'dcf-terminal-value-hm-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    format: undefined,
    totalParser: undefined,
    ...spreadsheetProps,
  });
};

export const createTVTRMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}: TVTDataProps) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_RM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = (reverseParserParams: CustomReverseParserParams) =>
    remReverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (
    changes: Changes[],
    cells: Cells,
    rowConfig: RowConfigProps,
    tableData: TableData,
    allCells: AllCells
  ) => afterCellChanged(changes, cells, rowConfig, tableData, allCells, approaches);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: customReverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: customAfterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    tableTerms: {
      tableSlug: 'dcf-terminal-value-rm-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    format: undefined,
    totalParser: undefined,
    ...spreadsheetProps,
  });
};

export const createTVTEMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  benchmarkApproach,
  dcfAttributes,
  name: tableName,
  isDisabled,
}: TVTDataProps) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_EM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = (reverseParserParams: CustomReverseParserParams) =>
    remReverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (
    changes: Changes[],
    cells: Cells,
    rowConfig: RowConfigProps,
    tableData: TableData,
    allCells: AllCells
  ) => afterCellChanged(changes, cells, rowConfig, tableData, allCells, approaches);

  return new SpreadsheetConfig({
    name,
    parser: parser(),
    reverseParser: customReverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: customAfterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    tableTerms: {
      tableSlug: 'dcf-terminal-value-em-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    format: undefined,
    totalParser: undefined,
    ...spreadsheetProps,
  });
};

export const createTVTREMData = ({
  approach,
  approaches,
  approachOptions,
  mainTableName,
  dcfWaccSheet,
  dcfTVTRMSheetName,
  dcfTVTEMSheetName,
  dcfAttributes,
  benchmarkApproach,
  name: tableName,
  isDisabled,
}: TVTDataProps) => {
  const name = getApproachTableName({ approach, tableSuffix: TVT_REM_TABLE_NAME });
  const tvtType = TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;

  const spreadsheetProps = getTvtConfig({
    approach,
    approachOptions,
    mainTableName,
    tvtType,
    dcfWaccSheet,
    dcfTVTRMSheetName,
    dcfTVTEMSheetName,
    benchmarkApproach,
    isDisabled,
  });

  const customReverseParser = (reverseParserParams: CustomReverseParserParams) =>
    remReverseParser({
      ...reverseParserParams,
      approachOptions,
    });

  const customAfterCellChanged = (
    changes: Changes[],
    cells: Cells,
    rowConfig: RowConfigProps,
    tableData: TableData,
    allCells: AllCells
  ) => afterCellChanged(changes, cells, rowConfig, tableData, allCells, approaches);

  return new SpreadsheetConfig({
    name,
    parser,
    reverseParser: customReverseParser as unknown as SpreadsheetConfig['reverseParser'],
    afterCellChanged: customAfterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    tableData: { approach, approaches, tableName, isDisabled },
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    shouldValidate: approach?.valuations_approach_dcf?.terminal_value === tvtType,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    tableTerms: {
      tableSlug: 'dcf-terminal-value-rem-table',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    format: undefined,
    totalParser: undefined,
    ...spreadsheetProps,
  });
};
