import { range } from 'mathjs';
import {
  MA_COMPS,
  MEAN_LABEL,
  MEDIAN_LABEL,
  OTHER_LABEL,
  PERCENTILE_25,
  PERCENTILE_75,
  SPECIFIED_LABEL,
} from 'pages/Valuations/util/constants';
import {
  get25thPercentileExpression,
  get75thPercentileExpression,
  getCustomPercentileExpression,
  getMeanExpression,
  getMedianExpression,
} from 'pages/Valuations/util/util';
import { BenchmarkApproach, getApproachTableName, getCompaniesOrTransactions } from 'pages/ValuationsAllocation/util';
import { getBenchmarkColumnLegend } from './getParser';
import { GetExpressionByPercentileProps, GetFormulaExpressionProps } from './types';

const getExpressionByPercentile = (props: GetExpressionByPercentileProps) => {
  const { companiesRange, selectedMultiple, specifiedExpression } = props;
  switch (selectedMultiple) {
    case MEDIAN_LABEL:
      return getMedianExpression(companiesRange);
    case MEAN_LABEL:
      return getMeanExpression(companiesRange);
    case PERCENTILE_25:
      return get25thPercentileExpression(companiesRange);
    case PERCENTILE_75:
      return get75thPercentileExpression(companiesRange);
    case SPECIFIED_LABEL:
      return specifiedExpression;
    case OTHER_LABEL:
      return '';
    default:
      return getCustomPercentileExpression(companiesRange, selectedMultiple);
  }
};

const getFormulaExpression = (params: GetFormulaExpressionProps) => {
  const { approach, alias, columnLegend, selectedMultipleValue } = params;

  if (!approach) {
    return '';
  }

  const approachData = approach.valuations_approach_gpt ?? (approach.valuations_approach_gpc as BenchmarkApproach);

  const tableName = getApproachTableName({ approach });
  const approachType = approach.approach_type;

  const approachColumnLegend = getBenchmarkColumnLegend({ benchmarkType: approachType, rowAlias: alias, columnLegend });

  const companiesRows = getCompaniesOrTransactions(approachData);
  const companiesRange = range(1, companiesRows.length + 1)
    .map(rowNumber => `${tableName}.${approachColumnLegend}${rowNumber + 2}`)
    .toString()
    .replace(/"/g, '');
  const offset = approachType === MA_COMPS ? 11 : 9;
  const specifiedExpression = `=${tableName}.${approachColumnLegend}${companiesRows.length + offset}`;

  return getExpressionByPercentile({ companiesRange, selectedMultiple: selectedMultipleValue, specifiedExpression });
};

export default getFormulaExpression;
