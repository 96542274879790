/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import { uniqBy } from 'lodash';
import numeral from 'numeral';
import * as constants from 'common/constants/financials';
import { toString } from 'utilities';
import { sanitizeCellValue } from 'utilities/sanitizeCellValue';
import syncCell from './syncCell';
import updateTotalCellExpr from './updateTotalCellExpr';

const getProjectionFormula = ({ cell, row, percent, useDefault }) => {
  if (cell.isParent && useDefault) {
    return cell.defaultExpr;
  }

  const targetCol = cell.isParent ? 'prevYear' : 'prevQuarter';
  const prevColumn = cell[targetCol] + row;
  return `=${prevColumn}+${prevColumn}*${percent}`;
};

const getFutureCellsChanges = ({ cells, analyzedChange, percent, newChanges }) => {
  const { cell: changeCell } = analyzedChange;
  const { rowNumber: row, key } = changeCell;
  const currentIndex = Object.keys(cells).findIndex(item => item === key);

  Object.entries(cells).forEach(([, cell], index) => {
    const isSameRow = cell.rowNumber === row;
    const isNextRow = index > currentIndex;

    if (isSameRow && isNextRow && !cell.value && !(cell.isLTM || cell.isNTM)) {
      const expr = getProjectionFormula({
        cell,
        row,
        percent,
        useDefault: true,
      });
      newChanges.push({ cell, value: expr });
    }
  });
};

const removeRequiredUpdateStatus = ({ triggerCell, newValue, rowConfig, newStatusChanges, allCells }) => {
  // instead of update/add changes and populate changes on all related cell
  // we just change the state (cells)
  rowConfig.forEach((_, index) => {
    const { columnLegend, key: currentKey } = triggerCell;
    const rowNumber = index + 1;
    const key = columnLegend + rowNumber;

    if (allCells[key]) {
      const newCell = Object.assign(allCells[key], {
        ...allCells[key],
        className: '',
        needs_actualization: false,
        action: null,
        extraActions: null,
      });

      const valueToSet = currentKey === key ? newValue : allCells[key].expr;

      newStatusChanges.push({
        cell: newCell,
        value: valueToSet,
      });
    }
  });
};

const afterCellChanged = (changes, cells, rowConfig) => {
  const newChanges = [];
  const newStatusChanges = [];
  const [changedCell] = changes;
  const { cell, value } = changedCell;

  if (cell.needs_actualization) {
    removeRequiredUpdateStatus({
      triggerCell: cell,
      newValue: value,
      rowConfig,
      newStatusChanges,
      allCells: cells,
    });
  }

  changes.forEach(change => {
    const { cell: changeCell, value } = change;
    const { rowNumber: row } = changeCell;

    switch (changeCell.alias) {
      case constants.TOTAL_REVENUE_ALIAS: // Start Income Statement Cells
      case constants.TOTAL_COST_OF_SALES_ALIAS:
      case constants.OPERATING_EXPENSES_ALIAS:
      case constants.ADJUSTED_EBITDA_ALIAS:
      case constants.DEPRECIATION_EXPENSE_ALIAS:
      case constants.AMORTIZATION_EXPENSE_ALIAS:
      case constants.INTEREST_EXPENSE_ALIAS:
      case constants.OTHER_EXPENSE_ALIAS:
      case constants.INCOME_TAXES_ALIAS:
      case constants.CASH_AND_EQUIVALENTS_ALIAS: // Start Balance Sheet Cells
      case constants.ASSETS_ACCOUNTS_RECEIVABLE_ALIAS:
      case constants.ASSETS_INVENTORY_ALIAS:
      case constants.ASSETS_OTHER_CURRENT_ALIAS:
      case constants.ASSETS_PPE_ALIAS:
      case constants.ASSETS_INTANGIBLES_ALIAS:
      case constants.ASSETS_OTHER_LONG_TERM_ALIAS:
      case constants.ASSETS_TOTAL_LONG_TERM_ALIAS:
      case constants.ASSETS_TOTAL_ALIAS:
      case constants.LIABILITIES_ACCOUNT_PAYABLE_ALIAS:
      case constants.LIABILITIES_ACCRUED_LIABILITIES_ALIAS:
      case constants.LIABILITIES_SHORT_TERM_DEBT_ALIAS:
      case constants.LIABILITIES_DEFERRED_REVENUE_ALIAS:
      case constants.LIABILITIES_OTHER_CURRENT_ALIAS:
      case constants.LIABILITIES_TOTAL_CURRENT_ALIAS:
      case constants.LIABILITIES_LONG_TERM_DEBT_ALIAS:
      case constants.LIABILITIES_OTHER_LONG_TERM_ALIAS:
      case constants.LIABILITIES_TOTAL_LONG_TERM_ALIAS:
      case constants.LIABILITIES_TOTAL_ALIAS:
      case constants.EQUITY_TOTAL_ALIAS:
      case constants.TOTAL_DEBT_ALIAS:
        change = sanitizeCellValue(change);

        // Set expr on cells when related cell is changed and has a valid value
        updateTotalCellExpr({ cells, change, newChanges });

        // search key of changed cell in controller cell
        syncCell({ cells, currentChanges: changes, newChanges, analyzedChange: change, updateTotalCellExpr });

        // If the inputted value contain a % at the end
        const matches = toString(value).match(/\d*\.?\d+%/);

        /* It fires the cells updates if match a percent,
         and if the changeCell is a projection */
        if (matches && changeCell.isProjectionPeriod) {
          // Convert the string to a valid percent (eg. 50% becomes 0.5)
          const percent = numeral(value).value();

          // Generate the formula eg. =PREV_COL+PREV_COL*PERCENT => =B4+B4*0.5
          const expr = getProjectionFormula({ cell: changeCell, row, percent });

          // Replace the inputted value and use the formula instead
          newChanges.push({ cell: changeCell, value: expr });

          // Perform more cells updates in empty future columns
          getFutureCellsChanges({ cells, analyzedChange: change, percent, newChanges });
        }
        break;
      default:
        break;
    }
  });

  return uniqBy(newChanges.length === 0 ? changes : newChanges, 'cell.key');
};

export default afterCellChanged;
