import { FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX } from 'pages/Funds/common/constants/fundSummary';
import {
  generateColumnKey,
  getBooleanValue,
  getNumberOrNAValue,
  getNumberValue,
  getStringValue,
  shortDate,
} from 'utilities';
import { CreateColumns, FundSummaryColumn } from './types';

const createColumns: CreateColumns = params => {
  const { companies, isProcessManagementEnabled } = params;

  // Process Management
  const companiesProcessManagement = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        name: getStringValue(current?.company_name),
        slug: getStringValue(current?.company_slug),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Investment Date
  const companiesInvestmentDate = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.initial_investment_date)),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Most Recent Investment Date
  const companiesMostRecentInvestmentDate = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        value: getStringValue(shortDate(current?.most_recent_investment_date)),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Fully Diluted Ownership %
  const companiesFullyDilutedOwnership = companies.reduce((accumulator, current) => {
    const hasOnlyCustomOwnerships = getBooleanValue(current?.has_only_custom_ownerships);

    return {
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: hasOnlyCustomOwnerships,
        value: hasOnlyCustomOwnerships ? 0 : getNumberValue(current?.diluted_ownership),
      },
    };
  }, {}) as FundSummaryColumn;

  // Invested Capital
  const companiesInvestedCapital = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: getNumberValue(current?.fund_cost_basis),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Realized Value
  const companiesRealizedValue = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: getNumberValue(current?.realized_value),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Unrealized Equity
  const companiesUnrealizedEquity = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: getNumberValue(current?.unrealized_value),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Total Value
  const companiesTotalValue = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: getNumberValue(current?.total_value),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // Gross IRR
  const companiesGrossIRR = companies.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: getNumberOrNAValue(current?.gross_irr),
      },
    }),
    {}
  ) as FundSummaryColumn;

  // MOIC
  const companiesMOIC = companies.reduce((accumulator, current) => {
    const companyInvestedCapital = getNumberValue(current?.fund_cost_basis);
    const companyTotalValue = getNumberValue(current?.total_value);

    return {
      ...accumulator,
      [generateColumnKey({
        id: current?.cap_table,
        name: current?.company_slug,
        prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        disabled: !current?.is_published,
        value: companyInvestedCapital > 0 ? companyTotalValue / companyInvestedCapital : 0,
      },
    };
  }, {}) as FundSummaryColumn;

  const columns = [
    companiesInvestmentDate,
    companiesMostRecentInvestmentDate,
    companiesFullyDilutedOwnership,
    companiesInvestedCapital,
    companiesRealizedValue,
    companiesUnrealizedEquity,
    companiesTotalValue,
    companiesGrossIRR,
    companiesMOIC,
  ];

  return isProcessManagementEnabled ? [companiesProcessManagement, ...columns] : columns;
};

export default createColumns;
