import React, { FC, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet, EmptyTableMessage } from 'components';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { VALUATIONS_SPREADSHEET_TABLE_TERMS } from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  VALUATION_SUMMARY_KEY,
  VALUATION_SUMMARY_SPREADSHEET_SLUG,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getObjectValue, getStringValue } from 'utilities';
import useStyles from './styles';
import { ValuationSummaryProps } from './types';

const VALUATION_SUMMARY_CONTAINER_CLASS = `${VALUATIONS_SLUG}-${VALUATION_SUMMARY_SPREADSHEET_SLUG}-container`;

// Group Rows
const { VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD } = SHEET_ALIASES_CONSTANTS;
const ROW_GROUPS_INITIAL_STATE = Object.freeze({ [VALUATION_SUMMARY_SPREADSHEET_ALLOCATION_METHOD]: SYSTEM_EXPAND });

// Delays
const LOADING_SKELETON_TIMEOUT_DELAY = 500;

const ValuationSummary: FC<ValuationSummaryProps> = props => {
  const {
    collapsibleColumns,
    isLoading,
    isLoadingValuationSummary,
    isSaving,
    isValuationEmpty,
    onSpreadsheetChange,
    setCollapsibleColumns,
    setIsLoadingValuationSummary,
    workbook,
  } = props;

  const { [VALUATION_SUMMARY_KEY]: valuationSummarySpreadsheet } = getObjectValue(workbook);
  const { data: valuationSummarySpreadsheetData } = getObjectValue(valuationSummarySpreadsheet);

  const classes = useStyles();

  const [format, formatDispatch] = useFormat({ page: CAP_TABLE_CURRENCY_PAGE }) as UseFormatValues;

  // Group Rows
  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, ROW_GROUPS_INITIAL_STATE);

  const onChange = useCallback(
    (cell, value) => {
      onSpreadsheetChange?.(cell, value);
    },
    [onSpreadsheetChange]
  );

  // Create Valuation Summary Sheet props
  const valuationSummarySheetProps = useMemo(() => {
    if (valuationSummarySpreadsheet) {
      return {
        ...valuationSummarySpreadsheet,
        collapsibleColumns,
        format,
        formatDispatch,
        key: getStringValue(valuationSummarySpreadsheet?.name),
        onChange,
        rowGroups,
        setCollapsibleColumns,
        setRowGroups,
        sheet: valuationSummarySpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [
    collapsibleColumns,
    format,
    formatDispatch,
    onChange,
    rowGroups,
    setCollapsibleColumns,
    valuationSummarySpreadsheet,
    workbook,
  ]);

  // Handle transition from GridSkeleton to ValuationSummary
  useEffect(() => {
    let displayLoadingTimer: NodeJS.Timeout;

    if (isEmpty(valuationSummarySpreadsheet) || isEmpty(valuationSummarySpreadsheetData)) {
      setIsLoadingValuationSummary?.(true);
    } else {
      displayLoadingTimer = setTimeout(() => {
        setIsLoadingValuationSummary?.(false);
      }, LOADING_SKELETON_TIMEOUT_DELAY);
    }

    return () => clearTimeout(displayLoadingTimer);
  }, [setIsLoadingValuationSummary, valuationSummarySpreadsheet, valuationSummarySpreadsheetData]);

  // Loading Valuation Summary
  if (isLoadingValuationSummary) {
    return (
      <Grid className={VALUATION_SUMMARY_CONTAINER_CLASS}>
        <GridSkeleton />
      </Grid>
    );
  }

  // Display empty Valuations message if there are no Valuation Approaches
  if (isValuationEmpty) {
    return (
      <Grid className={clsx(VALUATION_SUMMARY_CONTAINER_CLASS, classes.emptyMessageContainer)}>
        <EmptyTableMessage tableTerms={VALUATIONS_SPREADSHEET_TABLE_TERMS} />
      </Grid>
    );
  }

  return (
    <Grid className={VALUATION_SUMMARY_CONTAINER_CLASS}>
      <CustomScalarSpreadsheet
        {...valuationSummarySheetProps}
        isLoading={isLoading || isLoadingValuationSummary}
        isSaving={isSaving}
      />
    </Grid>
  );
};

export default ValuationSummary;
