import React, { useCallback, useState } from 'react';
import { DialogContent, IconButton, Paper, Popper, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircleOutlined,
  Close,
  Delete,
  Edit,
  HowToReg,
  Mail,
  MoreHoriz,
  RemoveCircleOutline,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { userSelectedProfileUrl } from 'common/config/urls';
import { HAS_NOT_LOGGED_IN_YET } from 'common/constants/user';
import { AuthService } from 'services';
import { useResponse } from 'services/hooks';
import { useRemoveUserFromFirm } from 'services/hooks/useManageUser';
import { useUpdateUserProfile } from 'services/hooks/useUserSelected';
import { dbShortDate } from 'utilities';

const useStyles = makeStyles(theme => ({
  inactiveUser: {
    color: theme.palette.grey[400],
  },
  tableCell: {
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    top: '0.25rem',
    right: '0.25rem',
    padding: '0.125rem',
  },
}));

const UserRow = ({ user, setCurrentUser, isSuperUser, firm, isFirmAdmin, togglePrimaryUser }) => {
  const { successNotification, errorNotification } = useResponse();
  const [openActionsDialog, setOpenActionsDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { email, last_login: lastLogin } = user;
  const history = useHistory();
  const { mutate: removeUserFromFirm } = useRemoveUserFromFirm();
  const { mutate: activateUser } = useUpdateUserProfile();

  const authService = AuthService.getInstance();

  const handleMoreClick = event => {
    setOpenActionsDialog(!openActionsDialog);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDialogClose = useCallback(() => {
    setOpenActionsDialog(false);
    setAnchorEl(null);
  }, []);

  const goToUserSettings = () => {
    const selectedUserSettings = userSelectedProfileUrl(firm.slug, user.id);
    history.push(selectedUserSettings);
  };

  const deleteUser = async () => {
    removeUserFromFirm(
      {
        firm_id: firm.id,
        user_id: user.id,
      },
      {
        onSuccess: () => {
          successNotification('User was removed from the firm successfully');
          handleDialogClose();
          setCurrentUser();
        },
        onError: () => {
          errorNotification('An error occurred while removing the user from firm');
        },
      }
    );
  };

  const activateUserHandler = useCallback(async () => {
    activateUser(
      {
        userId: user.id,
        updatedUserProfile: {
          username: user.username,
          email: user.email,
          is_active: !user.is_active,
        },
      },
      {
        onSuccess: () => {
          successNotification(`User was ${user.is_active ? 'disabled' : 'activated'} successfully`);
          handleDialogClose();
        },
        onError: () => {
          errorNotification('An error occurred while activating the user');
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateUser, user]);

  const resendPasswordResetOrActivation = useCallback(async () => {
    try {
      if (user.is_active) {
        await authService.sendPasswordReset({ email: user.email });
        successNotification('Password reset email was sent successfully');
      } else {
        await authService.resendActivationEmail(user.email);
        successNotification('Activation email was sent successfully');
      }
    } catch (error) {
      errorNotification('An error occurred while resending the email');
    } finally {
      handleDialogClose();
    }
  }, [authService, errorNotification, handleDialogClose, successNotification, user]);

  const onTogglePrimaryUser = useCallback(() => {
    togglePrimaryUser(user).then(() => {
      handleDialogClose();
    });
  }, [user, togglePrimaryUser, handleDialogClose]);

  return (
    <TableRow onClick={() => setCurrentUser(user)}>
      <TableCell className={`classes.tableCell ${!user.is_active ? classes.inactiveUser : ''}`}>{email}</TableCell>
      <TableCell className={`classes.tableCell ${!user.is_active ? classes.inactiveUser : ''}`}>
        {lastLogin ? dbShortDate(lastLogin, 'MM/DD/YYYY') : HAS_NOT_LOGGED_IN_YET}
      </TableCell>
      {(isSuperUser || isFirmAdmin) && (
        <TableCell className={classes.tableCell}>
          <Tooltip title="More">
            <IconButton aria-label="more" onClick={handleMoreClick}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>
          <Popper open={openActionsDialog} anchorEl={anchorEl} placement="top">
            <Paper>
              <DialogContent>
                {isSuperUser && (
                  <Tooltip title={user.is_active ? 'Disable user' : 'Activate user'}>
                    <IconButton aria-label="delete" onClick={activateUserHandler}>
                      {user.is_active ? <RemoveCircleOutline /> : <HowToReg />}
                    </IconButton>
                  </Tooltip>
                )}
                {togglePrimaryUser && (
                  <Tooltip title="Set As Primary Contact">
                    <IconButton aria-label="primary_user" onClick={onTogglePrimaryUser}>
                      <CheckCircleOutlined color={user.is_primary_contact ? 'primary' : ''} />
                    </IconButton>
                  </Tooltip>
                )}
                {firm && (
                  <Tooltip title={`Edit ${!isSuperUser ? ' is disabled' : ''}`}>
                    <div style={{ display: 'inline' }}>
                      <IconButton aria-label="edit" onClick={goToUserSettings} disabled={!isSuperUser}>
                        <Edit />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
                {firm && (
                  <Tooltip title="Remove from firm">
                    <IconButton aria-label="delete" onClick={deleteUser}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={user.is_active ? 'Send password reset' : 'Resend account activation'}>
                  <IconButton aria-label="resend" onClick={resendPasswordResetOrActivation}>
                    <Mail />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close">
                  <IconButton aria-label="close" onClick={handleDialogClose} className={classes.closeButton}>
                    <Close fontSize="small" />
                  </IconButton>
                </Tooltip>
              </DialogContent>
            </Paper>
          </Popper>
        </TableCell>
      )}
    </TableRow>
  );
};

UserRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    last_login: PropTypes.string,
    is_active: PropTypes.bool.isRequired,
    is_primary_contact: PropTypes.bool.isRequired,
  }).isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  isFirmAdmin: PropTypes.bool.isRequired,
  firm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  togglePrimaryUser: PropTypes.func,
};

export default UserRow;
