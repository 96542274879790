import React, { FC, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { globalAction } from 'common/actions';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { FUNDS_VALUATIONS_SUMMARY_TITLE } from 'common/constants/funds';
import { useFormat } from 'common/hooks';
import { useStore } from 'common/store';
import { UseFormatValues } from 'common/types/format';
import { UseStoreValues } from 'common/types/store';
import { CustomScalarSpreadsheet, MessageBox } from 'components';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import {
  VALUATIONS_SUMMARY_NO_PUBLISHED_ALLOCATIONS_FOUND,
  VALUATIONS_SUMMARY_NO_VALUATION_METHODS_PROVIDED,
} from 'pages/Funds/common/constants/valuationsSummary';
import { InformationMessage, SectionHeading } from 'pages/Funds/components';
import { useValuationsSummarySheet } from 'pages/Funds/hooks';
import { useGetFundAllocationsByMD } from 'services/hooks/funds';
import { getArrayValue, getBooleanValue } from 'utilities';
import { ValuationsSummaryProps } from './types';

const ValuationsSummary: FC<ValuationsSummaryProps> = props => {
  const { fund, measurementDate } = props;

  const [, dispatch] = useStore() as unknown as UseStoreValues;

  const { data: fundAllocations, isFetching, isLoading } = useGetFundAllocationsByMD({ fund, measurementDate });

  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: fund?.currency?.toString(),
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  const { spreadsheet: valuationsSummarySheet } = useValuationsSummarySheet({
    format,
    fund,
    fundAllocations,
    measurementDate,
  });

  const spreadsheets = useMemo(
    () => (valuationsSummarySheet?.yLength ? [valuationsSummarySheet] : []),
    [valuationsSummarySheet]
  );

  const { onChange, workbook } = useWorkbook(spreadsheets);

  // Create valuationsSummarySheet props
  const valuationsSummarySheetProps = useMemo(() => {
    if (valuationsSummarySheet)
      return {
        ...valuationsSummarySheet,
        data: getArrayValue(valuationsSummarySheet?.data),
        format,
        formatDispatch,
        onChange,
        sheet: valuationsSummarySheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;

    return null;
  }, [format, formatDispatch, onChange, valuationsSummarySheet, workbook]);

  // If at least one Allocation has only Custom Ownerships, display the message
  const shouldDisplayNoValuationMethodsMessage = useMemo(
    () =>
      getBooleanValue(
        fundAllocations?.allocations?.some(allocationItem => allocationItem?.allocation?.has_only_custom_ownerships)
      ),
    [fundAllocations]
  );

  // Show Loading Progress
  useEffect(() => {
    dispatch?.(globalAction.showLoadingProgress(isLoading));
  }, [dispatch, isLoading]);

  // Loading Grid Skeleton while fetching data
  if (isLoading) return <GridSkeleton maxColumns={10} maxRows={4} />;

  // Show message if no published Allocations found
  if (isEmpty(fundAllocations?.allocations))
    return <MessageBox title={VALUATIONS_SUMMARY_NO_PUBLISHED_ALLOCATIONS_FOUND} fullWidth={false} />;

  return (
    <Grid>
      {valuationsSummarySheetProps && valuationsSummarySheet && !isEmpty(fundAllocations?.allocations) && (
        <>
          <SectionHeading title={FUNDS_VALUATIONS_SUMMARY_TITLE} />

          <CustomScalarSpreadsheet {...valuationsSummarySheetProps} isLoading={isFetching} />

          <InformationMessage
            displayMessage={shouldDisplayNoValuationMethodsMessage}
            message={VALUATIONS_SUMMARY_NO_VALUATION_METHODS_PROVIDED}
          />
        </>
      )}
    </Grid>
  );
};

export default ValuationsSummary;
