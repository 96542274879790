import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { customParser } from './config/customParser';
import { customReverseParser } from './config/customReverseParser';
import { getRowConfig } from './config/rowConfig';
import { SecuritySharesTableProps } from './types';

const createSecuritySharesTable = ({
  capTable,
  approach,
  hideEnterpriseValueRow,
  isDisabled,
}: SecuritySharesTableProps) => {
  const name = getApproachTableName({ approach });
  const columns = [{}, {}, {}, {}];
  const rowConfig = getRowConfig({
    securities: capTable.securities ?? [],
    hideEnterpriseValueRow,
    isDisabled,
  });

  return new SpreadsheetConfig({
    name,
    currencyFormatter: true,
    format: undefined,
    totalParser: undefined,
    unitsFormatter: true,
    parser: customParser,
    reverseParser: customReverseParser as unknown as SpreadsheetConfig['reverseParser'],
    rowConfig,
    initialObj: approach as unknown as SpreadsheetConfig['initialObj'],
    tableData: { capTable, securities: capTable.securities, isDisabled },
    columns,
    showTitlesColumn: false,
    showToolbar: true,
    allowCopyColumn: false,
  });
};

export default createSecuritySharesTable;
