import { isNil } from 'lodash';
import moment from 'moment';
import { DCFFinancialPeriod } from 'api';
import { FinancialStatementsBasic } from 'common/types/financials';
import template from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcf/config/template';
import { getNumberValue } from 'utilities';

const generateDCFFinancialPeriods = (financialStatementPeriods?: FinancialStatementsBasic['fiscal_years']) => {
  if (isNil(financialStatementPeriods)) return [];

  return financialStatementPeriods?.map(financialStatementPeriod => {
    const date = moment(financialStatementPeriod.statement_date);
    const year = date.year();

    return {
      ...template,
      date: date.format('YYYY-MM-DD'),
      ebit_amortization_expenses_total: financialStatementPeriod?.income_statement?.amortization_expense ?? '0',
      ebit_depreciation_expenses_total: financialStatementPeriod?.income_statement?.depreciation_expense ?? '0',
      ebit_total: financialStatementPeriod?.income_statement?.ebit ?? null,
      ebitda_total: financialStatementPeriod?.income_statement?.ebitda ?? null,
      financial_statement_period_id: getNumberValue(financialStatementPeriod?.id),
      financial_statement_period: financialStatementPeriod,
      revenue_total: financialStatementPeriod?.income_statement?.total_revenue ?? null,
      year,
    } as DCFFinancialPeriod;
  });
};

export default generateDCFFinancialPeriods;
