import { VALUATIONS_PERCENTILE_LABEL } from 'common/constants/valuations';
import { getNumberValue } from 'utilities';

const ORDINAL_NUMBERS_EXCEPTIONS = [11, 12, 13];
const ORDINAL_NUMBERS_SUFFIXES = { '1': 'st', '2': 'nd', '3': 'rd' };

export const getPercentileLabel = (index?: number) => {
  const percentile = (getNumberValue(index) + 1).toString();
  const lastPartOfIndex = percentile[percentile.length - 1];

  const percentileSuffix = ORDINAL_NUMBERS_EXCEPTIONS.includes(getNumberValue(index))
    ? 'th'
    : ORDINAL_NUMBERS_SUFFIXES?.[lastPartOfIndex as keyof typeof ORDINAL_NUMBERS_SUFFIXES] ?? 'th';

  return `${percentile}${percentileSuffix} ${VALUATIONS_PERCENTILE_LABEL}`;
};

export default getPercentileLabel;
