import { ADJUSTED_EBITDA_TITLE } from 'common/constants/financials';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_CSE_NAME,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_OPM_NAME,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getSlugValue } from 'utilities';
import {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
} from './sheetAliases';

export const FUTURE_EXIT_KEY = 'futureExit';
export const FUTURE_EXIT_LABEL = 'Future Exit';
export const FUTURE_EXIT_SLUG = FUTURE_EXIT_KEY;
export const FUTURE_EXIT_SPREADSHEET_COLUMN_KEY_PREFIX = 'approach';
export const FUTURE_EXIT_SPREADSHEET_COLUMN_NAME = 'Column';
export const FUTURE_EXIT_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';

export const FE_MODIFIED_PRESENT_EQUITY_VALUE_KEY = 'modifiedPresentEquityValue';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE = 'Modified Present Equity Value';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_SLUG = getSlugValue(
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE
);
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TABLE = `${FUTURE_EXIT_SLUG}-${FE_MODIFIED_PRESENT_EQUITY_VALUE_KEY}-table`;
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY = 'present_equity_value';

export const FE_FUTURE_EQUITY_VALUE_KEY = 'futureEquityValue';
export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE = 'Future Equity Value';
export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET_SLUG = getSlugValue(FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE);
export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TABLE = `${FUTURE_EXIT_SLUG}-${FE_FUTURE_EQUITY_VALUE_KEY}-table`;
export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY = 'future_equity_value';

// Allocation Method - CSE
export const FE_ALLOCATION_METHOD_WATERFALL_CSE_KEY = 'allocationMethodWaterfallCSE';
export const FE_ALLOCATION_METHOD_WATERFALL_CSE_TITLE = 'Allocation Method Waterfall CSE';
export const FE_ALLOCATION_METHOD_WATERFALL_CSE_SLUG = getSlugValue(FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE);
export const FE_ALLOCATION_METHOD_WATERFALL_CSE_TABLE = `${FUTURE_EXIT_SLUG}-${FE_FUTURE_EQUITY_VALUE_KEY}-table`;

// Allocation Method - CSE
export const FE_ALLOCATION_METHOD_OPM_KEY = 'allocationMethodOPM';
export const FE_ALLOCATION_METHOD_OPM_TITLE = 'Allocation Method OPM';
export const FE_ALLOCATION_METHOD_OPM_SLUG = getSlugValue(FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE);
export const FE_ALLOCATION_METHOD_OPM_TABLE = `${FUTURE_EXIT_SLUG}-${FE_FUTURE_EQUITY_VALUE_KEY}-table`;

// Keys
export const FUTURE_VALUE_TO_CURRENT_SHAREHOLDERS_CUSTOM_KEY = 'FUTURE_VALUE';

export const SPECIFIED_MULTIPLE = 'Specified Multiple';
export const SPECIFIED_LABEL = 'Specified';

export const LTM_REVENUE = 'LTM Revenue';
export const NTM_REVENUE = 'NTM Revenue';
export const LTM_EBITDA = 'LTM EBITDA';
export const NTM_EBITDA = 'NTM EBITDA';
export const SPECIFIED_LTM_REVENUE = 'Specified LTM Revenue';
export const SPECIFIED_NTM_REVENUE = 'Specified NTM Revenue';
export const SPECIFIED_LTM_EBITDA = 'Specified LTM EBITDA';
export const SPECIFIED_NTM_EBITDA = 'Specified NTM EBITDA';
export const LTM_ADJUSTED_EBITDA = `LTM ${ADJUSTED_EBITDA_TITLE}`;
export const NTM_ADJUSTED_EBITDA = `NTM ${ADJUSTED_EBITDA_TITLE}`;

export const METRIC_OPTION_LABELS = [
  LTM_REVENUE,
  NTM_REVENUE,
  LTM_EBITDA,
  NTM_EBITDA,
  LTM_ADJUSTED_EBITDA,
  NTM_ADJUSTED_EBITDA,
  SPECIFIED_LTM_REVENUE,
  SPECIFIED_NTM_REVENUE,
  SPECIFIED_LTM_EBITDA,
  SPECIFIED_NTM_EBITDA,
];

export const METRIC_OPTION_MAP = {
  [LTM_REVENUE]: LTM_REVENUE,
  [NTM_REVENUE]: NTM_REVENUE,
  [LTM_EBITDA]: LTM_EBITDA,
  [NTM_EBITDA]: NTM_EBITDA,
  [LTM_ADJUSTED_EBITDA]: LTM_ADJUSTED_EBITDA,
  [NTM_ADJUSTED_EBITDA]: NTM_ADJUSTED_EBITDA,
  [SPECIFIED_LTM_REVENUE]: LTM_REVENUE,
  [SPECIFIED_NTM_REVENUE]: NTM_REVENUE,
  [SPECIFIED_LTM_EBITDA]: LTM_EBITDA,
  [SPECIFIED_NTM_EBITDA]: NTM_EBITDA,
};

export const METRIC_OPTIONS = METRIC_OPTION_LABELS.map((label, index) => ({
  label,
  value: index,
}));

export const SELECTED_MULTIPLE = 'selected_multiple';
export const MEAN_LOWERCASE = 'mean';
export const MEDIAN_LOWERCASE = 'median';

export const FUTURE_EQUITY_VALUE_TABLE_NAME = 'Future Equity Value';
export const FUTURE_EQUITY_VALUE_TABLE_SLUG = 'future-equity-value';

export const ALLOCATION_METHOD_OPTIONS = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME]: ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
  [ALLOCATION_SCENARIO_METHOD_CSE_NAME]: ALLOCATION_SCENARIO_METHOD_CSE_NAME,
  [ALLOCATION_SCENARIO_METHOD_OPM_NAME]: ALLOCATION_SCENARIO_METHOD_OPM_NAME,
};

export const ALLOCATION_METHOD_OPTIONS_VALUES = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME]: ALLOCATION_SCENARIO_METHOD_WATERFALL,
  [ALLOCATION_SCENARIO_METHOD_CSE_NAME]: ALLOCATION_SCENARIO_METHOD_CSE,
  [ALLOCATION_SCENARIO_METHOD_OPM_NAME]: ALLOCATION_SCENARIO_METHOD_OPM,
};

export const ALLOCATION_METHOD_OPTIONS_KEYS = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL]: ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME,
  [ALLOCATION_SCENARIO_METHOD_CSE]: ALLOCATION_SCENARIO_METHOD_CSE_NAME,
  [ALLOCATION_SCENARIO_METHOD_OPM]: ALLOCATION_SCENARIO_METHOD_OPM_NAME,
};

export type AllocationMethodsMapKeys = {
  [key in
    | typeof ALLOCATION_SCENARIO_METHOD_WATERFALL
    | typeof ALLOCATION_SCENARIO_METHOD_CSE
    | typeof ALLOCATION_SCENARIO_METHOD_OPM]: string;
};

export type AllocationMethodsMapValues = {
  [key in
    | typeof ALLOCATION_SCENARIO_METHOD_WATERFALL_NAME
    | typeof ALLOCATION_SCENARIO_METHOD_CSE_NAME
    | typeof ALLOCATION_SCENARIO_METHOD_OPM_NAME]: number;
};

export const SCENARIO_VALUES_DISPATCH_CELLS = [
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY,
];

export const ALLOCATION_METHOD_SPREADSHEET_KEYS = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL]: 'allocationMethodWaterfallCSESheet',
  [ALLOCATION_SCENARIO_METHOD_CSE]: 'allocationMethodWaterfallCSESheet',
  [ALLOCATION_SCENARIO_METHOD_OPM]: 'allocationMethodOPMSheet',
};

export const ALLOCATION_METHOD_SPREADSHEET_KEYS_REVERSE = {
  [ALLOCATION_SCENARIO_METHOD_WATERFALL]: 'allocationMethodOPMSheet',
  [ALLOCATION_SCENARIO_METHOD_CSE]: 'allocationMethodOPMSheet',
  [ALLOCATION_SCENARIO_METHOD_OPM]: 'allocationMethodWaterfallCSESheet',
};
