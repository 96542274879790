import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { sendButtonEvent, sendPageView } from 'scalarAnalytics/googleAnalytics';

const useAnalytics = () => {
  const { pathname, search } = useLocation();
  const currentPath = useMemo(() => pathname + search, [pathname, search]);
  const slug = useMemo(() => pathname.substring(pathname.lastIndexOf('/') + 1), [pathname]);

  useEffect(() => {
    sendPageView(currentPath, slug);
  }, [currentPath, slug]);

  useEffect(() => {
    const handleButtonClick = (event: Event) => {
      const target = event.target as HTMLElement;
      const button = target.closest('button') as HTMLButtonElement | null;

      if (button) {
        sendButtonEvent(currentPath, button.innerText);
      }
    };

    document.addEventListener('click', handleButtonClick);

    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, [currentPath]);
};

export default useAnalytics;
