import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { YEARFRAC_FORMATS } from 'common/formulas/math.js/YEARFRAC';
import { DISPLAY_DATE_FORMAT } from 'pages/Financials/data';
import { ScenarioWithIdentifier } from 'services/hooks/allocations';
import { getNumberValue } from 'utilities';
import {
  AreScenarioValidValuesParams,
  GetScenarioWithIdentifierParams,
  IsBacksolveValidParams,
  IsFutureExitValidParams,
  IsSpecifiedShareValuesValidParams,
} from './types';

const isSpecifiedShareValuesValid = (params: IsSpecifiedShareValuesValidParams) => {
  const { isSpecifiedShareValues, specifiedShareValuesApproach, specifiedShareValuesPresentValues } = params;

  // Validating Specified Share Values
  if (isSpecifiedShareValues && isEmpty(specifiedShareValuesApproach)) {
    return false;
  }
  if (isSpecifiedShareValues && isEmpty(specifiedShareValuesPresentValues)) {
    return false;
  }

  return true;
};

const isFutureExitValid = (params: IsFutureExitValidParams) => {
  const { discountRate, exitDate, futureExitValuationApproach, isFutureExit } = params;

  // Validating Future Exit
  if (isFutureExit && isEmpty(futureExitValuationApproach)) {
    return false;
  }
  if (isFutureExit && isNil(discountRate || exitDate)) {
    return false;
  }

  return true;
};

const isBacksolveValid = (params: IsBacksolveValidParams) => {
  const { backsolveDate, backsolveValuationApproach, isBacksolve, isOPM } = params;

  // Validating Backsolve
  if (isBacksolve && isEmpty(backsolveValuationApproach)) {
    return false;
  }
  if (isOPM && isBacksolve && isNil(backsolveDate)) {
    return false;
  }

  return true;
};

const areScenarioValidValues = (params: AreScenarioValidValuesParams) => {
  const {
    backsolveDate,
    backsolveValuationApproach,
    discountRate,
    exitDate,
    futureExitValuationApproach,
    isBacksolve,
    isFutureExit,
    isOPM,
    isSpecifiedShareValues,
    maturity,
    scenarioEquityValue,
    specifiedShareValuesApproach,
    specifiedShareValuesPresentValues,
    volatility,
  } = params;

  // Validating Equity Value
  if (getNumberValue(scenarioEquityValue) <= 0) {
    return false;
  }

  // Validating OPM
  if (isOPM && (getNumberValue(maturity) <= 0 || getNumberValue(volatility) <= 0)) {
    return false;
  }

  // Validating Backsolve
  if (!isBacksolveValid({ backsolveDate, backsolveValuationApproach, isBacksolve, isOPM })) {
    return false;
  }

  // Validating Future Exit
  if (!isFutureExitValid({ discountRate, exitDate, futureExitValuationApproach, isFutureExit })) {
    return false;
  }

  // Validating Specified Share Values
  if (
    !isSpecifiedShareValuesValid({
      isSpecifiedShareValues,
      specifiedShareValuesApproach,
      specifiedShareValuesPresentValues,
    })
  ) {
    return false;
  }

  return true;
};

const getScenarioWithIdentifier = (params: GetScenarioWithIdentifierParams) => {
  const {
    backsolveAllocationMethods,
    backsolveCapTable,
    backsolveDate,
    backsolvesEquityValues,
    backsolveValuationApproach,
    backsolveValuationApproachId,
    discountRate,
    exitDate,
    futureExitCapTable,
    futureExitValuationApproach,
    isBacksolve,
    isFutureExit,
    isOPM,
    isSpecifiedShareValues,
    maturity,
    scenarioCapTable,
    scenarioEquityValue,
    scenarioIdOrRef,
    scenarioMethod,
    scenarioType,
    specifiedShareValuesApproach,
    specifiedShareValuesCapTable,
    specifiedShareValuesPresentValues,
    volatility,
  } = params;

  if (
    !areScenarioValidValues({
      backsolveDate,
      backsolveValuationApproach,
      discountRate,
      exitDate,
      futureExitValuationApproach,
      isBacksolve,
      isFutureExit,
      isOPM,
      isSpecifiedShareValues,
      maturity,
      scenarioEquityValue,
      specifiedShareValuesApproach,
      specifiedShareValuesPresentValues,
      volatility,
    })
  ) {
    return null;
  }

  return {
    backsolveDate: isOPM && isBacksolve ? backsolveDate : null,
    captableId: getNumberValue(
      backsolveCapTable ?? futureExitCapTable ?? specifiedShareValuesCapTable ?? scenarioCapTable
    ),
    discountRate: isFutureExit ? discountRate : null,
    equityValue: isBacksolve
      ? backsolvesEquityValues?.[getNumberValue(backsolveValuationApproachId)] ?? scenarioEquityValue
      : scenarioEquityValue,
    exitDate: isFutureExit ? moment(exitDate, YEARFRAC_FORMATS).format(DISPLAY_DATE_FORMAT) : null,
    isBacksolve,
    maturity: isOPM ? maturity : '0',
    presentValues: isSpecifiedShareValues ? specifiedShareValuesPresentValues : null,
    scenarioIdOrRef,
    scenarioMethods: isBacksolve ? [...(backsolveAllocationMethods as number[])] : [getNumberValue(scenarioMethod)],
    scenarioType,
    volatility: isOPM ? volatility : '0',
  } as ScenarioWithIdentifier;
};

export default getScenarioWithIdentifier;
