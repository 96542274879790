import { useCallback, useContext } from 'react';
import { isEmpty } from 'lodash';
import { LayoutContextValues } from 'common/types/store';
import { LayoutContext } from 'context';
import { useUpdateDateOnQueryString } from 'pages/Funds/hooks';
import { checkEmptyObject, extractDate, getObjectValue } from 'utilities';
import { SetInitialMeasurementDateHook, UseSetInitialMeasurementDate } from './types';

const useSetInitialMeasurementDate: UseSetInitialMeasurementDate = params => {
  const { dateQueryString, measurementDates, openedMeasurementDate, setSelectedMeasurementDate } = params;

  const { commonMeasurementDate, setCommonMeasurementDate } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues;

  const { updateDateOnQueryString } = useUpdateDateOnQueryString();

  const setInitialMeasurementDate: SetInitialMeasurementDateHook['setInitialMeasurementDate'] = useCallback(() => {
    const dateSlugParam = extractDate({
      dateSlug: dateQueryString,
    });

    const dateToMatch: Date = commonMeasurementDate ?? dateSlugParam;
    const firstMeasurementDate = (measurementDates?.length && measurementDates[0]) || {};
    const sharedMeasurementDate = getObjectValue(
      measurementDates?.find(measurementDate => measurementDate.date === dateToMatch)
    );

    const defaultMD
      = checkEmptyObject(sharedMeasurementDate)
      ?? checkEmptyObject(openedMeasurementDate)
      ?? getObjectValue(firstMeasurementDate);

    if (!isEmpty(defaultMD)) {
      const { date, slug } = defaultMD;

      if (date) setCommonMeasurementDate?.(date);
      if (slug) updateDateOnQueryString({ date: slug });

      setSelectedMeasurementDate(defaultMD);
    }
  }, [
    commonMeasurementDate,
    dateQueryString,
    measurementDates,
    openedMeasurementDate,
    setCommonMeasurementDate,
    setSelectedMeasurementDate,
    updateDateOnQueryString,
  ]);

  return {
    setInitialMeasurementDate,
  };
};

export default useSetInitialMeasurementDate;
