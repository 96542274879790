import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { Cells, RowConfigProps } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import {
  APPROACH_SELECTIONS_ALIASES,
  EBITDA_GPC_MULTIPLE,
  REM_TVT_SECOND_COLUMN,
  REVENUE_GPC_MULTIPLE,
  SELECT_MULTIPLE_ALIASES,
  TERMINAL_VALUE,
} from 'pages/Valuations/util/constants';
import {
  DCF_SUMMARY_TABLE_NAME,
  PV_OF_TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE_CUSTOM_KEY,
  TERMINAL_VALUE_EXPR_CUSTOM_KEY,
  TERMINAL_VALUE_OPTIONS,
  TVT_EM_TABLE_NAME,
  TVT_HM_TABLE_NAME,
  TVT_PG_TABLE_NAME,
  TVT_REM_TABLE_NAME,
  TVT_RM_TABLE_NAME,
} from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/constants';
import {
  getApproachTableName,
  getSelectionCellOptions,
  publicCompsOrTransactionsExists,
} from 'pages/ValuationsAllocation/util';
import getFormulaExpression from './getFormulaExpression';
import getSelectedApproach from './getSelectedApproach';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';
import { AllCells, CellValueType, Changes, CustomCell, TableData } from './types';

const getCell = (allCells: AllCells, sheetName: string, key: string) => {
  const sheet = allCells[sheetName];
  return sheet[key as keyof typeof sheet];
};

const addCellToChangeList = (changeList: Changes[], cell: CustomCell, value: CellValueType) => {
  const cellInChangeList = changeList.find(change => change.cell === cell);
  if (!cellInChangeList) {
    changeList.push({ cell, value });
  }
};

// map of possible terminal value options to table names
const terminalValueOptionsMap = {
  [TERMINAL_VALUE_OPTIONS.PERPETUITY_GROWTH]: TVT_PG_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.H_MODEL]: TVT_HM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE]: TVT_RM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE]: TVT_EM_TABLE_NAME,
  [TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE]: TVT_REM_TABLE_NAME,
};

const afterCellChanged = (
  changes: Changes[],
  cells: Cells,
  rowConfig: RowConfigProps,
  tableData: TableData,
  allCells: AllCells,
  approaches?: ValuationsApproach[]
) => {
  const changeList: Changes[] = [];
  const cellObjects = Object.values(cells);
  changes.forEach(change => {
    const { cell, value } = change;

    changeList.push({ cell, value });

    const isCombinedTVT
      = tableData?.approach?.valuations_approach_dcf?.terminal_value
      === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;

    if (cell.alias === TERMINAL_VALUE) {
      // use TVT_REM_TABLE_NAME, TVT_EM_TABLE_NAME, TVT_RM_TABLE_NAME, TVT_HM_TABLE_NAME, and TVT_PG_TABLE_NAME
      // combined with the getApproachTableName() function and the tableData.approach object to get the
      // names of the relevant sheets in the allCells object.
      const relatedSheetNames = [
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_REM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_EM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_RM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_HM_TABLE_NAME }),
        getApproachTableName({ approach: tableData.approach, tableSuffix: TVT_PG_TABLE_NAME }),
      ];
      // get the terminal value cells, add them to the changeList
      relatedSheetNames
        .map(sheetName => getCell(allCells, sheetName, TERMINAL_VALUE_CUSTOM_KEY))
        .forEach(changeCell => addCellToChangeList(changeList, changeCell, value));

      // finally get the table name of the DCF_SUMMARY sheet, create a new expression, and add it to the changeList
      const dcfSummarySheetName = getApproachTableName({
        approach: tableData.approach,
        tableSuffix: DCF_SUMMARY_TABLE_NAME,
      });
      const dcfSummaryCell = getCell(allCells, dcfSummarySheetName, TERMINAL_VALUE_EXPR_CUSTOM_KEY);
      // use the value to determine the related table name and then create the expression
      // that says =table_name.terminal_value
      const tableName = getApproachTableName({
        approach: tableData.approach,
        tableSuffix: terminalValueOptionsMap[value as string],
      });
      const expression = `=${tableName}.${PV_OF_TERMINAL_VALUE_CUSTOM_KEY}`;
      addCellToChangeList(changeList, dcfSummaryCell, expression);
    }

    const isSecondColumn = cell.columnLegend === REM_TVT_SECOND_COLUMN;
    const gpcMultipleAlias = isSecondColumn ? EBITDA_GPC_MULTIPLE : REVENUE_GPC_MULTIPLE;
    const currentMultipleValue = cell?.sheet?.tableData?.approach?.valuations_approach_dcf?.[gpcMultipleAlias];

    if (SELECT_MULTIPLE_ALIASES.includes(cell.alias)) {
      const multipleAlias = obtainMapForSelectMultipleAlias().get(cell.alias);
      const cellToUpdate = cellObjects.find(
        ({ alias, columnLegend }) => alias === multipleAlias && columnLegend === cell.columnLegend
      );
      const indexValue = parseInt(value as string, 10);
      const approachSelectedCell = isCombinedTVT ? cells[`${cell.columnLegend}4`] : cells[`${cell.columnLegend}3`];
      const selectedApproach = getSelectedApproach({
        approaches,
        approachId: approachSelectedCell.value,
      }) as ValuationsApproach;
      const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;

      const multipleOptions = getSelectionCellOptions({
        isDCF: true,
        specificApproach,
      });
      const selectedMultipleValue = multipleOptions[indexValue];
      const changedCell = cells[cellToUpdate?.key ?? ''];
      // if new value is 'Other'
      // update the read only property of the "Multiple" cell and value/expression as needed
      if (selectedMultipleValue === VALUATIONS_OTHER_LABEL) {
        changedCell.readOnly = false;
        changeList.push({ cell: changedCell, value: currentMultipleValue });
      } else {
        // if we go from 'Other' to, for example, 'Median' => need complex expression back.
        changedCell.readOnly = true;
        const recoveredExpression = getFormulaExpression({
          approach: selectedApproach,
          alias: changedCell.alias,
          columnLegend: changedCell.columnLegend ?? '',
          selectedMultipleValue,
        });
        changeList.push({ cell: changedCell, value: recoveredExpression });
      }
    }

    if (APPROACH_SELECTIONS_ALIASES.includes(cell.alias)) {
      const multipleCell = isCombinedTVT ? cells[`${cell.columnLegend}5`] : cells[`${cell.columnLegend}4`];
      const selectedApproach = getSelectedApproach({ approaches, approachId: cell.value }) as ValuationsApproach;
      const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;

      const multipleOptions = getSelectionCellOptions({
        isDCF: true,
        specificApproach,
      });

      // Reset selected Multiple Value to Median if there approaches available if not set "Other" for input
      const selectedMultipleValue = !publicCompsOrTransactionsExists(approaches)
        ? VALUATIONS_OTHER_LABEL
        : multipleOptions[0];
      changeList.push({ cell: multipleCell, value: 0 });

      const multipleAlias = obtainMapForSelectMultipleAlias().get(multipleCell.alias);
      const cellToUpdate = cellObjects.find(
        ({ alias, columnLegend }) => alias === multipleAlias && columnLegend === cell.columnLegend
      );

      const changedCell = cells[cellToUpdate?.key ?? ''];
      // // if new value is 'Other'
      // update the read only property of the "Multiple" cell and value/expression as needed
      if (selectedMultipleValue === VALUATIONS_OTHER_LABEL) {
        changedCell.readOnly = false;
        changeList.push({ cell: changedCell, value: currentMultipleValue });
      } else {
        // if we go from 'Other' to, for example, 'Median' => need complex expression back.
        changedCell.readOnly = true;
        const recoveredExpression = getFormulaExpression({
          approach: selectedApproach,
          alias: changedCell.alias,
          columnLegend: changedCell.columnLegend ?? '',
          selectedMultipleValue,
        });
        changeList.push({ cell: changedCell, value: recoveredExpression });
      }
    }
  });

  return changeList;
};

export default afterCellChanged;
