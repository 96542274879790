/* eslint-disable object-curly-newline */
import { useCallback, useContext } from 'react';
import { isEmpty, isNil, isNull, isUndefined } from 'lodash';
import * as validationMessages from 'common/constants/messages/validations';
import LayoutContext from 'context/LayoutContext';
import {
  addClassName,
  endOfDay,
  isStringNumeric,
  MOMENT_DEFAULT_DATE_FORMAT,
  removeClassName,
  shortDate,
  toString,
} from 'utilities';

const useTableValidation = () => {
  const { showAlert, setShowAlert, setAlertMessage } = useContext(LayoutContext);

  // function to add feedback from any cell
  const addFeedbackFromCell = (cell, feedback, validationType = validationMessages.ERROR_CLASSNAME) => {
    const auxCell = cell;
    if (validationType === validationMessages.ERROR_CLASSNAME) {
      auxCell.isValid = false;
    }
    auxCell.className = addClassName(auxCell.className, validationType);

    if (!auxCell.tooltipMessages) {
      auxCell.tooltipMessages = [feedback];
    } else if (!auxCell.tooltipMessages.includes(feedback)) {
      auxCell.tooltipMessages.push(feedback);
    }
  };

  // function to remove feedback from any cell
  const removeFeedbackFromCell = (
    cell,
    feedback,
    validationType = validationMessages.ERROR_CLASSNAME,
    keepsHavingError = false
  ) => {
    const auxCell = cell;
    if (!auxCell.tooltipMessages) {
      auxCell.tooltipMessages = [];
    } else if (auxCell.tooltipMessages.includes(feedback)) {
      const index = auxCell.tooltipMessages.findIndex(message => message === feedback);
      auxCell.tooltipMessages.splice(index, 1);
      if (auxCell.tooltipMessages.length === 0) {
        auxCell.isValid = true;
      }
    }
    if (validationType && !keepsHavingError && auxCell?.isValid) {
      auxCell.className = removeClassName(auxCell.className, validationType);
    }
  };

  const validateCells = useCallback(
    ({ cellsToValidate, customValidations, tableData, parsedColumns, isSaving, useOriginalCell }) => {
      if (cellsToValidate && !isEmpty(cellsToValidate)) {
        const tmpCells = [];

        // Make base validations
        cellsToValidate.forEach(cell => {
          const {
            minValue,
            maxValue,
            isRequired,
            gridType,
            dbType,
            allowNegativeValue,
            className,
            greaterThan,
            maxNumberChars,
            maxNumberDigits,
            invalidExpr,
            allowDecimals,
            ignoreValidations,
            maxDecimalDigits,
            value,
          } = cell;

          const valueStr = toString(value);

          let tmpCell;

          if (ignoreValidations) {
            return;
          }

          if (useOriginalCell) {
            tmpCell = cell;
            tmpCell.isValid = true;
            tmpCell.tooltipMessages = cell.tooltipMessages || [];
          } else {
            tmpCell = {
              ...cell,
              isValid: true,
              tooltipMessages: cell.tooltipMessages || [],
            };
          }

          const addFeedback = (feedback, validationType = validationMessages.ERROR_CLASSNAME) => {
            if (validationType === validationMessages.ERROR_CLASSNAME) {
              tmpCell.isValid = false;
            }

            tmpCell.className = addClassName(tmpCell.className, validationType);

            if (!tmpCell.tooltipMessages.includes(feedback)) {
              tmpCell.tooltipMessages.push(feedback);
            }
          };

          const removeFeedback = (feedback, validationType = validationMessages.ERROR_CLASSNAME) => {
            if (tmpCell.tooltipMessages.includes(feedback)) {
              const index = tmpCell.tooltipMessages.findIndex(message => message === feedback);
              tmpCell.tooltipMessages.splice(index, 1);
            }
            if (validationType && tmpCell?.isValid) {
              tmpCell.className = removeClassName(className, validationType);
            }
          };

          if (invalidExpr) {
            addFeedback(validationMessages.INVALID_EXPRESSION, validationMessages.ERROR_CLASSNAME);
          } else {
            removeFeedback(validationMessages.INVALID_EXPRESSION);
          }

          // Greater than zero, could be 0.1 or 0.01...
          if (!isNil(greaterThan) && Number(value) <= Number(greaterThan)) {
            addFeedback(validationMessages.GREATER_THAN(greaterThan));
          } else {
            removeFeedback(validationMessages.GREATER_THAN(greaterThan));
          }

          if (
            maxNumberDigits
            && value
            && isStringNumeric(valueStr)
            && !cell.readOnly
            && valueStr.replace('.', '').length > maxNumberDigits
          ) {
            addFeedback(validationMessages.MAX_DIGIT_ERROR(maxNumberDigits));
          } else {
            removeFeedback(validationMessages.MAX_DIGIT_ERROR(maxNumberDigits));
          }

          if (maxNumberChars && value && !cell.readOnly && valueStr.length > maxNumberChars) {
            addFeedback(validationMessages.MAX_CHAR_ERROR(maxNumberChars));
          } else {
            removeFeedback(validationMessages.MAX_CHAR_ERROR(maxNumberChars));
          }

          // Validate min values
          if (!isUndefined(minValue) && Number(value) < Number(minValue)) {
            addFeedback(validationMessages.MIN_VALUE_ERROR(minValue));
          } else {
            removeFeedback(validationMessages.MIN_VALUE_ERROR(minValue));
          }
          if (value === Infinity) {
            addFeedback(validationMessages.INFINITY_ERROR);
          } else {
            removeFeedback(validationMessages.INFINITY_ERROR);
          }

          // Validate max values
          if (maxValue) {
            let tempValue = value;
            if (gridType === 'percentage') {
              tempValue *= 100;
            }
            if (gridType === 'gridDate') {
              tempValue = new Date(endOfDay(value, MOMENT_DEFAULT_DATE_FORMAT));
            }

            const maxValueTooltip = typeof maxValue === 'string' ? shortDate(maxValue) : maxValue;

            if (tempValue > maxValue) {
              addFeedback(validationMessages.MAX_VALUE_ERROR(maxValueTooltip));
            } else {
              removeFeedback(validationMessages.MAX_VALUE_ERROR(maxValueTooltip));
            }
          }

          // Validate required cells
          if (isRequired && (isNil(value) || valueStr.trim().length === 0)) {
            addFeedback(validationMessages.IS_REQUIRED_ERROR);
          } else {
            removeFeedback(validationMessages.IS_REQUIRED_ERROR);
          }

          // Validate negative number of rows
          /* for != reasons, sometimes allowNegativeValue will be undefined.
        IMHO having allowNegativeValue undefined
        leans more towards allowing negative values than not allowing them. */
          if (allowNegativeValue === false && Number(value) < 0) {
            addFeedback(validationMessages.POSITIVE_NUMBER_ERROR);
          } else {
            removeFeedback(validationMessages.POSITIVE_NUMBER_ERROR);
          }

          if (allowDecimals === false && String(value).includes('.')) {
            addFeedback(validationMessages.DECIMALS_NOT_ALLOWED);
          } else {
            removeFeedback(validationMessages.DECIMALS_NOT_ALLOWED);
          }

          if (maxDecimalDigits && value && !cell.readOnly && valueStr.includes('.')) {
            const decimalPlaces = valueStr.split('.')[1].length;
            if (decimalPlaces > maxDecimalDigits) {
              addFeedback(validationMessages.MAX_DECIMAL_DIGITS(maxDecimalDigits));
            } else {
              removeFeedback(validationMessages.MAX_DECIMAL_DIGITS(maxDecimalDigits));
            }
          }

          // Validate value type
          if (gridType) {
            let areDifferent = false;

            const newValue = isNull(value) ? '' : value;
            switch (gridType) {
              case 'percentage':
              case 'number':
                if (dbType && dbType !== 'boolean' && Number.isNaN(Number(newValue))) {
                  areDifferent = true;
                }
                break;
              case 'string':
                if (typeof newValue !== 'string') {
                  areDifferent = true;
                }
                break;
              default:
                break;
            }

            if (areDifferent) {
              addFeedback(validationMessages.VALUE_TYPE_ERROR(gridType));
            } else {
              removeFeedback(validationMessages.VALUE_TYPE_ERROR(gridType));
            }
          }

          // Set validation classes
          if (tmpCell?.isValid) {
            tmpCell.className = removeClassName(tmpCell.className, validationMessages.ERROR_CLASSNAME);
          } else {
            tmpCell.className = addClassName(tmpCell.className, validationMessages.ERROR_CLASSNAME);
          }

          if (customValidations) {
            customValidations({
              cellsToValidate,
              cell: tmpCell,
              parsedColumns,
              tableData,
              addFeedback,
              addFeedbackFromCell,
              removeFeedback,
              removeFeedbackFromCell,
              isSaving,
            });
          }

          tmpCells.push(tmpCell);
        });

        return {
          validatedCells: tmpCells,
          areCellsValid: !tmpCells.some(cell => cell.isValid === false),
        };
      }
    },
    []
  );

  const validateTable = ({ cellsToValidate, customValidations, tableData, parsedColumns, toggleAlert = true }) => {
    if (isEmpty(cellsToValidate)) {
      return { validatedCells: [], areCellsValid: true };
    }

    // Validate cells
    const { validatedCells, areCellsValid } = validateCells({
      cellsToValidate,
      customValidations,
      tableData,
      parsedColumns,
      isSaving: true,
    });

    // Show alert
    if (areCellsValid) {
      if (showAlert && toggleAlert) {
        setShowAlert(false);
      }
    } else if (!showAlert && toggleAlert) {
      setShowAlert(true);
      setAlertMessage(validationMessages.FIX_BEFORE_CONTINUE);
    }

    return { validatedCells, areCellsValid };
  };

  return { validateCells, validateTable };
};

export default useTableValidation;
