import { getStringValue } from 'utilities';
import { ClearAndAddFeedbackParams } from './types';

const clearAndAddFeedback = (params: ClearAndAddFeedbackParams) => {
  const { addFeedback, feedback, removeFeedback, validations } = params;

  const previousFeedback = validations[feedback as keyof typeof validations];

  removeFeedback?.(getStringValue(previousFeedback));
  addFeedback?.(getStringValue(feedback));
};

export default clearAndAddFeedback;
