import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  TASKS_PROGRESS_BY_FIRM_DEFAULT_ERROR_MESSAGE,
  TASKS_PROGRESS_BY_FIRM_QUERY_KEY,
} from 'common/constants/services/firms';
import { FirmService } from 'services';
import { throwError } from 'utilities';
import {
  GetTasksProgressByFirmHook,
  GetTasksProgressByFirmParams,
  GetTasksProgressByFirmResponse,
  UseGetTasksProgressByFirm,
} from './types';

const getTasksProgressByFirm = async (params: GetTasksProgressByFirmParams) => {
  const { firmId } = params;

  if (isNil(firmId)) return null;

  const firmService = new FirmService();

  try {
    const tasksProgress = (await firmService.getTasksProgress(firmId)) as GetTasksProgressByFirmResponse;

    if (!tasksProgress) return throwError(TASKS_PROGRESS_BY_FIRM_DEFAULT_ERROR_MESSAGE);

    return tasksProgress;
  } catch (error) {
    return throwError(TASKS_PROGRESS_BY_FIRM_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetTasksProgressByFirm: UseGetTasksProgressByFirm = params => {
  const { firm, shouldQueryAutomatically = true } = params;

  const firmId = firm?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetTasksProgressByFirmHook['data'], Error>({
    enabled: !isNil(firmId) && shouldQueryAutomatically,
    queryFn: () => getTasksProgressByFirm({ firmId }),
    queryKey: [TASKS_PROGRESS_BY_FIRM_QUERY_KEY, firmId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetTasksProgressByFirm;
