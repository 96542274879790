import { TVTREM_KEY, TVTRM_KEY } from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcf/config/constants';
import {
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { extractSpecificApproachFromApproach } from 'pages/ValuationsAllocation/util/extractSpecificApproachFromApproach';
import { getCompaniesOrTransactions } from 'pages/ValuationsAllocation/util/getCompaniesOrTransactions';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { getTVTsThatDependOnBenchmark } from 'pages/ValuationsAllocation/util/getTVTsThatDependOnBenchmark';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { GetRowConfigToUpdateParams, UpdateDCFOptionsParams } from './types';
import { getApproachesByType } from '../getApproachesByType';
import { getGpcAndGptForDropdown } from '../getGpcAndGptForDropdown';

const getRowConfigToUpdate = (params: GetRowConfigToUpdateParams) => {
  const { spreadsheetKey, DCFRowConfigs } = params;

  const { revenueRowConfig, ebitdaRowConfig, revenueAndEbitdaRowConfig } = getObjectValue(DCFRowConfigs);

  switch (spreadsheetKey) {
    case TVTRM_KEY:
      return revenueRowConfig;

    case TVTREM_KEY:
      return revenueAndEbitdaRowConfig;

    default:
      return ebitdaRowConfig;
  }
};

export const updateDCFOptions = (params: UpdateDCFOptionsParams) => {
  const { DCFSpreadsheets, benchmarkApproach, DCFRowConfigs, approaches } = params;
  const filteredApproaches = getApproachesByType({
    approaches,
    approachTypes: [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH],
  });

  const approachOptions = getGpcAndGptForDropdown(filteredApproaches);

  const specificApproach = extractSpecificApproachFromApproach(benchmarkApproach);
  const { percentile_selection_a: percentileSelectionA, percentile_selection_b: percentileSelectionB }
    = getObjectValue(specificApproach);

  const optionsInBenchmark = getSelectionCellOptions({
    isDCF: true,
    specificApproach,
  });

  const companiesRows = getCompaniesOrTransactions(specificApproach);

  const spreadsheetsToUpdate = getTVTsThatDependOnBenchmark(DCFSpreadsheets);

  spreadsheetsToUpdate.forEach(spreadsheet => {
    const [spreadsheetKey, spreadsheetConfig] = spreadsheet;

    const rowConfigArguments = {
      benchmarkType: benchmarkApproach?.approach_type,
      companiesRows,
      dcfWaccSheet: DCFSpreadsheets?.dcfWacc,
      mainTableName: getStringValue(DCFSpreadsheets?.dcf?.name),
      options: optionsInBenchmark,
      percentileSelections: {
        percentile_selection_a: getNumberValue(percentileSelectionA),
        percentile_selection_b: getNumberValue(percentileSelectionB),
      },
      approachOptions,
    };

    const rowConfigToUpdate = getRowConfigToUpdate({ spreadsheetKey, DCFRowConfigs });
    const updatedRowConfig = rowConfigToUpdate?.(rowConfigArguments);

    spreadsheetConfig.reset({ rowConfig: updatedRowConfig });
  });
};

export default updateDCFOptions;
