import React, { FC } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { isUndefined, sortBy } from 'lodash';
import { shortDate } from 'utilities';
import useStyles from './styles';
import { DateSelectorProps, MeasurementDate } from './types';

const DateSelector: FC<DateSelectorProps> = ({ options, onSelectMD }) => {
  const classes = useStyles();

  if (isUndefined(options)) {
    return <Skeleton variant="text" className={classes.skeleton} width={150} height={50} />;
  }

  const measurementDates = options
    .map(
      option =>
        ({
          ...option,
          name: shortDate(option.name),
        } as MeasurementDate)
    )
    .filter(option => option.name);

  const handleOnChange = (selectedMd: MeasurementDate | null): void => {
    if (selectedMd) {
      onSelectMD(selectedMd.date);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id="date-selector"
        options={sortBy(measurementDates, 'name')}
        style={{ width: '100%' }}
        autoHighlight
        getOptionLabel={option => option.name}
        onChange={(_event, option) => handleOnChange(option)}
        classes={{
          root: classes.select,
        }}
        renderInput={params => <TextField {...params} label="Select a measurement date" />}
        disableClearable
      />
    </FormControl>
  );
};

export default DateSelector;
