/* eslint-disable no-param-reassign */
import { isUndefined } from 'lodash';
import moment from 'moment';
import { OPM } from 'common/constants/allocations';
import { parseDatabaseValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import {
  MATURITY_ALIAS,
  OPM_BACKSOLVE_DATE_ALIAS,
  VOLATILITY_SOURCE_ALIAS,
  VOLATILITY_SOURCE_REFERENCE,
} from './constants';
import { APPROACH_GPC_PROPERTY } from '../../../util/constants';
import updateApproachReference from '../../../util/updateApproachReference';

const handleVolatilitySource = ({ cell, calcValue }) => {
  if (cell.alias === VOLATILITY_SOURCE_ALIAS && Number(cell.value) === 0) {
    // If the volatility source is Specified, we need to set the cell value to null
    return null;
  }

  // Otherwise, keep the id of the selected GPC approach
  return calcValue;
};

const handleDateCells = (cell, cells) => {
  if ([OPM_BACKSOLVE_DATE_ALIAS, MATURITY_ALIAS].includes(cell.alias)) {
    const backsolveDateCell = cells.B1;
    const maturityCell = cells.B2;
    const marketDateCell = cells.B3;
    marketDateCell.value = moment(backsolveDateCell.value, 'MM/DD/YYYY')
      .add(Number(maturityCell.value), 'years')
      .format('MM/DD/YYYY');
  }
};

const reverseParser = ({ cells, columns, rowConfig, allowEmptyValues, fieldAttributes, tableData }) => {
  const alphabet = alphabetGenerator([], columns.length);
  const { gpcApproaches } = tableData;
  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // copy original values
    const tmpColumn = column;

    rowConfig.forEach((_, rowIndex) => {
      const key = columnLegend + (rowIndex + 1);
      const cell = cells[key];
      const defaultValue = null;
      const type = cell.dbType || null;
      const format = cell.format || null;
      const value = cell ? cell.value : defaultValue;
      const { gridType } = cell;

      if (cell?.alias) {
        if (cell.alias === VOLATILITY_SOURCE_ALIAS) {
          updateApproachReference({
            approaches: gpcApproaches,
            column: tmpColumn,
            lookup: cell.value,
            specificApproachKey: APPROACH_GPC_PROPERTY,
            updateKey: VOLATILITY_SOURCE_ALIAS,
            referenceKey: VOLATILITY_SOURCE_REFERENCE,
          });
        } else {
          const decimalPlaces = fieldAttributes[cell.alias]?.decimal_places;

          const calcValue = parseDatabaseValue({
            type,
            value,
            defaultValue,
            format,
            allowEmptyValues,
            gridType,
            decimalPlaces,
          });

          handleDateCells(cell, cells);

          tmpColumn[cell.alias]
            = cell.alias === VOLATILITY_SOURCE_ALIAS ? handleVolatilitySource({ cell, calcValue }) : calcValue;

          // On Future Exit Approach, the applied_methodologies does not exist in the column
          const opmIndex = tmpColumn?.applied_methodologies?.findIndex(
            x => Number(x.allocation_method) === Number(OPM)
          );

          // opmIndex may be zero, so we need to check if it is not undefined explicitly
          if (!isUndefined(opmIndex) && opmIndex !== -1) {
            tmpColumn.applied_methodologies[opmIndex][cell.alias] = calcValue;
          }
        }
      }
    });
  });
};

export default reverseParser;
