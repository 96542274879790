import { Cell } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { REM_TVT_SECOND_COLUMN } from 'pages/Valuations/util/constants';
import { getObjectValue } from 'utilities';
import { TERMINAL_VALUE_OPTIONS } from '../../utilities/constants';

interface GetOptionsAccordingApproachProps {
  cell: Cell;
  dcfWacc: SpreadsheetConfig;
  revenueMultipleOptions: string[];
  ebitdaMultipleOptions: string[];
}

const getOptionsAccordingApproach = (props: GetOptionsAccordingApproachProps) => {
  const { cell, dcfWacc, revenueMultipleOptions, ebitdaMultipleOptions } = props;
  const { tableData } = getObjectValue(dcfWacc);
  const terminalValue = tableData?.approach?.valuations_approach_dcf?.terminal_value;
  const isRevenue = terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
  const isEbitda = terminalValue === TERMINAL_VALUE_OPTIONS.EBITDA_MULTIPLE;
  const isCombined = terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;
  let options: string[] = [];
  if (isCombined) {
    const isSecondColumn = cell?.columnLegend === REM_TVT_SECOND_COLUMN;
    if (isSecondColumn) {
      options = ebitdaMultipleOptions;
    } else {
      options = revenueMultipleOptions;
    }
  }
  if (isRevenue) {
    options = revenueMultipleOptions;
  }
  if (isEbitda) {
    options = ebitdaMultipleOptions;
  }
  return options;
};

export default getOptionsAccordingApproach;
