import { CellData } from 'common/types/scalarSpreadsheet';
import { generateColumnKey, getNumberValue } from 'utilities';
import { VALUES_PER_SHARE_SPREADSHEET_COLUMN_KEY_PREFIX } from './constants';
import { SecurityWithValue } from '../../types';

export interface ValuesPerShareColumn {
  [key: string]: CellData;
}

const createColumns = (data: SecurityWithValue[]) => {
  const shares = data.reduce((accumulator, current) => {
    const { security, value } = current;
    const { name, id } = security;

    const key = generateColumnKey({
      id,
      name,
      prefix: VALUES_PER_SHARE_SPREADSHEET_COLUMN_KEY_PREFIX,
    });

    return {
      ...accumulator,
      [key]: {
        value: getNumberValue(value)
          .toFixed(23)
          .replace(/(?:\.0+|(\.\d+?)0+)$/, '$1'),
      },
    };
  }, []);

  return [shares];
};

export default createColumns;
