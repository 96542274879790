/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { isEmpty } from 'lodash';
import * as CAPTABLE from 'common/constants/cap-table';
import { CONVERTIBLE_NOTES } from 'common/constants/securityTypes';
import { gridShortDate } from 'utilities/datesFormats';
import { getFirstMatchingSecurityByRef, isUnderlyingSecurity } from './arrayFunctions';
import getCellByColumnRef from './getCellByColumnRef';
import getUnderlyingSecurityCells from './getUnderlyingSecurityCells';

const afterCellChanged = (changes, cells, __rowConfig, tableData) => {
  const warrantSecurities = tableData.columns.filter(security => security.underlying_security_ref) || {};
  const getKeyOf = (columnLegend, rowNumber) => `${columnLegend}${rowNumber}`;

  const handlers = {
    [CAPTABLE.SECURITY_NAME_ALIAS]: cell => {
      Object.values(cells)
        .filter(candidateCell => candidateCell.alias === CAPTABLE.UNDERLYING_SECURITY_ALIAS && candidateCell.options)
        .forEach(underlyingCell => {
          const option = underlyingCell.options.find(
            underlyingCellOptions => underlyingCellOptions.id === cell.columnRef
          );
          if (option) {
            option.name = cell.value;
            changes.push({
              cell: underlyingCell,
              value: underlyingCell.value,
            });
          }
        });
    },
    [CAPTABLE.SECURITY_TYPE_ALIAS]: cell => {
      const { columnId, sheet, tooltipMessages = [] } = cell;
      const tmpColumns = sheet.columns.map(col => ({
        id: col.id,
        isUsed: col.is_used_in_fod || col.is_used_in_backsolve,
      }));
      if (tmpColumns.find(col => col.id === columnId && col.isUsed)) {
        tooltipMessages.push(CAPTABLE.SECURITY_SELECTED_IN_FO_OR_BACKSOLVE);
      }
    },
    [CAPTABLE.HAS_MULTIPLE_INVESTMENT_DATES_ALIAS]: cell => {
      const { columnLegend, value } = cell;
      const cellValue = Number(value);
      const investmentDateKey = getKeyOf(columnLegend, CAPTABLE.INVESTMENT_DATE_ROW_NUMBER);
      const sharesOutstandingKey = getKeyOf(columnLegend, CAPTABLE.SHARES_OUTSTANDING_ROW_NUMBER);
      const multipleInvestmentsKey = getKeyOf(columnLegend, CAPTABLE.MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER);

      if (cellValue === 0) {
        // NO
        const { securities } = tableData;
        const securityWithMultipleInvestments = securities.find(security => security.order === cell.columnOrder) || [];

        const multipleInvestments = isEmpty(cells[multipleInvestmentsKey].value)
          ? securityWithMultipleInvestments.multiple_investments
          : cells[multipleInvestmentsKey].value;

        // Get info of the previous investment dates (without isNew prop)
        const previousInvestmentDates = multipleInvestments?.filter(mid => !mid.isNew);

        if (!isEmpty(previousInvestmentDates)) {
          // Reset Shares Outstanding
          const totalShares = previousInvestmentDates
            .map(investment => Number(investment.shares))
            .reduce((previous, current) => previous + current, 0);
          changes.push({
            cell: cells[sharesOutstandingKey],
            value: totalShares.toString(),
          });

          // Find the earliest date to save it as the default Investment Date
          const earliestDate = previousInvestmentDates
            .map(investment => gridShortDate(investment.date))
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .shift();
          // Reset Investment Date
          changes.push({
            cell: cells[investmentDateKey],
            value: earliestDate,
          });
        }
      } else if (Number(value) === 1) {
        // YES
        const { value: multipleInvestments } = cells[multipleInvestmentsKey];
        if (multipleInvestments?.length) {
          const newSharesOutstanding = multipleInvestments.reduce(
            (total, investement) => total + Number(investement.shares),
            0
          );
          changes.push({
            cell: cells[sharesOutstandingKey],
            value: newSharesOutstanding,
          });
        }
      }
    },
  };

  const handleConvertibleNotes = (cell, convertibleNoteCells) => {
    const { columnLegend, value } = cell;
    const newTypeValue = value.toString();
    const currentTypeValue = cell.value.toString();
    const isCurrentConvertibleNote = currentTypeValue === CONVERTIBLE_NOTES.toString();
    const previouslyHadConvertibleNote = convertibleNoteCells[0].value.findIndex(cn => cn.id !== 0) !== -1;
    if (
      isCurrentConvertibleNote
      && currentTypeValue !== newTypeValue
      && cell.columnId !== 0
      && previouslyHadConvertibleNote
    ) {
      changes.push({
        cell: cells[`${columnLegend}${CAPTABLE.CONVERTIBLE_NOTES_ROW_NUMBER}`],
        value: convertibleNoteCells[0].value.map(cn => ({
          ...cn,
          is_deleted: true,
        })),
      });
    } else if (isCurrentConvertibleNote) {
      changes.push({
        cell: cells[`${columnLegend}${CAPTABLE.CONVERTIBLE_NOTES_ROW_NUMBER}`],
        value: [],
      });
    }
  };

  changes.forEach(change => {
    const { cell, value } = change;
    if (!cell) return;

    const { alias, columnLegend } = cell;

    const initialLiquidationKey = getKeyOf(columnLegend, CAPTABLE.INITIAL_LIQUIDATION_PREFERENCE_ROW_NUMBER);
    const totalPreferenceKey = getKeyOf(columnLegend, CAPTABLE.TOTAL_PREFERENCE_ROW_NUMBER);

    const warrantSecurity = getFirstMatchingSecurityByRef({
      cellColumnRef: cell.columnRef,
      securities: warrantSecurities,
    });

    const cellIsUnderlyingSecurity = isUnderlyingSecurity({
      cell,
      warrantSecurity,
      matchingAlias: CAPTABLE.UNDERLYING_SECURITY_ALIAS,
    });

    const warrantColumnLegend = getCellByColumnRef({
      cells,
      columnRef: warrantSecurity.columnRef,
    }).columnLegend;

    const underlyingSecurityCells = getUnderlyingSecurityCells({
      cells,
      underlyingSecurityRef: value,
    });

    if (handlers[alias]) {
      handlers[alias](cell);
    }

    // Update Initial and Total Liquidation Preference
    changes.push({
      cell: cells[initialLiquidationKey],
      value: cells[initialLiquidationKey].expr,
    });
    changes.push({
      cell: cells[totalPreferenceKey],
      value: cells[totalPreferenceKey].expr,
    });

    // Update Warrant associated cell
    if (cellIsUnderlyingSecurity) {
      switch (true) {
        case alias === CAPTABLE.CONVERSION_RATE_ALIAS:
          changes.push({
            cell: cells[`${warrantColumnLegend}${CAPTABLE.CONVERSION_RATE_ROW_NUMBER}`],
            value,
          });
          break;
        case alias === CAPTABLE.LIQUIDATION_PREFERENCE_ALIAS:
          changes.push({
            cell: cells[`${warrantColumnLegend}${CAPTABLE.LIQUIDATION_PREFERENCE_ROW_NUMBER}`],
            value,
          });
          break;
        case alias === CAPTABLE.SENIORITY_ALIAS:
          changes.push({
            cell: cells[`${warrantColumnLegend}${CAPTABLE.SENIORITY_ROW_NUMBER}`],
            value,
          });
          break;
        default:
          break;
      }
    }

    // Update Warrant after selecting underlying security
    if (alias === CAPTABLE.UNDERLYING_SECURITY_ALIAS && underlyingSecurityCells) {
      underlyingSecurityCells.forEach(cellParam => {
        switch (true) {
          case cellParam.alias === CAPTABLE.CONVERSION_RATE_ALIAS:
            changes.push({
              cell: cells[`${columnLegend}${CAPTABLE.CONVERSION_RATE_ROW_NUMBER}`],
              value: cellParam.value,
            });
            break;
          case cellParam.alias === CAPTABLE.LIQUIDATION_PREFERENCE_ALIAS:
            changes.push({
              cell: cells[`${columnLegend}${CAPTABLE.LIQUIDATION_PREFERENCE_ROW_NUMBER}`],
              value: cellParam.value,
            });
            break;
          case cellParam.alias === CAPTABLE.SENIORITY_ALIAS:
            changes.push({
              cell: cells[`${columnLegend}${CAPTABLE.SENIORITY_ROW_NUMBER}`],
              value: cellParam.value,
            });
            break;
          default:
            break;
        }
      });
    }

    if (cell.value && alias === CAPTABLE.SECURITY_TYPE_ALIAS && cell.value !== value) {
      const convertibleNoteCells = Object.values(cells).filter(
        candidateCell =>
          candidateCell.columnLegend === cell.columnLegend && candidateCell.alias === CAPTABLE.CONVERTIBLE_NOTES_ALIAS
      );
      if (value === CONVERTIBLE_NOTES) {
        changes.push({
          cell: cells[`${columnLegend}${CAPTABLE.HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER}`],
          value: 0,
        });
      } else if (convertibleNoteCells.length > 0) {
        handleConvertibleNotes(cell, convertibleNoteCells);
      }
    }
  });

  return changes;
};

export default afterCellChanged;
