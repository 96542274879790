import { NOT_APPLICABLE_CALIBRATION } from 'common/constants/general';
import {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
  SHEET_ALIASES_CONSTANTS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { generateColumnKey, getArrayValue, getNumberValue, getStringValue } from 'utilities';
import { CreateColumnsCalibrationParams, PerformanceMetricsColumn } from './types';
import { determineValue } from '../../../utils';

const { PERFORMANCE_METRICS_SPREADSHEET_COMPANY } = SHEET_ALIASES_CONSTANTS;
const PERFORMANCE_METRICS_SPREADSHEET_COMPANY_LTM_REVENUE_MULTIPLE
  = 'performance_metrics_ltm_revenue_multiple_current_company';
const PERFORMANCE_METRICS_SPREADSHEET_COMPANY_NTM_REVENUE_MULTIPLE
  = 'performance_metrics_ntm_revenue_multiple_current_company';
const PERFORMANCE_METRICS_SPREADSHEET_COMPANY_LTM_EBITDA_MULTIPLE
  = 'performance_metrics_ltm_ebitda_multiple_current_company';
const PERFORMANCE_METRICS_SPREADSHEET_COMPANY_NTM_EBITDA_MULTIPLE
  = 'performance_metrics_ntm_ebitda_multiple_current_company';

const createColumnData = (
  comparisons: any[],
  financialsValue: number | string | undefined,
  keyPrefix: string,
  property: string,
  companyKey: string,
  calculateValue?: (current: any) => number | string
) =>
  comparisons.reduce(
    (accumulator, current) => ({
      ...accumulator,
      [generateColumnKey({
        name: getStringValue(current?.cap_iq_id),
        prefix: keyPrefix,
      })]: {
        value: determineValue(current, property, calculateValue),
      },
    }),
    {
      [companyKey]: {
        value: getNumberValue(financialsValue),
      },
    }
  ) as PerformanceMetricsColumn;

const createColumnsCalibrationPerformance = (params: CreateColumnsCalibrationParams) => {
  const { valuationsApproachGpc, financials } = params;
  const { gpcComparisonByDate } = params;

  let comparisons = getArrayValue(valuationsApproachGpc?.gpc_comparison);

  if (gpcComparisonByDate !== undefined && gpcComparisonByDate.length > 0) {
    comparisons = gpcComparisonByDate;
  }

  comparisons = comparisons.filter(item => item.name !== '0');

  const comparisonCompanyNameValue = createColumnData(
    comparisons,
    0,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'name',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  const comparisonEnterpriseValue = createColumnData(
    comparisons,
    0,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'enterprise_value',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  // LTM Revenue
  const comparisonsLTMRevenue = createColumnData(
    comparisons,
    financials?.ltm_revenue,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ltm_revenue',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  const comparisonsLTMRevenueMultiple = createColumnData(
    comparisons,
    0,
    `${PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX}_ltm_revenue_multiple`,
    '',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY_LTM_REVENUE_MULTIPLE,
    current =>
      isFinite(getNumberValue(current?.enterprise_value) / getNumberValue(current?.ltm_revenue))
        ? getNumberValue(current?.enterprise_value) / getNumberValue(current?.ltm_revenue)
        : 0
  );

  // LTM Revenue Growth
  const comparisonsLTMRevenueGrowth = createColumnData(
    comparisons,
    financials?.ltm_revenue_growth_rate === NOT_APPLICABLE_CALIBRATION
      ? financials?.ltm_revenue_growth_rate
      : getNumberValue(financials?.ltm_revenue_growth_rate),
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ltm_revenue_growth',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  // NTM Revenue
  const comparisonsNTMRevenue = createColumnData(
    comparisons,
    financials?.ntm_revenue,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ntm_revenue',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
    current =>
      Number(current?.ntm_revenue || 0) !== 0 ? getNumberValue(current?.ntm_revenue) : NOT_APPLICABLE_CALIBRATION
  );

  const comparisonsNTMRevenueMultiple = createColumnData(
    comparisons,
    0,
    `${PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX}_ntm_revenue_multiple`,
    '',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY_NTM_REVENUE_MULTIPLE,
    current =>
      isFinite(getNumberValue(current?.enterprise_value) / getNumberValue(current?.ntm_revenue))
        ? getNumberValue(current?.enterprise_value) / getNumberValue(current?.ntm_revenue)
        : 0
  );

  // NTM Revenue Growth
  const comparisonsNTMRevenueGrowth = createColumnData(
    comparisons,
    financials?.ntm_revenue_growth_rate === NOT_APPLICABLE_CALIBRATION
      ? financials?.ntm_revenue_growth_rate
      : getNumberValue(financials?.ntm_revenue_growth_rate),
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ntm_revenue_growth',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
    current =>
      Number(current?.ntm_revenue_growth || -1) !== -1
        ? getNumberValue(current?.ntm_revenue_growth)
        : NOT_APPLICABLE_CALIBRATION
  );

  // LTM EBITDA
  const comparisonsLTMEBITDA = createColumnData(
    comparisons,
    financials?.ltm_ebitda,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ltm_ebitda',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  const comparisonsLTMEBITDAMultiple = createColumnData(
    comparisons,
    0,
    `${PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX}_ltm_ebitda_multiple`,
    '',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY_LTM_EBITDA_MULTIPLE,
    current =>
      isFinite(getNumberValue(current?.enterprise_value) / getNumberValue(current?.ltm_ebitda))
        ? getNumberValue(current?.enterprise_value) / getNumberValue(current?.ltm_ebitda)
        : 0
  );

  // NTM EBITDA
  const comparisonsNTMEBITDA = createColumnData(
    comparisons,
    financials?.ntm_ebitda,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ntm_ebitda',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  const comparisonsNTMEBITDAMultiple = createColumnData(
    comparisons,
    0,
    `${PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX}_ntm_ebitda_multiple`,
    '',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY_NTM_EBITDA_MULTIPLE,
    current =>
      isFinite(getNumberValue(current?.enterprise_value) / getNumberValue(current?.ntm_ebitda))
        ? getNumberValue(current?.enterprise_value) / getNumberValue(current?.ntm_ebitda)
        : 0
  );

  // LTM Gross Margin
  const comparisonsLTMGrossMargin = createColumnData(
    comparisons,
    financials?.ltm_gross_margin,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ltm_gross_margin',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  // LTM EBITDA Margin
  const comparisonsLTMEBITDAMargin = createColumnData(
    comparisons,
    financials?.ltm_ebitda_margin,
    PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX,
    'ltm_ebitda_margin',
    PERFORMANCE_METRICS_SPREADSHEET_COMPANY
  );

  return [
    comparisonCompanyNameValue,
    comparisonEnterpriseValue,
    comparisonsLTMRevenue,
    comparisonsLTMRevenueMultiple,
    comparisonsLTMRevenueGrowth,
    comparisonsNTMRevenue,
    comparisonsNTMRevenueMultiple,
    comparisonsNTMRevenueGrowth,
    comparisonsLTMEBITDA,
    comparisonsLTMEBITDAMultiple,
    comparisonsNTMEBITDA,
    comparisonsNTMEBITDAMultiple,
    comparisonsLTMGrossMargin,
    comparisonsLTMEBITDAMargin,
  ];
};

export default createColumnsCalibrationPerformance;
