import React, { FC, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { FormDialog } from 'components';
import { TaskProgressModalContent } from 'pages/Funds/components/ProcessManagement/TaskProgressModalContent';
import { useGetTaskProgressDetails } from 'services/hooks/firms';
import theme from 'theme';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import useStyles from './styles';
import { CustomTaskBoxProps } from './types';

const CustomTaskBox: FC<CustomTaskBoxProps> = props => {
  const { children, companyName, fundName, task, measurementDateName } = props;
  const { summary: taskDescription, progress } = task || {};
  const { completed, total } = getObjectValue(progress);

  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { firmInfo } = storeValue;

  const { data: taskProgressDetails, isLoading } = useGetTaskProgressDetails({ task });

  const percentageOfProgress = (getNumberValue(task?.progress?.completed) / (task?.progress?.total ?? 1)) * 100;
  const taskWasCompleted = percentageOfProgress === 100;

  // Colors for the box depending on the status
  const backgroundColor = taskWasCompleted ? theme.palette.gray[100] : theme.palette.pink;
  const borderColor = taskWasCompleted ? theme.palette.gray[300] : theme.palette.pink;
  const color = taskWasCompleted ? theme.palette.black : theme.palette.white;
  const classes = useStyles({ color, backgroundColor, borderColor });

  const onClick = () => {
    setOpen(true);
    setOpenTooltip(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = () => {
    if (!open) {
      setOpenTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setOpenTooltip(false);
  };

  return (
    <Tooltip
      title={`${completed}/${total}`}
      placement="top"
      arrow
      disableFocusListener
      disableTouchListener
      open={openTooltip}
      onOpen={handleMouseEnter}
      onClose={handleMouseLeave}>
      <div className={classes.root}>
        <IconButton className={classes.svgIcon} onClick={onClick}>
          {children}
        </IconButton>

        {!isLoading && (
          <FormDialog
            open={open}
            onClose={onClose}
            title={companyName}
            subtitle={`${fundName} / ${getStringValue(firmInfo?.name)}`}
            showCancelButton={false}
            showSaveButton={false}
            disableTitleClose={false}
            classesContent={{ content: { marginBottom: 0 } }}>
            <TaskProgressModalContent
              measurementDateName={measurementDateName}
              percentageOfProgress={percentageOfProgress}
              taskDescription={taskDescription}
              taskProgressDetails={taskProgressDetails}
            />
          </FormDialog>
        )}
      </div>
    </Tooltip>
  );
};

export default CustomTaskBox;
