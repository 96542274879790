/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable no-tabs */
/* eslint-disable react/display-name */
import React from 'react';
import { isEmpty, isUndefined } from 'lodash';
import * as CAPTABLE from 'common/constants/cap-table';
import * as inputOptions from 'common/constants/inputs';
import * as validationMessages from 'common/constants/messages/validations';
import {
  COMMON_STOCK as COMMON,
  CONVERTIBLE_NOTES as CONVERTIBLE,
  LIST,
  OPTION,
  PREFERRED_STOCK as PREFERRED,
  UNISSUED_OPTIONS as UNISSUED,
  WARRANT,
} from 'common/constants/securityTypes';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import { GridDialogValueViewer } from 'components/Grid';
import { seniorityOptions } from 'pages/CapTable/cap-table/utilities/getOptionsForSelect';
import { addClassName, removeClassName, removeValidationClasses, toString } from 'utilities';
import { getOptions, getSecurityType } from './underlyingSecurities';
import { ConvertibleNote, LiquidationPriority, MultipleInvestmentDates, OptionsLedger } from '../components';
import { PREFERRED_STOCK_VALUE } from '../components/convertible-notes-ledger/config/constants';

const conditions = ({ cell, cells, tableData }) => {
  const { captable: capCells } = cells;
  const { alias, columnLegend, className, value, columnId, defaultValue, tooltipMessages = [] } = cell;
  const underlyingSecurityCell = capCells[columnLegend + CAPTABLE.UNDERLYING_SECURITY_ROW_NUMBER];
  const hasDividendsCell = capCells[columnLegend + CAPTABLE.CUMMULATIVE_DIVIDENDS_ROW_NUMBER] || '';
  const hasDividends = Boolean(Number(hasDividendsCell.value));
  const hasMultipleInvestmentsCell = capCells[columnLegend + CAPTABLE.HAS_MULTIPLE_INVESTMENT_DATES_ROW_NUMBER] || '';
  const hasMultipleInvestments = Boolean(Number(hasMultipleInvestmentsCell.value));
  const { isDisabled } = tableData;

  const getValue = () => (defaultValue && !Number.isNaN(defaultValue) ? Number(defaultValue) : defaultValue ?? '');

  const resetCellConfig = () => {
    cell.hidden = true;
    cell.readOnly = true;
    cell.forceComponent = false;
    cell.disableEvents = false;
    cell.component = null;
    cell.dialog = null;
    cell.greaterThan = null;
    cell.isDisabled = isDisabled;
  };

  const setAsRequired = (isRequired = true) => {
    cell.isRequired = isRequired;
  };

  const setAsEditable = () => {
    resetCellConfig();
    cell.readOnly = isDisabled;
    cell.hidden = false;
  };

  const setAsReadOnly = () => {
    resetCellConfig();
    cell.readOnly = true;
    cell.hidden = false;
  };

  const setAsHidden = (keepValue = false) => {
    resetCellConfig();
    if (!keepValue) {
      cell.value = getValue();
      cell.expr = cell.value;
    }
    cell.className = removeClassName(removeValidationClasses(className), 'read-only--white');
    cell.tooltipMessages = null;
    cell.isRequired = false;
    cell.hidden = true;
  };

  const addTooltipMessage = messageToAdd => {
    if (!tooltipMessages.includes(messageToAdd)) {
      tooltipMessages.push(messageToAdd);
    }

    cell.tooltipMessages = tooltipMessages;
  };

  const removeTooltipMessage = messageToDelete => {
    if (tooltipMessages.includes(messageToDelete)) {
      const index = tooltipMessages.findIndex(message => message === messageToDelete);
      tooltipMessages.splice(index, 1);
    }
  };

  const isSecurityType = type => {
    const securityType = capCells[cell.columnLegend + CAPTABLE.SECURITY_TYPE_ROW_NUMBER];

    if (type && securityType && securityType.value) {
      return securityType.value.toString() === type.toString();
    }
    return false;
  };

  const isUnderlyingSecurity = type => {
    const securityType = getSecurityType(underlyingSecurityCell.value, capCells);

    if (securityType && type) {
      return securityType.toString() === type.toString();
    }
    return false;
  };

  const enableMultipleInvestments = () => {
    setAsReadOnly();
    cell.minValue = 1;
    cell.dialog = {
      content: <MultipleInvestmentDates cell={cell} />,
    };
    cell.className = addClassName(className, 'read-only--white');
    cell.disableEvents = true;
  };

  const getConvertibleNotes = () => {
    const columnConvertibleNotes = {};
    const cellsArray = Object.values(capCells).filter(c => !c.totalKey);
    cellsArray.forEach(itemCell => {
      if (
        itemCell.alias === CAPTABLE.CONVERTIBLE_NOTES_ALIAS
        && itemCell.value.length > 0
        && isUndefined(columnConvertibleNotes[itemCell.columnLegend])
      ) {
        columnConvertibleNotes[itemCell.columnLegend] = itemCell.value;
      }
    });
    return columnConvertibleNotes;
  };

  const hasConvertibleNoteAsPreferred = () => {
    let convertibleAsPreferred = false;

    const convertibleNotes = getConvertibleNotes();

    if (!isEmpty(capCells) && !isEmpty(convertibleNotes)) {
      const columnConvertibleNotes = convertibleNotes[cell.columnLegend];
      convertibleAsPreferred
        = columnConvertibleNotes?.filter(item => item.model_as_equity === PREFERRED_STOCK_VALUE && !item.is_deleted)
          .length > 0;
    }
    return convertibleAsPreferred;
  };

  // 1. Security Name
  if (alias === CAPTABLE.SECURITY_NAME_ALIAS) {
    if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      // preferred doesn't have multiple strike price -> no ledger no virtual column
      if (isUnderlyingSecurity(COMMON) || isUnderlyingSecurity(PREFERRED)) {
        if (!columnId && columnId !== 0) {
          setAsReadOnly();
          addTooltipMessage('Title of a virtual column is read-only.');
          cell.className = addClassName(className, 'read-only--white');
          cell.forceComponent = true;
          cell.disableEvents = true;
          cell.tooltipMessages = tooltipMessages;
        } else {
          setAsEditable();
        }
      }
    }
  }

  // 02.  Investment Date
  if (alias === CAPTABLE.INVESTMENT_DATE_ALIAS) {
    if (isSecurityType(PREFERRED) || isSecurityType(CONVERTIBLE)) {
      if (hasMultipleInvestments) {
        enableMultipleInvestments();
      }

      if (!hasDividends || !hasMultipleInvestments) {
        setAsEditable();
        cell.gridDateComponent = GridDate;
        cell.dataEditor = props => <GridDateEditor {...props} />;
        cell.valueViewer = props => <GridDateValueViewer {...props} />;
        cell.isRequired = true;
      }

      cell.isRequired = true;
    } else {
      setAsHidden();
    }
  }

  // 03.  Security Type
  if (alias === CAPTABLE.SECURITY_TYPE_ALIAS) {
    cell.required = true;
  }

  // 04.  Original Issue Price
  if (alias === CAPTABLE.ISSUE_PRICE_ALIAS) {
    if (isSecurityType(PREFERRED)) {
      // editable
      setAsEditable();
      setAsRequired(true);
      cell.greaterThan = 0;
      cell.skipInitialValidation = true;
    } else if (isSecurityType(CONVERTIBLE)) {
      cell.readOnly = true;
      cell.hidden = true;
      setAsRequired(false);
    } else {
      // hidden
      setAsHidden();
      setAsRequired(false);
      cell.minValue = null;
    }
  }

  // 05.  Shares Outstanding
  if (alias === CAPTABLE.SHARES_OUTSTANDING_ALIAS) {
    if (isSecurityType(UNISSUED)) {
      setAsHidden();
      setAsRequired(false);
    } else if (isSecurityType(PREFERRED)) {
      // Show ledger if has multiple investment dates
      if (hasMultipleInvestments) {
        enableMultipleInvestments();
      }

      if (!hasDividends || !hasMultipleInvestments) {
        setAsEditable();
        setAsRequired(true);
        cell.minValue = 1;
        cell.dialog = null;
      }
    } else if (isSecurityType(COMMON)) {
      setAsEditable();
      setAsRequired(true);
      cell.minValue = 1;
    } else if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(COMMON)) {
        if (!columnId && columnId !== 0) {
          addTooltipMessage('This cell is part of a virtual column');
        }

        setAsReadOnly();
        cell.minValue = 1;
        cell.className = addClassName(className, 'read-only--white');
        cell.dialog = {
          content: <OptionsLedger cell={cell} />,
        };
        cell.disableEvents = true;
        cell.tooltipMessages = tooltipMessages;
      }

      if (isUnderlyingSecurity(PREFERRED)) {
        setAsEditable();
        setAsRequired(true);
        cell.minValue = 1;
      }

      if (!isUnderlyingSecurity(PREFERRED) && !isUnderlyingSecurity(COMMON)) {
        setAsEditable();
        setAsRequired(true);
        cell.minValue = null;
      }
    } else {
      setAsHidden(true);
      setAsRequired(false);
    }
  }

  // 06.  Conversion Rate
  if (alias === CAPTABLE.CONVERSION_RATE_ALIAS) {
    if (isSecurityType(PREFERRED)) {
      setAsEditable();
      setAsRequired();
    } else if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(PREFERRED)) {
        setAsReadOnly();
      } else if (isUnderlyingSecurity(COMMON)) {
        setAsHidden();
        cell.expr = 1;
        cell.value = 1;
      }
      if (!underlyingSecurityCell.value) {
        setAsHidden();
      }
    } else if (isSecurityType(COMMON)) {
      setAsHidden();
      cell.expr = 1;
      cell.value = 1;
    } else {
      setAsHidden();
    }
  }

  // 07.  PIK Shares as of today
  if (alias === CAPTABLE.PIK_SHARES_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      if (hasDividends) {
        const dividendPaymentTypeKey = columnLegend + CAPTABLE.DIVIDEND_PAYMENT_TYPE_ROW_NUMBER;
        const dividendPaymentType = capCells[dividendPaymentTypeKey];
        const isCash = Number(dividendPaymentType.value) === inputOptions.NO;
        const isPIK = Number(dividendPaymentType.value) === inputOptions.YES;

        if (dividendPaymentType) {
          if (isCash) {
            resetCellConfig();
            cell.hidden = true;
            cell.value = null;
          }

          if (isPIK) {
            setAsReadOnly();
          }
        }
      } else {
        setAsHidden();
      }
    } else {
      setAsHidden();
    }
  }

  // 08.  Shares Fully Diluted (as converted)
  if (alias === CAPTABLE.SHARES_FULLY_DILUTED_ALIAS) {
    const sharesOutstanding = capCells[cell.columnLegend + CAPTABLE.SHARES_OUTSTANDING_ROW_NUMBER];

    if (isSecurityType(UNISSUED)) {
      setAsEditable();
      setAsRequired(true);
      cell.minValue = 1;
      cell.value = cell.value > 0 ? cell.value : '';
      cell.expr = cell.value;
    } else if (isSecurityType(CONVERTIBLE)) {
      cell.minValue = null;
      cell.className = addClassName(className, 'read-only--white');
      cell.dialog = { content: <ConvertibleNote cell={cell} /> };
      cell.disableEvents = true;
      cell.tooltipMessages = tooltipMessages;
    } else {
      cell.minValue = 0;
      cell.value = sharesOutstanding?.value === '' ? '' : cell.value;
      cell.dialog = null;
      setAsReadOnly();
      setAsRequired(false);
    }
  }

  // 09. Current Ownership
  if (alias === CAPTABLE.CURRENT_OWNERSHIP_ALIAS) {
    if (isSecurityType(UNISSUED)) {
      setAsReadOnly();
      cell.value = 0;
    } else {
      setAsReadOnly();
    }
  }

  // 11. Strike Price
  if (alias === CAPTABLE.STRIKE_PRICE_ALIAS) {
    // Hide cells when undelying is empty
    if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(PREFERRED)) {
        setAsEditable();
        cell.greaterThan = 0;
        setAsRequired();
      }
      if (isUnderlyingSecurity(COMMON)) {
        if (!columnId && columnId !== 0) {
          addTooltipMessage('This cell is part of a virtual column');
        }

        setAsReadOnly();

        cell.className = addClassName(className, 'read-only--white');
        cell.forceComponent = true;
        cell.disableEvents = true;
        cell.dialog = {
          content: <OptionsLedger cell={cell} />,
        };
        cell.greaterThan = 0;
        setAsRequired();
      }
      if (!isUnderlyingSecurity(PREFERRED) && !isUnderlyingSecurity(COMMON)) {
        setAsHidden();
        cell.greaterThan = null;
        setAsRequired(false);
      }
    } else {
      setAsHidden();
      cell.greaterThan = null;
    }
  }

  // 12.  Underlying Security
  if (alias === CAPTABLE.UNDERLYING_SECURITY_ALIAS) {
    if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      const underlyingSecurities = getOptions({
        securityType: isSecurityType(WARRANT) ? WARRANT : OPTION,
        cells: capCells,
      });

      if (isEmpty(underlyingSecurities)) {
        const preferredOrCommonMsg = `There are no "${LIST[PREFERRED - 1]}" or "${LIST[COMMON - 1]}" securities`;
        const commonMsg = `There are no "${LIST[COMMON - 1]}" securities`;

        // Clean previous tooltips
        removeTooltipMessage(preferredOrCommonMsg);
        removeTooltipMessage(commonMsg);

        const message = isSecurityType(WARRANT) ? preferredOrCommonMsg : commonMsg;
        addTooltipMessage(message);

        cell.value = '0';
        cell.hidden = false;
        cell.readOnly = isDisabled;
        cell.forceComponent = true;
        cell.options = [];
        cell.className = addClassName(className, 'warning');
      } else {
        // Verify if the Underlying Security selected is available
        const isListed = underlyingSecurities.find(security => toString(security.id) === toString(value));

        cell.value = !isListed ? null : value;
        cell.hidden = false;
        cell.readOnly = isDisabled;
        cell.forceComponent = true;
        cell.tooltipMessages = [];
        cell.options = underlyingSecurities;
        cell.className = removeClassName(className, 'warning');
      }
    } else {
      cell.hidden = true;
      cell.readOnly = true;
      cell.forceComponent = false;
      cell.tooltipMessages = [];
      cell.className = removeClassName(className, 'warning');
    }
  }

  // 14.  Liquidation Preference
  if (alias === CAPTABLE.LIQUIDATION_PREFERENCE_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      setAsEditable();
      cell.minValue = 0;
      setAsRequired();
    } else if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(PREFERRED)) {
        setAsReadOnly();
      } else if (isUnderlyingSecurity(COMMON)) {
        setAsHidden();
      }
    } else {
      setAsHidden();
      cell.minValue = 0;
    }
  }

  // 15.  Seniority
  if (cell.alias === CAPTABLE.SENIORITY_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      cell.dataEditor = props => <LiquidationPriority options={seniorityOptions(props)} {...props} />;
      cell.valueViewer = props => <SelectValueViewer {...props} />;
      cell.readOnly = isDisabled;
      cell.hidden = false;
      setAsRequired();
    } else if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(PREFERRED)) {
        setAsReadOnly();
      } else if (isUnderlyingSecurity(COMMON)) {
        setAsHidden();
      }
    } else {
      setAsHidden();
      setAsRequired(false);
      cell.minValue = 0;
    }
  }

  // 16.  Participating
  if (alias === CAPTABLE.PARTICIPATION_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      setAsRequired();
      cell.hidden = false;
      cell.readOnly = isDisabled;
      cell.forceComponent = true;
      cell.dataEditor = props => <GridSelect options={inputOptions.YES_NO} {...props} />;
      cell.valueViewer = props => <SelectValueViewer {...props} />;
    } else if (isSecurityType(WARRANT) || isSecurityType(OPTION)) {
      if (isUnderlyingSecurity(PREFERRED)) {
        setAsRequired(false);
        cell.readOnly = true;
      }
      setAsHidden();
    } else {
      setAsHidden();
    }
  }

  // 17.  Participation Cap
  if (alias === CAPTABLE.IS_PARTICIPATION_CAP_ALIAS) {
    const participating = capCells[columnLegend + CAPTABLE.PARTICIPATON_ROW_NUMBER];
    const isParticipating = participating && toString(participating.value) === '1';

    if (isParticipating) {
      if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
        setAsRequired();
        cell.hidden = false;
        cell.readOnly = isDisabled;
        cell.forceComponent = true;
        cell.dataEditor = props => <GridSelect options={inputOptions.YES_NO} {...props} />;
        cell.valueViewer = props => <SelectValueViewer {...props} />;
      }
      if (isSecurityType(OPTION) || isSecurityType(WARRANT)) {
        if (isUnderlyingSecurity(PREFERRED)) {
          cell.readOnly = true;
          setAsRequired(false);
        }
        setAsHidden();
      }
    } else {
      setAsHidden();
    }
  }

  // 18.  Participation Cap (X)
  if (alias === CAPTABLE.PARTICIPATION_CAP_ALIAS) {
    const participating = capCells[columnLegend + CAPTABLE.PARTICIPATON_ROW_NUMBER];
    const participatingCap = capCells[columnLegend + CAPTABLE.IS_PARTICIPATION_CAP_ROW_NUMBER];
    const isParticipating = participating && toString(participating.value) === '1';
    const isParticipatingCap = participatingCap && toString(participatingCap.value) === '1';

    if (isParticipating && isParticipatingCap) {
      if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
        setAsEditable();
      } else if (isSecurityType(OPTION) || isSecurityType(WARRANT)) {
        setAsReadOnly();
        setAsHidden();
        cell.expr = defaultValue;
        cell.value = defaultValue;
      }
    } else {
      setAsHidden();
      cell.expr = defaultValue;
      cell.value = defaultValue;
    }
  }

  // 19.  Cumulative Dividends
  if (alias === CAPTABLE.CUMMULATIVE_DIVIDENDS_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      cell.hidden = false;
      cell.readOnly = isDisabled;
      cell.dataEditor = props => <GridSelect options={inputOptions.YES_NO} {...props} />;
      cell.valueViewer = props => <SelectValueViewer {...props} />;
      setAsRequired();
    } else {
      setAsHidden();
    }
  }

  // 20.  Multiple Investment Dates?
  if (alias === CAPTABLE.HAS_MULTIPLE_INVESTMENT_DATES_ALIAS) {
    if (isSecurityType(PREFERRED)) {
      const multipleInvestmentsKey = columnLegend + CAPTABLE.MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER;
      const hasMultipleInvestments = Boolean(Number(cell.value));
      const { securities } = tableData;

      const securityWithMultipleInvestments
        = (Array.isArray(securities) && securities.find(security => security.order === cell.columnOrder)) || [];
      const multipleInvestments = isEmpty(capCells[multipleInvestmentsKey].value)
        ? securityWithMultipleInvestments.multiple_investments
        : capCells[multipleInvestmentsKey].value;

      // Toggle tooltip and warning based on the presence of multiple investments
      if (hasDividends && hasMultipleInvestments && (isEmpty(multipleInvestments) || multipleInvestments.length < 2)) {
        cell.className = addClassName(cell.className, 'warning');
        addTooltipMessage(validationMessages.MIN_INVESTMENTS);
      }
      if (!hasDividends || !hasMultipleInvestments || multipleInvestments?.length >= 2) {
        removeTooltipMessage(validationMessages.MIN_INVESTMENTS);
        cell.className = removeClassName(cell.className, 'warning');
      }

      if (hasDividends) {
        cell.readOnly = isDisabled;
        cell.hidden = false;
        cell.dialog = {
          content: <MultipleInvestmentDates cell={cell} />,
        };
        cell.dataEditor = props => <GridSelect options={inputOptions.YES_NO} {...props} />;
        cell.valueViewer = props => (
          <GridDialogValueViewer isDisabled={isDisabled} options={inputOptions.YES_NO} {...props} />
        );
        setAsRequired();
        cell.disableEvents = true;
        cell.skipInitialValidation = true;
      } else {
        setAsHidden();
        cell.tooltipMessages = tooltipMessages;
        cell.expr = defaultValue;
        cell.value = defaultValue;
      }
    } else if (isSecurityType(CONVERTIBLE)) {
      cell.value = getValue();
      cell.dialog = { content: <ConvertibleNote cell={cell} /> };
      cell.minValue = null;
      cell.disableEvents = true;
      cell.tooltipMessages = tooltipMessages;
      cell.dbType = 'string';
      cell.readOnly = isDisabled;
      cell.disableWhenLedgerOpen = false;
    } else {
      setAsHidden();
      cell.tooltipMessages = tooltipMessages;
      cell.expr = defaultValue;
      cell.value = defaultValue;
    }
  }

  // 21.  Cash or PIK
  if (alias === CAPTABLE.DIVIDEND_PAYMENT_TYPE_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      setAsEditable();
      if (hasDividends) {
        cell.dataEditor = props => <GridSelect options={inputOptions.DIVIDEND_PAYMENT_TYPE} {...props} />;
        cell.valueViewer = props => <SelectValueViewer {...props} />;
        setAsRequired();
        cell.skipInitialValidation = true;
      } else {
        setAsHidden();
        cell.tooltipMessages = tooltipMessages;
        cell.expr = defaultValue;
        cell.value = defaultValue;
      }
    } else {
      setAsHidden();
      cell.tooltipMessages = tooltipMessages;
      cell.expr = defaultValue;
      cell.value = defaultValue;
    }
  }

  // 22.  Dividend Rate
  if (alias === CAPTABLE.DIVIDEND_RATE_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      if (hasDividends) {
        setAsEditable();
      } else {
        setAsHidden();
      }
    } else {
      setAsHidden();
    }
  }

  // 23.  Compounding
  if (alias === CAPTABLE.COMPOUNDING_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      if (hasDividends) {
        setAsEditable();
        setAsRequired();
        cell.dataEditor = props => <GridSelect options={inputOptions.YES_NO} {...props} />;
        cell.valueViewer = props => <SelectValueViewer {...props} />;
        cell.skipInitialValidation = true;
      } else {
        setAsHidden();
      }
    } else {
      setAsHidden();
    }
  }

  // 24.  Compounding Periods/Year
  if (alias === CAPTABLE.COMPOUNDING_PERIOD_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      if (hasDividends) {
        const hasCompounding = capCells[columnLegend + CAPTABLE.COMPOUNDING_ROW_NUMBER];

        if (hasCompounding && Number(hasCompounding.value) === inputOptions.YES) {
          setAsEditable();
        } else {
          setAsHidden();
          cell.expr = '1';
          cell.value = '1';
        }
      } else {
        setAsHidden();
        cell.expr = '0';
        cell.value = '0';
      }
    } else {
      setAsHidden();
      cell.expr = '0';
      cell.value = '0';
    }
  }

  // 25.  Accrued Dividends (as of 12/31/2018)
  if (alias === CAPTABLE.ACCRUED_DIVIDENDS_ALIAS) {
    setAsHidden(true);
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      const cumulativeDividendsKey = columnLegend + CAPTABLE.CUMMULATIVE_DIVIDENDS_ROW_NUMBER;
      const cumulativeDividendsCell = capCells[cumulativeDividendsKey];
      const cumulativeDividendsValue = cumulativeDividendsCell?.value;
      const hasCumulativeDividends = toString(cumulativeDividendsValue) === '1';

      if (hasCumulativeDividends) {
        if (hasMultipleInvestments) {
          setAsReadOnly();
          cell.dialog = { content: <MultipleInvestmentDates cell={cell} /> };
          cell.className = addClassName(className, 'read-only--white');
          cell.disableEvents = true;
        } else {
          setAsReadOnly();
          cell.dialog = null;
          cell.className = removeClassName(className, 'read-only--white');
        }
      }
    }
  }

  // 26.  Initial Liquidation Preference
  if (alias === CAPTABLE.INITIAL_LIQUIDATION_PREFERENCE_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      setAsReadOnly();
    } else {
      setAsHidden(true);
    }
  }

  // 27.  Total Preference
  if (alias === CAPTABLE.TOTAL_PREFERENCE_ALIAS) {
    if (isSecurityType(PREFERRED) || (isSecurityType(CONVERTIBLE) && hasConvertibleNoteAsPreferred())) {
      cell.hidden = false;
    } else {
      setAsHidden(true);
    }
  }

  return cell;
};

export default conditions;
