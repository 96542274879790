import React from 'react';
import { Cell } from 'common/types/scalarSpreadsheet';
import GridRowLabel from 'components/Grid/GridRowLabel';

const HEADER_CONFIG = {
  readOnly: true,
  isTitleOrHeader: true,
  valueViewer: (props: Cell) => <GridRowLabel {...props} />,
};

export const TABLE_HEADER_CONFIG = {
  ...HEADER_CONFIG,
  className: 'table-header read-only--white padded subtitle-border-bottom',
};

export const CAP_TABLE_ALIAS = 'cap_table';

export const SECURITY_TITLE = 'Security';
export const SHARES_TITLE = 'Shares';
export const SHARE_PRICE_TITLE = 'Share Price';
export const TOTAL_VALUE_TITLE = 'Value';
export const FIRST_ROW_WITH_DATA = 2;
export const SECURITY_ALIAS = 'security';
export const SHARES_ALIAS = 'shares';
export const SHARE_PRICE_ALIAS = 'share_price';
export const TOTAL_VALUE_ALIAS = 'value';
export const EQUITY_VALUE_LABEL = 'Equity Value';
export const ENTERPRISE_VALUE_LABEL = 'Enterprise Value';

export const EQUITY_VALUE_ALIAS = 'equity_value';
export const EQUITY_VALUE_LABEL_ALIAS = 'equity_value_label';
export const ENTERPRISE_VALUE_ALIAS = 'enterprise_value';
export const ENTERPRISE_VALUE_LABEL_ALIAS = 'enterprise_value_label';
export const TOTAL_ENTERPRISE_LABEL_ALIAS = 'total_enterprise_value_label';

export const SSV_TABLE_NAMES = {
  CAP_TABLE_SELECTION: 'capTableSelectionSheet',
  SECURITY_SHARES: 'securitySharesSheet',
};
