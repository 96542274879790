import { isEmpty } from 'lodash';
import { NOT_APPLICABLE } from 'common/constants/general';
import { dateMinusOrPlusXPeriods, gridShortDate, ISO_DATE_FORMAT } from 'utilities';
import { ColumnParams, MultiplePremiumDiscountDiscountColumn } from './types';
import { getTopNObjectsFromList } from '../../../utils/utilities';
import {
  ADJUSTMENT_METRIC_ALIAS,
  ADJUSTMENT_METRIC_OPTIONS,
  AS_OF_DATE_ALIAS,
  COMPUTED_PREMIUM_DISCOUNT_ALIAS,
  GPC_MEDIAN_ALIAS,
  MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE,
  MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE,
  OTHER_PREMIUM_DISCOUNT_ALIAS,
  TOTAL_PREMIUM_DISCOUNT_ALIAS,
} from '../../common/constants';

const getOneDayBeforeMeasurementDate = (measurementDate: string) =>
  dateMinusOrPlusXPeriods({
    date: measurementDate,
    period: 'days',
    amount: -1,
    format: ISO_DATE_FORMAT,
  });

const createColumns = ({ multiplePremiumDiscountFromDB, measurementDate, valuationDate }: ColumnParams) => {
  const columnTitles: MultiplePremiumDiscountDiscountColumn = {};
  const customDateColumn: MultiplePremiumDiscountDiscountColumn = {};
  const measurementDateColumn: MultiplePremiumDiscountDiscountColumn = {};

  const mpdCustomDate = !isEmpty(multiplePremiumDiscountFromDB)
    ? getTopNObjectsFromList(
      multiplePremiumDiscountFromDB.filter(item => item.name === MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE),
      1
    )[0]
    : {};
  const mpdMeasurementDate = !isEmpty(multiplePremiumDiscountFromDB)
    ? getTopNObjectsFromList(
      multiplePremiumDiscountFromDB.filter(item => item.name === MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE),
      1
    )[0]
    : {};

  const formattedAsOfDate = mpdCustomDate.as_of_date
    ? gridShortDate(mpdCustomDate.as_of_date)
    : gridShortDate(getOneDayBeforeMeasurementDate(measurementDate.date as string));
  const formattedMeasurementDate = gridShortDate(measurementDate.date);
  const formattedValuationDate = gridShortDate(valuationDate);

  const adjustmentMetricIsAvailable
    = !isEmpty(mpdCustomDate.adjustment_metric)
    && ADJUSTMENT_METRIC_OPTIONS.map(option => option.value).includes(mpdCustomDate.adjustment_metric);

  const adjustmentMetricIndex = adjustmentMetricIsAvailable
    ? ADJUSTMENT_METRIC_OPTIONS.findIndex(option => option.value === mpdCustomDate.adjustment_metric)
    : 0;

  const adjustmentMetricValue = ADJUSTMENT_METRIC_OPTIONS[adjustmentMetricIndex].value;

  // As of Date
  customDateColumn[AS_OF_DATE_ALIAS] = {
    value: formattedAsOfDate,
  };
  measurementDateColumn[AS_OF_DATE_ALIAS] = {
    value: formattedValuationDate === formattedMeasurementDate ? formattedMeasurementDate : formattedValuationDate,
  };

  // Adjusted Metric
  customDateColumn[ADJUSTMENT_METRIC_ALIAS] = {
    value: adjustmentMetricValue || mpdCustomDate.adjustment_metric,
  };
  measurementDateColumn[ADJUSTMENT_METRIC_ALIAS] = {
    value: adjustmentMetricValue || mpdMeasurementDate.adjustment_metric,
  };

  // GPC Median
  customDateColumn[GPC_MEDIAN_ALIAS] = {
    value: mpdCustomDate.gpc_median,
  };
  measurementDateColumn[GPC_MEDIAN_ALIAS] = {
    value: mpdMeasurementDate.gpc_median,
  };

  // Computed Premium / (Discount)
  customDateColumn[COMPUTED_PREMIUM_DISCOUNT_ALIAS] = {
    value: mpdCustomDate.computed_premium_discount || 0,
  };
  measurementDateColumn[COMPUTED_PREMIUM_DISCOUNT_ALIAS] = {
    value: mpdMeasurementDate.computed_premium_discount || 0,
  };

  // Other Premium / (Discount)
  customDateColumn[OTHER_PREMIUM_DISCOUNT_ALIAS] = { value: NOT_APPLICABLE };
  measurementDateColumn[OTHER_PREMIUM_DISCOUNT_ALIAS] = { value: mpdMeasurementDate.other_premium_discount || 0 };

  // Total Premium / (Discount)
  customDateColumn[TOTAL_PREMIUM_DISCOUNT_ALIAS] = {
    value: undefined,
  };
  measurementDateColumn[TOTAL_PREMIUM_DISCOUNT_ALIAS] = {
    value: mpdCustomDate.total_premium_discount || undefined,
  };

  return [columnTitles, customDateColumn, measurementDateColumn];
};

export default createColumns;
