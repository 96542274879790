/* eslint-disable no-restricted-globals */
/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DECIMAL_PLACES, ONE_HUNDRED_VALUE } from 'common/config/app';
import { HYPHEN_SYMBOL, NUMBER, PERCENT, PERCENTAGE } from 'common/constants/general';
import { STRING } from 'common/constants/gridType';
import { ENTER_DATA } from 'common/constants/inputs';
import { toString } from 'utilities';
import isExpression from 'utilities/isExpression';

function parseValue(tmpValue, endsWithDec, gridType, maximumFractionDigits = 10) {
  /* True if:
    - tmpValue is undefined/null/NaN,
    - the last character is a dot followed by zero or more numbers
  */
  let numValue = Number(toString(tmpValue).split(',').join(''));
  if (!toString(tmpValue).length || Number.isNaN(numValue) || endsWithDec(tmpValue)) {
    return tmpValue;
  }

  if (gridType === PERCENTAGE) {
    numValue *= ONE_HUNDRED_VALUE;
  }
  if (numValue !== 0 || 1 / numValue === -Infinity) {
    return numValue.toLocaleString('en-US', { maximumFractionDigits });
  }
  return numValue;
}

function parseDecimal(dbDecimalPlaces, tmpValue, endsWithDec, isWithDecimals, gridType) {
  let decimalPlaces = isWithDecimals ? dbDecimalPlaces + 1 : dbDecimalPlaces - 2;
  decimalPlaces = decimalPlaces < 0 || isNaN(decimalPlaces) ? 0 : decimalPlaces;
  return parseValue(tmpValue, endsWithDec(false), gridType, decimalPlaces);
}

const ValueEditor = ({ cell, onKeyDown, onChange, doubleClicked, setDoubleClicked }) => {
  const inputRef = useRef();
  const {
    gridType,
    format,
    value: cellValue,
    defaultValue,
    isCellCalculated,
    rowNumberPair,
    dbDecimalPlaces,
    extraAttr: cellExtraAttr,
    alias: cellAlias,
    key: cellKey,
  } = cell || {};
  const { style, maximumFractionDigitsWhenEditing, maximumFractionDigits } = format || {};
  const isPercentCell = style === PERCENT;
  const isNumeric = [NUMBER, PERCENTAGE].includes(gridType);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const endsWithDec = useCallback(
    searchDecimalNumbers => inputValue => {
      // Return false on enter
      if (cellValue === inputValue) {
        return false;
      }
      if (searchDecimalNumbers) {
        return !!inputValue.match(/\.\d*$/);
      }
      return !!inputValue.match(/\.$/);
    },
    [cellValue]
  );

  const getDisplayValue = useCallback(
    (valueParam, initialLoad = false) => {
      const isPairCell = !isNaN(rowNumberPair);
      const isPairCellCalculated = isCellCalculated;
      const isCalculatedCellWithPair = isPercentCell && isPairCell && isPairCellCalculated;
      const isExpr = isExpression(toString(valueParam));
      const tmpValue
        = (gridType === NUMBER || isCalculatedCellWithPair) && isExpr && Number(cellValue) !== 0 ? cellValue : valueParam;
      switch (gridType) {
        case NUMBER:
          return parseValue(tmpValue, endsWithDec(true));
        case PERCENTAGE:
          if (initialLoad) {
            return parseDecimal(dbDecimalPlaces, tmpValue, endsWithDec, isPercentCell, gridType);
          }
          return parseValue(tmpValue, endsWithDec(true));
        default:
          return tmpValue;
      }
    },
    [dbDecimalPlaces, isCellCalculated, rowNumberPair, cellValue, endsWithDec, gridType, isPercentCell]
  );

  const [displayValue, setDisplayValue] = useState(getDisplayValue(cellValue ?? '', true));
  const [wasDoubleClicked, setWasDoubleClicked] = useState(false);

  // The rule is that if we double click into the cell, we should preserve the original value,
  // other wise we should start typing on a blank slate
  useEffect(() => {
    if (doubleClicked) {
      setDoubleClicked(false);
      setWasDoubleClicked(true);
    } else if (!doubleClicked && !wasDoubleClicked) {
      setDisplayValue('');
    }
  }, [doubleClicked, setDoubleClicked, wasDoubleClicked]);

  const inputId = useMemo(() => {
    if (cellExtraAttr) {
      return `${cellExtraAttr}-${cellAlias}-${cellKey}_editor`;
    }

    return `${cellAlias}-${cellKey}_editor`;
  }, [cellAlias, cellExtraAttr, cellKey]);

  const getPercentageValue = currentValue => {
    const numberParts = currentValue.toString().split('.');
    const isStartingWithDot = numberParts[0] === '' && numberParts.length > 1;
    const integerPartLength = numberParts[0].length === 0 && numberParts.length > 1 ? 1 : numberParts[0].length;
    const remainingDecimalPlaces = DECIMAL_PLACES - integerPartLength;
    const numericCurrentValue = Number(currentValue);
    const calculatedValue = isStartingWithDot ? numericCurrentValue : numericCurrentValue / ONE_HUNDRED_VALUE;

    return calculatedValue.toFixed(remainingDecimalPlaces).toString();
  };

  const getTargetValue = valueParam => {
    let targetValue;
    const isExpr = isExpression(valueParam.toString());

    if (isExpr) {
      targetValue = valueParam;
    } else {
      // if valueParam is not an expression, remove commas
      targetValue = valueParam.split(',').join('');

      // if valueParam includes a percentage sign, remove it
      if (targetValue.includes('%')) {
        targetValue = targetValue.replace('%', '');
      }

      // if valueParam is a number and gridType is PERCENTAGE, convert it to a percentage
      if (!Number.isNaN(Number(targetValue)) && gridType === PERCENTAGE && !endsWithDec()(targetValue)) {
        targetValue = getPercentageValue(targetValue);
      }

      // if valueParam is a hyphen and gridType is a number, set it to 0
      if (targetValue === HYPHEN_SYMBOL && isNumeric) {
        targetValue = '0';
      } else if (!isNumeric) {
        targetValue = valueParam;
      }
    }
    return targetValue;
  };

  const countCommasInString = (str, position) => {
    // Count the number of commas in a string up to a given position
    return str?.length ? (toString(str).substring(0, position).match(/,/g) || []).length : 0;
  };

  const getCursorPosition = (value, position) => {
    const formattedValue = getDisplayValue(value);
    // Count total of commas on the value returned on event.target.value;
    const commasInTargetValue = countCommasInString(value, position);
    // Count total of commas on the formatted value (format with commas separators);
    const commasInFormattedValue = countCommasInString(formattedValue, position);
    // Calculate the difference between the two values
    const totalCommasToConsider = commasInFormattedValue - commasInTargetValue;
    // Calculate the new position
    return position + totalCommasToConsider;
  };

  // basically, on every change we will set the display value, and also update true value
  const handleChange = async e => {
    const { target } = e;
    const { value, selectionStart: position } = target;
    setDisplayValue(getDisplayValue(value));
    const targetValue = getTargetValue(value);
    await onChange(targetValue);
    if (isNumeric) {
      const cursorPosition = getCursorPosition(value, position);
      target.selectionEnd = cursorPosition;
    }
  };

  const handleFocus = () => {
    let sanitizedValue = cellValue ?? '';

    if (gridType) {
      switch (gridType) {
        case NUMBER:
          sanitizedValue = Number(cellValue);
          break;
        case PERCENTAGE:
          sanitizedValue = Number(cellValue * ONE_HUNDRED_VALUE);
          break;
        case STRING:
          sanitizedValue = cellValue === ENTER_DATA ? '' : cellValue;
          break;
        default:
          sanitizedValue = Number(cellValue);
      }
    }

    if (toString(sanitizedValue) === toString(defaultValue) || toString(sanitizedValue) === '0') {
      onChange('');
    }
    const isPlaceHolder = toString(sanitizedValue) === 'ENTER NAME';
    const displayedValue = isPlaceHolder
      ? ''
      : sanitizedValue.toLocaleString('en-US', {
        maximumFractionDigits: maximumFractionDigitsWhenEditing || maximumFractionDigits,
      });

    setDisplayValue(isPercentCell ? `${displayedValue}%` : displayedValue);
  };

  return (
    <input
      id={inputId}
      ref={inputRef}
      className="data-editor"
      value={displayValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={onKeyDown}
    />
  );
};

ValueEditor.propTypes = {
  cell: PropTypes.shape({
    alias: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gridType: PropTypes.string,
    format: PropTypes.shape({
      style: PropTypes.string,
      maximumFractionDigits: PropTypes.number,
      maximumFractionDigitsWhenEditing: PropTypes.number,
    }),
    dbDecimalPlaces: PropTypes.number,
    rowNumberPair: PropTypes.number,
    isCellCalculated: PropTypes.bool,
    extraAttr: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  doubleClicked: PropTypes.bool,
  setDoubleClicked: PropTypes.func,
};

export default ValueEditor;
