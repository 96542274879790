import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { useFormat } from 'common/hooks';
import { ConfigurationSpreadsheets } from 'common/types/scalarSpreadsheet';
import { Widgets } from 'components';
import { USD_THOUSANDS } from 'components/FeaturedSpreadsheet/constants';
import CurrencyChipContext from 'components/FeaturedSpreadsheet/context/CurrencyChipContext';
import { UPDATED_BENCHMARK_ROWS } from 'pages/Valuations/util/constants';
import DCFSummaryTable from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/DCFSummaryTable';
import FinancialsDCFTable from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/DCFTable';
import DCFTerminalValueTable from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/DCFTerminalValueTable';
import { Note } from 'pages/ValuationsAllocation/types';
import { DCFRowConfigs, updateDCFOptions } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useNotes } from 'services/hooks/notes';
import DCFApproachContext from './context/DCFApproachContext';
import { BENCHMARK_PERCENTILE_A, BENCHMARK_PERCENTILE_B } from './dcf/config/constants';
import ebitdaRowConfig from './dcfTerminalValue/config/MultipleTerminal/ebitdaRowConfig';
import revenueRowConfig from './dcfTerminalValue/config/MultipleTerminal/revenueRowConfig';
import revenueAndEbitdaRowConfig from './dcfTerminalValue/config/RevEbitdaMultiple/rowConfig';
import { DCFTableData, DiscountCashFlowProps } from './types';

const DiscountCashFlow = (props: DiscountCashFlowProps) => {
  const { spreadsheets, onChange, workbook, name, approaches } = props;
  const dcfSpreadsheet = spreadsheets.dcf;
  const tableData = dcfSpreadsheet.tableData as DCFTableData;
  const { approach, isDisabled } = tableData;
  const approachPanelId = approach.panelId;
  const benchmarkSpreadsheet = useMemo(() => workbook?.benchmark, [workbook]);
  const tableDataBenchmarkSpreadsheet = benchmarkSpreadsheet?.tableData as DCFTableData;

  const [format, formatDispatch] = useFormat();
  const [globalCurrency, setGlobalCurrency] = useState(USD_THOUSANDS);
  const [currentTerminalValue, setCurrentTerminalValue] = useState<ConfigurationSpreadsheets>();
  const { setNotesInApproach } = useContext(ValuationContext);
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  const updateCurrentTerminalValueTable = useCallback(
    (tvt: ConfigurationSpreadsheets) => {
      tvt.reverseShouldValidate();
      if (currentTerminalValue) {
        currentTerminalValue.reverseShouldValidate();
      }
      setCurrentTerminalValue(tvt);
    },
    [currentTerminalValue]
  );

  // eslint-disable-next-line
  const rowConfigsObject = {
    ebitdaRowConfig,
    revenueAndEbitdaRowConfig,
    revenueRowConfig,
  } as DCFRowConfigs;

  const resetRowConfigs = useCallback(() => {
    if (benchmarkSpreadsheet) {
      updateDCFOptions({
        DCFSpreadsheets: spreadsheets,
        benchmarkApproach: tableDataBenchmarkSpreadsheet?.approach,
        DCFRowConfigs: rowConfigsObject,
        approaches,
      });
    }
  }, [approaches, benchmarkSpreadsheet, spreadsheets, rowConfigsObject, tableDataBenchmarkSpreadsheet]);

  useEffect(() => {
    document.addEventListener(BENCHMARK_PERCENTILE_A, resetRowConfigs);
    document.addEventListener(BENCHMARK_PERCENTILE_B, resetRowConfigs);
    document.addEventListener(UPDATED_BENCHMARK_ROWS, resetRowConfigs);

    return () => {
      document.removeEventListener(BENCHMARK_PERCENTILE_A, resetRowConfigs);
      document.removeEventListener(BENCHMARK_PERCENTILE_B, resetRowConfigs);
      document.addEventListener(UPDATED_BENCHMARK_ROWS, resetRowConfigs);
    };
  }, [resetRowConfigs]);

  useEffect(() => {
    if (!isEmpty(notes) && setNotesInApproach) {
      setNotesInApproach((prevState: Note[] = []) => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);

        return [
          ...tmpNotes,
          {
            notes: notes ?? undefined,
            panelId: approachPanelId,
            notesHasChanged,
          },
        ];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  const commonProps = {
    format,
    formatDispatch,
    spreadsheets,
    onChange,
    workbook,
    name,
    terminalValue: approach?.valuations_approach_dcf?.terminal_value,
    isDisabled,
    approach,
    approaches,
  };

  const dcfContextValue = useMemo(
    () => ({
      panelId: approach?.panelId,
      format,
      onChange,
      formatDispatch,
      currentTerminalValue,
      setCurrentTerminalValue: updateCurrentTerminalValueTable,
    }),
    [approach, format, formatDispatch, currentTerminalValue, updateCurrentTerminalValueTable, onChange]
  );

  const chipContextValue = useMemo(
    () => ({
      globalCurrency,
      setGlobalCurrency,
    }),
    [globalCurrency, setGlobalCurrency]
  );

  return (
    <DCFApproachContext.Provider value={dcfContextValue}>
      <CurrencyChipContext.Provider value={chipContextValue}>
        <Grid container spacing={4}>
          <Grid xs={12} item>
            <FinancialsDCFTable {...commonProps} />
          </Grid>
          <Grid xs={12} md={6} item>
            <DCFSummaryTable {...commonProps} />
          </Grid>
          <Grid xs={12} md={6} item>
            <DCFTerminalValueTable {...commonProps} />
          </Grid>
          <Grid xs={12} item>
            <Widgets
              notesProps={{
                pageType: VALUATIONS_PAGE_VALUE,
                pageTypeKey: VALUATIONS_PAGE_KEY,
                pageTypeId: approach.id,
                notes,
                isApproach: true,
                setNotes,
                onAddNote,
                onUpdateNotes,
                onDeleteNote,
                isDisabled,
              }}
            />
          </Grid>
        </Grid>
      </CurrencyChipContext.Provider>
    </DCFApproachContext.Provider>
  );
};

export default DiscountCashFlow;
