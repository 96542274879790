/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { BACKSOLVE_SECURITY_ALIAS } from 'common/constants/allocations';
import { CustomSelect, withTooltip } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import LedgerContext from 'context/LedgerContext';
import { filterSecurities, toString } from 'utilities';

const CaptableSecurities = ({ cell, securityList, menuPosition, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const [securities, setSecurities] = useState();
  const { cells: allocationCells } = useContext(FeaturedSpreadsheetContext);

  const { onCellsChange, cells: ledgerCells } = useContext(LedgerContext);

  useEffect(() => {
    if (allocationCells) {
      const selectedSecurities = [];

      each(ledgerCells, ledgerCell => {
        if (ledgerCell.alias === BACKSOLVE_SECURITY_ALIAS && toString(cell.value) !== toString(ledgerCell.value)) {
          selectedSecurities.push(ledgerCell.value);
        }
      });

      const filteredSecurities = Array.isArray(securityList)
        ? securityList.filter(({ id }) => !selectedSecurities.includes(id))
        : [];

      setSecurities(filteredSecurities);
    }
  }, [securityList, ledgerCells, cell.value]);

  const handleChange = value => {
    const newValue = value;
    onCellsChange([
      {
        cell,
        value: newValue,
      },
    ]);
  };

  const optionIsListed = useMemo(() => {
    if (securities && Array.isArray(securities)) {
      const isListed = securities.find(item => toString(item.id) === toString(cell.value));
      return !!isListed;
    }

    return false;
  }, [securities]);

  const getOptions = useMemo(() => {
    const tmpOptions = [];
    if (securities) {
      const tmpSecurities = filterSecurities(securities);

      tmpSecurities.forEach(security => {
        tmpOptions.push({
          value: security.id,
          label: security.name,
        });
      });
    }

    return tmpOptions;
  }, [securities]);

  if (isEmpty(securities)) {
    return <span>No securities available</span>;
  }

  return (
    <CustomSelect
      id={`captable-securities-${cell.key}`}
      value={(optionIsListed && cell.value) || ''}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="SELECT SECURITY"
      disabled={cell.readOnly}
      menuPosition={menuPosition}
      disableUnderline
      openMenuOnFocus
      autoFocus
    />
  );
};

CaptableSecurities.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

CaptableSecurities.propTypes = {
  cell: PropTypes.object.isRequired,
  securityList: PropTypes.array,
  menuPosition: PropTypes.string,
};

export default withTooltip(CaptableSecurities);
