/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { oneDecimalPercentFormatValidateFloatTrue } from 'common/formats/formats';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { GridDate, GridDateEditor, GridDateValueViewer, GridSelect } from 'components/FeaturedSpreadsheet/components';
import { endOfDay } from 'utilities';
import { ADJUSTMENT_METRIC_OPTIONS } from '../../common/constants/sheetConfig';
import {
  ADJUSTMENT_METRIC_ALIAS,
  ADJUSTMENT_METRIC_TITLE,
  AS_OF_DATE_ALIAS,
  AS_OF_DATE_TITLE,
  COMPANY_PERFORMANCE_METRIC_ALIAS,
  COMPUTED_PREMIUM_DISCOUNT_ALIAS,
  COMPUTED_PREMIUM_DISCOUNT_TITLE,
  GPC_MEDIAN_ALIAS,
  GPC_MEDIAN_TITLE,
  OTHER_PREMIUM_DISCOUNT_ALIAS,
  OTHER_PREMIUM_DISCOUNT_TITLE,
  TOTAL_PREMIUM_DISCOUNT_ALIAS,
  TOTAL_PREMIUM_DISCOUNT_TITLE,
} from '../../common/constants/sheetTitlesAndAliases';

const rowConfig = (mpdInfo: { companyName: string | null; maxDate: string | null }): Rows => [
  {
    ignoreRowCopy: true,
    largeHeader: true,
  } as Row,
  {
    rowNumber: 2,
    value: AS_OF_DATE_TITLE,
    alias: AS_OF_DATE_ALIAS,
    ignoreRowCopy: true,
    dbType: 'string',
    gridType: 'gridDate',
    // @ts-ignore: gridDateComponent is not typed
    gridDateComponent: (props: unknown) => <GridDate {...props} maxDate={mpdInfo.maxDate} />,
    // @ts-ignore: dataEditor is not typed
    dataEditor: (props: unknown) => <GridDateEditor {...props} />,
    // @ts-ignore: valueViewer is not typed
    valueViewer: (props: unknown) => <GridDateValueViewer {...props} />,
    readOnly: false,
    defaultValue: '',
    customKey: 'AsOfDate',
    maxValue: new Date(endOfDay(mpdInfo.maxDate)),
  } as unknown as Row,
  {
    rowNumber: 3,
    alias: ADJUSTMENT_METRIC_ALIAS,
    // @ts-ignore: dataEditor is not typed
    dataEditor: (props: unknown) => <GridSelect options={ADJUSTMENT_METRIC_OPTIONS} {...props} />,
    ignoreRowCopy: true,
    readOnly: false,
    value: ADJUSTMENT_METRIC_TITLE,
    // @ts-ignore: valueViewer is not typed
    valueViewer: (props: unknown) => <SelectValueViewer options={ADJUSTMENT_METRIC_OPTIONS} {...props} />,
  } as unknown as Row,
  {
    rowNumber: 4,
    value: GPC_MEDIAN_TITLE,
    alias: GPC_MEDIAN_ALIAS,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
    dbDecimalPlaces: 10,
  } as Row,
  {
    rowNumber: 5,
    alias: COMPANY_PERFORMANCE_METRIC_ALIAS,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
    value: mpdInfo.companyName,
  } as Row,
  {
    rowNumber: 6,
    alias: COMPUTED_PREMIUM_DISCOUNT_ALIAS,
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
    readOnly: false,
    value: COMPUTED_PREMIUM_DISCOUNT_TITLE,
    dbDecimalPlaces: 15,
    linkedCellSymbols: ['C8'],
  } as Row,
  {
    rowNumber: 7,
    value: OTHER_PREMIUM_DISCOUNT_TITLE,
    alias: OTHER_PREMIUM_DISCOUNT_ALIAS,
    expr: '',
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
    readOnly: false,
    dbDecimalPlaces: 15,
    linkedCellSymbols: ['C8'],
  } as Row,
  {
    rowNumber: 8,
    value: TOTAL_PREMIUM_DISCOUNT_TITLE,
    alias: TOTAL_PREMIUM_DISCOUNT_ALIAS,
    expr: '',
    format: oneDecimalPercentFormatValidateFloatTrue,
    gridType: 'percentage',
    readOnly: true,
  } as Row,
];

export default rowConfig;
