/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withRequired from 'components/WithRequired';
import withTooltip from 'components/WithTooltip';
import { toString } from 'utilities';

const UnderlyingSecurity = ({ cell, ...restProps }) => {
  const { onCommit, onRevert } = restProps;
  const { options: underlyingSecurities = [] } = cell;
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);

  const optionIsListed = useMemo(() => {
    if (Array.isArray(underlyingSecurities)) {
      const isListed = underlyingSecurities.find(item => item.id === cell.value);
      return !!isListed;
    }

    return false;
  }, [underlyingSecurities]);

  const getOptions = useMemo(() => {
    const tmpOptions = [];

    if (Array.isArray(underlyingSecurities)) {
      Object.entries(underlyingSecurities).forEach(([, { name, id }]) => {
        tmpOptions.push({
          value: id,
          label: name,
        });
      });
    }

    return tmpOptions;
  }, [underlyingSecurities]);

  const handleChange = value => {
    const newValue = toString(value);
    onCellsChanged([{ cell, value: newValue }]);
  };

  return (
    <CustomSelect
      id={`underlying-select-${cell.key}`}
      value={(optionIsListed && cell.value) || ''}
      options={getOptions}
      onChange={handleChange}
      onCommit={onCommit}
      onRevert={onRevert}
      onEmptyValue="SELECT SECURITY"
      disabled={!!cell.readOnly || isEmpty(getOptions)}
      disableUnderline
    />
  );
};

UnderlyingSecurity.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

UnderlyingSecurity.propTypes = {
  cell: PropTypes.object,
};

export default withRequired(withTooltip(UnderlyingSecurity));
