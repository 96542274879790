import React from 'react';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { CapTableSelectionConfig } from './config/rowConfig/types';

const CapTableSelection = ({ spreadsheets, onChange, workbook }: CapTableSelectionConfig) => {
  const { capTableSelectionSheet } = spreadsheets;
  const { data } = capTableSelectionSheet;

  return (
    <ScalarSpreadsheet
      {...capTableSelectionSheet}
      data={data}
      id="cap-table-selection"
      key={capTableSelectionSheet.name}
      onChange={onChange}
      sheet={capTableSelectionSheet}
      workbook={workbook}
      validations={() => {}}
      allowAddMultipleRows
      allowAddSingleRow
      sortedColumn={undefined}
      isToolbarVisible
      disableVerticalScroll
      isLedgerTable
    />
  );
};

export default CapTableSelection;
