import { isEmpty } from 'lodash';
import type { CustomUser } from 'api/models/CustomUser';

const GA_MEASUREMENT_ID = 'G-66HY7R7H0L';
const USER_PROPERTIES = 'user_properties';

declare global {
  interface Window {
    gtag: any;
  }
}

export const initAnalytics = (user: CustomUser | null): void => {
  window.gtag('js', new Date());
  window.gtag('config', GA_MEASUREMENT_ID, {
    transport_url: 'https://metrics.scalar.io',
  });

  if (user && !isEmpty(user)) {
    const findEmailProvider = (address: string): string => {
      const lastIndex = address.lastIndexOf('@');
      return address.slice(lastIndex + 1);
    };

    window.gtag('set', USER_PROPERTIES, {
      user_id: user.id,
      user_email_domain: user.email ? findEmailProvider(user.email) : '',
      is_superuser: user.is_superuser,
    });
  }
};

const formattedSlug = (slugParam: string): string => {
  const arr = slugParam.replace(/-/g, ' ').split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const sendPageView = (path: string, slug: string): void => {
  const sendData = (finalSlug: string): void => {
    document.title = formattedSlug(finalSlug);

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: finalSlug || document.title,
        page_location: window.location.href,
        page_path: path,
      });
    }
  };

  if (path.includes('companies') && path.includes(slug) && slug !== 'summary') {
    sendData(slug);
  } else {
    const PATH = (nameToMatch: string): boolean => path.includes(nameToMatch);
    let finalSlug = slug;

    switch (true) {
      case PATH('companies'):
        finalSlug = 'company-summary';
        break;
      case PATH('investments'):
        finalSlug = 'fund-investments';
        break;
      case PATH('funds'):
        finalSlug = 'fund-summary';
        break;
      case PATH('account-settings'):
        finalSlug = 'account-settings';
        break;
      case PATH('permissions'):
        finalSlug = 'permissions';
        break;
      case PATH('user-management'):
        finalSlug = 'user-management';
        break;
      case PATH('comp-groups'):
        finalSlug = 'comp-groups';
        break;
      case PATH('firms'):
        finalSlug = 'firm-summary';
        break;
      case PATH('questionnaires'):
        finalSlug = 'questionnaire';
        break;
      case PATH('requests'):
        finalSlug = 'document-request';
        break;

      default:
        break;
    }
    sendData(finalSlug);
  }
};

export const sendButtonEvent = (currentPath: string, label: string): void => {
  window.gtag('event', 'button-click', {
    button_location: currentPath,
    button_text: label,
  });
};
