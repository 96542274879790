import React, { FC } from 'react';
import { Box, Grid, Radio, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FolderIcon } from 'pages/RequestDocuments/components/FolderIcon';
import theme from 'theme';
import { formatDateViewLL } from 'utilities';
import { TaskProgressModalContentBodyDocumentProps } from './types';

const TaskProgressModalContentBodyDocument: FC<TaskProgressModalContentBodyDocumentProps> = props => {
  const { taskProgressDetails } = props;

  return (
    <PerfectScrollbar style={{ overflowY: 'scroll', height: '30rem' }}>
      <Box margin="2rem auto">
        {taskProgressDetails?.progress?.map(requestedFile => (
          <Box
            key={requestedFile.id}
            border={`1px solid ${theme.palette.gray[300]}`}
            borderRadius="4px"
            padding="1rem"
            marginBottom="1rem"
            bgcolor={theme.palette.gray[100]}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <Radio checked={requestedFile.is_uploaded} style={{ pointerEvents: 'none', padding: 0 }} />
              </Grid>
              <Grid item xs={11}>
                <Typography style={{ fontWeight: 700, color: theme.palette.black, fontSize: '1rem' }}>
                  {requestedFile.name}
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <div>
                  {requestedFile?.files?.map(file => (
                    <Box key={file.id} display="flex" alignItems="center">
                      <FolderIcon requestedFile={file} hideRadio hideBorder size="small" />
                      <Box display="flex" flexDirection="column" marginTop="1rem">
                        <Typography style={{ fontWeight: 400, fontSize: '1rem', color: theme.palette.primary[500] }}>
                          {file.filename}.{file.file_type}
                        </Typography>
                        <Typography
                          key={file.id}
                          style={{ fontWeight: 400, fontSize: '1rem', color: theme.palette.black }}>
                          {formatDateViewLL(file.uploaded_date)}
                          {file.uploaded_by ? ` by ${file.uploaded_by}` : ''}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </PerfectScrollbar>
  );
};

export default TaskProgressModalContentBodyDocument;
