import React from 'react';
import { useFormat } from 'common/hooks';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { SecuritySharesTableConfig } from './types';

const SecuritySharesTable = ({
  spreadsheet,
  onChange,
  workbook,
  setIsDisplayingRowNumbers,
}: SecuritySharesTableConfig) => {
  const [format, formatDispatch] = useFormat({
    page: 'captable',
    units: '',
  });

  const tableKey = 'security-shares-table';

  return (
    <ScalarSpreadsheet
      {...spreadsheet}
      id={tableKey}
      tableTerms={{
        tableSlug: tableKey,
      }}
      onChange={onChange}
      sheet={spreadsheet}
      workbook={workbook}
      format={format}
      formatDispatch={formatDispatch}
      setIsDisplayingRowNumbers={setIsDisplayingRowNumbers}
      disableVerticalScroll
      isLedgerTable
      isToolbarVisible
    />
  );
};

export default SecuritySharesTable;
