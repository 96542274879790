import { isEmpty } from 'lodash';
import { getStringValue } from 'utilities';
import { HandleEquityValueParams } from './types';

const handleEquityValue = (params: HandleEquityValueParams) => {
  const { approach, cell, isEquityValue } = params;

  if (isEquityValue && !isEmpty(approach)) {
    approach.equity_value = getStringValue((cell?.value as string) ?? '0');
  }
};

export default handleEquityValue;
