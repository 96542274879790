/* eslint-disable max-len */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { globalAction, valuationsAction } from 'common/actions';
import { ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY } from 'common/constants/services/companies';
import { GET_APPROACHES_ERROR, VALUATIONS_SSV_NO_SECURITIES_MESSAGE } from 'common/constants/valuations/valuations';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import DashboardService from 'services/dashboard';
import ValuationService from 'services/valuations';
import { getArrayValue } from 'utilities';
import { useResponse } from './useResponse';

const useValuation = () => {
  const [data, setData] = useState();
  const [isFetchingDValuation, setIsFetchingDValuation] = useState(false);

  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { enqueueSnackbar } = useSnackbar();
  const { processErrorResponse } = useResponse();

  const valuationSvc = useMemo(() => new ValuationService(), []);

  // React Query Client
  const queryClient = useQueryClient();

  const fetchData = useCallback(
    async (allocationId, updateGlobalStore = true, showFeedback = true) => {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        setIsFetchingDValuation(true);
        const response = await valuationSvc.getValuation(allocationId);

        if (response) {
          setData(response);
          if (updateGlobalStore) {
            dispatch(valuationsAction.setValuationInfo(response));
          }
        }
      } catch (error) {
        setIsFetchingDValuation(false);
        setData([]);
        if (showFeedback) {
          const defaultErrorMessage = 'An error occurred while retrieving the valuation';
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'retrieving the valuation',
          });
        }
      } finally {
        setIsFetchingDValuation(false);
        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, processErrorResponse, valuationSvc]
  );

  const updateData = useCallback(
    async (valuation, updateGlobalStore = true, showFeedback = true) => {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const update = { ...valuation };
        delete update.allocation;

        const valuationsApproachSsv = update.valuations_approaches.filter(approach => approach.valuations_approach_ssv);
        if (valuationsApproachSsv.some(approach => approach.valuations_approach_ssv?.share_values?.length === 0)) {
          throw new Error(VALUATIONS_SSV_NO_SECURITIES_MESSAGE);
        }

        const response = await valuationSvc.updateValuation(update);

        if (response) {
          setData(response);
          enqueueSnackbar('Valuation updated successfully', { variant: 'success' });
          setAction(false);

          if (updateGlobalStore) {
            dispatch(valuationsAction.setValuationInfo(response));
          }
        }
      } catch (error) {
        if (showFeedback) {
          const err = {
            [VALUATIONS_SSV_NO_SECURITIES_MESSAGE]: VALUATIONS_SSV_NO_SECURITIES_MESSAGE,
          };
          const defaultErrorMessage = 'An error occurred while retrieving the valuation';
          processErrorResponse({
            error,
            defaultErrorMessage: err[error.message] || defaultErrorMessage,
            action: 'retrieving the valuation',
          });
        }
      } finally {
        // Invalidate the Allocation Values query to refetch the data with the new values
        queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

        dispatch(globalAction.showLoadingProgress(false));
      }
    },
    [dispatch, enqueueSnackbar, processErrorResponse, queryClient, setAction, valuationSvc]
  );

  return [data, fetchData, updateData, setData, isFetchingDValuation];
};

export const useGetValuationApproaches = () => {
  const { processErrorResponse } = useResponse();
  const [, dispatch] = useStore();
  const [data, setData] = useState();

  const fetchData = async (measurementDateId, allocationVersionId, showFeedback = false) => {
    dispatch(globalAction.showLoadingProgress(true));

    try {
      const valuationSvc = new ValuationService();
      const response = await valuationSvc.getValuationApproaches(measurementDateId, allocationVersionId);

      if (response?.results?.length) {
        setData(response.results);
      }
    } catch (error) {
      setData([]);
      if (showFeedback) {
        processErrorResponse({ error, GET_APPROACHES_ERROR });
      }
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  return [data, fetchData];
};

const useGetGpc = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const { processErrorResponse } = useResponse();

  const fetch = async (ticker, md, currency, financialsPeriods = []) => {
    dispatch(globalAction.showLoadingProgress(true));
    const defaultErrorMessage = `Could not retrieve data for ${ticker}`;
    const errorAction = `get gpc: ${ticker}`;
    try {
      const valuationSvc = new ValuationService();

      // Generating string with uniques years
      const calendarYears = [...new Set(financialsPeriods.map(period => period.statement_date.slice(0, 4)))].join(',');

      const gpc = await valuationSvc.getGpc(ticker, md, currency, calendarYears);
      // GPC data is not empty
      if (gpc.valid_gpc_results) {
        setData(gpc);
      } else {
        /* Return the ticker back to the calling component to indicate that it is not valid,
        and therefore no data is available. Errors should only be raised when running into
        actual HTTP errors, which are handled by the catch below. */
        setData({
          requested_identifier: ticker,
        });
      }
    } catch (error) {
      setData();
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: errorAction,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  const cleanCompanyData = () => setData();
  return [data, fetch, cleanCompanyData];
};

const useGetBenchmarkMultiples = () => {
  const [data, setData] = useState();
  const { processErrorResponse } = useResponse();
  const fetchData = useCallback(
    async allocationId => {
      const defaultErrorMessage = 'Could not find Public Comps or Transaction Comps multiples for this allocation';
      const valuationService = new ValuationService();
      try {
        const multiples = await valuationService.getBenchmarkMultiples(allocationId);
        setData(multiples);
      } catch (error) {
        processErrorResponse({
          error,
          defaultErrorMessage,
        });
      }
    },
    [processErrorResponse]
  );
  return [data, fetchData];
};

const useGetPublicCompList = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const { processErrorResponse } = useResponse();
  const fetchData = async (tickerList, md, currency, financialsPeriods = []) => {
    dispatch(globalAction.showLoadingProgress(true));
    const defaultErrorMessage = 'An error occurred while retrieving the public companies';
    const valuationService = new ValuationService();
    const calendarYears = [...new Set(financialsPeriods.map(period => period.statement_date.slice(0, 4)))].join(',');

    try {
      const response = await valuationService.getPublicCompList(tickerList, md, currency, calendarYears);

      setData(response);
    } catch (error) {
      processErrorResponse({
        error,
        defaultErrorMessage,
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };

  const removeComps = () => setData();

  return [data, fetchData, removeComps];
};

export const getCompsListByBatch = async (
  identifiers,
  doCapIqRequest,
  processErrorResponseFn,
  defaultErrorMessage = 'An error occurred while retrieving the public companies'
) => {
  try {
    // split identifiers up into groups of 7, launch a request for each group, and return the combined results
    const MAX_IDENTIFIERS = 7;
    const chunkedIdentifiers = identifiers.reduce((acc, curr, i) => {
      // this is the maximum number of identifiers the backend will process with CAP IQ in a single request
      // so we divide requests into groups of seven for maximum efficiency
      const index = Math.floor(i / MAX_IDENTIFIERS);
      if (!acc[index]) {
        acc[index] = []; // eslint-disable-line no-param-reassign
      }
      acc[index].push(curr);
      return acc;
    }, []);
    const promises = chunkedIdentifiers.map(async chunk => {
      const response = await doCapIqRequest(chunk);
      return response;
    });
    const responses = await Promise.all(promises);
    const results = responses.reduce(
      (acc, curr) => {
        acc.results = [...acc.results, ...curr.results];
        return acc;
      },
      { results: [] }
    );
    return results;
  } catch (error) {
    const action = 'fetch public comps for date';
    processErrorResponseFn({
      error,
      defaultErrorMessage,
      action,
    });
  }
};

const useGetPublicCompListBatchByDate = () => {
  const [, dispatch] = useStore();
  const [data, setData] = useState();
  const { processErrorResponse } = useResponse();

  const fetchData = async (tickerListsByDate, currency, financialsPeriods = []) => {
    const valuationService = new ValuationService();
    const calendarYears = [...new Set(financialsPeriods.map(period => period.statement_date.slice(0, 4)))].join(',');
    const compsByDate = {};
    dispatch(globalAction.showLoadingProgress(true));

    const promises = Object.entries(tickerListsByDate).map(async ([customDate, tickerList]) => {
      const doCapIqRequest = async chunk => {
        const response = await valuationService.getPublicCompList(chunk, 0, currency, calendarYears, customDate);
        return response;
      };

      const data = await getCompsListByBatch(tickerList, doCapIqRequest, processErrorResponse);
      const { results: comparisons } = data;
      return [customDate, comparisons];
    });

    const results = await Promise.all(promises);
    // eslint-disable-next-line no-restricted-syntax
    for (const [customDate, comparisons] of results) {
      compsByDate[customDate] = comparisons;
    }
    setData(compsByDate);
    dispatch(globalAction.showLoadingProgress(false));
  };

  const removeComps = () => setData();

  return [data, fetchData, removeComps];
};

const useGetEffectiveTaxRate = () => {
  const { processErrorResponse } = useResponse();

  const fetch = useCallback(
    async companyMeasurementDate => {
      try {
        const valuationSvc = new ValuationService();
        return await valuationSvc.getEffectiveTaxRate(companyMeasurementDate);
      } catch (error) {
        const defaultErrorMessage = 'Effective tax rate could not be found';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'get effective tax rate',
        });
        return error;
      }
    },
    [processErrorResponse]
  );
  return [fetch];
};

const useSaveEffectiveTaxRate = () => {
  const [, dispatch] = useStore();

  const { setAction } = useContext(UnsavedChanges);
  const { processErrorResponse, successNotification } = useResponse();

  const saveEffectiveTaxRate = async data => {
    let response = null;
    if (data) {
      try {
        dispatch(globalAction.showLoadingProgress(true));
        const valuationSvc = new ValuationService();
        response = await valuationSvc.saveEffectiveTaxRate(data);
        setAction(false);
        successNotification('Effective Tax Rate saved successfully');
      } catch (error) {
        const defaultErrorMessage = 'An error ocurred while saving the Effective Tax Rate';
        processErrorResponse({ error, defaultErrorMessage });
        response = error.response;
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
      }
    }
    return response;
  };

  return [saveEffectiveTaxRate];
};

/**
 * @function
 * @name useGetCompGroupByFirmId
 * @param {integer} firmId
 * @description Hook that fetch the comp groups list by the firm id
 * @returns {array}
 */
const useGetCompGroupByFirmId = () => {
  const [data, setData] = useState();
  const [{ firmInfo }] = useStore();
  const { processErrorResponse } = useResponse();
  const fetchData = async firmId => {
    const defaultErrorMessage = 'An error occurred while retrieving the comp groups';
    const valuationService = new ValuationService();
    try {
      const response = await valuationService.getFirmCompGroups(firmId);
      const compGroups = getArrayValue(response?.results);

      setData(compGroups);
    } catch (error) {
      processErrorResponse({
        error,
        defaultErrorMessage,
      });
    }
  };
  useEffect(() => {
    if (firmInfo?.id) {
      fetchData(firmInfo?.id);
    }
    // eslint-disable-next-line
  }, [firmInfo]);
  return [data];
};

const useGetCompsByDate = () => {
  const { processErrorResponse } = useResponse();
  const fetch = async (identifiers, date, currency, firmId, identifiersAsCapIqIds = false) => {
    const service = new DashboardService();
    const doCapIqRequest = async chunk => {
      const response = await service.getPublicCompsByDate(date, chunk, currency, firmId, identifiersAsCapIqIds);
      return response;
    };

    const results = await getCompsListByBatch(identifiers, doCapIqRequest, processErrorResponse);
    return results;
  };
  return [fetch];
};

const useQueryTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, dispatch] = useStore();
  const { processErrorResponse } = useResponse();

  const queryTransaction = useCallback(
    async transactionId => {
      try {
        setIsLoading(true);
        dispatch(globalAction.showLoadingProgress(true));
        const service = new ValuationService();
        const response = await service.getTransaction(transactionId);
        return response;
      } catch (error) {
        const defaultErrorMessage
          = 'An error occurred while retrieving the transaction. Check the transaction ID and try again.';
        processErrorResponse({
          error,
          defaultErrorMessage,
        });
      } finally {
        dispatch(globalAction.showLoadingProgress(false));
        setIsLoading(false);
      }
    },
    [processErrorResponse, dispatch]
  );
  return { queryTransaction, isLoading };
};

export default useValuation;
export {
  useGetGpc,
  useGetEffectiveTaxRate,
  useSaveEffectiveTaxRate,
  useGetBenchmarkMultiples,
  useGetPublicCompList,
  useGetPublicCompListBatchByDate,
  useGetCompGroupByFirmId,
  useGetCompsByDate,
  useQueryTransaction,
};
