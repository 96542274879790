import React from 'react';
import { largeCurrencyFormat, largeDecimalFormat, smallCurrencyFormat } from 'common/formats/formats';
import { Cell, Cells, GridType } from 'common/types/scalarSpreadsheet';
import GridRowLabel from 'components/Grid/GridRowLabel';
import { underScoreSpaces } from 'pages/Allocation/allocations/utilities/util';
import {
  ENTERPRISE_VALUE_LABEL,
  ENTERPRISE_VALUE_LABEL_ALIAS,
  EQUITY_VALUE_ALIAS,
  EQUITY_VALUE_LABEL,
  EQUITY_VALUE_LABEL_ALIAS,
  SECURITY_ALIAS,
  SECURITY_TITLE,
  SHARE_PRICE_ALIAS,
  SHARE_PRICE_TITLE,
  SHARES_ALIAS,
  SHARES_TITLE,
  TABLE_HEADER_CONFIG,
  TOTAL_ENTERPRISE_LABEL_ALIAS,
  TOTAL_VALUE_ALIAS,
  TOTAL_VALUE_TITLE,
} from 'pages/ValuationsAllocation/approaches/SpecifiedShareValues/constants';
import { getSumExprCells } from 'pages/ValuationsAllocation/approaches/SpecifiedShareValues/utils';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import {
  SHEET_ALIASES_CONSTANTS,
  VALUATION_SUMMARY_KEY,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { CellParserParams, ShareValue } from './types';

const { VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY, VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY }
  = SHEET_ALIASES_CONSTANTS;

const cellParser = (params: CellParserParams) => {
  const { initialObject, rowConfig, tableData } = params;
  const { isDisabled, securities } = tableData;
  const customClasses = `${TABLE_HEADER_CONFIG.className} align-right`;
  const shareValueMap = new Map();
  initialObject.valuations_approach_ssv?.share_values?.forEach((shareValue: ShareValue) => {
    shareValueMap.set(shareValue.security, shareValue);
  });
  const cells = {
    A1: {
      ...TABLE_HEADER_CONFIG,
      value: SECURITY_TITLE,
      key: 'A1',
      valueViewer: (props: Cell) => <GridRowLabel {...props} unitsFormatter={false} currencyFormatter formatterCell />,
    } as Cell,
    B1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: SHARE_PRICE_TITLE,
      key: 'B1',
    } as Cell,
    C1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: SHARES_TITLE,
      key: 'C1',
    } as Cell,
    D1: {
      ...TABLE_HEADER_CONFIG,
      className: customClasses,
      value: TOTAL_VALUE_TITLE,
      key: 'D1',
    } as Cell,
  } as Cells;

  const EQUITY_VALUE_LABEL_FORMAT = {
    readOnly: true,
    value: EQUITY_VALUE_LABEL,
    key: `A${rowConfig.length - 1}`,
    alias: EQUITY_VALUE_LABEL_ALIAS,
  };

  const ENTERPRISE_VALUE_LABEL_FORMAT = {
    readOnly: true,
    value: ENTERPRISE_VALUE_LABEL,
    key: `A${rowConfig.length}`,
    alias: ENTERPRISE_VALUE_LABEL_ALIAS,
  };

  const TOTAL_EQUITY_VALUE_CELL = {
    readOnly: true,
    value: '',
    gridType: 'number' as GridType,
    key: `D${rowConfig.length - 1}`,
    expr: `=SUM(${getSumExprCells('D', securities)})`,
    format: largeCurrencyFormat,
    alias: EQUITY_VALUE_ALIAS,
  };

  const TOTAL_ENTERPRISE_VALUE_CELL = {
    readOnly: true,
    value: '',
    gridType: 'number' as GridType,
    key: `D${rowConfig.length}`,
    expr: `=SUM(${getSumExprCells('D', securities)}) +
     ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_PLUS_DEBT_KEY} - 
     ${VALUATION_SUMMARY_KEY}.${VALUATION_SUMMARY_SPREADSHEET_LESS_CASH_KEY}`,
    customKey: VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
    format: largeCurrencyFormat,
    alias: TOTAL_ENTERPRISE_LABEL_ALIAS,
  };

  cells[EQUITY_VALUE_LABEL_FORMAT.key] = EQUITY_VALUE_LABEL_FORMAT;
  cells[ENTERPRISE_VALUE_LABEL_FORMAT.key] = ENTERPRISE_VALUE_LABEL_FORMAT;
  cells[TOTAL_EQUITY_VALUE_CELL.key] = TOTAL_EQUITY_VALUE_CELL;
  cells[TOTAL_ENTERPRISE_VALUE_CELL.key] = TOTAL_ENTERPRISE_VALUE_CELL;

  rowConfig.forEach((security, rowIndex) => {
    if (!security.skipParse) {
      const rowNumber = rowIndex + 1;
      const securityNameCell = {
        alias: SECURITY_ALIAS,
        key: `A${rowNumber}`,
        columnLegend: 'A',
        expr: security.name,
        readOnly: true,
        ignoreRowCopy: true,
        isRequired: true,
        id: security.security,
      };

      const sharePriceValueCell = {
        key: `B${rowNumber}`,
        value: shareValueMap.get(security.id)?.value,
        gridType: 'number' as GridType,
        format: smallCurrencyFormat,
        customKey: underScoreSpaces(security.name),
        minValue: 0,
        alias: SHARE_PRICE_ALIAS,
        readOnly: isDisabled,
      };

      const sharesCell = {
        key: `C${rowNumber}`,
        value: security.shares,
        gridType: 'number' as GridType,
        format: largeDecimalFormat,
        minValue: 0,
        alias: SHARES_ALIAS,
        readOnly: true,
        isRequired: true,
      };

      const totalValueCell = {
        key: `D${rowNumber}`,
        expr: `=B${rowNumber} * C${rowNumber}`,
        customKey: `${underScoreSpaces(security.name)}_aggregate`,
        format: TOTAL_EQUITY_VALUE_CELL.format,
        alias: TOTAL_VALUE_ALIAS,
        gridType: 'number' as GridType,
      };

      cells[securityNameCell.key] = securityNameCell;
      cells[sharePriceValueCell.key] = sharePriceValueCell;
      cells[sharesCell.key] = sharesCell;
      cells[totalValueCell.key] = totalValueCell;
    }
  });

  return cells;
};

export default cellParser;
