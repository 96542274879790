import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LinearProgress, MenuItem, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RefreshIcon from '@material-ui/icons/Refresh';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormat } from 'common/hooks';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useGetPublicCompListBatchByDate } from 'services/hooks';
import { dbShortDate, formatDate } from 'utilities';
import { useRefreshHelpersWithContext } from './hooks';
import useStyles from './RefreshGPCOption.styles';

const RefreshGPCOption = ({ approach, approaches, setApproaches, closeApproachMenu }) => {
  const classes = useStyles();
  const gpcApproach = approach.valuations_approach_gpc;
  const [isLoading, setIsLoading] = useState(false);

  const { financialsPeriods, valuation } = useContext(ValuationContext);
  // Always use the financials currency. That's what we do in the export and in the rollover. Using a differnet currency
  // can actually result in slightly different values
  const [format] = useFormat();
  const { getTickerListsByDate, getRefreshedApproaches } = useRefreshHelpersWithContext();
  const [publicCompsDataByDate, fetchGPCCompListsByDate] = useGetPublicCompListBatchByDate();

  useEffect(() => {
    if (!isEmpty(publicCompsDataByDate)) {
      const refreshedApproaches = getRefreshedApproaches(publicCompsDataByDate, [approach], approaches);

      setIsLoading(false);
      closeApproachMenu();

      // This is for not lost the user introduced and not saved data when
      // the useEffect on src/pages/Valuations/Valuation.js try to syncronize
      // the valuation.valuations_approaches with the "approaches" state variable.
      // Actually we do the same when we add/remove an approach
      valuation.valuations_approaches = [...refreshedApproaches];

      // redraw approaches Accordion with the updated approach
      setApproaches([...refreshedApproaches]);
    }
  }, [
    publicCompsDataByDate,
    approaches,
    setApproaches,
    approach,
    closeApproachMenu,
    getRefreshedApproaches,
    valuation,
  ]);

  const refreshGPCApproach = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const tickerListsByDate = getTickerListsByDate([approach], approaches, dbShortDate(approach.valuation_date));
    fetchGPCCompListsByDate(tickerListsByDate, format.currency.code, financialsPeriods);
  }, [
    approach,
    approaches,
    isLoading,
    getTickerListsByDate,
    fetchGPCCompListsByDate,
    financialsPeriods,
    format.currency.code,
  ]);

  return (
    <div>
      {isLoading ? (
        <LinearProgress color="secondary" />
      ) : (
        <LinearProgress color="secondary" variant="determinate" value={100} />
      )}
      <MenuItem onClick={refreshGPCApproach}>
        <div className={classes.menuItemRow}>
          <div>
            <Typography color="secondary" variant="h4">
              {isLoading ? 'REFRESHING' : 'REFRESH DATA'}
            </Typography>
            <Typography className={classes.refreshDateLabel} color="secondary" variant="caption">
              LAST REFRESH DATE: {formatDate({ date: gpcApproach.last_refresh_date || new Date().toUTCString() })}
            </Typography>
          </div>
          <div className={classes.refreshIconContainer}>
            {isLoading ? (
              <MoreHorizIcon color="secondary" fontSize="small" />
            ) : (
              <RefreshIcon color="secondary" fontSize="small" />
            )}
          </div>
        </div>
      </MenuItem>
    </div>
  );
};

RefreshGPCOption.propTypes = {
  approach: PropTypes.object,
  approaches: PropTypes.array,
  setApproaches: PropTypes.func,
  closeApproachMenu: PropTypes.func,
};

export default RefreshGPCOption;
