import { isEmpty } from 'lodash';
import { addClassName, removeClassName } from 'utilities';

export const updateTooltipMessage = ({ message, key, container, currentCell, addMessage }) => {
  if (currentCell.sheet) {
    const column = container[key];
    const existingMessages = column.tooltipMessages ?? [];

    if (addMessage) {
      column.className = addClassName(currentCell.className, 'error');
      if (!existingMessages.includes(message)) {
        existingMessages.push(message);
      }
      column.isValid = false;
      column.tooltipMessages = existingMessages;
    } else {
      const messages = existingMessages.filter(m => m !== message);
      if (isEmpty(messages)) {
        column.className = removeClassName(currentCell.className, 'error');
        column.tooltipMessages = [];
        column.isValid = true;
      } else {
        column.className = addClassName(currentCell.className, 'error');
        column.tooltipMessages = messages;
        column.isValid = false;
      }
    }
  }
};
