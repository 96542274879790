/**
 * Note Component
 *
 * @component
 * @description This component represents a note and provides an interface to view, edit, and delete it.
 * It displays the note's content, author, creation date, and provides actions to edit or delete the note.
 *
 * @param {NoteItemProps} props - The properties and event handlers required by the component.
 * @returns {JSX.Element} - A JSX element representing the Note component.
 */

import React, { ChangeEvent, FC, MouseEvent, useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowDropDown, Delete, Edit } from '@material-ui/icons';
import useNotesContext from 'context/NotesContext';
import { dbLongDate, LONG_DATE_FORMAT, stopEventPropagation, truncateText } from 'utilities';
import NoteEdit from './NoteEdit';
import useStyles from '../styles';
import { NoteData, NoteItemProps } from '../types';

const MAX_TITLE_LENGTH = 40;

const Note: FC<NoteItemProps> = props => {
  const classes = useStyles();
  const { note, isDisabled } = props;
  const { onUpdateNotes, onDeleteNote, onDeleteNewNote } = useNotesContext();
  const { content, author, created_at: createdAt, updated_by: updatedBy, updated_at: updatedAt, isNew } = note;
  const [isEditing, setIsEditing] = useState<boolean>(isNew ?? false);
  const [open, setOpen] = useState<boolean>(isNew ?? false);
  const [noteContent, setNoteContent] = useState<string>(content);
  const creator = author ? `${author?.first_name} ${author?.last_name}` : '-';
  const editor = updatedBy ? `${updatedBy?.first_name} ${updatedBy?.last_name}` : '-';
  const noteId = note.id ?? note.temp_id;

  /**
   * @function
   * @name handleOpenAccordion
   * @description Handles the opening of an accordion or a collapsible element.
   * @param {Event} event - The event that triggered the function.
   * @param {boolean} expandedState - A boolean value indicating whether the accordion should be expanded (true) or collapsed (false).
   * @return {void}
   */
  const handleOpenAccordion = useCallback(
    (event, expandedState) => {
      stopEventPropagation(event);
      setOpen(expandedState);
    },
    [setOpen]
  );

  /**
   * @function
   * @name handleOnEnableNoteEdition
   * @description Enables the editing mode for a note.
   * @param {MouseEvent} event - The mouse event that triggered the function.
   * @return {void}
   */
  const handleOnEnableNoteEdition = (event: MouseEvent<HTMLButtonElement>) => {
    handleOpenAccordion(event, true);
    setIsEditing(true);
  };

  /**
   * @function
   * @name handleOnClickDeleteNote
   * @description Handles the deletion of a note.
   * @param {MouseEvent} event - The mouse event that triggered the function.
   * @return {void}
   */
  const handleOnClickDeleteNote = (event: MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);
    if (noteId) {
      const noteData: NoteData = {
        ...note,
        is_deleted: true,
      };
      onDeleteNote(noteData);
    }
  };

  /**
   * @function
   * @name handleOnSaveSingleNote
   * @description Saves changes made to a note.
   * @param {MouseEvent} event - The mouse event that triggered the function.
   * @return {void}
   */
  const handleOnSaveSingleNote = (event: MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);
    setIsEditing(false);
    const noteData: NoteData = {
      ...note,
      isNew: false,
      content: noteContent,
    };
    onUpdateNotes(noteData);
  };

  /**
   * @function
   * @name handleOnCancelEditNote
   * @description Cancels the editing mode for a note.
   * @param {MouseEvent} event - The mouse event that triggered the function.
   * @return {void}
   */
  const handleOnCancelEditNote = (event: MouseEvent<HTMLButtonElement>) => {
    stopEventPropagation(event);
    setIsEditing(false);
    if (note.isNew && note.content === '') {
      const noteData: NoteData = {
        ...note,
        is_deleted: true,
      };
      onDeleteNewNote(noteData);
    }
  };

  /**
   * @function
   * @name handleOnChangeContentNote
   * @description Handles changes in the content of a note.
   * @param {ChangeEvent} event - The change event that occurred when the content of the note input field changed.
   * @return {void}
   */
  const handleOnChangeContentNote = (event: ChangeEvent<HTMLInputElement>) => {
    setNoteContent(event.target.value);
  };

  return (
    <Accordion
      className={classes.note}
      expanded={open}
      onChange={(event, expanded) => handleOpenAccordion(event, expanded)}>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="caption" className={classes.noteHeaderText}>
                  {truncateText(noteContent ?? content, MAX_TITLE_LENGTH)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton size="small" color="default" onClick={handleOnEnableNoteEdition} disabled={isDisabled}>
              <Edit className={classes.actionIcons} />
            </IconButton>
            <IconButton size="small" color="default" onClick={handleOnClickDeleteNote} disabled={isDisabled}>
              <Delete className={classes.actionIcons} />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.noteDetails}>
        {isEditing ? (
          <NoteEdit
            noteContent={noteContent}
            isDisabled={isDisabled}
            handleOnChangeContentNote={handleOnChangeContentNote}
            handleOnCancelEditNote={handleOnCancelEditNote}
            handleOnSaveSingleNote={handleOnSaveSingleNote}
          />
        ) : (
          <Typography className={classes.noteDetailContent}>{content}</Typography>
        )}
      </AccordionDetails>
      <AccordionDetails className={classes.noteFooter}>
        {author && !updatedBy && (
          <Typography variant="caption" color="textSecondary">
            Created by {creator} at {dbLongDate(createdAt, LONG_DATE_FORMAT)}
          </Typography>
        )}
        {updatedBy && (
          <Typography variant="caption" color="textSecondary">
            Updated by {editor} at {dbLongDate(updatedAt, LONG_DATE_FORMAT)}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Note;
