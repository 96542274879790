import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Cell } from 'common/types/scalarSpreadsheet';
import { LedgerTable } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import { useSaveEffectiveTaxRate } from 'services/hooks/valuation';
import { getKey, getNumberValue } from 'utilities';
import { colConfig, getRowConfig, parser } from './config';
import { EFFECTIVE_TAX_RATE_ALIAS, MEASUREMENT_DATE_ALIAS } from './config/rowConfig';
import DialogHeader from './DialogHeader';
import { AuxRowsProps, EffectiveTaxRateLedgerProps, TCells } from './types';

const EffectiveTaxRateLedger: FC<EffectiveTaxRateLedgerProps> = props => {
  const { cashTaxRate, measurementDate, setCashTaxRate, closeDialog, companyMeasurementDate } = props;
  const tableId = 'effective-tax-rate-table';
  const [rowConfig] = useState(getRowConfig());
  const [cells, setCells] = useState<TCells>({});
  const [effectiveTaxRateHasInvalidCells, setEffectiveTaxRateHasInvalidCells] = useState(false);
  const [rows, setRows] = useState<AuxRowsProps[]>();
  const [isValidatingTables, setIsValidatingTables] = useState(false);
  const [doFullValidation, setDoFullValidation] = useState(false);
  const [effectiveTaxRateInfo, setEffectiveTaxRateInfo] = useState<string | number | boolean | JSX.Element | null>();
  const tableTerms = {
    tableSlug: 'effective-tax-rate-table',
  };
  const [saveEffectiveTaxRate] = useSaveEffectiveTaxRate();
  const updateValidationStatus = (validationStatus: boolean) => {
    setIsValidatingTables(isValidatingTables || validationStatus);
  };
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const cashTaxRateCell = Object.values(cells).find(
      (cell: Cell) => cell.alias === EFFECTIVE_TAX_RATE_ALIAS && cell.colAlias === 'value'
    );
    setEffectiveTaxRateInfo(cashTaxRateCell?.value ?? 0);
    setEffectiveTaxRateHasInvalidCells(Object.values(cells).some(cell => cell.isValid === false));
  }, [cells]);

  const handleOnSave = useCallback(async () => {
    const cashTaxRateCell = Object.values(cells).find(
      cell => cell.colAlias === 'value' && cell.rowAlias === EFFECTIVE_TAX_RATE_ALIAS
    );

    const cashTaxRateValue = getNumberValue(Number(cashTaxRateCell?.value));
    const auxCashTaxRate = {
      id: cashTaxRate?.id,
      company_measurement_date: cashTaxRate?.company_measurement_date?.id ?? companyMeasurementDate?.id,
      effective_tax_rate: cashTaxRateValue.toFixed(3),
    };

    const auxCompanyMeasurementDate = cashTaxRate?.company_measurement_date;
    const savedCashTaxRate = await saveEffectiveTaxRate(auxCashTaxRate);

    if (!savedCashTaxRate.error) {
      setCashTaxRate({
        ...savedCashTaxRate,
        company_measurement_date: auxCompanyMeasurementDate,
      });
      closeDialog();
    }
  }, [cashTaxRate, cells, closeDialog, companyMeasurementDate, saveEffectiveTaxRate, setCashTaxRate]);

  const dataRows: AuxRowsProps[] = useMemo(
    () => [
      { alias: MEASUREMENT_DATE_ALIAS, value: measurementDate?.date },
      { alias: EFFECTIVE_TAX_RATE_ALIAS, value: cashTaxRate?.effective_tax_rate ?? 0 },
    ],
    [measurementDate, cashTaxRate]
  );

  const applyParser = useCallback(
    async dataRows => {
      const auxCells = await parser({ initialObject: dataRows, rowConfig, columns: colConfig, tableId });
      setCells(auxCells);
    },
    [rowConfig]
  );

  useEffect(() => {
    setRows(dataRows);
    applyParser(dataRows);
  }, [cashTaxRate, applyParser, dataRows]);

  useEffect(() => {
    if (!isValidatingTables && doFullValidation && isValid) {
      handleOnSave();
      setDoFullValidation(false);
    }
  }, [isValidatingTables, doFullValidation, handleOnSave, isValid]);

  return (
    <LedgerDialog
      id="current-effective-rate-tax-ledger"
      onSave={() => setDoFullValidation(true)}
      onClose={closeDialog}
      dialogHeader={<DialogHeader effectiveTaxRateInfo={effectiveTaxRateInfo} />}
      title=""
      disableSaveButton={effectiveTaxRateHasInvalidCells}>
      <LedgerTable
        doFullValidation={doFullValidation}
        updateValidationStatus={updateValidationStatus}
        hasRowTitles={false}
        showAddRowButton={false}
        showDeleteColumn={false}
        showTotalRow={false}
        getKey={getKey}
        tableId={tableId}
        colConfig={colConfig}
        rowConfig={rowConfig}
        rows={rows}
        tableTerms={tableTerms}
        cells={cells}
        setCells={setCells}
        setIsValid={setIsValid}
      />
    </LedgerDialog>
  );
};

export default EffectiveTaxRateLedger;
