import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  COMPANY_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE,
  COMPANY_SUMMARY_BY_MD_QUERY_KEY,
} from 'common/constants/services/companies';
import { CompaniesService } from 'services';
import { throwError } from 'utilities';
import {
  GetCompanySummaryByMDHook,
  GetCompanySummaryByMDParams,
  GetCompanySummaryByMDResponse,
  UseGetCompanySummaryByMD,
} from './types';

const getCompanySummaryByMD = async (params: GetCompanySummaryByMDParams) => {
  const { companyId, measurementDateId } = params;

  if (isNil(companyId) && isNil(measurementDateId)) return null;

  const companiesService = new CompaniesService();

  try {
    const companySummary = (await companiesService.getCompanySummary(
      companyId,
      measurementDateId
    )) as GetCompanySummaryByMDResponse;

    if (!companySummary) return throwError(COMPANY_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE);

    return companySummary;
  } catch (error) {
    return throwError(COMPANY_SUMMARY_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetCompanySummaryByMD: UseGetCompanySummaryByMD = params => {
  const { company, measurementDate, shouldQueryAutomatically = true } = params;

  const companyId = company?.id;
  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetCompanySummaryByMDHook['data'], Error>({
    enabled: !isNil(companyId) && !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getCompanySummaryByMD({ companyId, measurementDateId }),
    queryKey: [COMPANY_SUMMARY_BY_MD_QUERY_KEY, companyId, measurementDateId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetCompanySummaryByMD;
