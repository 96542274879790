import React, { ChangeEvent, FC, MouseEvent, useEffect } from 'react';
import { FormControl, Grid, InputLabel, ListItem, ListItemText, MenuItem, Select } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { FUNDS_MEASUREMENT_DATE, FUNDS_NO_MEASUREMENT_DATE } from 'common/constants/funds';
import { CustomCalendarIcon } from 'pages/Funds/components/CustomCalendarIcon';
import { useGetMeasurementDatesByFund } from 'services/hooks/funds';
import { formatDate, getObjectValue, getStringValue, stopEventPropagation } from 'utilities';
import useStyles from './styles';
import { FundMeasurementDateSelectorProps } from './types';

const FUND_MEASUREMENT_DATE_SELECTOR = 'fund-measurement-date-selector';

const FundMeasurementDateSelector: FC<FundMeasurementDateSelectorProps> = props => {
  const { fund, selectedMeasurementDate, setSelectedMeasurementDate } = props;
  const { slug } = getObjectValue(fund);

  const classes = useStyles();

  const { data: measurementDates, isLoading } = useGetMeasurementDatesByFund({ fund });

  // Select the first Measurement Date by default
  useEffect(() => {
    if (!isEmpty(measurementDates) && measurementDates?.length) setSelectedMeasurementDate(measurementDates[0]);
  }, [measurementDates, setSelectedMeasurementDate]);

  // Loading Measurement Dates
  if (isLoading) {
    return (
      <Grid className={clsx(classes.formContainer, 'skeleton')}>
        <Skeleton variant="rect" className={classes.skeleton} />
      </Grid>
    );
  }

  const onMeasurementDateChange = (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    // Prevent expanding/unexpanding the FundSummaryAccordion
    stopEventPropagation(event as unknown as MouseEvent<HTMLDivElement>);

    const { value } = event.target;

    const selectedMD = measurementDates?.find(measurementDate => measurementDate?.id?.toString() === value);

    if (selectedMD) setSelectedMeasurementDate(selectedMD);
  };

  return (
    <FormControl
      className={clsx(classes.formContainer, { 'no-measurement-date': isNil(selectedMeasurementDate?.id) })}
      size="small"
      variant="outlined">
      {isNil(selectedMeasurementDate?.id) && (
        <InputLabel id={`${slug}-${FUND_MEASUREMENT_DATE_SELECTOR}-label`}>{FUNDS_NO_MEASUREMENT_DATE}</InputLabel>
      )}
      <Select
        className={classes.select}
        IconComponent={CustomCalendarIcon}
        id={`${slug}-${FUND_MEASUREMENT_DATE_SELECTOR}`}
        labelId={`${slug}-${FUND_MEASUREMENT_DATE_SELECTOR}-label`}
        onChange={onMeasurementDateChange}
        onClick={stopEventPropagation} // Prevent expanding/unexpanding the FundSummaryAccordion
        value={getStringValue(selectedMeasurementDate?.id?.toString())}>
        {measurementDates?.map(measurementDate => (
          <MenuItem
            key={getStringValue(measurementDate?.id?.toString())}
            value={getStringValue(measurementDate?.id?.toString())}>
            <ListItem className={classes.listItem} component="div">
              <ListItemText className={classes.listItemText} primary={FUNDS_MEASUREMENT_DATE} />
              {measurementDate?.date && (
                <ListItemText
                  className={clsx(classes.listItemText, 'date')}
                  primary={formatDate({ date: measurementDate.date })}
                />
              )}
            </ListItem>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FundMeasurementDateSelector;
