import { isNil } from 'lodash';
import * as validationMessages from 'common/constants/messages/validations';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS } from 'common/constants/valuations';
import { SELECTION } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { LAST_MIDDLE_COLUMN_INDEX } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/constants';
import { parseFloatToFixed } from 'utilities/parseFloatToFixed';
import { getTotalPremiumDiscount } from '../../utils/utilities';

const validations = ({ cell, addFeedback, removeFeedback, tableData }) => {
  if (cell.alias === SELECTION && cell.y > LAST_MIDDLE_COLUMN_INDEX) {
    // if cell value is '0' then addFeedback(validationMessages.IS_REQUIRED_ERROR);

    if (isNil(cell.value)) {
      addFeedback(validationMessages.IS_REQUIRED_ERROR);
    } else {
      removeFeedback(validationMessages.IS_REQUIRED_ERROR);
    }
  }

  const isMultiplePremiumDiscountApplicable
    = cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS
    && cell.y > LAST_MIDDLE_COLUMN_INDEX
    && tableData?.approach?.valuations_approach_gpc?.use_multiple_premium_discount
    && tableData?.approach?.valuations_approach_gpc?.multiple_premium_discount;

  if (isMultiplePremiumDiscountApplicable) {
    const multiplePremiumDiscount = tableData?.approach?.valuations_approach_gpc?.multiple_premium_discount;
    const validValues = [
      parseFloatToFixed(0, 2),
      parseFloatToFixed(getTotalPremiumDiscount(multiplePremiumDiscount), 2),
    ];
    if (!validValues.includes(parseFloatToFixed(cell.value, 2))) {
      addFeedback(validationMessages.IS_REQUIRED_ERROR);
    } else {
      removeFeedback(validationMessages.IS_REQUIRED_ERROR);
    }
  }
};

export default validations;
