/* eslint-disable no-param-reassign */
import { removeClassName } from 'utilities';

const getOutdatedCellsChanges = ({ cell, rowConfig, cells }) => {
  const newChanges = [];

  rowConfig.forEach((_row, index) => {
    const { columnLegend } = cell;
    const rowNumber = index + 1;
    const key = columnLegend + rowNumber;

    if (cells[key]) {
      const newCell = Object.assign(cells[key], {
        ...cells[key],
        className: removeClassName(cells[key].className, 'warning'),
        needs_actualization: false,
        action: null,
        extraActions: null,
      });

      newChanges.push({
        cell: newCell,
        value: cells[key].value,
      });
    }
  });

  return newChanges;
};

export default getOutdatedCellsChanges;
