/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, List, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useStayScrolled from 'react-stay-scrolled';
import { authAction, firmsAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { firmSummaryUrl } from 'common/config/urls';
import { FUND_PERMISSION_NAME } from 'common/constants/sidebar';
import { COMPANY_NAME_TITLE } from 'common/constants/summary';
import { useStore } from 'common/store';
import { HiddenContentChip } from 'components';
import { dashboardCompanyOverviewUrl } from 'dashboard409a/common/config/urls';
import { FirmStepper } from 'layouts/Main/components';
import { TermsAgreed } from 'pages/SignUp/components';
import { useGetCompanyInfoById } from 'services/hooks/company';
import { useGetFundById } from 'services/hooks/fund';
import { useUpdateUserProfile } from 'services/hooks/useUserSelected';
import { dbShortDate, doesNotExistValue, isFalseStrict } from 'utilities';
import CompanySelector from './components/CompanySelector';
import FirmList from './components/FirmList';
import ListHeader from './components/ListHeader';
import LoadingPaper from './components/LoadingPaper';
import Otp from './components/OTP';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    maxHeight: '100vh',
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
  },
  firmList: {
    overflow: 'hidden',
    overflowY: 'auto',
    marginLeft: `-${theme.spacing(1)}px`,
    marginRight: `-${theme.spacing(1)}px`,
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 150px);',
  },
  listItem: {
    borderRadius: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  avatar: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1.5),
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.white,
  },
  'hiddenContentTab-bottom': {
    border: '1px solid red !important',
  },
  'hiddenContentTab-top': {
    border: '1px solid green !important',
  },
  loadingText: {
    marginTop: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  logoutBtn: {
    textAlign: 'right',
    marginTop: '1.5em',
    '& button': {
      fontSize: '0.875rem',
    },
  },
}));

const FILTER_FIRM_NUMBER = 9;

const OrganizationSelector = ({ lowerPermissions, isDashboard = false }) => {
  const { mutate: updateUserProfile } = useUpdateUserProfile();

  const classes = useStyles();

  const divRef = useRef(null);

  const history = useHistory();
  const getCompanyInfo = useGetCompanyInfoById();
  const [, getFundInfo] = useGetFundById();
  const [{ firmList, user }, dispatch] = useStore();
  const [search, setSearch] = useState('');
  const [hiddenBottom, setHiddenBottom] = useState(0);
  const [openStepper, setOpenStepper] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status2fa, setStatus2fa] = useState({ requires_2fa: null, user_has_2fa: null });
  const [otpVerified, setOtpVerified] = useState(false);
  const [needsToAcceptTerms, setNeedsToAcceptTerms] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const inputFilterRef = useRef();

  useStayScrolled(divRef);

  const hasNotFirmPermissions = useMemo(
    () => firmList?.every(firm => firm.user_permissions.length === 0) && user && !user.is_superuser,
    [firmList]
  );

  // Check if user needs to accept the terms and permissions (only for new users)
  useEffect(() => {
    if (user) {
      setNeedsToAcceptTerms(!user?.profile?.terms_last_agreed);
    }
  }, [user]);

  const handleAcceptTerms = useCallback(() => {
    const now = dbShortDate(new Date(), 'YYYY-MM-DD HH:mm:ss');
    updateUserProfile(
      {
        userId: user.id,
        updatedUserProfile: {
          username: user.username,
          email: user.email,
          profile: {
            terms_last_agreed: now,
          },
        },
      },
      {
        onSuccess: () => {
          setNeedsToAcceptTerms(false);
          dispatch(
            authAction.setUserInfo({
              ...user,
              profile: {
                ...user.profile,
                terms_last_agreed: now,
              },
            })
          );
        },
      }
    );
  }, [user, updateUserProfile]);

  const addFirmHandler = useCallback(() => setOpenStepper(true), [setOpenStepper]);
  const onFirmStepperClose = useCallback(() => setOpenStepper(false), [setOpenStepper]);

  const getHiddenFirms = () => {
    const firmListContainer = document.querySelector('#firm-list');
    const firmListItems = document.querySelectorAll('#firm-list > a');

    let tmpHiddenBottom = 0;

    firmListItems.forEach(firm => {
      if (firm.offsetTop >= firmListContainer.offsetHeight + firmListContainer.scrollTop) {
        tmpHiddenBottom += 1;
      }
    });

    if (hiddenBottom !== tmpHiddenBottom) setHiddenBottom(tmpHiddenBottom);

    setHiddenBottom(tmpHiddenBottom);
  };

  const handleLowerPermissions = () => {
    let featureObject;
    let objectPath;
    /* for restricted user, the only scenario where it has multiple permissions is when it has
    permissions for a single fund, and the companies in which the fund invested */
    const fundPermission = lowerPermissions?.find(
      ({ feature_object }) => feature_object.object_type === FUND_PERMISSION_NAME
    );

    if (lowerPermissions.length > 1 && fundPermission) {
      objectPath = fundPermission.feature_object_path;
      featureObject = fundPermission.feature_object;
    } else {
      featureObject = lowerPermissions[0].feature_object;
      objectPath = lowerPermissions[0].feature_object_path;
    }
    /* need to set some global state in store to say this user is restricted to only a company
    and then in next component use that global state to call getActiveCompanyExtendedInfo */
    dispatch(authAction.setIsRestrictedUser(true));
    // some store info needs to be set to have working containers
    if (featureObject.object_type === COMPANY_NAME_TITLE) {
      getCompanyInfo(featureObject.object_id);
    } else {
      getFundInfo(featureObject.object_id);
    }
    return history.push(`/${objectPath}`);
  };

  const fetchFirmList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { firms, requires_2fa, user_has_2fa } = await firmsAction.getFirmList();
      dispatch(firmsAction.setFirmList(firms));
      setStatus2fa({ requires_2fa, user_has_2fa });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(firmList)) {
      fetchFirmList();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputFilterRef.current) {
        inputFilterRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputFilterRef.current]);

  useEffect(() => {
    if (!isDashboard && !isEmpty(firmList) && firmList !== ERROR_403 && !hasNotFirmPermissions) {
      // Only redirect if the user has not enabled 2FA and if the firm does not require 2FA
      if (
        firmList.length === 1
        && !needsToAcceptTerms
        && history
        && isFalseStrict(status2fa.requires_2fa)
        && isFalseStrict(status2fa.user_has_2fa)
      ) {
        return history.push(firmSummaryUrl(firmList[0].slug));
      }
    }
    // Redirect to 409A Dashboard company
    else if (user?.standalone_companies_permissions) {
      if (
        user.standalone_companies_permissions.length === 1
        && history
        && isFalseStrict(status2fa.requires_2fa)
        && isFalseStrict(status2fa.user_has_2fa)
      ) {
        return history.push(dashboardCompanyOverviewUrl(user.standalone_companies_permissions[0].slug));
      }
    } else if (hasNotFirmPermissions && lowerPermissions.length) {
      handleLowerPermissions();
    }

    getHiddenFirms();
  }, [firmList, isLoading, lowerPermissions]);

  if (
    (isDashboard || isEmpty(firmList))
    && !isLoading
    && user
    && user?.standalone_companies_permissions
    && user?.standalone_companies_permissions.length > 0
  ) {
    return <CompanySelector />;
  }

  if (user && !doesNotExistValue(status2fa.user_has_2fa) && !otpVerified) {
    return <Otp userHas2FA={status2fa.user_has_2fa} verifyOtp={setOtpVerified} />;
  }

  const onChange = event => {
    const {
      target: { value },
    } = event;
    if (!value) {
      setSearch('');
    }
    setSearch(value);
  };

  return (
    <>
      {isLoading ? (
        <>
          <LoadingPaper message="Loading..." />
          <div ref={divRef} />
        </>
      ) : (
        <>
          {user && !otpVerified && (
            <Otp userHas2FA={status2fa.user_has_2fa || user?.has_2fa} verifyOtp={setOtpVerified} />
          )}
          {needsToAcceptTerms && otpVerified && (
            <>
              <TermsAgreed termsAgreed={acceptTerms} setTermsAgreed={setAcceptTerms} />
              <Button onClick={handleAcceptTerms} variant="contained" color="primary" disabled={!acceptTerms}>
                Accept Terms
              </Button>
            </>
          )}
          {!needsToAcceptTerms && otpVerified && (
            <Paper className={classes.root}>
              <ListHeader
                onInputChange={onChange}
                searchState={search}
                firmList={firmList}
                inputFilterRef={inputFilterRef}
                listLimit={FILTER_FIRM_NUMBER}
              />
              <List id="firm-list" component="nav" ref={divRef} className={classes.firmList} onScroll={getHiddenFirms}>
                <FirmList firmList={firmList} addFirmHandler={addFirmHandler} searchState={search} user={user} />
                {openStepper && <FirmStepper isVisible={openStepper} onClose={onFirmStepperClose} />}
              </List>
              <HiddenContentChip
                id="hidden-firms"
                isVisible={hiddenBottom > 0}
                label={`${hiddenBottom} firms`}
                position="bottom"
              />
            </Paper>
          )}
        </>
      )}
    </>
  );
};

OrganizationSelector.propTypes = {
  lowerPermissions: PropTypes.array,
  isDashboard: PropTypes.bool,
};

export default OrganizationSelector;
