/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-case-declarations */
import { isEmpty, isUndefined, mapValues } from 'lodash';
import { all, create } from 'mathjs';
import { FULLY_DILUTED_OWNERSHIP_ROW_NUMBER } from 'common/constants/fund-ownership';
import { alphabetGenerator } from 'utilities/alphabet-utilities';

const totalParser = ({ cells, columns, rows }) => {
  let totalsRow = [];
  const alphabet = alphabetGenerator([], columns.length);
  const math = create(all);

  const scope = mapValues(cells, cell => {
    // eslint-disable-next-line no-restricted-globals
    if (isUndefined(cell) || isNaN(cell.value)) return 0;
    return parseFloat(Number(cell.value));
  });

  const sumExpr = rowIndexAux =>
    Object.keys(columns)
      .map(columnIndex => alphabet[columnIndex] + rowIndexAux)
      .filter(key => cells[key]);

  rows.forEach(({ alias, hasTotal, format }, rowIndex) => {
    const columnLegend = alphabet[0];
    const rowIndexAux = rowIndex + 1;
    const keyActual = `${columnLegend + rowIndexAux}`;
    const key = `TOTAL_${columnLegend + rowIndexAux}`;

    const colFormat = format?.style;
    const gridFormat = colFormat && 'number';

    const fractionDigits = rowIndexAux === FULLY_DILUTED_OWNERSHIP_ROW_NUMBER ? 1 : 0;

    const cell = {
      ...rows[rowIndex],
      key: keyActual,
      isTotal: true,
      readOnly: true,
      alias,
      dataSourceKey: '',
      gridType: gridFormat,
      className: [rows[rowIndex].className, 'total-cell'].join(' '),
      format: {
        style: colFormat,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        isDecimal: true,
      },
      expr: rowIndex === 0 ? 'Total' : undefined,
      linkedCellSymbols: undefined,
    };

    if (hasTotal) {
      cell.expr = `=sum(${sumExpr(rowIndexAux)})`;
    }

    if (cell.expr && !isEmpty(scope) && rowIndex !== 0) {
      cell.value = math.evaluate(cell.expr.substring(1), scope) || 0;
    } else {
      cell.value = null;
    }

    totalsRow = {
      ...totalsRow,
      [key]: cell,
    };
  });
  return totalsRow;
};

export default totalParser;
