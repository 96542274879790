export const PENDING = 'pending';
export const COMPLETE = 'complete';
export const FAILED = 'failed';
export const INITIAL = 'initial';
export const TIMEOUT = 'timeout';
export const POLLING = 'polling';

export type PromiseState = 'pending' | 'complete' | 'failed' | 'initial' | 'timeout' | 'polling';

export interface PromiseResult<T, J> {
  promise: Promise<void>;
  state: PromiseState;
  key: number;
  result?: T;
  numRetries?: number;
  promiseParams: J;
}
export interface MultiPromiseMap<T, J> {
  [key: number]: PromiseResult<T, J>;
}
