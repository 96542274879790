import {
  EBITDA_APPROACH_SELECTION,
  HIGH_GROWTH_RATE,
  LONG_TERM_GROWTH_RATE,
  REVENUE_APPROACH_SELECTION,
  WACC,
} from 'pages/Valuations/util/constants';
import { GetColumnsProps } from './types';

const getColumns = (props: GetColumnsProps) => {
  const { valuationApproachDCF, dCFFinancialPeriod, waccData } = props;
  const wacc = waccData?.wacc ?? 0;
  const long_term_growth_rate = valuationApproachDCF[LONG_TERM_GROWTH_RATE] ?? 0;
  const high_growth_rate = valuationApproachDCF[HIGH_GROWTH_RATE] ?? 0;
  const column = {
    ...valuationApproachDCF,
    ...dCFFinancialPeriod,
    [WACC]: Number(wacc) * 100,
    [LONG_TERM_GROWTH_RATE]: long_term_growth_rate,
    [HIGH_GROWTH_RATE]: high_growth_rate,
    [REVENUE_APPROACH_SELECTION]: valuationApproachDCF[REVENUE_APPROACH_SELECTION],
    [EBITDA_APPROACH_SELECTION]: valuationApproachDCF[EBITDA_APPROACH_SELECTION],
  };
  return [column];
};

export default getColumns;
