import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type CircularProgressBarProps = {
  children: ReactNode;
};

const CircularProgressBar = (props: CircularProgressBarProps) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <br />
      {children || <p>Loading...</p>}
    </div>
  );
};

export default CircularProgressBar;
