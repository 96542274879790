/* eslint-disable no-param-reassign */
import { sortBy } from 'lodash';
import { ValuationApproachDCF } from 'api';
import { VALUATIONS_OTHER_LABEL } from 'common/constants/valuations';
import { CellData } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import {
  APPROACH_SELECTIONS_ALIASES,
  EBITDA_GPC_MULTIPLE,
  MULTIPLE_ALIASES,
  REVENUE_GPC_MULTIPLE,
  TERMINAL_VALUE_OPTIONS,
} from 'pages/Valuations/util/constants';
import { DCF_FINANCIAL_PERIOD_TABLE_NAME } from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/constants';
import { getSelectionCellOptions, publicCompsOrTransactionsExists } from 'pages/ValuationsAllocation/util';
import { getObjectValue, getValueByPropOrFallback, parseDatabaseValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { GenericColumn } from 'utilities/handleDisabledCells/types';
import swapMap from 'utilities/swapMap';
import getCurrentSelectedApproach from './getCurrentSelectedApproach';
import { ApproachType, getSelectedApproachAliases } from './getSelectedApproachAliases';
import getSelectionFromTVT from './getSelectionFromTVT';
import obtainMapForSelectMultipleAlias from './obtainMapForSelectMultipleAlias';
import { ReverseParserProps } from './types';
import updateApproachesSelectionsInTableData from './updateApproachesSelectionsInTable';
import { CustomValuationApproachDCF } from '../../utilities/types';

const reverseParser = ({
  cells,
  columns,
  allowEmptyValues,
  rowConfig,
  tableData,
  sheet,
  fieldAttributes,
  approachOptions,
}: ReverseParserProps) => {
  const sortedColumns = sortBy(columns, ['order']);
  const { approaches } = tableData;
  const alphabet = alphabetGenerator([], sortedColumns.length);
  const { dcfApproachAttrs } = getObjectValue(fieldAttributes);
  sortedColumns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];

    // replace updated values
    rowConfig.forEach((cell, cellIndex) => {
      const key = `${columnLegend}${cellIndex + 1}`;
      const defaultValue = getValueByPropOrFallback(cell, 'defaultValue', null);
      const type = getValueByPropOrFallback(cell, 'dbType', null);
      const format = getValueByPropOrFallback(cell, 'format', null);
      const value = cells[key] ? cells[key].value : defaultValue;
      const { gridType } = cell;
      const decimalPlaces
        = dcfApproachAttrs?.[cell.alias as keyof typeof dcfApproachAttrs]?.decimal_places ?? cell?.dbDecimalPlaces ?? 2;

      const calcValue = parseDatabaseValue({
        type,
        value,
        format,
        allowEmptyValues,
        gridType,
        decimalPlaces: decimalPlaces as number,
      }) as string | number;

      const selectedApproach = getCurrentSelectedApproach({
        approaches,
        approach: tableData.approach,
      }) as ValuationsApproach;

      const specificApproach = selectedApproach?.valuations_approach_gpc ?? selectedApproach?.valuations_approach_gpt;

      const multipleOptions = getSelectionCellOptions({
        isDCF: true,
        specificApproach,
      });

      // need to send multiple selection as label, not number
      const selection = getSelectionFromTVT({ cell, value, multipleOptions });

      if (cell.origin !== DCF_FINANCIAL_PERIOD_TABLE_NAME) {
        // this is to temporarily save the 'Other' multiple numeric value
        if (MULTIPLE_ALIASES.includes(cell.alias)) {
          const reversedMap = swapMap(obtainMapForSelectMultipleAlias());
          const selectMultipleAlias = reversedMap.get(cell.alias);
          const selectionStored = column[selectMultipleAlias] as string;
          if (tableData?.approach?.valuations_approach_dcf) {
            const key = cell.alias as keyof CustomValuationApproachDCF;
            (tableData.approach.valuations_approach_dcf[key] as string | number) = calcValue;
          }
          if (selectionStored === VALUATIONS_OTHER_LABEL || !publicCompsOrTransactionsExists(approaches ?? [])) {
            const terminalValue = tableData?.approach?.valuations_approach_dcf?.terminal_value;
            const isRevenue = terminalValue === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
            const otherMultipleValueKey = isRevenue ? REVENUE_GPC_MULTIPLE : EBITDA_GPC_MULTIPLE;
            if (tableData.approach.valuations_approach_dcf) {
              (tableData.approach.valuations_approach_dcf[otherMultipleValueKey] as string | number) = calcValue;
              // make sure the selection is 'Other'
              tableData.approach.valuations_approach_dcf[`${otherMultipleValueKey}_selection`] = VALUATIONS_OTHER_LABEL;
            }
          }
        } else if (APPROACH_SELECTIONS_ALIASES.includes(cell.alias)) {
          const selectedApproach = approachOptions.find(({ value: approachValue }) => approachValue === value);
          const updatedAlias = getSelectedApproachAliases(cell.alias as ApproachType, selectedApproach?.type);
          const selectedApproachValue
            = selectedApproach?.value === '0' || selectedApproach?.value === undefined ? null : selectedApproach?.value;
          if (selectedApproachValue) {
            updateApproachesSelectionsInTableData({
              tableData,
              valueFromSelector: selectedApproachValue,
              updatedAlias,
            });
          }
        } else if (tableData.approach.valuations_approach_dcf) {
          (tableData.approach.valuations_approach_dcf[cell.alias as keyof ValuationApproachDCF] as string | number)
            = selection ?? calcValue;
        }
      }

      columns.forEach(column => {
        column[cell.alias] = (selection ?? calcValue) as CellData;
      });

      (sheet.columns as GenericColumn[]) = columns;
    });
  });
};
export default reverseParser;
