/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { companiesAction, firmsAction, fundsAction, globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { DOCUMENTS_SLUG } from 'common/constants/documents';
import { FIRM_REQUESTS_SLUG } from 'common/constants/firms';
import { PERIOD_CHAR, PERIOD_CODE } from 'common/constants/general';
import { SCALAR_OTP_COOKIE, SIGN_IN_URL } from 'common/constants/login';
import { EXCEL_EXPORT } from 'common/constants/pageActions';
import { FIRM_TYPE } from 'common/constants/pageTypes';
import { forbiddenPath } from 'common/constants/paths';
import {
  AUDITOR_VALUE,
  EXPORT_VIEWER_VALUE,
  FIRM_SUMMARY,
  FULL_ACCESS_VIEWER_VALUE,
  VIEW_ONLY_NO_ACTIONS_ROLES_VALUES,
} from 'common/constants/user';
import { useStore } from 'common/store';
import { Alert, Filter, Header } from 'components';
import ExcelExportContext from 'context/ExcelExportContext';
import LayoutContext from 'context/LayoutContext';
import { FundDialogForm } from 'pages/Funds/forms';
import { FirmService } from 'services';
import { useHandleSingleCompanyUser } from 'services/hooks/company';
import { useCheckReportFile } from 'services/hooks/excelExport';
import { useGetCompanyListByFirmId, useGetFundListByFirmId } from 'services/hooks/firm';
import { useHandleSingleFundUser } from 'services/hooks/fund';
import { useGetLowerUserPermissions } from 'services/hooks/users';
import { useDialogFormsStore } from 'store';
import { HEADER_HEIGHT } from 'theme';
import { checkCookie, getCurrency, getExchangeRate, getNumberValue, isFalseStrict } from 'utilities';
import { FirmStepper, Footer, NewCompanyDialog, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    paddingLeft: '60px',
    [theme.breakpoints.up('sm')]: {},
  },
  transparent: {
    backgroundColor: 'transparent !important',
  },
  content: {
    padding: theme.spacing(2),
    marginTop: `${HEADER_HEIGHT}px`,
    // Set the footer at the bootom of the screen whe the content is not enough
    minHeight: 'calc(100vh - 250px)',
    backgroundColor: theme.palette.white,
  },
  hotkeyContainer: {
    height: '100%',
  },
}));

const Main = ({ children, ...props }) => {
  const classes = useStyles(props);
  const location = useLocation();
  const { firmSlugParam, companySlugParam, tableSlugParam } = useParams();
  const history = useHistory();

  const { getCompanies } = useGetCompanyListByFirmId();
  const { getFunds } = useGetFundListByFirmId();
  const [, getLowerUserPermissions] = useGetLowerUserPermissions();

  const {
    doReportPollCheck,
    reportRequestData,
    reportUUID,
    setDoReportPollCheck,
    setReportRequestData,
    setReportUUID,
    currentSelectedValues,
    setCurrentSelectedValues,
  } = useCheckReportFile();

  const [activeCompany, setActiveCompany] = useState({});
  const [pageTitle, setPageTitle] = useState();
  const [pageFilters, setPageFilters] = useState();
  const [navItems, setNavItems] = useState();
  const [pageActions, setPageActions] = useState();
  const [extraPageActions, setExtraPageActions] = useState();
  const [showPageForm, setShowPageForm] = useState(false);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [editMode, setEditMode] = useState(false);
  const [extendedCompanyInfo, setExtendedCompanyInfo] = useState();
  const [companyExchangeRate, setCompanyExchangeRate] = useState();
  const [financialExchangeRate, setFinancialExchangeRate] = useState();
  const [pageType, setPageType] = useState(props?.pageType);
  const [commonMeasurementDate, setCommonMeasurementDate] = useState();
  const [currentMeasurementDate, setCurrentMeasurementDate] = useState();
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openCapIqRefreshDialog, setOpenCapIqRefreshDialog] = useState(false);
  const [fundsByMeasurementDate, setFundsByMeasurementDate] = useState([]);

  const [
    {
      isFirmStepperVisible,
      firmList,
      companyList,
      firmId,
      openCompanyDialog,
      fundInfo,
      companyInfo,
      otherPermissions,
      user,
    },
    dispatch,
  ] = useStore();

  // Dialog Forms Store
  const displayFundDialogForm = useDialogFormsStore(state => state.displayFundDialogForm);
  const setDisplayFundDialogForm = useDialogFormsStore(state => state.setDisplayFundDialogForm);
  const setShouldUpdateFund = useDialogFormsStore(state => state.setShouldUpdateFund);

  const toggleExportDialog = () => {
    setOpenExportDialog(!openExportDialog);
  };

  const toggleRefreshCapitalIqDataDialog = useCallback(() => {
    setOpenCapIqRefreshDialog(v => !v);
  }, []);

  // 0. try to determine this first to see if all the other firm calls should be made
  useEffect(() => {
    if (user?.id && isUndefined(otherPermissions)) {
      getLowerUserPermissions(user.id);
    }
  }, [user, otherPermissions]);

  const isSinglePermissionUser = useMemo(() => {
    if (isUndefined(otherPermissions)) {
      return undefined;
    }
    return Array.isArray(otherPermissions) && otherPermissions.length === 1 && user?.is_superuser === false;
  }, [otherPermissions, user]);

  const shouldMakeFirmCalls = useMemo(
    () => location.pathname.includes(String(firmId)) && !isUndefined(isSinglePermissionUser) && !isSinglePermissionUser,
    [firmId, isSinglePermissionUser]
  );

  const startSingleCompanyHook = useMemo(() => {
    if (isSinglePermissionUser === false) {
      return null;
    }
    if (isSinglePermissionUser) {
      // there shouldn't be any fund permission to return true
      return !otherPermissions.some(({ feature_object }) => feature_object.object_type === 'Fund');
    }
    return null;
  }, [isSinglePermissionUser]);
  // will run if argument is true
  useHandleSingleCompanyUser(startSingleCompanyHook);
  // will run if argument is false
  useHandleSingleFundUser(startSingleCompanyHook);

  // Fund Dialog
  const isFundPageActive = useMemo(() => !isEmpty(fundInfo), [fundInfo]);

  const onCloseFundDialog = useCallback(() => {
    // Close Fund Dialog Form
    setDisplayFundDialogForm(false);
    setShouldUpdateFund(false);

    setShowPageForm(false);
  }, []);

  // 1. Identify active firm
  const activeFirm = useMemo(() => {
    if (firmList === ERROR_403) {
      history.push(forbiddenPath);
      return null;
    }
    if (isEmpty(firmList) || !firmSlugParam) {
      return null;
    }

    const currentActiveFirm = firmList.find(f => f.slug === firmSlugParam);

    if (currentActiveFirm) {
      return currentActiveFirm;
    }
    /*
      The firmList contains only the firms where the user has access, so if the user tries to
      access using a url with firm slug that doesn't exist on firmList, the user will be redirected
      to forbidden path. For the combination of firmSlugParam and tableSlugParam below, a superuser
      should get a valid path.
    */
    if (firmSlugParam === '0' && tableSlugParam === 'comp-groups') {
      return null;
    }
    history.push(forbiddenPath);
    return null;
  }, [firmSlugParam, firmList]);

  // 1. Identify active company
  useEffect(() => {
    if (!isEmpty(companyList) && companySlugParam) {
      const company = companyList.find(item => item.company_slug === companySlugParam);
      if (company) {
        setActiveCompany(company);
      }
    }
  }, [companyList, companySlugParam]);

  useEffect(() => {
    if (!isEmpty(activeFirm)) {
      dispatch(firmsAction.setFirmId(activeFirm.id));
      dispatch(firmsAction.setFirmInfo(activeFirm));
    }
  }, [activeFirm]);

  const togglePageForm = useCallback(() => {
    setShowPageForm(!showPageForm);
  }, [showPageForm]);

  const toggleMainForm = useCallback(async () => {
    if (isFundPageActive) {
      const updatedFund = await fundsAction.getFundById(fundInfo?.id);
      dispatch(fundsAction.setFundInfo(updatedFund));

      // Open Fund Dialog Form
      setShouldUpdateFund(isFundPageActive);
      setDisplayFundDialogForm(isFundPageActive);
    }

    if (!isEmpty(activeCompany)) {
      setEditMode(true);
      dispatch(globalAction.showCompanyDialog(!openCompanyDialog));
      setShowPageForm(!showPageForm);
    }
  }, [activeCompany, fundInfo]);

  const getActiveCompanyExtendedInfo = useCallback(async () => {
    const firmService = new FirmService();
    const response = await firmService.getFirmCompanyExtendedInfo(activeFirm.id, activeCompany.company_id);
    setExtendedCompanyInfo(response);
  }, [activeCompany]);

  useEffect(async () => {
    if (
      (activeFirm?.id && activeCompany?.company_id)
      || (activeFirm?.id && activeCompany?.company_id && !currentMeasurementDate)
    ) {
      await getActiveCompanyExtendedInfo();
    }
  }, [activeCompany, currentMeasurementDate]);

  const getCompanyExchangeRate = useCallback(async () => {
    if (!isEmpty(companyInfo)) {
      const financialsCurrency = getCurrency({
        companyInfo,
        page: 'financials',
      });
      const captableCurrency = getCurrency({
        companyInfo,
        page: 'captable',
      });
      const exchangeRate = await getExchangeRate(
        financialsCurrency,
        captableCurrency,
        getNumberValue(currentMeasurementDate?.id ?? companyInfo?.measurement_date_id)
      );

      setCompanyExchangeRate(exchangeRate);
      const tempFinancialExchangeRate = await getExchangeRate(
        captableCurrency,
        financialsCurrency,
        getNumberValue(currentMeasurementDate?.id ?? companyInfo?.measurement_date_id)
      );
      setFinancialExchangeRate(tempFinancialExchangeRate);
    }
  }, [companyInfo]);

  useEffect(async () => {
    if (companyInfo) {
      await getCompanyExchangeRate();
    }
  }, [companyInfo]);

  // 2. Get company info base on companySlugParam
  useEffect(() => {
    if (!isEmpty(activeCompany) && !isEmpty(extendedCompanyInfo)) {
      /*
        We pass the active company information to the hook
        to avoid setting the information in the global store twice.
        Remove properties to avoid duplicated properties in companyInfo
      */
      const requiredProperties = {
        id: activeCompany.company_id,
        name: activeCompany.company_name,
        slug: activeCompany.company_slug,
        ...extendedCompanyInfo,
      };
      delete requiredProperties.company_slug;
      delete requiredProperties.company_name;
      delete requiredProperties.company_id;
      dispatch(companiesAction.setCompanyInfo({ ...requiredProperties }));
    }
  }, [extendedCompanyInfo]);

  // GET list of firms
  useEffect(() => {
    async function fetchFirmList() {
      const { firms, field_attributes: fieldAttributes } = await firmsAction.getFirmList();
      dispatch(firmsAction.setFirmList(firms));
      dispatch(globalAction.setFieldAttrs(fieldAttributes));
    }
    fetchFirmList();
  }, [dispatch]);

  // GET firm information
  useEffect(() => {
    if (firmId && (shouldMakeFirmCalls || firmList?.length === 1)) {
      (async () => {
        const response = await firmsAction.getFirmById(firmId);
        if (response?.firmInfo) {
          dispatch(firmsAction.setFirmInfo(response.firmInfo));
        }
      })();
    }
  }, [firmId, shouldMakeFirmCalls]);

  // GET all the companies and funds in the current firm
  useEffect(() => {
    if (firmId && (shouldMakeFirmCalls || firmList?.length === 1)) {
      getFunds(firmId);
      getCompanies(firmId);
    }
  }, [firmId, shouldMakeFirmCalls]);

  // add event listener for hotkeys to save
  useEffect(() => {
    const handleKeyupForSave = event => {
      const key = event.key || event.code;
      const periodKeyHitted = [PERIOD_CODE, PERIOD_CHAR].includes(key);
      // most likely to work is ctrl + .
      const initiatingKey = event.ctrlKey || event.metaKey || event.altKey;
      if (initiatingKey && periodKeyHitted && pageActions?.mainAction) {
        pageActions.mainAction.callback();
      }
    };

    if (!isEmpty(pageActions) && pageActions?.mainAction.label === 'Save') {
      document.addEventListener('keyup', handleKeyupForSave);
    }
    return () => document.removeEventListener('keyup', handleKeyupForSave);
  }, [pageActions]);

  const currentFirmRole = useMemo(() => {
    if (firmId && user?.firms_permissions) {
      return user.firms_permissions.find(({ id }) => Number(id) === Number(firmId))?.user_firm_role;
    }
  }, [firmId, user]);

  const viewOnlyUser = useMemo(() => {
    if (currentFirmRole) {
      return VIEW_ONLY_NO_ACTIONS_ROLES_VALUES.includes(currentFirmRole);
    }
    return false;
  }, [currentFirmRole]);

  const displayMemoizedFilters = useMemo(() => {
    if (pageFilters) {
      const validPageFilters = pageFilters.filter(filterItem => filterItem.selectedValue);
      return validPageFilters.map(filter => (
        <Grid key={filter.id} item>
          <Filter
            {...filter}
            id={filter.id}
            label={filter.title}
            handleFilter={filter.handler}
            hideActions={viewOnlyUser}
          />
        </Grid>
      ));
    }
    return null;
  }, [pageFilters, viewOnlyUser]);

  const shouldDisplayGrayBg = useMemo(
    () =>
      (props?.pageType === FIRM_TYPE && location.pathname.includes(FIRM_SUMMARY))
      || location.pathname.includes(DOCUMENTS_SLUG)
      || location.pathname.includes(FIRM_REQUESTS_SLUG),
    [props?.pageType, location?.pathname]
  );

  const updatePageActions = useCallback(
    newActions => {
      if (viewOnlyUser) {
        setPageActions(null);
      } else {
        setPageActions(newActions);
      }
    },
    [viewOnlyUser, setPageActions]
  );

  const updateExtraPageActions = useCallback(
    extraActions => {
      if ([FULL_ACCESS_VIEWER_VALUE, EXPORT_VIEWER_VALUE, AUDITOR_VALUE].includes(currentFirmRole)) {
        setExtraPageActions(extraActions.filter(action => action.label === EXCEL_EXPORT));
      } else if (viewOnlyUser) {
        setExtraPageActions(null);
      } else {
        setExtraPageActions(extraActions);
      }
    },
    [currentFirmRole, viewOnlyUser, setExtraPageActions]
  );

  const excelExportContextValue = useMemo(
    () => ({
      doReportPollCheck,
      reportRequestData,
      reportUUID,
      setDoReportPollCheck,
      setReportRequestData,
      setReportUUID,
      fundsByMeasurementDate,
      setFundsByMeasurementDate,
      currentSelectedValues,
      setCurrentSelectedValues,
    }),
    [
      doReportPollCheck,
      reportRequestData,
      reportUUID,
      setDoReportPollCheck,
      setReportRequestData,
      setReportUUID,
      fundsByMeasurementDate,
      setFundsByMeasurementDate,
      currentSelectedValues,
      setCurrentSelectedValues,
    ]
  );

  const layoutContextValue = useMemo(
    () => ({
      pageTitle,
      setPageTitle,
      pageFilters,
      setPageFilters,
      navItems,
      setNavItems,
      pageActions,
      setPageActions,
      showPageForm,
      setShowPageForm,
      togglePageForm,
      pageBreadcrumbs,
      setPageBreadcrumbs,
      showAlert,
      setShowAlert,
      alertMessage,
      setAlertMessage,
      toggleMainForm,
      companyExchangeRate,
      pageType,
      setPageType,
      extraPageActions,
      commonMeasurementDate,
      setCommonMeasurementDate,
      openExportDialog,
      toggleExportDialog,
      openCapIqRefreshDialog,
      toggleRefreshCapitalIqDataDialog,
      currentMeasurementDate,
      setCurrentMeasurementDate,
      financialExchangeRate,
      updatePageActions,
      updateExtraPageActions,
      viewOnlyUser,
      currentFirmRole,
    }),
    [
      pageTitle,
      setPageTitle,
      pageFilters,
      setPageFilters,
      navItems,
      setNavItems,
      pageActions,
      setPageActions,
      showPageForm,
      setShowPageForm,
      togglePageForm,
      pageBreadcrumbs,
      setPageBreadcrumbs,
      showAlert,
      setShowAlert,
      alertMessage,
      setAlertMessage,
      toggleMainForm,
      companyExchangeRate,
      pageType,
      setPageType,
      extraPageActions,
      commonMeasurementDate,
      setCommonMeasurementDate,
      openExportDialog,
      toggleExportDialog,
      openCapIqRefreshDialog,
      toggleRefreshCapitalIqDataDialog,
      currentMeasurementDate,
      setCurrentMeasurementDate,
      financialExchangeRate,
      updatePageActions,
      updateExtraPageActions,
      viewOnlyUser,
      currentFirmRole,
    ]
  );

  const scalarCookieExists = useMemo(() => {
    if (user?.id) {
      return checkCookie(`${SCALAR_OTP_COOKIE}_${user.id}`);
    }

    return false;
  }, [user]);

  if (user?.has_2fa && !localStorage.getItem('isOtpVerified') && isFalseStrict(scalarCookieExists)) {
    history.push(SIGN_IN_URL);
  }

  return (
    <div className={`${classes.root} ${shouldDisplayGrayBg && classes.transparent}`}>
      <LayoutContext.Provider value={layoutContextValue}>
        <ExcelExportContext.Provider value={excelExportContextValue}>
          <Sidebar variant="permanent" />
          <Header>{displayMemoizedFilters}</Header>
          <main className={`${classes.content} ${shouldDisplayGrayBg && classes.transparent}`}>
            {showAlert && <Alert isAlertVisible={showAlert}>{alertMessage}</Alert>}
            {children}
          </main>

          {/* Fund Dialog Form */}
          {displayFundDialogForm && <FundDialogForm handleOnClose={onCloseFundDialog} open />}

          {isFirmStepperVisible && (
            <FirmStepper
              isVisible={isFirmStepperVisible}
              onClose={() => dispatch(globalAction.showFirmStepper(false))}
            />
          )}
          {openCompanyDialog && (
            <NewCompanyDialog
              open={openCompanyDialog}
              onClose={() => {
                dispatch(globalAction.showCompanyDialog(false));
                setShowPageForm(false);
                setEditMode(false);
              }}
              editMode={editMode}
            />
          )}
        </ExcelExportContext.Provider>
      </LayoutContext.Provider>

      <Footer shouldDisplayGrayBg={shouldDisplayGrayBg} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.element,
  backgroundColor: PropTypes.string,
  pageType: PropTypes.string,
};

export default Main;
