import { SPECIFIED_LABEL } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { getNumberValue } from 'utilities';

const getFinancialMetricValue = (params: any) => {
  const { financials, financialsMetric, financialsMetricValue } = params;

  let metricValue = financialsMetricValue;

  const isSpecified = financialsMetric.includes(SPECIFIED_LABEL);

  if (!isSpecified) {
    const financialsMetricKey = financialsMetric?.toLowerCase()?.replace(/ /g, '_');
    metricValue = getNumberValue(financials?.[financialsMetricKey]);
  }

  return { isSpecified, metricValue };
};

export default getFinancialMetricValue;
