import React from 'react';
import { TitleTextProps } from './types';

const TitleText = (props: TitleTextProps) => {
  const { titleArray, title } = props;
  if (titleArray) {
    return (
      <>
        {titleArray.map(line => (
          <p key={line.join('-')} style={line[1]}>
            {line[0]}
          </p>
        ))}
      </>
    );
  }
  return <span>{title}</span>;
};

export default TitleText;
