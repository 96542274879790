import React, { useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import { getObjectValue } from 'utilities';
import getOptionsAccordingApproach from './getOptionsAccordingApproach';
import { MultipleTypeApproachProps } from './types';
import DCFTVTContext from '../../context/DCFTVTContext';

const MultipleTypeApproachSelect = (props: MultipleTypeApproachProps) => {
  const { cell, dcfWacc } = getObjectValue(props);
  const { revenueMultipleOptions, ebitdaMultipleOptions } = useContext(DCFTVTContext);
  const options = getOptionsAccordingApproach({
    dcfWacc,
    cell,
    revenueMultipleOptions: revenueMultipleOptions as string[],
    ebitdaMultipleOptions: ebitdaMultipleOptions as string[],
  });

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore: ScalarSpreadsheet is not typed */
  return <GridSelect useHandleChange={false} usePortal options={options} {...props} />;
};

export default MultipleTypeApproachSelect;
