/* eslint-disable no-param-reassign */
/* eslint-disable react/display-name */
import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { GRAY_CELL } from 'common/constants/general';
import { GridDate, GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { removeValidationClasses } from 'utilities';
import isExpression from 'utilities/isExpression';
import * as CONSTANTS from './constants';

const conditions = ({ cell, cells }) => {
  const { alias, columnLegend } = cell;

  const hasCompoundingCell = cells[columnLegend + CONSTANTS.COMPOUNDING_ROW] || '';
  const hasCompounding = Boolean(Number(hasCompoundingCell.value));
  const equityConversionModel = cells[cell.columnLegend + CONSTANTS.EQUITY_CONVERSION_MODEL_ROW];
  const defaultGridDateConfig = () => ({
    gridDateComponent: GridDate,
    dataEditor: props => <GridDateEditor {...props} />,
    valueViewer: props => <GridDateValueViewer {...props} />,
  });

  const disableCell = key => {
    cells[key] = {
      ...cell,
      hidden: true,
      readOnly: true,
      isRequired: false,
      minValue: null,
      value: null,
      tooltipMessages: [],
      className: removeValidationClasses(),
    };
  };

  const enableCell = ({
    key,
    isRequired = true,
    readOnly = false,
    className = null,
    cellTooltip = null,
    cellExpr = null,
  }) => {
    const { value, defaultValue } = cell;
    const originCellValue = value || defaultValue;
    const currentValue = !isUndefined(originCellValue) ? Number(originCellValue) : null;
    const cellValue = !isUndefined(currentValue) && Number.isFinite(currentValue) ? currentValue : null;

    cells[key] = {
      ...cell,
      hidden: false,
      readOnly,
      isRequired,
      value: cellValue,
      tooltipMessages: cellTooltip ?? [],
      ...(!isNull(cellExpr) && { expr: cellExpr }),
      className: className || '',
    };
  };

  const isEquityConversionModelType = type => {
    if (type && equityConversionModel?.value) {
      if (equityConversionModel.value.toString() === type.toString()) {
        return true;
      }
    }
    return false;
  };

  const hasConversionModelSelectedAndIsNotLoan = () =>
    Number(equityConversionModel.value) !== 0 && !isEquityConversionModelType(CONSTANTS.LOAN_VALUE);

  const getClassName = isCalculated => (isCalculated ? GRAY_CELL : '');
  const getTooltip = isCalculated => (isCalculated ? [CONSTANTS.NEXT_ROUND_PREMONEY_TOOLTIP] : null);
  const getExpr = expr => expr.replace(/@/g, columnLegend);

  // 2.  Issuance Date
  if (alias === CONSTANTS.ISSUANCE_DATE_ALIAS) {
    cells[cell.key] = {
      ...cell,
      ...defaultGridDateConfig(),
    };
  }

  // 3.  Maturity Date
  if (alias === CONSTANTS.MATURITY_DATE_ALIAS) {
    cells[cell.key] = {
      ...cell,
      ...defaultGridDateConfig(),
    };
  }

  // 7. Compounding periods per year
  if (alias === CONSTANTS.COMPOUNDING_PERIODS_PER_YEAR_ALIAS) {
    if (!hasCompounding) {
      cells[cell.key] = {
        ...cell,
        readOnly: true,
        hidden: true,
        isRequired: false,
        value: '',
      };
    } else {
      cells[cell.key] = {
        ...cell,
        readOnly: false,
        hidden: false,
        isRequired: true,
      };
    }
  }

  // 15. loan value
  if (alias === CONSTANTS.LOAN_VALUE_ALIAS) {
    if (isEquityConversionModelType(CONSTANTS.LOAN_VALUE)) {
      enableCell({
        ...cell,
        readOnly: false,
      });
    } else {
      disableCell(cell.key);
    }
  }

  // 16.  Conversion Date
  if (alias === CONSTANTS.CONVERSION_DATE_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      cells[cell.key] = {
        ...cell,
        hidden: false,
        readOnly: false,
        isRequired: true,
        ...defaultGridDateConfig(),
      };
    } else {
      cells[cell.key] = {
        ...cell,
        hidden: true,
        readOnly: true,
        forceComponent: false,
        disableEvents: false,
        component: null,
        isRequired: false,
        minValue: null,
        value: '',
      };
    }
  }

  // 17.  Interest at Measurement Date
  if (alias === CONSTANTS.INTEREST_AT_MEASUREMENT_DATE_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      cells[cell.key] = {
        ...cell,
        readOnly: true,
        isRequired: true,
        hidden: false,
      };
    } else {
      disableCell(cell.key);
    }
  }

  // 18. Value at measurement date
  if (alias === CONSTANTS.VALUE_AT_MEASUREMENT_DATE_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      enableCell({
        key: cell.key,
        isRequired: false,
        readOnly: true,
      });
    } else {
      disableCell(cell.key);
    }
  }

  // 19. Value at Conversion
  if (alias === CONSTANTS.VALUE_AT_CONVERSION_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      enableCell({
        key: cell.key,
        isRequired: false,
        readOnly: true,
      });
    } else {
      disableCell(cell.key);
    }
  }

  const getEnableCellProps = () => {
    const { expr, exprPair } = cell;
    const isCalculated = isExpression(expr.toString());
    const isNextRoundPreMoney = alias === CONSTANTS.NEXT_ROUND_PREMONEY_ALIAS;
    // A cell is calculated when it was not entered any data manually
    const cellExpr = isCalculated ? getExpr(exprPair) : '';
    const cellClassName = getClassName(isCalculated);
    const tooltipMessages = isNextRoundPreMoney && isCalculated ? getTooltip(isCalculated) : '';
    return {
      key: cell.key,
      isRequired: false,
      className: cellClassName,
      cellTooltip: tooltipMessages,
      cellExpr,
    };
  };

  // 19.  Next Round PreMoney
  if (alias === CONSTANTS.NEXT_ROUND_PREMONEY_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      const enableCellProps = getEnableCellProps();
      enableCell(enableCellProps);
    } else {
      disableCell(cell.key);
    }
  }

  // 20.  Conversion Premoney
  if (alias === CONSTANTS.CONVERSION_PRE_MONEY_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      const enableCellProps = getEnableCellProps();
      enableCell(enableCellProps);
    } else {
      disableCell(cell.key);
    }
  }

  // 21.  Pre money share count
  if (alias === CONSTANTS.PRE_MONEY_SHARE_COUNT_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      enableCell({
        key: cell.key,
        isRequired: false,
      });
    } else {
      disableCell(cell.key);
    }
  }

  // 22. Conversion price per share
  if (alias === CONSTANTS.CONVERSION_PRICE_PER_SHARE_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      enableCell({
        key: cell.key,
        isRequired: true,
      });
    } else {
      disableCell(cell.key);
    }
  }

  // 23. Expected shares
  if (alias === CONSTANTS.EXPECTED_SHARES_ALIAS) {
    if (hasConversionModelSelectedAndIsNotLoan()) {
      enableCell({
        key: cell.key,
        isRequired: true,
      });
    } else {
      disableCell(cell.key);
    }
  }

  return cell;
};

export default conditions;
