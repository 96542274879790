import { useCallback, useState } from 'react';
import { valuationsAction } from 'common/actions';
import { Calibration as CalibrationValuationModel, ValuationByAllocation } from 'common/types/valuation/valuation';
import { VALIDATED } from '../../../../common/constants/general';
import { useStore } from '../../../../common/store';
import { UseStoreValues } from '../../../../common/types/store';
import { Tab } from '../../../../components';
import { VALUATION_SUMMARY_KEY } from '../../common/constants/valuationSummary';
import { useApproachQueryParam } from '../useApproachQueryParam';
import { UseCreateCalibrationParams } from '../useCreateValuation/types';

const useCreateCalibration = (params: UseCreateCalibrationParams) => {
  const {
    valuation,
    REDIRECT_TIMEOUT_DELAY,
    setValuation,
    setPageStatus,
    setArePendingChanges,
    setCurrentValuation,
    setIsEditingApproachName,
    setIsEditingCalibrationName,
    setIsChangingFinancialStatement,
    handleMenuClose,
    setApproachToUpdate,
    handleOpenUpdateApproachDialog,
  } = params;
  const [, dispatch] = useStore() as unknown as UseStoreValues;
  const [calibrationToUpdate, setCalibrationToUpdate] = useState<CalibrationValuationModel | null>(null);
  const [calibrationTabs, setCalibrationTabs] = useState<Tab[]>([]);

  // Approach Query Param
  const { setApproachQueryParam } = useApproachQueryParam();

  // Delete Calibration
  const handleDeleteCalibration = useCallback(
    (panelIdParam: string) => {
      if (panelIdParam) {
        valuation.calibrations = valuation.calibrations?.filter(calibration => calibration?.panelId !== panelIdParam);

        dispatch?.(valuationsAction.setValuationInfo(valuation));
        setCurrentValuation(valuation);
        setValuation(valuation);

        setPageStatus(VALIDATED);
        setArePendingChanges(true);

        setTimeout(() => {
          setApproachQueryParam(VALUATION_SUMMARY_KEY);
        }, REDIRECT_TIMEOUT_DELAY);
      }
    },
    [
      valuation,
      dispatch,
      setCurrentValuation,
      setValuation,
      setPageStatus,
      setArePendingChanges,
      REDIRECT_TIMEOUT_DELAY,
      setApproachQueryParam,
    ]
  );

  const handleUpdateCalibrationDialog = useCallback(
    (panelIdParam: string) => {
      if (panelIdParam) {
        handleMenuClose();

        const calibrationToBeUpdated = valuation.calibrations?.find(
          calibration => calibration?.panelId === panelIdParam
        );

        if (calibrationToBeUpdated) {
          setApproachToUpdate(null);
          handleOpenUpdateApproachDialog();
          setCalibrationToUpdate(calibrationToBeUpdated);
        }
      }
    },
    [
      handleMenuClose,
      handleOpenUpdateApproachDialog,
      setApproachToUpdate,
      setCalibrationToUpdate,
      valuation.calibrations,
    ]
  );

  const showUpdateCalibrationNameDialog = useCallback(
    (panelIdParam: string) => {
      if (panelIdParam) {
        setIsEditingApproachName(false);
        setIsEditingCalibrationName(true);
        setIsChangingFinancialStatement(false);
        handleUpdateCalibrationDialog(panelIdParam);
      }
    },
    [
      handleUpdateCalibrationDialog,
      setIsEditingApproachName,
      setIsEditingCalibrationName,
      setIsChangingFinancialStatement,
    ]
  );

  const updateValuationCalibrations = useCallback(
    (updatedCalibration: CalibrationValuationModel[]) => {
      const updatedValuation = { ...valuation, calibrations: updatedCalibration } as ValuationByAllocation;

      // Set Valuation Info in Store
      dispatch?.(valuationsAction.setValuationInfo(updatedValuation));
      setCurrentValuation(updatedValuation);
      setValuation(updatedValuation);
    },
    [dispatch, setCurrentValuation, setValuation, valuation]
  );

  return {
    handleDeleteCalibration,
    showUpdateCalibrationNameDialog,
    updateValuationCalibrations,
    setCalibrationToUpdate,
    calibrationToUpdate,
    setCalibrationTabs,
    calibrationTabs,
  };
};

export default useCreateCalibration;
