import { TERMINAL_VALUE_OPTIONS } from 'pages/Valuations/util/constants';
import { getObjectValue } from 'utilities';
import getSelectedApproach from './getSelectedApproach';
import { GetCurrentSelectedApproachProps } from './types';

const getCurrentSelectedApproach = (props: GetCurrentSelectedApproachProps) => {
  const { approaches, approach, isSecondColumn = false } = props;
  const { valuations_approach_dcf } = getObjectValue(approach);
  const {
    terminal_value,
    ebitda_gpc_approach_selection,
    ebitda_gpt_approach_selection,
    revenue_gpc_approach_selection,
    revenue_gpt_approach_selection,
  } = getObjectValue(valuations_approach_dcf);

  const revenueApproachSelection = revenue_gpc_approach_selection ?? revenue_gpt_approach_selection;
  const ebitdaApproachSelection = ebitda_gpc_approach_selection ?? ebitda_gpt_approach_selection;
  const isCombinedTVT = terminal_value === TERMINAL_VALUE_OPTIONS.REVENUE_AND_EBITDA_MULTIPLE;
  if (isCombinedTVT) {
    if (isSecondColumn) {
      return getSelectedApproach({ approaches, approachId: ebitdaApproachSelection });
    }
    return getSelectedApproach({ approaches, approachId: revenueApproachSelection });
  }
  const isRevenue = terminal_value === TERMINAL_VALUE_OPTIONS.REVENUE_MULTIPLE;
  const approachSelectedValue = isRevenue ? revenueApproachSelection : ebitdaApproachSelection;
  const selectedApproach = getSelectedApproach({ approaches, approachId: approachSelectedValue });
  return selectedApproach;
};

export default getCurrentSelectedApproach;
