import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { ParserParams } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { SHEET_CONFIG_CONSTANTS } from 'pages/Funds/common/constants/scheduleOfInvestments';
import {
  createColumns,
  customParser,
  rowConfig,
  ScheduleOfInvestmentsColumn,
} from 'pages/Funds/pages/ScheduleOfInvestments/config';
import { getArrayValue, getStringValue } from 'utilities';
import { UseScheduleOfInvestmentsSheet } from './types';

const { SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const useScheduleOfInvestmentSheet: UseScheduleOfInvestmentsSheet = params => {
  const { company, firmSlug, queryParams } = params;

  const investments = useMemo(() => getArrayValue(company?.investments), [company]);

  // Create row config for Investments Spreadsheet
  const rows = useMemo(() => rowConfig({ company, firmSlug, queryParams }), [company, firmSlug, queryParams]);

  // Create columns for Investments Spreadsheet
  const columns = useMemo(() => {
    if (!isEmpty(investments)) return createColumns({ company, investments });

    return [];
  }, [company, investments]);

  const spreadsheetTableTerms = useMemo(
    () => SCHEDULE_OF_INVESTMENTS_SPREADSHEET_TABLE_TERMS(getStringValue(company?.company_slug)),
    [company]
  );

  // Create parser with custom props
  const parser = useCallback(
    (props: ParserParams<ScheduleOfInvestmentsColumn>) =>
      customParser({
        ...props,
      }),
    []
  );

  // Create Spreadsheet config
  const spreadsheet = useMemo(() => {
    if (!isEmpty(columns)) {
      const spreadsheetConfig = new SpreadsheetConfig({
        allowConfirmAndDeleteColumn: false,
        alwaysDisplayLegend: false,
        colTitleRow: 0, // Add header row styles
        columns,
        currencyFormatter: true,
        format: undefined,
        hasColTitle: true, // Add styles to header row (colTitleRow)
        name: spreadsheetTableTerms.tableName,
        page: spreadsheetTableTerms.tableName as unknown as SpreadsheetConfig['page'],
        parser,
        rowConfig: rows,
        showPreviousColsDivider: false,
        showTitlesColumn: true,
        showToolbar: true,
        showTotalColumn: false,
        tableData: { columns },
        tableTerms: spreadsheetTableTerms as unknown as SpreadsheetConfig['tableTerms'],
        totalParser: undefined,
        unitsFormatter: true,
      });

      return spreadsheetConfig;
    }

    return null;
  }, [columns, parser, rows, spreadsheetTableTerms]);

  return {
    spreadsheet,
  };
};

export default useScheduleOfInvestmentSheet;
