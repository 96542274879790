import { NOT_APPLICABLE } from 'common/constants/general';
import {
  largeCurrencyFormat,
  largeDecimalFormat,
  smallCurrencyFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { WeightedShareValuesCellCustomData } from 'pages/ValuationsAllocation/allocation/WeightedShareValues/config';
import { ALLOCATION_STATIC_CUSTOM_FORMAT } from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { getExpression, getObjectValue, getStringValue } from 'utilities';
import { alphabetGenerator, getColumnLetter } from 'utilities/alphabet-utilities';
import { CellParserParams, WeightedShareValuesParserParams } from './types';
import { generateWeightedValuePerShareExpression } from './utils';

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE } = SHEET_ALIASES_CONSTANTS;

const { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS, getAllocationWeightedShareValuesHeader }
  = SHEET_TITLES_CONSTANTS;

const { WEIGHTED_VALUE_PER_SHARE, MOIC, NUMBER_OF_SHARES, VALUE }
  = ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADERS;

const cellParser = (params: CellParserParams) => {
  const { allocationScenarios, alphabet, colIndex, column, isUniformCurrency, row, rowIndex } = params;

  const colNumber = colIndex + 1;
  const rowNumber = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + rowNumber;

  const {
    investedCapital,
    isCustomSecurity,
    security,
    value: columnValue,
    notApplicableMOIC,
  } = getObjectValue(column[row.alias]);

  const getSumProductExpr = () => {
    const { presentValuePerShareCells, scenarioWeightingProbabilityCells } = generateWeightedValuePerShareExpression({
      allocationScenarios,
      isUniformCurrency,
      security: row.value?.toString(),
    });
    return `=SUMPRODUCT([${scenarioWeightingProbabilityCells}], [${presentValuePerShareCells}])`;
  };

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };

  const weightedValuePerShareColumn = getColumnLetter(WEIGHTED_VALUE_PER_SHARE.columnNumber - 1);
  const weightedValuePerShareCell = `${weightedValuePerShareColumn}${rowNumber}`;

  const numberOfSharesColumn = getColumnLetter(NUMBER_OF_SHARES.columnNumber - 1);
  const numberOfSharesCell = `${numberOfSharesColumn}${rowNumber}`;

  const valueColumn = getColumnLetter(VALUE.columnNumber - 1);
  const valueCell = `${valueColumn}${rowNumber}`;

  // Parse Cell based on Row alias
  if (row.alias === ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_HEADER_TITLE) {
    cell.value = getStringValue(getAllocationWeightedShareValuesHeader(colNumber)?.title);
  }
  // Rest of the Rows (Securities)
  else {
    // Parse Cell based on Column number
    switch (colNumber) {
      case WEIGHTED_VALUE_PER_SHARE.columnNumber:
        cell.customFormat = ALLOCATION_STATIC_CUSTOM_FORMAT;
        cell.expr = getSumProductExpr();
        cell.format = smallCurrencyFormat;
        cell.gridType = 'number';
        break;

      case NUMBER_OF_SHARES.columnNumber:
        cell.format = largeDecimalFormat;
        cell.gridType = 'number';
        break;

      case VALUE.columnNumber:
        cell.allowNegativeValue = false;
        cell.expr = `=${weightedValuePerShareCell}*${numberOfSharesCell}`;
        cell.format = largeCurrencyFormat;
        cell.gridType = 'number';
        cell.minValue = 0;

        if (isCustomSecurity) {
          cell.expr = null;
          cell.readOnly = false;
        }
        break;

      case MOIC.columnNumber:
        if (notApplicableMOIC) {
          cell.value = NOT_APPLICABLE;
          break;
        }
        cell.expr = `=IF(${investedCapital}>0,${valueCell}/${investedCapital},0)`;
        cell.format = xStandardSuffixFormat;
        cell.gridType = 'number';
        break;

      default:
        break;
    }
  }

  // Custom Data for Weighted Share Values
  cell.data = {
    ...getObjectValue(cell.data),
    isCustomSecurity,
    security,
  } as WeightedShareValuesCellCustomData;

  // Set default Cell values
  const { expr = '', format = null, gridType = 'string', value = '' } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      value,
    } as Cell,
  };
};

const customParser = (params: WeightedShareValuesParserParams) => {
  const { allocationScenarios, columns, isUniformCurrency, rowConfig } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allocationScenarios,
          alphabet,
          colIndex,
          column,
          isUniformCurrency,
          row,
          rowIndex,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
