/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, DialogActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import {
  EQUITY_VALUE_ROW_NUMBER,
  FUTURE_EQUITY_VALUE_ROW_NUMBER,
  FUTURE_EQUITY_VALUE_TABLE_ID,
  FUTURE_EXIT_TABLE_ID,
  FUTURE_VALUE,
  MATURITY_INDEX,
  OPM,
  OPM_BACKSOLVE_DATE_INDEX,
  OPM_TABLE_ID,
  SCENARIO_METHOD_ROW_NUMBER,
  SCENARIO_METHOD_TITLE,
  SCENARIO_METHODS,
  VOLATILITY_INDEX,
} from 'common/constants/allocations';
import { CAP_TABLE_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { ADJUSTED_EBITDA_TITLE } from 'common/constants/financials';
import { FIX_BEFORE_CONTINUE } from 'common/constants/messages/validations';
import { currencyFormat } from 'common/formats/formats';
import useFormat from 'common/hooks/useFormat';
import { useStore } from 'common/store';
import { Alert, InfoCard, MessageBox } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { LayoutContext } from 'context';
import AllocationContext from 'context/AllocationContext';
import createOpmInputData from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/createOpmInputData';
import OPMInputTable from 'pages/Valuations/approaches/backsolveApproach/OPMInputTable/OPMInputTable';
import { FutureEquity } from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity';
import createFutureEquityConfiguration from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/config/createFutureEquityConfiguration';
import { ModifiedPresentEquity } from 'pages/ValuationsAllocation/approaches/FutureExit/ModifiedPresentEquity';
import createModifiedPresentEquityConfiguration from 'pages/ValuationsAllocation/approaches/FutureExit/ModifiedPresentEquity/config/createModifiedPresentEquityConfiguration';
import FutureExitContext from 'pages/ValuationsAllocation/approaches/FutureExit/utils/FutureExitContext';
import { getApproachFromColumn } from 'pages/ValuationsAllocation/approaches/FutureExit/utils/getApproachFromColumn';
import { METRIC_OPTION_LABELS, SPECIFIED_MULTIPLE } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { useReadFieldAttributes } from 'services/hooks';
import { useGetFinancialStatementBasicDataByMD } from 'services/hooks/financialStatement';
import { FUTURE_EXIT_MODEL_NAME, VALUATIONS_PAGE } from 'services/hooks/useReadFieldAttributes/constants';
import { formatNumbers, getLedgerKey } from 'utilities';
import { SelectButton } from './components';

const useStyles = makeStyles(() => ({
  infoCards: {
    display: 'flex',
    padding: '1rem 2.5rem',
    backgroundColor: '#f1f4f6',
    boxShadow: 'inner 0px 1px 2px #000000',

    '& .MuiPaper-root': {
      flexGrow: 1,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const getTableValue = (value, exchangeRate = 1) => {
  if (value) {
    return value * exchangeRate || 0;
  }
  return 0;
};

const InfoCards = ({ data, companyExchangeRate, format, scenarioMethod }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoCards}>
      <InfoCard
        title={FUTURE_VALUE}
        body={formatNumbers({
          format: currencyFormat({ fractionDigits: 0 }),
          value: getTableValue(data?.futureEquitySheet?.cells?.FUTURE_VALUE?.value, companyExchangeRate),
          currency: format.currency,
        })}
      />
      <InfoCard title={SCENARIO_METHOD_TITLE} body={SCENARIO_METHODS[scenarioMethod] || ''} />
    </div>
  );
};
const FutureValueLedger = ({ cell, closeDialog }) => {
  const [getFinancialsBasicDataList] = useGetFinancialStatementBasicDataByMD();
  const classes = useStyles();

  const [{ valuationApproach: storedValuationApproach }] = useStore();

  // cells here are the cells from the main table in Allocation view
  const { cells: allocationCells, onCellsChanged, tableData } = useContext(FeaturedSpreadsheetContext);
  const [format, formatDispatch] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: tableData?.currency,
    units: '',
  });
  const { companyExchangeRate } = useContext(LayoutContext);
  const { ltmData, ntmData, measurementDate, valuationInfo } = useContext(AllocationContext);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [valuationApproaches, setValuationApproaches] = useState();
  const [spreadsheets, setSpreadsheets] = useState([]);
  const [futureExitApproach, setFutureExitApproach] = useState({});
  const [selectedApproach, setSelectedApproach] = useState();
  const [isNTMSelected, setIsNTMSelected] = useState(false);
  const [ltmNtm, setLtmNtm] = useState();
  const { onChange: onSpreadsheetChange, cells, data, areCellsValid } = useWorkbook(spreadsheets);

  const fieldAttributesMap = useReadFieldAttributes(VALUATIONS_PAGE);

  const futureExitAttributes = useMemo(
    () => ({
      futureExitApproachAttrs: fieldAttributesMap.get(FUTURE_EXIT_MODEL_NAME),
    }),
    [fieldAttributesMap]
  );
  const valuationApproach = useMemo(() => {
    if (futureExitApproach?.valuation_approach && valuationApproaches?.length) {
      const selectedValuationApproach = valuationApproaches.find(
        approach => approach.id === futureExitApproach.valuation_approach
      );
      if (selectedValuationApproach) {
        return {
          ...selectedValuationApproach,
          valuations_approach_future_exit: futureExitApproach,
        };
      }
    }
    return {
      valuations_approach_future_exit: {
        ...futureExitApproach,
      },
    };
  }, [futureExitApproach, valuationApproaches]);

  useEffect(() => {
    if (futureExitApproach && valuationInfo.valuations_approaches) {
      setSelectedApproach(getApproachFromColumn(futureExitApproach, valuationInfo.valuations_approaches));
    }
  }, [futureExitApproach, valuationInfo]);

  const ledgerKey = getLedgerKey(cell.columnLegend);

  useEffect(() => {
    if (!isEmpty(valuationInfo?.valuations_approaches)) {
      const ledgersData = allocationCells[ledgerKey] || {};
      const valuationApp = ledgersData[FUTURE_EXIT_TABLE_ID];
      if (!isEmpty(valuationApp)) {
        setFutureExitApproach({ ...valuationApp });
      }
    }
  }, [allocationCells, cell, valuationInfo, ledgerKey]);

  const usedApproachIds = useMemo(() => {
    // filter the allocationCells to find only those that have the word 'LEDGER' in the key
    const ledgerCells = Object.keys(allocationCells).filter(key => key.includes('LEDGER'));
    // for each key, look up the related cell and return the 'BACKSOLVE_VALUATION' property
    return ledgerCells.map(key => allocationCells[key][FUTURE_EXIT_TABLE_ID]?.valuation_approach);
  }, [allocationCells]);

  const selectApproach = useCallback(approach => {
    setIsAddingNew(true);
    const initialApproach = {};
    setFutureExitApproach(
      approach?.valuations_approach_future_exit ? { ...approach.valuations_approach_future_exit } : initialApproach
    );
  }, []);

  const multipleOptions = useMemo(() => {
    if (selectedApproach) {
      return [
        ...getSelectionCellOptions({
          specificApproach: selectedApproach,
        }),
        SPECIFIED_MULTIPLE,
      ];
    }
    return [SPECIFIED_MULTIPLE];
  }, [selectedApproach]);

  useEffect(() => {
    if (!isEmpty(futureExitApproach)) {
      setIsAddingNew(true);
    }
  }, [futureExitApproach]);

  const scenarioMethod = useMemo(() => {
    const scenarioMethodKey = `${cell.columnLegend}${SCENARIO_METHOD_ROW_NUMBER}`;
    return allocationCells[scenarioMethodKey].value;
  }, [allocationCells, cell]);

  const isOpm = useMemo(() => Number(scenarioMethod) === OPM, [scenarioMethod]);

  const updateFields = useCallback(() => {
    const tmpCells = { ...allocationCells };

    const ledgersData = tmpCells[ledgerKey] || {};

    const dialogData = {
      ...valuationApproach.valuations_approach_future_exit,
    };

    // Extract alias and value from the relevant cells (the ones we want to save) of the OPM inputs
    const opmInputs = [OPM_BACKSOLVE_DATE_INDEX, MATURITY_INDEX, VOLATILITY_INDEX]
      .map(index => data?.opmInputSheet?.data[index]?.[1])
      .filter(item => !isUndefined(item))
      .map(({ value, alias }) => ({ value, alias }));

    const changes = [];

    ledgersData[OPM_TABLE_ID] = opmInputs;
    ledgersData[FUTURE_EQUITY_VALUE_TABLE_ID] = {
      ...(dialogData || {}),
    };
    ledgersData[FUTURE_EXIT_TABLE_ID] = {
      ...valuationApproach.valuations_approach_future_exit,
    };
    changes.push({ cell: ledgersData, value: ledgersData.value });

    const futureCell = tmpCells[cell.columnLegend + FUTURE_EQUITY_VALUE_ROW_NUMBER];
    const presentCell = tmpCells[cell.columnLegend + EQUITY_VALUE_ROW_NUMBER];
    changes.push({
      cell: futureCell,
      value: getTableValue(data?.futureEquitySheet?.cells?.FUTURE_VALUE?.value, companyExchangeRate),
    });
    changes.push({
      cell: presentCell,
      value: getTableValue(data?.presentValueSheet?.cells?.present_equity_value?.value, companyExchangeRate),
    });
    onCellsChanged(changes);
  }, [ledgerKey, data, allocationCells, cell, companyExchangeRate, onCellsChanged, valuationApproach]);

  const save = useCallback(() => {
    const isValid = areCellsValid();
    if (isValid) {
      setIsAlertVisible(false);
      updateFields();
      closeDialog();
    }
  }, [closeDialog, areCellsValid, updateFields]);

  useEffect(() => {
    const approaches = valuationInfo?.valuations_approaches;
    if (
      approaches
      && measurementDate
      && valuationApproaches
      && valuationApproach
      && !isEmpty(valuationApproach)
      && futureExitAttributes
    ) {
      const { futureExitApproachAttrs } = futureExitAttributes;
      const futureEquitySheet = createFutureEquityConfiguration({
        approach: valuationApproach,
        approaches,
        name: 'futureEquitySheet',
        staticFinancials: { ltmData, ntmData },
        isStatic: true,
        futureExitApproachAttrs,
      });
      const modifiedPresentEquityValueSheet = createModifiedPresentEquityConfiguration({
        name: 'presentValueSheet',
        approach: valuationApproach,
        futureEquitySheet,
        measurementDate,
        ltmData: ltmData?.balance_sheet,
        futureExitApproachAttrs,
      });

      const opmDataList = allocationCells[ledgerKey][OPM_TABLE_ID];
      const opmData = opmDataList.reduce((sofar, next) => {
        if (!sofar[next.alias]) {
          sofar[next.alias] = next.value;
        }
        return sofar;
      }, {});
      const opmTableName = 'opmInputSheet';
      const opmInputSheet = createOpmInputData({
        tableName: opmTableName,
        data: opmData,
        attrs: futureExitApproachAttrs,
        approaches,
        measurementDate,
      });

      setSpreadsheets([futureEquitySheet, modifiedPresentEquityValueSheet, opmInputSheet]);
    }
  }, [
    futureExitAttributes,
    valuationApproach,
    valuationInfo,
    ltmData,
    valuationApproaches,
    ntmData,
    measurementDate,
    allocationCells,
    cell,
    ledgerKey,
  ]);

  useEffect(() => {
    if (valuationInfo?.valuations_approaches) {
      const futureExitApproaches = valuationInfo.valuations_approaches.filter(
        approach => approach?.approach_type.includes('Future Exit') && !usedApproachIds.includes(approach.id)
      );
      setValuationApproaches(futureExitApproaches);
    }
  }, [valuationInfo, usedApproachIds]);

  useEffect(() => {
    if (measurementDate) {
      const fetchFinancials = async () => {
        const financialsBasicDataList = await getFinancialsBasicDataList(measurementDate.id);
        setLtmNtm(financialsBasicDataList.find(f => !f.is_a_version));
      };

      fetchFinancials();
    }
  }, [measurementDate, getFinancialsBasicDataList]);

  const metricOptions = useMemo(() => {
    if (!ltmNtm?.use_adjusted_ebitda) return METRIC_OPTION_LABELS.filter(m => !m.includes(ADJUSTED_EBITDA_TITLE));
    return METRIC_OPTION_LABELS;
  }, [ltmNtm]);

  const onChange = useCallback(
    (changeCell, expr) => {
      if (changeCell.alias === FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE) {
        const isNTM = expr.includes('NTM');
        setIsNTMSelected(isNTM);
        if (isNTM && !isNTMSelected) {
          setSelectedApproach(undefined);
        }
      } else if (changeCell.alias === 'comps') {
        const foundApproach = valuationInfo.valuations_approaches.find(a => a.id.toString() === expr?.toString());
        setSelectedApproach(foundApproach);
      }
      onSpreadsheetChange(changeCell, expr);
    },
    [isNTMSelected, onSpreadsheetChange, valuationInfo]
  );

  const futureExitContextData = useMemo(
    () => ({
      approaches: valuationInfo.valuations_approaches || [],
      isNTMSelected,
      multipleOptions,
      metricOptions,
    }),
    [valuationInfo, isNTMSelected, multipleOptions, metricOptions]
  );
  if (!isAddingNew && !storedValuationApproach && spreadsheets && valuationApproaches && isEmpty(futureExitApproach)) {
    return (
      <>
        <MessageBox
          title="Future Equity Valuation"
          tagline="Select an existing Future Exit approach or add a new one."
          action={
            <SelectButton
              valuationApproaches={valuationApproaches}
              addNew={() => setIsAddingNew(true)}
              selectApproach={selectApproach}
              label="Select Future Exit"
            />
          }
          showIcon={false}
          hasFrame={false}
        />
        <DialogActions className={classes.dialogActions}>
          <Button id="backsolve-ledger-cancel-btn" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <FutureExitContext.Provider value={futureExitContextData}>
      <LedgerDialog
        id="future-exit-ledger"
        title="Future Value Ledger"
        onSave={save}
        onClose={() => {
          closeDialog();
        }}
        showDeleteColumn
        dialogHeader={
          <InfoCards
            data={data}
            companyExchangeRate={companyExchangeRate}
            format={format}
            scenarioMethod={scenarioMethod}
          />
        }>
        <Alert
          autoClose
          isAlertVisible={isAlertVisible}
          setIsAlertVisible={setIsAlertVisible}
          onClose={() => setIsAlertVisible(false)}>
          {FIX_BEFORE_CONTINUE}
        </Alert>
        {spreadsheets && cells && data && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FutureEquity
                spreadsheets={data}
                onChange={onChange}
                format={format}
                formatDispatch={formatDispatch}
                isLedgerTable
              />
            </Grid>
            <Grid item xs={6}>
              <ModifiedPresentEquity
                spreadsheets={data}
                onChange={onChange}
                format={format}
                formatDispatch={formatDispatch}
                isLedgerTable
              />
            </Grid>
            {isOpm && (
              <Grid item xs={12}>
                <OPMInputTable
                  spreadsheets={data}
                  onChange={onChange}
                  fullWidth={false}
                  isLedgerTable
                  measurementDate={measurementDate}
                />
              </Grid>
            )}
          </Grid>
        )}
      </LedgerDialog>
    </FutureExitContext.Provider>
  );
};

FutureValueLedger.defaultProps = {
  cell: {
    value: null,
    key: null,
  },
};

FutureValueLedger.propTypes = {
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

InfoCards.propTypes = {
  data: PropTypes.object,
  companyExchangeRate: PropTypes.number,
  format: PropTypes.object,
  scenarioMethod: PropTypes.string,
};

export default FutureValueLedger;
