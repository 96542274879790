import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GridRowLabel } from 'components/Grid';
import { Cell } from 'components/ScalarSpreadsheet/utilities/Cell';
import { mergeCells } from 'utilities';
import SingleColumn from './SingleColumn';
import StaticValueViewer from './StaticValueViewer';
import FeaturedSpreadsheetContext from '../context/FeaturedSpreadsheetContext';

const TitlesColumn = ({
  className,
  rows,
  hasColTitle,
  currencyFormatter,
  unitsFormatter,
  isToolbarVisible,
  accessedSheet,
  showTitlesColumn = true,
}) => {
  const [titlesGrid, setTitlesGrid] = useState([]);

  const { onTitleCellsChanges, validatedTitleCells, setTitleCells, getClassnamesForLegends, displayLegend }
    = useContext(FeaturedSpreadsheetContext);

  const titlesColumnRef = useRef({});

  useEffect(() => {
    const className = getClassnamesForLegends('legend row-legend col-legend');
    const tmpRows = [
      [
        {
          value: '',
          readOnly: true,
          className,
        },
      ],
    ];
    if (showTitlesColumn) {
      tmpRows[0].push({
        value: '',
        readOnly: true,
        className: getClassnamesForLegends('legend col-legend', isToolbarVisible),
      });
    }

    rows.forEach((row, rowIndex) => {
      const isFirstRow = rowIndex === 0;

      let cell = {
        ...row,
        readOnly: true,
      };
      const copyOfCell = { ...cell };

      const newRow = [];

      for (let colIndex = 0; colIndex < 2; colIndex += 1) {
        const isFirstColumn = colIndex === 0;
        const isFirstTitle = isFirstRow && colIndex === 1;

        const getLargeRowClass = className => {
          if (row.largeHeader) {
            return `${className} large-header`;
          }
          return className;
        };

        if (isFirstRow && isFirstColumn) {
          cell = {
            value: '',
            readOnly: true,
            className: getLargeRowClass('legend col-legend'),
          };
        }
        if (isFirstColumn) {
          const classNames = getClassnamesForLegends('legend row-legend', isToolbarVisible, displayLegend);
          cell = {
            ...cell,
            className: getLargeRowClass(classNames),
            forceComponent: false,
            value: rowIndex + 1,
            isLegend: true,
            isTitleCell: true,
            valueViewer: null,
          };
          newRow.push(cell);
        } else if (showTitlesColumn) {
          const classNames = ['row-label'];

          if (isFirstRow) {
            classNames.push('row-label-title');
          }

          if (row.className) {
            classNames.push(row.className);
          }

          if (isFirstRow && hasColTitle) {
            classNames.push('table-header');
          }

          if (isFirstRow && (currencyFormatter || unitsFormatter)) {
            classNames.push('formatter-cell');
          }

          cell = {
            ...cell,
            className: classNames.join(' '),
            component: (
              <GridRowLabel
                cell={{ ...row }}
                currencyFormatter={isFirstRow && currencyFormatter}
                unitsFormatter={isFirstRow && unitsFormatter}
                formatterCell={isFirstTitle}
              />
            ),
            forceComponent: true,
            rowSpan: row.rowSpan || 1,
            style: row.style || {},
          };

          if (cell.titleClassName) {
            cell.className = `${cell.className} ${cell.titleClassName}`;
          }
          if (cell.isEditableTitleCell || cell.isTitleWithLabel) {
            classNames.push('editable');
            cell = {
              ...cell,
              key: row.alias,
              component: null,
              forceComponent: false,
              readOnly: cell.isCellWithLabel,
              valueViewer: copyOfCell.valueViewer,
              dataEditor: copyOfCell.dataEditor,
              value: copyOfCell.value,
              isRequired: true,
              sheet: accessedSheet,
            };
            if (cell.useScalarSpreadsheetCell && cell.dropdown) {
              cell = Object.assign(new Cell(cell.expr), {
                ...cell,
                className: `${cell.className} titles-column-selector`,
              });
            }
          }
          newRow.push(cell);
        }
      }

      tmpRows.push(newRow);
    });

    mergeCells(tmpRows);

    if (validatedTitleCells) {
      setTitlesGrid(validatedTitleCells);
    } else {
      setTitlesGrid(tmpRows);
    }

    if (setTitleCells) {
      setTitleCells(tmpRows);
    }
  }, [
    rows,
    isToolbarVisible,
    displayLegend,
    getClassnamesForLegends,
    showTitlesColumn,
    validatedTitleCells,
    setTitleCells,
    hasColTitle,
    currencyFormatter,
    unitsFormatter,
    accessedSheet,
  ]);

  useEffect(() => {
    if (validatedTitleCells) {
      setTitlesGrid(validatedTitleCells);
    }
  }, [validatedTitleCells]);

  const onChanges = useCallback(
    changes => {
      if (onTitleCellsChanges) onTitleCellsChanges(changes);
    },
    [onTitleCellsChanges]
  );

  return (
    <div ref={titlesColumnRef}>
      <SingleColumn
        data={titlesGrid}
        className={className}
        valueViewer={StaticValueViewer}
        isLegend
        onCellsChanged={onChanges}
      />
    </div>
  );
};

TitlesColumn.defaultProps = {
  rows: [],
  showTitlesColumn: true,
};

TitlesColumn.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array,
  hasColTitle: PropTypes.bool,
  currencyFormatter: PropTypes.bool,
  unitsFormatter: PropTypes.bool,
  isToolbarVisible: PropTypes.bool,
  accessedSheet: PropTypes.object,
  showTitlesColumn: PropTypes.bool,
};

export default TitlesColumn;
