import { GREATER_THAN } from 'common/constants/messages/validations';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getNumberValue, getObjectValue } from 'utilities';
import { HandleScenarioEquityValueParams } from './types';

const { VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE } = SHEET_ALIASES_CONSTANTS;

const handleScenarioEquityValue = (params: HandleScenarioEquityValueParams) => {
  const { parsedColumns: parsedCells, addFeedbackFromCell, removeFeedbackFromCell } = params;

  const scenarioEquityValueCells = Object.values(getObjectValue(parsedCells)).filter(
    parsedCell => parsedCell?.alias === VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE
  );

  scenarioEquityValueCells.forEach(equityValueCell => {
    const { value, hidden } = getObjectValue(equityValueCell);

    const parsedCell = getObjectValue(equityValueCell);

    // Remove previous feedback
    parsedCell.tooltipMessages = [];

    if (!hidden && getNumberValue(value as number) <= 0) {
      addFeedbackFromCell?.(parsedCell, GREATER_THAN(0));
      parsedCell.isValid = false;
    } else {
      removeFeedbackFromCell?.(parsedCell, GREATER_THAN(0));
      parsedCell.isValid = true;
    }
  });
};

export default handleScenarioEquityValue;
