import React from 'react';
import { ValuationApproachGPC } from 'api';
import { NOT_APPLICABLE } from 'common/constants/general';
import {
  largeCurrencyFormat,
  oneDecimalPercentFormat,
  oneDecimalPercentFormatValidateFloatTrue,
} from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import {
  MEDIAN_LABEL,
  PERCENTILE_25,
  PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
  VOLATILITY_YEARS,
  VOLATILITY_YEARS_COMPANY_CELL,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/sheetAliases';
import { countDecimalPlaces, generateAllRowsExpression, getExpression, getObjectValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import {
  CellParserParams,
  ColumnVolatilityYears,
  CustomParserParams,
  EvaluateNTMRevenueGrowth,
  EvaluateNTMRevenueGrowthReturn,
  GenerateNTMRevenueGrowthExpressionParams,
  GetCellParamsValueProps,
  GetCellParamsValueReturn,
  GetRankExpressionParams,
} from './types';
import { MEAN_LABEL, SPECIFIED_LABEL } from '../../../../../util/constants';
import { PERCENTILE_75 } from '../../../constants';
import MultipleTypeSelect from '../../../gpc/MultipleTypeSelect';
import MultipleTypeValueViewer from '../../../gpc/MultipleTypeValueViewer';
import {
  get25thPercentileExpression,
  get75thPercentileExpression,
  getMeanExpression,
  getMedianExpression,
} from '../utils/utils';

const {
  PERFORMANCE_METRICS_SPREADSHEET_COMPANY,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE,
  PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE,
} = SHEET_ALIASES_CONSTANTS;
const {
  PERFORMANCE_METRICS_SPREADSHEET_COLUMNS,
  PERFORMANCE_METRICS_SPREADSHEET_HEADERS,
  PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_COLUMNS,
} = SHEET_TITLES_CONSTANTS;

const EXCLUDE_TITLE_AND_SUBTITLE = 2;

const generateNTMRevenueGrowthExpression = (params: GenerateNTMRevenueGrowthExpressionParams): string => {
  const { alphabet, rowNumber } = params;

  // Column legend (-1 excluding Company Column)
  const columnLTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE - 1];
  const columnNTMRevenueLegend = alphabet[PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE - 1];

  const columnLTMRevenueKey = columnLTMRevenueLegend + rowNumber;
  const columnNTMRevenueKey = columnNTMRevenueLegend + rowNumber;

  // NTM Revenue Growth formula expression
  const NTMRevenueGrowthExpression = `=IF(OR(${columnNTMRevenueKey} == 0, ${columnLTMRevenueKey} == 0), "${NOT_APPLICABLE}", (${columnNTMRevenueKey} / ${columnLTMRevenueKey}) - 1)`;

  return NTMRevenueGrowthExpression;
};

const evaluateNTMRevenueGrowth: EvaluateNTMRevenueGrowth = params => {
  const { alias, alphabet, rowNumber } = params;
  let { value, expr } = params;

  if (value === NOT_APPLICABLE && alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY)
    return {
      expr: '',
      value,
    };

  // If the NTM Revenue Growth has 2 or less decimal places, we need to use the Expression to display the accurate value
  // Because that means that the value is not using the new decimal places coming from the CAPIQ API
  // We also need to use the Expression for the Company
  if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY || countDecimalPlaces(Number(value)) <= 2) {
    value = 0;
    expr = generateNTMRevenueGrowthExpression({ alphabet, rowNumber });
  }

  // The value is in percentage, we only need to apply the format
  if (alias !== PERFORMANCE_METRICS_SPREADSHEET_COMPANY) value = Number(value ?? 0);

  return { expr, value };
};

function getRankExpression({ colNumber, allComparisonsExpression }: GetRankExpressionParams) {
  let expr = null;
  let hidden = true;
  if (colNumber < PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_1_YEAR) {
    expr = `=PERCENTRANK([${allComparisonsExpression}], ${allComparisonsExpression.at(-1)})`;
    hidden = false;
  }
  return { expr, hidden };
}

type KeyNumber = 9 | 10 | 11;

const getCellParamsValue = ({
  valuationsApproachGpc,
  params,
  colNumber,
}: GetCellParamsValueProps): GetCellParamsValueReturn => {
  let value = '';

  const keyLabel = VOLATILITY_YEARS[colNumber as KeyNumber];
  const keyValue = VOLATILITY_YEARS_COMPANY_CELL[colNumber as KeyNumber];

  const customKey = VOLATILITY_YEARS_COMPANY_CELL[colNumber as KeyNumber];
  const label = valuationsApproachGpc?.[keyLabel as keyof ValuationApproachGPC] as string;
  value = valuationsApproachGpc?.[keyValue as keyof ValuationApproachGPC] as string;

  // allComparisonsExpression contains the rows that reference the public comps and the company being valued.
  // The latter is removed from the array to calculate the selected metric based on the public comps only.
  const comparisonsExpr = params.allComparisonsExpression.slice(0, -1);
  if (label !== SPECIFIED_LABEL) {
    switch (label) {
      case MEAN_LABEL:
        value = getMeanExpression(`[${comparisonsExpr.toString()}]`);
        break;
      case MEDIAN_LABEL:
        value = getMedianExpression(`[${comparisonsExpr.toString()}]`);
        break;
      case PERCENTILE_25:
        value = get25thPercentileExpression(`[${comparisonsExpr.toString()}]`);
        break;
      case PERCENTILE_75:
        value = get75thPercentileExpression(`[${comparisonsExpr.toString()}]`);
        break;
      default:
        value = '';
    }
  }

  return {
    customKey,
    value,
    label,
    expr: value ?? null,
  };
};

const cellParser = (params: CellParserParams) => {
  const {
    alphabet,
    colIndex,
    column,
    row,
    rowIndex,
    allComparisonsExpression,
    isDisabled,
    customData: cellOptions,
  } = params;
  const { initialObject } = getObjectValue(params);
  const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(initialObject);
  const {
    one_year_volatility_percentile_selection,
    two_year_volatility_percentile_selection,
    five_year_volatility_percentile_selection,
  } = getObjectValue(valuationsApproachGpc);

  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const volatilitySelectionMap = {
    9: one_year_volatility_percentile_selection,
    10: two_year_volatility_percentile_selection,
    11: five_year_volatility_percentile_selection,
  };

  const { options } = getObjectValue(row);
  let {
    alias,
    className = '',
    colSpan = 1,
    expr = '',
    format = null,
    gridType = 'string',
    rowSpan,
    hidden,
    readOnly,
    dataEditor,
    valueViewer,
    isVolatility,
    dropdown,
    useScalarSpreadsheetCell,
  } = getObjectValue(row);
  let value = column[alias]?.value ?? '';
  let customKey;
  let NTMRevenueGrowthEvaluation: EvaluateNTMRevenueGrowthReturn = {};

  const VOLATILITY_COLUMNS_ARRAY = Object.values(PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_COLUMNS);

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_TITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].value;

      switch (colNumber) {
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_1_YEAR:
          className = `${className} ${PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME}`;
          rowSpan = 1;
          colSpan = 3;
          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_2_YEARS:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_5_YEARS:
          rowSpan = 1;
          break;

        default:
          break;
      }
      break;

    // Header subtitles
    case PERFORMANCE_METRICS_SPREADSHEET_HEADER_SUBTITLE:
      value = PERFORMANCE_METRICS_SPREADSHEET_HEADERS[colNumber].subtitle;
      break;

    case PERFORMANCE_METRICS_SPREADSHEET_RANK_ALIAS:
      {
        const rankValues = getRankExpression({ colNumber, allComparisonsExpression });
        expr = rankValues.expr;
        hidden = rankValues.hidden;
        gridType = 'percentage';
      }

      // Display (for the moment) values on Company Volatility columns
      if (VOLATILITY_COLUMNS_ARRAY.includes(colNumber as ColumnVolatilityYears)) {
        alias = VOLATILITY_YEARS[colNumber as ColumnVolatilityYears];
        hidden = false;
        gridType = 'string';
        format = oneDecimalPercentFormat;
        readOnly = isDisabled;
        isVolatility = true;
        if (colNumber === 9 || colNumber === 10 || colNumber === 11) {
          value = volatilitySelectionMap[colNumber] ?? '';
        }
        dropdown = true;
        useScalarSpreadsheetCell = true;
        dataEditor = (props: any) => <MultipleTypeSelect {...props} performanceCellOptions={cellOptions} />;
        valueViewer = (props: any) => <MultipleTypeValueViewer {...props} performanceCellOptions={cellOptions} />;
      }

      break;
    // Rest of the rows (excluding Header titles and subtitles)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_EBITDA:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_EBITDA:
          format = largeCurrencyFormat;
          gridType = 'number';
          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            expr = '';
          }
          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.LTM_REVENUE_GROWTH:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.GROSS_MARGIN:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.EBITDA_MARGIN:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';

          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            expr = '';
          }
          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.NTM_REVENUE_GROWTH:
          format = oneDecimalPercentFormat;
          gridType = 'percentage';

          NTMRevenueGrowthEvaluation = evaluateNTMRevenueGrowth({ alphabet, alias, expr, rowNumber, value });
          expr = NTMRevenueGrowthEvaluation?.expr ?? expr;
          value = NTMRevenueGrowthEvaluation?.value ?? value;

          break;

        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_1_YEAR:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_2_YEARS:
        case PERFORMANCE_METRICS_SPREADSHEET_COLUMNS.VOLATILITY_5_YEARS:
          format = oneDecimalPercentFormatValidateFloatTrue;
          gridType = 'percentage';
          // The value is in percentage, we only need to apply the format
          value = Number(value ?? 0) / 100;

          if (alias === PERFORMANCE_METRICS_SPREADSHEET_COMPANY) {
            alias = VOLATILITY_YEARS_COMPANY_CELL[colNumber as KeyNumber];
            const cellProps = getCellParamsValue({ valuationsApproachGpc, params, colNumber, customKey });
            customKey = cellProps.customKey;
            value = getExpression({ expr: cellProps.value as string, columnLegend });
            expr = cellProps.expr;
          }
          break;

        default:
          break;
      }
      break;
  }

  return {
    [key]: {
      ...row,
      alias,
      className,
      colSpan,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      key,
      customKey,
      hidden,
      rowSpan,
      value,
      readOnly,
      dataEditor,
      valueViewer,
      isVolatility,
      dropdown,
      useScalarSpreadsheetCell,
      options,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => {
  const { columns, rowConfig, tableData } = params;
  const { isDisabled, customData } = tableData;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const allComparisonsExpression = generateAllRowsExpression({
    rowConfig: rowConfig.filter(row => !row.forceComponent),
    excludedRows: EXCLUDE_TITLE_AND_SUBTITLE,
    excludeLastRow: false,
  });

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          allComparisonsExpression,
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
          isDisabled,
          initialObject: params.initialObject,
          customData,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
