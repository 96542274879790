import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  FINANCIAL_VERSIONS_BY_MD_DEFAULT_ERROR_MESSAGE,
  FINANCIAL_VERSIONS_BY_MD_QUERY_KEY,
} from 'common/constants/services/financials';
import { FinancialStatementService } from 'services';
import { throwError } from 'utilities';
import {
  GetFinancialVersionsByMDHook,
  GetFinancialVersionsByMDParams,
  GetFinancialVersionsByMDResponse,
  UseGetFinancialVersionsByMD,
} from './types';

const getFinancialVersionsByMD = async (params: GetFinancialVersionsByMDParams) => {
  const { measurementDateId } = params;

  if (isNil(measurementDateId)) return null;

  const financialStatementService = new FinancialStatementService();

  try {
    const financialVersios = (await financialStatementService.listByMeasurementPeriod(
      measurementDateId
    )) as GetFinancialVersionsByMDResponse;

    if (!financialVersios) return throwError(FINANCIAL_VERSIONS_BY_MD_DEFAULT_ERROR_MESSAGE);

    return financialVersios;
  } catch (error) {
    return throwError(FINANCIAL_VERSIONS_BY_MD_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetFinancialVersionsByMD: UseGetFinancialVersionsByMD = params => {
  const { measurementDate, shouldQueryAutomatically = true } = params;

  const measurementDateId = measurementDate?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    GetFinancialVersionsByMDHook['data'],
    Error
  >({
    enabled: !isNil(measurementDateId) && shouldQueryAutomatically,
    queryFn: () => getFinancialVersionsByMD({ measurementDateId }),
    queryKey: [FINANCIAL_VERSIONS_BY_MD_QUERY_KEY, measurementDateId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetFinancialVersionsByMD;
