import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash';
import {
  CREATE_NEW_VERSION_DEFAULT_ERROR_MESSAGE,
  CREATE_NEW_VERSION_DEFAULT_SUCCESS_MESSAGE,
  VALUATION_BY_ALLOCATION_QUERY_KEY,
} from 'common/constants/services/valuations';
import { ValuationService } from 'services';
import { throwError } from 'utilities';
import { CreateNewVersionParams, CreateNewVersionResponse } from './types';

const createNewVersion = async (params: CreateNewVersionParams) => {
  const { valuation } = params;

  if (isEmpty(valuation) || isNil(valuation?.id)) return throwError(CREATE_NEW_VERSION_DEFAULT_ERROR_MESSAGE);

  const valuationService = new ValuationService();

  try {
    const updatedValuation = await valuationService.createNewVersion(valuation);

    if (updatedValuation?.id)
      return {
        valuation: updatedValuation,
        responseMessage: CREATE_NEW_VERSION_DEFAULT_SUCCESS_MESSAGE,
      } as CreateNewVersionResponse;

    return throwError(CREATE_NEW_VERSION_DEFAULT_ERROR_MESSAGE);
  } catch (error) {
    return throwError(CREATE_NEW_VERSION_DEFAULT_ERROR_MESSAGE);
  }
};

const useCreateNewVersion = () => {
  const queryClient = useQueryClient();

  const createNewVersionMutation = useMutation<CreateNewVersionResponse, Error, CreateNewVersionParams>({
    mutationFn: params => createNewVersion(params),
  });

  const { data, isError, isLoading, isSuccess, mutateAsync } = createNewVersionMutation;

  if (isSuccess) {
    // Invalidate the queries to refetch the updated data of the Valuation
    queryClient.invalidateQueries({
      queryKey: [
        VALUATION_BY_ALLOCATION_QUERY_KEY, // Valuation by Allocation
      ],
    });

    // Clean the mutation internal state
    createNewVersionMutation.reset();
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    mutateAsync,
  };
};

export default useCreateNewVersion;
