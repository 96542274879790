import { sortBy } from 'lodash';
import { ConfigurationSpreadsheets } from 'common/types/scalarSpreadsheet';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import {
  EQUITY_ALLOCATION_KEY,
  SHEET_CONFIG_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { Configuration } from 'pages/ValuationsAllocation/hooks';
import { getObjectValue } from 'utilities';
import { createColumns } from './createColumns';
import { AfterCellChangedParams, customAfterCellChanged } from './customAfterCellChanged';
import { customParser, EquityAllocationParserParams } from './customParser';
import { customReverseParser, ReverseParserParams } from './customReverseParser';
import { customRowConfig } from './customRowConfig';
import { customValidations } from './customValidations';
import { CreateEquityAllocationConfigurationParams } from './types';

const { EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const createEquityAllocationConfiguration = (params: CreateEquityAllocationConfigurationParams) => {
  const {
    approaches,
    capTableVersions,
    fieldAttributes,
    isDisabled,
    isUniformCurrency,
    riskFreeRates,
    securities,
    updateWeightingProbabilities,
    valuation,
  } = params;

  const { allocation } = getObjectValue(valuation);
  const { allocation_scenarios: allocationScenarios = [] } = getObjectValue(allocation);

  const sortedAllocationScenarios = sortBy(allocationScenarios, 'order');

  const primaryCapTable = capTableVersions?.find(capTable => capTable?.is_primary);

  const getColumns = () =>
    createColumns({
      allocationScenarios: sortedAllocationScenarios,
      approaches,
      isUniformCurrency,
      primaryCapTable,
      riskFreeRates,
      securities,
    });

  const columns = getColumns();

  const rowConfig = customRowConfig({
    isDisabled,
    securities,
  });

  const parser = (parserParams: EquityAllocationParserParams) =>
    customParser({
      ...parserParams,
      allocationScenarios: sortedAllocationScenarios,
      isUniformCurrency,
      securities,
    });

  const afterCellChanged: AfterCellChangedParams = (
    initialChanges,
    cells,
    rowConfigUpdated,
    tableData,
    spreadsheets
  ) => {
    const afterCellChangedParams = {
      spreadsheets,
      cells,
      initialChanges,
      rowConfig: rowConfigUpdated,
      tableData,
    };

    return customAfterCellChanged({
      ...afterCellChangedParams,
      primaryCapTable,
    });
  };

  const reverseParser = (reverseParserParams: ReverseParserParams) =>
    customReverseParser({
      ...reverseParserParams,
      allocationScenarios: sortedAllocationScenarios,
      approaches,
      capTableVersions,
      updateWeightingProbabilities,
    });

  const sheet = new SpreadsheetConfig({
    afterCellChanged: afterCellChanged as unknown as SpreadsheetConfig['afterCellChanged'],
    allowConfirmAndDeleteColumn: !isDisabled,
    allowCopyColumn: false, // if is Final
    allowDeleteColumn: !isDisabled,
    allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
    alwaysDisplayLegend: false,
    columns,
    currencyFormatter: true,
    customValidations: customValidations as unknown as SpreadsheetConfig['customValidations'],
    fieldAttributes,
    format: undefined,
    name: EQUITY_ALLOCATION_KEY,
    page: EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
    parser,
    getColumns,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    rowConfig,
    showPreviousColsDivider: false,
    showTitlesColumn: true,
    showToolbar: true,
    showTotalColumn: false,
    tableData: { approaches },
    tableTerms: EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
    totalParser: undefined,
    unitsFormatter: true,
  }) as unknown as ConfigurationSpreadsheets;

  return {
    isEquityAllocation: true,
    name: EQUITY_ALLOCATION_KEY,
    spreadsheets: {
      [EQUITY_ALLOCATION_KEY]: sheet,
    },
  } as Configuration;
};

export default createEquityAllocationConfiguration;
