import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';
import { textWithEllipsisStyle } from 'utilities';

const ICON_HEIGHT = 24;

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    container: {
      display: 'block',
      position: 'relative',

      '&.title': {
        display: 'inline-block',
        paddingRight: theme.spacing(4),
      },

      '&.link': {
        cursor: 'pointer',
      },
    },

    textContainer: {
      ...textWithEllipsisStyle,
      paddingRight: theme.spacing(2.5),

      '&.dark': {
        color: theme.palette.white,
        fontWeight: 700,
      },
    },
    text: {
      ...textWithEllipsisStyle,
      display: 'inline',
    },
    iconContainer: {
      background: `linear-gradient(90deg, transparent 0%, ${theme.palette.white} 50%)`,
      display: 'flex',
      opacity: 0,
      paddingLeft: theme.spacing(2.5),
      position: 'absolute',
      right: '-100%',
      top: `calc(50% - (${ICON_HEIGHT}px / 2))`,
      transition: 'ease all 0.25s',
      pointerEvents: 'none',

      '&.active': {
        opacity: 1,
        right: 0,
      },

      '&.dark': {
        background: `linear-gradient(90deg, transparent 0%, ${theme.palette.primary['600']} 50%)`,
      },
    },
    icon: {
      fill: `${theme.palette.cellIconColor} !important`,

      '&.dark': {
        fill: `${theme.palette.white} !important`,
      },
    },
  })
);

export default useStyles;
