/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { LedgerTable } from 'components';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import {
  AMOUNT_ALIAS,
  COST_BASIS_ALIAS,
  PURCHASE_DATE_ALIAS,
} from 'pages/CapTable/fund-ownership/components/shares-ledger/constants';
import useExchangeRateMap from 'pages/CapTable/fund-ownership/components/shares-ledger/hooks/useExchangeRateMap';
import { toString } from 'utilities';
import { colConfig } from './colConfig';
import updateCostCellsIfDifferent from '../../../../utilities/updateCostCellsIfDifferent';
import conditions from '../../conditions';

const currencyAdjustedValueViewerCells = [COST_BASIS_ALIAS, AMOUNT_ALIAS];

function updateInvestmentDates(investmentDates, parsedCells, [key, cell]) {
  if (cell.alias === PURCHASE_DATE_ALIAS) {
    const isListed = investmentDates.find(date => toString(date) === toString(cell.value));
    return {
      ...parsedCells[key],
      value: isListed ? cell.value : '',
    };
  }
  return cell;
}

const PurchasedTable = ({
  cell,
  setIsAlertVisible,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  investmentDates,
  isDisabled,
  modeledAfterConvertibleNote,
  fundCurrency,
  isDifferentCurrency,
  selectedMeasurementDate,
  format,
  acquisitionsData,
  setAcquisitionsData,
  purchasedCells,
  setPurchasedCells,
  dividendsCalculator,
  setDeletedAcquisitions,
  reverseParsedAcquisitions,
  isWarrantOrOption,
}) => {
  const emptyRow = modeledAfterConvertibleNote
    ? { purchase_date: null, amount: null, acquisition_ref: uuid(), sales: [], conversions: [] }
    : { purchase_date: null, shares: null, cost_basis: null, acquisition_ref: uuid(), sales: [] };

  const { cells } = useContext(FeaturedSpreadsheetContext);
  const [rows, setRows] = useState();

  const dataSourceKey = useMemo(() => cell.linkedCells.values().next().value.key, [cell.columnLegend]);

  const { exchangeRateMap, linkedCellChanges } = useExchangeRateMap({
    fundCurrency,
    format,
    shares: rows,
    selectedMeasurementDate,
    isDifferentCurrency,
    cellKeys: modeledAfterConvertibleNote ? [AMOUNT_ALIAS] : [COST_BASIS_ALIAS],
    acquisitionsData: reverseParsedAcquisitions,
    setAcquisitionsData,
    dateProp: PURCHASE_DATE_ALIAS,
  });

  const addRow = useCallback(() => {
    const latestAcquisitions = reverseParsedAcquisitions || [];
    setAcquisitionsData(prevState => ({
      ...prevState,
      acquisitions: [...latestAcquisitions, emptyRow],
    }));
  }, [emptyRow, setAcquisitionsData, reverseParsedAcquisitions]);

  const deleteRow = useCallback(
    rowIndex => {
      const tmpData = [...reverseParsedAcquisitions];
      const acquisitionToDelete = tmpData[rowIndex];
      const remaining = tmpData.filter(acq => acq.acquisition_ref !== acquisitionToDelete.acquisition_ref);
      if (acquisitionToDelete.id) {
        setDeletedAcquisitions(prevState => [...prevState, acquisitionToDelete.id]);
      }
      setAcquisitionsData({ ...tmpData, acquisitions: remaining });
    },
    [reverseParsedAcquisitions, setAcquisitionsData, setDeletedAcquisitions]
  );

  const config = useMemo(
    () => colConfig(investmentDates, modeledAfterConvertibleNote, cell.isCustomSecurity, isWarrantOrOption),
    [investmentDates, modeledAfterConvertibleNote, cell.isCustomSecurity, isWarrantOrOption]
  );

  // Set Rows
  useEffect(() => {
    if (cells && dataSourceKey && cells[dataSourceKey] && acquisitionsData?.acquisitions) {
      if (isEmpty(acquisitionsData?.acquisitions)) {
        setRows([]);
      } else {
        /* cells[shares_distribution_ledger_A] doesn't have cost_basis
        if coming from convertible notes */
        const fromExistingOrConvertibleNotesPurchased = acquisitionsData.acquisitions.map(row => ({
          ...row,
          cost_basis: row.cost_basis || row.amount || null,
          shares: row.shares || null,
          dividends_per_share: dividendsCalculator(row.purchase_date),
        }));
        setRows(fromExistingOrConvertibleNotesPurchased || []);
      }
    }
  }, [cells, dataSourceKey, acquisitionsData]);

  // Parse cells
  useEffect(() => {
    if (Array.isArray(rows)) {
      let parsedCells = cellsParser(rows, config);

      // find any of the cells aliased with 'cost_basis' and add a custom component to it
      parsedCells = Object.entries(parsedCells).reduce((acc, [key, parsedCell]) => {
        const updatedCell = updateCostCellsIfDifferent({
          currencyAdjustedValueViewerCells,
          isDifferentCurrency,
          parsedCells,
          cell: parsedCell,
          relatedDateAlias: PURCHASE_DATE_ALIAS,
        });

        return { ...acc, [key]: updatedCell };
      }, {});

      if (investmentDates && !Array.isArray(investmentDates)) {
        parsedCells = {
          ...parsedCells,
          date1: {
            ...parsedCells.date1,
            value: investmentDates,
            readOnly: true,
          },
        };
      } else if (Array.isArray(investmentDates) && investmentDates.length) {
        parsedCells = Object.entries(parsedCells).reduce((acc, [key, parsedCell]) => {
          const updatedCell = updateInvestmentDates(investmentDates, parsedCells, [key, parsedCell]);

          return { ...acc, [key]: updatedCell };
        }, {});
      }

      setPurchasedCells(parsedCells);
    }
  }, [rows, investmentDates, isDifferentCurrency]);

  return (
    <>
      <h4>Purchased</h4>
      <br />
      <LedgerTable
        colConfig={config}
        rows={rows}
        emptyRow={emptyRow}
        tableTerms={{
          tableName: 'Purchased Shares',
          tableSlug: 'purchased-shares',
          columnName: `${modeledAfterConvertibleNote ? 'Note' : 'Shares'}`,
          pluralColumnName: 'Purchased Shares',
        }}
        setIsAlertVisible={setIsAlertVisible}
        disabled={isDisabled}
        addRow={addRow}
        deleteRow={deleteRow}
        cells={purchasedCells}
        setCells={setPurchasedCells}
        data={rows}
        tableData={{ acquisitions: reverseParsedAcquisitions }}
        doFullValidation={doFullValidation}
        setIsValid={setIsValid}
        updateValidationStatus={updateValidationStatus}
        conditions={conditions}
        currency={format.currency}
        linkedCellUpdates={linkedCellChanges}
        exchangeRateMap={exchangeRateMap}
      />
    </>
  );
};

PurchasedTable.propTypes = {
  cell: PropTypes.shape({
    columnLegend: PropTypes.string,
    isCustomSecurity: PropTypes.bool,
    linkedCells: PropTypes.shape({
      values: PropTypes.func,
    }),
  }),
  acquisitionsData: PropTypes.shape({
    acquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setAcquisitionsData: PropTypes.func,
  modeledAfterConvertibleNote: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isWarrantOrOption: PropTypes.bool,
  setIsAlertVisible: PropTypes.func,
  doFullValidation: PropTypes.bool,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  investmentDates: PropTypes.arrayOf(PropTypes.string),
  fundCurrency: PropTypes.string,
  isDifferentCurrency: PropTypes.bool,
  selectedMeasurementDate: PropTypes.shape({}),
  format: PropTypes.shape({
    currency: PropTypes.string,
  }),
  purchasedCells: PropTypes.shape({}),
  setPurchasedCells: PropTypes.func,
  dividendsCalculator: PropTypes.func,
  reverseParsedAcquisitions: PropTypes.arrayOf(PropTypes.shape({})),
  setDeletedAcquisitions: PropTypes.func,
};

export default PurchasedTable;
