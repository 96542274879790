import React from 'react';
import { companySummaryUrl } from 'common/config/urls';
import { Row, Rows } from 'common/types/scalarSpreadsheet';
import { ValueWithLinkViewer } from 'components';
import {
  FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
  FUND_SUMMARY_SPREADSHEET_TABLE,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/Funds/common/constants/fundSummary';
import { getExchangeRateTooltip } from 'pages/Funds/utils';
import { generateColumnKey, getArrayValue, getNumberValue, getStringValue } from 'utilities';
import { CreateCompaniesRowsParams, RowConfigParams } from './types';

const createCompaniesRows = (params: CreateCompaniesRowsParams): Rows => {
  const { companies, firmSlug, queryParams } = params;

  return getArrayValue(
    companies?.map(company => {
      const companyCaptableCurrency = getStringValue(company?.company_captable_currency?.toString());
      const companyCaptableId = getNumberValue(company?.cap_table);
      const companySlug = getStringValue(company?.company_slug);
      let companyName = getStringValue(company?.company_name);

      // Display Company Captable Currency if it's different from Fund currency
      if (company.fund_currency !== company.company_captable_currency)
        companyName = `${companyName} (${companyCaptableCurrency})`;

      // Get Company Summary URL
      const companyUrl = `${companySummaryUrl(firmSlug, companySlug)}${queryParams}`;
      const fundCurrencyValue = getStringValue(company.fund_currency?.toString());
      const exchangeRateTooltip = getExchangeRateTooltip(
        fundCurrencyValue,
        companyCaptableCurrency,
        Number(company.exchange_rate_to_fund_currency ?? 1)
      );

      return {
        alias: generateColumnKey({
          id: companyCaptableId,
          name: companySlug,
          prefix: FUND_SUMMARY_SPREADSHEET_COLUMN_KEY_PREFIX,
        }),
        gridType: 'string',
        readOnly: true,
        value: <ValueWithLinkViewer exchangeRateMessage={exchangeRateTooltip} name={companyName} url={companyUrl} />,
      } as Row;
    })
  );
};

const rowConfig = (params: RowConfigParams): Rows => {
  const { companies, firmInfo, measurementDate } = params;

  const firmSlug = getStringValue(firmInfo?.slug);
  const queryParams = measurementDate?.slug ? `?date=${measurementDate?.slug}` : '';

  return [
    {
      alias: SHEET_ALIASES_CONSTANTS.FUND_SUMMARY_SPREADSHEET_HEADER_TITLE,
      className: `${FUND_SUMMARY_SPREADSHEET_TABLE}-header division-bottom-only`,
      gridType: 'string',
      largeHeader: true,
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.FUND_SUMMARY_SPREADSHEET_HEADERS[0].value,
    } as Row,
    ...createCompaniesRows({ companies, firmSlug, queryParams }),
    {
      alias: SHEET_ALIASES_CONSTANTS.FUND_SUMMARY_SPREADSHEET_TOTAL,
      className: `${FUND_SUMMARY_SPREADSHEET_TABLE}-total division`,
      format: null,
      gridType: 'string',
      readOnly: true,
      value: SHEET_TITLES_CONSTANTS.FUND_SUMMARY_SPREADSHEET_TOTAL_TITLE,
    } as Row,
  ];
};

export default rowConfig;
