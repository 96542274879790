/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { isEmpty, isNull, values } from 'lodash';
import PropTypes from 'prop-types';
import { BENCHMARK_MULTIPLE_VALUE_ALIAS, FINANCIALS_METRIC_VALUE_ALIAS } from 'common/constants/allocations';
import { FIX_BEFORE_CONTINUE } from 'common/constants/messages/validations';
import { MessageBox } from 'components';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { GridSkeleton } from 'components/Grid';
import ScalarDatasheet from 'components/ScalarDatasheet';
import { ValueEditor, ValueViewer } from 'components/Spreadsheet/components';
import Cell from 'components/Spreadsheet/components/Cell';
import { DialogContext, LedgerContext } from 'context';
import { useTableValidation } from 'services/hooks';
import { formatNumbers, objToArray, onCellsChanged } from 'utilities';
import { AddRowButton, DeleteRowButton } from './components';

const useStyles = makeStyles({
  row: {
    '&:hover .delete-button': {
      opacity: 1,
    },
  },
});

const ContentComponent = () => <ConfirmationDelete itemName="row" />;

const LedgerTable = ({
  id,
  cell: parentCell,
  colConfig,
  rowConfig,
  rows,
  tableTerms,
  isAlertVisible,
  setIsAlertVisible,
  showAddRowButton,
  showDeleteColumn,
  cells,
  setCells,
  linkedCellUpdates,
  addRow,
  deleteRow,
  showTotalRow,
  doFullValidation,
  setIsValid,
  updateValidationStatus,
  disabled,
  conditions,
  tableData,
  getKey,
  tableId,
  sharedCells,
  customValidations,
  currencyCode,
  currency,
  addBtnAlign,
  inLedger,
  exchangeRateMap,
}) => {
  const classes = useStyles();
  const { showDialog } = useContext(DialogContext);
  const { validateCells } = useTableValidation();
  // this is what is rendered as cells
  const [ledgerGrid, setLedgerGrid] = useState();

  if (!getKey || (typeof getKey === 'object' && !isNull(getKey) && isEmpty(getKey))) {
    getKey = (alias, colIndex, tId, rowIndex) => `${alias}${rowIndex + 1}`;
  }

  const applyExpressions = async (changes, cells) => {
    const computedCells = await onCellsChanged(changes, cells);
    return computedCells;
  };

  const applyValidations = async (changes, cells) => {
    const tmpCells = { ...cells };
    // Prepare data to be validated
    const cellsToValidate = changes.map(change => cells[change.cell.key]);

    // Validate cells
    const { validatedCells } = await validateCells({
      cellsToValidate,
      tableData,
    });

    // Update cells
    validatedCells.forEach(cell => {
      tmpCells[cell.key] = cell;
    });

    return tmpCells;
  };

  const applyConditions = cells => {
    const tmpState = { ...cells };

    if (cells && conditions) {
      const tmpCells = objToArray(cells).map(([, cell]) => cell);
      tmpCells.forEach(cell =>
        conditions({
          cell,
          tableData,
          parentCell,
          tableCells: tmpState,
          onCellsChanged,
        })
      );
    }
    return tmpState;
  };

  const validateAll = async () => {
    if (updateValidationStatus) {
      // begin validation proccess
      updateValidationStatus(true);
    }

    const tmpCells = { ...cells };

    const cellsToValidate = [];

    rows.forEach((row, rowIndex) => {
      colConfig.forEach((col, colIndex) => {
        const alias = rowConfig.length ? row.alias : col.alias;
        const key = getKey(alias, colIndex, tableId, rowIndex, row.alias);
        const tmpCell = {
          ...col, // Copy config data
          ...cells[key],
        };
        cellsToValidate.push(tmpCell);
      });
    });

    let isValid = true;
    if (cellsToValidate.length > 0) {
      // Validate cells
      const { validatedCells, areCellsValid } = await validateCells({ cellsToValidate, tableData, customValidations });

      isValid = areCellsValid;

      // Update cells
      validatedCells.forEach(cell => {
        tmpCells[cell.key] = cell;
      });
      setCells(state => ({ ...state, ...tmpCells }));
    }

    if (setIsValid) {
      await setIsValid(isValid);
    }
    if (updateValidationStatus) {
      // finish validation proccess
      updateValidationStatus(false);
    }
  };

  const validateAllAsync = async () => {
    if (doFullValidation) {
      try {
        await validateAll();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  // check if we have to validate all cells on the table
  useEffect(() => {
    validateAllAsync();
  }, [doFullValidation]);

  const onCellsChange = async changes => {
    let tmpCells = { ...cells, ...sharedCells };

    // Get linked cell updates, not defined in all ledgers
    const updatedChanges = typeof linkedCellUpdates === 'function' ? linkedCellUpdates(changes, tmpCells) : changes;

    // Apply expressions
    tmpCells = await applyExpressions(updatedChanges, tmpCells);

    // Apply conditions
    tmpCells = applyConditions(tmpCells);
    // decide if it's necessary to re-evaluate expresions
    const metricOrMultipleWasSelected
      = [BENCHMARK_MULTIPLE_VALUE_ALIAS, FINANCIALS_METRIC_VALUE_ALIAS].includes(changes[0].cell.alias)
      && changes[0].col === 0;
    if (metricOrMultipleWasSelected) {
      tmpCells = await applyExpressions(updatedChanges, tmpCells);
    }
    // Apply validations
    tmpCells = await applyValidations(updatedChanges, tmpCells);

    setCells(state => ({ ...state, ...tmpCells }));
  };

  const totalRow = useMemo(() => {
    const getTotal = alias => {
      const cellValues = values(cells).filter(cell => cell.alias === alias && cell.insideLedger) || [];

      return cellValues.reduce((total, item) => {
        total += parseFloat(Number(item.value));
        return total;
      }, 0);
    };

    if (colConfig) {
      return colConfig.map((col, index) => {
        const totalValue = col.hasTotal ? getTotal(col.alias) : '-';
        const value = index === 0 ? 'Total' : totalValue;
        const { valueViewer, ...colProps } = col;

        return {
          ...colProps,
          key: `total${index + 1}`,
          className: 'subtitle align-left',
          readOnly: true,
          forceComponent: false,
          component: null,
          value: formatNumbers({
            value,
            currencyCode,
            format: col.format,
            currency,
          }),
        };
      });
    }
  }, [colConfig, cells]);

  const generateTableGrid = () => {
    let grid = rows.map((row, rowIndex) =>
      colConfig.map((col, colIndex) => {
        let key = '';

        key = getKey(rowConfig.length ? row.alias : col.alias, colIndex, tableId, rowIndex);

        const cell = cells[key] || {};
        const { selectorInLabelColumn } = cell;

        const isNotMetricOrMultipleRow = ![BENCHMARK_MULTIPLE_VALUE_ALIAS, FINANCIALS_METRIC_VALUE_ALIAS].includes(
          row.alias
        );
        const isNotMetricOrMultipleTitleCell = cell.colAlias === 'title' && isNotMetricOrMultipleRow;
        const isMetricOrMultipleValueCell = cell.colAlias === 'value' && !isNotMetricOrMultipleRow;

        const config = getConfigToUse({
          rowConfig,
          shouldUseTitle: isNotMetricOrMultipleTitleCell || isMetricOrMultipleValueCell,
          rowIndex,
          fallbackConfig: col,
        });

        // Copy config data and replace the common properties with cell data
        const configAndCell = { ...config, ...cell };

        const determineIfReadOnly
          = cell?.readOnly || (col?.readOnly && isNotMetricOrMultipleRow) || config?.readOnly || disabled;

        const tmpCell = {
          ...configAndCell,
          currency,
          readOnly: selectorInLabelColumn ? false : determineIfReadOnly,
          // Style can be present either in the cell of in the column config
          currencyCode: configAndCell?.format?.style === 'currency' ? currencyCode : null,
        };
        // Sometimes row will have a value and the cell will not exist yet.
        // It's better than displaying col title as value in some components.
        if (isEmpty(cell) && row[col?.alias]) {
          tmpCell.value = row[col.alias];
        }

        // Add component
        if (tmpCell.component) {
          tmpCell.component = React.cloneElement(tmpCell.component, { cell: tmpCell }, null);
        }

        return tmpCell;
      })
    );

    if (showTotalRow) {
      grid = [...grid, totalRow];
    }
    setLedgerGrid(grid);
  };

  const getConfigToUse = ({ rowConfig, shouldUseTitle, rowIndex, fallbackConfig }) => {
    if (rowConfig.length) {
      if (shouldUseTitle) {
        const { dataEditor, valueViewer, ...rowConfigTitle } = rowConfig[rowIndex];
        return rowConfigTitle;
      }
      return rowConfig[rowIndex];
    }
    return fallbackConfig;
  };

  const tableHeader = useMemo(() => {
    const rowColumns = [...colConfig];

    return rowColumns.map(col => ({
      value: col.value,
      className: `table-header ${col.className || ''}`,
      width: col.width || '150px',
      readOnly: true,
      disableEvents: true,
    }));
  }, [colConfig]);

  useEffect(() => {
    if (isEmpty(cells)) {
      setLedgerGrid([]);
    } else {
      generateTableGrid();
    }
  }, [cells, rows]);

  useEffect(() => {
    if (rows) {
      const tmpCells = applyConditions(cells);
      setCells(state => ({ ...state, ...tmpCells }));
    }
  }, [rows]);

  const renderValue = cell => {
    if (cell.className && cell.className === 'table-header') {
      return cell.value;
    }
    return formatNumbers(cell);
  };

  const ledgerTableContext = useMemo(
    () => ({
      parentCell,
      cells,
      setCells,
      rows,
      colConfig,
      onCellsChange,
      exchangeRateMap,
    }),
    [parentCell, cells, setCells, rows, colConfig, onCellsChange, exchangeRateMap]
  );

  const handleDeleteRow = rowIndex => {
    if (showDeleteColumn) {
      showDialog({
        wrapper: ConfirmationDialog,
        content: ContentComponent,
        actions: [
          {
            label: 'Cancel',
            type: 'cancel',
          },
          {
            label: 'Delete',
            variant: 'contained',
            type: 'danger',
            callback: () => deleteRow(rowIndex),
          },
        ],
      });
    } else {
      deleteRow(rowIndex);
    }
  };

  const Row = ({ row, children }) => (
    <tr className={classes.row}>
      {children}
      {showDeleteColumn && row > 0 && row <= rows.length && !disabled && (
        <td className="delete-row">
          <DeleteRowButton rowIndex={row - 1} deleteRow={handleDeleteRow} />
        </td>
      )}
    </tr>
  );

  if (!ledgerGrid) {
    return (
      <div style={{ minWidth: '500px' }}>
        <GridSkeleton maxRows={3} maxColumns={colConfig.length + 1} />
      </div>
    );
  }

  if (isEmpty(ledgerGrid)) {
    return (
      <MessageBox
        title={`The ${tableTerms.tableName} table has no data yet`}
        action={<AddRowButton addRow={addRow} tableTerms={tableTerms} disabled={disabled} />}
        showIcon={false}
        hasFrame={false}
      />
    );
  }

  return (
    <LedgerContext.Provider value={ledgerTableContext}>
      <Collapse in={isAlertVisible}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsAlertVisible(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {FIX_BEFORE_CONTINUE}
        </Alert>
        <br />
      </Collapse>
      <div id={id}>
        <ScalarDatasheet
          id={id}
          className="white-table-header"
          data={[tableHeader, ...ledgerGrid]}
          dataRenderer={cell => cell.value}
          valueRenderer={renderValue}
          onCellsChanged={onCellsChange}
          valueViewer={ValueViewer}
          dataEditor={ValueEditor}
          rowRenderer={Row}
          cellRenderer={props => <Cell {...props} inLedger={inLedger} />}
        />
      </div>
      {showAddRowButton && !disabled ? (
        <>
          <br />
          <div style={{ textAlign: addBtnAlign }}>
            <AddRowButton addRow={addRow} tableTerms={tableTerms} />
          </div>
        </>
      ) : null}
    </LedgerContext.Provider>
  );
};

LedgerTable.defaultProps = {
  rows: [],
  rowConfig: [],
  isAlertVisible: false,
  showAddRowButton: true,
  showDeleteColumn: true,
  disabled: false,
  hasRowTitles: false,
  ledgerScope: {},
  tableId: '',
  sharedCells: {},
  addBtnAlign: 'left',
};

LedgerTable.propTypes = {
  id: PropTypes.string,
  cell: PropTypes.object,
  colConfig: PropTypes.array,
  rowConfig: PropTypes.array,
  rows: PropTypes.array,
  addRow: PropTypes.func,
  deleteRow: PropTypes.func,
  cells: PropTypes.object,
  setCells: PropTypes.func,
  linkedCellUpdates: PropTypes.func,
  tableTerms: PropTypes.object,
  isAlertVisible: PropTypes.bool,
  setIsAlertVisible: PropTypes.func,
  showAddRowButton: PropTypes.bool,
  showDeleteColumn: PropTypes.bool,
  showTotalRow: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  row: PropTypes.number,
  doFullValidation: PropTypes.bool,
  conditions: PropTypes.func,
  tableData: PropTypes.object,
  setIsValid: PropTypes.func,
  updateValidationStatus: PropTypes.func,
  hasRowTitles: PropTypes.bool,
  ledgerScope: PropTypes.object,
  getKey: PropTypes.func,
  tableId: PropTypes.string,
  sharedCells: PropTypes.object,
  customValidations: PropTypes.func,
  currencyCode: PropTypes.string,
  addBtnAlign: PropTypes.string,
  currency: PropTypes.object,
  inLedger: PropTypes.bool,
  exchangeRateMap: PropTypes.object,
};

export default LedgerTable;
