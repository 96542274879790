import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  SET_READY_FOR_AUDIT_DEFAULT_ERROR_MESSAGE,
  SET_READY_FOR_AUDIT_DEFAULT_SUCCESS_MESSAGE,
  VALUATION_BY_ALLOCATION_QUERY_KEY,
} from 'common/constants/services/valuations';
import ValuationService from 'services/valuations';
import { throwError } from 'utilities';
import { SetReadyForAuditParams, SetReadyForAuditResponse } from './types';

const setReadyForAudit = async (data: SetReadyForAuditParams) => {
  const { valuationId, readyForAuditData } = data;
  const valuationService = new ValuationService();

  try {
    const readyForAudit = await valuationService.setReadyForAudit(valuationId, readyForAuditData);
    return {
      is_ready_for_audit: readyForAudit.is_ready_for_audit,
      responseMessage: SET_READY_FOR_AUDIT_DEFAULT_SUCCESS_MESSAGE,
    };
  } catch (error) {
    return throwError(SET_READY_FOR_AUDIT_DEFAULT_ERROR_MESSAGE);
  }
};

const useSetReadyForAudit = () => {
  const queryClient = useQueryClient();
  const setReadyForAuditMutation = useMutation<SetReadyForAuditResponse, Error, SetReadyForAuditParams>({
    mutationFn: data => setReadyForAudit(data),
  });
  const { data, isError, isLoading, isSuccess, mutateAsync } = setReadyForAuditMutation;

  if (isSuccess) {
    queryClient.invalidateQueries({
      queryKey: [VALUATION_BY_ALLOCATION_QUERY_KEY],
    });
    setReadyForAuditMutation.reset();
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    mutateAsync,
  };
};

export default useSetReadyForAudit;
