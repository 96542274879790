import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Firm } from 'api';
import Logo from 'components/Logo';

const useStyles = makeStyles({
  logo: {
    width: 'auto',
    height: '100%',
    maxHeight: '80px',
  },
  icon: {
    width: '42px',
    height: '42px',
  },
});

type FirmLogoProps = {
  firmInfo: Firm;
  isSidebarOpen: boolean;
};

const FirmLogo = (props: FirmLogoProps) => {
  const { firmInfo, isSidebarOpen } = props;
  const classes = useStyles();

  if (!firmInfo) {
    return isSidebarOpen ? <Logo variation="onlyWord" /> : <Logo variation="small" />;
  }

  const { icon_url: firmIcon = null, logo_url: firmLogo = null } = firmInfo;

  const FirmLogoComponent = firmLogo ? (
    <img className={classes.logo} src={firmLogo} alt="firm logo" />
  ) : (
    <Logo variation="onlyWord" />
  );
  const FirmIconComponent = firmIcon ? (
    <img className={classes.icon} src={firmIcon} alt="firm icon" />
  ) : (
    <Logo variation="small" />
  );

  return isSidebarOpen ? FirmLogoComponent : FirmIconComponent;
};

export default FirmLogo;
