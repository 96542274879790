import React, { useCallback } from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toString } from 'utilities';
import { SymbolHandler } from '../utilities/utilities';

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    height: theme.spacing(3.125),
  },
  container: {
    fontFamily: 'Roboto Condensed',
    border: `1px solid ${theme.palette.gray[300]}`,
    backgroundColor: `${theme.palette.gray[100]}`,
    height: theme.spacing(4.375),
    marginLeft: theme.spacing(1.5),
  },
  padLeft: {
    marginLeft: theme.spacing(3),
  },
  keyContainer: {
    width: theme.spacing(4.375),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '0.75rem',
    paddingBottom: theme.spacing(0.625),
  },
  valueContainer: {
    paddingLeft: theme.spacing(0.5),
    fontSize: '0.875rem',
    cursor: 'copy',
    paddingBottom: theme.spacing(0.625),
    border: 'none',
  },
  fxLabel: {
    paddingRight: theme.spacing(0.5),
    fontWeight: 600,
  },
  value: {
    textTransform: 'uppercase',
  },
}));

const CellReferenceBar = ({ id, cell, referencedValues, matchCurrencyPadding }) => {
  const classes = useStyles();
  const getValue = useCallback(() => {
    if (![undefined].includes(cell.value)) {
      if (cell.hidden) {
        return '';
      }
      if (referencedValues[cell.alias]) {
        if (Array.isArray(cell.value)) {
          const values = [];
          cell.value.forEach(value => {
            values.push(referencedValues[cell.alias][value]);
          });
          return values.join(', ');
        }
        return referencedValues[cell.alias][cell.value];
      }
      if (cell.expr) {
        if (typeof cell.expr === 'object') {
          return JSON.stringify(cell.expr);
        }
        if (cell.sheet && cell.isExpr) {
          const symbolHandler = new SymbolHandler(cell);
          return symbolHandler.getExpressionList();
        }
        return cell.expr;
      }
      return toString(cell.value);
    }
    return '';
  }, [cell, referencedValues]);

  const handleClick = useCallback(() => {
    (async () => {
      try {
        await navigator.clipboard.writeText(getValue());
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, [getValue]);

  return (
    <div id={`${id}__toolbar`} className={classes.toolbarContainer}>
      <Box
        display="flex"
        className={matchCurrencyPadding ? `${classes.container} ${classes.padLeft}` : classes.container}>
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.keyContainer}>
          <span>{cell.key || '-'}</span>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title="Click to copy formula to clipboard" placement="bottom">
            <button type="button" className={classes.valueContainer} onClick={handleClick} onKeyDown={handleClick}>
              <span className={classes.fxLabel}>(fx)</span>
              <span className={classes.value}>{getValue()}</span>
            </button>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};

CellReferenceBar.defaultProps = {
  referencedValues: {},
};

CellReferenceBar.propTypes = {
  id: PropTypes.string.isRequired,
  cell: PropTypes.object.isRequired,
  referencedValues: PropTypes.object,
  matchCurrencyPadding: PropTypes.bool,
};

export default CellReferenceBar;
