/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import * as propTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  IS_ACTIVE,
  VALUATIONS_DEFAULT_CURRENCY,
  VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
} from 'common/constants/valuations';
import { useStore } from 'common/store';
import { Search } from 'pages/Valuations/approaches/guidelinePublicCompanies/AddGpcDialogue/components';
import CompGroupsOptions from 'pages/Valuations/components/CompGroups/components/CompGroupsOptions';
import { PUBLIC_COMPANIES, USE_CAP_IQ_ID, VALUATION, WAS_NOT_FOUND_MD } from 'pages/Valuations/util/constants';
import { getGPCTableRows } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useGetPublicCompList } from 'services/hooks';
import { useGetMeasurementDatesByFirmId } from 'services/hooks/firm';
import { useGetGpc } from 'services/hooks/valuation';
import { worldCurrencies } from 'utilities';
import { dbShortDate } from 'utilities/datesFormats';
import checkCompExistsInTable from './utils/checkCompExistsInTable';
import {
  ADD_COMP,
  ADD_PUBLIC,
  COMPANIES,
  COMPANY,
  COMPANY_NOT_PUBLIC,
  COMPANY_PRESENT_IN_TABLE,
  COMPANY_TICKER,
  EBITDA,
  ENTERPRISE_VALUE_MILLIONS,
  MARKET_CAP_MILLIONS,
  RETRIEVING_COMPANY,
  REVENUE,
  SOME_COMPANIES_NOT_PUBLIC,
  STOCK_PRICE,
  SYMBOL_NOT_FOUND,
} from '../constants';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

// This function kicks in when the user is searching by company or ticker. Since we do not use
// the measurement date in the comp groups page, we need a mechanism to determine if the company
// is public or not. We do this by checking if the measurement date is between the first and last
// pricing dates of the company or if it is still active but has a last pricing date in the past.
const isCompanyPublic = (companyData, measurementDate) => {
  // We subtract 2 weeks because the last pricing date only happen on work days in the USA
  // so if the date is a holiday or a weekend, then the date of the data is the previous workday.
  // Also, in some other trading markets, like China, the market can be closed for as long as about a week.
  // So, we can assume the company is public if it has a last_pricing_date within the last 2 weeks.

  const firstPricingDate = dbShortDate(
    moment(companyData?.gpc?.first_pricing_date?.value.replace('  ', ' '), 'MMM D YYYY hh:mmA', true)
  );
  const lastPricingDate = dbShortDate(
    moment(companyData?.gpc?.last_pricing_date?.value.replace('  ', ' '), 'MMM D YYYY hh:mmA', true)
  );
  const isCompanyActive = companyData?.gpc?.security_active_status?.value === IS_ACTIVE || false;

  // Define date to use
  // measurementDate is used on valuation page and current date is used on comp groups page
  const date = measurementDate?.date || dbShortDate();

  const twoWeeksAgo = dbShortDate(moment().subtract(2, 'w'));

  // check which date will be used: defined date or 2 weeks ago
  const lastDateForComparison = moment(date).isBefore(twoWeeksAgo) ? date : twoWeeksAgo;

  const isBetweenFirstAndLastPricingDates = moment(lastDateForComparison).isBetween(firstPricingDate, lastPricingDate);
  const isAfterOrSameThanFirstPricingDate = moment(lastDateForComparison).isSameOrAfter(firstPricingDate);
  const isLowerOrSameThanLastingDate = moment(lastDateForComparison).isSameOrBefore(lastPricingDate);

  // The company is public when...
  return (
    // The measurement date is between the dates of its first and last pricing date
    isBetweenFirstAndLastPricingDates
    // Or if it is after the last pricing date and is active or last date for comparison
    // is before than last pricing date.
    || (isAfterOrSameThanFirstPricingDate && (isCompanyActive || isLowerOrSameThanLastingDate))
  );
};

const StyledTypography = styled(Typography)({
  paddingBottom: '1em',
  borderBottom: '2px solid black',
  marginBottom: '1.5em',
});

const StyledTableCell = styled(TableCell)({
  width: '5rem',
});

const AddGpcDialog = ({
  onAdd,
  saveCompGroups,
  firmId,
  disabled,
  deleteCompGroup,
  tableData: gpcTableData,
  source,
  currentComps,
  openGpcDialog,
  setOpenGpcDialog,
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const [measurementDates, fetchMeasurementDates] = useGetMeasurementDatesByFirmId();
  const recentMeasurementDates = measurementDates.sort((a, b) => a.date > b.date);
  const [companyData, fetchCompanyData, cleanCompanyData] = useGetGpc();
  const [tableData, setTableData] = useState(companyData);
  const compGroupsRef = useRef(null);
  const [openCompGroupsOptions, setOpenCompGroupsOptions] = useState(false);
  const [
    {
      companyInfo: { financials_currency },
      isShowLoadingProgress,
    },
  ] = useStore();
  const { measurementDate, setAreThereChanges } = useContext(ValuationContext);
  const isValuationPage = source === VALUATION;
  const [publicCompsData, fetchPCFromCompGroup, removeCompsData] = useGetPublicCompList();
  const [multipleTickerSymbols, setMultipleTickerSymbols] = useState([]);
  const [companiesData, setCompaniesData] = useState();
  const [companyWasntPublic, setCompanyWasntPublic] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(COMPANY_TICKER);
  const [searchResultAlreadyPresent, setSearchResultAlreadyPresent] = useState(false);
  const isSearchingBySingleCompany = searchCriteria === COMPANY_TICKER;

  const companiesDataKeys = useMemo(() => (companiesData ? companiesData.map(() => uuid()) : []), [companiesData]);

  const useStyles = makeStyles(theme => ({
    notFound: {
      color: theme.palette.error.pink,
      borderColor: theme.palette.error.pink,
      '& .MuiChip-deleteIcon': {
        color: theme.palette.error.pink,
      },
    },
    companiesContainer: {
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[300]}`,
      minHeight: '8rem',
    },
  }));

  const classes = useStyles();

  const { financialsPeriods } = useContext(ValuationContext);

  const initialValues = {
    companyName: RETRIEVING_COMPANY,
    tableRows: [
      { label: STOCK_PRICE, value: '' },
      {
        label: ENTERPRISE_VALUE_MILLIONS({
          currencyCode: financials_currency || VALUATIONS_DEFAULT_CURRENCY,
          currencySymbol: worldCurrencies[financials_currency] || VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
        }),
        value: '',
      },
      {
        label: MARKET_CAP_MILLIONS({
          currencyCode: financials_currency || VALUATIONS_DEFAULT_CURRENCY,
          currencySymbol: worldCurrencies[financials_currency] || VALUATIONS_DEFAULT_CURRENCY_SYMBOL,
        }),
        value: '',
      },
      { label: REVENUE, value: '' },
      { label: EBITDA, value: '' },
    ],
  };

  const getCompanyError = queriedCompany => (
    <>
      <Typography variant="h3" align="center">{`'${queriedCompany}' ${WAS_NOT_FOUND_MD}`}</Typography>
      <Typography variant="subtitle2" align="justify">
        {USE_CAP_IQ_ID}
        <Typography variant="body1" component="span">
          IQ123456789
        </Typography>
      </Typography>
    </>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setTableData();
    setOpen(false);
    removeCompsData();
    cleanCompanyData();
    setMultipleTickerSymbols([]);
    if (setOpenGpcDialog) setOpenGpcDialog(false);
  }, [removeCompsData, cleanCompanyData, setOpenGpcDialog]);

  const handleToggle = () => {
    setOpenCompGroupsOptions(prevOpen => !prevOpen);
  };

  useEffect(() => {
    fetchMeasurementDates(firmId);
  }, [fetchMeasurementDates, firmId]);

  useEffect(() => {
    if (isUndefined(companyData)) {
      return;
    }
    if (companyData.valid_gpc_results) {
      const compNotPresentInTable = checkCompExistsInTable({
        existingComps: currentComps,
        publicCompsData: {
          results: [companyData.gpc],
        },
      })[0].valid_gpc_results;
      setSearchResultAlreadyPresent(!compNotPresentInTable);

      const isPublic = isCompanyPublic(companyData, measurementDate);
      setCompanyWasntPublic(!isPublic);
      if (!isPublic) {
        setTableData({
          companyName: companyData.gpc.company_name.value,
          measurement_date: measurementDate,
        });
      } else {
        setTableData(getGPCTableRows({ gpcData: companyData.gpc, financialsCurrency: financials_currency }));
      }
    } else {
      setTableData({
        error: getCompanyError(companyData.requested_identifier),
      });
    }
  }, [companyData, currentComps, financials_currency, measurementDate]);

  useEffect(() => {
    if (publicCompsData) {
      const tmpCompaniesData = checkCompExistsInTable({
        existingComps: currentComps,
        publicCompsData,
      });

      const tickersInResponse = tmpCompaniesData.map(company => ({
        ticker: company.gpc.ticker_symbol.value.toUpperCase(),
      }));

      // These are the tickers from the search box
      multipleTickerSymbols.forEach(ticker => {
        if (!tickersInResponse.some(t => t.ticker.includes(ticker.toUpperCase()))) {
          tmpCompaniesData.push({
            gpc: {
              company_name: {
                value: `"${ticker}" ${SYMBOL_NOT_FOUND}`,
              },
            },
            valid_gpc_results: false,
            notFound: true,
          });
        }
      });

      setCompaniesData(tmpCompaniesData);
    }

    return () => setCompaniesData();
  }, [publicCompsData, multipleTickerSymbols, measurementDate, gpcTableData, currentComps]);

  const handleAdd = () => {
    /*
      #1 - Add a single company (GPC approach and comp group tables)
      #2 - Add multiple companies (GPC approach table)
      #3 - Add multiple companies (Comp group table)
    */
    // The user can choose to search by multiple tickers but still enter only one
    if (companyData && multipleTickerSymbols.length <= 1) {
      // #1
      onAdd([companyData]);
    } else if (companiesData) {
      const validCompsData = companiesData.filter(c => c.valid_gpc_results);
      if (companiesData && saveCompGroups) {
        // #2
        saveCompGroups(validCompsData, false, [], null);
      } else if (!isEmpty(companiesData)) {
        // #3
        onAdd([...validCompsData]);
      }
    }
    handleClose();
    if (setAreThereChanges) {
      setAreThereChanges(true);
    }
  };

  const handleDelete = index => {
    const companyToRemove = companiesData?.at(index);
    const allowedCompanies = companiesData?.filter(t => t !== companyToRemove);
    setCompaniesData(allowedCompanies);

    // Clean up comps when the user has deleted all companies manually
    if (allowedCompanies.length === 0) {
      removeCompsData();
    }
  };

  const getArrowIcon = () => {
    if (openCompGroupsOptions) return <ArrowDropUpIcon id="comp-groups-up" />;
    return <ArrowDropDownIcon id="comp-groups-down" />;
  };

  const isAnyCompanyNotPublic = useMemo(() => {
    if (!measurementDate) {
      return true;
    }
    return (publicCompsData?.results?.length || 0) < multipleTickerSymbols.length;
  }, [measurementDate, publicCompsData, multipleTickerSymbols]);

  const isSelectedCompanyNotPublic = useMemo(
    () => !isCompanyPublic(companyData, measurementDate),
    [companyData, measurementDate]
  );

  const shouldDisableAddBtn = useMemo(() => {
    const isCompanyEmptyOrNotPublic
      = isSearchingBySingleCompany && (isEmpty(companyData) || isSelectedCompanyNotPublic);

    const areCompaniesEmpty
      = !isSearchingBySingleCompany && (companiesData?.every(c => !c.valid_gpc_results) || isEmpty(companiesData));

    return isShowLoadingProgress || isCompanyEmptyOrNotPublic || areCompaniesEmpty || searchResultAlreadyPresent;
  }, [
    isSearchingBySingleCompany,
    companyData,
    isSelectedCompanyNotPublic,
    companiesData,
    isShowLoadingProgress,
    searchResultAlreadyPresent,
  ]);

  return (
    <div>
      {isValuationPage ? (
        <>
          <ButtonGroup
            color="primary"
            variant="outlined"
            aria-label="split button"
            style={{
              borderRadius: '2.688rem',
              marginTop: '1.5em',
            }}>
            <Button
              id="add-comparable-company-btn-1"
              onClick={handleClickOpen}
              disabled={isDisabled}
              style={{
                borderBottomLeftRadius: '2.688rem',
                borderTopLeftRadius: '2.688rem',
              }}>
              {ADD_COMP}
            </Button>
            <Button
              ref={compGroupsRef}
              size="small"
              aria-controls={() => {
                if (openCompGroupsOptions) return 'split-button-menu';
                return undefined;
              }}
              aria-expanded={() => {
                if (openCompGroupsOptions) return 'true';
                return undefined;
              }}
              aria-label="select comp groups options"
              aria-haspopup="menu"
              disabled={isDisabled}
              onClick={handleToggle}
              style={{
                borderBottomRightRadius: '2.688rem',
                borderTopRightRadius: '2.688rem',
              }}>
              {getArrowIcon()}
            </Button>
          </ButtonGroup>
          <CompGroupsOptions
            setOpenCompGroupsOptions={setOpenCompGroupsOptions}
            compGroupsRef={compGroupsRef}
            openCompGroupsOptions={openCompGroupsOptions}
            saveCompGroups={saveCompGroups}
            deleteCompGroup={deleteCompGroup}
            tableData={gpcTableData}
            approachType={PUBLIC_COMPANIES}
          />
        </>
      ) : (
        <Button
          id="add-comparable-company-btn-2"
          variant="outlined"
          color="primary"
          endIcon={<AddIcon />}
          onClick={handleClickOpen}
          disabled={disabled}>
          {ADD_COMP}
        </Button>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
        data-testid="addGpcDialog"
        open={open || openGpcDialog}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {ADD_PUBLIC}
        </DialogTitle>
        <Search
          setTableData={setTableData}
          initialValues={initialValues}
          fetchPCFromCompGroup={fetchPCFromCompGroup}
          fetchCompanyData={fetchCompanyData}
          measurementDate={measurementDate || recentMeasurementDates[0] || {}}
          financialsPeriods={financialsPeriods}
          financialsCurrency={financials_currency || VALUATIONS_DEFAULT_CURRENCY}
          multipleTickerSymbols={multipleTickerSymbols}
          setMultipleTickerSymbols={setMultipleTickerSymbols}
          removeCompsData={removeCompsData}
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
        />
        {
          // If the user searches by company or ticker
          isSearchingBySingleCompany ? (
            <>
              {tableData?.companyName && (
                <DialogContent data-testid="company-info" dividers>
                  <Typography variant="h2">{tableData.companyName}</Typography>
                  <StyledTypography>{tableData.ticker}</StyledTypography>
                  {companyWasntPublic || searchResultAlreadyPresent ? (
                    <Typography>
                      {companyWasntPublic
                        && COMPANY_NOT_PUBLIC({
                          company_name: companyData.gpc.company_name.value,
                          measurement_date: companyData.measurement_date,
                        })}
                      {searchResultAlreadyPresent
                        && COMPANY_PRESENT_IN_TABLE({
                          company_name: companyData.gpc.company_name.value,
                        })}
                    </Typography>
                  ) : (
                    <Table aria-label="Guideline Public Company Table" size="small">
                      <TableBody>
                        {tableData.tableRows.map((tableRow, key) => (
                          <TableRow key={`${tableRow.label}-${tableRow.value}`}>
                            <StyledTableCell align="right">
                              <strong data-testid={`company-data-row-value-${key}`}>{tableRow.value}</strong>
                            </StyledTableCell>
                            <TableCell align="left">{tableRow.label}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </DialogContent>
              )}
              {tableData?.error && <DialogContent data-testid="company-info">{tableData.error}</DialogContent>}
            </>
          ) : (
            // If the user search by Enter Multiple Tickers
            <>
              {companiesData && (
                <div style={{ padding: '1rem' }}>
                  <Box width="100%" className={classes.companiesContainer}>
                    <div style={{ padding: '1rem' }}>
                      {companiesData?.map((comp, compIndex) => (
                        <Chip
                          key={companiesDataKeys[compIndex]}
                          variant="outlined"
                          label={comp.gpc.company_name.value}
                          size="small"
                          color={comp.notPublic ? 'default' : 'primary'}
                          deleteIcon={<ClearIcon />}
                          onDelete={() => handleDelete(compIndex)}
                          style={{
                            marginRight: '1rem',
                            marginBottom: '1rem',
                          }}
                          className={comp.valid_gpc_results ? null : classes.notFound}
                        />
                      ))}
                    </div>
                  </Box>
                  {isAnyCompanyNotPublic && (
                    <Typography variant="subtitle2">{SOME_COMPANIES_NOT_PUBLIC(measurementDate)}</Typography>
                  )}
                </div>
              )}
            </>
          )
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            data-testid="add-gpc-company-btn"
            disabled={shouldDisableAddBtn}
            variant="contained"
            color="secondary">
            Add {companiesData?.length > 1 ? COMPANIES : COMPANY}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddGpcDialog.propTypes = {
  onAdd: propTypes.func,
  saveCompGroups: propTypes.func,
  deleteCompGroup: propTypes.func,
  firmId: propTypes.number,
  disabled: propTypes.bool,
  tableData: propTypes.arrayOf(propTypes.shape({})),
  source: propTypes.string,
  openGpcDialog: propTypes.bool,
  setOpenGpcDialog: propTypes.func,
  currentComps: propTypes.arrayOf(
    propTypes.shape({
      company_name: propTypes.string,
      ticker_symbol: propTypes.string,
      cap_iq_id: propTypes.string,
      created_at: propTypes.string,
      deleted_at: propTypes.string,
      id: propTypes.number,
      is_deleted: propTypes.bool,
      updated_at: propTypes.string,
    })
  ),
  isDisabled: propTypes.bool,
};

export default AddGpcDialog;
