/* eslint-disable no-param-reassign */
import React from 'react';
import { range } from 'mathjs';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { weightingPercentFormat, xSmallSuffixFormat } from 'common/formats/formats';
import { SelectValueViewer, withTooltip } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import NumberCheckbox from 'components/FeaturedSpreadsheet/components/NumberMultipleCheckbox';
import rowConfig from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import { GPT_SUMMARY } from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import CompGroupRowHeader from 'pages/Valuations/components/CompGroupRowHeader';
import { TransactionInfoDialog } from 'pages/Valuations/components/TransactionInfoDialog';
import {
  get25thPercentileExpression,
  get75thPercentileExpression,
  getAppliedMultipleExpression,
  getMeanExpression,
  getMedianExpression,
  getVariableExpressions,
} from 'pages/Valuations/util/util';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { generateColorFromString } from 'utilities';

const CompGroupRowHeaderWithTooltip = withTooltip(CompGroupRowHeader);

const getRowConfig = ({ companyName: company_name, approach, allCompGroups, isDisabled, gpcOptions }) => {
  const gpt_transactions = approach?.gpt_transactions || [];
  const currentCompGroups = approach?.valuationapproachptcompgroup_set || [];
  const gptRange = range(1, gpt_transactions.length + 1)
    .map(rowNumber => `@${rowNumber + 2}`)
    .toString()
    .replace(/"/g, '');

  const getMatchingCompGroup = transaction => {
    const compGroupId = transaction.pt_approach_comp_group || transaction.comp_group_id;
    if (compGroupId) {
      const compGroupOfTransaction = currentCompGroups.find(item => {
        const itemId = item.isNew ? item.comp_group : item.id;
        return itemId === compGroupId;
      });
      if (!compGroupOfTransaction) return null;

      const transactionCompGroupInfo = allCompGroups?.find(
        compGroupItem => compGroupItem.id === compGroupOfTransaction?.comp_group
      );

      return {
        id: transactionCompGroupInfo?.id,
        name: transactionCompGroupInfo?.name,
      };
    }
    return null;
  };

  const getCompGroupData = transaction => {
    const transactionCompGroupInfo = getMatchingCompGroup(transaction);
    if (transactionCompGroupInfo) {
      return {
        id: transactionCompGroupInfo.id,
        name: transaction.comp_group_name,
        color: generateColorFromString(transaction.comp_group_name),
      };
    }
    return null;
  };
  const dynamicValueProps = transaction =>
    transaction.comes_from_capital_iq
      ? {
        value: (
          <CompGroupRowHeader
            value={transaction.target_name}
            comparisonData={transaction}
            compGroupData={getCompGroupData(transaction)}
            companyDialogComponent={TransactionInfoDialog}
            allowShowCompanyDialog
          />
        ),
      }
      : {
        valueViewer: props => (
          <CompGroupRowHeaderWithTooltip
            value={transaction.target_name}
            compGroupData={getCompGroupData(transaction)}
            {...props}
          />
        ),
        value: transaction.target_name,
      };

  const transactionCells = gpt_transactions.map((transaction, index) => ({
    alias: transaction.row_ref || transaction.id,
    className: index === 0 ? 'divisionTopOnly' : undefined,
    row_ref: transaction.row_ref,
    readOnly: isDisabled || transaction.comes_from_capital_iq,
    gridType: GRID_NUMBER_CHECKBOX,
    component: <NumberCheckbox />,
    ignoreRowCopy: true,
    hidden: false,
    forceComponent: !isDisabled,
    format: xSmallSuffixFormat,
    allowNegativeValue: true,
    isEditableTitleCell: !transaction.comes_from_capital_iq,
    defaultValue: 0,
    displayNAforNull: transaction.comes_from_capital_iq,
    isGptRow: true,
    ignoreValidations: transaction.comes_from_capital_iq,
    ...dynamicValueProps(transaction),
  }));

  const selectionOptions = getSelectionCellOptions({
    specificApproach: approach,
  });

  const variableExpressions = getVariableExpressions({
    companiesRange: gptRange,
    currentSelectionOptions: selectionOptions,
    numberOfCompanies: gpt_transactions.length,
  });

  const baseRowConfig = rowConfig({ companyName: company_name, approach, allCompGroups, isDisabled });

  const filteredRowConfig = baseRowConfig.filter(row => row.gridType !== GRID_NUMBER_CHECKBOX && !row.hideInGptConfig);

  const firstPercentileSelectionValue = approach?.percentile_selection_a;
  const secondPercentileSelectionValue = approach?.percentile_selection_b;

  const gptRowConfig = filteredRowConfig.map((row, index) => {
    switch (index) {
      case 0:
        // Title
        row.value = 'Target Name';
        break;
      case 2:
        // Median
        row.expr = getMedianExpression(gptRange);
        row.className = 'divisionTopOnly';
        break;
      case 3:
        // Mean
        row.expr = getMeanExpression(gptRange);
        break;
      case 4:
        row.expr = get75thPercentileExpression(gptRange);
        break;
      case 5:
        row.expr = get25thPercentileExpression(gptRange);
        break;
      case 6:
        // 1st percentile selection / Selection A
        row.value = firstPercentileSelectionValue;
        break;
      case 7:
        // 2nd percentile selection / Selection B
        row.value = secondPercentileSelectionValue;
        break;
      case 9:
        // GPT Summary row
        row.alias = GPT_SUMMARY;
        row.value = 'Transaction Comps Summary';
        break;
      case 11:
        // Multiple Type
        row.options = selectionOptions;
        break;
      case 12:
        // Applied Multiple
        row.expr = getAppliedMultipleExpression({
          offset: 11,
          comparisonsLength: gpt_transactions.length,
          variableExpressions,
        });
        break;
      case 13: // Enterprise Value:
        // Revenue (row [11 + gpt_transactions.length]) * Applied Multiple (row [13 + gpt_transactions.length])
        row.expr = `=@${13 + gpt_transactions.length} * @${15 + gpt_transactions.length}`;
        break;
      default:
        break;
    }
    return row;
  });
  const multipleDiscountConfig = {
    alias: 'multiple_discount',
    readOnly: true,
    value: 'NTM Multiple Discount',
    expr: '',
    gridType: 'string',
    format: { ...weightingPercentFormat, isDecimal: false },
    ignoreRowCopy: true,
    className: 'divisionTopOnly',
  };
  const gpcApproachConfig = {
    alias: 'gpc_multiple_discount',
    readOnly: isDisabled,
    value: 'Public Comps Approach',
    dbType: 'number',
    dropdown: true,
    ignoreRowCopy: true,
    defaultValue: null,
    dataEditor: props => (
      <GridSelect
        menuPosition="fixed"
        options={gpcOptions}
        objectOptions={{ displayKey: 'name', valueKey: 'id' }}
        {...props}
      />
    ),
    valueViewer: props => <SelectValueViewer options={gpcOptions} {...props} />,
  };
  gptRowConfig.splice(2, 0, ...transactionCells);
  const multipleDiscountConfigInsertionIndex = transactionCells.length + 2;
  gptRowConfig.splice(multipleDiscountConfigInsertionIndex, 0, gpcApproachConfig, multipleDiscountConfig);
  return gptRowConfig;
};

export default getRowConfig;
