import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS } from 'common/constants/services';
import {
  CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_DEFAULT_ERROR_MESSAGE,
  CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_QUERY_KEY,
} from 'common/constants/services/capTables';
import { CaptableService } from 'services';
import { throwError } from 'utilities';
import {
  CheckMeasurementDateByCapTableHook,
  CheckMeasurementDateByCapTableParams,
  CheckMeasurementDateByCapTableResponse,
  UseCheckMeasurementDateByCapTable,
} from './types';

const CheckMeasurementDateByCapTable = async (params: CheckMeasurementDateByCapTableParams) => {
  const { capTableId } = params;

  if (isNil(capTableId)) return null;

  const capTableService = new CaptableService();

  try {
    const hasMeasurementDate = (await capTableService.hasMeasurementDates(
      capTableId
    )) as CheckMeasurementDateByCapTableResponse['response'];

    if (!hasMeasurementDate) return throwError(CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_DEFAULT_ERROR_MESSAGE);

    return hasMeasurementDate;
  } catch (error) {
    return throwError(CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_DEFAULT_ERROR_MESSAGE);
  }
};

const useCheckMeasurementDateByCapTable: UseCheckMeasurementDateByCapTable = params => {
  const { capTable, shouldQueryAutomatically = true } = params;

  const capTableId = capTable?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<
    CheckMeasurementDateByCapTableHook['data'],
    Error
  >({
    enabled: !isNil(capTableId) && shouldQueryAutomatically,
    queryFn: () => CheckMeasurementDateByCapTable({ capTableId }),
    queryKey: [CHECK_MEASUREMENT_DATE_BY_CAP_TABLE_QUERY_KEY, capTableId],
    refetchOnWindowFocus: SERVICES_VALUATIONS_REFETCH_ON_WINDOW_FOCUS,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useCheckMeasurementDateByCapTable;
