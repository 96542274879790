import React, { useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useMDContext from 'context/MDContext';
import { gridShortDate, nextQuarterEndDate } from 'utilities/datesFormats';
import CompanyList from './CompanyList';
import FundList from './FundList';
import getSuggestedQuarterFiscalEndDate from '../utilities/getSuggestedQuarterFiscalEndDate';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  suggestedDate: {
    fontSize: '12px',
    margin: 0,
  },
}));

const CompaniesSelector = ({ activeCompany }) => {
  const classes = useStyles();
  const { measurementDate, setMeasurementDate, errors } = useMDContext();
  const [date, setDate] = useState(measurementDate || null);

  const nextQuarterEnd = nextQuarterEndDate();
  let suggestedQuarterFiscalEnd = '';
  if (activeCompany) {
    suggestedQuarterFiscalEnd = getSuggestedQuarterFiscalEndDate(activeCompany.fiscal_year_end);
  }
  const handleChange = date => {
    if (date) {
      setDate(gridShortDate(date));
    }
  };
  const handleAccept = date => {
    setMeasurementDate(date);
  };

  const handleFocus = () => {
    if (!date) {
      setDate(gridShortDate());
    }
  };

  const handleBlur = event => {
    if (measurementDate !== event.target.value) {
      handleAccept(event.target.value);
    }
  };

  const onKeyDown = e => {
    // On Enter
    if (e.which === 13) {
      handleAccept(e.target.value);
    }
  };

  const handleClickSuggested = suggDate => {
    setDate(suggDate);
    handleAccept(suggDate);
  };

  const SuggestedDate = ({ suggestedDate }) => (
    <Button className={classes.suggestedDate} onClick={() => handleClickSuggested(suggestedDate)}>
      <small>Suggested: {suggestedDate}</small>
    </Button>
  );

  SuggestedDate.propTypes = {
    suggestedDate: PropTypes.string,
  };

  return (
    <>
      <Grid item xs={6}>
        <KeyboardDatePicker
          value={date}
          format="MM/dd/yyyy"
          onChange={handleChange}
          onAccept={handleAccept}
          onFocus={handleFocus}
          onBlur={handleBlur}
          openTo={date ? 'date' : 'year'}
          views={['year', 'month', 'date']}
          margin="normal"
          className={classes.fullWidth}
          id="new-measurement-date"
          label="Measurement Date"
          KeyboardButtonProps={{
            'aria-label': 'Select date',
          }}
          onKeyDown={onKeyDown}
          helperText={errors.date}
          error={!!errors.date}
          emptyLabel="SELECT DATE"
        />
        {!isEmpty(suggestedQuarterFiscalEnd) ? (
          <SuggestedDate suggestedDate={suggestedQuarterFiscalEnd} />
        ) : (
          <SuggestedDate suggestedDate={nextQuarterEnd} />
        )}
      </Grid>
      <Grid
        container
        classes={{
          root: classes.marginTop,
        }}
        spacing={4}>
        <Grid xs={6} container item>
          <FundList />
        </Grid>
        <Grid xs={6} container item>
          <CompanyList />
        </Grid>
      </Grid>
    </>
  );
};

export default CompaniesSelector;

CompaniesSelector.propTypes = {
  activeCompany: PropTypes.object,
};
