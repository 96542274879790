import { BLANK_VALUE } from 'common/constants/general';
import { Cell } from 'common/types/scalarSpreadsheet';
import isExpression from 'utilities/isExpression';

interface Change {
  cell: Cell;
  value: string;
}

const sanitizeCellValue = (change: Change) => {
  // If the new value is an empty string and the cell has a valid expression
  // we need to set the value to the expression to prevent the cell from
  // displaying the formula as a string.
  if (change.value === BLANK_VALUE && isExpression(String(change.cell.defaultExpr || ''))) {
    return { ...change, value: change.cell.defaultExpr } as Change;
  }
  return change;
};

export default sanitizeCellValue;
