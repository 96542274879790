import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import { SimpleFinancialStatementPeriod } from 'api';
import { MessageBox } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { getThresholdDate } from 'pages/Valuations/util/util';
import createColumns from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcf/config/createColumns';
import getNolColumns from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/dcfNOLCarryover/config/createColumns';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getObjectValue, getStringValue } from 'utilities';
import template from './dcf/config/template';
import { DCFTableData, DCFTableProps } from './types';
import { CustomDCFFinancialPeriod } from './utilities/types';

const DCFTable = (props: DCFTableProps) => {
  const { format, formatDispatch, spreadsheets, onChange, workbook } = props;
  const { dcf: dcfSpreadsheet } = getObjectValue(spreadsheets);
  const tableData = dcfSpreadsheet.tableData as DCFTableData;
  const { approach, isDisabled } = tableData;
  const { valuations_approach_dcf } = approach;
  const { dcf_financial_period } = valuations_approach_dcf;
  const MIN_COLUMNS_ALLOWED = 2;

  const { measurementDate, financialStatementPeriods, resetConfiguration, companyMeasurementDate }
    = useContext(ValuationContext);

  const thresholdDate = getThresholdDate(companyMeasurementDate);

  const addYear = useCallback(async () => {
    const lastPeriod = dcfSpreadsheet.columns.at(-1) as CustomDCFFinancialPeriod | undefined;
    const lastPeriodDate = lastPeriod ? lastPeriod.date : moment(measurementDate?.date).subtract(1, 'year');
    const newPeriodDate = moment(lastPeriodDate).add(1, 'year');
    const formatedDate = newPeriodDate.format('YYYY-MM-DD');

    const updatedColumns = createColumns({
      financialPeriods: dcfSpreadsheet?.columns,
      thresholdDate,
      isAddingNewYear: true,
    });

    const columnId = uuid();

    const newColumn = {
      ...template,
      columnId,
      date: formatedDate,
      year: newPeriodDate.year(),
      valuation_approach_dcf: valuations_approach_dcf.id,
      isDeleteableColumn: true,
    } as CustomDCFFinancialPeriod;
    const relatedStatement = financialStatementPeriods?.find(fsp => fsp.statement_date === formatedDate);
    if (relatedStatement) {
      newColumn.financial_statement_period = relatedStatement as SimpleFinancialStatementPeriod;
      newColumn.financial_statement_period_id = relatedStatement.id;
    } else {
      newColumn.fsp_year = newColumn.year;
    }

    updatedColumns.push(newColumn);

    tableData.approach.valuations_approach_dcf.dcf_financial_period = updatedColumns;

    await dcfSpreadsheet.reset({ columns: updatedColumns });

    await spreadsheets.dcfNol.reset({ columns: getNolColumns({ approach, thresholdDate }) });

    if (resetConfiguration) {
      resetConfiguration();
    }
  }, [
    approach,
    dcfSpreadsheet,
    financialStatementPeriods,
    measurementDate,
    resetConfiguration,
    spreadsheets.dcfNol,
    thresholdDate,
    valuations_approach_dcf.id,
    tableData.approach,
  ]);

  const deleteColumn = useCallback(
    async (columnIndex, __columnId, __wb, cb) => {
      if ((dcfSpreadsheet.columns as CustomDCFFinancialPeriod[]).length === MIN_COLUMNS_ALLOWED) {
        return;
      }

      const column = dcfSpreadsheet.columns[columnIndex] as CustomDCFFinancialPeriod;
      const filteredPeriods = dcf_financial_period?.filter(({ columnId }) => columnId !== column.columnId);

      const columns = createColumns({ financialPeriods: filteredPeriods, thresholdDate });

      const deletedId = column.id;
      let { deleted_dcf_financial_periods } = valuations_approach_dcf;

      if (deletedId) {
        deleted_dcf_financial_periods = deleted_dcf_financial_periods
          ? [...deleted_dcf_financial_periods, deletedId]
          : [deletedId];
      }

      tableData.approach.valuations_approach_dcf = {
        ...valuations_approach_dcf,
        deleted_dcf_financial_periods,
        dcf_financial_period: filteredPeriods,
      };

      await dcfSpreadsheet.reset({ columns });

      await spreadsheets.dcfNol.reset({ columns: getNolColumns({ approach, thresholdDate }) });

      if (cb) cb();

      if (resetConfiguration) {
        resetConfiguration();
      }
    },
    [
      approach,
      dcfSpreadsheet,
      dcf_financial_period,
      resetConfiguration,
      spreadsheets.dcfNol,
      thresholdDate,
      valuations_approach_dcf,
      tableData.approach,
    ]
  );

  const dcfTableSheetProps = {
    ...dcfSpreadsheet,
    sheet: dcfSpreadsheet,
    key: getStringValue(dcfSpreadsheet?.name),
    workbook,
    onChange,
    format,
    formatDispatch,
    className: 'valuations-dcf',
    deleteColumn,
  } as unknown as typeof ScalarSpreadsheet;

  return (
    <div style={{ width: '100%' }}>
      {!dcfSpreadsheet.columns?.length && (
        <MessageBox title="No columns have been added yet" tagline="Please add a year to continue" fullWidth={false} />
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore: ScalarSpreadsheet is not typed */}
      <ScalarSpreadsheet {...dcfTableSheetProps} />
      <ExtendedFabButton
        id="financials-dcf-table-add-year-btn"
        label="Add Year"
        notFixed
        onClick={addYear}
        btnStyle={{
          marginTop: '1.5em',
          float: 'right',
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default DCFTable;
