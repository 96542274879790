import { getObjectValue } from 'utilities';
import { OnMeasurementDateChange } from './types';

const onMeasurementDateChange: OnMeasurementDateChange = params => {
  const { event, measurementDates, setCommonMeasurementDate, setSelectedMeasurementDate, updateDateOnQueryString }
    = params;

  const measurementDateId = event.target.value;

  if (measurementDateId) {
    const selectedMD = getObjectValue(
      measurementDates?.find(measurementDate => measurementDate.id === Number(measurementDateId))
    );

    const { date, slug } = selectedMD;

    if (date && slug) {
      setSelectedMeasurementDate(selectedMD);
      updateDateOnQueryString({ date: slug });

      if (setCommonMeasurementDate) setCommonMeasurementDate(date);
    }
  }
};

export default onMeasurementDateChange;
