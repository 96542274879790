import React, { useCallback, useMemo, useState } from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { read, utils } from 'xlsx';
import UsersTemplate from 'assets/files/UsersTemplate.xlsx';
import { CREATE_USERS, DOWNLOAD_TEMPLATE, FILE_MAX_SIZE_MSG, UPLOAD_TEMPLATE_MSG } from 'common/constants/user';
import { DropZone, MessageBox } from 'components/';
import areAllObjectsValid from 'utilities/areAllObjectsValid';
import ActionButtonGroup from './ActionButtonGroup';
import ActionDialog from './ActionDialog';
import InviteUserLedger from './InviteUserLedger';

const useStyles = makeStyles(theme => ({
  textSuccess: {
    color: theme.palette.secondary[900],
  },
  textError: {
    color: theme.palette.error.main,
  },
  mimicLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));

const ActionDialogPicker = ({
  dialogUserData,
  handleDialogClose,
  firm,
  handleAddUserToFirm,
  selectedCompany,
  companyList,
  fundList,
  company,
}) => {
  const classes = useStyles();

  const [newUsers, setNewUsers] = useState();

  const onLoad = event => {
    try {
      const bufferArray = event.target.result;
      const wb = read(bufferArray, { type: 'buffer' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      if (areAllObjectsValid({ objects: utils.sheet_to_json(ws), keys: ['email', 'first_name', 'last_name'] })) {
        setNewUsers(utils.sheet_to_json(ws));
      } else {
        setNewUsers([]);
      }
    } catch (error) {
      setNewUsers([]);
    }
  };

  const readFile = async file => {
    const fileReader = new FileReader();
    fileReader.onload = onLoad;
    fileReader.readAsArrayBuffer(file);
  };

  const displayMessageBox = useCallback(() => {
    if (newUsers?.length > 0)
      return (
        <MessageBox
          title=""
          action={
            <div className={classes.textSuccess}>
              <span>{`You are uploading ${newUsers?.length} users. Click `}</span>
              <span
                style={{
                  fontWeight: 'bold',
                }}>
                {CREATE_USERS}
              </span>
              <span> to confirm.</span>
            </div>
          }
          type="success"
          fullWidth={false}
          hasFrame={false}
        />
      );
    return (
      <MessageBox
        title=""
        action={
          <div className={classes.textError}>
            <span>The file you are trying to upload is invalid. Click </span>
            {/* Reset newUsers to display the drop zone again */}
            <Typography variant="inherit" className={classes.mimicLink} onClick={() => setNewUsers(undefined)}>
              here
            </Typography>
            <span> to try again.</span>
          </div>
        }
        type="error"
        fullWidth={false}
        hasFrame={false}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUsers]);

  const resetDropzone = useCallback(() => {
    handleDialogClose();
    setNewUsers(undefined);
  }, [handleDialogClose]);

  const titleArray = [
    ['Drag & Drop', null],
    ['or', null],
    ['Select a file', { textDecoration: 'underline' }],
  ];

  const dialog = useMemo(() => {
    const { type } = dialogUserData;
    const actionDialogs = {
      bulkAdd: () => (
        <ActionDialog
          title="BULK ADD USERS"
          subtitle={firm.name}
          handleDialogClose={resetDropzone}
          open={dialogUserData.open}>
          <div style={{ marginBottom: '1rem' }}>
            <Typography variant="body2">{UPLOAD_TEMPLATE_MSG}</Typography>
            <Link href={UsersTemplate} variant="body2" download="UsersTemplate">
              {DOWNLOAD_TEMPLATE}
            </Link>
          </div>
          {isUndefined(newUsers) ? (
            <DropZone
              setFileSrc={readFile}
              subTitle={FILE_MAX_SIZE_MSG}
              acceptFileTypes="application/*"
              validFileTypes={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ]}
              maxSizeAllowedInMb={1}
              titleArray={titleArray}
            />
          ) : (
            displayMessageBox()
          )}
          <ActionButtonGroup
            handleDialogClose={resetDropzone}
            createButtonText={CREATE_USERS}
            disableCreate={isUndefined(newUsers) || newUsers.length === 0}
            firm={firm}
            selectedCompany={selectedCompany}
            newUsers={newUsers}
            companyList={companyList}
            fundList={fundList}
          />
        </ActionDialog>
      ),
      inviteUser: () => (
        <ActionDialog title="INVITE USER" handleDialogClose={handleDialogClose} open={dialogUserData.open}>
          <div />
          <InviteUserLedger
            dialogUserData={dialogUserData}
            handleDialogClose={handleDialogClose}
            handleAddUserToFirm={handleAddUserToFirm}
            isCompanyInvite={company?.id !== undefined}
          />
        </ActionDialog>
      ),
    };
    return actionDialogs[type] ? actionDialogs[type]() : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogUserData, firm, resetDropzone, newUsers]);

  return dialogUserData.open && dialog;
};

ActionDialogPicker.propTypes = {
  dialogUserData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
  }),
  handleDialogClose: PropTypes.func.isRequired,
  firm: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  handleAddUserToFirm: PropTypes.func.isRequired,
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.number.isRequired,
      company_name: PropTypes.string.isRequired,
    })
  ),
  fundList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default ActionDialogPicker;
