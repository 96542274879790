/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-case-declarations */
import { isUndefined, mapValues } from 'lodash';
import { all, create } from 'mathjs';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import * as c from './constants';

const totalParser = ({ cells, columns, rows }) => {
  const oneDecimalCells = [c.INTEREST_RATE_ROW, c.COMPOUNDING_PERIODS_PER_YEAR_ROW, c.CONVERSION_DISCOUNT_ROW];
  let totalsRow = [];
  const totalColumns = columns.length;
  const alphabet = alphabetGenerator([], totalColumns);
  const math = create(all);

  const scope = mapValues(cells, cell => {
    // eslint-disable-next-line no-restricted-globals
    if (isUndefined(cell) || isNaN(cell.value)) return 0;
    return parseFloat(Number(cell.value));
  });

  const sumExpr = rowIndex => columns.map((col, colIndex) => alphabet[colIndex] + rowIndex); //

  rows.forEach(({ alias, hasTotal, format }, rowIndex) => {
    const columnLegend = alphabet[0];
    const rowIndexAux = rowIndex + 1;
    const key = columnLegend + rowIndexAux;
    const baseExpr = `=sum(${sumExpr(rowIndexAux)})`;
    const totalExpr = rowIndexAux === c.CONVERSION_PRICE_PER_SHARE_ROW ? `${baseExpr}/${totalColumns}` : baseExpr;

    const colFormat = format?.style;
    const gridFormat = colFormat && 'number';

    const fractionDigits = oneDecimalCells.includes(rowIndexAux) ? 1 : 0;

    const cell = {
      hidden: true,
      readOnly: true,
      alias,
      dataSourceKey: '',
      gridType: gridFormat,
      className: 'total-cell',
      format: {
        style: colFormat,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      },
      expr: hasTotal && totalExpr,
    };

    if (cell.expr) {
      cell.value = math.evaluate(cell.expr.substring(1), scope) || 0;
    } else {
      cell.value = null;
    }

    totalsRow = {
      ...totalsRow,
      [key]: cell,
    };
  });
  return totalsRow;
};

export default totalParser;
