import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getThresholdDate } from 'pages/Valuations/util/util';
import reverseParser from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/reverseParser';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getObjectValue } from 'utilities';
import createColumns from './config/createColumns';
import getParser from './config/parser';
import rowConfig from './config/rowConfig';
import { ColumnsData, CreateDCFNOLCarryoverDataProps, ParserProps } from './config/types';

const createDCFNOLCarryoverData = (props: CreateDCFNOLCarryoverDataProps) => {
  const { approach, mainTableName, dcfProps, dcfAttributes, isDisabled } = props;
  const { companyMeasurementDate } = getObjectValue(dcfProps);
  const thresholdDate = getThresholdDate(companyMeasurementDate);
  const name = getApproachTableName({ approach, tableSuffix: 'nol' });

  const getColumns = () => createColumns({ approach, thresholdDate });
  const columns: ColumnsData[] = getColumns();

  const parser = (parserProps: ParserProps) => {
    const { columns, rowConfig } = parserProps;
    return getParser({ columns, rowConfig, mainTableRefence: mainTableName, isDisabled });
  };

  return new SpreadsheetConfig({
    name,
    tableData: { approach },
    rowConfig,
    columns,
    reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
    parser,
    showToolbar: true,
    currencyFormatter: true,
    unitsFormatter: true,
    hasColTitle: true,
    showTotalColumn: false,
    linkCurrencyChips: true,
    tableTerms: {
      tableSlug: 'dcf-nol-ledger',
    } as unknown as SpreadsheetConfig['tableTerms'],
    fieldAttributes: dcfAttributes,
    page: 'financials' as unknown as SpreadsheetConfig['page'],
    format: undefined,
    getColumns,
    totalParser: undefined,
  });
};

export default createDCFNOLCarryoverData;
