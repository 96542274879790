import { each, isUndefined } from 'lodash';
import { securityTypes } from 'common/constants';
import { SECURITY_NAME_ALIAS, SECURITY_TYPE_ROW_NUMBER } from 'common/constants/cap-table';
import { toInt } from 'utilities';

const underlyingType = {
  [securityTypes.OPTION]: securityTypes.COMMON_STOCK,
  [securityTypes.WARRANT]: securityTypes.PREFERRED_STOCK,
};

export const securityTypeValue = (columnLegend, cells) => {
  const securityTypeCell = cells[columnLegend + SECURITY_TYPE_ROW_NUMBER];
  return securityTypeCell && toInt(securityTypeCell.value);
};

export const getRequiredSecurityName = parentSecurityType => {
  const securityTypeIndex = underlyingType[parentSecurityType] - 1;
  return securityTypes.LIST[securityTypeIndex];
};

export const getSecurityType = (secRef, cells) => {
  let result;

  each(cells, cell => {
    if (cell.alias === SECURITY_NAME_ALIAS && cell.securityRef === secRef) {
      const securityType = cells[cell.columnLegend + SECURITY_TYPE_ROW_NUMBER];

      if (securityType?.value) {
        result = securityType.value;
      }
    }
  });

  return result;
};

export const getOptions = ({ securityType, cells }) => {
  const securityNames = [];

  each(cells, cell => {
    const validCell = cell && cell.alias === SECURITY_NAME_ALIAS;
    const validColumn = !isUndefined(cell.columnId);

    if (validCell && validColumn) {
      const { securityRef: id, value: name, columnLegend: legend } = cell;
      const columnLegend = legend || '';
      const cellSecurityType = securityTypeValue(columnLegend, cells);

      const isPreferredOrCommon = [securityTypes.PREFERRED_STOCK, securityTypes.COMMON_STOCK].includes(
        cellSecurityType
      );

      const isCommon = cellSecurityType === securityTypes.COMMON_STOCK;

      if (
        (securityType === securityTypes.WARRANT && isPreferredOrCommon)
        || (securityType === securityTypes.OPTION && isCommon)
      ) {
        securityNames.push({ id, name, columnLegend });
      }
    }
  });

  return securityNames;
};

export default getOptions;
