import { removeClassName } from 'utilities';
/**
 * @function
 * @name syncCell
 * @description Synchronized cells have a 'controller' cell. So when a cell that has a controller changes, the other controlled cell is updated
 * @param {array} newChanges  - changes to push for updates
 * @param {object} currentChanges - what changed
 * @param {object} analyzedChange - one of the things that changed
 * @param {object} cells - all cells in the current spreadsheet
 */

const syncCell = ({ cells, currentChanges, newChanges, analyzedChange, updateTotalCellExpr }) => {
  const { cell: analyzedCell, value } = analyzedChange;
  const controllerKey = Object.keys(cells).find(
    key => key.includes(`_${analyzedCell.key}`) && key !== analyzedCell.key && key.includes('controller')
  );
  if (controllerKey) {
    const triggerCell = cells[controllerKey];
    const [first, second] = controllerKey.split('_').slice(1);
    const firstCell = cells[first];
    const secondCell = cells[second];
    if (firstCell && secondCell) {
      firstCell.expr = `=${controllerKey}`;
      secondCell.expr = `=${firstCell.key}`;
      // remove warning class since trigger cell was updated.
      firstCell.needs_actualization = false;
      secondCell.needs_actualization = false;
      firstCell.className = removeClassName(firstCell.className, 'warning');
      secondCell.className = removeClassName(secondCell.className, 'warning');

      currentChanges.shift(); // remove originally changed cell from changes
      if (newChanges.length) {
        // get index of repeated cell
        const indexOfChangedCell = newChanges.findIndex(({ cell }) => cell.key === secondCell.key);
        newChanges.splice(indexOfChangedCell);
        // upstream changes left the trigger cell as the last change but we want it as first
        newChanges.pop();
        newChanges.unshift({ cell: triggerCell, value });
      } else {
        currentChanges.push({ cell: triggerCell, value });
      }
      const finalChanges = newChanges.length ? newChanges : currentChanges;
      // Set expr on cells when related cell is changed and has a valid value
      updateTotalCellExpr({ cells, change: { cell: triggerCell, value }, newChanges: finalChanges });
    }
  }
};

export default syncCell;
