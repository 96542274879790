import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { TASK_COMPLETION, TIMEZONE } from 'common/constants/firms';
import MeasurementDateHeader from 'layouts/TaskLayout/components/MeasurementDateHeader';
import CircularProgressWithLabel from 'pages/RequestDocuments/components/CircularProgressWithLabel';
import { formatDateTime, getStringValue } from 'utilities';
import { TaskProgressModalContentHeaderProps } from './types';

const TaskProgressModalContentHeader: FC<TaskProgressModalContentHeaderProps> = props => {
  const { lastInteraction, measurementDateName, percentageOfProgress, taskDescription } = props;

  const lastInteractionDate = new Date(getStringValue(lastInteraction?.toString()));
  const measurementDateFormatted = moment(getStringValue(measurementDateName?.toString())).toDate();
  const timeZone = TIMEZONE;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgressWithLabel progress={percentageOfProgress} />
      </Grid>
      <Grid
        item
        xs={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '1rem',
          }}>
          {TASK_COMPLETION}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '0.875rem',
          }}>
          {taskDescription}
        </Typography>
        {lastInteraction && (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '0.75rem',
              color: '#888888',
              textTransform: 'uppercase',
              marginTop: '0.5rem',
            }}>
            Last interaction: {formatDateTime(lastInteractionDate, { timeZone })} GMT-5
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <MeasurementDateHeader date={measurementDateFormatted.toISOString()} />
      </Grid>
    </Grid>
  );
};

export default TaskProgressModalContentHeader;
