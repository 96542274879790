import { LAST_TWELVE_MONTHS, NEXT_TWELVE_MONTHS } from 'common/constants/financials';
import {
  COMPANY,
  LTM_EBITDA,
  LTM_REVENUE,
  ltmColumnLetters,
  MEAN_ALIAS,
  MEDIAN_ALIAS,
  NTM_EBITDA,
  NTM_REVENUE,
  ntmColumnLetters,
  PERCENTILE_25,
  PERCENTILE_75,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  SELECTED_MULTIPLE,
  SELECTED_MULTIPLE_VALUE,
  SELECTION,
  SEVENTY_FIFTH_ALIAS,
  SPECIFIED_LABEL,
  TWENTY_FIFTH_ALIAS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import { getFinancialPeriodValue } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utilities';
import { getNumberValue } from 'utilities';

const companyFields = [LTM_REVENUE, LTM_EBITDA, NTM_REVENUE, NTM_EBITDA];

const afterLTMNTMCellChanged = ({ valuations_approach_gpc, cell, isLTM, financialsPeriods, value, changes, cells }) => {
  let comparisonRow = 3;
  const columnLettersToParse = isLTM ? ltmColumnLetters : ntmColumnLetters;
  valuations_approach_gpc.gpc_comparison.forEach(comparison => {
    let financialPeriodValue = cell;
    if (comparison.calendar_years_financials) {
      columnLettersToParse.forEach(legend => {
        const type = ['E', 'G'].includes(legend) ? 'revenue' : 'ebitda';

        financialPeriodValue = getFinancialPeriodValue({
          comparison,
          periods: financialsPeriods,
          periodId: value,
          type,
        });

        financialPeriodValue = getNumberValue(financialPeriodValue);
        const expr = !financialPeriodValue ? 0 : `=D${comparisonRow}/${financialPeriodValue}`;

        changes.push({
          cell: cells[legend + comparisonRow],
          value: expr,
        });
      });

      comparisonRow += 1;
    }
  });

  const companyCells = Object.values(cells)
    .filter(cell => cell.alias === COMPANY && companyFields.includes(cell.columnId))
    .filter(({ columnLegend }) => columnLettersToParse.includes(columnLegend));

  const selectedPeriod = financialsPeriods.find(p => p.id.toString() === value.toString());
  const { ebitda, total_revenue: revenue } = selectedPeriod.income_statement;

  const newPeriodValue = {
    [LTM_REVENUE]: revenue,
    [NTM_REVENUE]: revenue,
    [LTM_EBITDA]: ebitda,
    [NTM_EBITDA]: ebitda,
  };

  companyCells.forEach(cell => {
    changes.push({
      cell,
      value: newPeriodValue[cell.columnId],
    });
  });
};

export function selectionChange(
  cells,
  changes,
  cell,
  value,
  useMultiplePremiumDiscount = false,
  selectionUpdates = {}
) {
  let selectionA = percentileOptions[cells.TITLES_percentile_selection_a.value - 1];
  let selectionB = percentileOptions[cells.TITLES_percentile_selection_b.value - 1];
  if ('percentile_selection_a' in selectionUpdates) {
    selectionA = percentileOptions[selectionUpdates.percentile_selection_a - 1];
  }
  if ('percentile_selection_b' in selectionUpdates) {
    selectionB = percentileOptions[selectionUpdates.percentile_selection_b - 1];
  }
  const selectionMap = {
    Mean: MEAN_ALIAS,
    Median: MEDIAN_ALIAS,
    [SPECIFIED_LABEL]: SELECTED_MULTIPLE,
    [PERCENTILE_75]: SEVENTY_FIFTH_ALIAS,
    [PERCENTILE_25]: TWENTY_FIFTH_ALIAS,
    [selectionA?.label]: PERCENTILE_SELECTION_A_ALIAS,
    [selectionB?.label]: PERCENTILE_SELECTION_B_ALIAS,
  };

  const appliedMultipleCell = Object.values(cells).find(
    cellParam => cellParam.alias === SELECTED_MULTIPLE_VALUE && cellParam.columnId === cell.columnId
  );
  const mpdExpr = useMultiplePremiumDiscount ? ` * (1 + ${cell.columnId}_MPD)` : '';
  changes.push({
    cell: appliedMultipleCell,
    value: `=${cell.columnId}_${selectionMap[value]}${mpdExpr}`,
  });
}

export function percentileChange(cell, value, cells, changes, useMultiplePremiumDiscount = false) {
  const previousSelectionOption = percentileOptions[Number(cell.value) - 1]?.label;
  // eslint-disable-next-line no-case-declarations
  const currentOption = percentileOptions[value - 1]?.label;
  const selectionUpdates = {
    [cell.alias]: value,
  };
  Object.values(cells)
    .filter(selectionCell => selectionCell.alias === SELECTION && selectionCell.value === previousSelectionOption)
    .forEach(selectionCell => {
      changes.push({
        cell: selectionCell,
        value: currentOption,
      });

      selectionChange(cells, changes, selectionCell, currentOption, useMultiplePremiumDiscount, selectionUpdates);
    });
}

const afterCellChanged = (changes, cells, rowConfig, tableData) => {
  const { approach, financialsPeriods } = tableData;
  const { valuations_approach_gpc } = approach;
  const { use_multiple_premium_discount } = valuations_approach_gpc;

  changes.forEach(change => {
    const { cell, value } = change;

    switch (cell.alias) {
      // if the alias is to the percentile header, then we need to update the selection cells
      // if the alias if for the selection cells, then we need to update the formula for the applied multiple cell

      case PERCENTILE_SELECTION_A_ALIAS:
      case PERCENTILE_SELECTION_B_ALIAS:
        percentileChange(cell, value, cells, changes, use_multiple_premium_discount);
        break;

      case SELECTION:
        selectionChange(cells, changes, cell, value, use_multiple_premium_discount);
        break;

      case COMPANY:
        if (cell.columnId === LTM_EBITDA) {
          valuations_approach_gpc.use_adjusted_LTM_ebitda
            = Number(change.value) !== Number(cell.sheet.tableData.financials[LTM_EBITDA] ?? 0);
        }
        if (cell.columnId === NTM_EBITDA) {
          valuations_approach_gpc.use_adjusted_NTM_ebitda
            = Number(change.value) !== Number(cell.sheet.tableData.financials[NTM_EBITDA] ?? 0);
        }
        break;

      case MEDIAN_ALIAS:
      case MEAN_ALIAS:
      case SELECTED_MULTIPLE_VALUE:
        changes.push({
          cell,
          value: cell.expr,
        });
        break;
      case LAST_TWELVE_MONTHS:
        afterLTMNTMCellChanged({
          valuations_approach_gpc,
          cell,
          isLTM: true,
          financialsPeriods,
          value,
          changes,
          cells,
        });
        break;
      case NEXT_TWELVE_MONTHS:
        // Generate new changes for company comparison rows
        afterLTMNTMCellChanged({
          valuations_approach_gpc,
          cell,
          isLTM: false,
          financialsPeriods,
          value,
          changes,
          cells,
        });
        break;
      default:
        break;
    }
  });

  return changes;
};

export default afterCellChanged;
