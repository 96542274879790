import React, { FC, useContext } from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { FUND_TYPE } from 'common/constants/pageTypes';
import { LayoutContextValues } from 'common/types/store';
import { LayoutContext } from 'context';
import { getStringValue } from 'utilities';
import useStyles, { EDIT_BUTTON_CLASSNAME } from './styles';
import { TitleProps } from './types';

const EDIT_BUTTON_TOOLTIP = 'Edit';

const EditButton = () => {
  const { toggleMainForm } = useContext(LayoutContext) as unknown as LayoutContextValues;

  return (
    <Tooltip arrow title={EDIT_BUTTON_TOOLTIP}>
      <IconButton className={EDIT_BUTTON_CLASSNAME} data-testid="title-edit-button" onClick={toggleMainForm}>
        <EditIcon color="primary" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const Title: FC<TitleProps> = props => {
  const { pageTitle } = props;

  const { pageType } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h1">
      {pageTitle}

      {/* Display Edit Button on Funds page */}
      {[FUND_TYPE].includes(getStringValue(pageType)) && <EditButton />}
    </Typography>
  );
};

export default Title;
