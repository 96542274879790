import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Event as EventIcon } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { gridLocalShortDate, SHORT_DATE_FORMAT } from 'utilities/datesFormats';

const useStyles = makeStyles(theme => ({
  actionBtn: {
    position: 'absolute',
    top: '2px',
    right: 0,
  },
  calendarIcon: {
    fill: `${theme.palette.primary.dark} !important`,
  },
}));

const GridDate = ({
  cell,
  value,
  readOnly,
  disabled,
  minDate,
  maxDate,
  icon: Icon,
  onCellsChanged,
  className,
  disableFuture,
}) => {
  const classes = useStyles();
  const [openPicker, setOpenPicker] = useState(false);

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  const handleAccept = dateParam => {
    if (dateParam) {
      onCellsChanged(gridLocalShortDate(dateParam), cell);
    }
  };

  return (
    <>
      <IconButton
        className={clsx(classes.actionBtn, !!className && className)}
        disabled={disabled}
        size="small"
        color="primary"
        onClick={handleOpenPicker}>
        {Icon ? (
          <Icon size="small" color="secondary" className={classes.calendarIcon} />
        ) : (
          <EventIcon size="small" color="secondary" className={classes.calendarIcon} />
        )}
      </IconButton>
      <DatePicker
        open={openPicker}
        onOpen={handleOpenPicker}
        onClose={handleClosePicker}
        value={value || null}
        format={SHORT_DATE_FORMAT}
        onChange={handleAccept}
        onAccept={handleAccept}
        openTo={value ? 'date' : 'year'}
        views={['year', 'month', 'date']}
        disabled={!!readOnly}
        TextFieldComponent={() => null}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
      />
    </>
  );
};

GridDate.defaultProps = {
  readOnly: false,
  disabled: false,
  minDate: undefined,
  maxDate: undefined,
  icon: null,
  disableFuture: false,
};

GridDate.propTypes = {
  cell: PropTypes.shape({
    key: PropTypes.string,
    row: PropTypes.number,
    column: PropTypes.number,
  }),
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  onCellsChanged: PropTypes.func.isRequired,
  className: PropTypes.string,
  disableFuture: PropTypes.bool,
};

export default GridDate;
