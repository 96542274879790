import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { MEASUREMENT_DATE_SLUG } from 'dashboard409a/common/constants';
import { updateGlobalState } from 'dashboard409a/states/features/global';
import { AppDispatchType, RootStateType } from 'dashboard409a/states/store';
import { formatMD, getUniqueListBy, useQueryParams } from 'dashboard409a/utilities';
import { toString } from 'utilities';
import useStyles from './styles';
import { IDateFilter, IOptions } from './types';

const DateFilter: FC<IDateFilter> = props => {
  const { label, filterId } = props;

  const [options, setOptions] = useState<IOptions[]>([]);
  const [selectedValue, setSelectedValue] = useState('');

  const classes = useStyles();
  const { getQuery, setQuery } = useQueryParams();

  const { currentCompany } = useSelector((state: RootStateType) => state.global);
  const { allValuationScalarOpinion } = useSelector((state: RootStateType) => state.valuation);

  const dispatch = useDispatch<AppDispatchType>();

  const queryMeasurementDate = getQuery.get('md');

  const setLastMeasurementDate = useCallback(() => {
    const hasCurrentCompanyValuations
      = (allValuationScalarOpinion
        && allValuationScalarOpinion.length > 0
        && allValuationScalarOpinion[0]?.report_date.company === currentCompany?.id)
      || false;

    if (hasCurrentCompanyValuations) {
      const recentMD = options.length > 0 ? options[0] : undefined;

      if (recentMD) {
        const queryMD = `${recentMD.date}-${recentMD.id}`;

        setQuery('md', queryMD);
        dispatch(
          updateGlobalState({
            currentMeasurementDateId: recentMD.id,
            currentCompanyMeasurementDateId: recentMD.companyMeasurementDateId,
          })
        );
      }
    }
  }, [allValuationScalarOpinion, currentCompany, dispatch, options, setQuery]);

  // set options with all MDs
  useEffect(() => {
    setOptions([]);

    if (allValuationScalarOpinion) {
      const allOptions = allValuationScalarOpinion.map(
        scalarOpinion =>
          ({
            id: scalarOpinion.report_date.measurement_date.id,
            date: scalarOpinion.report_date.measurement_date.date,
            slug: scalarOpinion.report_date.measurement_date.slug,
            companyMeasurementDateId: scalarOpinion.report_date.id,
          } as IOptions)
      );

      setOptions(getUniqueListBy<IOptions>(allOptions, 'id').sort((a, b) => b.date.localeCompare(a.date)));
    }
  }, [allValuationScalarOpinion]);

  // get/set MD from query params
  useEffect(() => {
    if (options.length === 0) return;

    if (queryMeasurementDate) {
      const currentMD = options.find(option => option.slug === `${MEASUREMENT_DATE_SLUG}-${queryMeasurementDate}`);

      if (currentMD) {
        setSelectedValue(toString(currentMD.id));
        dispatch(
          updateGlobalState({
            currentMeasurementDateId: currentMD.id,
            currentCompanyMeasurementDateId: currentMD.companyMeasurementDateId,
          })
        );
      } else {
        setLastMeasurementDate();
      }
    } else {
      setLastMeasurementDate();
    }
  }, [dispatch, options, queryMeasurementDate, setLastMeasurementDate]);

  const renderValue = (value: unknown) => {
    const currentOption = options.find(option => toString(option.id) === toString(value));

    if (options.length > 0 && currentOption) {
      return formatMD(currentOption.date);
    }

    return `No ${label.toLowerCase()}s available`;
  };

  const onMeasurementDateChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const measurementDateId = event.target.value;

    if (measurementDateId) {
      const selectedMD = options.find(md => md.id === Number(measurementDateId));

      if (selectedMD) {
        const selectedMDQuery = `${selectedMD.date}-${selectedMD.id}`;
        setQuery('md', selectedMDQuery);
      }
    }
  };

  // Loading
  if (options.length === 0) {
    return <Skeleton variant="text" width={150} height={50} />;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${filterId}-label`} className={classes.label} shrink>
        {label}
      </InputLabel>
      <Select
        classes={{ root: classes.select }}
        className={classes.select}
        displayEmpty
        id={filterId}
        onChange={onMeasurementDateChange}
        renderValue={renderValue}
        value={selectedValue}>
        <MenuItem selected disabled value=" ">
          Select a {label}
        </MenuItem>
        {options
          // .sort((a, b) => b.date.localeCompare(a.date))
          .map(({ id, date }) => (
            <MenuItem value={toString(id)} key={toString(id)} id={`${filterId}-option-${id + 1}`}>
              {formatMD(date)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DateFilter;
