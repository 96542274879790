import { VALUATIONS_PERCENTILE_LABEL } from 'common/constants/valuations';
import { getNumberValue } from 'utilities';

const ORDINAL_NUMBERS_EXCEPTIONS = [11, 12, 13];
const ORDINAL_NUMBERS_SUFFIXES = { '1': 'st', '2': 'nd', '3': 'rd' };
export const calibrationAliasToPercentiles = [
  'ltm_revenue_multiple_percentile',
  'ntm_revenue_multiple_percentile',
  'ltm_ebitda_multiple_percentile',
  'ntm_ebitda_multiple_percentile',
];

export const getPercentileLabelCalibration = (index: number, alias: string) => {
  if (isNaN(index) === false && calibrationAliasToPercentiles.includes(alias)) {
    const newIndex = index.toFixed(2);
    const indexStr = newIndex.toString();

    if (indexStr === '1') {
      return '1st Percentile';
    }

    const percentile = (getNumberValue(newIndex) + 1).toString();
    const lastDigit = percentile[percentile.length - 1];

    const percentileSuffix = ORDINAL_NUMBERS_EXCEPTIONS.includes(getNumberValue(newIndex))
      ? 'th'
      : ORDINAL_NUMBERS_SUFFIXES?.[lastDigit as keyof typeof ORDINAL_NUMBERS_SUFFIXES] ?? 'th';

    const result = `${indexStr}${percentileSuffix} ${VALUATIONS_PERCENTILE_LABEL}`;

    if (result === '0.00st Percentile') {
      return '0th Percentile';
    }

    // this mathematically doesn't make sense, i display the values after 0. , so its visually purpose
    if (result === '1.00nd Percentile') {
      return '1st Percentile';
    }

    return result.substring(2);
  }
  return index;
};

export default getPercentileLabelCalibration;
