/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export const sumArrayValues = array => array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

export const objToArray = (object = {}) => Object.entries(object);

export const splitArrayIntoRows = ({ items = '', maxSize = 2 }) => {
  // Maximum number of items per row
  const chunkSize = items.length > maxSize ? Math.floor(items.length / 2) : items.length;
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new row
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};
