import {
  fullyDilutedOwnershipFormat,
  largeCurrencyFormat,
  largeDecimalFormat,
  smallCurrencyFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { SHEET_ALIASES_CONSTANTS, SHEET_TITLES_CONSTANTS } from 'pages/Portfolio/common/constants/companySummary';
import { generateTotalExpression, getExpression, getObjectValue, removeFormatIfBlank } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import {
  CellParserParams,
  CustomParserParams,
  FundsSecuritiesIndexes,
  GenerateMOICExpressions,
  GetFundsTotalsIndexes,
} from './types';

const {
  COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL,
  COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL,
  COMPANY_SUMMARY_SPREADSHEET_HEADER_TITLE,
  COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE,
} = SHEET_ALIASES_CONSTANTS;
const { COMPANY_SUMMARY_SPREADSHEET_COLUMNS, COMPANY_SUMMARY_SPREADSHEET_HEADERS } = SHEET_TITLES_CONSTANTS;

const DEFAULT_ALLOW_NEGATIVE_VALUE = true;
const DEFAULT_COL_SPAN = 1;
const DEFAULT_ROW_SPAN = 1;

const generateMOICExpressions = (params: GenerateMOICExpressions) => {
  const { alphabet, rowNumber } = params;

  // Columns legends (-1 excluding Type of Security Column)
  const investedCapitalColLegend = alphabet[COMPANY_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL - 1];
  // Columns legends (-1 excluding Type of Security Column)
  const totalValueColLegend = alphabet[COMPANY_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE - 1];

  // Cell key of total of Total Values
  const totalInvestedCapitalKey = `${investedCapitalColLegend}${rowNumber}`;
  // Cell key of total of Total Values
  const totalTotalValueKey = `${totalValueColLegend}${rowNumber}`;

  return {
    totalInvestedCapitalKey,
    totalTotalValueKey,
  };
};

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, row, rowIndex, newFundTotalIndexes, newFirmTotalIndexes } = params;
  const rowNumber = rowIndex + 1;
  const colNumber = colIndex + 1;
  const columnLegend = alphabet[colIndex];
  const key = columnLegend + rowNumber;

  const { alias, colSpan = DEFAULT_COL_SPAN, hidden = false, tooltipMessages = [] } = getObjectValue(row);
  let { expr = '', format = null, gridType = 'string' } = getObjectValue(row);

  let { value = '' } = getObjectValue(column[alias]);

  // Get Total Invested Capital and Total Total Value Expression of the current Fund
  const { totalInvestedCapitalKey, totalTotalValueKey } = generateMOICExpressions({ alphabet, rowNumber });

  if (row.alias === COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL) {
    const newIndex = `@${rowNumber}`;
    if (!newFirmTotalIndexes.includes(newIndex)) {
      newFirmTotalIndexes.push(newIndex);
    }
  }

  if (row.alias === COMPANY_SUMMARY_SPREADSHEET_FUND_TITLE) {
    newFundTotalIndexes.length = 0;
  }

  if (row.hasSubRows) {
    const newIndex = `@${rowNumber}`;
    if (!newFundTotalIndexes.includes(newIndex)) {
      newFundTotalIndexes.push(newIndex);
    }
  }

  // Parse cell based on Row alias
  switch (alias) {
    // Header titles
    case COMPANY_SUMMARY_SPREADSHEET_HEADER_TITLE:
      value = COMPANY_SUMMARY_SPREADSHEET_HEADERS[colNumber].value;
      break;

    // Fund Totals
    case COMPANY_SUMMARY_SPREADSHEET_FUND_TOTAL:
      switch (colNumber) {
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          expr = `=SUM(${newFundTotalIndexes})`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.SHARES:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.CSE_SHARES:
          expr = `=SUM(${newFundTotalIndexes})`;
          format = largeDecimalFormat;
          gridType = 'number';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          expr = `=SUM(${newFundTotalIndexes})`;
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.MOIC:
          // Total X Factor or MOIC = Total of Total Value / Total Invested Capital
          expr = `=IF(${totalInvestedCapitalKey}>0,${totalTotalValueKey}/${totalInvestedCapitalKey},0)`;
          format = xStandardSuffixFormat;
          gridType = 'number';
          break;

        default:
          break;
      }
      break;

    // Totals
    case COMPANY_SUMMARY_SPREADSHEET_FIRM_TOTAL:
      switch (colNumber) {
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          expr = `=SUM(${newFirmTotalIndexes})`;
          format = largeCurrencyFormat;
          gridType = 'number';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.SHARES:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.CSE_SHARES:
          expr = `=SUM(${newFirmTotalIndexes})`;
          format = largeDecimalFormat;
          gridType = 'number';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          expr = `=SUM(${newFirmTotalIndexes})`;
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.MOIC:
          // Total X Factor or MOIC = Total of Total Value / Total Invested Capital
          expr = `=IF(${totalInvestedCapitalKey}>0,${totalTotalValueKey}/${totalInvestedCapitalKey},0)`;
          format = xStandardSuffixFormat;
          gridType = 'number';
          break;

        default:
          break;
      }
      break;

    // Rest of the rows (excluding Header titles and Totals)
    default:
      // Parse cell based on Column number
      switch (colNumber) {
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.INVESTED_CAPITAL:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.REALIZED_VALUE:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.UNREALIZED_EQUITY:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.TOTAL_VALUE:
          ({ value, format, gridType } = removeFormatIfBlank(value, largeCurrencyFormat, 'number'));
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.CONCLUDED_SHARE_VALUE:
          ({ value, format, gridType } = removeFormatIfBlank(value, smallCurrencyFormat, 'number'));
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.SHARES:
        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.CSE_SHARES:
          format = largeDecimalFormat;
          gridType = 'number';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.FULLY_DILUTED_OWNERSHIP:
          format = fullyDilutedOwnershipFormat;
          gridType = 'percentage';
          break;

        case COMPANY_SUMMARY_SPREADSHEET_COLUMNS.MOIC:
          ({ value, format, gridType } = removeFormatIfBlank(value, xStandardSuffixFormat, 'number'));
          break;

        default:
          break;
      }
      break;
  }

  return {
    [key]: {
      ...row,
      allowNegativeValue: DEFAULT_ALLOW_NEGATIVE_VALUE,
      colSpan,
      columnLegend,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      hidden,
      key,
      rowSpan: DEFAULT_ROW_SPAN,
      tooltipMessages,
      value,
    } as Cell,
  };
};

const getFundsTotalsIndexes = (fundsSecuritiesIndexes: FundsSecuritiesIndexes): GetFundsTotalsIndexes => {
  const nextIndexes = [] as GetFundsTotalsIndexes;

  Object.keys(fundsSecuritiesIndexes).forEach(key => {
    const array = fundsSecuritiesIndexes[key];

    const lastIndex = array[array.length - 1];
    nextIndexes.push(lastIndex + 1);
  });

  return nextIndexes;
};

const customParser = (params: CustomParserParams) => {
  const { columns, initialObject, rowConfig } = params;

  const { fundsSecuritiesIndexes } = initialObject;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  const fundsTotalsIndexes = getFundsTotalsIndexes(fundsSecuritiesIndexes);
  const fundsTotalsExpression = generateTotalExpression({
    items: fundsTotalsIndexes,
    generateIndex: false,
  });

  const newFundTotalIndexes: string[] = [];
  const newFirmTotalIndexes: string[] = [];
  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          fundsSecuritiesIndexes,
          fundsTotalsExpression,
          row,
          rowIndex,
          newFundTotalIndexes,
          newFirmTotalIndexes,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
