import * as constants from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { EBITDA_GPC_MULTIPLE, REVENUE_GPC_MULTIPLE } from 'pages/Valuations/util/constants';
import { MULTIPLE_OPTIONS } from 'pages/ValuationsAllocation/approaches/DiscountCashFlow/utilities/constants';

export default (alias, multiple, { _data: options }) => {
  let type = '';
  if (alias === EBITDA_GPC_MULTIPLE) {
    type = 'ebitda';
  } else if (alias === REVENUE_GPC_MULTIPLE) {
    type = 'revenue';
  }
  const multiples = options.reduce((sofar, [key, val]) => {
    // eslint-disable-next-line no-param-reassign
    sofar[key] = val;
    return sofar;
  }, {});
  switch (multiple) {
    case MULTIPLE_OPTIONS.MEAN:
      return multiples[`ltm_${type}_${constants.MEAN_ALIAS}`];
    case MULTIPLE_OPTIONS.MEDIAN:
      return multiples[`ltm_${type}_${constants.MEDIAN_ALIAS}`];
    case MULTIPLE_OPTIONS.SEVENTY_FIFTH:
      return multiples[`ltm_${type}_${constants.SEVENTY_FIFTH_ALIAS}`];
    case MULTIPLE_OPTIONS.TWENTY_FIFTH:
      return multiples[`ltm_${type}_${constants.TWENTY_FIFTH_ALIAS}`];
    default:
      return '';
  }
};
