import { ValuationApproachFutureExit } from 'api';
import {
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHODS_NAMES,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import { getNumberValue, getObjectValue } from 'utilities';

const getFutureExitScenarioMethod = (futureExitValuationApproach?: ValuationApproachFutureExit) => {
  const { allocation_method: futureExitAllocationMethod } = getObjectValue(futureExitValuationApproach);

  const isFutureExitWithOPM = getNumberValue(futureExitAllocationMethod) === ALLOCATION_SCENARIO_METHOD_OPM;

  const futureExitScenarioMethod
    = ALLOCATION_SCENARIO_METHODS_NAMES[
      getNumberValue(futureExitAllocationMethod) as ValuationApproachFutureExit.allocation_method
    ];

  return { futureExitScenarioMethod, isFutureExitWithOPM };
};

export default getFutureExitScenarioMethod;
