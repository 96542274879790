import { MAX_CHAR_LENGTH } from 'common/constants/general';
import { roundedPercentFormatIsDecimalTrue, smallCurrencyFormat, yearsDecimalFormat } from 'common/formats/formats';
import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import {
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY,
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import {
  FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY,
  FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';
import { getExpression, getObjectValue, gridShortDate } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { CellParserParams, CustomParserParams } from './types';

const {
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH,
  FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE,
} = SHEET_ALIASES_CONSTANTS;

const { FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET } = SHEET_TITLES_CONSTANTS;

const getSummaryExprFromLtmData = (ltmData: FinancialsTransposed) => {
  const plusDebt = Number(ltmData?.total_debt) || 0;
  const lessCash = Number(ltmData?.total_cash_equivalents) || 0;
  return `${plusDebt} - ${lessCash}`;
};

const cellParser = (params: CellParserParams) => {
  const { alphabet, colIndex, column, row, rowIndex, futureEquitySheet, ltmData, measurementDate } = params;

  const ROW_NUMBER = rowIndex + 1;
  const columnLegend = alphabet[colIndex];
  const cellKey = columnLegend + ROW_NUMBER;

  const { value: columnValue } = getObjectValue(column[row.alias]);

  // Creating Cell from Row and Column
  const cell = { ...row, key: cellKey, value: columnValue };
  const enterpriseValueExpr = getSummaryExprFromLtmData(getObjectValue(ltmData));
  const formattedMeasurementDate = gridShortDate(measurementDate?.date);

  // Parse Cell based on Row alias
  switch (row.alias) {
    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_HEADER_TITLE:
      cell.value = '';
      cell.readOnly = true;
      cell.ignoreRowCopy = true;
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_MEASUREMENT_DATE:
      cell.value = formattedMeasurementDate;
      cell.gridType = 'gridDate';
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_EXIT_DATE:
      cell.value = '';
      cell.expr = `=${futureEquitySheet?.name}.${FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY}`;
      cell.gridType = 'gridDate';
      cell.defaultValue = '';
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_YEARS_UNTIL_EXIT:
      cell.value = '';
      cell.expr = `=IF(${futureEquitySheet?.name}.${FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY}, YEARFRAC(${futureEquitySheet?.name}.${FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE_CUSTOM_KEY}, A${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.MEASUREMENT_DATE.ROW_NUMBER}, 3, 0))`;
      cell.gridType = 'number';
      cell.format = yearsDecimalFormat;
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_DISCOUNT_RATE:
      cell.value = columnValue;
      cell.gridType = 'percentage';
      cell.dbType = 'string';
      cell.dbDecimalPlaces = 4;
      cell.format = roundedPercentFormatIsDecimalTrue;
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS:
      cell.expr = `=${futureEquitySheet?.name}.${FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY}`;
      cell.value = '';
      cell.format = smallCurrencyFormat;
      cell.dbType = 'string';
      cell.gridType = 'number';
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_EQUITY_VALUE:
      cell.customKey = FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_CUSTOM_KEY;
      cell.expr = `=${futureEquitySheet?.name}.${FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS_CUSTOM_KEY} / (1 + A${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.DISCOUNT_RATE.ROW_NUMBER}) ^ A${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.YEARS_UNTIL_EXIT.ROW_NUMBER}`;
      cell.format = smallCurrencyFormat;
      cell.dbType = 'string';
      cell.gridType = 'number';
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PLUS_DEBT:
    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_LESS_CASH:
      cell.value = columnValue;
      cell.format = smallCurrencyFormat;
      cell.dbType = 'string';
      cell.gridType = 'number';
      break;

    case FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_PRESENT_ENTERPRISE_VALUE:
      cell.customKey = VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY;
      cell.gridType = 'number';
      cell.format = smallCurrencyFormat;
      cell.dbType = 'string';
      cell.gridType = 'number';
      cell.expr = `=A${FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET.PRESENT_EQUITY_VALUE.ROW_NUMBER} + ${enterpriseValueExpr}`;
      cell.className = 'bold-row';
      break;

    default:
      break;
  }

  // Set default Cell values
  const {
    allowEmptyValues = true,
    customKey = null,
    dbType = 'string',
    expr = '',
    format = null,
    gridType = 'string',
    isTextArea = false,
    maxNumberChars = MAX_CHAR_LENGTH,
    value = '',
  } = getObjectValue(cell);

  return {
    [cell.key]: {
      ...cell,
      allowEmptyValues,
      columnLegend,
      customKey,
      dbType,
      expr: getExpression({ expr, columnLegend }),
      format,
      gridType,
      isTextArea,
      maxNumberChars,
      value,
    } as Cell,
  };
};

const customParser = (params: CustomParserParams) => {
  const { columns, rowConfig, futureEquitySheet, ltmData, measurementDate } = params;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      cells = {
        ...cells,
        ...cellParser({
          alphabet,
          colIndex,
          column,
          row,
          rowIndex,
          futureEquitySheet,
          ltmData,
          measurementDate,
        }),
      };
    });
  });

  return cells;
};

export default customParser;
