import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { companiesAction, fundsAction, globalAction } from 'common/actions';
import { firmSummaryUrl, fundSummaryUrl } from 'common/config/urls';
import { useStore } from 'common/store';
import { DialogContextValues } from 'common/types/dialogContext';
import { UseStoreValues } from 'common/types/store';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import { FundFormSchema } from 'pages/Funds/forms/FundDialogForm/hooks/useFundForm';
import { useCreateFund, useDeleteFund, useUpdateFund } from 'services/hooks/funds';
import { getArrayValue, getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { UseFund } from './types';

const DELETE_LABEL = 'Delete';
const DIALOG_TITLE = 'Confirmationsss';

const useFund: UseFund = params => {
  const { firmSlugParam } = params;

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { firmId, fundInfo, fundList } = storeValue;

  const { showDialog } = useContext(DialogContext) as unknown as DialogContextValues;

  const history = useHistory();

  const { mutateAsync: createFund } = useCreateFund();

  const { mutateAsync: updateFund } = useUpdateFund();

  const { mutateAsync: deleteFund } = useDeleteFund();

  // Create
  const handleCreateFund = useCallback(
    async (fund: FundFormSchema) => {
      dispatch(globalAction.showLoadingProgress(true));

      const createFundResponse = await createFund(
        { fund: { ...fund, firm: getNumberValue(firmId) } },
        {
          onError: () => {
            dispatch(globalAction.showLoadingProgress(false));
          },
        }
      );

      const { fund: createdFund } = getObjectValue(createFundResponse);

      if (createdFund) {
        // Update Fund Info  innmediatly after create a Fund (meanwhile the query is refetching)
        dispatch?.(fundsAction.setFundInfo(getObjectValue(createdFund)));
        // Update Funds List innmediatly after create a Fund (meanwhile the query is refetching)
        dispatch?.(
          companiesAction.setFundList(
            fundList ? [...fundList, getObjectValue(createdFund)] : [getObjectValue(createdFund)]
          )
        );
      }

      dispatch(globalAction.showLoadingProgress(false));

      return history.push(fundSummaryUrl(getStringValue(firmSlugParam), getStringValue(createdFund?.slug)));
    },
    [createFund, dispatch, firmId, firmSlugParam, fundList, history]
  );

  // Update
  const handleUpdateFund = useCallback(
    async (fund: FundFormSchema) => {
      dispatch(globalAction.showLoadingProgress(true));

      const updateFundResponse = await updateFund({
        fund: { ...getObjectValue(fundInfo), ...fund, firm: getNumberValue(firmId) },
      });

      const { fund: updatedFund } = getObjectValue(updateFundResponse);

      if (updatedFund) {
        // Update Fund Info innmediatly after update a Fund (meanwhile the query is refetching)
        dispatch?.(fundsAction.setFundInfo(getObjectValue(updatedFund)));

        // Update Funds List innmediatly after update a Fund (meanwhile the query is refetching)
        const updatedFundList = [...getArrayValue(fundList)];
        const fundIndex = updatedFundList.findIndex(fundItem => fundItem?.id === updatedFund?.id);
        if (fundIndex) updatedFundList[fundIndex] = getObjectValue(updatedFund);
        dispatch?.(companiesAction.setFundList(updatedFundList));
      }

      dispatch(globalAction.showLoadingProgress(false));

      return history.push(fundSummaryUrl(getStringValue(firmSlugParam), getStringValue(updatedFund?.slug)));
    },
    [dispatch, firmId, firmSlugParam, fundInfo, fundList, history, updateFund]
  );

  // Delete
  const handleDeleteFund = useCallback(async () => {
    dispatch(globalAction.showLoadingProgress(true));

    const deleteFundResponse = await deleteFund({ fundId: fundInfo?.id });

    const { fundId } = getObjectValue(deleteFundResponse);

    // Update Funds List innmediatly after remove a Fund (meanwhile the query is refetching)
    if (fundId) dispatch?.(fundsAction.setFundList(fundList?.filter(fund => fund.id !== fundId)));

    dispatch(globalAction.showLoadingProgress(false));

    return history.push(firmSummaryUrl(getStringValue(firmSlugParam)));
  }, [deleteFund, dispatch, firmSlugParam, fundInfo, fundList, history]);

  const confirmDeleteFund = useCallback(() => {
    showDialog({
      wrapper: ConfirmationDialog,
      content: () => <ConfirmationDelete itemName={`Fund: ${fundInfo?.name}`} />,
      actions: [
        {
          label: DELETE_LABEL,
          variant: 'contained',
          type: 'danger',
          callback: handleDeleteFund,
        },
      ],
      wrapperProps: {
        title: DIALOG_TITLE,
      },
    });
  }, [fundInfo, handleDeleteFund, showDialog]);

  return {
    confirmDeleteFund,
    handleCreateFund,
    handleUpdateFund,
  };
};

export default useFund;
