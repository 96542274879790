import { isEmpty } from 'lodash';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getObjectValue } from 'utilities';
import parser from './parser';
import reverseParserCalibrationOutputs from './reverseParserCalibrationOutputs';
import getRowConfigCalibrationOutputs from './rowConfigCalibrationOutputs';
import rowTransformerCalibrationOutputs from './rowTransformerCalibrationOutputs';
import createColumnsCalibrationPerformance from '../performance_metrics/config/createColumns/createColumnsCalibrationPerformance';
import { CreateCalibrationOutputsDataParams } from '../performance_metrics/types';

const createCalibrationOutputsData = (params: CreateCalibrationOutputsDataParams) => {
  const { calibration, approach, name, isDisabled, financials, comparisons } = params;

  const { valuations_approach_gpc: valuationsApproachGpc } = getObjectValue(approach);

  const columnsPerformance = createColumnsCalibrationPerformance({
    valuationsApproachGpc,
    financials,
    gpcComparisonByDate: comparisons,
  });

  const columns = rowTransformerCalibrationOutputs(undefined);

  const rowConfig = getRowConfigCalibrationOutputs({
    calibration,
    isDisabled,
    columnsPerformance,
  });

  const spreadsheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      columns,
      name,
      parser,
      rowConfig,
      tableData: { calibration, columnsPerformance, isDisabled },
      totalParser: undefined,
      unitsFormatter: false,
      currencyFormatter: false,
      format: undefined,
      reverseParser: reverseParserCalibrationOutputs as unknown as SpreadsheetConfig['reverseParser'],
      allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
      showToolbar: true,
      allowConfirmAndDeleteColumn: false,
      allowCopyColumn: false,
      allowCopyRows: !isDisabled,
      allowAddSingleRow: !isDisabled,
      allowDeleteRow: !isDisabled,
      allowDeleteColumn: false,
    })
    : undefined;

  return { spreadsheet };
};

export default createCalibrationOutputsData;
