import {
  EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE,
  EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE,
} from 'pages/ValuationsAllocation/common/constants/externalValuation/sheetAliases';
import { getNumberValue, getObjectValue } from 'utilities';
import { CreateColumns, ExternalValuationColumnCellData } from './types';

const createColumns: CreateColumns = params => {
  const { externalValuationApproach } = params;
  const { enterprise_value: approachEnterpriseValue, equity_value: approachEquityValue }
    = getObjectValue(externalValuationApproach);

  // Enterprise Value
  const enterpriseValue = {
    [EXTERNAL_VALUATION_SPREADSHEET_EQUITY_VALUE]: {
      value: getNumberValue(approachEquityValue),
    } as ExternalValuationColumnCellData,

    [EXTERNAL_VALUATION_SPREADSHEET_ENTERPRISE_VALUE]: {
      value: getNumberValue(approachEnterpriseValue),
    } as ExternalValuationColumnCellData,
  };

  return [enterpriseValue];
};

export default createColumns;
