import { ValuationApproachModel } from 'api';
import { ValuationsApproach } from 'common/types/valuation';
import getGPCRowConfig from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import gpcRowTransformer from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/rowTransformer';
import getGPTRowConfig from 'pages/Valuations/approaches/GuidelineTransactions/config/getRowConfig';
import gptRowTransformer from 'pages/Valuations/approaches/GuidelineTransactions/config/rowTransformer';
import {
  VALUATIONS_APPROACH_GPC_PROPERTY,
  VALUATIONS_APPROACH_GPT_PROPERTY,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { getObjectValue, getStringValue } from 'utilities';
import { UpdatePercentileAndGetConfigurationParams } from './types';

const APPROACHES_WITH_PERCENTILE_CONFIGURATIONS = Object.freeze({
  [VALUATIONS_PUBLIC_COMPANIES_APPROACH]: {
    approachProperty: VALUATIONS_APPROACH_GPC_PROPERTY,
    rowConfigGenerator: getGPCRowConfig,
    rowTransformer: gpcRowTransformer,
  },
  [VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH]: {
    approachProperty: VALUATIONS_APPROACH_GPT_PROPERTY,
    rowConfigGenerator: getGPTRowConfig,
    rowTransformer: gptRowTransformer,
  },
});

const updatePercentileAndGetConfiguration = (params: UpdatePercentileAndGetConfigurationParams) => {
  const { alias, approach, updatedValue } = params;

  const { approach_type: approachType } = getObjectValue(approach);
  const approachTypeKey = getStringValue(approachType) as keyof typeof APPROACHES_WITH_PERCENTILE_CONFIGURATIONS;

  const specificApproachProperty = APPROACHES_WITH_PERCENTILE_CONFIGURATIONS[approachTypeKey]
    .approachProperty as keyof ValuationApproachModel;

  const updatedApproach = {
    ...approach,
    [specificApproachProperty]: {
      ...getObjectValue(approach?.[specificApproachProperty] as object),
      [getStringValue(alias)]: updatedValue,
    },
  } as ValuationsApproach;

  return {
    rowConfigGenerator: APPROACHES_WITH_PERCENTILE_CONFIGURATIONS[approachTypeKey].rowConfigGenerator,
    rowTransformer: APPROACHES_WITH_PERCENTILE_CONFIGURATIONS[approachTypeKey].rowTransformer,
    specificApproachProperty,
    updatedApproach,
  };
};

export default updatePercentileAndGetConfiguration;
