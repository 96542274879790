import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';
import {
  TASK_PROGRESS_DETAILS_DEFAULT_ERROR_MESSAGE,
  TASK_PROGRESS_DETAILS_QUERY_KEY,
} from 'common/constants/services/firms';
import { FirmService } from 'services';
import { throwError } from 'utilities';
import {
  GetTaskProgressDetailsHook,
  GetTaskProgressDetailsParams,
  GetTaskProgressDetailsResponse,
  UseGetTaskProgressDetails,
} from './types';

const getTaskProgressDetails = async (params: GetTaskProgressDetailsParams) => {
  const { taskId } = params;

  if (isNil(taskId)) return null;

  const firmService = new FirmService();

  try {
    const tasksProgressDetail = (await firmService.getTaskProgressDetail(taskId)) as GetTaskProgressDetailsResponse;

    if (!tasksProgressDetail) return throwError(TASK_PROGRESS_DETAILS_DEFAULT_ERROR_MESSAGE);

    return tasksProgressDetail;
  } catch (error) {
    return throwError(TASK_PROGRESS_DETAILS_DEFAULT_ERROR_MESSAGE);
  }
};

const useGetTaskProgressDetails: UseGetTaskProgressDetails = params => {
  const { task, shouldQueryAutomatically = true } = params;

  const taskId = task?.id;

  const { data, error, isError, isFetching, isLoading, refetch } = useQuery<GetTaskProgressDetailsHook['data'], Error>({
    enabled: !isNil(taskId) && shouldQueryAutomatically,
    queryFn: () => getTaskProgressDetails({ taskId }),
    queryKey: [TASK_PROGRESS_DETAILS_QUERY_KEY, taskId],
  });

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useGetTaskProgressDetails;
