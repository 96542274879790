import moment from 'moment';
import { MeasurementDate, MeasurementDates } from 'common/types/measurementDate';
import { shortDate } from 'utilities/datesFormats';
import { getArrayValue } from 'utilities/getArrayValue';

const isValidDate = (date: Date) => {
  const formats = [moment.ISO_8601];

  return moment(date, formats, true).isValid();
};

const formatMeasurementDates = (measurementDates: MeasurementDates) => {
  const formattedMeasurementDates = getArrayValue(
    measurementDates?.map(measurementDate => {
      const { name } = measurementDate;

      const formattedName = name && isValidDate(name) ? (shortDate(name) as unknown as Date) : name;

      return { ...measurementDate, name: formattedName } as MeasurementDate;
    })
  );

  return formattedMeasurementDates;
};

export default formatMeasurementDates;
