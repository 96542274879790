import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { notFoundPath } from 'common/config/urls';
import { PRIMARY_FINANCIAL_LABEL } from 'common/constants/financials/financials';
import { FINANCIAL_VERSION_FILTER, FINANCIAL_VERSION_FILTER_TITLE } from 'common/constants/pageFilters';
import LayoutContext from 'context/LayoutContext';
import UnsavedChanges from 'context/UnsavedChanges';
import { toString } from 'utilities';

const useVersionsFilter = ({ financialVersions }) => {
  const history = useHistory();
  const location = useLocation();

  const { version: versionQueryString } = queryString.parse(location.search);
  const parsedQuery = useMemo(() => queryString.parse(location.search), [location.search]);

  const { pageFilters, setPageFilters } = useContext(LayoutContext);

  const { action: hasChanges, showDialog } = useContext(UnsavedChanges);
  const [selectedVersion, setSelectedVersion] = useState('');

  const setQueryString = useCallback(
    (paramName, newQueryString) => {
      parsedQuery[paramName] = newQueryString;
      history.replace({ pathname: location.pathname, search: queryString.stringify(parsedQuery) });
    },
    [history, location.pathname, parsedQuery]
  );

  const handleVersion = useCallback(() => {
    if (financialVersions?.length > 0) {
      // No versioning means that statement is the primary
      const defaultVersion = financialVersions.find(v => !v.versioning);
      const version = versionQueryString || defaultVersion?.id;

      const matchVersion = v => financialVersions.find(item => item.slug === v || item.id.toString() === v.toString());

      const setVersionQueryString = v => {
        if (
          !selectedVersion
          || selectedVersion.id.toString() !== v.id.toString()
          || selectedVersion?.name !== v?.name
        ) {
          setSelectedVersion(v);
          setQueryString('version', v.slug);
        }
      };

      if (version) {
        const matchedVersion = matchVersion(version);
        if (matchedVersion) {
          setVersionQueryString(matchedVersion);
        } else if (defaultVersion) {
          // sometimes a name change creates a mismatch of the version query string and the new name
          setVersionQueryString(defaultVersion);
        } else {
          history.push(notFoundPath);
        }
      }
    } else {
      setSelectedVersion('');
    }
  }, [financialVersions, history, selectedVersion, setQueryString, versionQueryString]);

  const onVersionChange = useCallback(
    event => {
      const callback = () => {
        const tmpSelectedVersion = financialVersions.find(v => toString(v.id) === event.target.value);
        setSelectedVersion(tmpSelectedVersion);
        setQueryString('version', tmpSelectedVersion.slug);
      };

      if (hasChanges) {
        showDialog(callback);
      } else {
        callback();
      }
    },
    [financialVersions, hasChanges, setQueryString, showDialog]
  );

  const setFilters = useCallback(() => {
    const pageFilterMap = pageFilters
      ? pageFilters.reduce((sofar, pageFilter) => {
        // eslint-disable-next-line no-param-reassign
        sofar[pageFilter.id] = pageFilter;
        return sofar;
      }, {})
      : {};

    let orderedVersions = [];

    if (financialVersions?.length) {
      orderedVersions = orderBy(financialVersions, 'versioning', 'desc').map(v => ({
        ...v,
        name: !v.versioning ? PRIMARY_FINANCIAL_LABEL : v.name,
      }));
    }
    if (pageFilterMap[FINANCIAL_VERSION_FILTER]?.selectedValue !== selectedVersion.id) {
      pageFilterMap[FINANCIAL_VERSION_FILTER] = {
        id: FINANCIAL_VERSION_FILTER,
        options: orderedVersions,
        title: FINANCIAL_VERSION_FILTER_TITLE,
        selectedValue: selectedVersion.id,
        handler: onVersionChange,
      };

      setPageFilters(Object.values(pageFilterMap));
    }
  }, [financialVersions, onVersionChange, pageFilters, selectedVersion.id, setPageFilters]);

  useEffect(() => {
    if (financialVersions) {
      handleVersion();
    }
  }, [financialVersions, handleVersion]);

  // Set filters
  useEffect(() => {
    if (!isEmpty(financialVersions) && selectedVersion && selectedVersion !== '' && pageFilters) {
      setFilters();
    }
  }, [selectedVersion, financialVersions, pageFilters, setFilters]);

  return { selectedVersion };
};

export default useVersionsFilter;
