import { getStringValue, worldCurrencies } from 'utilities';
import { GetCurrencyParams } from './types';

const getCurrency = (params: GetCurrencyParams): string => {
  const { currencyKey, defaultValue } = params;

  return getStringValue(currencyKey)
    ? worldCurrencies[currencyKey as keyof typeof worldCurrencies]
    : getStringValue(defaultValue);
};

export default getCurrency;
