import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { isUndefined } from 'lodash';
import { NO_TASKS } from 'common/constants/firms';
import { DOCUMENT_REQUEST_CATEGORY, QUESTIONNAIRE_REQUEST_CATEGORY } from 'common/constants/process-management';
import { Task } from 'common/types/firm';
import { CustomTaskBox } from 'pages/Funds/components/ProcessManagement/CustomTaskBox';
import { getDateValue } from 'utilities';
import { TaskCellProps } from './types';

const sortedByCreatedAtTasks = (array?: Task[]) =>
  array?.sort(
    (a, b) =>
      // getDateValue(DateValue, convertToDate)
      getDateValue(b?.created_at, true).getTime() - getDateValue(a?.created_at, true).getTime()
  );

const TaskCell: FC<TaskCellProps> = props => {
  const { companySlug, companyName, fundName, tasksProgress, measurementDateName } = props;

  const companyId = Number(companySlug.split('-').pop());
  const companyTasks = tasksProgress?.companies?.find(company => company.id === companyId);
  const tasksFoundByMeasurementDate = companyTasks?.company_measurement_dates?.find(
    measurementDate => measurementDate.measurement_date?.name === measurementDateName
  );

  // Find last created_at task of each category
  const lastCreatedDocumentTask = sortedByCreatedAtTasks(
    tasksFoundByMeasurementDate?.tasks?.filter(task => task.category === DOCUMENT_REQUEST_CATEGORY)
  )?.[0];

  const lastCreatedQuestionTask = sortedByCreatedAtTasks(
    tasksFoundByMeasurementDate?.tasks?.filter(task => task.category === QUESTIONNAIRE_REQUEST_CATEGORY)
  )?.[0];

  return (
    <Box display="flex" justifyContent="center">
      {!isUndefined(lastCreatedDocumentTask) && !isUndefined(lastCreatedQuestionTask) ? (
        <>
          {/* Last created Document Task */}
          <CustomTaskBox
            companyName={companyName}
            fundName={fundName}
            measurementDateName={measurementDateName}
            task={lastCreatedDocumentTask}>
            <InsertDriveFileOutlinedIcon />
          </CustomTaskBox>

          {/* Last created Question Task */}
          <CustomTaskBox
            companyName={companyName}
            fundName={fundName}
            measurementDateName={measurementDateName}
            task={lastCreatedQuestionTask}>
            ?
          </CustomTaskBox>
        </>
      ) : (
        <Typography variant="body2">{NO_TASKS}</Typography>
      )}
    </Box>
  );
};

export default TaskCell;
