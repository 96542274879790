import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { SSV_REFERENCE_TITLE, SSV_REFERENCE_TYPE } from 'common/constants/documents';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { MISSING_SECURITIES, MISSING_SECURITIES_MESSAGE } from 'common/constants/valuations';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { Widgets } from 'components';
import { EmptyCapTableMessage } from 'pages/CapTable/cap-table/components';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useDocuments } from 'services/hooks';
import { useNotes } from 'services/hooks/notes';
import { CapTableSelection } from './components/CapTableSelection';
import { SecuritySharesTable } from './components/SecurityShares';
import { getRowConfig as securitySharesRowConfig } from './components/SecurityShares/config/rowConfig';
import { SpecifiedShareValuesProps } from './types';

const SpecifiedShareValues = ({
  spreadsheets,
  onChange,
  workbook,
  approach,
  approachTableName,
}: SpecifiedShareValuesProps) => {
  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { capTableList } = storeValue;
  const [isSelectedCapTableEmpty, setIsSelectedCapTableEmpty] = useState(false);
  const { measurementDate, filesInApproach, setFilesInApproach, resetConfiguration, setNotesInApproach }
    = useContext(ValuationContext);

  const [isDisplayingRowNumbers, setIsDisplayingRowNumbers] = useState(false);

  const ssvApproachTableName = useMemo(() => {
    if (!approachTableName) {
      return getApproachTableName({ approach });
    }

    return approachTableName;
  }, [approachTableName, approach]);

  const { capTableSelectionSheet } = spreadsheets;
  const securitySharesSheet = spreadsheets[ssvApproachTableName];
  const { isDisabled } = capTableSelectionSheet.tableData;

  const approachPanelId = approach.panelId;
  const { filesToSave, setFilesToSave } = useDocuments();
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  const onCapTableChange = useCallback(
    (cell, value) => {
      if ((capTableList ?? []).length > 0 && typeof value === 'number') {
        const selectedCapTable = (capTableList ?? []).find(ct => ct.id === value);
        if (selectedCapTable?.securities) {
          setIsSelectedCapTableEmpty(selectedCapTable.securities.length === 0);
          securitySharesSheet.reset({
            tableData: {
              capTable: selectedCapTable,
              securities: selectedCapTable.securities,
            },
            rowConfig: securitySharesRowConfig({
              securities: selectedCapTable.securities,
              isDisabled,
            }),
            columns: null,
          });
          capTableSelectionSheet.reset({
            tableData: null,
            rowConfig: null,
            columns: [
              [],
              {
                ...approach.valuations_approach_ssv,
                cap_table: value,
                share_values: selectedCapTable?.securities,
              },
            ],
          });
          onChange(cell, value);
          if (resetConfiguration) {
            resetConfiguration();
          }
        }
      }
    },
    [
      capTableList,
      onChange,
      resetConfiguration,
      approach.valuations_approach_ssv,
      capTableSelectionSheet,
      securitySharesSheet,
      isDisabled,
    ]
  );

  useEffect(() => {
    if (capTableList) {
      const selectedCapTable = capTableList.find(ct => ct.is_primary);
      if (selectedCapTable?.securities) {
        setIsSelectedCapTableEmpty(selectedCapTable.securities.length === 0);
      }
    }
  }, [capTableList]);

  useEffect(() => {
    const tmpFilesInApproach = filesInApproach || [];
    if (tmpFilesInApproach.length > 0) {
      const tmpFilesToSave = tmpFilesInApproach.filter(file => file.panelId === approachPanelId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore setFilesToSave is not typed
      setFilesToSave(tmpFilesToSave[0]?.filesToSave);
    }
  }, [approachPanelId, filesInApproach, filesToSave, setFilesToSave]);

  useEffect(() => {
    if (setFilesInApproach && filesToSave?.length > 0) {
      setFilesInApproach(prevFiles => [
        {
          ...prevFiles,
          filesToSave,
          panelId: approachPanelId,
        },
      ]);
    }
  }, [approachPanelId, filesToSave, setFilesInApproach]);

  useEffect(() => {
    if (setNotesInApproach && !isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [
          ...tmpNotes,
          {
            notes: notes ?? [],
            panelId: approachPanelId,
            notesHasChanged,
          },
        ];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  return (
    <div style={{ width: '100%' }}>
      <CapTableSelection spreadsheets={spreadsheets} onChange={onCapTableChange} workbook={workbook} />
      <br />
      {isSelectedCapTableEmpty && ssvApproachTableName.length > 0 ? (
        <>
          <EmptyCapTableMessage title={MISSING_SECURITIES} tagline={MISSING_SECURITIES_MESSAGE} />
          <br />
        </>
      ) : (
        <div>
          <SecuritySharesTable
            spreadsheet={spreadsheets[ssvApproachTableName]}
            onChange={onChange}
            workbook={workbook}
            setIsDisplayingRowNumbers={setIsDisplayingRowNumbers}
          />
          <Widgets
            documentReferencesProps={{
              selectedMeasurementDate: measurementDate,
              referenceType: SSV_REFERENCE_TYPE,
              currentPage: SSV_REFERENCE_TITLE,
              referencedFeatureId: approach.id,
              filesToSave,
              setFilesToSave,
              isDisplayingRowNumbers,
              isDisabled,
            }}
            notesProps={{
              pageType: VALUATIONS_PAGE_VALUE,
              pageTypeKey: VALUATIONS_PAGE_KEY,
              pageTypeId: approach.id,
              notes,
              isApproach: true,
              setNotes,
              onAddNote,
              onUpdateNotes,
              onDeleteNote,
              isDisabled,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SpecifiedShareValues;
