import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CustomTextField } from 'components';
import { SET_AS_CALIBRATION } from 'pages/ValuationsAllocation/common/constants/valuations';
import { SelectFinancialsVersions } from 'pages/ValuationsAllocation/components/ApproachDialog/components/SelectFinancialsVersions';
import { ValuationsFormState } from 'pages/ValuationsAllocation/types';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { FormState, useFormValidation } from 'services/hooks/useFormValidation';
import { getObjectValue, getStringValue, gridShortDate } from 'utilities';
import useStyles from './styles';
import { ApproachFormProps } from './types';

const APPROACH_NAME_ID = 'name';
const APPROACH_NAME_LABEL = 'Approach Name';
const APPROACH_VALUATION_DATE_ID = 'valuation_date';
const APPROACH_MARKET_DATE_LABEL = 'Select Market Date';
const APPROACH_MARKET_DATE_PLACEHOLDER = 'Select Market Date';

const CALIBRATION_NAME_LABEL = 'Calibration Name';

const FORM_CONSTRAINTS = Object.freeze({
  name: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    length: {
      message: 'must be at least 2 characters',
      minimum: 2,
    },
  },
});

const ApproachForm: FC<ApproachFormProps> = props => {
  const { formState, onFormChange, shouldDisplayDateSelector } = props;

  const [formValues, setFormValues] = useState<ValuationsFormState['values']>(formState.values);

  const { disableFinancialsSelection, isEditingApproachName, isChangingFinancialStatement, isEditingCalibrationName }
    = useContext(ValuationContext);

  const classes = useStyles();

  const { formValidated, validateForm } = useFormValidation(FORM_CONSTRAINTS);
  const { errors } = getObjectValue(formValidated);

  // Validate Form
  useEffect(() => {
    validateForm(formValues as FormState['values']);
  }, [formValues, validateForm]);

  // Update Form
  useEffect(() => {
    onFormChange?.(formValidated);
  }, [formValidated, onFormChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setFormValues(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <Grid container>
      {isEditingApproachName && (
        <>
          <CustomTextField
            className={classes.field}
            dbErrors={null}
            formErrors={errors}
            fullWidth
            id={APPROACH_NAME_ID}
            label={APPROACH_NAME_LABEL}
            name={APPROACH_NAME_ID}
            onBlur={null}
            onChange={handleChange}
            onFocus={null}
            required
            type="text"
            value={getStringValue(formValues?.name)}
          />
          {shouldDisplayDateSelector && (
            <KeyboardDatePicker
              className={classes.field}
              emptyLabel={APPROACH_MARKET_DATE_PLACEHOLDER}
              format="MM/dd/yyyy"
              fullWidth
              label={APPROACH_MARKET_DATE_LABEL}
              onChange={newDate =>
                handleChange({
                  target: {
                    name: APPROACH_VALUATION_DATE_ID,
                    value: gridShortDate(newDate),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
              required
              value={formValues?.valuation_date}
            />
          )}
          <FormGroup row style={{ fontSize: '0.5rem' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="set-as-calibration"
                  color="primary"
                  checked={formValues?.is_calibration || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.persist();
                    setFormValues(prevState => ({ ...prevState, is_calibration: event.target.checked }));
                  }}
                />
              }
              label={<Typography variant="body2">{SET_AS_CALIBRATION}</Typography>}
            />
          </FormGroup>
        </>
      )}

      {isEditingCalibrationName && (
        <CustomTextField
          className={classes.field}
          dbErrors={null}
          formErrors={errors}
          fullWidth
          id={APPROACH_NAME_ID}
          label={CALIBRATION_NAME_LABEL}
          name={APPROACH_NAME_ID}
          onBlur={null}
          onChange={handleChange}
          onFocus={null}
          required
          type="text"
          value={getStringValue(formValues?.name)}
        />
      )}

      {!disableFinancialsSelection && isChangingFinancialStatement && (
        <SelectFinancialsVersions onChange={handleChange} />
      )}
    </Grid>
  );
};

export default ApproachForm;
